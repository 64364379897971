import { Timeline } from '../../styled';
import Icon from '@mdi/react';
import { mdiAccountCircle } from '@mdi/js';

const TimelineSoapEvolution = ({ timelineItem, shouldShow }) => (
  <>
    {shouldShow ? (
      <Timeline.Body>
        <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }} maxHeight={350}>
          <Timeline.GroupTitle>
            <Timeline.Title>SOAP</Timeline.Title>
            <Timeline.Title>
              <Timeline.RightDate datetime={timelineItem.date} />
            </Timeline.Title>
          </Timeline.GroupTitle>
          {timelineItem?.evolutionSoap?.attendanceType ? (
            <>
              <Timeline.Subtitle>Tipo de atendimento</Timeline.Subtitle>
              <Timeline.Text>{timelineItem.evolutionSoap.attendanceType}</Timeline.Text>
            </>
          ) : null}
          {timelineItem?.evolutionSoap?.soap?.subjective ? (
            <>
              <Timeline.Subtitle>Subjetivo</Timeline.Subtitle>
              <Timeline.Text>{timelineItem.evolutionSoap.soap.subjective}</Timeline.Text>
            </>
          ) : null}
          {timelineItem?.evolutionSoap?.soap?.objective ? (
            <>
              <Timeline.Subtitle>Objetivo</Timeline.Subtitle>
              <Timeline.Text>{timelineItem.evolutionSoap.soap.objective}</Timeline.Text>
            </>
          ) : null}
          {timelineItem?.evolutionSoap?.cid?.length ? (
            <>
              <Timeline.Subtitle>CID</Timeline.Subtitle>
              <Timeline.GroupClassfiers>
                {timelineItem.evolutionSoap.cid.map((classifier) => (
                  <Timeline.BadgeClassifier key={classifier}>{classifier}</Timeline.BadgeClassifier>
                ))}
              </Timeline.GroupClassfiers>
            </>
          ) : null}
          {timelineItem?.evolutionSoap?.ciap?.length ? (
            <>
              <Timeline.Subtitle>CIAP</Timeline.Subtitle>
              <Timeline.GroupClassfiers>
                {timelineItem.evolutionSoap.ciap.map((classifier) => (
                  <Timeline.BadgeClassifier key={classifier}>{classifier}</Timeline.BadgeClassifier>
                ))}
              </Timeline.GroupClassfiers>
            </>
          ) : null}
          {timelineItem?.evolutionSoap?.soap?.assessment ? (
            <>
              <Timeline.Subtitle>Avaliação</Timeline.Subtitle>
              <Timeline.Text>{timelineItem.evolutionSoap.soap.assessment}</Timeline.Text>
            </>
          ) : null}
          {timelineItem?.evolutionSoap?.soap?.plan ? (
            <>
              <Timeline.Subtitle>Plano</Timeline.Subtitle>
              <Timeline.Text>{timelineItem.evolutionSoap.soap.plan}</Timeline.Text>
            </>
          ) : null}
        </Timeline.Body.Item>
      </Timeline.Body>
    ) : null}

    <Timeline.Small>
      <Icon color="#1D1D1D" path={mdiAccountCircle} size={0.8} style={{ marginRight: 6 }} />
      Responsável: {timelineItem.evolutionSoap.user}
    </Timeline.Small>
  </>
);

export default TimelineSoapEvolution;

import { forwardRef, cloneElement, useMemo } from 'react';
import { useLayer, Arrow, useHover, mergeRefs } from 'react-laag';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './styled';

const positionMap = {
  top: 'top-center',
  left: 'left-center',
  right: 'right-center',
  bottom: 'bottom-center',
};

type Props = {
  text: string | React.ReactElement;
  position?: 'top' | 'left' | 'right' | 'bottom';
  children: React.ReactElement | string | number | ((...args: any[]) => any);
  auto?: boolean;
  maxWidth?: number;
};

const Tooltip = forwardRef<any, Props>(({ text, position, children, auto, maxWidth }, ref) => {
  const [isOpen, hoverProps] = useHover({ delayEnter: 100, delayLeave: 250 });
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    placement: positionMap[position],
    triggerOffset: 8,
    possiblePlacements: ['top-center', 'bottom-center', 'left-center', 'right-center'],
    auto,
    snap: true,
  });

  const trigger = useMemo(() => {
    if (['string', 'number'].includes(typeof children)) {
      return (
        <Styled.Text ref={mergeRefs(ref, triggerProps.ref)} {...hoverProps}>
          {children}
        </Styled.Text>
      );
    }

    return cloneElement(children, { ref: mergeRefs(ref, triggerProps.ref), ...hoverProps });
  }, [triggerProps, hoverProps, children, ref]);

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.Tooltip
              maxWidth={maxWidth}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              {...layerProps}
            >
              {text}
              <Arrow {...arrowProps} backgroundColor="#444444" size={6} />
            </Styled.Tooltip>
          )}
        </AnimatePresence>
      )}
    </>
  );
});

Tooltip.displayName = 'Tooltip';

Tooltip.defaultProps = {
  position: 'top',
  auto: true,
};

export default Tooltip;

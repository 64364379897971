import {
  Icon,
  ScheduleTimesItemContainer,
  ScheduleTimesItemCountContainer,
  ScheduleTimesItemTime,
} from './styled';
import { mdiCalendar } from '@mdi/js';
import moment from 'moment';

export default function ScheduleTimesItem({ selected, item, onClick }) {
  const time =
    moment(item.startTime).format('HH:mm') +
    ' - ' +
    moment(item.endTime).format('HH:mm');
  return (
    <ScheduleTimesItemContainer selected={selected} onClick={(e) => onClick(item, e)}>
      <ScheduleTimesItemTime>{time}</ScheduleTimesItemTime>
      <ScheduleTimesItemCountContainer>
        <Icon
          style={{ marginLeft: 5 }}
          path={mdiCalendar}
          size={0.8}
          color={selected ? '#fff' : '#707070'}
        />
      </ScheduleTimesItemCountContainer>
    </ScheduleTimesItemContainer>
  );
}

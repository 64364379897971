import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import MunaiGif from '../../Assets/munai-logo.gif';

import * as Styled from './styled';

const Loader = (props) => {
  const { modules } = props;
  const location = useLocation();
  const [careline, setCareLine] = useState('');

  useEffect(() => {
    const careline = location.pathname.split('/')[1];
    const carelinePath = modules?.filter((module) => module?.slug === careline);
    setCareLine(carelinePath?.[0]?.name);
  }, [location, modules]);

  return (
    <>
      <Styled.Container>
        <img src={MunaiGif} />
        {careline && (
          <>
            <Styled.Divider />
            <Styled.Title color="#FF881F">Módulo</Styled.Title>
            <Styled.Title color="#707070">{careline}</Styled.Title>
          </>
        )}
      </Styled.Container>
    </>
  );
};

export default Loader;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;

  img {
    height: 100px;
  }
`;

export const Divider = styled.div`
  margin: 5px 0px;
  height: 10px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff881f;
  font: 28px;
  text-align: center;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0.56px;
  opacity: 1;
  color: ${(props) => props.color};
`;

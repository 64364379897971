import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { UncontrolledSelect } from 'Components/Select';
import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';

import { rules, rulesMessages, customRules } from '../../Utils/Helpers/Validator';
import Input, { InputMaskControl } from '../Input';
import PractitionerCredentialsInput from './PractitionerCredentialsInput';
import { ROLE } from './shared';

const PractitionerForm = ({ editOnly = false }) => {
  const { register, errors, control, watch } = useFormContext();

  const isPractitionerCredentialsEnabled = useFlag(
    `lc-practitioner-flag-${environment.UNLEASH_APP_ENV}`
  );

  const roleOptions = useMemo(
    () => [
      { id: null, name: '' },
      { id: ROLE.DOCTOR, name: 'Equipe médica' },
      { id: ROLE.NURSE, name: 'Equipe de enfermagem' },
    ],
    []
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <div className="flex-fill">
          <Input
            name="firstName"
            id="Nome"
            label="Nome"
            type="text"
            ref={register({
              required: 'Favor informar o Nome.',
              validate: customRules.empty,
            })}
            error={errors.firstName}
            errormessage={errors?.firstName?.message}
          />
        </div>
        <div className="px-1" />
        <div className="flex-fill">
          <Input
            name="lastName"
            id="Sobrenome"
            label="Sobrenome"
            type="text"
            ref={register({
              required: 'Favor informar o Sobrenome.',
              validate: customRules.empty,
            })}
            error={errors.lastName}
            errormessage={errors?.lastName?.message}
          />
        </div>
      </div>
      <Input
        name="email"
        id="email"
        label="E-mail"
        type="email"
        ref={register({
          required: rulesMessages.email?.required,
          pattern: {
            value: rules.email,
            message: rulesMessages.email.pattern,
          },
        })}
        error={errors.email}
        errormessage={errors?.email?.message}
      />
      {!editOnly && (
        <div className="d-flex">
          <div className="flex-fill">
            <Input
              name="password"
              id="Senha"
              label="Senha"
              type="password"
              ref={register({
                required: 'Favor informar a Senha.',
                validate: customRules.empty,
              })}
              error={errors.password}
              errormessage={errors?.password?.message}
              autoComplete="new-password"
            />
          </div>
        </div>
      )}
      {isPractitionerCredentialsEnabled && (
        <InputMaskControl
          type="text"
          defaultValue={''}
          error={errors?.personalId}
          errormessage={errors?.personalId?.message}
          id="CPF"
          label="CPF"
          name="personalId"
          control={control}
          mask="999.999.999-99"
          rules={{
            required: rulesMessages.document.required,
            validate: customRules.cpf,
          }}
        />
      )}
      {!editOnly && (
        <>
          <UncontrolledSelect
            error={errors?.roleId}
            errormessage={{
              required: 'Favor informar a Especialidade.',
              pattern: 'Favor informar a Especialidade.',
            }}
            id="Especialidade"
            label="Especialidade"
            name="roleId"
            options={roleOptions}
            control={control}
            ref={register({ required: true })}
            isSelected={!!watch('roleId')}
          />
          <PractitionerCredentialsInput />
        </>
      )}
    </div>
  );
};

export const PractitionerFormSubmitButton = () => {
  const { formState } = useFormContext();

  return (
    <button
      disabled={formState.isSubmitting || !formState.isValid}
      className="btn btn-primary btn-block"
    >
      {formState.isSubmitting ? 'CARREGANDO...' : 'REGISTRAR PROFISSIONAL'}
    </button>
  );
};

export default PractitionerForm;

export const DictionaryIntents = [
  {
    affirm: 'Afirmações',
    compliment: 'Agradecimentos',
    airbag: 'Características/Airbag',
    ask_consumption: 'Características/Consumo',
    ask_engine: 'Características/Motor',
    ask_gastank: 'Características/Tanque Gasolina',
    ask_transmission: 'Características/Transmissão',
    ask_trunk_size: 'Características/Porta-Malas',
    ask_warranty: 'Características/Garantia',
    brake: 'Características/Freio',
    electrical_breakdown: 'Características/Avaria Elétrica',
    features: 'Características',
    dealerships: 'Concessionárias',
    consortium: 'Consórcio',
    app_discount: 'Desconto',
    financing: 'Financiamento/Geral',
    financing_anticipation: 'Financiamento/Antecipação',
    financing_discharge: 'Financiamento/Quitação',
    financing_doubts: 'Financiamento/Dúvidas',
    financing_history: 'Financiamento/Histórico',
    financing_others: 'Financiamento/Geral',
    financing_register: 'Financiamento/Registro',
    financing_simulation: 'Financiamento/Simulação',
    financing_transfer: 'Financiamento/Transferência',
    executive_board: 'Infos Nissan/Executivos',
    nissan_japan: 'Infos Nissan/Nissan Japão',
    presidency: 'Infos Nissan',
    frontier_versions: 'Modelos/Frontier',
    kicks_versions: 'Modelos/Kicks Versões',
    march_versions: 'Modelos/March',
    models: 'Modelos',
    nissan_frontier: 'Modelos/Frontier',
    nissan_kicks: 'Kicks Modelos',
    nissan_leaf: 'Modelos/Leaf',
    nissan_leaf_availability: 'Modelos/Leaf',
    nissan_leaf_battery_capacity: 'Modelos/Leaf',
    nissan_leaf_how_it_works: 'Modelos/Leaf',
    nissan_leaf_pre_sale: 'Modelos/Leaf',
    nissan_leaf_recharge: 'Modelos/Leaf',
    nissan_march: 'Modelos/March',
    nissan_sentra: 'Modelos/Sentra',
    nissan_versa: 'Modelos/Versa',
    sentra_versions: 'Modelos/Sentra',
    versa_versions: 'Modelos/Versa',
    versions: 'Modelos',
    no: 'Não Identificado',
    offer: 'Ofertas',
    offer_id: 'Ofertas',
    offer_model: 'Ofertas',
    bonus: 'Bonus',
    collision: 'Colisão',
    deny: 'Negações',
    expired_ticket: 'Outros',
    farewells: 'Despedidas',
    farmer_producer: 'Outros',
    greet: 'Saudações',
    handover: 'Entregas',
    inducement_phrase: 'Outros',
    informations: 'Informações',
    judicial_process: 'Processo Judicial',
    live_agent: 'Atendimento Humano',
    low_mileage: 'Outros/Baixa KM',
    market: 'Outros',
    media: 'Outros/Mídia',
    model_doubts: 'Outros',
    powerless: 'Outros/Perda de Potência',
    used_cars: 'Outros/Seminovos',
    zero_rate: 'Outros',
    installments: 'Parcelas/Parcelas',
    pcd: 'PCD',
    pcd_price: 'PCD/Preço',
    pcd_sales: 'PCD/Venda',
    pcd_term: 'PCD/Termos',
    price: 'Preço',
    complaint: 'Reclamações/Reclamações',
    procon: 'Reclamações/Procon',
    test_drive: 'Test Drive',
    direct_sale: 'Venda',
    direct_sales: 'Venda',
    Importado: 'Importado',
  },
];

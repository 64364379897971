import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { ModuleSectors } from 'Utils/Config/SectorConfig';
import { SectorDictionary } from 'Utils/Dictionary/RemoteAtendimento/SectorDictionary';
import TitleBack from 'Components/Modal/TitleBack';
import Button from 'Components/Button';
import DashboardModel from 'Models/DashboardModel';
import useCache from 'Hooks/useCache';
import ModuleContext from 'Context/moduleContext';
import { returnHanderOver } from 'Chat/context/conversations/bot';
import cacheService from 'Services/cache';

import { Styled } from '../Actions/styled';

export const RemoteAtendimentoChangeStatus = ({
  store,
  dispatch,
  alertRecord,
  callBack,
  actualSector,
}) => {
  const { isLoading, sector } = store;
  const { moduleAccess } = useContext(ModuleContext);

  const [remoteAtendimentoEvolutionRecord, { loading }] = useMutation(
    DashboardModel.REMOTE_ATENDIMENTO_EVOLUTION_RECORD
  );

  const [user] = useCache('user');

  const submit = () => {
    if (actualSector?.slug === 'HUMAN') {
      returnHanderOver(alertRecord.senderId);
    }

    remoteAtendimentoEvolutionRecord({
      variables: {
        entityId: parseInt(user.entityId),
        sector: sector.slug,
        atendimentoId: alertRecord.senderId,
        motivo: 'Status Change',
        usuario: user.name,
        email: user.email,
        module: 'customer_service',
        name: 'Enviado',
      },
    })
      .then(() => {
        dispatch({ type: 'success', message: 'Evolução registrada com Sucesso!' });
        cacheService.set('scrollPosition', window.scrollY);
        callBack();
      })
      .catch(() => {
        console.log('Error: ', {
          variables: {
            entityId: parseInt(user.entityId),
            sector: sector.slug,
            atendimentoId: alertRecord.senderId,
            motivo: 'Status Change',
            usuario: user.name,
            email: user.email,
            module: 'customer_service',
          },
        });
      });
  };

  const getClassName = (sectorDictionary) => {
    if (sector.slug === sectorDictionary.slug) return 'active';
    if (actualSector.slug === sectorDictionary.slug) return 'disabled';
    return '';
  };

  return (
    <>
      <TitleBack onClick={() => dispatch({ type: 'action', action: false })}>
        Mover Cliente
      </TitleBack>
      <Styled.Label>Para onde deseja mover o Cliente?</Styled.Label>

      <Styled.CheckoutOptionWrapper>
        {ModuleSectors(moduleAccess).map((sectorConfig) => (
          <Styled.CheckoutOption
            className={getClassName(sectorConfig)}
            key={sectorConfig.id}
            onClick={() => dispatch({ type: 'sector', sector: sectorConfig })}
          >
            <Styled.CheckoutIcon className={`mdi ${sectorConfig.icon}`} />
            <Styled.CheckoutDescription>{sectorConfig.name}</Styled.CheckoutDescription>
          </Styled.CheckoutOption>
        ))}
      </Styled.CheckoutOptionWrapper>
      {sector === SectorDictionary.HISTORY && (
        <p className="mt-2 text-right text-muted">
          {'O Cliente ainda poderá ser encontrado no menu "Clientes inativos"'}
        </p>
      )}
      <hr />
      <Button
        // observationLoading ||
        disabled={isLoading || loading || sector === ''}
        modifiers={['btn', 'fullWidth', 'bgPrimary', 'textWhite']}
        onClick={() => submit()}
        style={{ marginTop: '15px' }}
      >
        CONFIRMAR
      </Button>
    </>
  );
};

import { useState, useContext, useEffect } from 'react';
import Picker from 'emoji-picker-react';

import { Styled } from './styles';
import { mdiSend, mdiEmoticon } from '@mdi/js';
import { Context } from '../../context';

const Footer = (props) => {
  const { inputPlaceholder, disabled, submit } = props;

  const [emoji, setEmoji] = useState(false);
  const [value, setValue] = useState();
  const {
    state: { values },
    dispatch,
  } = useContext(Context);

  useEffect(() => {
    if (values === 'None') {
      setValue('');
    }
  }, [values]);

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const handleChange = (event, emojiObject) => {
    event.persist();

    if (emojiObject) {
      const emoji = emojiObject.emoji;
      setValue((value) => value + emoji);
    } else {
      setValue(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!event.shiftKey) {
        setEmoji(false);
        submit(value);

        sleep(100).then(() => {
          dispatch({ type: 'ADD_VALUE', payload: 'None' });
          setValue('');
        });
      }
    }
  };

  const handleSubmit = () => {
    if (value !== '') {
      setEmoji(false);
      submit(value);

      sleep(100).then(() => {
        dispatch({ type: 'ADD_VALUE', payload: 'None' });
        setValue('');
      });
    }
  };

  const Emoji = () => {
    emoji === false ? setEmoji(true) : setEmoji(false);
  };

  return (
    <Styled.Container>
      <Styled.Layout>
        {emoji && (
          <Styled.Modal>
            <Picker onEmojiClick={handleChange} />
          </Styled.Modal>
        )}
        <Styled.PickerIcon>
          <Styled.IconPicker
            color={emoji ? '#0071eb' : '#333'}
            onClick={Emoji}
            path={mdiEmoticon}
            size={1}
            title="Picker"
          />
        </Styled.PickerIcon>
        <Styled.Input
          spellCheck="true"
          lang="pt-BR"
          minRows={1}
          maxRows={3}
          placeholder={inputPlaceholder}
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          disabled={disabled}
          style={{ background: `${disabled ? '#f1f1f1' : '#fff'}` }}
        />
        <Styled.Send
          color={'#ab273b'}
          onClick={handleSubmit}
          path={mdiSend}
          size={1}
          title="Enviar"
        />
      </Styled.Layout>
    </Styled.Container>
  );
};

export default Footer;

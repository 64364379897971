import { useRef, useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import { useMutation } from '@apollo/client';

import useCache from 'Hooks/useCache';
import DashboardModel from 'Models/DashboardModel';
import LocalCache from 'Services/LocalCache';
import ModuleContext from 'Context/moduleContext';
import environment from 'Utils/Dictionary/environment';
import cpfMaks from '../Utils/Helpers/validate/cpf';
import cellphone from '../Utils/Helpers/validate/cellphone';

const useMemed = (token) => {
  const active = useRef(false);
  const [ready, setReady] = useState(false);
  const { moduleAccess } = useContext(ModuleContext);
  const [memedPatient, setMemedPatient] = useState(null);
  const [sendMemed, setSendMemed] = useState(null);
  const [user] = useCache('user');
  const [sendPrescriptionLink] = useMutation(DashboardModel.SEND_PRESCRIPTION_LINK);

  useEffect(() => {
    if (sendMemed != null && memedPatient != null) {
      if (cpfMaks.strip(sendMemed?.atendimentoId) === cpfMaks.strip(memedPatient?.medicalRecord)) {
        sendPrescriptionLink({
          variables: {
            ...sendMemed,
            usuario: user?.name,
            email: user?.email,
            attendantCpf: user?.cpf,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMemed]);

  useEffect(() => {
    if (token && !active.current && !window.MdHub) {
      active.current = true;
      const script = document.createElement('script');
      script.src =
        environment.MEMED_URL +
        '/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js';
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('id', 'memed-script-config');
      script.setAttribute('data-token', token);
      script.setAttribute('data-color', '#ff0000');
      script.async = true;
      script.onload = function () {
        window.MdSinapsePrescricao.event.add('core:moduleInit', function moduleInitHandler() {
          setReady(true);
        });
      };

      document.body.appendChild(script);
    } else {
      active.current = false;
    }

    return () => {
      window.MdHub && window.MdHub.server.unbindEvents();
      delete window.MdHub;
    };
  }, [token, setReady, active]);

  useEffect(() => {
    if (memedPatient) {
      window.MdHub.command
        .send('plataforma.prescricao', 'setPaciente', {
          nome: memedPatient.name,
          telefone: memedPatient.phone,
          idExterno: memedPatient.medicalRecord,
        })
        .then(function () {
          // Mostra o módulo de prescrição
          window.MdHub.module.show('plataforma.prescricao');
        });

      window.MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
        // / opção de excluir um paciente (obrigatório)
        deletePatient: false,
        // opção de remover/trocar o paciente (obrigatório)
        removePatient: false,
        // histórico de prescrições
        historyPrescription: false,
        // botão "Nova Prescrição"
        newPrescription: false,
        // botão "Opções Receituário"
        optionsPrescription: true,
        // opção de remover a prescrição no histórico
        removePrescription: false,
        // Esconde o formulário de edição do paciente
        editPatient: false,
        // opção para definir alergias para o paciente
        setPatientAllergy: true,
        // aba "Exames" do Autocomplete de medicamentos
        autocompleteExams: false,
        // aba "Industrializados" do Autocomplete de medicamentos
        autocompleteIndustrialized: true,
        // aba "Manipulados" do Autocomplete de medicamentos
        autocompleteManipulated: true,
        // aba "Composições" do Autocomplete de medicamentos
        autocompleteCompositions: true,
        // aba "Periféricos" do Autocomplete de medicamentos
        autocompletePeripherals: true,
        // Esconde o botão "Copiar para Prontuário" (que copia o conteúdo da prescrição para o clipboard)
        copyMedicalRecords: false,
        // Esconde o botão de fechar da prescrição
        buttonClose: true,
        // Habilita opção Nova Fórmula Manipulada no menu
        newFormula: false,
      });
      window.MdHub.command.send('plataforma.prescricao', 'getPrescricao').then(function () {
        window.MdHub.event.add('prescricaoImpressa', function (prescriptionData) {
          Axios.get(
            `${environment.MEMED_API_URL}/v1/prescricoes/${prescriptionData.prescricao.id}/url-document/full?token=${token}`
          ).then((response) => {
            const link = response.data.data[0].attributes.link;

            setSendMemed({
              module: moduleAccess,
              entityId: parseInt(LocalCache.getUser().entityId),
              atendimentoId: cpfMaks.format(memedPatient.medicalRecord),
              cellphone: cellphone.format(memedPatient.phone),
              prescriptionLink: link,
            });
          });
        });
      });
    }
  }, [memedPatient, token, sendPrescriptionLink, moduleAccess]);
  return {
    memedActive: ready,
    memedPatient,
    setMemedPatient,
  };
};

export default useMemed;

import Skeleton from 'react-loading-skeleton';

import * as Styled from './styled';

type Props = {
  columns: number;
  rows: number;
};

const TableSkeleton = ({ columns, rows }: Props) => {
  return (
    <>
      {[...Array(rows)].map((_, rowIndex) => (
        <Styled.Tr key={rowIndex}>
          <td colSpan={columns}>
            <Skeleton height={20} />
          </td>
        </Styled.Tr>
      ))}
      <tr
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          padding: '0',
          margin: '-1px',
          overflow: 'hidden',
          clip: 'rect(0,0,0,0)',
          whiteSpace: 'nowrap',
          border: '0',
        }}
      >
        <td>Carregando</td>
      </tr>
    </>
  );
};

export default TableSkeleton;

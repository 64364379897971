import { useCallback, useContext, useState } from 'react';

import { mdiCloseThick } from '@mdi/js';
import { SearchIcon } from '@chakra-ui/icons'

// import { getEntityId, ENTITY_ID } from 'Utils/Config/SectorConfig';
import ModuleContext from 'Context/moduleContext';

// import Filters from './Filters';
import Styled from './styled';
import { InfoIcon, InfoIconTeleAtendimento } from './components';
import Filters from './Filters';
import { IconButton, Input } from '@chakra-ui/react';

export const Search = ({ keyword }) => {
  const { setSearchKeyword, moduleAccess } = useContext(ModuleContext);

  const [value, setValue] = useState(keyword);

  const search = useCallback((e) => {
    e.preventDefault();
    setSearchKeyword(value ? value : null);
  }, [value, setSearchKeyword]);

  const clearSearch = useCallback(() => {
    setValue('');
    setSearchKeyword('');
  }, [setSearchKeyword]);

  return (
    <Styled.FilterContainer>
      {moduleAccess !== 'remoteatendimento' ? <InfoIcon /> : <InfoIconTeleAtendimento />}
      <form style={{ display: 'flex' }} onSubmit={search}>
        <Styled.SearchContainer>
          <Input
            borderRightRadius={0}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder={'busque por palavra-chave'}
            type="text"
            value={value || ''}
            maxW={'230px'}
            color={'#707070'}
            variant={'filled'}
            _placeholder={{ color: '#aaaaaa' }}
          />
          {value && (
            <Styled.ClearSearchIcon
              onClick={clearSearch}
              path={mdiCloseThick}
              color="#0071eb"
              size={0.8}
            />
          )}
        </Styled.SearchContainer>
        <IconButton
          borderLeftRadius={0}
          mr={'16px'}
          aria-label='Pesquisar'
          icon={<SearchIcon />}
          type='submit'
        />
      </form>
      <Filters />
    </Styled.FilterContainer>
  );
};

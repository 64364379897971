import {
  mdiViewDashboardEditOutline,
  mdiViewDashboardEdit,
  mdiViewList,
  mdiViewListOutline,
} from '@mdi/js';
import { useHistory, useParams } from 'react-router-dom';

import routes from 'routes';
import router from 'Utils/Helpers/router';

import { Option, Register, Import } from './components';

const Patients = () => {
  const { type } = useParams();
  const history = useHistory();

  return (
    <>
      <div className="row">
        <div className="d-flex mx-2">
          <Option
            value="individual"
            label="Cadastro manual"
            icon={{
              path: mdiViewDashboardEditOutline,
              activePath: mdiViewDashboardEdit,
            }}
            active={type === 'individual'}
            onClick={(value) => history.replace(router.to(routes.PATIENTS.path, { type: value }))}
          />
          <Option
            value="csv"
            label="Cadastro em massa"
            icon={{
              path: mdiViewListOutline,
              activePath: mdiViewList,
            }}
            active={type === 'csv'}
            onClick={(value) => history.replace(router.to(routes.PATIENTS.path, { type: value }))}
          />
        </div>
      </div>
      <div className="row">
        {type === 'individual' ? (
          <div className="col-md-6">
            <Register />
          </div>
        ) : null}
        {type === 'csv' ? (
          <div className="col-md-12">
            <Import />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Patients;

import { useRef, useEffect } from 'react';

const useEventListener = (eventName, handler, element = window) => {
  const savedHander = useRef();

  useEffect(() => {
    savedHander.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event) => savedHander.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export default useEventListener;

import { gql } from '@apollo/client';

export const SEND_ACTIVE_CONTACT_MESSAGE = gql`
  mutation SendActiveContactMessage(
    $template: String!
    $cellphone: String!
    $entityId: Int!
    $medicalRecord: String!
    $module: String!
    $user: String!
    $variables: String!
    $nextServiceStep: String
    $nextSector: String
    $application: String
    $uuid: String
    $name: String
    $complaint: String
    $triagestep: String
    $text: String
  ) {
    sendActiveContactMessage(
      template: $template
      cellphone: $cellphone
      entityId: $entityId
      medicalRecord: $medicalRecord
      module: $module
      user: $user
      variables: $variables
      nextServiceStep: $nextServiceStep
      nextSector: $nextSector
      application: $application
      uuid: $uuid
      name: $name
      complaint: $complaint
      triagestep: $triagestep
      text: $text
    )
  }
`;

export const ADD_HANDOVER = gql`
  mutation AddHandover(
    $senderId: String!
    $entityId: Int!
    $medicalRecord: String!
    $module: String!
    $email: String!
    $usuario: String
    $attendantCpf: String
  ) {
    addHandover(
      entityId: $entityId
      senderId: $senderId
      medicalRecord: $medicalRecord
      module: $module
      email: $email
      usuario: $usuario
      attendantCpf: $attendantCpf
    )
  }
`;

export const UPDATE_HANDOVER = gql`
  mutation UpdateHandover(
    $entityId: Int!
    $medicalRecord: String!
    $module: String!
    $email: String
  ) {
    updateHandover(
      entityId: $entityId
      medicalRecord: $medicalRecord
      module: $module
      email: $email
    )
  }
`;

export const REMOVE_HANDOVER = gql`
  mutation RemoveHandover(
    $senderId: String!
    $entityId: Int!
    $medicalRecord: String!
    $module: String!
    $usuario: String
    $attendantCpf: String
  ) {
    removeHandover(
      entityId: $entityId
      senderId: $senderId
      medicalRecord: $medicalRecord
      module: $module
      usuario: $usuario
      attendantCpf: $attendantCpf
    )
  }
`;

export const SUBMIT_HANDOVER = gql`
  mutation SubmitHandover($senderId: String!, $text: String!) {
    submitHandover(senderId: $senderId, text: $text)
  }
`;

export const OPEN_WINDOW = gql`
  mutation OperateWindow(
    $entityId: Int!
    $senderId: String!
    $module: String!
    $medicalRecord: String!
    $operation: Boolean
  ) {
    operateWindow(
      entityId: $entityId
      senderId: $senderId
      module: $module
      medicalRecord: $medicalRecord
      operation: $operation
    )
  }
`;

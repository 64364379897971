import { useContext, useEffect } from 'react';
import Container from '../../Components/TeleAtendimento/Container';
import Data from '../../Components/TeleAtendimento/Data';
import { MenuHistory } from '../../Components/TeleAtendimento/Menu';
import {
  SectorRoutes,
  SectorDictionaryHistory,
} from '../../Utils/Dictionary/RemoteAtendimento/TeleAtendimento';
import ModuleContext from 'Context/moduleContext';

export default function History() {
  const { moduleDispatch } = useContext(ModuleContext);

  useEffect(() => {
    moduleDispatch({
      type: 'CHANGE_STEP',
      stepActive: SectorDictionaryHistory.TELEATENDIMENTO_HISTORY.slug,
    });
  }, [moduleDispatch]);

  return (
    <Container>
      <MenuHistory>
        <Data sector={SectorRoutes.history} />
      </MenuHistory>
    </Container>
  );
}

import styled from 'styled-components';

export const Button = styled.button`
  width: 65px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #ececec;
  border: none;
  outline: none;
  color: #707070;
  font: normal normal medium 14px/17px Roboto;
  &:hover {
    filter: brightness(0.98);
  }
  margin-right: 8px;
`;

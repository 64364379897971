import * as Types from '../../../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ServiceStepsChatCountQueryVariables = Types.Exact<{
  module: Types.Scalars['String'];
  entityId: Types.Scalars['Int'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.DocumentsFilterInput>;
}>;


export type ServiceStepsChatCountQuery = { __typename?: 'Query', serviceStepsChatCount?: { __typename?: 'ServiceStepsChatCount', DASHBOARD?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, TELEMEDICINE?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, TELEMEDICINESCHEDULE?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, TELEMEDICINEQUEUE?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, CHAT?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, HISTORY?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, MONITORING?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, IN_PROGRESS?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, SCHEDULE?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, TRANSFERRED?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, CHAT_WAITING?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, CHAT_PROGRESS?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, CHAT_ADJOURNED?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null, ELIGIBLE?: { __typename?: 'StepsChatCount', unreadChats?: Array<string | null> | null, patientCount?: number | null } | null } | null };

export type DocumentsCountQueryVariables = Types.Exact<{
  entityId: Types.Scalars['Int'];
  module: Types.Scalars['String'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.DocumentsFilterInput>;
}>;


export type DocumentsCountQuery = { __typename?: 'Query', documentCount?: { __typename?: 'DocumentCount', DASHBOARD?: number | null, HISTORY?: number | null, INTENTIONS?: number | null, ELIGIBLE?: number | null, CHECKOUT?: number | null, MONITORING?: number | null, TOWN_MONITORING?: number | null, FORWARDED?: number | null, GENERALIST_TRANSFERRED?: number | null, GENERALIST_IN_PROGRESS?: number | null, GENERALIST_QUEUE?: number | null, GENERALIST_MONITORING?: number | null, IN_PROGRESS?: number | null, TRANSFERRED?: number | null, SCHEDULE?: number | null, TELEMEDICINE?: number | null, TELEMEDICINESCHEDULE?: number | null, TELEMEDICINEQUEUE?: number | null, REMOTEATENDIMENTO?: number | null, MAINSTEP?: number | null, CHAT?: number | null, CHAT_WAITING?: number | null, CHAT_PROGRESS?: number | null, CHAT_ADJOURNED?: number | null } | null };


export const ServiceStepsChatCountDocument = gql`
    query ServiceStepsChatCount($module: String!, $entityId: Int!, $search: String, $filter: DocumentsFilterInput) {
  serviceStepsChatCount(
    module: $module
    entityId: $entityId
    search: $search
    filter: $filter
  ) {
    DASHBOARD {
      unreadChats
      patientCount
    }
    TELEMEDICINE {
      unreadChats
      patientCount
    }
    TELEMEDICINESCHEDULE {
      unreadChats
      patientCount
    }
    TELEMEDICINEQUEUE {
      unreadChats
      patientCount
    }
    CHAT {
      unreadChats
      patientCount
    }
    HISTORY {
      unreadChats
      patientCount
    }
    MONITORING {
      unreadChats
      patientCount
    }
    IN_PROGRESS {
      unreadChats
      patientCount
    }
    SCHEDULE {
      unreadChats
      patientCount
    }
    TRANSFERRED {
      unreadChats
      patientCount
    }
    CHAT_WAITING {
      unreadChats
      patientCount
    }
    CHAT_PROGRESS {
      unreadChats
      patientCount
    }
    CHAT_ADJOURNED {
      unreadChats
      patientCount
    }
    ELIGIBLE {
      unreadChats
      patientCount
    }
  }
}
    `;

/**
 * __useServiceStepsChatCountQuery__
 *
 * To run a query within a React component, call `useServiceStepsChatCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceStepsChatCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceStepsChatCountQuery({
 *   variables: {
 *      module: // value for 'module'
 *      entityId: // value for 'entityId'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useServiceStepsChatCountQuery(baseOptions: Apollo.QueryHookOptions<ServiceStepsChatCountQuery, ServiceStepsChatCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceStepsChatCountQuery, ServiceStepsChatCountQueryVariables>(ServiceStepsChatCountDocument, options);
      }
export function useServiceStepsChatCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceStepsChatCountQuery, ServiceStepsChatCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceStepsChatCountQuery, ServiceStepsChatCountQueryVariables>(ServiceStepsChatCountDocument, options);
        }
export type ServiceStepsChatCountQueryHookResult = ReturnType<typeof useServiceStepsChatCountQuery>;
export type ServiceStepsChatCountLazyQueryHookResult = ReturnType<typeof useServiceStepsChatCountLazyQuery>;
export type ServiceStepsChatCountQueryResult = Apollo.QueryResult<ServiceStepsChatCountQuery, ServiceStepsChatCountQueryVariables>;
export const DocumentsCountDocument = gql`
    query DocumentsCount($entityId: Int!, $module: String!, $search: String, $filter: DocumentsFilterInput) {
  documentCount(
    entityId: $entityId
    module: $module
    search: $search
    filter: $filter
  ) {
    DASHBOARD
    HISTORY
    INTENTIONS
    ELIGIBLE
    CHECKOUT
    MONITORING
    TOWN_MONITORING
    FORWARDED
    GENERALIST_TRANSFERRED
    GENERALIST_IN_PROGRESS
    GENERALIST_QUEUE
    GENERALIST_MONITORING
    IN_PROGRESS
    TRANSFERRED
    SCHEDULE
    TELEMEDICINE
    TELEMEDICINESCHEDULE
    TELEMEDICINEQUEUE
    REMOTEATENDIMENTO
    MAINSTEP
    CHAT
    CHAT_WAITING
    CHAT_PROGRESS
    CHAT_ADJOURNED
  }
}
    `;

/**
 * __useDocumentsCountQuery__
 *
 * To run a query within a React component, call `useDocumentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsCountQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      module: // value for 'module'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDocumentsCountQuery(baseOptions: Apollo.QueryHookOptions<DocumentsCountQuery, DocumentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsCountQuery, DocumentsCountQueryVariables>(DocumentsCountDocument, options);
      }
export function useDocumentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsCountQuery, DocumentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsCountQuery, DocumentsCountQueryVariables>(DocumentsCountDocument, options);
        }
export type DocumentsCountQueryHookResult = ReturnType<typeof useDocumentsCountQuery>;
export type DocumentsCountLazyQueryHookResult = ReturnType<typeof useDocumentsCountLazyQuery>;
export type DocumentsCountQueryResult = Apollo.QueryResult<DocumentsCountQuery, DocumentsCountQueryVariables>;
import {
  forwardRef,
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  Fragment,
} from 'react';
import { AnimatePresence } from 'framer-motion';
import { mergeRefs } from 'react-laag';
import { mdiMessage } from '@mdi/js';
import Icon from '@mdi/react';

import ChatContext from 'Components/Chat/context';
import { springSlide } from 'Utils/animations';
import { sizes } from 'Components/Chat/types';

import * as Styled from './styled';
import { Message } from './components';
import { usePinScroll } from 'Hooks';
import Spinner from 'Components/Spinner';
import { formatChatDay } from 'Utils/Helpers/dateFnsHelper';
import Divider from 'Components/Divider';

const MessageList = forwardRef((_props, ref) => {
  const [isBottom, handleIsBottom] = useState(true);
  const [currentView, handleCurrentView] = useState(0);
  const { messages, expanded, senderId, handleNextPage, isLastPage, loading } =
    useContext(ChatContext);
  const bottomRef = useRef(null);
  const { containerRef } = usePinScroll({
    elementsQuery: '[id]',
    isPin: currentView,
  });

  const height = expanded ? sizes().expanded.inner : sizes().normal.inner;

  const handleScroll = (e) => {
    handleIsBottom(e.target?.scrollHeight - e.target?.scrollTop <= (expanded ? 430 : 320));

    if (e.target?.scrollTop === 0 && !isLastPage && !loading) {
      handleCurrentView((prev) => prev + 1);
      handleNextPage();
    }
  };

  const scrollToBottom = useCallback(() => {
    bottomRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, []);

  useEffect(() => {
    if (isBottom) {
      scrollToBottom();
    }
  }, [isBottom, messages, scrollToBottom, expanded]);

  useImperativeHandle(
    ref,
    () => ({
      scrollToBottom,
      height,
    }),
    [scrollToBottom, height]
  );

  return (
    <Styled.Container
      height={height}
      ref={mergeRefs(ref, containerRef)}
      onScroll={handleScroll}
      initial={{ height }}
      animate={{ height }}
      transition={springSlide}
    >
      {loading && (
        <Styled.Loading>
          <Spinner />
        </Styled.Loading>
      )}

      {!!messages.length && (
        <AnimatePresence initial={false}>
          {messages.map((message, index) => (
            <Fragment key={message?.timestamp}>
              {index === 0 ||
              formatChatDay(message?.timestamp) !==
                formatChatDay(messages[index - 1]?.timestamp) ? (
                <Divider>{formatChatDay(message?.timestamp)}</Divider>
              ) : null}
              <Message
                id={message?.timestamp}
                file={message?.file && message?.file}
                {...message}
                {...{
                  event: message.event !== 'user' && message.handover ? 'handover' : message.event,
                }}
              />
            </Fragment>
          ))}
          <div id={`${senderId}-bottom-ref`} ref={bottomRef} />
        </AnimatePresence>
      )}

      {!loading && !messages.length && (
        <Styled.NoMessages>
          <Icon path={mdiMessage} size={2} style={{ marginBottom: 8 }} />
          Para iniciar uma conversa com este paciente, você precisa enviar um contato ativo pelo
          ícone de ações.
        </Styled.NoMessages>
      )}
    </Styled.Container>
  );
});

MessageList.displayName = 'MessageList';

export default MessageList;

import { checkboxTheme } from './checkbox';

const baseStyleControl = (props) => {
  const controlStyle = checkboxTheme.baseStyle(props)?.control;

  return {
    ...controlStyle,
    borderRadius: 'full',
    _checked: {
      ...controlStyle?.['_checked'],
      _before: {
        content: '""',
        display: 'inline-block',
        pos: 'relative',
        w: '50%',
        h: '50%',
        borderRadius: '50%',
        bg: 'currentColor',
      },
    },
  };
};

const baseStyle = (props) => ({
  label: checkboxTheme.baseStyle?.(props).label,
  container: checkboxTheme.baseStyle?.(props).container,
  control: baseStyleControl(props),
});

const sizes = {
  md: {
    control: { w: 4, h: 4 },
    label: { fontSize: 'md' },
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' },
  },
  sm: {
    control: { width: 3, height: 3 },
    label: { fontSize: 'sm' },
  },
};

export const radioTheme = {
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
};

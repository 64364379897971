import { getColor } from '@chakra-ui/theme-tools';

const baseStyle = {
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
  },
};

const size = {
  lg: {
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: 'md',
  },
  md: {
    fontSize: 'md',
    px: 2.5,
    h: 10,
    borderRadius: 'md',
  },
  sm: {
    fontSize: 'sm',
    px: 3,
    h: 8,
    borderRadius: 'sm',
  },
  xs: {
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: 'sm',
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
};

function getDefaults(props: Record<string, string>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || 'blue.500',
    errorBorderColor: ec || 'red.400',
  };
}

const variantOutline = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'inherit',
      _hover: {
        borderColor: getColor(theme, fc),

        _disabled: {
          borderColor: 'inherit',
          bg: 'inherit',
          opacity: 0.4,
          cursor: 'not-allowed',
        },
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: getColor(theme, fc),
        boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
      },
    },
    addon: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'gray.100',
    },
  };
};

const variantFilled = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '1px solid',
      borderColor: 'gray.50',
      bg: 'gray.50',
      _hover: {
        bg: 'gray.100',

        _disabled: {
          borderColor: 'gray.50',
          bg: 'gray.50',
          opacity: 0.4,
          cursor: 'not-allowed',
        },
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
      _focusVisible: {
        bg: 'transparent',
        borderColor: getColor(theme, fc),
      },
    },
    addon: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: 'gray.50',
    },
  };
};

const variantFlushed = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      borderBottom: '1px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0px 1px 0px 0px ${getColor(theme, ec)}`,
      },
      _focusVisible: {
        borderColor: getColor(theme, fc),
        boxShadow: `0px 1px 0px 0px ${getColor(theme, fc)}`,
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
    addon: {
      borderBottom: '2px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
    },
  };
};

const variantUnstyled = {
  field: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
  addon: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
  },
};

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
};

export const inputTheme = {
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline',
    defaultProps: { colorScheme: 'blue' },
  },
};

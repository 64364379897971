import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px 24px 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 42px;
  background: #fff;
  box-shadow: 0px 3px 15px #0909090a;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #ececec;
`;

export const FilterOption = styled.button<{ isSelected: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;

  background-color: #ececec;
  border: none;
  border-radius: 10px;

  padding: 4px 8px;

  cursor: pointer;

  &:hover {
    transition: 0.3s;

    background-color: #a9a9a9;
  }

  &:active {
    background-color: #0071eb;
    color: #fff;
  }

  ${(props) =>
    props.isSelected &&
    css`
      background-color: #0071eb;
      color: #fff;

      &:hover {
        transition: 0.3s;

        background-color: #0071eb;
      }
    `}
`;

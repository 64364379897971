export const INIT_STATE = {
  senderId: '',
  statusHandOver: [],
  events: [],
  values: '',
};

export const useReduce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SENDER_ID_EVENTS':
      return { ...state, senderId: action.payload?.senderId };
    case 'STATUS_HANDOVER':
      return { ...state, statusHandOver: action.payload };
    case 'ADD_EVENTS':
      return { ...state, events: action.payload };
    case 'SOCKET_EVENTS':
      return { events: [...state.events, action.payload] };
    case 'ADD_VALUE':
      return { ...state, values: action.payload };
    default:
      return state;
  }
};

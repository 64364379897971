import { gql } from '@apollo/client';

import { ModulesDataFields } from './module';

export const UserFields = gql`
  fragment UserFields on User {
    firstName
    id
    lastName
    email
    entityId
    roleId
    cpf
    moduleData {
      ...ModulesDataFields
    }
  }
  ${ModulesDataFields}
`;

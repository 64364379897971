import Tooltip from 'Components/Tooltip';

import * as Styled from './styled';

const InfoIcon = () => (
  <Tooltip
    text={
      <Styled.ContainerHelp>
        Em sua pesquisa você pode buscar resultados para:{' '}
        <Styled.List>
          <Styled.ListItem>● Nome dos pacientes</Styled.ListItem>
          <Styled.ListItem>● Fluxos da queixa principal</Styled.ListItem>
          <Styled.ListItem>● Tags associadas</Styled.ListItem>
          <Styled.ListItem>● UBS de origem</Styled.ListItem>
          <Styled.ListItem>● Telefone</Styled.ListItem>
          <Styled.ListItem>● Status</Styled.ListItem>
          <Styled.ListItem>● Grupos</Styled.ListItem>
          <Styled.ListItem>● Sintomas</Styled.ListItem>
          <Styled.ListItem>● Cor dos alertas</Styled.ListItem>
        </Styled.List>
      </Styled.ContainerHelp>
    }
    position="bottom"
  >
    <Styled.Container />
  </Tooltip>
);

export default InfoIcon;

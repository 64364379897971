import { gql } from '@apollo/client';

import { EntityInitialWithModuleFields } from 'repository/fragments';

export const GET_ENTITY_AND_MODULES = gql`
  query ($entityId: Int!) {
    entity(id: $entityId) {
      ...EntityInitialWithModuleFields
    }
  }
  ${EntityInitialWithModuleFields}
`;

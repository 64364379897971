import { useContext, useState, useEffect, useCallback } from 'react';
import { App, Main } from './styles/styles';

import ToolBar from './components/ToolBar';
import Footer from './components/Footer';
import ConversationMessages from './components/ConversationMessages';
import environment from 'Utils/Dictionary/environment';
import useSocket from 'Hooks/useSocket';

import { api } from './utils/axios';
import { Context } from './context';

let chatMessages;

const Chat = (props) => {
  const { data } = props;
  const {
    URI_SOCKET,
    URI_REQ_HANOVER,
    URI_SEND_HANDOVER,
    state: { events },
    dispatch,
  } = useContext(Context);

  const [socket] = useSocket(URI_SOCKET, { autoConnect: false });
  const [disabledButton, setDisabledButton] = useState('visible');
  const [textSend] = useState(
    'Podemos ajudar com mais alguma informação? O seu atendimento foi encerrado, mas não se preocupe! O assistente virtual da Nissan continuará ajudando caso queira retornar. Digite *AVALIAR ATENDIMENTO* para classificar como foi seu atendimento. Estamos sempre à disposição. Até logo!'
  );

  const StatusHandOver = useCallback(() => {
    (async () => {
      const handover = await api(
        // eslint-disable-next-line no-undef
        URI_REQ_HANOVER
      ).get(`/handover?filter[where][senderId]=${data.senderId}`);

      if (handover.status === 200 && handover.data.length > 0) {
        if (data.sector === 'ALL' || data.sector === 'HISTORY' || data.sector === 'CLOSED') {
          setDisabledButton('none');
          setHanover('HandOver');
          setDisabled(true);
          setInputPlaceholder('Solicite o handover para interagir com o usuário');
          dispatch({ type: 'ADD_VALUE', payload: 'None' });
        } else {
          setDisabledButton('visible');
          setHanover('Bot');
          setDisabled(false);
          setInputPlaceholder('Mensagem');
          dispatch({ type: 'ADD_VALUE', payload: 'None' });
        }
      } else {
        if (data.sector === 'ALL' || data.sector === 'HISTORY' || data.sector === 'CLOSED') {
          setDisabledButton('none');
        } else {
          setDisabledButton('visible');
          setHanover('HandOver');
          setDisabled(true);
          setInputPlaceholder('Solicite o handover para interagir com o usuário');
          dispatch({ type: 'ADD_VALUE', payload: 'None' });
        }
      }
    })();
  }, [data, URI_REQ_HANOVER, dispatch]);

  useEffect(() => {
    StatusHandOver();
  }, [StatusHandOver]);

  useEffect(() => {
    if (data.senderId) {
      dispatch({ type: 'SENDER_ID_EVENTS', payload: data });
    }
  }, [data, dispatch]);

  const SocketHandOver = useCallback(() => {
    (async () => {
      // eslint-disable-next-line no-undef
      await socket.connect();
      await socket.on(`${data.senderId}`, (rows) => {
        dispatch({ type: 'SOCKET_EVENTS', payload: rows });
      });
    })();
  }, [data.senderId, socket, dispatch]);

  useEffect(() => {
    SocketHandOver();
  }, [SocketHandOver]);

  useEffect(() => {
    sleep(888).then(() => {
      chatMessages && (chatMessages.scrollTop = chatMessages.scrollHeight);
    });
  }, [events]);

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const [handover, setHanover] = useState('HandOver');
  const [disabled, setDisabled] = useState(true);

  const [inputPlaceholder, setInputPlaceholder] = useState(
    'Solicite o handover para interagir com o usuário'
  );

  const submit = (value) => {
    (async () => {
      if (value && value?.trim().length > 0) {
        await api(
          // eslint-disable-next-line no-undef
          URI_SEND_HANDOVER
        ).post(`/webhooks/whatsapp/webhook?token=${environment.RASA_AUTH}`, {
          messages: [
            {
              from: data.senderId,
              text: {
                body: value,
              },
              timestamp: Date.now(),
              type: 'liveagent',
            },
          ],
        });
      }
    })();
  };

  const requestHandover = (props) => {
    (async () => {
      const res = await api(
        // eslint-disable-next-line no-undef
        URI_REQ_HANOVER
      ).post('/handover', {
        senderId: data.senderId,
      });

      if (res.status === 200) {
        setHanover(props);
        setDisabled(false);
        dispatch({ type: 'ADD_VALUE', payload: 'None' });
        setInputPlaceholder('Mensagem');
      }
    })();
  };

  const returnHandover = (props) => {
    (async () => {
      const handover = await api(
        // eslint-disable-next-line no-undef
        URI_REQ_HANOVER
      ).get(`/handover?filter[where][senderId]=${data.senderId}`);

      // eslint-disable-next-line
      for (const [, value] of Object.entries(handover.data)) {
        api(
          // eslint-disable-next-line no-undef
          URI_REQ_HANOVER
        )
          .delete(`/handover/${value.id}`)
          .then((res) => {
            if (res.status === 204) {
              setHanover(props);
              setDisabled(true);
              dispatch({ type: 'ADD_VALUE', payload: 'None' });
              setInputPlaceholder('Solicite o handover para interagir com o usuário');
              submit(textSend);
            }
          });
      }
    })();
  };

  return (
    <App>
      <ToolBar
        data={data}
        requestHandover={requestHandover}
        returnHandover={returnHandover}
        handover={handover}
        disabled={disabledButton}
      />
      <Main>
        <ConversationMessages
          data={data}
          events={events}
          chatMessagesRef={(ref) => {
            if (ref !== null) {
              chatMessages = ref;
            }
          }}
        />
      </Main>
      <Footer inputPlaceholder={inputPlaceholder} disabled={disabled} submit={submit} />
    </App>
  );
};

export default Chat;

import { Redirect, Route } from 'react-router-dom';
import useCache from 'Hooks/useCache';

type Props = {
  component: any;
  location?: Record<string, any>;
};

const PublicRoute = ({ component: Component, ...rest }: Props) => {
  const [user] = useCache('user');
  const [moduleAccess] = useCache('module_access');

  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.token ? (
          <Redirect
            to={{
              pathname: `/${moduleAccess}/all`,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;

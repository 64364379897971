import { useState } from 'react';
import Modal from 'Components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ModalResendLinkActions } from 'store/ducks/modal_resend_link';
import { MainContent, Message, ConfirmButton, CancelButton, Footer } from './styled';

import { Toast } from 'Components';
import { toast } from 'react-toastify';

export default function ModalResendLink() {
  const [loading, setLoading] = useState(false);

  const { isOpen, submit } = useSelector(({ modal_resend_link: { isOpen, submit } }) => ({
    isOpen,
    submit,
  }));

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(ModalResendLinkActions.modalResendLinkReset());
  };

  function handlesubmit() {
    try {
      setLoading(true);
      submit();
      toast(<Toast type="success" title="Link reenviado com sucesso!" />);
    } catch (error) {
      toast(
        <Toast type="error" title="Não foi possível reenvier o link" subtitle={error.message} />
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} width="400px">
      <MainContent>
        <Message>
          O paciente já recebeu o link. Você tem certeza que gostaria de enviar novamente?
        </Message>
      </MainContent>
      <Footer>
        <CancelButton onClick={handleClose} className="text">
          Cancelar
        </CancelButton>
        <ConfirmButton
          onClick={handlesubmit}
          disabled={loading}
          // style={{ height: 32 }}
          className="contained"
        >
          {loading ? 'Enviando link' : 'Enviar'}
        </ConfirmButton>
      </Footer>
    </Modal>
  );
}

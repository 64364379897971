export const MonitoringDictionary = {
  started: {
    value: 'INICIADO',
    color: '#333',
    colorDefault: '#fff',
    borderColor: '#0071eb',
    backgroundColor: '#444444',
    backgroundColorDefault: '#fff',
    marginDefault: '0px',
    margin: '12px 0px 0px 0px',
    title: 'Acompanhamento Iniciado',
    text: 'Respondeu com SIM mensagem de aceite do programa',
    generalist: 'Acompanhamento Iniciado',
    covid: 'Acompanhamento Iniciado',
  },
  on_hold: {
    value: 'PAUSADO',
    color: '#333',
    colorDefault: '#fff',
    borderColor: '#EC2C47',
    backgroundColor: '#444444',
    backgroundColorDefault: '#fff',
    marginDefault: '0px',
    margin: '12px 0px 0px 0px',
    title: 'Acompanhamento Pausado - Ocorrência no Módulo ',
    text: '',
    generalist: 'Acompanhamento Pausado - Módulo ',
    covid: 'Acompanhamento Pausado - Módulo ',
  },
  resumed: {
    value: 'RETOMADO',
    color: '#333',
    colorDefault: '#fff',
    borderColor: '#F6C500',
    backgroundColor: '#444444',
    backgroundColorDefault: '#fff',
    marginDefault: '0px',
    margin: '12px 0px 0px 0px',
    title: 'Acompanhamento Retomado',
    text: 'Acompanhamento retomado para a etapa de onde parou.',
    generalist: 'Acompanhamento Retomado',
    covid: 'Acompanhamento Retomado',
  },
  true: {
    value: 'SIM',
    color: '#333',
    colorDefault: '#fff',
    borderColor: '#0071eb',
    backgroundColor: '#444444',
    backgroundColorDefault: '#fff',
    marginDefault: '0px',
    margin: '12px 0px 0px 0px',
    title: 'Acompanhamento Iniciado',
    text: 'Respondeu com SIM mensagem de aceite do programa',
    generalist: 'Acompanhamento Iniciado',
    covid: 'Acompanhamento Iniciado',
  },
  false: {
    value: 'NÃO',
    color: '#333',
    colorDefault: '#fff',
    borderColor: '#0071eb',
    backgroundColor: '#444444',
    backgroundColorDefault: '#fff',
    marginDefault: '0px',
    margin: '12px 0px 0px 0px',
    title: 'Acompanhamento Iniciado',
    text: 'Respondeu com SIM mensagem de aceite do programa',
    generalist: 'Acompanhamento Iniciado',
    covid: 'Acompanhamento Iniciado',
  },
};

import * as Types from '../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTeleconsultationScheduleSlotsQueryVariables = Types.Exact<{
  entityId: Types.Scalars['Int'];
  date: Types.Scalars['String'];
  module: Types.Scalars['String'];
}>;


export type GetTeleconsultationScheduleSlotsQuery = { __typename?: 'Query', getTeleconsultationScheduleSlots?: Array<{ __typename?: 'TeleconsultationSlots', startTime?: any | null, endTime?: any | null, scheduledConsultations?: number | null } | null> | null };


export const GetTeleconsultationScheduleSlotsDocument = gql`
    query getTeleconsultationScheduleSlots($entityId: Int!, $date: String!, $module: String!) {
  getTeleconsultationScheduleSlots(
    entityId: $entityId
    date: $date
    module: $module
  ) {
    startTime
    endTime
    scheduledConsultations
  }
}
    `;

/**
 * __useGetTeleconsultationScheduleSlotsQuery__
 *
 * To run a query within a React component, call `useGetTeleconsultationScheduleSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeleconsultationScheduleSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeleconsultationScheduleSlotsQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      date: // value for 'date'
 *      module: // value for 'module'
 *   },
 * });
 */
export function useGetTeleconsultationScheduleSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetTeleconsultationScheduleSlotsQuery, GetTeleconsultationScheduleSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeleconsultationScheduleSlotsQuery, GetTeleconsultationScheduleSlotsQueryVariables>(GetTeleconsultationScheduleSlotsDocument, options);
      }
export function useGetTeleconsultationScheduleSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeleconsultationScheduleSlotsQuery, GetTeleconsultationScheduleSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeleconsultationScheduleSlotsQuery, GetTeleconsultationScheduleSlotsQueryVariables>(GetTeleconsultationScheduleSlotsDocument, options);
        }
export type GetTeleconsultationScheduleSlotsQueryHookResult = ReturnType<typeof useGetTeleconsultationScheduleSlotsQuery>;
export type GetTeleconsultationScheduleSlotsLazyQueryHookResult = ReturnType<typeof useGetTeleconsultationScheduleSlotsLazyQuery>;
export type GetTeleconsultationScheduleSlotsQueryResult = Apollo.QueryResult<GetTeleconsultationScheduleSlotsQuery, GetTeleconsultationScheduleSlotsQueryVariables>;
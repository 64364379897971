import styled, { css } from 'styled-components';
import IconBase from '@mdi/react';
import { Menu as MenuBase, MenuItem as MenuItemBase } from '@szhsin/react-menu';

export const Container = styled.div.attrs((props) => ({
  active: props.active,
  activeColor: props.activeColor,
}))`
  padding: 1rem;
  cursor: pointer;
  display: block;

  & span {
    display: block;
    border-radius: 5px;
    width: 19px;
    height: 2px;
    margin-bottom: 5px;
    transition: background-color 0.2s ease;
    background: #444444;
  }

  ${({ active, activeColor }) => css`
    & span {
      background: ${active ? activeColor : '#444444'};
    }
  `}
`;

export const Menu = styled(MenuBase)`
  margin-top: -15px;
  margin-right: 10px;
  padding: 0px;
  width: 190px;
  background: white;
  color: #444444;
  border-radius: 5px;
  box-shadow: 0px 3px 20px #00000029;
`;

export const MenuItem = styled(MenuItemBase)`
  background: white;
  color: #444444;
  padding: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border-radius: 5px;

  &:hover {
    background: #f4f5f7;
  }

  &:active {
    color: #444444;
  }
`;

export const Icon = styled(IconBase)`
  margin-right: 8px;
`;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type Filter = {
  options: string[];
  active: boolean;
};

type FiltersObject = {
  tags?: Filter;
  evolution?: Filter;
  healthCare?: Filter;
  status?: Filter;
  flow?: Filter;
  hasUnreadChat?: Filter;
};

/**
 * Define os filtros que serão usados
 * @returns filters
 */
export default function useDocumentFilters(): FiltersObject {
  const moduleFilters = useSelector(({ modules: { filters } }) => filters);

  const filters = useMemo<FiltersObject>(() => {
    const _filters = {};
    for (const key in moduleFilters) {
      if (moduleFilters[key].active === null && moduleFilters[key]?.selected?.length === 0) {
        continue;
      } else if (moduleFilters[key].active === false) {
        _filters[key] = {
          options: [],
          active: false,
        };
      } else {
        _filters[key] = {
          options: moduleFilters[key].selected,
          active: moduleFilters[key].active,
        };
      }
    }
    return _filters;
  }, [moduleFilters]);

  return filters;
}

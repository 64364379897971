import styled, { css } from 'styled-components';

type StyledProps = {
  hasZoomedIn?: boolean;
  isLoading?: boolean;
  imgScaleZoom?: number;
  translateX?: number;
  alignImageCenter?: boolean;
};

export const ModalImagem = styled.img<StyledProps>`
  ${({ hasZoomedIn, isLoading, imgScaleZoom, translateX, alignImageCenter }) => {
    return css`
      ${hasZoomedIn
        ? `
            transition: transform 0.3s;
            transform: scale(${imgScaleZoom}) translateX(${translateX}px);
            ${alignImageCenter && 'transform-origin: top;'}
            
          `
        : `
            max-height: calc(100vh - 355px);
            max-width: 680px;
          `}

      display: ${isLoading ? 'none' : 'inherit'};
      cursor: pointer;
    `;
  }}
`;

import { useContext, useState, useEffect } from 'react';
import LocalCache from '../Services/LocalCache';
import ModuleContext from '../Context/moduleContext';

export function useTelemedicineQueueEnabled() {
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const [isQueue, handleIsQueue] = useState(false);

  useEffect(() => {
    const [module] = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);

    handleIsQueue(module?.serviceStep?.isTelemedicineQueueEnabled === true);
  }, [moduleAccess, stepActive]);

  return isQueue;
}

import { gql } from '@apollo/client';

const EvolutionModel = {
  SET_EVOLUTION_SOAP: gql`
    mutation setEvolutionSoap(
      $entityId: Int!
      $module: String!
      $cpf: String!
      $soapFields: SoapFields!
      $cid: [String]
      $ciap: [String]
      $attendanceType: String
      $attendantCpf: String
      $userFirstName: String
      $userEmail: String
    ) {
      setEvolutionSoap(
        entityId: $entityId
        module: $module
        cpf: $cpf
        soapFields: $soapFields
        cid: $cid
        ciap: $ciap
        attendanceType: $attendanceType
        userFirstName: $userFirstName
        userEmail: $userEmail
        attendantCpf: $attendantCpf
      )
    }
  `,
  GET_CID_CLASSIFIERS: gql`
    query getCidClassifiers($cid: String!) {
      getCidClassifiers(cid: $cid) {
        id
        name
      }
    }
  `,
  GET_CIAP_CLASSIFIERS: gql`
    query getCiapClassifiers($ciap: String!) {
      getCiapClassifiers(ciap: $ciap) {
        id
        name
      }
    }
  `,
};

export default EvolutionModel;

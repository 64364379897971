import * as Types from '../../../data/types';

import { gql } from '@apollo/client';
import { ConversationsFieldsFragmentDoc } from '../../fragments/__generated__/handover.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBotConversationsQueryVariables = Types.Exact<{
  senderId: Types.Scalars['String'];
  page: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
  filter?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetBotConversationsQuery = { __typename?: 'Query', getBotConversations?: { __typename?: 'ConversationDocuments', totalPages: number, totalDocuments: number, conversations?: Array<{ __typename?: 'BotEvents', event?: string | null, timestamp?: string | null, text?: string | null, handover?: boolean | null, file?: { __typename?: 'FileBot', url?: string | null, type?: string | null, size?: string | null, name?: string | null } | null } | null> | null } | null };

export type GetHandoverQueryVariables = Types.Exact<{
  senderId: Types.Scalars['String'];
  entityId: Types.Scalars['Int'];
  module?: Types.InputMaybe<Types.Scalars['String']>;
  medicalRecord?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetHandoverQuery = { __typename?: 'Query', getHandover?: boolean | null };

export type GetActiveContactTemplatesQueryVariables = Types.Exact<{
  entityId: Types.Scalars['Int'];
  module: Types.Scalars['String'];
  sector: Types.Scalars['String'];
  variables: Types.ParamsContactTemplates;
  monitoring_type: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetActiveContactTemplatesQuery = { __typename?: 'Query', getActiveContactTemplates?: Array<{ __typename?: 'ActiveContactTemplates', name?: string | null, message?: string | null, variables?: string | null, category?: string | null, description?: string | null } | null> | null };


export const GetBotConversationsDocument = gql`
    query GetBotConversations($senderId: String!, $page: Int!, $limit: Int!, $filter: [String]) {
  getBotConversations(
    senderId: $senderId
    page: $page
    limit: $limit
    filter: $filter
  ) {
    ...ConversationsFields
  }
}
    ${ConversationsFieldsFragmentDoc}`;

/**
 * __useGetBotConversationsQuery__
 *
 * To run a query within a React component, call `useGetBotConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBotConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBotConversationsQuery({
 *   variables: {
 *      senderId: // value for 'senderId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetBotConversationsQuery(baseOptions: Apollo.QueryHookOptions<GetBotConversationsQuery, GetBotConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBotConversationsQuery, GetBotConversationsQueryVariables>(GetBotConversationsDocument, options);
      }
export function useGetBotConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBotConversationsQuery, GetBotConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBotConversationsQuery, GetBotConversationsQueryVariables>(GetBotConversationsDocument, options);
        }
export type GetBotConversationsQueryHookResult = ReturnType<typeof useGetBotConversationsQuery>;
export type GetBotConversationsLazyQueryHookResult = ReturnType<typeof useGetBotConversationsLazyQuery>;
export type GetBotConversationsQueryResult = Apollo.QueryResult<GetBotConversationsQuery, GetBotConversationsQueryVariables>;
export const GetHandoverDocument = gql`
    query GetHandover($senderId: String!, $entityId: Int!, $module: String, $medicalRecord: String) {
  getHandover(
    entityId: $entityId
    senderId: $senderId
    module: $module
    medicalRecord: $medicalRecord
  )
}
    `;

/**
 * __useGetHandoverQuery__
 *
 * To run a query within a React component, call `useGetHandoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandoverQuery({
 *   variables: {
 *      senderId: // value for 'senderId'
 *      entityId: // value for 'entityId'
 *      module: // value for 'module'
 *      medicalRecord: // value for 'medicalRecord'
 *   },
 * });
 */
export function useGetHandoverQuery(baseOptions: Apollo.QueryHookOptions<GetHandoverQuery, GetHandoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHandoverQuery, GetHandoverQueryVariables>(GetHandoverDocument, options);
      }
export function useGetHandoverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHandoverQuery, GetHandoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHandoverQuery, GetHandoverQueryVariables>(GetHandoverDocument, options);
        }
export type GetHandoverQueryHookResult = ReturnType<typeof useGetHandoverQuery>;
export type GetHandoverLazyQueryHookResult = ReturnType<typeof useGetHandoverLazyQuery>;
export type GetHandoverQueryResult = Apollo.QueryResult<GetHandoverQuery, GetHandoverQueryVariables>;
export const GetActiveContactTemplatesDocument = gql`
    query GetActiveContactTemplates($entityId: Int!, $module: String!, $sector: String!, $variables: ParamsContactTemplates!, $monitoring_type: [String]!) {
  getActiveContactTemplates(
    entityId: $entityId
    module: $module
    sector: $sector
    variables: $variables
    monitoring_type: $monitoring_type
  ) {
    name
    message
    variables
    category
    description
  }
}
    `;

/**
 * __useGetActiveContactTemplatesQuery__
 *
 * To run a query within a React component, call `useGetActiveContactTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveContactTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveContactTemplatesQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      module: // value for 'module'
 *      sector: // value for 'sector'
 *      variables: // value for 'variables'
 *      monitoring_type: // value for 'monitoring_type'
 *   },
 * });
 */
export function useGetActiveContactTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetActiveContactTemplatesQuery, GetActiveContactTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveContactTemplatesQuery, GetActiveContactTemplatesQueryVariables>(GetActiveContactTemplatesDocument, options);
      }
export function useGetActiveContactTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveContactTemplatesQuery, GetActiveContactTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveContactTemplatesQuery, GetActiveContactTemplatesQueryVariables>(GetActiveContactTemplatesDocument, options);
        }
export type GetActiveContactTemplatesQueryHookResult = ReturnType<typeof useGetActiveContactTemplatesQuery>;
export type GetActiveContactTemplatesLazyQueryHookResult = ReturnType<typeof useGetActiveContactTemplatesLazyQuery>;
export type GetActiveContactTemplatesQueryResult = Apollo.QueryResult<GetActiveContactTemplatesQuery, GetActiveContactTemplatesQueryVariables>;
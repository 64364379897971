import { gql } from '@apollo/client';

import { UserFields } from 'repository/fragments';

export const LOGIN = gql`
  query ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export const VALIDATE_TOKEN = gql`
  query validateToken {
    validateToken
  }
`;

export const CHECK_MEMED_TOKEN = gql`
  query ($userId: String, $memedId: String) {
    checkMemedToken(userId: $userId, memedId: $memedId)
  }
`;

import { useEffect, useReducer } from 'react';
import { Styled } from './styled';
import actionReducer from './reducer';
import { RemoteAtendimentoChangeStatus } from '../ChangeStatus/RemoteAtendimentoChangeStatus';
import { useMutation } from '@apollo/client';
import DashboardModel from '../../../Models/DashboardModel';
import useCache from '../../../Hooks/useCache';

const ACTION = {
  CHANGE_STATUS: 1,
};

let actived = false;

const ActiveContact = ({ actualSector, alertRecord, callBack, dispatch, medicalRecord, store }) => {
  const [user] = useCache('user');

  const [teleAtendimentoContactActive] = useMutation(DashboardModel.TELEATENDIMENTO_CONTACT_ACTIVE);

  useEffect(() => {
    teleAtendimentoContactActive({
      variables: {
        entityId: parseInt(user.entityId),
        sector: 'CONTACT_ACTIVE',
        atendimentoId: alertRecord.senderId,
        motivo: 'Status Change',
        usuario: user.name,
        email: user.email,
        module: 'customer_service',
        name: alertRecord.clients.name,
      },
    })
      .then(() => {
        dispatch({ type: 'success', message: 'Evolução registrada com Sucesso!' });
        actived = true;
      })
      .catch(() => {
        console.log('Error: ', {
          variables: {
            entityId: parseInt(user.entityId),
            sector: 'CONTACT_ACTIVE',
            atendimentoId: alertRecord.senderId,
            motivo: 'Status Change',
            usuario: user.name,
            email: user.email,
            module: 'customer_service',
          },
        });
      });
  }, [teleAtendimentoContactActive, user, alertRecord, dispatch]);

  return <></>;
};

export const initialState = {
  isLoading: false,
  action: false,
  sector: '',
  status: '',
  reason: '',
  showMessage: null,
  showModal: false,
  data: {},
};

export default function RemoteAtendimentoActions({
  medicalRecord,
  callBack,
  alertRecord,
  actualSector = '',
  activeContact,
}) {
  const [store, dispatch] = useReducer(actionReducer, initialState);
  const { action } = store;
  return (
    <>
      {!action && (
        <>
          <Styled.Title>Ações</Styled.Title>
          <Styled.CheckoutOptionWrapper>
            <Styled.CheckoutOption
              onClick={() => dispatch({ type: 'action', action: ACTION.CHANGE_STATUS })}
            >
              <Styled.CheckoutIcon className="mdi mdi-account-alert" />
              <Styled.CheckoutDescription>Mudar o Status</Styled.CheckoutDescription>
            </Styled.CheckoutOption>

            {actualSector?.slug === 'OUTSIDE' && activeContact === -1 && (
              <Styled.CheckoutOption
                onClick={() => {
                  actived = true;
                  dispatch({ type: 'action', action: ACTION.ACTIVE_CONTACT });
                }}
              >
                <Styled.CheckoutIcon className="mdi mdi-whatsapp" />
                <Styled.CheckoutDescription>
                  {actived ? <strong>Enviando Aguarde !!!</strong> : 'Contato Ativo'}
                </Styled.CheckoutDescription>
              </Styled.CheckoutOption>
            )}
          </Styled.CheckoutOptionWrapper>
        </>
      )}

      {action === ACTION.CHANGE_STATUS && (
        <RemoteAtendimentoChangeStatus
          actualSector={actualSector}
          alertRecord={alertRecord}
          callBack={callBack}
          dispatch={dispatch}
          medicalRecord={medicalRecord}
          store={store}
        />
      )}

      {action === ACTION.ACTIVE_CONTACT && (
        <ActiveContact
          actualSector={actualSector}
          alertRecord={alertRecord}
          callBack={callBack}
          dispatch={dispatch}
          medicalRecord={medicalRecord}
          store={store}
        />
      )}
    </>
  );
}

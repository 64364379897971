import { useState } from 'react';
import styled, { css } from 'styled-components';

const TextEllipsis = styled.div.attrs((props) => ({
  show: props.show,
}))`
  ${({ show }) => css`
    width: ${!show ? 220 : 300}px;
    cursor: ${show ? 'zoom-out' : 'zoom-in'};
    overflow: hidden;
    white-space: nowrap;
  `}

  text-overflow: ellipsis;
  text-transform: capitalize;
`;

const findTag = (symptoms) => {
  const tagSearch = [];
  Object.values(symptoms).forEach((severity) => {
    if (typeof severity === 'object' && severity !== null) {
      severity.forEach((symptom, key) => {
        if (symptom.tag) {
          tagSearch.push(
            <div key={`${key}-${tagSearch}`}>
              <strong>{symptom.tag}</strong> {symptom.values.join(', ')}
            </div>
          );
        }
      });
    }
  });

  return tagSearch;
};

type PatientNameProps = {
  dateSymptomsStart?: string;
  patient: any;
};

export default function PatientName({ patient, dateSymptomsStart, symptoms }: PatientNameProps) {
  const [show, setShow] = useState(false);
  const tagValidation = symptoms != null ? symptoms : {};
  const tags = findTag(tagValidation);

  return (
    <>
      <TextEllipsis onClick={() => setShow((show) => !show)} show={show} title={patient.nome}>
        {patient.nome}
      </TextEllipsis>
      {patient.rgh && (
        <div>
          <strong>RGH:</strong> {patient.rgh}
        </div>
      )}
      {patient.healthCare && (
        <div>
          <strong>Convênio:</strong> {patient.healthCare}
        </div>
      )}
      {show && patient.age && (
        <div>
          <strong>Idade:</strong> {patient.age}
        </div>
      )}
      {show && patient.height && (
        <div>
          <strong>Altura:</strong> {patient.height}
        </div>
      )}
      {show && patient.weight && (
        <div>
          <strong>Peso:</strong> {patient.weight}
        </div>
      )}
      {show && patient.imc && (
        <div>
          <strong>IMC:</strong> {patient.imc}
        </div>
      )}
      {dateSymptomsStart && dateSymptomsStart && (
        <div>
          <strong>Início dos sintomas:</strong> {dateSymptomsStart}
        </div>
      )}

      {show && symptoms && tags}
    </>
  );
}

import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import { MONITORING_PAUSED_RULE } from '../Utils/Helpers/StatusHelper';

export const useMonitoringLock = (serviceStep, data) => {
  const blocked =
    serviceStep &&
    serviceStep?.value === SERVICE_STEPS('').monitoring.value &&
    MONITORING_PAUSED_RULE(data)
      ? 'Não é possível mover para monitoramento pois, o paciente bloqueou o monitoramento ao optar por sair do fluxo'
      : null;

  return blocked;
};

export default useMonitoringLock;

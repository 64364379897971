export const AlertTypes = ['red', 'yellow', 'blue', 'grey'];

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const groupsPatient = (symptoms) => {
  if (symptoms && symptoms.grey && symptoms.grey.length > 0) {
    const greySymptoms = [];
    symptoms.grey.map((s) => s.values.map((symptom) => greySymptoms.push(symptom)));

    return greySymptoms.filter(onlyUnique);
  } else {
    return null;
  }
};

export const alertComorbidities = (symptoms) => {
  const groups = groupsPatient(symptoms);
  if (groups?.length) {
    return groups.join('; ');
  }

  return groups;
};

export const alertGroupsKeys = (symptoms) => {
  // eslint-disable-next-line
  return symptoms.map((value) => {
    if (value.epidemiologyVariables && value.epidemiologyVariables !== null) {
      return (
        <li key={value.epidemiologyVariables[0].variableKey}>
          {' '}
          <strong></strong>
          <span>{value.epidemiologyVariables[0].variableKey}</span>
        </li>
      );
    }
  });
};

import routesDictionary from 'Utils/Dictionary/routesDictionary';

export const SectorDictionary = (module) => {
  return {
    CHAT_WAITING: {
      slug: 'CHAT_WAITING',
      background: '#FF881F',
      color: '#FF881F',
      name: 'Espera',
      icon: 'mdi-account-clock',
      module: 'etapa de Chat em espera',
      route: `/${module}${routesDictionary.CHAT_WAITING}`,
      type: 1,
      id: '8',
      rule: (collection) =>
        (collection.vitalSigns || collection.symptoms) &&
        collection.alert &&
        collection?.evolutionRecord?.serviceStep === 'chat' &&
        collection?.evolutionRecord?.setor === 'CHAT_WAITING' &&
        collection.medicalRecord &&
        collection.patient &&
        collection.patient.cpf,
    },
    CHAT_PROGRESS: {
      slug: 'CHAT_PROGRESS',
      background: '#FF77FF',
      color: '#FF33FF',
      name: 'Em atendimento',
      icon: 'mdi-doctor',
      module: 'etapa de Chat em atendimento',
      route: `/${module}${routesDictionary.CHAT_PROGRESS}`,
      type: 1,
      id: '9',
      rule: (collection) =>
        (collection.vitalSigns || collection.symptoms) &&
        collection.alert &&
        collection?.evolutionRecord?.serviceStep === 'chat' &&
        collection.medicalRecord &&
        collection.patient &&
        collection.patient.cpf &&
        collection?.evolutionRecord?.setor === 'CHAT_PROGRESS',
    },
    CHAT_ADJOURNED: {
      slug: 'CHAT_ADJOURNED',
      background: '#FF77FF',
      color: '#FF33FF',
      name: 'Adiados',
      icon: 'mdi-doctor',
      module: 'etapa de Chat em adiados',
      route: `/${module}${routesDictionary.CHAT_ADJOURNED}`,
      type: 1,
      id: '10',
      rule: (collection) =>
        (collection.vitalSigns || collection.symptoms) &&
        collection.alert &&
        collection?.evolutionRecord?.serviceStep === 'chat' &&
        collection.medicalRecord &&
        collection.patient &&
        collection.patient.cpf &&
        collection?.evolutionRecord?.setor === 'CHAT_ADJOURNED',
    },
  };
};

export const SectorRoutes = (module) => {
  return {
    waiting: SectorDictionary(module).CHAT_WAITING,
    progress: SectorDictionary(module).CHAT_PROGRESS,
    adjourned: SectorDictionary(module).CHAT_ADJOURNED,
  };
};

import { useEffect, useState, memo } from 'react';

import { Scrollbar } from './styles';

import ConversationMessage from '../ConversationMessage';
import Loading from '../Loading';

const ConversationMessages = (props) => {
  const { events, chatMessagesRef, data } = props;

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const MessagesItem = ({ messages }) => {
    // eslint-disable-next-line
    const itens = messages.map((message, index) => {
      if (message && (message.event === 'bot' || message.event === 'user')) {
        return <ConversationMessage data={data} key={index} message={message} />;
      }
    });

    return itens.length > 1 ? (
      itens
    ) : (
      <Loading>O cliente ainda não finalizou o envio dos dados ...</Loading>
    );
  };

  useEffect(() => {
    if (events) {
      setMessages(events);
      setLoading(true);
    }
  }, [events]);

  return (
    <>
      <Scrollbar containerRef={chatMessagesRef}>
        {loading && messages.length > 0 ? (
          <MessagesItem messages={messages} />
        ) : (
          <Loading>Carregando ...</Loading>
        )}
      </Scrollbar>
    </>
  );
};

export default memo(ConversationMessages);

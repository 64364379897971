import * as Types from '../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetPasswordTokenQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type ResetPasswordTokenQuery = { __typename?: 'Query', resetPasswordToken?: { __typename?: 'ResetPasswordToken', isValid: boolean } | null };

export type ResetUserPasswordMutationVariables = Types.Exact<{
  input: Types.ResetUserPasswordInput;
}>;


export type ResetUserPasswordMutation = { __typename?: 'Mutation', resetUserPassword?: { __typename?: 'ResetUserPasswordPayload', success: boolean, error?: string | null } | null };


export const ResetPasswordTokenDocument = gql`
    query ResetPasswordToken($token: String!) {
  resetPasswordToken(token: $token) {
    isValid
  }
}
    `;

/**
 * __useResetPasswordTokenQuery__
 *
 * To run a query within a React component, call `useResetPasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetPasswordTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordTokenQuery(baseOptions: Apollo.QueryHookOptions<ResetPasswordTokenQuery, ResetPasswordTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResetPasswordTokenQuery, ResetPasswordTokenQueryVariables>(ResetPasswordTokenDocument, options);
      }
export function useResetPasswordTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetPasswordTokenQuery, ResetPasswordTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResetPasswordTokenQuery, ResetPasswordTokenQueryVariables>(ResetPasswordTokenDocument, options);
        }
export type ResetPasswordTokenQueryHookResult = ReturnType<typeof useResetPasswordTokenQuery>;
export type ResetPasswordTokenLazyQueryHookResult = ReturnType<typeof useResetPasswordTokenLazyQuery>;
export type ResetPasswordTokenQueryResult = Apollo.QueryResult<ResetPasswordTokenQuery, ResetPasswordTokenQueryVariables>;
export const ResetUserPasswordDocument = gql`
    mutation ResetUserPassword($input: ResetUserPasswordInput!) {
  resetUserPassword(input: $input) {
    success
    error
  }
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
import { useState, useRef, useEffect } from 'react';

import '@szhsin/react-menu/dist/index.css';

import Tooltip from 'Components/Tooltip';

import LocalCache from 'Services/LocalCache';
import amplitude from 'Utils/Helpers/amplitude';
import dateFns from 'Utils/Helpers/dateFns';

import * as Styled from './styled';

type OwnProps = {
  activeColor?: string;
  disabled?: boolean;
  items: {
    label: string;
    icon: string;
    onClick?: (...args: any[]) => any;
    href?: string;
    disabled?: boolean;
    helpText?: string;
  }[];
};

type Props = OwnProps & typeof MoreOptions.defaultProps;

const MoreOptions = ({ items, activeColor, disabled, info }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const handleAmplitudeTimeline = (action, subSteps, moduleAccess, stepActive) => {
    let serviceAtive;

    if (stepActive === 'chat' || stepActive === 'telemedicine') {
      serviceAtive = {
        subSteps: {
          route: subSteps,
        },
      };
    }
    const data = {
      name: LocalCache?.getUser()?.name.toString(),
      email: LocalCache?.getUser()?.email.toString(),
      userId: LocalCache?.getUser()?.userId.toString(),
      entityId: LocalCache?.getUser()?.entityId.toString(),
      moduleAccess: moduleAccess,
      serviceStep: stepActive,
      action: action,
      ...serviceAtive,
      date: dateFns.formatTo(new Date(), 'dd/MM/yyyy HH:mm:ss'),
    };

    const userId = `${data.userId}.${data.entityId}`;
    amplitude.setUserId(userId);
    amplitude.trackEvent('ACTIONS_LAURA_CARE', data);
  };

  useEffect(() => {
    if (isOpen) {
      if (info && info?.action === 'action') {
        handleAmplitudeTimeline(info?.action, info?.location, info?.moduleAccess, info?.stepActive);
      }
    }
  }, [isOpen, info]);

  return (
    <>
      <Styled.Container
        active={isOpen}
        disabled={disabled}
        ref={ref}
        onClick={() => (disabled ? null : setOpen((prev) => !prev))}
        activeColor={activeColor}
      >
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </Styled.Container>

      <Styled.Menu
        anchorRef={ref}
        isOpen={isOpen}
        align="start"
        position="anchor"
        direction="left"
        onClose={() => setOpen(false)}
      >
        {items.map(({ onClick, label, href, external = false, icon, disabled, helpText }) => {
          const props = {
            ...(onClick ? { onClick } : {}),
            ...(external && href ? { href } : {}),
            disabled,
          };

          const Child = (
            <Styled.Content>
              <Styled.Icon size={1} path={icon} />
              {label}
            </Styled.Content>
          );

          return (
            <Styled.MenuItem {...props} key={label}>
              {helpText ? (
                <Tooltip text={helpText} maxWidth={230}>
                  {Child}
                </Tooltip>
              ) : (
                Child
              )}
            </Styled.MenuItem>
          );
        })}
      </Styled.Menu>
    </>
  );
};

MoreOptions.defaultProps = {
  activeColor: '#0071eb',
  disabled: false,
};

export default MoreOptions;

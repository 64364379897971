import { useCallback, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import Dialog from 'Components/Dialog';
import Button from 'Components/NewButton';
import Toast from 'Components/Toast';

import LocalCache from 'Services/LocalCache';

import PractitionerForm, { PractitionerFormSubmitButton } from './PractitionerForm';
import { ROLE } from './shared';
import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';

export const deprecatedCreateUserMutation = gql`
  mutation createUser(
    $entityId: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $roleId: String
  ) {
    createUser(
      entityId: $entityId
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      roleId: $roleId
    )
  }
`;

export const createPractitionerUserMutation = gql`
  mutation createPractitionerUser($input: CreatePractitionerUserInput!) {
    createPractitionerUser(input: $input) {
      success
      error
    }
  }
`;

const defaultSuccessMessage = 'Dados do profissional cadastrados com sucesso';
const defaultErrorMessage = 'Não foi possível cadastrar os dados do profissional';

const CreatePractitionerModal = ({ isInline, isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const formContext = useForm({ mode: 'onChange' });
  const { handleSubmit, reset, getValues, triggerValidation, formState } = formContext;

  const isPractitionerCredentialsEnabled = useFlag(
    `lc-practitioner-flag-${environment.UNLEASH_APP_ENV}`
  );

  const handlerError = (err) => {
    toast(<Toast type="error" title={defaultErrorMessage} subtitle={err.message} />, {
      autoClose: false,
    });
  };

  const [addUser] = useMutation(deprecatedCreateUserMutation, {
    onCompleted: () => {
      toast(<Toast type="success" title={defaultSuccessMessage} />, {
        autoClose: false,
      });
      onSuccess();
      onClose();

      reset({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roleId: null,
        personalId: '',
      });
    },
    onError: handlerError,
  });

  const [createPractitioner] = useMutation(createPractitionerUserMutation, {
    onCompleted: (data) => {
      const { success, error } = data.createPractitionerUser;

      if (error || !success) {
        toast(<Toast type="error" title={defaultErrorMessage} subtitle={error} />, {
          autoClose: false,
        });
        return;
      }

      toast(<Toast type="success" title={defaultSuccessMessage} />, {
        autoClose: false,
      });

      onSuccess();
      onClose();

      reset({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roleId: null,
        personalId: '',
        federativeUnit: undefined,
        practitionerRegistration: undefined,
        registrationCategory: undefined,
      });
    },
    onError: handlerError,
  });

  const handleFormChange = useCallback(() => {
    const { roleId, practitionerRegistration, federativeUnit, registrationCategory } = getValues();

    const isNurse = roleId === ROLE.NURSE;
    const hasNotInformedRegistration =
      !practitionerRegistration && !federativeUnit && !registrationCategory;

    if (isNurse && hasNotInformedRegistration) {
      triggerValidation('practitionerRegistration');
      triggerValidation('federativeUnit');
      triggerValidation('registrationCategory');
    }
  }, [getValues, triggerValidation]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const baseInput = {
        entityId: Number(LocalCache.getUser().entityId),
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        roleId: data.roleId,
      };

      if (!isPractitionerCredentialsEnabled) {
        await addUser({ variables: baseInput });
        setLoading(false);
        return;
      }

      const isDoctor = data.roleId === ROLE.DOCTOR;

      const identifier = data.registrationCategory
        ? `${data.practitionerRegistration}-${data.registrationCategory}`
        : data.practitionerRegistration;

      const input = {
        ...baseInput,
        personalId: data.personalId.replace(/\D/g, ''),
        registration: data.practitionerRegistration
          ? {
              identifier: identifier,
              federativeUnit: data.federativeUnit,
              registrationType: isDoctor ? 'CRM' : 'COREN',
            }
          : null,
      };

      await createPractitioner({ variables: { input } });
      setLoading(false);
    },
    [addUser, createPractitioner, isPractitionerCredentialsEnabled]
  );

  return (
    <FormContext {...formContext}>
      <form
        onChange={handleFormChange}
        className="form-style-1"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        {isInline ? (
          <>
            <PractitionerForm />
            <PractitionerFormSubmitButton />
          </>
        ) : (
          <Dialog isOpen={isOpen} onClose={onClose} backdrop width="732px">
            <Dialog.Header>
              <Dialog.Title>Adicionar Profissionais</Dialog.Title>
              <Dialog.Subtitle>Cadastre novos profissionais em sua equipe</Dialog.Subtitle>
            </Dialog.Header>

            <Dialog.Body>
              <PractitionerForm />
            </Dialog.Body>

            <Dialog.Footer>
              <Button maxWidth={120} fullWidth variant="link" onClick={onClose} type="button">
                Cancelar
              </Button>

              <Button
                maxWidth={120}
                fullWidth
                type="submit"
                disabled={loading || formState.isSubmitting || !formState.isValid}
                loading={loading || formState.isSubmitting}
              >
                Confirmar
              </Button>
            </Dialog.Footer>
          </Dialog>
        )}
      </form>
    </FormContext>
  );
};

export default CreatePractitionerModal;

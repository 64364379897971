import { createContext, useContext } from 'react';

const DataTableContext = createContext({});

/**
 * Provider de funções globais do DataTable
 */
export const DataTableProvider = (props) => {
  const contextValue = {
    contexts: {},
    setDataTableFetch: (ctx, fetch) => {
      contextValue.contexts[ctx] = {
        ...contextValue.contexts[ctx],
        fetch,
      };
    },
    fetch: (ctx) => {
      if (!ctx) {
        Object.keys(contextValue.contexts).map(
          (key) => contextValue.contexts[key]?.fetch && contextValue.contexts[key]?.fetch()
        );
      } else {
        contextValue.contexts[ctx]?.fetch && contextValue.contexts[ctx]?.fetch();
      }
      contextValue.documentCountFetch && contextValue.documentCountFetch();
    },
    setDocumentCountFetch: (documentCountFetch) => {
      contextValue.documentCountFetch = documentCountFetch;
    },
  };

  return (
    <DataTableContext.Provider value={contextValue}>{props.children}</DataTableContext.Provider>
  );
};

/**
 * Utiliza o contexto do datatable
 * @param {String} ctx
 * @returns contextValue
 */
export const useDataTable = (ctx?: string) => {
  const contextValue = useContext(DataTableContext);
  if (!ctx) {
    return contextValue;
  }
  return {
    ...contextValue,
    setDataTableFetch: (fetch) => contextValue.setDataTableFetch(ctx, fetch),
    context: contextValue.contexts[ctx],
  };
};

import styled from 'styled-components';
import Icon from '@mdi/react';
import TextareaAutosize from 'react-textarea-autosize';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 35px;

  background-color: #fff;
  border: #f1f1f1 solid 1px;
  padding: 0px;
  margin: 0px;
`;
export const Layout = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 70px;

  background-color: #fff;
  padding: 3px 6px;
  margin: 0px 0px;
  box-shadow: 0px 2px 8px #ccc;
  z-index: 0;
`;
export const Input = styled(TextareaAutosize)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  overflow-y: scroll;
  max-height: 60px;
  min-height: 60px;

  outline: none;
  border: #ccc solid 1px;

  font-size: 15px;
  color: #333;
  background: #fff;
  border-radius: 5px;

  margin: 0px 3px;
  padding: 5px;
`;

export const Avatar = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  width: 35px;
  height: 35px;

  margin: 0px 7px;
  padding: 0px;

  background-color: #ccc;
  color: #333;
  border-radius: 50%;

  cursor: pointer;
`;
export const IconPicker = styled(Icon)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0px;
  padding: 0px;

  background-color: #fff;
  color: #333;
  border-radius: 50%;

  cursor: pointer;
`;

export const PickerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0px 7px;
  padding: 0px;

  background-color: #fff;
  color: #333;
  border-radius: 50%;

  cursor: pointer;
`;

export const Send = styled(Icon)`
  cursor: pointer;
  margin: 0px 7px;
`;
export const Modal = styled.div`
  position: fixed;
  bottom: 80px;
  left: 10px;

  background-color: #fff;
  width: 280px;
  height: 325px;

  box-shadow: inset 0 0 1em gold, 0 0 1em blue;
  z-index: 100000;
`;

export const Styled = {
  Container,
  Layout,
  Avatar,
  Input,
  Send,
  Modal,
  PickerIcon,
  IconPicker,
};

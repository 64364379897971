import { useContext, useState, useMemo, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import LocalCache from 'Services/LocalCache';

import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';

import { StatusDictionary } from 'Utils/Dictionary/StatusDictionary';
import { Styled } from '../Actions/styled';
import useMonitoringLock from 'Hooks/useMonitoringLock';
import Button from 'Components/NewButton';
import DashboardModel from 'Models/DashboardModel';
import useCache from 'Hooks/useCache';
import ModuleContext from 'Context/moduleContext';
import ListServiceStep from 'Components/ListServiceStep';
import TelemedicineQueueModal from './TelemedicineQueueModal';
import { useMoreActions } from 'Hooks/useMoreActions';
import Toast from 'Components/Toast';

export default function ChangeStatus({ medicalRecord, callBack, alertRecord }) {
  const { moduleAccess } = useContext(ModuleContext);
  const { store } = useMoreActions();
  const { isLoading, sector, serviceStep, status, reason, data } = store;
  const [evolutionRecord, { loading }] = useMutation(DashboardModel.EVOLUTION);
  const [user] = useCache('user');
  const blocked = useMonitoringLock(serviceStep, data);
  const [isTelemedicinQueue, setIsTelemedicineQueue] = useState(false);

  const isHistory = useMemo(
    () => serviceStep?.value === SERVICE_STEPS(moduleAccess).history.value,
    [serviceStep, moduleAccess]
  );

  const isValid = useMemo(() => {
    return serviceStep?.value === 'telemedicine'
      ? true
      : Boolean(serviceStep) &&
          (serviceStep.subSteps ? Boolean(sector) : true) &&
          (isHistory
            ? Boolean(status) &&
              (![
                StatusDictionary.OBSERVATION,
                StatusDictionary.CRITIC,
                StatusDictionary.NO_SHOW,
              ].includes(status)
                ? Boolean(reason)
                : true)
            : true);
  }, [serviceStep, sector, isHistory, status, reason]);

  const inputRef = useRef();

  const isScheduleEnabled = useMemo(() => {
    const module = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);
    return module?.[0]?.serviceStep?.isTelemedicineScheduleEnabled ? true : false;
  }, [moduleAccess]);

  const isQueueEnabled = useMemo(() => {
    const module = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);
    return module?.[0]?.serviceStep?.isTelemedicineQueueEnabled ? true : false;
  }, [moduleAccess]);

  const isTelemedicine = !isScheduleEnabled && serviceStep?.value === 'telemedicine' ? true : false;

  function isQueue() {
    if (isTelemedicine) {
      setIsTelemedicineQueue(true);
    }
  }

  const handleEvolutionRecord = async () => {
    try {
      let hospitalMedicalRecord = '';
      if (
        isHistory &&
        (status === StatusDictionary.CRITIC || status === StatusDictionary.OBSERVATION)
      ) {
        hospitalMedicalRecord = inputRef.current.value;
      }

      await evolutionRecord({
        variables: {
          entityId: parseInt(user.entityId),
          medicalRecord: medicalRecord,
          hospitalMedicalRecord,
          motive: isHistory && reason ? reason.slug : 'Status change',
          sector: sector ? sector.slug : serviceStep?.initialSector,
          user: user.name,
          email: user.email,
          serviceStep: serviceStep?.value,
          module: moduleAccess,
          cellphone: alertRecord?.patient?.telefone,
          attendantCpf: user?.cpf,
        },
      });

      if (isTelemedicinQueue) {
        toast(
          <Toast
            type="success"
            title="Paciente movido para a etapa vídeo"
            subtitle={
              <p>
                O paciente foi movido para a etapa <strong>Vídeo</strong> e já recebeu o link da
                teleconsulta, onde irá acessar a sala de espera e aguardar o atendimento.
              </p>
            }
          />
        );
      } else {
        toast(
          <Toast
            type="success"
            title="Paciente movido com sucesso"
            subtitle="Pronto agora o paciente está  na etapa/setor selecionado."
          />
        );
      }

      callBack && callBack();
    } catch (error) {
      Sentry.captureException(error);
      toast(
        <Toast type="error" title="Não foi possível mover o paciente" subtitle={error.message} />
      );
    }
  };

  const submit = async () => {
    if (isTelemedicine && !isTelemedicinQueue) {
      if (!isQueueEnabled) {
        handleEvolutionRecord();
      } else {
        isQueue();
      }
    } else if (!isTelemedicine & !isTelemedicinQueue) {
      handleEvolutionRecord();
    } else if (isTelemedicinQueue) {
      handleEvolutionRecord();
    }
  };

  return (
    <>
      <Styled.NewTitleModal>Mover Paciente</Styled.NewTitleModal>

      {isTelemedicinQueue ? (
        <TelemedicineQueueModal />
      ) : (
        <>
          <Styled.Label align="center">Para onde deseja mover o paciente?</Styled.Label>

          <ListServiceStep
            inputRef={inputRef}
            alertRecord={alertRecord}
            isTelemedicine={isTelemedicine}
          />
        </>
      )}

      {blocked && <Styled.Description>blocked</Styled.Description>}
      <Styled.Separator height={44} />
      <Styled.Flex justify="center">
        <Button
          disabled={isLoading || blocked || !isValid}
          maxWidth={372}
          loading={loading}
          fullWidth
          rounded
          onClick={() => submit()}
          style={{ marginTop: '15px' }}
        >
          CONFIRMAR
        </Button>
      </Styled.Flex>
    </>
  );
}

import { useContext } from 'react';

import LocalCache from 'Services/LocalCache';
import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import ModuleContext from 'Context/moduleContext';
import ServiceButton from './components/ServiceButton';
import { useMoreActions } from 'Hooks/useMoreActions';
import amplitude from 'Utils/Helpers/amplitude';
import dateFns from 'Utils/Helpers/dateFns';

import * as Styled from './styled';
import moduleHelper from 'Utils/Helpers/moduleHelper';
import { useSelector } from 'react-redux';
import { MenuContext } from 'Context/menuContext';

export default function ServicesStep(props) {
  const { stepsData } = useContext(MenuContext);
  const { moduleDispatch, stepActive, moduleAccess } = useContext(ModuleContext);
  const { dispatch } = useMoreActions();
  const imminentTeleconsultations = useSelector(
    ({ modules }) => modules.imminentTeleconsultations[moduleAccess]
  );

  const handleAmplitudeModuleAccess = (stepValue) => {
    const data = {
      name: LocalCache?.getUser()?.name.toString(),
      email: LocalCache?.getUser()?.email.toString(),
      userId: LocalCache?.getUser()?.userId.toString(),
      entityId: LocalCache?.getUser()?.entityId.toString(),
      moduleAccess: moduleAccess,
      serviceStep: stepValue,
      date: dateFns.formatTo(new Date(), 'dd/MM/yyyy HH:mm:ss'),
    };

    const userId = `${data.userId}.${data.entityId}`;
    amplitude.setUserId(userId);
    amplitude.trackEvent('SERVICE_STEP_LAURA_CARE', data);
  };

  const handleServiceChange = (stepValue) => {
    handleAmplitudeModuleAccess(stepValue);

    moduleDispatch({
      type: 'CHANGE_STEP',
      stepActive: stepValue,
    });
    dispatch({ type: 'HIDE_MODAL' });
  };

  return (
    <Styled.Container>
      {Object.values(SERVICE_STEPS(moduleAccess))
        .filter(
          (serviceStep) =>
            serviceStep.fixed || moduleHelper.enabledService(moduleAccess, serviceStep.value)
        )
        .map((serviceStep, index) => {
          const serviceStepName = serviceStep.count;
          let serviceStepCount = 0;

          // If the feature flag 'displayUnreadMessages' is true servicestepcount is a object
          // and we need to get patientCount from it
          // We need to refactor this when removing the feature flag 'displayUnreadMessages'
          if (typeof stepsData?.[serviceStepName] === 'number') {
            serviceStepCount = stepsData?.[serviceStepName];
          } else if (stepsData?.[serviceStepName]?.patientCount) {
            serviceStepCount = stepsData?.[serviceStepName]?.patientCount;
          }

          return (
            <ServiceButton
              {...serviceStep}
              active={Boolean(stepActive === serviceStep.value)}
              onClick={handleServiceChange}
              key={`service_step_${index}`}
              count={serviceStepCount}
              unreadMessages={stepsData?.[serviceStepName]?.unreadChats?.length}
              loading={props?.loading}
              showAlert={serviceStep?.value === 'telemedicine' && imminentTeleconsultations}
            />
          );
        })}
    </Styled.Container>
  );
}

import { Children, cloneElement, useEffect, useState } from 'react';
import { RadioGroupContainer } from './styled';

export default function RadioGroup({ value, children, onChange }) {
  const [selected, setSelected] = useState(value);
  useEffect(() => {
    setSelected(value);
  }, [value]);
  const handleChange = (e, value) => {
    setSelected(value);
    onChange(e, value);
  };
  return (
    <RadioGroupContainer>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          onChange: handleChange,
          checked: selected === child.props.value,
        });
      })}
    </RadioGroupContainer>
  );
}

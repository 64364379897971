import { gql } from '@apollo/client';

export const ADD_ELIGIBLE_PATIENT = gql`
  mutation (
    $entityId: Int!
    $name: String!
    $email: String
    $cellphone: String!
    $cpf: String!
    $module: String!
    $complaint: String
    $flow: String
    $triage: TriageDataInput
    $user: String!
    $birthdate: String
    $tags: [String]
  ) {
    addEligiblePatient(
      entityId: $entityId
      name: $name
      email: $email
      cellphone: $cellphone
      cpf: $cpf
      module: $module
      complaint: $complaint
      flow: $flow
      triage: $triage
      user: $user
      birthdate: $birthdate
      tags: $tags
    )
  }
`;

export const ADD_ELIGIBLE_PATIENTS_BY_JSON = gql`
  mutation (
    $entityId: Int!
    $module: String!
    $user: String!
    $patients: String!
    $complaint: String
    $triage: TriageDataInput
    $flow: String
    $email: String
    $attendantCpf: String
  ) {
    addEligiblePatientsByJSON(
      entityId: $entityId
      patients: $patients
      module: $module
      complaint: $complaint
      flow: $flow
      triage: $triage
      user: $user
      email: $email
      attendantCpf: $attendantCpf
    ) {
      nowEligible
      alreadyEligible
    }
  }
`;

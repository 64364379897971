import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const props = (props) => ({
  size: props.size,
  active: props.active,
  handleColor: props.handleColor,
  color: props.color,
  disabled: props.disabled,
});

const sizes = {
  sm: 12,
  md: 14,
  lg: 16,
};

const sizeHandle = {
  sm: css`
    width: 10px;
    height: 10px;
  `,
  md: css`
    width: 12px;
    height: 12px;
  `,
  lg: css`
    width: 14px;
    height: 14px;
  `,
};

const propsSwitchSize = {
  sm: css`
    width: 32px;
    height: 16px;
    padding: 3px;
  `,
  md: css`
    width: 36px;
    height: 20px;
    padding: 4px;
  `,
  lg: css`
    width: 42px;
    height: 24px;
    padding: 5px;
  `,
};

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span.attrs(props)`
  color: #444444;
  font-family: 'Roboto';
  margin-left: 8px;

  ${({ size }) => css`
    font-size: ${sizes[size]}px;
  `}
`;

export const Switch = styled(motion.div).attrs(props)`
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;

  ${({ size, active, color, disabled }) => css`
    ${propsSwitchSize[size]}
    justify-content: ${active ? 'flex-end' : 'flex-start'};
    background: ${disabled ? '#707070' : color};
    cursor: ${disabled ? 'default' : 'pointer'};
  `}
`;

export const Handle = styled(motion.div)`
  border-radius: 50%;

  ${({ size, handleColor }) => css`
    ${sizeHandle[size]}
    background-color: ${handleColor};
  `}
`;

import { useContext, useMemo, useEffect } from 'react';

import ModuleContext from 'Context/moduleContext';
import { SectorRoutes } from 'Utils/Dictionary/Generalist/SectorDictionary';
import Wrapper from 'Pages/shared/Wrapper';

import { Menu } from './components';
import ModalCancelSchedule from 'Components/ModalCancelSchedule';

const getStepAcessed = (match) => {
  let stepAcessed;
  if (match.params.services) {
    stepAcessed = match.params.services;
  } else {
    stepAcessed = match.params.sector === 'all' ? 'screening' : match.params.sector;
  }
  return stepAcessed;
};

const getSectorAcessed = (match, moduleAccess) => {
  let sectorAcessed;
  if (match.params.services) {
    sectorAcessed = SectorRoutes(moduleAccess)[match.params.services][match.params.sector];
  } else {
    sectorAcessed = SectorRoutes(moduleAccess)[match.params.sector];
  }
  return sectorAcessed;
};

const Main = ({ match }) => {
  const { moduleAccess, moduleDispatch } = useContext(ModuleContext);
  const stepAcessed = getStepAcessed(match);

  const activeSector = useMemo(() => {
    return getSectorAcessed(match, moduleAccess);
  }, [match, moduleAccess]);

  useEffect(() => {
    moduleDispatch({
      type: 'CHANGE_STEP',
      stepActive: stepAcessed,
    });
  }, [stepAcessed, moduleDispatch]);

  return (
    <Wrapper sector={activeSector?.slug}>
      <Menu sector={activeSector} />
      <ModalCancelSchedule />
    </Wrapper>
  );
};

export default Main;

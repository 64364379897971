import { mdiAccountCircle } from '@mdi/js';
import Icon from '@mdi/react';

import { Timeline } from '../styled';

const TimelineNote = ({ timelineItem, shouldShow }) => (
  <>
    {shouldShow ? (
      <Timeline.Body>
        <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }} maxHeight={350}>
          <Timeline.GroupTitle>
            <Timeline.Title>Padrão</Timeline.Title>
            {timelineItem?.evolutionNote?.dataColeta ? (
              <Timeline.RightDate datetime={timelineItem.evolutionNote.dataColeta} />
            ) : null}
          </Timeline.GroupTitle>
          {timelineItem?.evolutionNote?.attendanceType ? (
            <>
              <Timeline.Subtitle>Tipo de atendimento</Timeline.Subtitle>
              <Timeline.Text>{timelineItem.evolutionNote.attendanceType}</Timeline.Text>
            </>
          ) : null}
          <Timeline.Subtitle>Evolução</Timeline.Subtitle>
          <Timeline.Text>{timelineItem.evolutionNote.observacao}</Timeline.Text>
        </Timeline.Body.Item>
      </Timeline.Body>
    ) : null}

    <Timeline.Small>
      <Icon color="#1D1D1D" path={mdiAccountCircle} size={0.8} style={{ marginRight: 6 }} />
      Responsável: {timelineItem.evolutionNote.usuario}
    </Timeline.Small>
  </>
);

export default TimelineNote;

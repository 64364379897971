import * as Types from '../../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserListExtendedFieldsFragment = { __typename?: 'UserDetail', roleId?: number | null, createdAt?: any | null, createdBy?: { __typename?: 'UserDetail', firstName?: string | null, lastName?: string | null } | null };

export type UserListQueryVariables = Types.Exact<{
  tenantId: Types.Scalars['Int'];
  useExtendedFields?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UserListQuery = { __typename?: 'Query', getUsers?: Array<{ __typename?: 'UserDetail', id: number, entityId?: number | null, firstName?: string | null, lastName?: string | null, email?: string | null, personalId?: string | null, isActive?: boolean | null, roleId?: number | null, createdAt?: any | null, createdBy?: { __typename?: 'UserDetail', firstName?: string | null, lastName?: string | null } | null } | null> | null };

export const UserListExtendedFieldsFragmentDoc = gql`
    fragment UserListExtendedFields on UserDetail {
  roleId
  createdAt
  createdBy {
    firstName
    lastName
  }
}
    `;
export const UserListDocument = gql`
    query UserList($tenantId: Int!, $useExtendedFields: Boolean = false) {
  getUsers(entityId: $tenantId) {
    id
    entityId
    firstName
    lastName
    email
    personalId
    isActive
    ...UserListExtendedFields @include(if: $useExtendedFields)
  }
}
    ${UserListExtendedFieldsFragmentDoc}`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      useExtendedFields: // value for 'useExtendedFields'
 *   },
 * });
 */
export function useUserListQuery(baseOptions: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
      }
export function useUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
        }
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
import styled from 'styled-components';
import {
  Menu as MenuBase,
  MenuButton as MenuButtonBase,
  MenuItem as MenuItemBase,
} from '@szhsin/react-menu';
import Icon from '@mdi/react';

export const Menu = styled(MenuBase)`
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
`;
export const MenuButton = styled(MenuButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: none;
  gap: 10px;
  color: #4a4f5e;
  border-radius: 5px;
  background: none;
  transition: background 200ms ease;

  &:hover,
  &[aria-expanded='true'] {
    background: #edeff3;
  }

  &:active {
    background: #dfdfe1;
  }
`;

export const MenuItem = styled(MenuItemBase)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 12px 12px 12px 16px;
  gap: 10px;
  color: #4a4f5e;
  transition: background 200ms ease;

  &:hover {
    background: #edeff3;
  }

  &:active {
    background: #dfdfe1;
  }
`;

export const MenuIcon = styled(Icon)`
  color: #4a4f5e;
`;

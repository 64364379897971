import styled, { css } from 'styled-components';

const Row = styled.div.attrs((props) => ({
  direction: props.direction || 'row',
  justify: props.justify || 'flex-start',
  align: props.align || 'center',
}))`
  ${({ direction, justify, align }) => css`
    display: flex;
    width: 100%;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
  `}
`;

export default Row;

import Tooltip from 'Components/Tooltip';

import * as Styled from './styled';

type OwnProps = {
  tags?: string[];
};

type Props = OwnProps & typeof TagsColumn.defaultProps;

const TagsColumn = ({ tags, width }: Props) => {
  const extraTags = [...(tags ? tags : [])].splice(2);

  return (
    <Styled.Container width={width}>
      {tags?.slice(0, 2).map((tag, index) => (
        <Tooltip key={index} text={tag}>
          <Styled.Tag width={width}>{tag}</Styled.Tag>
        </Tooltip>
      ))}
      {extraTags.length ? (
        <Tooltip
          text={
            <Styled.List>
              {extraTags.map((tag, index) => (
                <Styled.ListItem key={index}>✓ {tag}</Styled.ListItem>
              ))}
            </Styled.List>
          }
          maxWidth={220}
        >
          <Styled.Link>+{extraTags.length}</Styled.Link>
        </Tooltip>
      ) : null}
    </Styled.Container>
  );
};

TagsColumn.defaultProps = {
  tags: [],
  width: 124,
};

export default TagsColumn;

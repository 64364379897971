import { useCallback, useState, useMemo } from 'react';

import * as S from './styles';

import IconImage from './assets/iconImage.svg';
import IconPdf from './assets/iconPdf.svg';

import { ImageModal } from '../ImageModal';

export type fileOptions = {
  url: string;
  type: string;
  size: number;
  name: string;
};

type Props = {
  file: fileOptions;
  expanded: boolean;
  time: string;
};

export const FileContainer = ({ file, expanded, time }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const fileSize = useMemo(() => {
    if (!file) return '';

    // convert mb to b
    const bytes = Number(file.size) * 1000000;

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const selectedSize = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / Math.pow(1024, selectedSize))} ${sizes[selectedSize]}`;
  }, [file]);

  const toggleModal = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const isPdf = file?.type.toString() === 'pdf';
  return (
    <>
      <S.Wrapper expanded={expanded}>
        <S.WrapperFileInfo>
          <S.WrapperLogo>
            {isPdf ? (
              <S.WrapperDowload href={file?.url} download target="_blank" rel="noopener noreferrer">
                <IconPdf />
              </S.WrapperDowload>
            ) : (
              <IconImage onClick={toggleModal} />
            )}
          </S.WrapperLogo>
          <S.WrapperFileName>
            <S.FileName>{file?.name}</S.FileName>
            <S.FileSize>{fileSize}</S.FileSize>
          </S.WrapperFileName>
        </S.WrapperFileInfo>
      </S.Wrapper>
      <ImageModal isOpen={isOpen} onClose={toggleModal} file={file} time={time} />
    </>
  );
};

import parse from 'html-react-parser';

const parseTextToHtml = (text) => {
  const regex = /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2,})(?::\d{2,5})?(?:\/[^\s]*)?/gi
  const protocolPattern = /^(?:(?:https?|ftp):\/\/)/i;

  const html = text.replace(regex, (url) => {
    const href = protocolPattern.test(url) ? url : `https://${url}`;
    return `<a href="${href}" target="_blank">${url}</a>`
  });

  return parse(html);
};

export default parseTextToHtml;

import * as Styled from './styled';
import moment from 'moment';

export default function ScheduleDateColumn({ document }) {
  const date = moment(document?.serviceSteps?.telemedicine?.scheduling?.slotInitialTime).utc();
  const endDate = moment(document?.serviceSteps?.telemedicine?.scheduling?.slotEndTime).utc();
  const startTimeFormatted = date.format('HH:mm');
  const endTimeFormatted = endDate.format('HH:mm');
  const currentDate = moment().utc();
  const nextWeekDay = currentDate.clone().add(6, 'days');
  let formatted = '';
  if (date.startOf('day').isSame(currentDate.startOf('day'))) {
    formatted = 'Hoje';
  } else if (
    date.startOf('day').isBefore(nextWeekDay.startOf('day')) &&
    date.startOf('day').isAfter(currentDate.startOf('day'))
  ) {
    formatted = date.format('dddd');
  } else {
    formatted = date.format('DD [de] MMMM');
  }
  if (!date.isValid() || !endDate.isValid()) {
    return <Styled.Container>Sem data</Styled.Container>;
  }
  return (
    <Styled.Container title={`${date.format('DD [de] MMMM [de] YYYY')}`}>
      {formatted}
      <br />
      {startTimeFormatted} - {endTimeFormatted}
    </Styled.Container>
  );
}

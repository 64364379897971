import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

    color: var(--white);
  }
  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;

    width: 100%;
    height: 100%;
  }
  
  html {
    background: var(--unnamed-color-f7f6f4) 0% 0% no-repeat padding-box;
    background: #F7F6F4 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  :root {

/* Colors: */
--unnamed-color-ffffff: #FFFFFF;
--unnamed-color-0099e9: #0071eb;
--unnamed-color-212121: #212121;
--unnamed-color-e3e3e3: #E3E3E3;
--unnamed-color-7a7a7a: #7A7A7A;
--unnamed-color-ff881f: #FF881F;
--unnamed-color-f7f6f4: #F7F6F4;
--unnamed-color-ffccd3: #FFCCD3;
--unnamed-color-ec2c47: #EC2C47;
--unnamed-color-fff7d5: #FFF7D5;
--unnamed-color-f6c500: #F6C500;
--unnamed-color-1c7d00: #1C7D00;
--unnamed-color-f7f6f466: #F7F6F4;
--unnamed-color-000000: #000000;
--unnamed-color-f4f7f4: #F4F7F4;

/* Font/text values */
--unnamed-font-family-open-sans: Open Sans;
--unnamed-font-style-normal: normal;
--unnamed-font-weight-300: 300px;
--unnamed-font-weight-800: 800px;
--unnamed-font-weight-bold: bold;
--unnamed-font-weight-normal: normal;
--unnamed-font-size-12: 12px;
--unnamed-font-size-40: 40px;
--unnamed-character-spacing-0-24: 0.24px;
--unnamed-character-spacing-0-48: 0.48px;
--unnamed-character-spacing-0-12: 0.12px;
--unnamed-character-spacing-1-6: 1.6px;
--unnamed-line-spacing-17: 17px;
--unnamed-line-spacing-55: 55px;
}

/* Character Styles */
.unnamed-character-style-1 {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-12);
line-height: var(--unnamed-line-spacing-17);
letter-spacing: var(--unnamed-character-spacing-0-24);
color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-2 {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-12);
line-height: var(--unnamed-line-spacing-17);
letter-spacing: var(--unnamed-character-spacing-0-48);
color: var(--unnamed-color-7a7a7a);
}
.unnamed-character-style-3 {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-12);
line-height: var(--unnamed-line-spacing-17);
letter-spacing: var(--unnamed-character-spacing-0-48);
color: var(--unnamed-color-000000);
}
.unnamed-character-style-4 {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-12);
line-height: var(--unnamed-line-spacing-17);
letter-spacing: var(--unnamed-character-spacing-0-12);
color: var(--unnamed-color-f4f7f4);
}
.unnamed-character-style-5 {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-800);
font-size: var(--unnamed-font-size-40);
line-height: var(--unnamed-line-spacing-55);
letter-spacing: var(--unnamed-character-spacing-1-6);
color: var(--unnamed-color-212121);
}
.unnamed-character-style-6 {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-300);
font-size: var(--unnamed-font-size-40);
line-height: var(--unnamed-line-spacing-55);
letter-spacing: var(--unnamed-character-spacing-1-6);
color: var(--unnamed-color-212121);
}
`;

import { Timeline } from '../styled';

const parseWhatsappStatus = (status) => (status === 'good' ? 'Sentindo Bem' : 'Sentindo Mal');

const excludeSymptoms = ['None', 'Unknown'];

const TimelineWhatsapp = ({ timelineItem, shouldShow }) => {
  if (!shouldShow) {
    return null;
  }

  return (
    <>
      {/* <Timeline.Alert>whatsapp</Timeline.Alert> */}
      <Timeline.Body color={false}>
        <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }}>
          {parseWhatsappStatus(timelineItem.healthStatus)}
        </Timeline.Body.Item>
        {!excludeSymptoms.includes(timelineItem.symptom) && (
          <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }}>
            Sintoma: {timelineItem.symptom}
          </Timeline.Body.Item>
        )}
      </Timeline.Body>
    </>
  );
};

export default TimelineWhatsapp;

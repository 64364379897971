import { formatTo } from 'Utils/Helpers/dateFnsHelper';
import { DATE_FORMAT } from 'Utils/types';

import { Timeline } from '../../styled';

const TimelineMonitoring = ({ timelineItem, shouldShow }) => (
  <>
    {shouldShow ? (
      <Timeline.Body>
        <Timeline.Body.Item style={{ fontSize: '0.850rem', color: '#A9A9A9' }}>
          <div>
            <strong style={{ color: '#707070' }}>Nome: </strong>
            <span>{timelineItem?.paciente?.nome}</span>
          </div>
          <div style={{ margin: '5px 0' }}>
            <strong style={{ color: '#707070' }}>Data de Nascimento: </strong>
            <span>{formatTo(timelineItem?.paciente?.dataNascimento, DATE_FORMAT.date)}</span>
          </div>
          <div>
            <strong style={{ color: '#707070' }}>Telefone: </strong>
            <span>{timelineItem?.paciente?.telefone}</span>
          </div>
          <div>
            <strong style={{ color: '#707070' }}>Email: </strong>
            <span>{timelineItem?.paciente?.email}</span>
          </div>
        </Timeline.Body.Item>
      </Timeline.Body>
    ) : null}
  </>
);

export default TimelineMonitoring;

import isValidDate from 'date-fns/isValid';

import dateFns from '../dateFns';

const MAX_AGE = 120;
const MIN_AGE = 18;

const parseDate = (date) => {
  if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(date)) {
    throw new Error('Invalid date format');
  }

  // eslint-disable-next-line prefer-const
  let [dd, mm, yyyy] = date.split('/').map((p) => parseInt(p, 10));
  mm -= 1;
  const d = new Date(yyyy, mm, dd);

  if (d.getMonth() === mm && d.getDate() === dd && d.getFullYear() === yyyy) {
    return d;
  }

  throw new Error('Invalid date format');
};

const format = (date) => {
  if (date && isValidDate(date)) {
    return dateFns.formatTo(date, 'dd/MM/yyyy');
  }
  return null;
};

const isValid = (date, applyMinAge = false) => {
  if (!date) {
    return true;
  }

  try {
    const parsedDate = parseDate(date);
    const now = new Date();
    const currentYear = now.getFullYear();
    const age = currentYear - parsedDate.getFullYear();
    let response: boolean | string = true;

    if (!isValidDate(parsedDate)) {
      response = 'Informe uma data válida';
    } else if (parsedDate > now) {
      response = 'A data deve ser menor que o dia de hoje';
    } else if (applyMinAge && age < MIN_AGE) {
      response = `A data deve ser menor que ${currentYear - MIN_AGE}`;
    } else if (age > MAX_AGE) {
      response = `A data deve ser maior que ${currentYear - MAX_AGE}`;
    }

    return response;
  } catch {
    return 'Informe uma data válida';
  }
};

export default {
  parseDate,
  format,
  isValid,
};

import { createContext } from 'react';

import { chatData } from 'Components/Chat/types';

const ChatContext = createContext({
  ...chatData,
  myCurrentChat: false,
  awaitingPatientResponse: false,
  needActiveContact: false,
  color: '#DCF0FF',
  colorBot: '#FFE9D5',
  colorUser: '#E2E2E2',
  colorStatus: '#DCF0FF',
  sendMessage: () => void 0,
  sendUpdateHandover: () => void 0,
  handleNextPage: () => void 0,
  handleNewMessage: () => void 0,
  toggleExpandChat: () => void 0,
  closeChat: () => void 0,
  handleHandover: () => void 0,
  toggleMinimize: () => void 0,
  onClickTimeline: () => void 0,
  filterValue: null,
  setFilterValue: () => void 0,
  optionsFilter: () => void 0,
});

export default ChatContext;

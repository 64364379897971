import { Document } from 'data/types';
import { addDays } from 'date-fns';

import LocalCache from 'Services/LocalCache';
import { DATE_FORMAT } from 'Utils/types';

import { formatTo } from './dateFnsHelper';

type Props = Pick<Document, 'monitoring' | 'complaint'> & { moduleAccess: string };

export const getMonitoringDays = ({ monitoring, complaint, moduleAccess }: Props) => {
  let currentDay = -1;
  let maximumDay = 10;

  if (monitoring?.nEnvios && monitoring?.nEnvios >= 1) {
    currentDay = monitoring?.nEnvios;
  }

  const remoteMonitoringConfig =
    LocalCache.getEntity()?.remoteMonitoringTypes != null
      ? JSON.parse(LocalCache.getEntity()?.remoteMonitoringTypes)
      : 0;

  if (monitoring?.type === 'period') {
    maximumDay = remoteMonitoringConfig?.period?.config?.total_days;
  } else if (moduleAccess && complaint && monitoring?.type === 'steps') {
    maximumDay = remoteMonitoringConfig?.steps?.[moduleAccess]?.[complaint]?.steps?.length;
  }

  return {
    start: formatTo(monitoring?.dataPrimeiroEnvio, DATE_FORMAT.date),
    end: formatTo(addDays(new Date(monitoring?.dataPrimeiroEnvio), maximumDay), DATE_FORMAT.date),
    currentDay,
    maximumDay,
  };
};

import { Dict } from '@chakra-ui/utils';
import { ThemeConfig, ThemeDirection } from '@chakra-ui/theme';

import { components } from './components';
import { foundations } from './foundations';
import { semanticTokens } from './semantic-tokens';
import { styles } from './styles';

const direction: ThemeDirection = 'ltr';

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'laura',
};

export const theme: Dict = {
  semanticTokens,
  direction,
  ...foundations,
  components,
  styles,
  config,
};

export default theme;

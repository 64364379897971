import { useContext, useMemo } from 'react';
import Icon from '@mdi/react';
import { useHistory } from 'react-router-dom';
import { mdiClockTimeFive } from '@mdi/js';
import { Button as ChakraButton } from '@chakra-ui/react';

import Datatable from 'Components/Datatable';
import {
  MoreActions,
  FlexColumnRow,
  TagsColumn,
  AlertColumn,
  StatusColumn,
  ActiveContactColumn,
  LastInteractionColumn,
  MonitoringColumn,
  ChatButton,
  TextEllipsis,
} from 'Components/Datatable/components';
import Button from 'Components/Button';
import ButtonCsv from '../ButtonCsv';

import PatientName from 'Components/PatientName';
import { ColorDictionary } from 'Utils/Dictionary/ColorDictionary';
import { alertComorbidities } from 'Utils/Dictionary/AlertDictionary';
import Tooltip from 'Components/Tooltip';
import ModuleContext from 'Context/moduleContext';
import DataContext from 'Context/dataContext';
import { formatTo } from 'Utils/Helpers/dateFnsHelper';
import { MORE_ACTIONS, DATE_FORMAT } from 'Utils/types';
import routes from 'routes';
import LocalCache from 'Services/LocalCache';
import { actualStatusText } from 'Utils/Helpers/StatusHelper';
import { differenceInDays } from 'date-fns';
import { SectorDictionary } from 'Utils/Dictionary/SectorDictionary';
import { SectorDictionary as ChatSectorDictionary } from 'Utils/Dictionary/Chat/SectorDictionary';
import { SectorDictionary as TelemedicineSectorDictionary } from 'Utils/Dictionary/Telemedicine/SectorDictionary';

import VideoButton from 'Components/Datatable/components/VideoButton';
import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import router from 'Utils/Helpers/router';

import amplitude from 'Utils/Helpers/amplitude';
import dateFns from 'Utils/Helpers/dateFns';
import ScheduleTelemedicineButton from 'Components/Datatable/components/ScheduleTelemedicineButton';
import ScheduleDateColumn from 'Components/Datatable/components/ScheduleDateColumn';
import { useFlag } from 'Context/unleashContext';
import MovePatientButton from 'Components/Datatable/components/MovePatientButton';
import environment from 'Utils/Dictionary/environment';
import ScheduleReminderButton from 'Components/Datatable/components/ScheduleReminderButton';
import CancelReminderButton from '../../../../Components/Datatable/components/CancelReminderButton';

const exportCsvParams = [
  { column: 'CPF', row: 'patient.cpf' },
  { column: 'Paciente', row: 'patient.nome' },
  { column: 'Grupo', row: 'comorbidities' },
  { column: 'Telefone', row: 'patient.telefone' },
  { column: 'Nível do alerta', row: 'alertResult' },
  { column: 'Última interação', row: 'alertDate' },
  { column: 'Convênio', row: 'patient.healthCare' },
  { column: 'UBS Origem', row: 'patient.ubs' },
  { column: 'Tags', row: 'tags' },
  { column: 'Status', row: 'status' },
  { column: 'Fluxo', row: 'flow' },
  { column: 'Dias de monitoramento', row: 'monitoringDays' },
];

export default function Data({ loadMenu, sector }) {
  const { moduleAccess, stepActive } = useContext(ModuleContext);

  const isReminder: boolean = useFlag(`lc-reminder-${environment.UNLEASH_APP_ENV}`);
  const isPrescriptionEnabled = useFlag('lc-eligible-prescription');
  const displayMovePatientButton = useFlag(`lc-move-patient-button-${environment.UNLEASH_APP_ENV}`);
  const {
    data,
    loading,
    fetchData,
    totalDoctorsOnline,
    toogleDoctorStatus,
    doctorInRoom,
    refetch,
  } = useContext(DataContext);
  const history = useHistory();
  const { enabled: telemedicineIsEnabled } = LocalCache.getModuleData('telemedicine');

  const documentsData = useMemo(() => data?.documentsByPage?.records, [data]);
  const isTelemedicineStep = useMemo(
    () => SERVICE_STEPS(moduleAccess).telemedicine.value === stepActive,
    [stepActive, moduleAccess]
  );

  const isSchedule = useMemo(() => {
    const [module] = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);
    return module?.serviceStep?.telemedicine &&
      module?.serviceStep?.isTelemedicineScheduleEnabled === true
      ? true
      : false;
  }, [moduleAccess]);

  const isScheduleEnabled = useMemo(() => {
    const [module] = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);
    return stepActive === 'telemedicine' &&
      module?.serviceStep?.isTelemedicineScheduleEnabled === true
      ? false
      : true;
  }, [moduleAccess, stepActive]);

  const InternalSectorDictionary = useMemo(
    () => ({
      ...SectorDictionary,
      ...ChatSectorDictionary(moduleAccess),
      ...TelemedicineSectorDictionary(moduleAccess),
    }),
    [moduleAccess]
  );

  const sectorName = InternalSectorDictionary[sector?.slug].module;

  const CategoryFlow = (row) => {
    if (row?.moduleParams?.flow === null) return;
    return (
      <span>
        <span>{row?.moduleParams?.flow}</span>
        <br />
        <i style={{ color: '#a9a9a9' }}>{row?.triage}</i>
      </span>
    );
  };

  const handleAmplitudeTimeline = (subSteps, timeline, moduleAccess, stepActive) => {
    let serviceAtive;

    if (stepActive === 'chat' || stepActive === 'telemedicine') {
      serviceAtive = {
        subSteps: {
          route: subSteps,
        },
      };
    }
    const data = {
      name: LocalCache?.getUser()?.name.toString(),
      email: LocalCache?.getUser()?.email.toString(),
      userId: LocalCache?.getUser()?.userId.toString(),
      entityId: LocalCache?.getUser()?.entityId.toString(),
      moduleAccess: moduleAccess,
      serviceStep: stepActive,
      timeline: timeline,
      ...serviceAtive,
      date: dateFns.formatTo(new Date(), 'dd/MM/yyyy HH:mm:ss'),
    };

    const userId = `${data.userId}.${data.entityId}`;
    amplitude.setUserId(userId);
    amplitude.trackEvent('TIMELINE_LAURA_CARE', data);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'CPF',
        accessor: 'patient.cpf',
        hide: true,
      },
      {
        Header: 'Paciente',
        accessor: 'patient.nome',
        width: 230,
        Cell: ({ row }) => (
          <PatientName
            dateSymptomsStart={row.original.dateSymptomsStart}
            patient={row.original.patient}
            symptoms={row.original.symptoms}
          />
        ),
      },
      {
        Header: '',
        id: 'tags',
        accessor: (row) => {
          return row?.tags || [];
        },
        disableSortBy: true,
        Cell: ({ value }) => <TagsColumn tags={value} />,
      },
      {
        Header: 'Fluxo',
        accessor: 'moduleParams.flow',
        width: 109,
        Cell: ({ row }) => {
          return (
            <>
              {CategoryFlow(row.original)}
              {stepActive === 'monitoring' && (
                <MonitoringColumn
                  monitoring={row?.original?.monitoring}
                  complaint={row?.original?.complaint}
                  moduleAccess={moduleAccess}
                />
              )}
            </>
          );
        },
      },
      {
        Header: 'Telefone',
        accessor: 'patient.telefone',
        Cell: ({ row }) => (
          <TextEllipsis width={120}>{row.original?.patient?.telefone}</TextEllipsis>
        ),
      },
      {
        Header: 'Alerta',
        accessor: 'alertResult',
        width: 100,
        hide: stepActive === 'eligible',
        Cell: ({ row }) => <AlertColumn alert={row.original?.alert} />,
      },
      {
        Header: 'Status',
        accessor: 'activeContactStatus',
        width: 110,
        Cell: ({ row }) => {
          return stepActive === 'eligible' ? (
            <ActiveContactColumn collection={row.original} />
          ) : (
            <StatusColumn collection={row.original} />
          );
        },
      },
      {
        Header: (
          <Tooltip text="Dia/Hora da última interação">
            <Icon path={mdiClockTimeFive} size={0.8} />
          </Tooltip>
        ),
        accessor: 'dateLastInteraction',
        width: 70,
        hide: ['eligible'].includes(stepActive),
        Cell: ({ row }) => {
          return <LastInteractionColumn date={row?.values['dateLastInteraction']} />;
        },
      },
      {
        Header: 'Consulta',
        id: 'scheduling',
        accessor: 'serviceSteps.telemedicine.scheduling.slotInitialTime',
        width: 110,
        hide: isScheduleEnabled,
        Cell: ({ row }) => <ScheduleDateColumn document={row.original} />,
      },
      {
        Header: 'Ações',
        id: 'patient_action',
        disableSortBy: true,
        width: 100,

        Cell: ({ row }) => (
          <FlexColumnRow>
            {isSchedule && <ScheduleTelemedicineButton document={row.original} />}
            <ChatButton record={row.original} />
            <VideoButton document={row.original} />
            {displayMovePatientButton ? (
              <MovePatientButton
                loadMenu={loadMenu}
                medicalRecord={row.original.medicalRecord}
                alertRecord={row.original}
                stepActive={stepActive ?? ''}
                sectorSlug={sector?.slug}
              />
            ) : null}
            {isReminder && (
              <>
                <ScheduleReminderButton document={row.original} />
                <CancelReminderButton document={row.original} />
              </>
            )}
            <ChakraButton
              variant="outlined"
              size="sm"
              paddingX="6"
              marginX="2"
              onClick={() => {
                handleAmplitudeTimeline(
                  history.location.pathname,
                  router.to(routes.TIMELINE.path, { medicalRecord: row.original.medicalRecord }),
                  moduleAccess,
                  stepActive
                );
                history.push(
                  router.to(routes.TIMELINE.path, { medicalRecord: row.original.medicalRecord })
                );
              }}
            >
              Timeline
            </ChakraButton>
            <MoreActions
              alertRecord={row.original}
              evolutionNote={row.original.evolutionNote}
              medicalRecord={row.original.medicalRecord}
              stepActive={stepActive}
              location={history.location.pathname}
              onFinishModal={() => {
                loadMenu();
              }}
              activeActions={[
                ...(stepActive === 'telemedicine'
                  ? [MORE_ACTIONS.RESCHEDULE_PATIENT, MORE_ACTIONS.CANCEL_SCHEDULE]
                  : []),
                ...(stepActive !== 'eligible' && sector?.slug !== 'SCHEDULE'
                  ? [MORE_ACTIONS.MOVE_PACIENT]
                  : []),
                MORE_ACTIONS.REGISTER_EVOLUTION,
                MORE_ACTIONS.ADD_TAG,
                ...(isTelemedicineStep || (stepActive === 'eligible' && isPrescriptionEnabled)
                  ? [MORE_ACTIONS.SEND_PRESCRIPTION]
                  : []),
                MORE_ACTIONS.SEND_ACTIVE_CONTACT_TEMPLATE,
                MORE_ACTIONS.UPDATE_PACIENT,
              ]}
            />
          </FlexColumnRow>
        ),
        SubCell: () => null,
      },
      {
        Header: '',
        accessor: 'search',
        width: 0,
        Cell: () => null,
      },
    ],
    // eslint-disable-next-line
    [
      moduleAccess,
      loadMenu,
      history,
      isTelemedicineStep,
      stepActive,
      sector,
      isSchedule,
      isScheduleEnabled,
    ]
  );

  function prepareData(patientsList) {
    function handleMonitoringDays(individualPatientDocument) {
      if (
        individualPatientDocument?.monitoring?.dataUltimoEnvio &&
        individualPatientDocument?.monitoring?.dataPrimeiroEnvio
      ) {
        const period = differenceInDays(
          new Date(individualPatientDocument?.monitoring?.dataUltimoEnvio),
          new Date(individualPatientDocument?.monitoring?.dataPrimeiroEnvio)
        );

        return period === 0 ? 1 : period;
      }
      return null;
    }

    return patientsList
      .filter((individualPatientDocument) => sector?.rule(individualPatientDocument))
      .map((individualPatientDocument) => ({
        ...individualPatientDocument,
        alertDate: individualPatientDocument?.alert?.dataAlerta
          ? formatTo(individualPatientDocument?.alert?.dataAlerta, DATE_FORMAT.datetime)
          : null,
        alertResult: individualPatientDocument?.alert?.resultado
          ? ColorDictionary.Badge[individualPatientDocument.alert.resultado].name
          : null,
        patient: {
          ...individualPatientDocument.patient,
          nome: individualPatientDocument.patient.nome,
        },
        comorbidities: individualPatientDocument?.symptoms
          ? alertComorbidities(individualPatientDocument.symptoms)
          : null,
        tags: individualPatientDocument?.tags,
        status: actualStatusText(individualPatientDocument),
        flow: individualPatientDocument?.moduleParams?.flow,
        monitoringDays: handleMonitoringDays(individualPatientDocument),
        activeContactStatus: individualPatientDocument?.chat?.activeContactStatus,
      }));
  }

  return (
    <>
      {isTelemedicineStep ? (
        <div className="doctorsAvailableCotainer">
          <div
            style={{
              borderRadius: 50,
              width: 9,
              height: 9,
              backgroundColor: '#00A205',
              marginTop: '10px',
              marginRight: '10px',
            }}
          />
          <div className="text-left">
            <span className="doctorsAvailable">{totalDoctorsOnline} MÉDICOS ONLINE</span>
            <Button
              className="patient-table__button"
              disabled={!telemedicineIsEnabled && !doctorInRoom}
              modifiers={[
                'btnSmall',
                'bgWhite',
                'textBlue',
                'btnFlex',
                'marginBottom20',
                'fontSize12',
              ]}
              onClick={toogleDoctorStatus}
            >
              <span>
                <i className="mdi mdi-play-pause" /> {doctorInRoom ? 'DESATIVAR' : 'ATIVAR'}{' '}
                CONSULTAS
              </span>
            </Button>
          </div>
        </div>
      ) : null}
      <Datatable
        columns={columns}
        loading={loading}
        data={documentsData?.length ? prepareData(documentsData) : []}
        fetchData={fetchData}
        refetch={refetch}
        totalData={data?.documentsByPage?.totalDocuments}
        totalPages={data?.documentsByPage?.totalPages}
        contextKey={sector?.slug}
        cacheKey={sector?.slug}
        key={sector?.slug}
      />
      <div className="export-data-button">
        <ButtonCsv
          documentsData={documentsData}
          prepareData={prepareData}
          exportCsvParams={exportCsvParams}
          sectorName={sectorName}
          stepActive={stepActive}
          moduleAccess={moduleAccess}
          sector={sector}
        />
      </div>
    </>
  );
}

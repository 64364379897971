import { onlyUnique } from 'Utils/Dictionary/AlertDictionary';

import { Timeline } from '../styled';

const alertTypes = ['custom', 'grey', 'blue', 'green', 'yellow', 'red'];

const TimelineAlert = ({ timelineItem, idx, timelineItemsCount, shouldShow }) => {
  if (!shouldShow) {
    return null;
  }
  const lastAlert = alertTypes.reduce(
    (previousAlert, alert) =>
      timelineItem.symptoms[alert] && timelineItem.symptoms[alert].length > 0
        ? alert
        : previousAlert,
    ''
  );

  const color = idx === timelineItemsCount - 1 ? lastAlert : '';

  const uniqueValuesAlert = {};
  Object.values(timelineItem.symptoms).forEach((alert) => {
    if (alert && Array.isArray(alert)) {
      return alert?.forEach((symptom) => {
        if (!uniqueValuesAlert[symptom.questionId]) {
          uniqueValuesAlert[symptom.questionId] = {
            type: symptom.type,
            question: symptom.question,
            values: symptom.values,
          };
        }
      });
    }
  });

  return (
    <>
      {/* <Timeline.Alert>{lastAlert}</Timeline.Alert> */}
      <Timeline.Body color={color}>
        {
          Object.values(uniqueValuesAlert)?.map((symptom, symptomIdx) => {
            return symptom.values.filter(onlyUnique).map((ss, ssIdx) => (
              <Timeline.Body.Item color={color} key={`${symptomIdx}${ssIdx}`}>
                {symptom.type === 'buttons' || symptom.type === 'multiple'
                  ? `${symptom.question} `
                  : ''}
                {ss}
              </Timeline.Body.Item>
            ));
          })
          // <Timeline.Body.Item>Nenhum Sintoma</Timeline.Body.Item>
        }
      </Timeline.Body>
    </>
  );
};

export default TimelineAlert;

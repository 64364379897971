import { useContext, useCallback, useLayoutEffect, useRef, useState, useEffect } from 'react';
import Modal from 'Components/Modal';
import { Subtitle, Title } from 'Components/Modal/styled';
import Select from 'Components/NewSelect';
import ScheduleTimes from './ScheduleTimes';
import Calendar from '../Calendar';
import { mdiAccountArrowRight } from '@mdi/js';
import * as Sentry from '@sentry/react';
import {
  CalendarContainer,
  CancelButton,
  ConfirmButton,
  ConfirmPatient,
  ConfirmPatientName,
  ConfirmTag,
  ConfirmTags,
  ConfirmText,
  ConfirmTextBold,
  ConfirmTime,
  ConfirmTitle,
  Content,
  ContentConfirm,
  Footer,
  Header,
  Icon,
  TagsContainer,
} from './styled';

import 'moment-timezone';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { ModalScheduleReminderActions } from 'store/ducks/modal_schedule_reminder';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { Toast } from 'Components';
import useCache from 'Hooks/useCache';
import ModuleContext from 'Context/moduleContext';

import { ColorDictionary } from 'Utils/Dictionary/ColorDictionary';
import { BadgeWithColor } from 'Components/Badge';
import { useDataTable } from 'Components/Datatable/container';
import { useSetReminderScheduleMutation } from './__generated__/index.generated';
import TagsModel from 'Models/TagsModel';
import { useLazyQuery } from '@apollo/client';
import { getEntityId } from '../../Utils/Config/SectorConfig';

export const setReminderScheduleMutation = gql`
  mutation setReminderSchedule(
    $type: String
    $slotInitialTime: String!
    $slotEndTime: String!
    $cpf: String!
    $entityId: Int!
    $module: String!
    $userId: Int!
    $tags: [String]!
  ) {
    setReminderSchedule(
      type: $type
      slotInitialTime: $slotInitialTime
      slotEndTime: $slotEndTime
      cpf: $cpf
      entityId: $entityId
      module: $module
      userId: $userId
      tags: $tags
    ) {
      success
      message
    }
  }
`;

export default function ModalScheduleReminder() {
  const dispatch = useDispatch();
  const { reminder, step, date, interval, document } = useSelector(
    ({ modal_schedule_reminder }) => modal_schedule_reminder
  );
  const [setReminderSchedule, { loading }] = useSetReminderScheduleMutation();

  const [user] = useCache('user');
  const { moduleAccess } = useContext(ModuleContext);
  const currentDate = moment();
  const { fetch } = useDataTable();
  const calendarContainerRef = useRef();
  const [contentHeight, setContentHeight] = useState(340);

  const _onResized = useCallback(() => {
    if (calendarContainerRef.current) {
      setContentHeight(calendarContainerRef.current.clientHeight);
    }
  }, []);

  useLayoutEffect(() => {
    const current = calendarContainerRef.current;
    let resizeObserver = null;
    if (current) {
      setContentHeight(current.clientHeight);
      resizeObserver = new ResizeObserver(_onResized).observe(calendarContainerRef.current);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
    // eslint-disable-next-line
  }, [calendarContainerRef.current, _onResized]);

  const onChangeDate = (date) => {
    dispatch(ModalScheduleReminderActions.modalScheduleSetDate(date));
  };

  const handleStep = (action) => () => {
    if (action === 'next') {
      if (step === 1) {
        return handleSubmit();
      }
      dispatch(ModalScheduleReminderActions.modalScheduleSetStep(step + 1));
    } else {
      if (step === 0) {
        return handleClose();
      }
      dispatch(ModalScheduleReminderActions.modalScheduleSetStep(step - 1));
    }
  };

  const handleClose = () => {
    dispatch(ModalScheduleReminderActions.modalScheduleReset());
  };

  const handleSubmit = async () => {
    try {
      const { data } = await setReminderSchedule({
        variables: {
          type: 'reminder',
          entityId: parseInt(user.entityId),
          module: moduleAccess,
          slotInitialTime: interval.startTime,
          slotEndTime: interval.endTime,
          userId: user.userId,
          cpf: document?.patient?.cpf,
          tags: selectedTags.map(({ value }) => value),
        },
      });

      if (data && !data.setReminderSchedule?.success) {
        throw new Error(data.setReminderSchedule?.message ?? 'Erro inesperado');
      }

      toast(
        <Toast
          type="success"
          title="Lembrete de atendimento agendado com sucesso"
          subtitle={
            <>
              O paciente ficará aguardando na etapa <b>Chat &gt; Adiados</b>.<br />
              Na data agendada, ele aparecerá na etapa <b>Triagem</b> para o atendimento.
            </>
          }
        />
      );

      fetch();
      handleClose();
    } catch (e) {
      Sentry.captureException(e);
      toast(
        <Toast
          type="error"
          title="Não foi possível agendar o lembrete de atendimento."
          subtitle={e.message}
        />
      );
    }
  };

  const [fetchTags, { data: tagsList, refetch }] = useLazyQuery(
    TagsModel.GET_ALL_TAGS(getEntityId()),
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (reminder) {
      if (refetch) {
        refetch();
      } else {
        fetchTags();
      }
    }
  }, [reminder, fetchTags, refetch]);

  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    setTagOptions(
      tagsList?.getAllTags?.map((item) => ({
        value: item,
        label: item,
      })) ?? []
    );
  }, [tagsList, setTagOptions]);

  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setSelectedTags(document?.tags?.map((tag) => ({ value: tag, label: tag })) ?? []);
  }, [document, setSelectedTags]);

  return (
    <Modal isOpen={reminder} onClose={handleClose} noPadding width="732px">
      <Header>
        <Title>Agendar lembrete de atendimento</Title>
        <Subtitle>
          {date
            ? moment(date).utc().locale('pt').format('dddd[,] DD [de] MMMM')
            : 'Data não selecionada'}
          <br />
          {step === 0 &&
            'Selecione o dia e o horário para lembrar sobre o atendimento deste paciente.'}
          {step === 1 &&
            'Confira os dados de agendamento deste paciente e confirme se estiver correto.'}
        </Subtitle>
      </Header>
      {step === 0 && (
        <>
          <Content>
            <CalendarContainer ref={calendarContainerRef}>
              <Calendar
                minDate={currentDate.toDate()}
                maxDate={currentDate.add(365, 'days').toDate()}
                onChange={onChangeDate}
                defaultValue={date}
              />
            </CalendarContainer>
            <ScheduleTimes height={contentHeight} date={date} value={interval} />
          </Content>
          <TagsContainer>
            <Subtitle style={{ paddingBottom: '12px' }}>
              Selecione ou adicione tags relacionadas ao motivo do adiamento
            </Subtitle>
            <Select
              isMulti
              isClearable
              placeholder="Selecione ou crie uma nova tag"
              createLabel="Adicionar tag"
              noOptionsMessage="Nenhuma tag encontrada"
              creatable
              isSearchable
              options={tagOptions}
              onChange={setSelectedTags}
              value={selectedTags}
              maxLength={30}
            />
          </TagsContainer>
        </>
      )}
      {step === 1 && (
        <ContentConfirm>
          <Icon
            style={{ marginBottom: 8 }}
            path={mdiAccountArrowRight}
            size={1.4}
            color="#0071eb"
          />
          <ConfirmTitle>Confirmar e Mover paciente</ConfirmTitle>
          <ConfirmText style={{ width: 500 }}>
            O paciente ficará aguardando na etapa de&nbsp;
            <ConfirmTextBold>Chat &gt; Adiados.</ConfirmTextBold>
            <br />
            Na data agendada, ele aparecerá na etapa <ConfirmTextBold>Triagem</ConfirmTextBold> para
            o atendimento.
          </ConfirmText>
          <ConfirmPatient>
            <BadgeWithColor color="reminder">
              {ColorDictionary?.Badge?.reminder?.name}
            </BadgeWithColor>
            <ConfirmPatientName>{document?.patient?.nome}</ConfirmPatientName>
          </ConfirmPatient>
          <ConfirmTime>
            {moment(date).locale('pt').format('dddd[,] DD [de] MMMM')} |&nbsp;
            {moment(interval?.startTime).format('HH:mm')} -&nbsp;
            {moment(interval?.endTime).format('HH:mm')}
          </ConfirmTime>
          <ConfirmTags>
            <ConfirmText style={{ marginBottom: 0, width: 'auto' }}>Tags:</ConfirmText>
            {selectedTags.map(({ value }, index) => (
              <ConfirmTag key={`tag-${index}`}>{value}</ConfirmTag>
            ))}
          </ConfirmTags>
        </ContentConfirm>
      )}
      <Footer>
        <CancelButton className="text" onClick={handleStep('prev')}>
          {step === 0 ? 'Cancelar' : 'Voltar'}
        </CancelButton>
        <ConfirmButton
          disabled={!interval}
          loading={loading}
          style={{ height: 32 }}
          className="contained"
          onClick={handleStep('next')}
        >
          {step === 0 ? 'Continuar' : 'Confirmar'}
        </ConfirmButton>
      </Footer>
    </Modal>
  );
}

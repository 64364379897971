import { MonitoringDocument } from 'data/types';
import { getMonitoringDays } from 'Utils/Helpers/MonitoringHelper';

import * as Styled from './styled';

type Props = {
  monitoring?: MonitoringDocument;
  complaint?: string;
  moduleAccess: string;
};

const MonitoringColumn = ({ monitoring, complaint, moduleAccess }: Props) => {
  if (!monitoring || !complaint || !moduleAccess) {
    return <></>;
  }
  const monitoringDays = getMonitoringDays({ monitoring, complaint, moduleAccess });

  return (
    <Styled.Container>
      <span title={`Início: ${monitoringDays.start} | Fim:  ${monitoringDays.end}`}>
        {monitoringDays.currentDay === -1 ? (
          <></>
        ) : (
          `${monitoring?.type === 'period' ? 'Dia' : 'Etapa'} ${monitoringDays.currentDay}/${
            monitoringDays.maximumDay
          }`
        )}
      </span>
    </Styled.Container>
  );
};

export default MonitoringColumn;

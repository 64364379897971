import { useMemo } from 'react';

import Tooltip from 'Components/Tooltip';

import * as Styled from './styled';
import { NotificationCircle } from 'Components';

type OwnProps = {
  icon: string;
  activeColor?: string;
  color?: string;
  backgroundActive?: string;
  background?: string;
  backgroundDisabled?: string;
  counter?: number;
  onClick?: (...args: any[]) => any;
  marginHorizontal?: number;
  disabled?: boolean;
  messageHelp?: string;
};

type Props = OwnProps & typeof IconButton.defaultProps;

const IconButton = ({
  icon,
  color,
  activeColor,
  background,
  marginHorizontal,
  backgroundActive,
  backgroundDisabled,
  counter,
  onClick,
  disabled,
  messageHelp,
}: Props) => {
  const withTooltip = useMemo(() => Boolean(messageHelp), [messageHelp]);

  const variantsChild = {
    active: {
      background: backgroundActive,
    },
    normal: {
      background,
    },
  };

  const Child = (
    <Styled.Container
      disabled={disabled}
      $marginHorizontal={marginHorizontal}
      onClick={disabled ? null : onClick}
      active={Boolean(counter)}
      background={background}
      $withTooltip={withTooltip}
      variants={variantsChild}
      initial={'normal'}
      animate={Boolean(counter) ? 'active' : 'normal'}
    >
      <Styled.Icon
        path={icon}
        size={0.65}
        color={disabled ? backgroundDisabled : Boolean(counter) ? activeColor : color}
      />
      <NotificationCircle countNotification={counter} />
    </Styled.Container>
  );

  if (withTooltip) {
    return (
      <Tooltip text={messageHelp} maxWidth={200}>
        {Child}
      </Tooltip>
    );
  }

  return Child;
};

IconButton.defaultProps = {
  activeColor: '#FFFFFF',
  color: '#707070',
  backgroundActive: '#0071eb',
  background: '#F4F5F7',
  backgroundDisabled: '#DBDBDB',
  counter: 0,
  onClick: null,
  marginHorizontal: 8,
  disabled: false,
  messageHelp: '',
};

export default IconButton;

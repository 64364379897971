import styled from 'styled-components';
import IconBase from '@mdi/react';

export const Wrapper = styled.div.attrs((props) => ({ ...props }))`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.color};

  a {
    text-decoration: none;
    color: ${(props) => props.color};

    &:hover,
    &:active {
      color: ${(props) => props.color};
    }
  }
`;

export const Icon = styled(IconBase)`
  margin-right: 8px;
`;

const cssUnit = {
  cm: true,
  mm: true,
  in: true,
  px: true,
  pt: true,
  pc: true,
  em: true,
  ex: true,
  ch: true,
  rem: true,
  vw: true,
  vh: true,
  vmin: true,
  vmax: true,
  '%': true,
};

export const parseLengthAndUnit = (size) => {
  if (typeof size === 'number') {
    return {
      value: size,
      unit: 'px',
    };
  }
  let value;
  const valueString = (size.match(/^[0-9.]*/) || '').toString();
  if (valueString.includes('.')) {
    value = parseFloat(valueString);
  } else {
    value = parseInt(valueString, 10);
  }

  const unit = (size.match(/[^0-9]*$/) || '').toString();

  if (cssUnit[unit]) {
    return {
      value,
      unit,
    };
  }

  console.warn(`React Spinners: ${size} is not a valid css value. Defaulting to ${value}px.`);

  return {
    value,
    unit: 'px',
  };
};

export const cssValue = (value) => {
  const lengthWithunit = parseLengthAndUnit(value);

  return `${lengthWithunit.value}${lengthWithunit.unit}`;
};

const baseStyle = {
  container: { width: '100%', position: 'relative' },
  requiredIndicator: {
    marginStart: 1,
    color: 'red.400',
  },
  helperText: {
    mt: 1.5,
    color: 'gray.400',
    lineHeight: 'normal',
    fontSize: 'sm',
  },
};

export const formTheme = {
  baseStyle,
};

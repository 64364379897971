import { gql } from '@apollo/client';

const ServiceStepModel = {
  GET_SERVICE_STEP: () => gql`
    query getServiceStep {
      getServiceStep {
        id
        name
      }
    }
  `,
};

export default ServiceStepModel;

import cacheService from 'Services/cache';

class LocalCache {
  setUser(user) {
    cacheService.set('user', user);
  }

  setItemsPerPage(items) {
    cacheService.set('itemsPerPage', items);
  }

  async deleteUser() {
    await cacheService.remove('user');
  }

  async deleteMemedToken() {
    await cacheService.remove('memed_token');
  }

  getTmpUser() {
    const tmpUser = cacheService.get('tmpUser');
    if (tmpUser) {
      return tmpUser;
    }

    return null;
  }

  getUser() {
    const user = cacheService.get('user');
    if (user) {
      return user;
    }

    return false;
  }

  getEntity() {
    const user = cacheService.get('user');
    if (user && user?.entity) {
      return user?.entity;
    }

    return false;
  }

  getModuleData(module) {
    const user = this.getUser();
    return user.moduleData ? (user.moduleData[module] ? user.moduleData[module] : {}) : {};
  }

  isDataEncrypted() {
    const user = this.getUser();
    return user && user?.entity?.isDataEncrypted;
  }

  getItemsPerPage() {
    const items = cacheService.get('itemsPerPage');
    if (items) {
      return parseInt(items);
    }

    return 6;
  }

  getExpanded() {
    const menu = cacheService.get('menu_expanded');
    if (menu) {
      return menu;
    }

    return false;
  }

  setPatient(patient) {
    cacheService.set('patient', patient);
  }

  deletePatient() {
    cacheService.remove('patient');
  }

  setExpanded(expanded) {
    cacheService.set('menu_expanded', expanded);
  }

  getPatient() {
    const patient = cacheService.get('patient');
    if (patient) {
      return patient;
    }

    return null;
  }

  getModules() {
    return cacheService.get('modules');
  }

  getModule() {
    const module = cacheService.get('module_access');
    if (module) {
      return module;
    }

    return 'covid';
  }

  setModule(module) {
    cacheService.set('module_access', module);
  }

  clearCache() {
    cacheService.purge();
  }
}

export default new LocalCache();

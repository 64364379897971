import styled from 'styled-components';
import { lighten } from 'polished';

export const Title = styled.span`
  font-family: 'Roboto', sans-serif;
  color: #707070;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
`;
export const TextMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  color: ${(props) => props.color || '#707070'};
  text-align: left;
  padding: 15px 0 0 15px;
  font-size: 14px;
  font-weight: 400;
`;
export const Value = styled.span`
  font-family: 'Roboto', sans-serif;
  color: ${(props) => props.color || '#707070'};
  text-align: left;
  padding-left: 15px;
  font-size: 28px;
  font-weight: 500;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  -ms-flex-direction: column;
  height: 80px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #f6f6f8;
  background-color: ${(props) => props.color || '#F6F6F8'};
  cursor: default;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

export const CancelText = styled.span`
  color: #0071eb;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  align-self: center;
  margin-right: 50px;
  :hover {
    color: ${lighten(0.2, '#0071eb')};
  }
`;

export const DownloadText = styled.span`
  color: #0071eb;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 400;
  padding-right: 22px;
  cursor: pointer;
`;

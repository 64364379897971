import { gql } from '@apollo/client';

const DailyLimitMessagesSentModel = {
  GET_MESSAGES_LIMIT: (entityId) => gql`
    query getDailyLimitMessagesSent {
      getDailyLimitMessagesSent(entityId: ${entityId}) {
        messagesSent
        dailyLimitMessagesSent
      }
    }
  `,
};

export default DailyLimitMessagesSentModel;

import { actualStatusText } from 'Utils/Helpers/StatusHelper';

import * as Styled from './styled';

type Props = {
  collection: Record<string, any>;
};

const StatusColumn = ({ collection }: Props) => {
  return <Styled.Container>{actualStatusText(collection)}</Styled.Container>;
};

export default StatusColumn;

import { useRef, useMemo, useLayoutEffect } from 'react';

const findFirstElementInViewPort = (elements) =>
  Array.prototype.find.call(elements, (element) => element.getBoundingClientRect().y > 0);

const usePinScroll = ({ elementsQuery, isPin }) => {
  const containerRef = useRef(null);

  const scrollTo = useMemo(() => {
    if (isPin) {
      const nodeElements = containerRef.current?.querySelectorAll(elementsQuery);

      if (nodeElements) {
        return findFirstElementInViewPort(nodeElements);
      }
    }

    return undefined;
  }, [elementsQuery, isPin]);

  useLayoutEffect(() => {
    if (scrollTo) {
      scrollTo.scrollIntoView({
        block: 'start',
      });
    }
  }, [scrollTo, isPin]);

  return { containerRef };
};

export default usePinScroll;

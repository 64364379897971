import { LinkWrapper } from '../Wrappers';

import * as Styled from './styled';

type OwnProps = {
  color?: string;
  icon?: string;
  label: string;
  url: string;
};

type Props = OwnProps & typeof Navlink.defaultProps;

const Navlink = ({ label, url, icon, color }: Props) => (
  <Styled.Wrapper color={color}>
    <LinkWrapper to={url} style={{ display: 'flex', alignItems: 'center' }}>
      {icon && <Styled.Icon color={color} size={1} path={icon} />}
      {label}
    </LinkWrapper>
  </Styled.Wrapper>
);

Navlink.defaultProps = {
  color: '#a9a9a9',
  icon: null,
};

export default Navlink;

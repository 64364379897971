import { useContext, useCallback, useLayoutEffect, useRef, useState } from 'react';
import Modal from 'Components/Modal';
import { Subtitle, Title } from 'Components/Modal/styled';
import ScheduleTimes from './ScheduleTimes';
import Calendar from '../Calendar';
import { mdiAccountArrowRight } from '@mdi/js';
import * as Sentry from '@sentry/react';
import {
  CalendarContainer,
  CancelButton,
  ConfirmButton,
  ConfirmPatient,
  ConfirmPatientName,
  ConfirmText,
  ConfirmTextBold,
  ConfirmTime,
  ConfirmTitle,
  Content,
  ContentConfirm,
  Footer,
  Header,
  Icon,
} from './styled';

import { useDispatch, useSelector } from 'react-redux';
import { ModalScheduleActions } from 'store/ducks/modal_schedule';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Toast } from 'Components';
import useCache from 'Hooks/useCache';
import ModuleContext from 'Context/moduleContext';

import 'moment-timezone';
import moment from 'moment';

import { ColorDictionary } from 'Utils/Dictionary/ColorDictionary';
import { BadgeWithColor } from 'Components/Badge';
import { useDataTable } from 'Components/Datatable/container';

const SET_TELECONSULTATION_SCHEDULE = gql`
  mutation setTeleconsultationSchedule(
    $slotInitialTime: String!
    $slotEndTime: String!
    $cpf: String!
    $entityId: Int!
    $module: String!
    $userId: Int!
  ) {
    setTeleconsultationSchedule(
      slotInitialTime: $slotInitialTime
      slotEndTime: $slotEndTime
      cpf: $cpf
      entityId: $entityId
      module: $module
      userId: $userId
    )
  }
`;

const UPDATE_TELECONSULTATION_SCHEDULE = gql`
  mutation updateTeleconsultationSchedule(
    $type: String
    $entityId: Int!
    $currentSlotInitialTime: String!
    $slotInitialTime: String!
    $slotEndTime: String!
    $cpf: String!
    $module: String!
    $userId: Int!
  ) {
    updateTeleconsultationSchedule(
      type: $type
      entityId: $entityId
      currentSlotInitialTime: $currentSlotInitialTime
      slotInitialTime: $slotInitialTime
      slotEndTime: $slotEndTime
      cpf: $cpf
      module: $module
      userId: $userId
    )
  }
`;

export default function ModalSchedule() {
  const dispatch = useDispatch();
  const { schedule, step, date, interval, document, isUpdate } = useSelector(
    ({ modal_schedule }) => modal_schedule
  );
  const [setTeleconsultationSchedule, { loading }] = useMutation(SET_TELECONSULTATION_SCHEDULE);
  const [updateTeleconsultationSchedule, { loading: loadingUpdate }] = useMutation(
    UPDATE_TELECONSULTATION_SCHEDULE
  );
  const [user] = useCache('user');
  const { moduleAccess } = useContext(ModuleContext);
  const currentDate = moment();
  const { fetch } = useDataTable();
  const calendarContainerRef = useRef();
  const [contentHeight, setContentHeight] = useState(340);

  const _onResized = useCallback(() => {
    if (calendarContainerRef.current) {
      setContentHeight(calendarContainerRef.current.clientHeight);
    }
  }, []);

  useLayoutEffect(() => {
    const current = calendarContainerRef.current;
    let resizeObserver = null;
    if (current) {
      setContentHeight(current.clientHeight);
      resizeObserver = new ResizeObserver(_onResized).observe(calendarContainerRef.current);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
    // eslint-disable-next-line
  }, [calendarContainerRef.current, _onResized]);

  const onChangeDate = (date) => {
    dispatch(ModalScheduleActions.modalScheduleSetDate(date));
  };

  const handleStep = (action) => () => {
    if (action === 'next') {
      if (step === 1) {
        return handleSubmit();
      }
      dispatch(ModalScheduleActions.modalScheduleSetStep(step + 1));
    } else {
      if (step === 0) {
        return handleClose();
      }
      dispatch(ModalScheduleActions.modalScheduleSetStep(step - 1));
    }
  };

  const handleClose = () => {
    dispatch(ModalScheduleActions.modalScheduleReset());
  };

  const handleSubmit = async () => {
    try {
      if (isUpdate) {
        await updateTeleconsultationSchedule({
          variables: {
            type: null,
            entityId: parseInt(user.entityId),
            module: moduleAccess,
            slotInitialTime: interval.startTime,
            slotEndTime: interval.endTime,
            userId: user.userId,
            cpf: document?.patient?.cpf,
            currentSlotInitialTime:
              document?.serviceSteps?.telemedicine?.scheduling?.slotInitialTime,
          },
        });

        toast(
          <Toast
            type="success"
            title="Teleconsulta remarcada com sucesso"
            subtitle="O paciente já recebeu a confirmação do reagendamento."
          />
        );
      } else {
        await setTeleconsultationSchedule({
          variables: {
            entityId: parseInt(user.entityId),
            module: moduleAccess,
            slotInitialTime: interval.startTime,
            slotEndTime: interval.endTime,
            userId: user.userId,
            cpf: document?.patient?.cpf,
          },
        });

        toast(
          <Toast
            type="success"
            title="Teleconsulta agendada com sucesso"
            subtitle={
              <>
                O paciente já recebeu a confirmação do agendamento, e foi movido para a etapa de{' '}
                <b>{'Vídeo > Agendamentos'}</b>, permanecendo lá até que sua consulta seja{' '}
                realizada.
              </>
            }
          />
        );
      }
      fetch();
      handleClose();
    } catch (e) {
      Sentry.captureException(e);
      toast(<Toast type="error" title="Não foi possível mover o paciente" subtitle={e.message} />);
    }
  };

  return (
    <Modal isOpen={schedule} onClose={handleClose} noPadding width="732px">
      <Header>
        <Title>
          Agendamento de <span style={{ color: '#0071eb' }}>Teleconsultas</span>
        </Title>
        <Subtitle>
        {date
            ? moment(date).utc().locale('pt').format('dddd[,] DD [de] MMMM')
            : 'Data não selecionada'}
          <br />
          Selecione o dia e slot de horário, conforme a disponibilidade do paciente e da equipe
        </Subtitle>
      </Header>
      {step === 0 && (
        <Content>
          <CalendarContainer ref={calendarContainerRef}>
            <Calendar
              minDate={currentDate.toDate()}
              maxDate={currentDate.add(6, 'days').toDate()}
              onChange={onChangeDate}
              value={date}
            />
          </CalendarContainer>
          <ScheduleTimes height={contentHeight} date={date} value={interval} />
        </Content>
      )}
      {step === 1 && (
        <ContentConfirm style={{ height: contentHeight }}>
          <Icon
            style={{ marginBottom: 8 }}
            path={mdiAccountArrowRight}
            size={1.4}
            color="#0071eb"
          />
          <ConfirmTitle>Confirmar e Mover paciente</ConfirmTitle>
          <ConfirmText>
            Ao confirmar o agendamento, o paciente será movido para etapa{' '}
            <ConfirmTextBold>Vídeo {'>'} Agendamentos</ConfirmTextBold>
          </ConfirmText>
          <ConfirmPatient>
            <BadgeWithColor color={document?.alert?.resultado}>
              {ColorDictionary?.Badge[document?.alert?.resultado]?.name}
            </BadgeWithColor>
            <ConfirmPatientName>{document?.patient?.nome}</ConfirmPatientName>
          </ConfirmPatient>
          <ConfirmTime>
          {moment(date).utc().locale('pt').format('dddd[,] DD [de] MMMM')} |{' '}
            {moment(interval?.startTime).utc().format('HH:mm')} -{' '}
            {moment(interval?.endTime).utc().format('HH:mm')}
          </ConfirmTime>
        </ContentConfirm>
      )}
      <Footer>
        <CancelButton className="text" onClick={handleStep('prev')}>
          {step === 0 ? 'Cancelar' : 'Voltar'}
        </CancelButton>
        <ConfirmButton
          disabled={!interval}
          loading={loading || loadingUpdate}
          style={{ height: 32 }}
          className="contained"
          onClick={handleStep('next')}
        >
          {step === 0 ? 'Continuar' : 'Confirmar'}
        </ConfirmButton>
      </Footer>
    </Modal>
  );
}

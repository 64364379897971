import * as axios from 'axios';

import DashboardModel from 'Models/DashboardModel';
import { getEntityId } from 'Utils/Config/SectorConfig';
import environment from 'Utils/Dictionary/environment';
import LocalCache from '../../Services/LocalCache';

export async function exportDataToCsv(
  prepareData,
  colRow,
  filename,
  stepActive,
  moduleAccess,
  sector,
  setValue,
  setPagesTotal,
  setStatus,
  setStatusLoading
) {
  let queryDocuments;
  let documents;
  let totalPages;
  const fetchData = [];

  const pageDocuments = 1;
  const limitDocuments = 300;

  if (getEntityId()?.[0] === 4) {
    queryDocuments = DashboardModel.GET_ALL(4, moduleAccess);
  } else {
    // TODO: create a new query to fetch the data in the database without using liveQuery
    queryDocuments = DashboardModel.DOCUMENTS_BY_PAGE(false);
  }

  const request = {
    call: async (queryDocuments, page, limit) => {
      return await Promise.all([
        await axios.post(
          environment.GRAPHQL_ENDPOINT,
          {
            query: queryDocuments,
            variables: {
              entityId: getEntityId(),
              serviceStep: stepActive,
              sector: sector?.slug,
              module: moduleAccess,
              page,
              limit,
            },
          },
          { headers: { authorization: `Bearer ${LocalCache.getUser().token}` } }
        ),
      ]);
    },
  };

  setStatus('AGUARDE EXPORTAR');
  setStatusLoading(true);

  await request
    .call(queryDocuments, pageDocuments, limitDocuments)
    .then(async (documentsByPage) => {
      if (getEntityId()?.[0] === 4) {
        documents = documentsByPage?.[0]?.data?.data?.collections;
        totalPages = documentsByPage?.[0]?.data?.data?.documentsByPage?.totalPages;
      } else {
        documents = documentsByPage?.[0]?.data?.data?.documentsByPage?.records;
        totalPages = documentsByPage?.[0]?.data?.data?.documentsByPage?.totalPages;
      }
      fetchData.push(...documents);

      setPagesTotal(totalPages);

      const pageTotal = totalPages;
      let itens = 2;

      while (itens <= pageTotal) {
        setValue(itens);
        const documentsByPage = await request.call(queryDocuments, itens, limitDocuments);
        if (getEntityId()?.[0] === 4) {
          documents = documentsByPage?.[0]?.data?.data?.collections;
          totalPages = documentsByPage?.[0]?.data?.data?.documentsByPage?.totalDocuments;
        } else {
          documents = documentsByPage?.[0]?.data?.data?.documentsByPage?.records;
          totalPages = documentsByPage?.[0]?.data?.data?.documentsByPage?.totalDocuments;
        }
        fetchData.push(...documents);
        itens++;
      }
    });

  setStatus('EXPORTAR P/ EXCEL');
  setStatusLoading(false);
  setPagesTotal(null);
  setValue(null);

  const data = await prepareData(fetchData);
  const aLink = document.createElement('a');

  const treatedCsvArray = new Promise((resolve) => {
    const columns = colRow.map(({ column }) => column);
    const rows = colRow.map(({ row }) => row);

    const csv = [columns].concat(
      data?.map((d) => rows.map((r) => r.split('.').reduce((prev, curr) => prev[curr], d)))
    );

    resolve(csv);
  });

  treatedCsvArray
    .then((csvArray) => {
      const csvString = csvArray.map((row) => `"${row.join('";"')}"`).join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const encodedUrl = window.URL.createObjectURL(blob);
      aLink.setAttribute('href', encodedUrl);
      aLink.setAttribute('type', 'text/csv');
      aLink.setAttribute('download', filename);
      document.body.appendChild(aLink);
      aLink.click();
    })
    .then(() => {
      document.body.removeChild(aLink);
    })
    .catch((e) => {
      console.error(e.message);
      alert('Houve um erro ao tentar exportar para Excel');
    });
}

import { useEffect } from 'react';

import LocalCache from 'Services/LocalCache';

const useMovidesk = () => {
  const userMovidesk = LocalCache.getUser();

  useEffect(() => {
    const scriptMD = document.createElement('script');
    scriptMD.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
    scriptMD.async = true;
    document.body.appendChild(scriptMD);

    const scriptUR = document.createElement('script');
    scriptUR.src = 'https://sak.userreport.com/d1026e0f1f3d421ba2d5/launcher.js';
    scriptUR.async = true;
    scriptUR.id = 'userreport-launcher-script';
    document.body.appendChild(scriptUR);

    setTimeout(() => {
      if (window.movideskLogin) {
        window.movideskLogin({
          name: userMovidesk.name,
          email: userMovidesk.email,
          stayConnected: false,
          emptySubject: false,
          startChat: false,
        });
      }
    }, 3000);

    return () => {
      document.body.removeChild(scriptMD);
      document.body.removeChild(scriptUR);
    };
  }, [userMovidesk]);
};

export default useMovidesk;

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = (...params: string[]) => {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return params.map((param) => searchParams.get(param));
  }, [search]);
};

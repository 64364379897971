import LocalCache from 'Services/LocalCache';
import { SectorDictionary } from '../Dictionary/Telemedicine/SectorDictionary';

export const TelemedicineSectors = (module) => {
  const moduleAccess = LocalCache?.getModules()?.filter((item) => item?.slug === module);
  const isTelemedicineScheduleEnabled =
    moduleAccess?.[0]?.serviceStep?.isTelemedicineScheduleEnabled;
  return isTelemedicineScheduleEnabled
    ? [SectorDictionary(module).SCHEDULE, SectorDictionary(module).IN_PROGRESS]
    : [SectorDictionary(module).TRANSFERRED, SectorDictionary(module).IN_PROGRESS];
};

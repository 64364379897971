export const modifierConfig = {
  btn: () => `
        font-size: 0.75rem;
        font-weight: bold;
        height: 2.75rem;
        line-height: 2.75rem;
        border-radius: 50px;
        border: none;
        cursor: pointer;
        padding: 0 0.812rem;
    `,
  btnSmall: () => `
    font-size: 0.687rem;
    font-weight: bold;
    height: 1.875rem;
    line-height: 1.875rem;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    padding: 0 0.812rem;
`,
  btnMarginLeft: () => `
    margin-left: 5px;
  `,
  btnSmallSquare: () => `
        font-size: 14px;
        font-weight: 500;
        height: 1.875rem;
        line-height: 1.875rem;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        padding: 0 20px;
    `,
  btnLink: () => `
        text-decoration: underline;
        background: transparent;
        border: 0 none;
        cursor: pointer;
        font-size: 0.75rem;
    `,
  btnFlex: () => `
        display: inline-flex;
        align-items: center;
    `,
  btnDisabled: () => `
        color:#A9A9A9;
        border-color:#A9A9A9;
        pointer-events: none;
    `,
  fullWidth: () => `
        width: 100%;
    `,
  horizontalPadding90: () => `
        padding-left: 5.625rem;
        padding-right: 5.625rem;
    `,
  bgTransparent: () => `
      background: transparent;
    `,
  bgPrimary: () => `
        background-color: #0071eb;
    `,
  bgWhite: () => `
        background-color: #ffffff;
    `,
  textWhite: () => `
        color: #ffffff;
    `,
  textBlue: () => `
        color: #0071eb;
    `,
  textDanger: () => `
        color: #EC2C47;
    `,
  textSuccess: () => `
        color: #29B050;
    `,
  textPrimary: () => `
        color: #0071eb;
    `,
  borderPrimary: () => `
        border: 1px solid #0071eb;
    `,
  borderSuccess: () => `
        border: 1px solid #29B050;
    `,
  borderDanger: () => `
        border: 1px solid #EC2C47;
    `,
  fontSize11: () => `
        font-size: 0.687rem;
    `,
  fontSize12: () => `
        font-size: 0.75rem;
    `,
  fontSize13: () => `
        font-size: 0.812rem;
    `,
  floatRight: () => `
        float: right;
    `,
  marginBottom20: () => `
        margin-bottom: 20px;
    `,
  marginTop20: () => `
        margin-top: 20px;
    `,
  marginTop25: () => `
        margin-top: 25px;
    `,
  marginTop30: () => `
        margin-top: 30px;
    `,
  marginRight25: () => `
        margin-right: 25px;
    `,
};

import styled from 'styled-components';

export const Button = styled.button`
  &:focus {
    outline: 0;
  }
  &:hover {
    filter: brightness(0.98);
  }
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  text-align: center;
  font: normal normal bold 12px/14px Roboto;
  letter-spacing: 0px;
  padding: 10px;
  &.contained {
    background-color: #0071eb;
    color: #fff;
    &:disabled {
      background-color: #c6c6c6;
    }
  }
  &.outlined {
    border: 1px solid #0071eb;
    color: #0071eb;
  }
  &.rounded {
    border-radius: 20px !important;
  }
  &.text {
    color: #444;
    background-color: transparent;
    border: none;
  }
  &.underlined {
    text-decoration: underline;
  }
  &.uppercase {
    text-transform: uppercase;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

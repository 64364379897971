import { gql } from '@apollo/client';

const TelemedicineModel = {
  USER_UPDATE: gql`
    mutation telemedicineUserUpdate(
      $email: String!
      $enabled: Boolean!
      $firstName: String!
      $lastName: String!
      $cpf: String!
      $birthdate: String!
    ) {
      telemedicineUserUpdate(
        email: $email
        enabled: $enabled
        firstName: $firstName
        lastName: $lastName
        cpf: $cpf
        birthdate: $birthdate
      )
    }
  `,
};

export default TelemedicineModel;

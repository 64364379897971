import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const User = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;

  font-size: 15px;
  padding: 0px 12px;
  margin: 10px 0px;
`;
export const UserText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 11px;

    padding: 0px;
    margin: 0px 7px;
  }
`;
export const TextUser = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  color: #333;
  border-radius: 3px;
  padding: 7px;
  margin: 7px;
`;

export const Bot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: start;
  font-size: 15px;
  padding: 0px 12px;
  margin: 10px 0px;
`;
export const BotText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  > span {
    display: flex;
    flex-direction: row;
    color: #333;
    font-size: 11px;

    padding: 0px;
    margin: 0px 7px;
  }
`;
export const TextBot = styled.div`
  display: flex;
  flex-direction: column;
  background: #0071eb;
  border-radius: 3px;
  padding: 7px;
  margin: 7px;
  opacity: 0.9;
`;

export const UserAvatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;

  margin: 5px 3px;
  padding: 0px;
  background-color: #333;
  color: #fff;
  border-radius: 120px;
`;

export const Avatar = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 35px;

  margin: 5px 0px;
  padding: 0px;
  background-color: #ccc;
  color: #333;
  border-radius: 50%;
`;

export const Styled = {
  Container,
  UserText,
  BotText,
  User,
  Bot,
  Avatar,
  UserAvatar,
  TextUser,
  TextBot,
};

import * as Styled from './styled';

type NotificationCircleProps = {
  countNotification?: number;
};

const NotificationCircle = ({ countNotification }: NotificationCircleProps) => {
  const variantBadge = {
    active: {
      width: 18,
      height: 18,
      top: -5,
      right: -8,
      opacity: 1,
    },
    normal: {
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      opacity: 0,
    },
  };

  return (
    <Styled.BadgeCounter
      variants={variantBadge}
      animate={Boolean(countNotification) ? 'active' : 'normal'}
    >
      {countNotification}
    </Styled.BadgeCounter>
  );
};

export default NotificationCircle;

import { forwardRef, useImperativeHandle, useState } from 'react';
import { useLayer, mergeRefs } from 'react-laag';
import { AnimatePresence } from 'framer-motion';

import { spring } from 'Utils/animations';
import Tooltip from 'Components/Tooltip';

import * as Styled from './styled';

type Props = {
  icon: string;
  color?: string;
  activeColor?: string;
  backgroundActive?: string;
  background?: string;
  showStatus?: boolean;
  statusColor?: string;
  statusHelp?: string;
  children: React.ReactElement | React.ReactElement[];
  maxWidthChildren?: number;
  disabled?: boolean;
};

const IconButtonPopover = forwardRef<any, Props>(
  (
    {
      icon,
      color,
      background,
      backgroundActive,
      showStatus,
      statusColor,
      activeColor,
      children,
      maxWithChildren,
      statusHelp,
      disabled,
      disabledColor,
    },
    ref
  ) => {
    const [isOpen, handleOpen] = useState(false);

    const close = () => handleOpen(false);
    const open = () => (disabled ? null : handleOpen((prev) => !prev));

    useImperativeHandle(ref, () => ({
      close() {
        close();
      },
    }));

    const { triggerProps, layerProps, renderLayer } = useLayer({
      isOpen,
      placement: 'bottom-start',
      triggerOffset: -10,
      containerOffset: -10,
      onOutsideClick: close,
      onDisappear: close,
      overflowContainer: false,
    });

    const renderBadge = () => (
      <Styled.BadgeCounter
        color={statusColor}
        showStatus={showStatus}
        animate={{
          width: showStatus ? 7 : 0,
          height: showStatus ? 7 : 0,
          top: showStatus ? -2 : 0,
          right: showStatus ? -3 : 0,
          opacity: showStatus ? 1 : 0,
          color: statusColor,
        }}
      />
    );

    return (
      <>
        <Styled.Container
          onClick={open}
          ref={mergeRefs(ref, triggerProps.ref)}
          active={isOpen}
          background={background}
          disabled={disabled}
          backgroundActive={backgroundActive}
        >
          <>
            <Styled.Icon
              path={icon}
              size={0.75}
              color={disabled ? disabledColor : isOpen ? activeColor : color}
            />
            <Styled.ChevronDown color={disabled ? disabledColor : isOpen ? activeColor : color} />
          </>
          {statusHelp ? <Tooltip text={statusHelp}>{renderBadge()}</Tooltip> : renderBadge()}
        </Styled.Container>
        {renderLayer(
          <AnimatePresence>
            {isOpen && (
              <Styled.Popover
                {...layerProps}
                maxWidth={maxWithChildren}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={spring}
              >
                {children}
              </Styled.Popover>
            )}
          </AnimatePresence>
        )}
      </>
    );
  }
);

IconButtonPopover.displayName = 'IconButtonPopover';

IconButtonPopover.defaultProps = {
  color: '#707070',
  activeColor: '#707070',
  backgroundActive: '#D9D9D9',
  background: '#ECECEC',
  status: null,
  maxWithChildren: 200,
  disabled: false,
};

export default IconButtonPopover;

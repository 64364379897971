import GlobalStyles from './styles/GlobalStyles';

import { ChatProvider } from './context';
import Chat from './Chat';

const ChatApp = (props) => {
  const { data } = props;

  return (
    <ChatProvider>
      <Chat data={data} />
      <GlobalStyles />
    </ChatProvider>
  );
};

export default ChatApp;

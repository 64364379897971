import { useEffect, useMemo, useState } from 'react';

import Button from 'Components/NewButton';

import Styled from './styled';
import NewSelect from 'Components/NewSelect';
import RadioGroup from 'Components/RadioGroup';
import RadioButton from 'Components/RadioGroup/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { ModulesActions } from 'store/ducks/modules';
import useFilterTranslation from 'Hooks/useFilterTranslation';
import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';

import { Box, HStack, Spinner, Text } from '@chakra-ui/react';

export const filterLabel = {
  tags: 'Tag',
  ubs: 'UBS',
  grupos: 'Grupos',
  evolution: 'Evolução',
  healthCare: 'Convênio',
  status: 'Status',
  flow: 'Fluxo',
  alerts: 'Alerta',
  hasUnreadChat: 'Notificações',
};

export default function FiltersList(props) {
  const filters = useSelector(({ modules: { filters } }) => filters);

  const dispatch = useDispatch();

  const { getFilterTranslation } = useFilterTranslation();

  const setFilters = (filters) => {
    dispatch(ModulesActions.modulesSetFilters(filters));
  };

  const resetFilters = () => {
    dispatch(ModulesActions.modulesResetFilters());
    props.toggleFilters();
  };

  const [newFilters, setNewFilters] = useState({ ...filters });

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const sortByName = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const displayNotificationFilter = useFlag(
    `lc-display-notification-filter-${environment.UNLEASH_APP_ENV}`
  );

  const formattedFilters = useMemo(() => {
    const formatted = {};
    for (const key in props.data?.getAllFilters || {}) {
      const _filters = [...(props.data.getAllFilters[key] || [])];
      if (_filters?.length <= 6) {
        formatted[key] = _filters?.sort(sortByName);
      } else {
        formatted[key] = _filters?.sort(sortByName).map((item) => ({
          value: item,
          label: item,
        }));
      }
    }
    return formatted;
  }, [props.data]);

  if (props.loading) {
    return (
      <Box
        position="absolute"
        backgroundColor="white"
        color="#444444"
        border="1px solid #ececec"
        borderRadius="5px"
        zIndex="3"
        marginTop="10px"
        inset="60px 10px auto"
        boxShadow="0px 3px 15px rgba(0, 0, 0, 0.5)"
      >
        <HStack justify="center" align="center" gap="8px" padding="32px">
          <Spinner />
          <Text fontWeight="bold" margin="0">
            Carregando filtros
          </Text>
        </HStack>
      </Box>
    );
  }

  const applyFilter = () => {
    setFilters(newFilters);
    props.toggleFilters();
  };

  const toggleItem = (event, type, name) => {
    const aux = [...newFilters[type]?.selected];
    const idx = aux.indexOf(name);

    if (event.target.checked) {
      aux.push(name);
    } else {
      aux.splice(idx, 1);
    }

    setNewFilters({
      ...newFilters,
      [type]: {
        ...newFilters[type],
        selected: aux,
      },
    });
  };

  const toggleItems = (type, values) => {
    setNewFilters({
      ...newFilters,
      [type]: {
        ...newFilters[type],
        selected: [...values.map(({ value }) => value)],
      },
    });
  };

  const setFilterActive = (key) => (_, active) => {
    const newFiltersActive = {
      ...newFilters,
      [key]: {
        ...newFilters[key],
        active,
      },
    };
    if (!active) {
      newFiltersActive[key].selected = [];
    }
    setNewFilters(newFiltersActive);
  };

  return (
    <Styled.FiltersListContainer className={`${props.show ? 'showFilters' : 'hideFilters'}`}>
      <Styled.FiltersTitle>Filtrar Por</Styled.FiltersTitle>
      <Styled.FiltersListFormsContainer>
        <Styled.FiltersListForms>
          {Object.keys(filters)
            .filter((filterKey) => filterKey !== 'hasUnreadChat' || displayNotificationFilter)
            .map(
              (filterKey) =>
                Boolean(formattedFilters[filterKey]?.length || filters[filterKey]?.static) && (
                  <Styled.FiltersListColumn key={filterKey}>
                    <Styled.FiltersListForm>
                      <Styled.FiltersListTitle>{filterLabel[filterKey]}</Styled.FiltersListTitle>
                      <RadioGroup
                        value={newFilters[filterKey]?.active}
                        onChange={setFilterActive(filterKey)}
                      >
                        <RadioButton value={null} label="Ambos" />
                        <RadioButton value={true} label="Sim" />
                        <RadioButton value={false} label="Não" />
                      </RadioGroup>
                      {Boolean(newFilters[filterKey]?.active) ? (
                        formattedFilters[filterKey]?.length > 7 ? (
                          <NewSelect
                            isMulti
                            isSearchable
                            value={newFilters[filterKey]?.selected?.map((value) => ({
                              value,
                              label: value,
                            }))}
                            options={formattedFilters[filterKey]}
                            onChange={(newValue) => toggleItems(filterKey, newValue)}
                          />
                        ) : (
                          formattedFilters[filterKey]?.map((filterValue) => (
                            <Styled.FiltersListLabel key={filterValue}>
                              <input
                                className="filterCheckbox"
                                onChange={(event) => toggleItem(event, filterKey, filterValue)}
                                type="checkbox"
                                checked={
                                  newFilters[filterKey]?.selected?.find((t) => t === filterValue) ||
                                  false
                                }
                              />
                              {filters[filterKey]?.translate
                                ? getFilterTranslation(filterKey, filterValue)
                                : filterValue}
                            </Styled.FiltersListLabel>
                          ))
                        )
                      ) : null}
                    </Styled.FiltersListForm>
                  </Styled.FiltersListColumn>
                )
            )}
        </Styled.FiltersListForms>
      </Styled.FiltersListFormsContainer>
      <Styled.FiltersListButtonsContainer>
        <Button variant="outlined" onClick={resetFilters} fullWidth maxWidth={113}>
          Limpar
        </Button>
        <Styled.Spacing />
        <Button onClick={() => applyFilter()} fullWidth maxWidth={160}>
          Aplicar
        </Button>
      </Styled.FiltersListButtonsContainer>
    </Styled.FiltersListContainer>
  );
}

import { useContext, useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import { rules, rulesMessages, customRules } from 'Utils/Helpers/Validator';
import { Input, NewButton, InputMaskControl } from 'Components';
import ModuleContext from 'Context/moduleContext';
import TagsModel from 'Models/TagsModel';
import ComplaintModel from 'Models/ComplaintModel';
import { getEntityId } from 'Utils/Config/SectorConfig';
import Select from 'Components/NewSelect';
import ServiceStepModel from 'Models/ServiceStepModel';
// Dependencia seguinte vai ser usada em proximos desenvolvimentos
// import { useTelemedicineScheduleEnabled } from 'Hooks/useTelemedicineScheduleEnabled';
import ModalConfirmation from '../ModalConfirmation';
import { ModalConfirmationActions } from 'store/ducks/modal_confirmation';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../constants';

const Register = () => {
  const dispatch = useDispatch();
  const { moduleAccess } = useContext(ModuleContext);
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'onChange',
  });
  const [selectedTags, handleSelectedTags] = useState([]);
  const [selectedComplaint, handleSelectedComplaint] = useState([]);
  const [selectedServiceStep, handleSelectedServiceStep] = useState('');
  const [loadingButton, handleLoadingButton] = useState(false);
  // Dependencia seguinte vai ser usada em proximos desenvolvimentos
  // const isTelemedicineScheduleEnabled = useTelemedicineScheduleEnabled();

  const { onCompleted, loading } = useSelector(
    ({ modal_confirmation: { onCompleted, loading } }) => ({
      onCompleted,
      loading,
    })
  );

  const { data: tags, loading: loadingTags } = useQuery(TagsModel.GET_ALL_TAGS(getEntityId()), {
    fetchPolicy: 'network-only',
  });

  const { data: complaint, loading: loadingComplaint } = useQuery(
    ComplaintModel.GET_COMPLAINT(getEntityId(), moduleAccess),
    {
      variables: {
        entityId: getEntityId()?.[0],
        module: moduleAccess,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { data: serviceStep, loading: loadingServiceStep } = useQuery(
    ServiceStepModel.GET_SERVICE_STEP(),
    {
      fetchPolicy: 'network-only',
    }
  );

  const complaintOptions = useMemo(() => {
    if (complaint && complaint?.getComplaintByModule?.length > 0) {
      return complaint?.getComplaintByModule?.map((item) => {
        return {
          value: item?.complaintSlug,
          label: item?.complaintName,
        };
      });
    }
  }, [complaint]);

  const serviceStepOptions = useMemo(() => {
    if (serviceStep && serviceStep?.getServiceStep?.length > 0) {
      return serviceStep?.getServiceStep
        ?.filter(
          (step) =>
            step?.name !== constants.monitoredByLauraStep && step?.name !== constants.videoStep
        )
        .map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
    }
  }, [serviceStep]);

  const disabled = useMemo(() => {
    return !selectedServiceStep?.value || !selectedComplaint?.value;
  }, [selectedServiceStep, selectedComplaint]);

  const tagsOptions = useMemo(() => {
    if (loadingTags) return [];

    return tags.getAllTags.map((tag) => ({
      value: tag,
      label: tag,
    }));
  }, [tags, loadingTags]);

  const openModalConfirmation = (data) => {
    const dataForm = {
      ...data,
      selectedServiceStep,
      selectedComplaint,
      selectedTags,
    };
    dispatch(ModalConfirmationActions.modalConfirmationSetIsOpen(true));
    dispatch(
      ModalConfirmationActions.modalConfirmationSetData(
        selectedServiceStep,
        dataForm,
        constants.registerPatient
      )
    );
  };

  useEffect(() => {
    if (onCompleted) {
      reset({
        birthdate: '',
        cellphone: '',
        cpf: '',
        email: '',
        name: '',
      });
      handleSelectedTags([]);
      handleSelectedServiceStep(null);
      handleSelectedComplaint(null);
      dispatch(ModalConfirmationActions.modalConfirmationSetOnCompleted(false));
    }
  }, [onCompleted, reset, dispatch]);

  useEffect(() => {
    handleLoadingButton(loading);
  }, [loading]);

  return (
    <>
      <form
        className="form-style-1 card"
        style={{ minWidth: '609px' }}
        onSubmit={handleSubmit(openModalConfirmation)}
      >
        <fieldset className="card-body form-group">
          <div className="row">
            <div className="col-md-12">
              <legend>Cadastrar Paciente Único</legend>
            </div>
            <div className="col-md-12">
              <Input
                defaultValue={''}
                name="name"
                label="Nome completo"
                type="text"
                ref={register({
                  required: 'Favor informar o nome.',
                  validate: customRules.empty,
                })}
                error={errors?.name}
                errormessage={errors?.name?.message}
              />
            </div>
            <div className="col-md-6">
              <InputMaskControl
                type="text"
                defaultValue={''}
                error={errors?.cpf}
                errormessage={errors?.cpf?.message}
                label="CPF"
                name="cpf"
                control={control}
                mask="999.999.999-99"
                rules={{
                  required: rulesMessages.document.required,
                  pattern: {
                    value: rules.document,
                    message: rulesMessages.document.pattern,
                  },
                  validate: customRules.cpf,
                }}
              />
            </div>
            <div className="col-md-6">
              <InputMaskControl
                type="text"
                defaultValue={''}
                error={errors?.cellphone}
                errormessage={errors?.cellphone?.message}
                label="Telefone"
                name="cellphone"
                control={control}
                mask="(99) 99999-9999"
                rules={{
                  required: rulesMessages.phone.required,
                  validate: customRules.cellphone,
                }}
              />
            </div>
            <div className="col-md-6">
              <Input
                name="email"
                label="E-mail"
                type="text"
                defaultValue={''}
                ref={register({
                  validate: customRules.email,
                })}
                error={errors?.email}
                errormessage={errors?.email?.message}
              />
            </div>
            <div className="col-md-6">
              <InputMaskControl
                type="text"
                defaultValue={''}
                error={errors?.birthdate}
                errormessage={errors?.birthdate?.message}
                label="Data de nascimento"
                name="birthdate"
                control={control}
                mask="99/99/9999"
                rules={{
                  required: rulesMessages.birthdate?.required,
                  validate: customRules.birthdate,
                }}
              />
            </div>
            <div className="col-md-12 mb-3">
              <Select
                isClearable
                defaultValue={''}
                placeholder="Selecione a etapa de atendimento para inserir o paciente"
                noOptionsMessage="Nenhuma etapa de atendimento encontrada"
                isLoading={loadingServiceStep}
                isSearchable
                options={serviceStepOptions}
                onChange={handleSelectedServiceStep}
                value={selectedServiceStep}
                maxLength={30}
                menuPlacement="auto"
              />
            </div>
            <div className="col-md-12 mb-3">
              <Select
                isClearable
                defaultValue={''}
                placeholder="Selecione uma linha de cuidado"
                noOptionsMessage="Nenhuma linha de cuidado encontrada"
                isLoading={loadingComplaint}
                isSearchable
                options={complaintOptions}
                onChange={handleSelectedComplaint}
                value={selectedComplaint}
                maxLength={30}
                menuPlacement="auto"
              />
            </div>
            <div className="col-md-12 mb-3">
              <Select
                isMulti
                isClearable
                defaultValue={''}
                placeholder="Selecione ou crie uma nova tag"
                createLabel="Adicionar tag"
                noOptionsMessage="Nenhuma tag encontrada"
                creatable
                isLoading={loadingTags}
                isSearchable
                options={tagsOptions}
                onChange={handleSelectedTags}
                value={selectedTags}
                maxLength={30}
                menuPlacement="auto"
              />
            </div>
          </div>
          <NewButton disabled={disabled || loadingButton} loading={loadingButton} fullWidth>
            Cadastrar Paciente
          </NewButton>
        </fieldset>
      </form>
      <ModalConfirmation />
    </>
  );
};

export default Register;

import styled, { css } from 'styled-components';
import Button from 'Components/NewButton';
import Icon from '@mdi/react';
import scrollbar from 'Assets/styles/scrollbar';
import RcSelect from 'react-select';
import AsyncSelect from 'react-select/async';

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
`;

const OverFlowContainer = styled.div`
  overflow-y: auto !important;
  max-height: calc(85vh - 210px);
  min-height: ${(props) => (props.default ? 'calc(85vh - 210px);' : '')};
  @media screen and (max-width: 1024px) {
    min-height: ${(props) => (props.default ? 'calc(85vh - 226px);' : '')};
    max-height: ${(props) => (props.default ? '' : 'calc(85vh - 230px);')};
  }
  @media screen and (min-width: 1024px) {
    min-height: ${(props) => (props.default ? 'calc(85vh - 210px);' : '')};
  }

  ::-webkit-scrollbar {
    width: 0.4em;
  }

  ::-webkit-scrollbar-track {
    margin: 0;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background: #ccc;
  }
`;

const Label = styled.label.attrs((props) => ({
  align: props.align || 'left',
}))`
  font: normal normal medium 14px/19px Roboto;
  font-size: 0.75rem;
  color: #707070;
  margin: 15px 0px;
  width: 100%;
  opacity: 1;

  ${({ align }) => css`
    text-align: ${align};
  `}
`;

const CheckoutOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckoutIcon = styled.i`
  font-size: 2rem;
`;

const Title = styled.label`
  font-size: 1.2rem;
  text-align: center;
  color: #0071eb;
  margin-top: 0;
  margin-bottom: 10px;
  width: 100%;
`;

const NewTitleModal = styled.label`
  font-size: 1.2rem;
  text-align: left;
  color: black;
  margin-top: 0;
  margin-bottom: 5px;
  width: 100%;
  font-weight: bold;
`;

const ModalSubtitle = styled.label`
  font-size: 0.8 rem;
  text-align: left;
  color: #aaaaaa;
  margin-top: 0;
  width: 100%;
`;

const HorizontalLine = styled.hr`
  border: -0.7px solid #aaaaaa;
  margin-top: 12px;
  width: 130%;
  margin-left: -30px;
  margin-bottom: 0px;
`;

const Textarea = styled.textarea.attrs((props) => ({
  scrollColor: '#cecece',
}))`
  ${(props) => (props.height ? `height: ${props.height}` : 'height: 132px;')}
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  padding: 17px 13px;
  color: #212121;
  font-size: 0.75rem;
  resize: none;
  opacity: 1;

  &:focus {
    outline: none !important;
    border: 1px solid #0071eb;
  }

  &:hover {
    border: 1px solid #0071eb;
  }

  ::-webkit-input-placeholder {
    color: #aaaaaa;
  }

  ${scrollbar}
`;

const CheckoutOption = styled.div`
  border: 1px solid #efefef;
  color: #556;
  border-radius: 2px;
  height: 100px;
  text-align: center;
  margin: 3px;
  flex: 1 1 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 1px solid #0071eb;
    color: #0071eb;
  }
  &.active {
    border: 1px solid #0071eb;
    color: #0071eb;
  }
  &.disabled {
    pointer-events: none;
    color: #ddd;
  }
`;

const CheckoutDescription = styled.div`
  font-size: 0.75rem;
  color: #556;
  height: 37px;
  margin-top: 10px;
  line-height: 20px;
`;

const Description = styled.div`
  background: #f4f5f7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
`;

const Text = styled.span`
  text-align: left;
  font: Regular 14px/19px Roboto;
  letter-spacing: 0px;
  color: #a9a9a9;
  opacity: 1;
`;

const Separator = styled.div.attrs((props) => ({
  height: props.height || 0,
  width: props.width || 0,
}))`
  ${({ height, width }) => css`
    height: ${height}px;
    width: ${width}px;
  `}
`;

const Flex = styled.div.attrs((props) => ({
  direction: props.direction || 'row',
  justify: props.justify || 'flex-start',
  align: props.align || 'center',
}))`
  ${({ direction, justify, align }) => css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
  `}
`;

const EvolutionButton = styled.button`
  margin-top: 20px;
  background-color: white !important;
  border-radius: 5px;
  opacity: 1;
  margin-right: 15px;
  margin-bottom: 28px;
  padding: 10px 25px;
  width: 136px;
  height: 45px;
  border: ${(props) =>
    props.active ? '1px solid #0071eb !important' : '1px solid #aaaaaa !important'};
  &:focus,
  &:hover,
  &:visited,
  &:link {
    border: 1px solid #0071eb !important;
  }
  &:focus {
    outline: none !important;
  }
`;

const EvolutionButtonText = styled.text`
  background-color: white !important;
  text-align: center;
  font: normal normal medium 14px/19px Roboto !important;
  letter-spacing: 0.21px;
  color: ${(props) => (props.active ? '#0071eb !important' : '#aaaaaa !important')};
  opacity: 1;
`;

const Alert = styled(Icon)`
  margin-right: 8px;
  width: 20px;
  color: #707070;
`;

const AlertText = styled.text`
  font-size: 12px;
  color: #707070;
`;

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 10px;
`;

export const CancelButtonContainer = styled.div`
  width: 100%;
`;

export const EvolutionCancelButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ConfirmButton = styled(Button).attrs((props) => ({
  disabled: props.disabled,
}))`
  border-radius: 5px !important;
  opacity: 1 !important;
  color: #ffffff !important;
  height: 32px;
  ${({ disabled }) => css`
    background: ${disabled ? '#c6c6c6' : '#0071eb'};
  `}
`;

export const FooterWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  height: 50px;
  bottom: 0;
`;

export const TitleWrapper = styled.div`
  width: 95%;
`;

export const HorizontalRule = styled.hr`
  border: -0.7px solid #aaaaaa;
  width: 130%;
  margin: 0px 0px 20px -30px;
  z-index: 1;
`;

export const ContainerEvolutionSoapForm = styled.div`
  margin-right: 25px;
  max-width: 569px;
`;

export const ClassifierInput = styled.div`
  margin-bottom: 20px;
`;

const SelectList = styled(AsyncSelect)``;

export const ContainerEvolutionDefault = styled.div`
  max-width: 650px;
  margin-right: 25px;
`;

const AttendanceTypeContainer = styled.div`
  margin-bottom: 20px;
`;

const AttendanceTypeSelector = styled(RcSelect)``;

export const Styled = {
  Label,
  CheckoutOptionWrapper,
  CheckoutIcon,
  Title,
  Textarea,
  CheckoutOption,
  CheckoutDescription,
  Description,
  Text,
  NewTitleModal,
  Separator,
  Flex,
  ModalContainer,
  ModalSubtitle,
  HorizontalLine,
  EvolutionButton,
  EvolutionButtonText,
  Alert,
  AlertText,
  AlertWrapper,
  FooterWrapper,
  TitleWrapper,
  EvolutionCancelButtonContainer,
  ConfirmButton,
  HorizontalRule,
  OverFlowContainer,
  ContainerEvolutionSoapForm,
  ClassifierInput,
  SelectList,
  ContainerEvolutionDefault,
  AttendanceTypeContainer,
  AttendanceTypeSelector,
};

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import IconBase from '@mdi/react';

const flexAllCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled(motion.button).attrs((props) => ({
  active: props.active,
  background: props.background,
  backgroundActive: props.backgroundActive,
  disabled: props.disabled,
}))`
  border: none;
  height: 32px;
  width: 40px;
  border-radius: 5px;
  margin: 0px 8px;
  position: relative;
  cursor: pointer;
  color: #fff;
  background-color: #0071eb;
  &:disabled {
    cursor: default;
    background-color: #ececec;
    & * {
      color: #dbdbdb;
    }
  }
  &:focus {
    outline: 0;
  }

  ${flexAllCenter}
`;

export const BadgeCounter = styled(motion.div).attrs((props) => ({
  color: props.color,
  showStatus: props.showStatus,
}))`
  width: 7px;
  height: 7px;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0px 3px 6px #00000029;
  top: 0;
  right: 0;

  ${({ color, showStatus }) => css`
    background: ${color};
    top: ${showStatus ? -2 : 0};
    right: ${showStatus ? -3 : 0};
  `}
`;

export const Icon = styled(IconBase)``;

export const Popover = styled(motion.div).attrs((props) => ({
  maxWidth: props.maxWidth,
}))`
  padding: 16px;
  background-color: #ffffff;
  pointer-events: visible;
  border-radius: 16px;
  box-shadow: 0px 3px 20px #00000029;

  ${({ maxWidth }) => css`
    max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
  `}
`;

export const ChevronDown = styled.div.attrs((props) => ({
  color: props.color,
}))`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  margin-left: 4px;

  ${({ color }) => css`
    border-top: 4px solid ${color};
  `}
`;

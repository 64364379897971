import styled from 'styled-components';

const TitleBackStyle = styled.div`
  text-align: left;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
  cursor: pointer;
`;

export const Styled = {
  TitleBackStyle,
};

import Tooltip from 'Components/Tooltip';

import * as Styled from './styled';

const InfoIconTeleAtendimento = () => (
  <Tooltip
    text={
      <Styled.ContainerHelp>
        Em sua pesquisa você pode buscar resultados para:{' '}
        <Styled.List>
          <Styled.ListItem>● Telefone</Styled.ListItem>
          <Styled.ListItem>● Nome</Styled.ListItem>
          <Styled.ListItem>● CEP</Styled.ListItem>
          <Styled.ListItem>● Veículo</Styled.ListItem>
          <Styled.ListItem>● CPF</Styled.ListItem>
          <Styled.ListItem>● E-Mail</Styled.ListItem>
        </Styled.List>
      </Styled.ContainerHelp>
    }
    position="bottom"
  >
    <Styled.Container />
  </Tooltip>
);

export default InfoIconTeleAtendimento;

import styled from 'styled-components';
import { ColorDictionary } from '../../Utils/Dictionary/ColorDictionary';
import Icon from '@mdi/react';
import { BadgeWithColor } from '../../Components/Badge';
import { mdiTriangle } from '@mdi/js';

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 6.25rem;
`;

export const AlertContainerDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
`;

export const Comorbidity = styled.strong`
  color: #212121;
`;

const Signal = ({ improvement }) => {
  if (improvement === null) {
    return [];
  }
  const signal = improvement === 'true' || improvement === true;
  if (signal) {
    return (
      <Icon
        path={mdiTriangle}
        rotate={180}
        title="Paciente Sinalizou Melhora"
        size={0.6}
        color="#00A205"
      />
    );
  }
  return <Icon path={mdiTriangle} title="Paciente Sinalizou Piora" size={0.6} color="#EC2C47" />;
};

export const AlertComorbidity = ({ comorbidities }) => {
  if (!comorbidities) return null;
  return <Comorbidity>{comorbidities}</Comorbidity>;
};

export const AlertComorbidityLegacy = ({ alert }) => {
  return (
    alert &&
    alert.anomalias && (
      <Comorbidity>
        {(alert.anomalias.comorbidade || []).concat(alert.anomalias.questao2 || []).join('; ')}
      </Comorbidity>
    )
  );
};
export const AlertWithBadgeAndSignal = ({ alert }) => {
  return (
    alert && (
      <AlertContainer>
        <BadgeWithColor color={alert.resultado}>
          {ColorDictionary.Badge[alert.resultado].name}
        </BadgeWithColor>
        <Signal improvement={alert.houveMelhora} />
      </AlertContainer>
    )
  );
};
export const AlertWithBadgeAndPoint = ({ data, alert, updatedAt, sector }) => {
  return (
    data && (
      <AlertContainerDate>
        <BadgeWithColor
          color={
            sector === 'ALL' || sector === 'AWAITING'
              ? updatedAt === 0
                ? 'yellow'
                : 'red'
              : alert?.resultado
          }
        >
          {data}
        </BadgeWithColor>
      </AlertContainerDate>
    )
  );
};

export { AlertDate } from './AlertDate';

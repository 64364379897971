import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Toast } from 'Components';
import ListServiceStep from 'Components/ListServiceStep';
import Modal from 'Components/Modal';
import { Title } from 'Components/Modal/styled';
import ModuleContext from 'Context/moduleContext';
import useCache from 'Hooks/useCache';
import useMonitoringLock from 'Hooks/useMonitoringLock';
import { useMoreActions } from 'Hooks/useMoreActions';
import { useContext, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalCancelScheduleActions } from 'store/ducks/modal_cancel_schedule';
import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import { StatusDictionary } from 'Utils/Dictionary/StatusDictionary';
import { Description } from './styled';
import { CancelButton } from './styled';
import { MainContent, Message, MoveButton } from './styled';
import gql from 'graphql-tag';
import { useDataTable } from 'Components/Datatable/container';

const CANCEL_TELECONSULTATION_SCHEDULE = gql`
  mutation cancelTeleconsultationSchedule(
    $entityId: Int!
    $currentSlotInitialTime: String
    $cpf: String!
    $module: String!
    $userId: Int!
    $sector: String!
    $serviceStep: String!
  ) {
    cancelTeleconsultationSchedule(
      entityId: $entityId
      currentSlotInitialTime: $currentSlotInitialTime
      cpf: $cpf
      module: $module
      userId: $userId
      sector: $sector
      serviceStep: $serviceStep
    )
  }
`;

export default function ModalCancelSchedule() {
  const { isOpen, document } = useSelector(({ modal_cancel_schedule: { isOpen, document } }) => ({
    isOpen,
    document,
  }));
  const [cancelTeleconsultationSchedule, { loading }] = useMutation(
    CANCEL_TELECONSULTATION_SCHEDULE
  );
  const [user] = useCache('user');
  const { moduleAccess } = useContext(ModuleContext);
  const { store } = useMoreActions();
  const { isLoading, sector, serviceStep, status, reason, data } = store;
  const dispatch = useDispatch();
  const blocked = useMonitoringLock(serviceStep, data);

  const isHistory = useMemo(
    () => serviceStep?.value === SERVICE_STEPS(moduleAccess).history.value,
    [serviceStep, moduleAccess]
  );
  const handleClose = () => {
    dispatch(ModalCancelScheduleActions.modalCancelScheduleReset());
  };
  const { fetch } = useDataTable();

  const isValid = useMemo(() => {
    return (
      Boolean(serviceStep) &&
      (serviceStep.subSteps ? Boolean(sector) : true) &&
      (isHistory
        ? Boolean(status) &&
          (![
            StatusDictionary.OBSERVATION,
            StatusDictionary.CRITIC,
            StatusDictionary.NO_SHOW,
          ].includes(status)
            ? Boolean(reason)
            : true)
        : true)
    );
  }, [serviceStep, sector, isHistory, status, reason]);

  const inputRef = useRef();
  /**
   * Atualiza o status da teleconsulta e abre o link
   */
  const handleCancelSchedule = async () => {
    try {
      await cancelTeleconsultationSchedule({
        variables: {
          currentSlotInitialTime: document?.serviceSteps?.telemedicine?.scheduling?.slotInitialTime,
          entityId: parseInt(user.entityId),
          userId: user.userId,
          cpf: document?.patient?.cpf,
          motive: isHistory && reason ? reason.slug : 'Status change',
          sector: sector ? sector.slug : serviceStep?.initialSector,
          serviceStep: serviceStep?.value,
          module: moduleAccess,
        },
      });

      toast(
        <Toast
          type="success"
          title="Consulta cancelada e paciente movido com sucesso"
          subtitle="Pronto agora o paciente está na etapa/setor selecionado."
        />
      );
      fetch();
      handleClose();
    } catch (e) {
      Sentry.captureException(e);
      toast(<Toast type="error" title="Não foi possível mover o paciente" subtitle={e.message} />);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose} width="732px">
      <Title>
        <span style={{ color: '#0071eb' }}>Cancelar a consulta</span> e mover paciente
      </Title>
      <MainContent>
        <Message>
          Para cancelar, você deve obrigatoriamente mover o paciente para uma das seguintes abas.
        </Message>
        {Boolean(document) && (
          <ListServiceStep inputRef={inputRef} alertRecord={document} isCancelling />
        )}
        {blocked && <Description>blocked</Description>}
        <MoveButton
          disabled={isLoading || blocked || !isValid}
          loading={loading}
          onClick={handleCancelSchedule}
          className="contained rounded uppercase"
        >
          Confirmar o cancelamento
        </MoveButton>
        <CancelButton onClick={handleClose} className="text underlined uppercase">
          Voltar
        </CancelButton>
      </MainContent>
    </Modal>
  );
}

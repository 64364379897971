import styled, { css } from 'styled-components';

const sharedProps = (props) => ({
  spacing: props.spacing,
  height: props.height,
});

export const Wrapper = styled.div.attrs(sharedProps)`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ spacing }) => css`
    margin: ${spacing}px 0;
  `}
`;

export const Line = styled.div.attrs(sharedProps)`
  ${({ height }) => css`
    height: ${height}px;
    background: #e2e2e2;
    flex: 1;
  `}
`;

export const Text = styled.span`
  font-family: 'Roboto', sans-serif;
  color: #a9a9a9;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 10px;
`;

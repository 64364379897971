import { useState } from 'react';

import { mdiAccountArrowRight } from '@mdi/js';
import Modal from 'Components/Modal';

import ChangeStatus from 'Components/Patient/ChangeStatus';
import { Document } from 'data/types';
import { Button } from '@chakra-ui/react';
import Icon from '@mdi/react';
import { useMoreActions } from 'Hooks/useMoreActions';

type MovePatientButtonProps = {
  loadMenu: () => void;
  medicalRecord: string;
  alertRecord: Document;
  stepActive?: string;
  sectorSlug?: string;
};

const MovePatientButton = ({
  loadMenu,
  medicalRecord,
  alertRecord,
  stepActive,
  sectorSlug,
}: MovePatientButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { dispatch: actionDispatch } = useMoreActions();

  if (stepActive === 'eligible' || sectorSlug === 'SCHEDULE') {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        title="Mover paciente"
        size="sm"
        paddingY="0"
        paddingX="2"
        marginX="2"
        colorScheme="gray"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Icon path={mdiAccountArrowRight} size={0.8} />
      </Button>

      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            actionDispatch({ type: 'HIDE_MODAL' });
          }}
        >
          <ChangeStatus
            callBack={() => {
              setModalOpen(false);
              actionDispatch({ type: 'HIDE_MODAL' });
              loadMenu();
            }}
            medicalRecord={medicalRecord}
            alertRecord={alertRecord}
          />
        </Modal>
      )}
    </>
  );
};

export default MovePatientButton;

import styled, { css } from 'styled-components';
import scrollbar from 'Assets/styles/scrollbar';

export const ModalContainer = styled.div`
  width: 600px;
`;

export const Container = styled.div`
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Loading = styled.div`
  padding: 16px 16px 0;
  margin: 16px 0px 8px;
  width: 100%;
`;

export const Options = styled.div.attrs((props) => ({
  scrollColor: '#cecece',
}))`
  padding: 16px 16px 0;
  margin: 16px 0px 8px;
  background: #f4f5f7;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 289px;

  ${scrollbar}
`;

export const Option = styled.div.attrs((props) => ({
  selected: props.selected,
}))`
  background: #dcf0ff;
  padding: 8px 16px;
  color: #444444;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 17px;
  width: 220px;
  border-radius: 5px 0px 5px 5px;
  letter-spacing: 0.28px;
  margin-bottom: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  ${({ selected }) => css`
    border: 1px solid ${selected ? '#0071eb' : 'rgba(255, 255, 255, .1)'};
  `}
`;

export const Name = styled.div.attrs((props) => ({
  selected: props.selected,
}))`
  color: #007bff;
  font-family: 'Roboto';
  font-size: 10px;
  width: 220px;
`;

export const Triage = styled.div`
  display: grid;
  grid-template-columns: 45% 30px 45%;
  margin-bottom: 20px;
`;

import MainButton from 'Components/MainButton';
import { Content } from 'Components/Modal/styled';
import styled from 'styled-components';

export const Message = styled.p`
  font: normal normal normal 14px Roboto;
  color: black;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin-top: -15px;
`;

export const MainContent = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: none;
  min-height: 70px;
  max-height: 100%;
  min-width: 50px;
`;

export const CancelButton = styled(MainButton)`
  width: 128px;
  height: 32px;
  margin-right: 10px;
  font-size: 14px !important;
  color: #0071eb !important;
  padding: 0px;
`;

export const ConfirmButton = styled(MainButton)`
  width: 128px;
  height: 32px;
  font-size: 14px !important;
  padding: 0px;
`;

export const Footer = styled.div`
  padding: 4px 26px 22px 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

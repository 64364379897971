import {
  SectorDictionary,
  SectorDictionaryHistory,
} from '../Dictionary/RemoteAtendimento/TeleAtendimento';

export const RemoteAtendimentoSectors = [
  SectorDictionary.TELEATENDIMENTO,
  SectorDictionary.TELEATENDIMENTO_IN_AWAITING,
  SectorDictionary.TELEATENDIMENTO_HUMAN,
  SectorDictionary.TELEATENDIMENTO_HISTORY,
  SectorDictionary.TELEATENDIMENTO_CLOSED,
  SectorDictionary.TELEATENDIMENTO_CONTACT_ACTIVE,
];

export const RemoteAtendimentoSectorsHistory = [
  SectorDictionaryHistory.TELEATENDIMENTO_HISTORY,
  SectorDictionaryHistory.TELEATENDIMENTO_OUTSIDE,
  SectorDictionaryHistory.TELEATENDIMENTO_CONTACT_ACTIVE,
  SectorDictionaryHistory.TELEATENDIMENTO_IMPORT,
];

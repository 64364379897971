import { useContext, useState, useEffect } from 'react';
import LocalCache from '../Services/LocalCache';
import ModuleContext from '../Context/moduleContext';

export function useTelemedicineScheduleEnabled() {
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const [isSchedule, handleIsSchedule] = useState(false);

  useEffect(() => {
    const [module] = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);

    handleIsSchedule(module?.serviceStep?.isTelemedicineScheduleEnabled === true);
  }, [moduleAccess, stepActive]);

  return isSchedule;
}

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import scrollbar from 'Assets/styles/scrollbar';

type StyledProps = {
  width?: string;
  height?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
};

export const Dialog = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled(motion.div)<StyledProps>`
  width: ${(props) => props.width};
  min-width: 452px;
  position: relative;
  z-index: 101;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0px rgba(1, 126, 49, 0.05);
  max-height: calc(100vh - 125px);
  min-height: ${(props) => (props.height ? 'calc(100vh - 125px)' : '152px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  font-family: 'Roboto';
  letter-spacing: 0.4px;
  color: #444444;
`;

export const Subtitle = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  color: #aaaaaa;
  letter-spacing: 0.28px;
  white-space: pre-line;
  word-break: break-word;
  margin-bottom: 0;
`;

export const Close = styled.span`
  position: absolute;
  right: 26px;
  top: 24px;
  cursor: pointer;
`;

export const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100vw;
`;

export const Header = styled.div<StyledProps>`
  ${({ noBorder, noPadding }) => {
    return css`
      border-bottom: ${noBorder ? '0' : '1px solid #dbdce1'};
      padding: ${noPadding ? '0' : '20px 26px 22px 26px'};
    `;
  }}
`;

export const Body = styled.div<StyledProps>`
  ${({ noPadding }) => {
    return css`
      overflow-y: auto;
      padding: ${noPadding ? '0' : '26px'};

      ${scrollbar} & {
        overflow-x: auto;
      }
    `;
  }}
`;

export const Footer = styled.div<StyledProps>`
  ${({ noBorder, noPadding }) => {
    return css`
      border-top: ${noBorder ? '0' : '1px solid #dbdce1'};
      display: flex;
      padding: ${noPadding ? '0' : '20px 26px 22px 26px'};
      align-items: center;
      justify-content: end;
    `;
  }}
`;

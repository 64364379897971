import { useCallback, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Dialog from 'Components/Dialog';
import Button from 'Components/NewButton';
import Toast from 'Components/Toast';

import activateUserIcon from '../../Assets/icons/user/activate-user-blue.svg?url';
import deactivateUserIcon from '../../Assets/icons/user/deactivate-user-blue.svg?url';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 114px 150px;
`;

const ModalBodyHeader = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  text-align: center;
  color: #0071eb;
  margin-bottom: 8px;
`;

const ModalBodyDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  color: #5c6275;
`;

const Image = styled.img`
  width: 36px;
  margin-bottom: 16px;
`;

export const deactivateUserMutation = gql`
  mutation M($input: DeactivateUserInput!) {
    deactivateUser(input: $input) {
      success
      error
    }
  }
`;

export const activateUserMutation = gql`
  mutation M($input: ActivateUserInput!) {
    activateUser(input: $input) {
      success
      error
    }
  }
`;

const DeactivateUserContainer = () => (
  <>
    <Dialog.Header>
      <Dialog.Title>Desativar profissional</Dialog.Title>
      <Dialog.Subtitle>Desative usuários da plataforma</Dialog.Subtitle>
    </Dialog.Header>
    <Dialog.Body>
      <ModalBody>
        <Image src={deactivateUserIcon} alt="Desativar profissional" />
        <ModalBodyHeader>Desativar profissional</ModalBodyHeader>
        <ModalBodyDescription>
          Ao desativar um usuário, o mesmo ficará com sua conta desativada e não poderá acessar o
          Backoffice do Digital Care.
        </ModalBodyDescription>
      </ModalBody>
    </Dialog.Body>
  </>
);

const ActivateUserContainer = () => (
  <>
    <Dialog.Header>
      <Dialog.Title>Reativar profissional</Dialog.Title>
      <Dialog.Subtitle>Reative usuários da plataforma</Dialog.Subtitle>
    </Dialog.Header>
    <Dialog.Body>
      <ModalBody>
        <Image src={activateUserIcon} alt="Reativar profissional" />
        <ModalBodyHeader>Reativar profissional</ModalBodyHeader>
        <ModalBodyDescription>
          Ao reativar um usuário, o mesmo ganhará acesso ao Backoffice do Digital Care novamente.
        </ModalBodyDescription>
      </ModalBody>
    </Dialog.Body>
  </>
);

const defaultDeactivateSuccessMessage = 'Profissional desativado com sucesso';
const defaultDeactivateSuccessSubtitle =
  'O profissional teve sua conta desativada e não poderá acessar o Backoffice do Digital Care.';
const defaultDeactivateErrorMessage = 'Não foi possível desativar o profissional';
const defaultActivateSuccessMessage = 'Profissional reativado com sucesso';
const defaultActivateSuccessSubtitle =
  'O profissional teve sua conta ativada novamente, podendo acessar o Backoffice do Digital Care.';
const defaultActivateErrorMessage = 'Não foi possível reativar o profissional';

const ChangeStatusConfirmModal = ({ isOpen, user, onSuccess, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [activateUser] = useMutation(activateUserMutation, {
    onCompleted: (data) => {
      const { success, error } = data.activateUser;

      if (error || !success) {
        toast(<Toast type="error" title={defaultActivateErrorMessage} subtitle={error} />, {
          autoClose: false,
        });
        return;
      }

      toast(
        <Toast
          type="success"
          title={defaultActivateSuccessMessage}
          subtitle={defaultActivateSuccessSubtitle}
        />,
        {
          autoClose: false,
        }
      );

      onSuccess();
      onClose();
    },
    onError: (err) => {
      toast(<Toast type="error" title={defaultActivateErrorMessage} subtitle={err.message} />, {
        autoClose: false,
      });
    },
  });

  const [deactivateUser] = useMutation(deactivateUserMutation, {
    onCompleted: (data) => {
      const { success, error } = data.deactivateUser;

      if (error || !success) {
        toast(<Toast type="error" title={defaultDeactivateErrorMessage} subtitle={error} />, {
          autoClose: false,
        });
        return;
      }
      toast(
        <Toast
          type="success"
          title={defaultDeactivateSuccessMessage}
          subtitle={defaultDeactivateSuccessSubtitle}
        />,
        {
          autoClose: false,
        }
      );

      onSuccess();
      onClose();
    },
    onError: (err) => {
      toast(<Toast type="error" title={defaultDeactivateErrorMessage} subtitle={err.message} />, {
        autoClose: false,
      });
    },
  });

  const handleActivateUser = useCallback(async () => {
    setLoading(true);
    if (user.isActive) {
      await deactivateUser({
        variables: {
          input: {
            userId: user.id,
          },
        },
      });
      setLoading(false);
      return;
    }

    await activateUser({
      variables: {
        input: {
          userId: user.id,
        },
      },
    });
    setLoading(false);
  }, [activateUser, deactivateUser, user]);

  return (
    <Dialog isOpen={isOpen && !!user} onClose={onClose} backdrop width="732px">
      {user && (user.isActive ? <DeactivateUserContainer /> : <ActivateUserContainer />)}
      <Dialog.Footer>
        <Button maxWidth={120} fullWidth variant="link" onClick={onClose} type="button">
          Cancelar
        </Button>
        <Button
          maxWidth={120}
          fullWidth
          onClick={handleActivateUser}
          type="button"
          loading={loading}
        >
          Confirmar
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default ChangeStatusConfirmModal;

import styled from 'styled-components';
import { motion } from 'framer-motion';
import MDIcon from '@mdi/react';
import MainButton from 'Components/MainButton';
const borderColor = 'rgba(112, 112, 112, 0.2)';

export const Header = styled.div`
  border-bottom: 1px solid ${borderColor};
  padding: 20px 26px 22px 26px;
`;

export const Content = styled.div`
  display: inline-flex;
  width: 100%;
  min-height: 296px;
`;

export const ContentConfirm = styled.div`
  display: flex;
  width: 100%;
  min-height: 296px;
  height: 340px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CalendarContainer = styled.div`
  width: 366px;
  border-right: 1px solid ${borderColor};
  padding: 20px;
  padding-top: 0px;
`;

export const Footer = styled.div`
  border-top: 1px solid ${borderColor};
  padding: 20px 26px 22px 26px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ConfirmTitle = styled.p`
  color: #0071eb;
  font: normal normal bold 20px/24px Roboto;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 10px;
`;

export const ConfirmText = styled.p`
  width: 370px;
  color: #707070;
  font: normal normal normal 16px/26px Roboto;
  letter-spacing: 0px;
  text-align: center;
`;

export const ConfirmTextBold = styled.span`
  font: normal normal bold 16px/26px Roboto;
  letter-spacing: 0px;
`;

export const ScheduleTimesContainer = styled(motion.div)`
  padding: 30px;
  flex: 1;
  overflow: auto;
  height: ${(props) => props.height}px;
`;

export const ScheduleTimesItemContainer = styled.div`
  width: 100%;
  height: 45px;
  background-color: #ececec;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
  transition: filter 0.5s;
  &:hover {
    filter: brightness(90%);
  }
  &:last-child {
    margin-bottom: 0px;
  }
  ${(props) =>
    props.selected
      ? `
    background-color: #0071eb;
    & p {
      color: #fff;
    }
  `
      : ''}
`;

export const ScheduleTimesItemTime = styled.p`
  color: #0071eb;
  letter-spacing: 0.24px;
  font: normal normal normal 16px/19px Roboto;
  margin-bottom: 0px;
`;

export const ScheduleTimesItemCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const Icon = styled(MDIcon)``;

export const ScheduleTimesItemCount = styled.p`
  color: #707070;
  letter-spacing: 0.18px;
  font: normal normal normal 12px/14px Roboto;
  margin-bottom: 0px;
  margin-left: 5px;
`;

export const ConfirmTime = styled.p`
  width: 332px;
  height: 36px;
  background-color: #ececec;
  color: #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font: normal normal normal 14px Roboto;
  letter-spacing: 0.32px;
  border-radius: 5px;
`;

export const CancelButton = styled(MainButton)`
  width: 128px;
  height: 32px;
  margin-right: 10px;
  font-size: 14px !important;
  color: #0071eb !important;
  padding: 0px;
`;

export const ConfirmButton = styled(MainButton)`
  width: 128px;
  height: 32px;
  font-size: 14px !important;
  padding: 0px;
`;

export const HelperText = styled.p`
  padding: 10px;
  text-align: center;
  color: #444;
  flex: 1;
`;

export const ConfirmPatient = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: 2px dashed #e2e2e2;
  border-radius: 5px;
  margin-bottom: 16px;
`;

export const ConfirmPatientName = styled.div`
  font: normal normal normal 13px/26px Roboto;
  letter-spacing: 0.07px;
  color: #0071eb;
  text-transform: capitalize;
  margin-left: 20px;
`;

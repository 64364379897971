import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { lighten } from 'polished';

import { springSlide } from 'Utils/animations';
import Spinner from 'Components/Spinner';

import * as Styled from './styled';

type OwnProps = {
  color?: string;
  background?: string;
  prefixIcon?: string;
  suffixIcon?: string;
  children?: string | React.ReactNode | React.ReactNode[];
  onClick?: (...args: any[]) => any;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  fullWidth?: boolean;
  maxWidth?: number;
  rounded?: boolean;
  loading?: boolean;
  variant?: 'contained' | 'outlined' | 'link' | 'text';
  paddingVertical?: number;
};

type Props = OwnProps & typeof NewButton.defaultProps;

const NewButton = ({
  size,
  color,
  background,
  prefixIcon,
  suffixIcon,
  children,
  disabled,
  fullWidth,
  variant,
  loading,
  onClick,
  maxWidth,
  paddingVertical,
  rounded,
  ...rest
}: Props) => {
  const [isHover, handleHover] = useState(false);

  const buttonVariants = {
    outlined: {
      color: background,
      border: `1px solid ${disabled ? '#707070' : background}`,
      background: 'rgba(0,0,0,0)',
      width: fullWidth ? '100%' : 'auto',
    },
    text: {
      color: background,
      background: 'rgba(0,0,0,0)',
      width: fullWidth ? '100%' : 'auto',
    },
    outlinedDisabled: {
      color: '#707070',
      border: `1px solid ${disabled ? '#707070' : background}`,
      background: 'rgba(0,0,0,0)',
      width: fullWidth ? '100%' : 'auto',
    },
    link: {
      color: background,
      border: '1px solid rgba(255, 255, 255, .1)',
      background: 'rgba(0,0,0,0)',
      width: fullWidth ? '100%' : 'auto',
    },
    linkDisabled: {
      color: '#707070',
      border: '1px solid rgba(255, 255, 255, .1)',
      background: 'rgba(0,0,0,0)',
      width: fullWidth ? '100%' : 'auto',
    },
    normal: {
      color,
      background,
      border: `1px solid ${background}`,
      width: fullWidth ? '100%' : 'auto',
    },
    normalHover: {
      color,
      background: lighten(0.1, background),
      border: `1px solid ${lighten(0.1, background)}`,
      width: fullWidth ? '100%' : 'auto',
    },
    disabled: {
      color: '#707070',
      background: '#E3E3E3',
      border: '1px solid #E3E3E3',
      width: fullWidth ? '100%' : 'auto',
    },
  };

  const handleClick = () => {
    if (!loading) {
      onClick && onClick();
    }
  };

  const getAnimation = () => {
    switch (variant) {
      case 'outlined':
        if (disabled) {
          return 'outlinedDisabled';
        }

        return 'outlined';
      case 'link':
        if (disabled) {
          return 'linkDisabled';
        }

        return 'link';
      default:
        if (disabled) {
          return 'disabled';
        }

        if (isHover) {
          return 'normalHover';
        }

        return 'normal';
    }
  };

  return (
    <AnimatePresence>
      <Styled.Button
        size={size}
        $hasLabel={Boolean(children)}
        color={color}
        background={background}
        $fullWidth={fullWidth}
        disabled={disabled}
        $variant={variant}
        $loading={loading.toString()}
        onClick={handleClick}
        $maxWidth={maxWidth}
        $paddingVertical={paddingVertical}
        $rounded={rounded}
        onHoverStart={() => handleHover(true)}
        onHoverEnd={() => handleHover(false)}
        {...rest}
        variants={buttonVariants}
        initial={getAnimation()}
        animate={getAnimation()}
        transition={springSlide}
      >
        {loading ? (
          <Spinner color={['outlined', 'link'].includes(variant) ? background : color} size="xs" />
        ) : (
          <>
            {prefixIcon && (
              <>
                <Styled.Icon
                  path={prefixIcon}
                  size={Styled.sizesRem[size]}
                  color={disabled ? '#707070' : color}
                />{' '}
                {children && <Styled.Spacing />}
              </>
            )}
            {children}
            {suffixIcon && (
              <>
                {children && <Styled.Spacing />}
                <Styled.Icon
                  path={suffixIcon}
                  size={Styled.sizesRem[size]}
                  color={disabled ? '#707070' : color}
                />{' '}
              </>
            )}
          </>
        )}
      </Styled.Button>
    </AnimatePresence>
  );
};

NewButton.defaultProps = {
  color: '#ffffff',
  background: '#0071eb',
  onClick: null,
  size: 'md',
  disabled: false,
  fullWidth: false,
  rounded: false,
  loading: false,
  maxWidth: null,
  variant: 'contained',
  paddingVertical: 8,
};

export default NewButton;

import { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react';
import { mdiMenuDown, mdiMenuUp } from '@mdi/js';

import { springSlide } from 'Utils/animations';

import * as Styled from './styled';

type Props = {
  placeholder?: string;
  initialValue?: string;
  onChange?: (...args: any[]) => any;
  width?: string | number;
  expandable?: boolean;
  direction?: 'top' | 'bottom';
  padding?: number;
  top?: number;
  disabled?: boolean;
  onKeyDown?: (...args: any[]) => any;
};

const TextArea = forwardRef<any, Props>(
  (
    {
      initialValue,
      placeholder,
      onChange,
      width,
      expandable,
      direction,
      padding,
      disabled,
      top,
      resetExpanded,
      onKeyDown,
      ...props
    },
    ref
  ) => {
    const [value, handleValue] = useState(initialValue);
    const [expanded, handleExpanded] = useState(false);

    const toggleExpanded = () => handleExpanded(!expanded);
    const handleChangeValue = (e) => handleValue(e.target.value);

    const refEditor = useRef();

    useEffect(() => {
      if (onChange && initialValue !== value) {
        onChange(value);
      }
    }, [value, onChange, initialValue]);

    useEffect(() => {
      handleExpanded(false);
    }, [resetExpanded, handleExpanded]);

    useEffect(() => {
      if (disabled) {
        handleExpanded(false);
      }
    }, [disabled, handleExpanded]);

    useImperativeHandle(ref, () => ({
      focus() {
        refEditor?.current?.focus();
      },
      clear() {
        handleValue('');
      },
    }));

    const variants = {
      up: {
        position: 'absolute',
        bottom: 0,
        top,
        height: 55,
        minHeight: 55,
        right: padding,
        left: padding,
        background: disabled ? '#707070' : '#f4f5f7',
      },
      down: {
        position: 'relative',
        top: 0,
        height: 55,
        minHeight: 55,
        right: padding,
        left: padding,
        background: disabled ? '#707070' : '#f4f5f7',
      },
      expanded: {
        position: 'absolute',
        bottom: 0,
        height: '100%',
        minHeight: '100%',
        top: 0,
        right: padding,
        left: padding,
        background: disabled ? '#707070' : '#f4f5f7',
      },
    };

    return (
      <Styled.Container
        ref={ref}
        expandable={expandable}
        direction={direction}
        expanded={expanded}
        width={width}
        padding={padding}
        variants={variants}
        disabled={disabled}
        {...props}
        transition={springSlide}
        initial={direction === 'top' ? 'up' : 'down'}
        animate={expanded ? 'expanded' : direction === 'top' ? 'up' : 'down'}
      >
        <Styled.TextArea
          placeholder={placeholder}
          value={value}
          onChange={handleChangeValue}
          disabled={disabled}
          transition={springSlide}
          ref={refEditor}
          onKeyDown={onKeyDown}
        />
        {expandable && (
          <Styled.ExpandableIcon
            onClick={disabled ? null : toggleExpanded}
            path={direction === 'bottom' ? mdiMenuDown : expanded ? mdiMenuDown : mdiMenuUp}
            size={1.5}
            color="#707070"
          />
        )}
      </Styled.Container>
    );
  }
);

TextArea.displayName = 'TextArea';

TextArea.defaultProps = {
  expandable: false,
  width: '100%',
  direction: 'bottom',
  padding: 0,
  disabled: false,
  onKeyDown: null,
};

export default TextArea;

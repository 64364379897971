import { useRef, useContext, useEffect, Fragment, useMemo } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy, useExpanded } from 'react-table';
import cls from 'classnames';
import {
  mdiSort,
  mdiSortAscending,
  mdiSortDescending,
  mdiChevronRight,
  mdiChevronLeft,
} from '@mdi/js';

import useCache from 'Hooks/useCache';
import ModuleContext from 'Context/moduleContext';

import TableSkeleton from './TableSkeleton';
import * as Styled from './styled';
import { useDataTable } from './container';

export default function Datatable({
  columns,
  data,
  fetchData,
  totalData,
  totalPages,
  loading = false,
  renderRowSubComponent,
  contextKey,
  refetch,
  header,
  autoResetSortBy = true,
  onPageSizeChange,
  cacheKey = '',
}) {
  const skipPageResetRef = useRef();

  const { searchKeyword, stepActive, filters } = useContext(ModuleContext);

  const isRemoteData = useMemo(() => Boolean(fetchData), [fetchData]);

  const [userPageSize, setUserPageSize] = useCache(`${cacheKey}-user-page-size`, 6);
  const [cacheSortBy, setCacheSortBy] = useCache<Array<{ id: string; desc: boolean }>>(
    `${cacheKey}-sort-by`,
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !!skipPageResetRef.current,
      manualPagination: isRemoteData,
      manualSortBy: isRemoteData,
      pageCount: totalPages,
      initialState: {
        pageSize: userPageSize,
        sortBy: cacheSortBy,
        ...(searchKeyword && !isRemoteData ? { globalFilter: searchKeyword } : {}),
      },
      stateReducer: (newState, action) => {
        if (action?.type === 'toggleSortBy') {
          setCacheSortBy(newState?.sortBy ?? []);
        }

        return newState;
      },
      autoResetSortBy,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const { setDataTableFetch } = useDataTable(contextKey);

  const row = useMemo(() => {
    skipPageResetRef.current = false;
    const count = (isRemoteData ? totalData : rows?.length) || 0;
    const min = count > 0 ? pageIndex * pageSize + 1 : 0;
    const max = Math.min((pageIndex + 1) * pageSize, count);

    return { count, min, max };
  }, [rows, isRemoteData, totalData, pageSize, pageIndex]);

  const pageIsIncorrect = row.min > row.count;

  useEffect(() => {
    gotoPage(0);
  }, [gotoPage, pageIsIncorrect]);

  useEffect(() => {
    skipPageResetRef.current = true;
    if (!isRemoteData) {
      setGlobalFilter(searchKeyword);
    }
  }, [searchKeyword, setGlobalFilter, isRemoteData]);

  useEffect(() => {
    skipPageResetRef.current = true;
  }, [filters, stepActive]);

  useEffect(() => {
    if (isRemoteData) {
      fetchData({ page: pageIndex, limit: pageSize, sortBy });
      if (contextKey) {
        setDataTableFetch(refetch);
      }
    }
    // eslint-disable-next-line
  }, [isRemoteData, fetchData, pageIndex, pageSize, sortBy]);

  const getSortIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return mdiSortDescending;
      }

      return mdiSortAscending;
    }

    return mdiSort;
  };

  return (
    <>
      <Styled.Container>
        <Styled.TableWrap>
          {header}
          <Styled.Table {...getTableProps()} className="table">
            <Styled.Thead>
              {headerGroups.map((headerGroup, idx) => (
                <Styled.Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                  {headerGroup.headers
                    .filter((c) => !c.hide)
                    .map((column, i) => {
                      return (
                        <Styled.Th
                          active={column.isSorted}
                          sort={column.canSort}
                          key={i}
                          {...column.getHeaderProps({
                            ...column.getSortByToggleProps(),
                            style: {
                              width: column.width,
                              minWidth:
                                column.forceWidth || column.minWidth
                                  ? column.minWidth || column.width
                                  : null,
                            },
                          })}
                          className={cls({
                            collapsed_: column.collapse,
                          })}
                        >
                          {column.render('Header')}
                          {column.canSort && <Styled.Icon path={getSortIcon(column)} size={0.6} />}
                        </Styled.Th>
                      );
                    })}
                </Styled.Tr>
              ))}
            </Styled.Thead>
            <Styled.Tbody {...getTableBodyProps()}>
              {!loading &&
                page.map((row) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  return (
                    // Use a Reactyled.Trt.Fragment here so the table markup is still valid
                    <Fragment key={rowProps.key}>
                      <Styled.Tr {...rowProps} valign="middle">
                        {row.cells
                          .filter((c) => !c.column.hide)
                          .map((cell, i) => {
                            return (
                              <td
                                key={i}
                                {...cell.getCellProps({
                                  className: cell.column.collapse ? 'collapsed_' : '',
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                      </Styled.Tr>
                      {/*
                  If the row is in an expanded state, render a row with a
                  column that fills the entire length of the table.
                */}
                      {row.isExpanded &&
                        renderRowSubComponent &&
                        renderRowSubComponent({ visibleColumns, row })}
                    </Fragment>
                  );
                })}
              {loading && <TableSkeleton columns={visibleColumns.length} rows={pageSize} />}
            </Styled.Tbody>
          </Styled.Table>
        </Styled.TableWrap>
      </Styled.Container>
      <Styled.Pagination>
        <Styled.PageSizeContainer>
          <Styled.PageSizeLabel htmlFor="page-size">Linhas por página:</Styled.PageSizeLabel>
          <Styled.Select
            id="page-size"
            onChange={(e) => {
              const val = Number(e.target.value);
              setPageSize(val);
              setUserPageSize(val);
              onPageSizeChange?.(val);
            }}
            value={pageSize}
          >
            {[6, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Styled.Select>
        </Styled.PageSizeContainer>
        <Styled.PaginationButton disabled={!canPreviousPage} onClick={previousPage}>
          <Styled.IconPaginaton path={mdiChevronLeft} size={1.37} title="Página anterior" />
        </Styled.PaginationButton>
        <Styled.DataInfo>
          <Styled.Bold>
            {row?.min}-{row?.max}
          </Styled.Bold>{' '}
          de {row?.count}
        </Styled.DataInfo>
        <Styled.PaginationButton disabled={!canNextPage} onClick={nextPage}>
          <Styled.IconPaginaton path={mdiChevronRight} size={1.37} title="Próxima página" />
        </Styled.PaginationButton>
      </Styled.Pagination>
    </>
  );
}

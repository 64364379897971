import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => {
    return css`
      width: ${expanded ? 512 : 320}px;

      display: flex;

      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    `;
  }}
`;

export const WrapperFileInfo = styled.div`
  display: flex;

  align-items: center;
  flex-direction: row;

  column-gap: 5px;
`;

export const WrapperLogo = styled.div`
  cursor: pointer;
`;
export const WrapperFileName = styled.div``;
export const FileName = styled.div``;
export const FileSize = styled.div``;

export const WrapperDowload = styled.a`
  cursor: pointer;
`;

import { gql } from '@apollo/client';

export const ContactTemplateFields = gql`
  fragment ContactTemplateFields on ActiveContactTemplates {
    name
    message
    variables
  }
`;

export const EventsFields = gql`
  fragment EventsFields on BotEvents {
    event
    timestamp
    text
    handover
    file {
      url
      type
      size
      name
    }
  }
`;

export const ConversationsFields = gql`
  fragment ConversationsFields on ConversationDocuments {
    totalPages
    totalDocuments
    conversations {
      ...EventsFields
    }
  }

  ${EventsFields}
`;

import { useState, useEffect, memo, useCallback } from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import Styled from './styled';

function ModalChat({ children, width = 500, onClose, initialOpen = false }) {
  const [show, setShow] = useState(initialOpen);

  // onToggle reusable function
  useEffect(() => {
    !show && typeof onClose === 'function' && onClose();
  }, [show, onClose]);

  const handleClose = useCallback(() => setShow(false), [setShow]);
  const handleShow = useCallback(() => setShow(true), [setShow]);

  if (!show) return <></>;

  return (
    <Styled.ModalChat>
      <Styled.Content width={width}>
        <Styled.Close onClick={handleClose}>
          <Icon color="#FFF" path={mdiClose} size={0.9} />
        </Styled.Close>
        {children({ handleClose, handleShow })}
      </Styled.Content>
    </Styled.ModalChat>
  );
}

ModalChat.Title = Styled.Title;

export default memo(ModalChat);

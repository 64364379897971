import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Scrollbar = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100vh - 140px);

  background: transparent;
  color: #333;

  margin: 0px;
  padding: 0px;

  overflow-x: hidden;
  overflow-y: auto;
`;

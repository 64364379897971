import { useContext, useCallback, useEffect } from 'react';

import Container from 'Components/TeleAtendimento/Container';
import Data from 'Components/TeleAtendimento/Data';
import { Menu } from 'Components/TeleAtendimento/Menu';
import { SectorRoutes } from 'Utils/Dictionary/RemoteAtendimento/TeleAtendimento';
import ModuleContext from 'Context/moduleContext';

const getStepAcessed = (match) => {
  let stepAcessed;
  if (match.params.sector) {
    stepAcessed = SectorRoutes[match.params.sector];
  }
  return stepAcessed;
};

const Active = ({ match }) => {
  const { moduleDispatch } = useContext(ModuleContext);
  const sector = getStepAcessed(match);

  const CHANGE_STEP = useCallback(() => {
    (async () => {
      await moduleDispatch({
        type: 'CHANGE_STEP',
        stepActive: sector.slug,
      });
    })();
  }, [moduleDispatch, sector.slug]);

  useEffect(() => {
    CHANGE_STEP();
    return () => {
      CHANGE_STEP();
    };
  }, [CHANGE_STEP]);

  return (
    <Container>
      <Menu>
        <Data sector={SectorRoutes[match.params.sector]} />
      </Menu>
    </Container>
  );
};

export default Active;

import { memo } from 'react';

import { Styled } from './styles';
import getInitials from '../../utils/getInitials';
import { numberMask } from '../../utils/numberMask';

const ToolBar = (props) => {
  const { requestHandover, returnHandover, handover, disabled } = props;

  const Handover = () => {
    handover === 'HandOver' ? requestHandover('Bot') : returnHandover('HandOver');
  };

  const { data } = props;

  return (
    <Styled.ToolBar>
      <Styled.Info>
        <Styled.Avatar>{data && data.name === '' ? 'NC' : getInitials(data.name)}</Styled.Avatar>
        <span>
          <div>{data && data.name === '' ? 'Novo Cliente' : data.name}</div>
          <div>{numberMask(data.senderId)}</div>
        </span>
      </Styled.Info>

      <Styled.Handover type="button" onClick={Handover} style={{ display: disabled }}>
        {handover}
      </Styled.Handover>
    </Styled.ToolBar>
  );
};

export default memo(ToolBar);

import styled from 'styled-components';
import { motion } from 'framer-motion';
import scrollbar from 'Assets/styles/scrollbar';

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled(motion.div)`
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  min-width: 452px;
  position: relative;
  padding: ${(props) => (props.noPadding ? '0' : '20px 26px 22px 26px')};
  z-index: 101;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0px rgba(1, 126, 49, 0.05);
  max-height: calc(100vh - 125px);
  min-height: ${(props) => (props.height ? 'calc(100vh - 125px)' : '152px')};

  ${scrollbar}
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  font-family: 'Roboto';
  letter-spacing: 0.4px;
  color: #444444;
`;

export const Subtitle = styled.span`
  font-family: 'Roboto';
  font-size: 14px;
  color: #aaaaaa;
  letter-spacing: 0.28px;
  white-space: pre-line;
  word-break: break-word;
`;

export const Close = styled.span`
  position: absolute;
  right: 26px;
  top: 24px;
  cursor: pointer;
`;

import { Timeline, TagsList } from '../styled';

const TimelineTags = ({ timelineItem, shouldShow }) => {
  const addedTags = timelineItem?.added?.map((tag) => {
    return (
      <div className="tag" key={tag}>
        {' '}
        {tag}{' '}
      </div>
    );
  });

  const removedTags = timelineItem?.removed?.map((tag) => {
    return (
      <div className="tag" key={tag}>
        {' '}
        {tag}{' '}
      </div>
    );
  });

  return (
    <>
      {shouldShow ? (
        <Timeline.Body>
          <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }}>
            {timelineItem.added.length ? (
              <TagsList>
                <div> Tag atribuída: </div> {addedTags}
              </TagsList>
            ) : null}
            {timelineItem.removed.length ? (
              <TagsList>
                <div> Tag removida: </div> {removedTags}
              </TagsList>
            ) : null}
          </Timeline.Body.Item>
        </Timeline.Body>
      ) : null}
    </>
  );
};

export default TimelineTags;

/* eslint-disable react/display-name */
import styled, { css } from 'styled-components';

import scrollbar from 'Assets/styles/scrollbar';
import {
  BadgeWithColorTranslated,
  BadgeWithColorTranslatedNone,
  BadgeWithColorTranslatedBadge,
} from 'Components/Badge';
import { formatTo, DateAndTime } from 'Utils/Helpers/DateFormatterHelper';
import { ColorDictionary } from 'Utils/Dictionary/ColorDictionary';

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  height: auto;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;

  .timeline-row {
    display: flex;
  }

  .left-date {
    font-weight: bold;
    padding: 20px;
  }

  .right-date {
    font-weight: bold;
  }

  .header-container {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 3px 15px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    span {
      margin-right: 10px;
    }

    .blue-arrow {
      position: absolute;
      right: 25px;
      width: 20px;
      transition: 0.3s;
    }

    .opened-arrow {
      transform: rotateX(180deg);
    }
  }
` as any;

Timeline.Container = styled.div`
  display: flex;
`;

Timeline.TableData = styled.div``;

Timeline.Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

Timeline.Item = styled.div`
  display: flex;
  margin-bottom: 5px;
  padding: 10px;
  color: #707070;
`;

Timeline.GroupTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

Timeline.Title = styled.h5`
  font-size: 12px;
  font-weight: bold;
  color: #0071eb;
  margin-bottom: 16px;
`;

Timeline.Subtitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #707070;
`;

Timeline.Text = styled.p`
  font-size: 12px;
  color: #707070;
  margin-bottom: 20px;
`;

Timeline.GroupClassfiers = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

Timeline.Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  border: none;
  background-color: #0071eb;
  cursor: pointer;

  &:visited,
  &:link,
  &:hover,
  &:active {
    text-decoration: none;
    color: #fff;
  }
`;

Timeline.Wrapper = styled.div`
  position: relative;
  border-left: 2px solid #0071eb;
  padding: 10px 10px 10px 20px;
  width: 100%;
`;

Timeline.OrderList = styled.ul`
  display: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  list-style-type: square;
  margin: 0px;

  > li {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    width: 100%;
    list-style: none;
    margin: 0px 0px 0px -15px;

    > strong {
      margin: 8px 10px 0px 0px;
      background: #333;
      color: #333;
      border: 2px solid #333;
      border-radius: 50%;
      width: 5px;
      height: 5px;
    }
  }
`;

Timeline.Badge = styled.span`
  margin-left: 8px;
  padding: 0 7px;
  line-height: 22px;
  border-radius: 3px;
  border: 1px solid #0071eb;
  color: #0071eb;
  background-color: white;
`;

Timeline.BadgeClassifier = styled.span`
  padding: 0 4px;
  border-radius: 3px;
  border: none;
  color: #707070;
  background-color: #e3e3e3;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const HeaderWrapper = styled.div.attrs((props) => ({
  backgroundColor: props.backgroundColor,
  color: props.color,
}))`
  ${({ backgroundColor, color }) =>
    backgroundColor &&
    css`
      color: ${color};
      background-color: ${backgroundColor || '#fff'};
    `}
`;

Timeline.Header = ({ idx }) => (
  <HeaderWrapper>
    <strong>{idx}ª resposta</strong>
  </HeaderWrapper>
);

Timeline.HeaderNew = ({ title = '', monitoring = false }) => (
  <HeaderWrapper monitoring={monitoring}>
    <strong>{title}</strong>
  </HeaderWrapper>
);

Timeline.HeaderDateOnly = ({ title = '', interruptedModule = '', color, backgroundColor }) => (
  <HeaderWrapper backgroundColor={backgroundColor} color={color}>
    <strong>
      {title}
      {interruptedModule}
    </strong>
  </HeaderWrapper>
);

Timeline.LeftDate = ({ datetime }) => (
  <div className="left-date text-facelift-primary">
    {formatTo(datetime, DateAndTime.datetimeseconds)}
  </div>
);

Timeline.RightDate = ({ datetime }) => (
  <div className="right-date">{formatTo(datetime, DateAndTime.datetimeseconds)}</div>
);

export const AlertWrapper = styled.div`
  height: 36px;
  line-height: 36px;
  position: relative;
  background: '#444444';
`;

Timeline.AlertNone = ({ children, monitoring }) => (
  <AlertWrapper>
    <BadgeWithColorTranslatedNone type={'timeline'} style={{ zIndex: 1 }} color={children} />
  </AlertWrapper>
);

Timeline.AlertBadge = ({ children, monitoring }) => (
  <AlertWrapper>
    <BadgeWithColorTranslatedBadge type={'timeline'} style={{ zIndex: 1 }} color={children} />
  </AlertWrapper>
);

Timeline.Alert = ({ children, monitoring }) => (
  <AlertWrapper>
    <BadgeWithColorTranslated type={'timeline'} style={{ zIndex: 1 }} color={children} />
  </AlertWrapper>
);

Timeline.Body = styled.div`
  border: 1px solid
    ${(props) => (props.color ? ColorDictionary.Badge[props.color].color : '#e3e3e3')};
`;

Timeline.Body.Item = styled.div.attrs((props) => ({
  scrollColor: '#cecece',
  maxHeight: props.maxHeight || 'auto',
}))`
  border-top: 1px solid
    ${(props) => (props.color ? ColorDictionary.Badge[props.color].color : '#e3e3e3')};
  padding: 10px;
  word-break: break-word;
  white-space: break-spaces;
  max-height: ${(props) =>
    typeof props.maxHeight === 'number' ? `${props.maxHeight}px` : props.maxHeight};
  &:first-child {
    border-top: none;
  }

  ${scrollbar}
`;

Timeline.Small = styled.p`
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
`;

export const PaginationButton = styled.button`
  background-color: #0071eb;
  border-radius: 50%;
  border: 0;
  width: 47px;
  height: 47px;
  position: absolute;
  left: -32px;
  text-align: right;
  top: 50%;
  z-index: 1;
  ${(props) =>
    props.position === 'right'
      ? `position: right;
    left: auto;
    right: -30px;
    text-align: left;`
      : ''}
  &:focus {
    outline: none;
  }
  &:disabled {
    display: none;
  }
`;

export const Container = styled.div`
  flex: 1;

  .grid {
    display: flex;
  }
`;

export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: start;
`;

export const PatientInfoContainer = styled.div`
  flex: 1;

  div > div {
    margin: 12px 0;
  }

  .subcontainer {
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
  }

  .patient-name {
    text-transform: uppercase;
  }

  label {
    font-weight: bold;
    margin: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  width: 56px;
  padding: 8px 0px;
  box-shadow: 0px 1px 5px #017e280d;
  border-radius: 5px;
  background: #fff;
  margin: 20px 0px;
`;

export const ActionSpacing = styled.div`
  height: 16px;
`;

export const TimelineContainer = styled.div`
  flex: 1;
  margin: 10px;
  padding: 10px;
  overflow: auto;
`;

export const Breadcrumb = styled.div`
  display: flex;
  margin: 10px;

  div:first-child span {
    text-decoration: underline;
  }

  & > div {
    padding: 10px;
  }

  .left-arrow {
    height: 20px;
    margin-right: 20px;
  }

  .breadcrumb-separator img {
    height: 13px;
  }

  .patient-name {
    text-transform: capitalize;
  }

  .pointer {
    cursor: pointer;
  }
`;

export const TagsList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;
  flex-wrap: wrap;

  div:first-child {
    min-width: 86px;
  }

  .tag {
    padding: 5px;
    color: #444444;
    background: #e3e3e3;
    border-radius: 3px;
    height: 30px;
    margin: 10px;
  }
`;

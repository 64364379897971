import { useContext, useEffect, useMemo } from 'react';
import { gql } from '@apollo/client';

import { getEntityId } from 'Utils/Config/SectorConfig';
import ModuleContext from 'Context/moduleContext';
import ServicesStep from 'Components/ServicesStep';
import SubStepTabs from 'Components/SubStepTabs';
import { SelectedFiltersList } from 'Components/Datatable/SelectedFiltersList';
import { useChat } from 'Components/Chat/hooks/useChat';
import { useDataTable } from 'Components/Datatable/container';
import useDocumentFilters from 'Hooks/useDocumentsFilter';
import Data from '../Data';
import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';
import {
  useDocumentsCountLazyQuery,
  useServiceStepsChatCountLazyQuery,
} from './__generated__/index.generated';
import { toast } from 'react-toastify';

import Toast from 'Components/Toast';
import { MenuProvider } from 'Context/menuContext';

export const serviceStepsChatCountQuery = gql`
  query ServiceStepsChatCount(
    $module: String!
    $entityId: Int!
    $search: String
    $filter: DocumentsFilterInput
  ) {
    serviceStepsChatCount(module: $module, entityId: $entityId, search: $search, filter: $filter) {
      DASHBOARD {
        unreadChats
        patientCount
      }
      TELEMEDICINE {
        unreadChats
        patientCount
      }
      TELEMEDICINESCHEDULE {
        unreadChats
        patientCount
      }
      TELEMEDICINEQUEUE {
        unreadChats
        patientCount
      }
      CHAT {
        unreadChats
        patientCount
      }
      HISTORY {
        unreadChats
        patientCount
      }
      MONITORING {
        unreadChats
        patientCount
      }
      IN_PROGRESS {
        unreadChats
        patientCount
      }
      SCHEDULE {
        unreadChats
        patientCount
      }
      TRANSFERRED {
        unreadChats
        patientCount
      }
      CHAT_WAITING {
        unreadChats
        patientCount
      }
      CHAT_PROGRESS {
        unreadChats
        patientCount
      }
      CHAT_ADJOURNED {
        unreadChats
        patientCount
      }
      ELIGIBLE {
        unreadChats
        patientCount
      }
    }
  }
`;

export const __deprecatedServiceStepsQuery = gql`
  query DocumentsCount(
    $entityId: Int!
    $module: String!
    $search: String
    $filter: DocumentsFilterInput
  ) {
    documentCount(entityId: $entityId, module: $module, search: $search, filter: $filter) {
      DASHBOARD
      HISTORY
      INTENTIONS
      ELIGIBLE
      CHECKOUT
      MONITORING
      TOWN_MONITORING
      FORWARDED
      GENERALIST_TRANSFERRED
      GENERALIST_IN_PROGRESS
      GENERALIST_QUEUE
      GENERALIST_MONITORING
      IN_PROGRESS
      TRANSFERRED
      SCHEDULE
      TELEMEDICINE
      TELEMEDICINESCHEDULE
      TELEMEDICINEQUEUE
      REMOTEATENDIMENTO
      MAINSTEP
      CHAT
      CHAT_WAITING
      CHAT_PROGRESS
      CHAT_ADJOURNED
    }
  }
`;

type ServiceStepPropName =
  | 'DASHBOARD'
  | 'HISTORY'
  | 'INTENTIONS'
  | 'ELIGIBLE'
  | 'CHECKOUT'
  | 'MONITORING'
  | 'TOWN_MONITORING'
  | 'FORWARDED'
  | 'GENERALIST_TRANSFERRED'
  | 'GENERALIST_IN_PROGRESS'
  | 'GENERALIST_QUEUE'
  | 'GENERALIST_MONITORING'
  | 'IN_PROGRESS'
  | 'TRANSFERRED'
  | 'SCHEDULE'
  | 'TELEMEDICINE'
  | 'TELEMEDICINESCHEDULE'
  | 'TELEMEDICINEQUEUE'
  | 'REMOTEATENDIMENTO'
  | 'MAINSTEP'
  | 'CHAT'
  | 'CHAT_WAITING'
  | 'CHAT_PROGRESS'
  | 'CHAT_ADJOURNED';

type ServiceStepObject = {
  patientCount: number;
  unreadChats: Array<string>;
};

export type ServiceSteps = Record<ServiceStepPropName, number | ServiceStepObject>;

export default function Menu({ sector }) {
  const { searchKeyword, moduleAccess } = useContext(ModuleContext);
  const { setLoadMenu } = useChat();
  const filters = useDocumentFilters();
  const displayUnreadMessages = useFlag(
    `lc-display-unread-messages-${environment.UNLEASH_APP_ENV}`
  );

  const [loadServiceStepsChatCount, serviceStepsChatCountResult] =
    useServiceStepsChatCountLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        entityId: getEntityId()[0],
        module: moduleAccess as unknown as string,
        search: searchKeyword,
        filter: filters,
      },
      onError: () => {
        toast(<Toast type="error" title="Não foi possível carregar contagem de pacientes" />);
      },
    });

  const [loadServiceStepsDeprecated, serviceStepsResultDeprecated] = useDocumentsCountLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      entityId: getEntityId()[0],
      module: moduleAccess as unknown as string,
      search: searchKeyword,
      filter: filters,
    },
    onError: () => {
      toast(<Toast type="error" title="Não foi possível carregar contagem de pacientes" />);
    },
  });

  const loadMenu = useMemo(
    () => (displayUnreadMessages ? loadServiceStepsChatCount : loadServiceStepsDeprecated),
    [displayUnreadMessages]
  );

  const refetchMenu = useMemo(
    () =>
      displayUnreadMessages
        ? serviceStepsChatCountResult.refetch
        : serviceStepsResultDeprecated.refetch,
    [displayUnreadMessages]
  );

  const countDocumentData = useMemo(
    () =>
      displayUnreadMessages
        ? serviceStepsChatCountResult.data?.serviceStepsChatCount
        : serviceStepsResultDeprecated.data?.documentCount,
    [displayUnreadMessages, serviceStepsChatCountResult, serviceStepsResultDeprecated]
  );

  const { setDocumentCountFetch } = useDataTable();

  useEffect(() => {
    setLoadMenu(loadMenu);
    loadMenu();
  }, [loadMenu]);

  useEffect(() => {
    setDocumentCountFetch(refetchMenu);
    return () => setDocumentCountFetch(null);
  }, [refetchMenu]);

  return (
    <MenuProvider stepsData={{ ...countDocumentData }}>
      <ServicesStep
        loading={serviceStepsChatCountResult.loading || serviceStepsResultDeprecated.loading}
        documentCount={countDocumentData}
      />
      <SelectedFiltersList />
      <div style={{ position: 'relative' }}>
        <SubStepTabs />
        <Data loadMenu={displayUnreadMessages ? () => void 0 : loadMenu} sector={sector} />
      </div>
    </MenuProvider>
  );
}

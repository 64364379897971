const hostname = window.location.hostname;

// this is needed to force webpack to inject process.env variables
const envs = {
  HOTJAR_ID: process.env.HOTJAR_ID,
  HOTJAR_SV: process.env.HOTJAR_SV,
  SENTRY_TRACES_SAMPLE_RATE: process.env.SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_DSN_LOCAL: process.env.SENTRY_DSN_LOCA,
  SENTRY_DSN_DEVELOP: process.env.SENTRY_DSN_DEVELOP,
  SENTRY_DSN_STAGING: process.env.SENTRY_DSN_STAGING,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
  SENTRY_ENVIRONMENT_LOCAL: process.env.SENTRY_ENVIRONMENT_LOCAL,
  SENTRY_ENVIRONMENT_DEVELOP: process.env.SENTRY_ENVIRONMENT_DEVELOP,
  SENTRY_ENVIRONMENT_STAGING: process.env.SENTRY_ENVIRONMENT_STAGING,
  UNLEASH_APP_NAME: process.env.UNLEASH_APP_NAME,
  UNLEASH_APP_ENV: process.env.UNLEASH_APP_ENV,
  UNLEASH_APP_ENV_LOCAL: process.env.UNLEASH_APP_ENV_LOCAL,
  UNLEASH_APP_ENV_DEVELOP: process.env.UNLEASH_APP_ENV_DEVELOP,
  UNLEASH_APP_ENV_STAGING: process.env.UNLEASH_APP_ENV_STAGING,
  UNLEASH_PROXY_URL: process.env.UNLEASH_PROXY_URL,
  UNLEASH_CLIENT_KEY: process.env.UNLEASH_CLIENT_KEY,
  UNLEASH_METRICS_INTERVAL: process.env.UNLEASH_METRICS_INTERVAL,
  UNLEASH_REFRESH_INTERVAL: process.env.UNLEASH_REFRESH_INTERVAL,
  AMPLITUDE: process.env.AMPLITUDE,
  AMPLITUDE_LOCAL: process.env.AMPLITUDE_LOCAL,
  AMPLITUDE_DEVELOP: process.env.AMPLITUDE_DEVELOP,
  AMPLITUDE_STAGING: process.env.AMPLITUDE_STAGING,
  GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT,
  GRAPHQL_ENDPOINT_LOCAL: process.env.GRAPHQL_ENDPOINT_LOCAL,
  GRAPHQL_ENDPOINT_DEVELOP: process.env.GRAPHQL_ENDPOINT_DEVELOP,
  GRAPHQL_ENDPOINT_STAGING: process.env.GRAPHQL_ENDPOINT_STAGING,
  GRAPHQL_WEBSOCKET_ENDPOINT: process.env.GRAPHQL_WEBSOCKET_ENDPOINT,
  GRAPHQL_WEBSOCKET_ENDPOINT_LOCAL: process.env.GRAPHQL_WEBSOCKET_ENDPOINT_LOCAL,
  GRAPHQL_WEBSOCKET_ENDPOINT_DEVELOP: process.env.GRAPHQL_WEBSOCKET_ENDPOINT_DEVELOP,
  GRAPHQL_WEBSOCKET_ENDPOINT_STAGING: process.env.GRAPHQL_WEBSOCKET_ENDPOINT_STAGING,
  LANDING_TRIAGE: process.env.LANDING_TRIAGE,
  LANDING_TRIAGE_LOCAL: process.env.LANDING_TRIAGE_LOCAL,
  LANDING_TRIAGE_DEVELOP: process.env.LANDING_TRIAGE_DEVELOP,
  LANDING_TRIAGE_STAGING: process.env.LANDING_TRIAGE_STAGING,
  HOSTNAME_LOCAL: process.env.HOSTNAME_LOCAL,
  HOSTNAME_DEVELOP: process.env.HOSTNAME_DEVELOP,
  HOSTNAME_STAGING: process.env.HOSTNAME_STAGING,
  MEMED_URL: process.env.MEMED_URL,
  MEMED_URL_LOCAL: process.env.MEMED_URL_LOCAL,
  MEMED_URL_STAGING: process.env.MEMED_URL_STAGING,
  MEMED_URL_DEVELOP: process.env.MEMED_URL_DEVELOP,
  MEMED_API_URL: process.env.MEMED_API_URL,
  MEMED_API_URL_LOCAL: process.env.MEMED_API_URL_LOCAL,
  MEMED_API_URL_STAGING: process.env.MEMED_API_URL_STAGING,
  MEMED_API_URL_DEVELOP: process.env.MEMED_API_URL_DEVELOP,
  CHATBOT_STREAM: process.env.CHATBOT_STREAM,
  CHATBOT_STREAM_LOCAL: process.env.CHATBOT_STREAM_LOCAL,
  CHATBOT_STREAM_STAGING: process.env.CHATBOT_STREAM_STAGING,
  CHATBOT_STREAM_DEVELOP: process.env.CHATBOT_STREAM_DEVELOP,
};

export function hostnameVars(environmentVar: string) {
  if (hostname === envs.HOSTNAME_LOCAL && envs[`${environmentVar}_LOCAL`]) {
    return envs[`${environmentVar}_LOCAL`];
  }

  if (hostname === envs.HOSTNAME_DEVELOP && envs[`${environmentVar}_DEVELOP`]) {
    return envs[`${environmentVar}_DEVELOP`];
  }

  if (hostname === envs.HOSTNAME_STAGING && envs[`${environmentVar}_STAGING`]) {
    return envs[`${environmentVar}_STAGING`];
  }

  return envs[environmentVar];
}

export function isProduction() {
  if (
    hostname === envs.HOSTNAME_DEVELOP ||
    hostname === envs.HOSTNAME_STAGING ||
    hostname === envs.HOSTNAME_LOCAL
  ) {
    return false;
  }

  return true;
}

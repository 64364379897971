import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const Shortcut = styled.span`
  color: #a9a9a9;
  font-size: 12px;
  position: absolute;
  bottom: 3px;
  right: 32px;
`;

import styled, { css } from 'styled-components';
import IconBase from '@mdi/react';

export const Container = styled.div.attrs((props) => ({
  color: props.color,
  activeColor: props.activeColor,
  active: props.active,
}))`
  text-align: center;
  margin: 0px 8px;
  height: 110px;
  width: 120px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 21px 8px;
  color: #444444;
  font-size: 14px;
  font-family: 'Roboto';
  letter-spacing: 0px;

  ${({ color, activeColor, active }) => css`
    border: 1px solid ${active ? activeColor : color};
    opacity: ${active ? 1 : 0.9};
  `}

  &:hover {
    opacity: 1;
  }
`;

export const Icon = styled(IconBase)`
  margin-bottom: 6px;
`;

export const Image = styled.img`
  margin-bottom: 6px;
  height: 24px;
  width: 24px;
`;

import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import Papa from 'papaparse';

import LocalCache from 'Services/LocalCache';
import { NewButton, Dropzone } from 'Components';
import { ADD_ELIGIBLE_PATIENTS_BY_JSON } from 'repository/mutations';
import ModuleContext from 'Context/moduleContext';
import cpf from 'Utils/Helpers/validate/cpf';
import Toast from 'Components/Toast';
import email from 'Utils/Helpers/validate/email';
import csv from 'Utils/Helpers/csv';
import cellphone from 'Utils/Helpers/validate/cellphone';

import * as Styled from './styled';
import { Card } from './components';
import array from 'Utils/Helpers/array';
import birthdate from 'Utils/Helpers/validate/birthdate';

import Select from 'Components/NewSelect';
import ComplaintModel from 'Models/ComplaintModel';
import { getEntityId } from 'Utils/Config/SectorConfig';
import ServiceStepModel from 'Models/ServiceStepModel';
import DailyLimitMessagesSentModel from 'Models/DailyLimitMessagesSentModel';
import { useTelemedicineScheduleEnabled } from 'Hooks/useTelemedicineScheduleEnabled';
import { useTelemedicineQueueEnabled } from 'Hooks/useTelemedicineQueueEnabled';
import { useDispatch, useSelector } from 'react-redux';
import { ModalConfirmationActions } from 'store/ducks/modal_confirmation';
import constants from '../../constants';
import ModalConfirmation from '../ModalConfirmation';

const dataExample = [
  {
    name: 'Fulana Nome Completo da Silva',
    email: 'fulana@gmail.com',
    cpf: '580.820.665-04',
    cellphone: '(41) 91234-5678',
    birthdate: '24/05/2018',
    tags: ['tag1', 'tag2'],
  },
];

const Import = () => {
  const dispatch = useDispatch();
  const { moduleAccess } = useContext(ModuleContext);

  const [file, handleFile] = useState([]);
  const [opening, handleOpening] = useState(false);
  const [triage, setTriage] = useState({ label: null, subStep: null });
  const [processedFile, handleProcessedFile] = useState(false);
  const [alreadyEligible, handleAlreadyEligible] = useState(0);
  const [data, handleData] = useState([]);
  const [selectedComplaint, handleSelectedComplaint] = useState([]);
  const [selectedServiceStep, setSelectedServiceStep] = useState('');
  const [showDailyLimitMessagesSent, setShowDailyLimitMessagesSent] = useState(false);
  const [dailyLimitMessagesSent, setDailyLimitMessagesSent] = useState(0);
  const isTelemedicineScheduleEnabled = useTelemedicineScheduleEnabled();
  const isTelemedicineQueueEnabled = useTelemedicineQueueEnabled();

  const { onCompleted } = useSelector(({ modal_confirmation: { onCompleted } }) => ({
    onCompleted,
  }));

  const { data: complaint, loading: loadingComplaint } = useQuery(
    ComplaintModel.GET_COMPLAINT(getEntityId(), moduleAccess),
    {
      variables: {
        entityId: getEntityId()?.[0],
        module: moduleAccess,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { data: serviceStep, loading: loadingServiceStep } = useQuery(
    ServiceStepModel.GET_SERVICE_STEP(),
    {
      fetchPolicy: 'network-only',
    }
  );

  const { data: statusDailyLimitMessagesSent } = useQuery(
    DailyLimitMessagesSentModel.GET_MESSAGES_LIMIT(getEntityId()?.[0]),
    {
      fetchPolicy: 'network-only',
    }
  );

  const [addEligibleCsv, { loading }] = useMutation(ADD_ELIGIBLE_PATIENTS_BY_JSON, {
    variables: {
      entityId: parseInt(LocalCache.getUser().entityId),
      user: LocalCache.getUser()?.name,
      module: moduleAccess,
      complaint: selectedComplaint?.value,
      flow: selectedComplaint?.label,
      triage: triage,
      email: LocalCache.getUser()?.email,
      attendantCpf: LocalCache.getUser()?.cpf,
    },
    onCompleted: ({ addEligiblePatientsByJSON }) => {
      handleAlreadyEligible((prevValue) => prevValue + addEligiblePatientsByJSON?.alreadyEligible);
    },
  });

  const disabled = useMemo(() => {
    return !selectedServiceStep?.value || !selectedComplaint?.value;
  }, [selectedServiceStep, selectedComplaint]);

  const complaintOptions = useMemo(() => {
    if (complaint && complaint?.getComplaintByModule?.length > 0) {
      return complaint?.getComplaintByModule?.map((item) => {
        return {
          value: item?.complaintSlug,
          label: item?.complaintName,
        };
      });
    }
  }, [complaint]);

  const serviceStepOptions = useMemo(() => {
    if (serviceStep && serviceStep?.getServiceStep?.length > 0) {
      return serviceStep?.getServiceStep
        ?.filter(
          (step) =>
            step?.name !== constants.monitoredByLauraStep && step?.name !== constants.videoStep
        )
        .map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
    }
  }, [serviceStep]);

  const rejectedData = useMemo(
    () =>
      data?.filter(
        (patient) =>
          !patient?.name ||
          !cpf.isValid(patient?.cpf) ||
          (patient?.email ? !email.isValid(patient?.email) : false) ||
          !cellphone.isValid(patient?.cellphone) ||
          (patient?.birthdate ? typeof birthdate.isValid(patient?.birthdate) !== 'boolean' : false)
      ),
    [data]
  );

  const acceptedData = useMemo(
    () =>
      data
        ?.filter(
          (patient) =>
            patient?.name &&
            cpf.isValid(patient?.cpf) &&
            (patient?.email ? email.isValid(patient?.email) : true) &&
            cellphone.isValid(patient?.cellphone) &&
            (patient?.birthdate ? typeof birthdate.isValid(patient?.birthdate) === 'boolean' : true)
        )
        .map((patient) => ({
          ...patient,
          email: patient?.email?.toLowerCase(),
          cpf: cpf.format(patient?.cpf),
          cellphone: cellphone.format(patient?.cellphone),
        })),
    [data]
  );

  useEffect(() => {
    handleProcessedFile(false);
    handleAlreadyEligible(0);
    handleData([]);

    const limit =
      statusDailyLimitMessagesSent?.getDailyLimitMessagesSent?.dailyLimitMessagesSent -
      statusDailyLimitMessagesSent?.getDailyLimitMessagesSent?.messagesSent;

    setDailyLimitMessagesSent(limit);
  }, [
    file,
    handleProcessedFile,
    handleData,
    handleAlreadyEligible,
    setDailyLimitMessagesSent,
    statusDailyLimitMessagesSent,
  ]);

  const handleSubmit = useCallback(() => {
    const tmpData = array.chunckInGrous(acceptedData, 100);

    Promise.all(
      tmpData.map((internalData) =>
        addEligibleCsv({
          variables: {
            patients: JSON.stringify(internalData),
          },
        })
      )
    )
      .then((result) => {
        setDailyLimitMessagesSent((prevState) => prevState + acceptedData?.length);
        const totalAlreadyInserted = result
          .map((insideData) => insideData.data)
          .reduce(
            (previousValue, currentObject) =>
              previousValue + currentObject.addEligiblePatientsByJSON?.alreadyEligible,
            0
          );

        if (totalAlreadyInserted === acceptedData?.length) {
          toast(
            <Toast
              type="error"
              title={'Error ao inserir pacientes na etapa'}
              subtitle={'Os pacientes inseridos já estão cadastrados dentro da plataforma.'}
            />,
            {
              autoClose: false,
            }
          );

          return dispatch(ModalConfirmationActions.modalConfirmationSetOnCompleted(false));
        }
        toast(
          <Toast
            type="success"
            title={'Inserção na etapa realizada com sucesso'}
            subtitle={
              'Pacientes inseridos nessa etapa permanecem lá até que seus atendimentos sejam iniciados manualmente.'
            }
          />,
          {
            autoClose: false,
          }
        );

        dispatch(ModalConfirmationActions.modalConfirmationSetOnCompleted(false));
      })
      .catch((error) => {
        dispatch(ModalConfirmationActions.modalConfirmationSetOnCompleted(false));
        throw new Error(error);
      });
  }, [acceptedData, addEligibleCsv, dispatch]);

  const openModalConfirmation = () => {
    dispatch(ModalConfirmationActions.modalConfirmationSetIsOpen(true));
    dispatch(
      ModalConfirmationActions.modalConfirmationSetData(
        selectedServiceStep,
        {},
        constants.importPatients
      )
    );
  };

  const handleSubmitFile = () => {
    handleOpening(true);
    Papa.parse(file[0], {
      header: true,
      skipEmptyLines: true,
      complete: ({ data: dataFile }) => {
        const numRows = dataFile?.length;
        if (numRows > 1000) {
          toast(
            <Toast
              type="error"
              title={'Não foi possível processar o arquivo'}
              subtitle="Os arquivos devem possuir no máximo 1000 registros."
            />
          );
        } else if (numRows > dailyLimitMessagesSent) {
          toast(
            <Toast
              type="error"
              title={'Erro ao inserir pacientes'}
              subtitle={`Somente ${dailyLimitMessagesSent} pacientes podem ser adicionados diariamente na plataforma`}
            />
          );
        } else {
          handleData(dataFile.map((patient) => ({ ...patient, tags: patient?.tags?.split(',') })));
          handleProcessedFile(true);
        }
        handleOpening(false);
      },
    });
  };

  const handleCancelInsertion = () => {
    handleData([]);
    handleProcessedFile(false);
  };

  const handleSelectedServiceStep = (e) => {
    setSelectedServiceStep(e);
    setShowDailyLimitMessagesSent(
      e != null &&
        (e.value === constants.idMonitoredByLauraStep ||
          (isTelemedicineScheduleEnabled && e.value === constants.idVideoStep))
    );

    let subStep = '';
    if (isTelemedicineScheduleEnabled) {
      subStep = 'SCHEDULE';
    } else if (isTelemedicineQueueEnabled) {
      subStep = 'TRANSFERRED';
    }

    setTriage({
      label: e?.label || 'elegíveis',
      subStep,
    });
  };

  const handleCsvFile = (isTemplate) => {
    if (isTemplate) {
      return csv.download(Papa.unparse(dataExample), 'template-import-patient-eligible.csv');
    }
    csv.download(
      Papa.unparse(rejectedData),
      `rejected-patients-eligible-${new Date()
        .toJSON()
        .slice(0, 19)
        .replaceAll('T', '-')
        .replaceAll(':', '')}.csv`
    );
  };

  useEffect(() => {
    if (onCompleted) {
      handleAlreadyEligible(0);
      handleSubmit();
    }
  }, [onCompleted, handleSubmit]);

  return (
    <>
      <div className="row d-flex align-items-stretch">
        <div className="col-md-6">
          <div className="form-style-1 card">
            <div className="card-body form-group">
              <div className="row">
                <div className="col-md-12">
                  <Styled.Legend>
                    Upload do arquivo .CSV{' '}
                    <Styled.Link onClick={() => handleCsvFile(true)}>
                      baixe aqui nosso template .csv
                    </Styled.Link>
                  </Styled.Legend>
                </div>
                <div className="col-md-12">
                  <b>Atenção</b>: Ao cadastrar pacientes em massa, todos serão colocados na mesma
                  linha de cuidado que você selecionar, onde a mesma não poderá ser alterada depois.
                  Os arquivos .csv para importação devem conter no máximo 1000 registros, e a
                  importação pode demorar até 3 minutos para ser finalizada.
                  <div style={{ marginTop: '18px' }}>
                    <Select
                      isClearable
                      placeholder="Selecione a etapa de atendimento para inserir os pacientes"
                      noOptionsMessage="Nenhuma etapa de atendimento encontrada"
                      isLoading={loadingServiceStep}
                      isSearchable
                      options={serviceStepOptions}
                      onChange={handleSelectedServiceStep}
                      value={selectedServiceStep}
                      maxLength={30}
                      menuPlacement="auto"
                    />
                  </div>
                  <div style={{ marginTop: '18px' }}>
                    <Select
                      isClearable
                      placeholder="Selecione uma linha de cuidado"
                      noOptionsMessage="Nenhuma linha de cuidado encontrada"
                      isLoading={loadingComplaint}
                      isSearchable
                      options={complaintOptions}
                      onChange={handleSelectedComplaint}
                      value={selectedComplaint}
                      maxLength={30}
                      menuPlacement="auto"
                    />
                  </div>
                  <Dropzone onChange={handleFile} accept=".csv" />
                  {showDailyLimitMessagesSent ? (
                    <Styled.Legend> Mensagens disponíveis: {dailyLimitMessagesSent} </Styled.Legend>
                  ) : null}
                </div>
              </div>
              <NewButton
                loading={opening}
                onClick={handleSubmitFile}
                fullWidth
                disabled={!file.length || processedFile || disabled}
              >
                Revisar arquivo
              </NewButton>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-stretch">
          {acceptedData?.length || rejectedData?.length ? (
            <Card
              title={`Adicionar pacientes na etapa de  ${
                selectedServiceStep?.label || 'elegíveis'
              }`}
              total={acceptedData?.length}
              rejectTotal={rejectedData?.length}
              alreadyInsertedTotal={alreadyEligible}
              onClick={openModalConfirmation}
              onClickDownload={() => handleCsvFile(false)}
              onClickCancel={handleCancelInsertion}
              loading={loading}
              successLabel="Pacientes com dados validados e prontos para serem adicionados."
              invalidLabel="Pacientes com dados inválidos ou fora do formato padrão exigido."
              alreadyInsertedLabel="Pacientes que já estavam na plataforma e foram desconsiderados."
              actionLabel="Adicionar na etapa selecionada"
            />
          ) : null}
        </div>
      </div>
      <ModalConfirmation />
    </>
  );
};

export default Import;

import { useState, useEffect, useCallback } from 'react';

import environment from 'Utils/Dictionary/environment';

import { api } from '../../utils/axios';

export default function useConversation(state, dispatch) {
  const [URI_CONVERSATIONS] = useState(environment.NISSAN_RASA);
  const [URI_SOCKET] = useState(environment.NISSAN_CHATBOT_STREAM);

  const [URI_REQ_HANOVER] = useState(environment.NISSAN_CHATBOT_API);
  const [URI_SEND_HANDOVER] = useState(environment.NISSAN_RASA);

  const ConversationHandOver = useCallback(() => {
    (async () => {
      if (state.senderId) {
        await api(environment.NISSAN_RASA)
          .get(`/conversations/${state.senderId}/tracker?token=${environment.RASA_AUTH}`)
          .then((res) => {
            dispatch({ type: 'ADD_EVENTS', payload: res.data.events });
          });
      }
    })();
    return () => void 0;
  }, [state.senderId, dispatch]);

  useEffect(() => {
    ConversationHandOver();
  }, [ConversationHandOver]);

  return { URI_CONVERSATIONS, URI_SOCKET, URI_REQ_HANOVER, URI_SEND_HANDOVER };
}

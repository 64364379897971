import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import IconBase from '@mdi/react';

export const Container = styled(motion.div).attrs((props) => ({
  active: props.active,
  background: props.background,
  $marginHorizontal: props.$marginHorizontal,
  disabled: props.disabled,
  $withTooltip: props.$withTooltip,
}))`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 40px;
  border-radius: 5px;
  position: relative;

  ${({ active, background, $marginHorizontal, disabled, $withTooltip }) => css`
    background: ${background};
    margin: 0px ${$marginHorizontal}px;
    cursor: ${disabled ? ($withTooltip ? 'help' : 'default') : 'pointer'};

    &:hover {
      filter: ${active || disabled ? 'brightness(1)' : 'brightness(0.98)'};
    }
  `}
`;

export const Icon = styled(IconBase)``;

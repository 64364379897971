import styled from 'styled-components';

export const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: #fff;
  background: #0071eb;
  opacity: 0.9;

  width: 100%;
  height: 70px;

  margin: 0px;
  padding: 0px 16px;

  box-shadow: 0px 2px 3px #ccc;
  z-index: 0;
`;
export const Avatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  margin: 0px;
  padding: 0px;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;

  > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

    padding: 0px 12px;
    font-size: 13px;
  }
`;
export const Handover = styled.button`
  cursor: pointer;
  width: 80px;
  height: 25px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  text-decoration: none;

  padding: 2px 8px;
  margin: -3px 35px 0px 0px;
`;

export const Styled = {
  ToolBar,
  Avatar,
  Info,
  Handover,
};

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { AnimatePresence } from 'framer-motion';

import { springSlide } from 'Utils/animations';

import * as Styled from './styled';

type OwnDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  width?: string;
  height?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
  noCloseButton?: boolean;
  backdrop?: boolean;
  closeOnOverlayClick?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
};

// @TODO - remove multiple modal implementations from app, and use this one, adapt this if needed
function Dialog({
  ref,
  children,
  onClose,
  isOpen = false,
  width = '600px',
  noPadding,
  noBorder,
  noCloseButton,
  height,
  backdrop = false,
  closeOnOverlayClick = false,
}: OwnDialogProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <Styled.Dialog>
          {backdrop && (
            <Styled.Backdrop
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.15,
                },
              }}
              onClick={() => closeOnOverlayClick && onClose()}
            />
          )}
          <Styled.Content
            ref={ref}
            height={height}
            noPadding={noPadding}
            noBorder={noBorder}
            width={width}
            transition={springSlide}
            initial={{
              opacity: 0,
              scale: 0.75,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                ease: 'easeOut',
                duration: 0.15,
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.75,
              transition: {
                ease: 'easeIn',
                duration: 0.15,
              },
            }}
          >
            {!noCloseButton && (
              <Styled.Close onClick={onClose}>
                <Icon color="#212121" path={mdiClose} size={0.7} />
              </Styled.Close>
            )}
            {children}
          </Styled.Content>
        </Styled.Dialog>
      )}
    </AnimatePresence>
  );
}

Dialog.Title = Styled.Title;
Dialog.Subtitle = Styled.Subtitle;
Dialog.Header = Styled.Header;
Dialog.Footer = Styled.Footer;
Dialog.Body = Styled.Body;

export default Dialog;

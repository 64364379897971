import styled, { css } from 'styled-components';
import IconBase from '@mdi/react';

type StyledProps = {
  active?: boolean;
  activeColor?: string;
  showAlert?: boolean;
  hasMessages?: boolean;
};

export const Container = styled.div<StyledProps>`
  position: relative;
  height: 57px;
  padding: 10px 17px;
  display: flex;
  border-radius: 5px;
  min-width: 150px;
  margin: 0px 8px;
  align-items: center;
  text-decoration: none !important;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: none;
  }

  ${({ active, activeColor, showAlert, hasMessages }) => css`
    color: ${active ? 'white' : '#444444'};
    background: ${active ? activeColor : '#FFFFFF'};
    font-size: ${active ? 16 : 15}px;
    font-weight: ${active ? 700 : 500};
    box-shadow: ${(showAlert || hasMessages) && !active
      ? '0px 0px 15px #FF881F36;'
      : '0px 3px 15px #0909090a'};
  `}
`;

export const LabelContiner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  letter-spacing: 0.45px;
`;

export const Counter = styled.span<StyledProps>`
  font-size: 14px;
  font-weight: 500;

  ${({ active }) => css`
    color: ${active ? 'white' : '#9B9B9B'};
  `}
`;

export const Icon = styled(IconBase)`
  margin-right: 17px;
`;

export const Image = styled.img`
  margin-right: 17px;
  height: 24px;
  width: 24px;
`;

export const Badge = styled.span`
  display: block;
  position: absolute;
  right: -9px;
  top: -9px;
  background: #ff881f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 9px;
  color: #fff;
  font-size: 12px;
`;

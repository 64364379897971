import { gql } from '@apollo/client';

export const TelemedicineDataFields = gql`
  fragment TelemedicineDataFields on TelemedicineData {
    email
    token
    firstName
    lastName
    enabled
    cpf
    birthdate
    crm
    uf
  }
`;

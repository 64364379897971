import { mdiLink } from '@mdi/js';
import { MainContent, Message, Title, Icon } from './styled';

const TelemedicineQueueModal = () => {
  return (
    <MainContent>
      <Icon path={mdiLink}></Icon>
      <Title>Mover e enviar link</Title>
      <Message>
        Ao mover o paciente para a etapa <strong>Vídeo</strong>, você <br></br> automaticamente
        estará enviando o link da teleconsulta.<br></br>
      </Message>
      <Message>
        Assim que o paciente acessar o link, ele poderá aguardar <br></br>na sala de espera até ser
        atendido, onde visualizará o <br></br>número de pessoas esperando atendimento.
      </Message>
    </MainContent>
  );
};

export default TelemedicineQueueModal;

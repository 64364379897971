import { NewButton } from 'Components';

import * as Styled from './styled';

type OwnProps = {
  onClick?: (...args: any[]) => any;
  onClickDownload?: (...args: any[]) => any;
  onClickCancel?: (...args: any[]) => any;
  total: number;
  rejectTotal: number;
  alreadyInsertedTotal: number;
  title: string;
  successLabel: string;
  invalidLabel: string;
  alreadyInsertedLabel: string;
  loading?: boolean;
  actionLabel?: string;
};

type Props = OwnProps & typeof Card.defaultProps;

const Card = ({
  onClick,
  onClickDownload,
  onClickCancel,
  total,
  rejectTotal,
  alreadyInsertedTotal,
  title,
  loading,
  actionLabel,
  successLabel,
  invalidLabel,
  alreadyInsertedLabel,
}: Props) => (
  <div className="col-md-12">
    <div className="card h-100" style={{ minWidth: '308px', maxWidth: '600px' }}>
      <div className="card-body form-group d-flex flex-column justify-content-between">
        <div className="d-flex flex-column" style={{ gap: '31px' }}>
          <Styled.Title>{title}</Styled.Title>

          <div className="d-flex flex-column">
            <Styled.Container color={'#0071eb'}>
              <Styled.TextMessage color={'#fff'}>{successLabel}</Styled.TextMessage>
              <Styled.Value color={'#fff'}>{total}</Styled.Value>
            </Styled.Container>
            {rejectTotal > 0 ? (
              <Styled.Container>
                <Styled.TextMessage>{invalidLabel}</Styled.TextMessage>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <Styled.Value>{rejectTotal}</Styled.Value>
                  <Styled.DownloadText onClick={onClickDownload}>
                    baixar lista .csv
                  </Styled.DownloadText>
                </div>
              </Styled.Container>
            ) : null}
            {alreadyInsertedTotal > 0 ? (
              <Styled.Container>
                <Styled.TextMessage>{alreadyInsertedLabel}</Styled.TextMessage>
                <Styled.Value>{alreadyInsertedTotal}</Styled.Value>
              </Styled.Container>
            ) : null}
          </div>
        </div>

        {onClick ? (
          <Styled.ButtonsContainer>
            <Styled.CancelText onClick={onClickCancel}>Cancelar</Styled.CancelText>
            <NewButton loading={loading} onClick={onClick}>
              {actionLabel}
            </NewButton>
          </Styled.ButtonsContainer>
        ) : (
          <span />
        )}
      </div>
    </div>
  </div>
);

Card.defaultProps = {
  onClick: null,
  onClickDownload: null,
  loading: false,
  actionLabel: null,
};

export default Card;

import { mdiCommentTextOutline, mdiContentDuplicate } from '@mdi/js';
import Icon from '@mdi/react';

export const AlertDate = ({ evolutionNote, telemedicine, children }) => (
  <div>
    {children}
    {evolutionNote && (
      <Icon
        style={{ marginLeft: 5 }}
        path={mdiCommentTextOutline}
        title={evolutionNote.observacao}
        size={0.8}
        color="#0071eb"
      />
    )}
    {telemedicine && (
      <Icon
        style={{ marginLeft: 5 }}
        path={mdiContentDuplicate}
        className="custom-class"
        title={'Módulo: Teleconsulta'}
        description={'teste'}
        size={0.8}
        color="#0071eb"
      />
    )}
  </div>
);

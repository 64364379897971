import { Button, Heading, VStack, Text, useToast } from '@chakra-ui/react';
import { useQueryParams } from 'Hooks/useQueryParams';
import { useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MailIcon } from 'theme/icons';
import { rules } from 'Utils/Helpers/Validator';
import { useUpdateUserPasswordMutation } from './__generated__/ForgotPassword.generated';

export const EmailSended = () => {
  const toast = useToast();
  const [email] = useQueryParams('email');
  const decodedEmail = useMemo(() => decodeURIComponent(email as string), [email]);
  const history = useHistory();

  useEffect(() => {
    if (!rules.email.test(decodedEmail)) {
      toast({
        status: 'error',
        title: 'E-mail inválido',
        description: 'Favor informe o e-mail novamente',
        position: 'top',
        isClosable: true,
      });

      history.replace('forgot-password');
    }
  }, [decodedEmail]);

  const toastError = useCallback(() => {
    toast({
      status: 'error',
      title: 'Erro',
      description: 'Não foi possível reenviar o e-mail no momento.',
      position: 'top',
      isClosable: true,
    });
  }, [toast]);

  const [updateUser, { loading }] = useUpdateUserPasswordMutation({
    onCompleted: ({ updateUserPassword }) => {
      if (updateUserPassword?.success && !updateUserPassword?.error) {
        toast({
          status: 'success',
          title: 'E-mail reenviado',
          description: 'E-mail reenviado com sucesso',
          position: 'top',
          isClosable: true,
        });

        return;
      }

      toastError();
    },
    onError: () => {
      toastError();
    },
  });

  const handleResendButtonClick = useCallback(() => {
    updateUser({
      variables: {
        input: {
          email: decodedEmail,
        },
      },
    });
  }, [decodedEmail]);

  return (
    <>
      <VStack spacing="2" marginBottom="8">
        <MailIcon boxSize="10" color="orange.500" />
        <Heading size="lg" fontWeight="light" textAlign="center">
          Email enviado
        </Heading>
        <Text fontSize="md" textAlign="center" color="gray.300">
          Acabamos de enviar para o seu email um link para a recuperação da senha. Após a
          redefinição da senha você terá acesso ao Backoffice do Digital Care novamente.
        </Text>
        <Text fontSize="md" textAlign="center" color="gray.300">
          Note que o link enviado tem validade somente por 1 hora.
        </Text>
      </VStack>
      <Button width="full" type="submit" onClick={handleResendButtonClick} isLoading={loading}>
        Reenviar email
      </Button>
    </>
  );
};

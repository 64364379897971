import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UnleashClient } from 'unleash-proxy-client';

const UnleashContext = createContext<{
  unleash: UnleashClient;
  updateUnleashUser: (tenantId: number | string) => void;
}>(null as any);

export const UnleashProvider = ({
  client,
  children,
}: PropsWithChildren<{ client: UnleashClient }>) => {
  const updateUnleashUser = useCallback(
    async (tenantId: number | string) => {
      await client.updateContext({
        userId: tenantId?.toString(),
      });
    },
    [client]
  );

  useEffect(() => {
    client.start().then(() => {
      const { userId: unleashUserId } = client.getContext();

      if (unleashUserId) {
        updateUnleashUser(unleashUserId);
      }
    });
    return () => client.stop();
  }, [client, updateUnleashUser]);

  return (
    <UnleashContext.Provider value={{ unleash: client, updateUnleashUser }}>
      {children}
    </UnleashContext.Provider>
  );
};

export function useUnleash() {
  return useContext(UnleashContext);
}

export function useFlag(featureKey: string) {
  const { unleash } = useUnleash();
  const [enabled, setEnabled] = useState(unleash.isEnabled(featureKey));

  const setState = useCallback(() => {
    setEnabled(unleash.isEnabled(featureKey));
  }, [featureKey, unleash]);

  useEffect(() => {
    unleash.on('update', setState);
    unleash.on('ready', setState);
    return () => {
      unleash.off('update', setState);
      unleash.off('ready', setState);
    };
  }, [unleash]);

  return enabled;
}

// TODO: remove this file when lc-enabled-refactored-auth-page flag is enabled in production

import { useForm } from 'react-hook-form';

import { rules } from 'Utils/Helpers/Validator';
import Input from 'Components/Input';
import LoginContainer from 'Components/LoginContainer';
import Button from 'Components/NewButton';

import useLogicLogin from './hooks/useLogicLogin';

export default function OldLogin() {
  const { register, handleSubmit, errors } = useForm();
  const { onSubmit, error, loading } = useLogicLogin();

  return (
    <LoginContainer>
      <LoginContainer.Logo />
      <LoginContainer.Welcome>
        Bem vindo ao <strong>PA Digital</strong>!
      </LoginContainer.Welcome>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          error={errors.email}
          errormessage="Favor informar o email."
          label="E-mail"
          name="email"
          id="email"
          ref={register({ required: true, pattern: rules.email })}
          type="email"
        />
        <Input
          error={errors.password}
          errormessage="Favor informar a senha."
          label="Senha"
          name="password"
          id="password"
          ref={register({ required: true })}
          type="password"
        />
        {Boolean(error) && <div className="alert alert-danger">{error}</div>}
        <Button
          style={{ marginBottom: 20 }}
          fullWidth
          loading={loading}
          background="#f78820"
          paddingVertical={12}
        >
          ENTRAR
        </Button>
      </form>
    </LoginContainer>
  );
}

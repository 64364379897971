import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { mdiAccountPlus, mdiExitToApp, mdiVideoOutline } from '@mdi/js';

import LocalCache from 'Services/LocalCache';
import routes from 'routes';
import { Header as DefaultHeader, ModuleSelect, StatusSelect, DashboardSelect } from 'Components';

import ModuleContext from 'Context/moduleContext';
import { Search } from 'Components/Datatable/Search';
import useCache from 'Hooks/useCache';
import router from 'Utils/Helpers/router';
import { removeMemedScriptConfig } from 'Utils/Helpers/removeMemedScriptConfig';
import { MEDICAL_STAFF, ALLOW_TO_DOCTORS } from 'Utils/Config/RolesConfig';
import { clearCachedFilters } from 'store/ducks/modules';

export default function Header() {
  const { searchKeyword, moduleAccess } = useContext(ModuleContext);
  const [user] = useCache('user');
  const [powerBi] = useCache('powerBi', null);
  const history = useHistory();

  const optionsBI = useMemo(() => {
    if (powerBi !== null && powerBi !== undefined) {
      return powerBi
        .filter((object) => object.module === moduleAccess)
        .map((object) => {
          const option = {};
          const slug = router.to(routes.POWER_BI.path, { slug: object.slug });
          option['value'] = slug;
          option['label'] = object.title;

          return option;
        });
    }

    return [];
  }, [powerBi, moduleAccess]);

  const optionsSelect = useMemo(() => [...(optionsBI.length ? optionsBI : [])], [optionsBI]);

  const handleLogout = async () => {
    clearCachedFilters();
    LocalCache.clearCache();
    await removeMemedScriptConfig();
    history.push(routes.LOGIN.path);
  };

  const getHeaderOptions = () => {
    return [
      {
        label: 'Ativar Telemedicina',
        icon: mdiVideoOutline,
        visible: MEDICAL_STAFF.includes(user?.roleId),
        onClick: () => history.push(router.to(routes.TELEMEDICINE.path)),
      },
      {
        label: 'Gerenciar equipe',
        icon: mdiAccountPlus,
        visible: ALLOW_TO_DOCTORS.includes(user?.roleId),
        onClick: () => history.push(router.to(routes.USERS.path)),
      },
      {
        label: 'Cadastrar pacientes',
        icon: mdiAccountPlus,
        visible: true,
        onClick: () => history.push(router.to(routes.PATIENTS.path, { type: 'individual' })),
      },
      {
        label: 'Fazer logout',
        icon: mdiExitToApp,
        visible: true,
        onClick: handleLogout,
      },
    ];
  };

  return (
    <DefaultHeader options={getHeaderOptions()}>
      <DefaultHeader.Col width="100%">
        <DefaultHeader.Col justify="flex-start">
          <ModuleSelect />
          <DefaultHeader.Divider />
          <StatusSelect routeAll={router.to(routes.DEFAULT)} />
          <DefaultHeader.Divider />
          <DashboardSelect options={optionsSelect} />
        </DefaultHeader.Col>
        <Search keyword={searchKeyword} />
      </DefaultHeader.Col>
    </DefaultHeader>
  );
}

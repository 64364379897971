import { useContext } from 'react';
import { HelperText, ScheduleTimesContainer } from './styled';
import { useQuery, gql } from '@apollo/client';
import ScheduleTimesItem from './ScheduleTimesItem';
import { AnimatePresence } from 'framer-motion';
import useCache from 'Hooks/useCache';
import ModuleContext from 'Context/moduleContext';
import { ModalScheduleActions } from 'store/ducks/modal_schedule';
import { useDispatch } from 'react-redux';
import Spinner from 'Components/Spinner';

const SCHEDULE_TIMES = gql`
  query getTeleconsultationScheduleSlots($entityId: Int!, $date: String!, $module: String!) {
    getTeleconsultationScheduleSlots(entityId: $entityId, date: $date, module: $module) {
      startTime
      endTime
      scheduledConsultations
    }
  }
`;

/**
 * Componente responsável pela listagem dos horários disponíveis para o dia
 * @param {{}} props
 */
export default function ScheduleTimes({ date, value, height }: Record<string, any>) {
  const [user] = useCache('user');
  const { moduleAccess } = useContext(ModuleContext);
  const dispatch = useDispatch();
  const { data, loading, error } = useQuery(SCHEDULE_TIMES, {
    variables: {
      entityId: parseInt(user.entityId),
      date: date || '',
      module: moduleAccess,
    },
  });

  const onChange = (item) => {
    dispatch(ModalScheduleActions.modalScheduleSetInterval(item));
  };

  if (!date) {
    return null;
  }

  const renderScheduleTimes = (item, index) => {
    const selected = value?.startTime === item.startTime && value?.endTime === item.endTime;
    return <ScheduleTimesItem key={index} selected={selected} item={item} onClick={onChange} />;
  };

  if (loading) {
    return (
      <HelperText>
        <Spinner color="#444" size="md" />
      </HelperText>
    );
  }

  if (error) {
    return <HelperText>{error}</HelperText>;
  }

  return (
    <AnimatePresence>
      <ScheduleTimesContainer
        height={height}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            ease: 'easeOut',
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
      >
        {data.getTeleconsultationScheduleSlots.map(renderScheduleTimes)}
      </ScheduleTimesContainer>
    </AnimatePresence>
  );
}

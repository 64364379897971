import {
  SectorDictionary,
  SectorDictionaryHistory,
} from '../Dictionary/RemoteAtendimento/SectorDictionary';

export const RemoteAtendimentoSectors = [
  SectorDictionary.NISSAN,
  SectorDictionary.NISSAN_IN_AWAITING,
  SectorDictionary.NISSAN_HUMAN,
  SectorDictionary.NISSAN_HISTORY,
  SectorDictionary.NISSAN_CLOSED,
];

export const RemoteAtendimentoSectorsHistory = [
  SectorDictionaryHistory.NISSAN_HISTORY,
  SectorDictionaryHistory.NISSAN_IMPORT,
];

import styled from 'styled-components';
import IconBase from '@mdi/react';

export const Container = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 16px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 48px;
  background: #fff;
  box-shadow: 0px 3px 15px #0909090a;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #ececec;
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-family: 'Roboto';
  color: #444444;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
`;

export const Icon = styled(IconBase)`
  padding: 5px;
  border-radius: 100%;

  &:hover {
    background: #ececec;
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  width: 18px;
  height: 18px;
  background: #ff881f;
  color: white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100%;
  margin-left: 16px;
  box-sizing: border-box;
`;

import { useMemo, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { mdiMessage, mdiClose } from '@mdi/js';

import IconButton from 'Components/IconButton';
import { formatChatDate } from 'Utils/Helpers/dateFnsHelper';
import { springSlide } from 'Utils/animations';
import { useChat } from 'Components/Chat/hooks/useChat';
import { getEntityEmail } from 'Utils/Config/SectorConfig';

import * as Styled from './styled';

type Props = {
  chat: Record<string, any>;
  onClick: (...args: any[]) => any;
};

const Item = ({ chat, onClick }: Props) => {
  const emailCurrentUser = getEntityEmail();
  const { socket, setMessages, handoverStatus, removePreviousChat } = useChat();
  const [isHover, setIsHover] = useState(false);

  const lastMessage = useMemo(() => {
    if (chat?.messages?.length) {
      return chat?.messages[chat?.messages?.length - 1];
    }
  }, [chat?.messages]);

  const myCurrentChat = useMemo(
    () =>
      chat?.original?.chat?.responsibleEmail
        ? chat?.original?.chat?.responsibleEmail === emailCurrentUser
        : true,
    [chat, emailCurrentUser]
  );

  useEffect(() => {
    const watchSocket = async () => {
      if (!socket.connected) {
        socket.open();
      }
      socket.emit('add', chat?.senderId);
      socket.on(`${chat?.senderId}`, (row) => {
        setMessages(chat?.senderId, [
          {
            event: row?.event,
            timestamp: `${row?.timestamp}`,
            text: row?.text,
            handover: handoverStatus(row),
            file: row?.parse_data?.metadata?.file,
          },
        ]);
      });
    };

    watchSocket();

    return () => {
      if (socket.connected) {
        socket.emit('remove', chat?.senderId);
      }
    };
  }, [chat?.senderId, socket, setMessages, handoverStatus]);

  return (
    <Styled.Container onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <div onClick={onClick}>
        <IconButton
          marginHorizontal={0}
          icon={mdiMessage}
          onClick={onClick}
          counter={myCurrentChat ? chat?.original?.chat?.messagesUnreaded : 0}
        />
      </div>
      <Styled.ContentContainer onClick={onClick}>
        <Styled.TextContainer>
          <Styled.Title>{chat?.title}</Styled.Title>
          <Styled.SubTitle>
            {lastMessage?.text ? lastMessage?.text : 'Nenhuma mensagem'}
          </Styled.SubTitle>
        </Styled.TextContainer>
        <Styled.LastInteraction>
          {lastMessage?.timestamp ? formatChatDate(lastMessage?.timestamp) : '-'}
        </Styled.LastInteraction>
      </Styled.ContentContainer>
      <AnimatePresence>
        <Styled.CloseIconContainer
          variants={{
            hovered: {
              opacity: 1,
            },
            hide: {
              opacity: 0,
            },
          }}
          layout
          initial="hide"
          animate={isHover ? 'hovered' : 'hide'}
          transition={springSlide}
        >
          <Styled.Icon
            onClick={() => removePreviousChat(chat?.senderId)}
            path={mdiClose}
            color="#707070"
            size={1.15}
          />
        </Styled.CloseIconContainer>
      </AnimatePresence>
    </Styled.Container>
  );
};

export default Item;

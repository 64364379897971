import styled from 'styled-components';
import IconBase from '@mdi/react';

export const Dropzone = styled.div.attrs(({ height = 100 }) => ({
  height,
}))`
  font-family: 'Roboto', sans-serif;
  display: flex;
  color: #a9a9a9;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e3e3e3;
  border-radius: 5px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  width: 100%;
  height: ${(props) => `${props.height}px`};
  position: relative;
  margin: 1.2rem 0;
  cursor: default;

  p {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }
`;

export const UploadList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #0071eb;
  margin-left: 5px;
  font-weight: 400;
`;

export const Icon = styled(IconBase)`
  margin-right: 30px;
`;

export const File = styled.span`
  margin-right: 28px;
`;

import { useContext } from 'react';

import { formatChatDate } from 'Utils/Helpers/dateFnsHelper';
import ChatContext from 'Components/Chat/context';
import { chatMessage, CHAT_STATUS, CHAT_STATUS_COLOR } from 'Components/Chat/types';
import parseTextToHtml from 'Components/Chat/utils/parser';

import * as Styled from './styled';
import { FileContainer } from '../FileContainer';

const Message = ({ id, text, timestamp, event, file }) => {
  const { color, colorBot, colorUser, colorStatus, expanded } = useContext(ChatContext);

  return (
    <Styled.Container
      id={id}
      event={event}
      color={color}
      expanded={expanded}
      colorBot={colorBot}
      colorUser={colorUser}
      colorStatus={colorStatus}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, transition: { duration: 0.15 } }}
      type={!!file}
    >
      {file ? (
        <FileContainer file={file} expanded={expanded} time={timestamp} />
      ) : (
        <Styled.Text>{parseTextToHtml(text)}</Styled.Text>
      )}
      {event !== 'status' && (
        <Styled.Status>
          <Styled.DateStatus>{formatChatDate(timestamp)}</Styled.DateStatus>
          {!['user'].includes(event) && (
            <Styled.IconStatus
              path={CHAT_STATUS.sended}
              color={CHAT_STATUS_COLOR.sended}
              size={0.6}
            />
          )}
        </Styled.Status>
      )}
    </Styled.Container>
  );
};

Message.propTypes = chatMessage;

export default Message;

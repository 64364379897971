import { activeContactStatusText } from 'Utils/Helpers/StatusHelper';

import * as Styled from './styled';

type Props = {
  collection: Record<string, any>;
};

const ActiveContactColumn = ({ collection }: Props) => {
  return <Styled.Container>{activeContactStatusText(collection)}</Styled.Container>;
};

export default ActiveContactColumn;

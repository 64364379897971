import Container from '../../../../Components/TeleAtendimento/Container';

function BIMessenger() {
  return (
    <Container>
      <iframe
        title="BIMessenger"
        style={{
          width: '100%',
          height: 'calc(100vh - 150px)',
        }}
        src="https://app.powerbi.com/reportEmbed?reportId=5dc322b3-a049-4080-ae94-efb2fb5b9d41&autoAuth=true&ctid=3466de14-692d-41cb-a848-2548e66a50a5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1iLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
        frameBorder="0"
        allowFullScreen="true"
      ></iframe>
    </Container>
  );
}

export default BIMessenger;

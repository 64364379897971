import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Spacing = styled.div`
  height: 12px;
`;

export const Error = styled.span`
  color: #cf0e00;
  font-size: 11px;
  font-family: 'Roboto';
`;

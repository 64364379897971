import { createActions, createReducer, resettableReducer } from 'reduxsauce';

// define as ações
export const { Types: ModalCancelScheduleTypes, Creators: ModalCancelScheduleActions } =
  createActions({
    modalCancelScheduleSetIsOpen: ['isOpen', 'document', 'alertRecord'],
    modalCancelScheduleReset: null,
  });

// define o estado inicial
const INITIAL_STATE = {
  isOpen: false,
  document: null,
  alertRecord: null,
};

/**
 * Atualiza se a modal está aberta ou não
 * @param {{}} state
 * @param {{}} payload
 */
const modalCancelScheduleSetIsOpen = (
  state: Record<string, any>,
  { isOpen, document, alertRecord }: Record<string, any>
) => ({
  ...state,
  isOpen,
  document,
  alertRecord,
});

// define o reducer
const reducer = createReducer(INITIAL_STATE, {
  [ModalCancelScheduleTypes.MODAL_CANCEL_SCHEDULE_SET_IS_OPEN]: modalCancelScheduleSetIsOpen,
});

// torna o reducer atualizado
export default resettableReducer(ModalCancelScheduleTypes.MODAL_CANCEL_SCHEDULE_RESET)(reducer);

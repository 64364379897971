import { createContext, useReducer, useCallback, useContext } from 'react';

export const initialState = {
  showModal: false,
  showTagsModal: false,
  loading: false,
  menuAction: '',
  sector: '',
  serviceStep: null,
  medicalRecord: '',
  status: '',
  reason: '',
  note: '',
  subjectiveNote: '',
  objectiveNote: '',
  assessmentNote: '',
  planNote: '',
  templateName: '',
  complaint: null,
  triageStep: null,
  medicalRecords: [],
  showMessage: null,
  isLoading: false,
  data: {},
  selectedTags: [],
  selectedCids: [],
  selectedCiaps: [],
  keyCidSelect: 1,
  keyCiapSelect: 1000,
  soapFields: {},
  soapEvolution: true,
  soapEvolutionModal: false,
  attendanceType: '',
};

const MoreActionContext = createContext({
  store: { ...initialState },
  dispatch: () => void 0,
  handleServiceStep: () => void 0,
  handleNote: () => void 0,
  handleSubjectiveNote: () => void 0,
  handleObjectiveNote: () => void 0,
  handleAssessmentNote: () => void 0,
  handlePlanNote: () => void 0,
  handleSector: () => void 0,
  handleStatusReason: () => void 0,
  handleReason: () => void 0,
  handleTemplateName: () => void 0,
  handleMedicalRecords: () => void 0,
  handleSelectedTags: () => void 0,
  handleSelectedCids: () => void 0,
  handleComplaint: () => void 0,
  handleTriageStep: () => void 0,
  handleKeyCidSelect: () => void 0,
  handleSelectedCiaps: () => void 0,
  handleKeyCiapSelect: () => void 0,
  handleSoapFields: () => void 0,
  handleSoapEvolution: () => void 0,
  handleSoapEvolutionModal: () => void 0,
  handleAttendanceType: () => void 0,
});

const actionReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return { ...state, showModal: true, medicalRecord: action.medicalRecord };
    case 'HIDE_MODAL':
      return initialState;
    case 'CHANGE_ACTION':
      return { ...state, menuAction: action.menuAction };
    case 'success':
      return {
        ...initialState,
        showMessage: {
          type: 'success',
          content: action.message,
        },
      };
    case 'error':
      return {
        ...state,
        loading: false,
        error: '',
        showMessage: {
          type: 'error',
          content: action.error,
        },
      };
    case 'serviceStep':
      return {
        ...state,
        serviceStep: action.serviceStep,
      };
    case 'sector':
      return {
        ...state,
        sector: action.sector,
      };
    case 'statusReason':
      return {
        ...state,
        status: action.statusReason,
      };
    case 'note':
      return {
        ...state,
        note: action.note,
      };
    case 'subjectiveNote':
      return {
        ...state,
        subjectiveNote: action.subjectiveNote,
      };
    case 'objectiveNote':
      return {
        ...state,
        objectiveNote: action.objectiveNote,
      };
    case 'assessmentNote':
      return {
        ...state,
        assessmentNote: action.assessmentNote,
      };
    case 'planNote':
      return {
        ...state,
        planNote: action.planNote,
      };
    case 'reason':
      return {
        ...state,
        reason: action.reason,
      };
    case 'templateName':
      return {
        ...state,
        templateName: action.templateName,
      };
    case 'complaint':
      return {
        ...state,
        complaint: action.complaint,
        triageStep: null,
      };
    case 'triageStep':
      return {
        ...state,
        triageStep: action.triageStep,
      };
    case 'medicalRecords':
      return {
        ...state,
        medicalRecords: action.medicalRecords,
      };
    case 'selectedCids':
      return {
        ...state,
        selectedCids: action.selectedCids,
      };
    case 'selectedTags':
      return {
        ...state,
        selectedTags: action.selectedTags,
      };
    case 'LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'keyCidSelect':
      return {
        ...state,
        keyCidSelect: action.keyCidSelect,
      };
    case 'soapEvolution':
      return {
        ...state,
        soapEvolution: action.soapEvolution,
      };
    case 'soapFields':
      return {
        ...state,
        soapFields: action.soapFields,
      };
    case 'keyCiapSelect':
      return {
        ...state,
        keyCiapSelect: action.keyCiapSelect,
      };
    case 'selectedCiaps':
      return {
        ...state,
        selectedCiaps: action.selectedCiaps,
      };
    case 'attendanceType':
      return {
        ...state,
        attendanceType: action.attendanceType,
      };
    default:
      return state;
  }
};

type MoreActionsProviderProps = {
  children: React.ReactElement;
};

const MoreActionsProvider = ({ children }: MoreActionsProviderProps) => {
  const [store, dispatch] = useReducer(actionReducer, initialState);

  const handleServiceStep = (serviceStep) => dispatch({ type: 'serviceStep', serviceStep });
  const handleSector = (sector) => dispatch({ type: 'sector', sector });
  const handleNote = (note) => dispatch({ type: 'note', note });
  const handleSubjectiveNote = (subjectiveNote) =>
    dispatch({ type: 'subjectiveNote', subjectiveNote });
  const handleObjectiveNote = (objectiveNote) => dispatch({ type: 'objectiveNote', objectiveNote });
  const handleAssessmentNote = (assessmentNote) =>
    dispatch({ type: 'assessmentNote', assessmentNote });
  const handlePlanNote = (planNote) => dispatch({ type: 'planNote', planNote });
  const handleReason = (reason) => dispatch({ type: 'reason', reason });
  const handleStatusReason = (statusReason) => dispatch({ type: 'statusReason', statusReason });
  const handleTemplateName = (templateName) => dispatch({ type: 'templateName', templateName });
  const handleComplaint = (complaint) => dispatch({ type: 'complaint', complaint });
  const handleTriageStep = (triageStep) => dispatch({ type: 'triageStep', triageStep });
  const handleSelectedCids = (selectedCids) => dispatch({ type: 'selectedCids', selectedCids });
  const handleSelectedTags = (selectedTags) => dispatch({ type: 'selectedTags', selectedTags });
  const handleCidSearch = (cidSearch) => dispatch({ type: 'cidSearch', cidSearch });
  const handleSoapEvolution = (soapEvolution) => dispatch({ type: 'soapEvolution', soapEvolution });
  const handleSoapFields = (soapFields) => dispatch({ type: 'soapFields', soapFields });
  const handleMedicalRecords = useCallback(
    (medicalRecords) => dispatch({ type: 'medicalRecords', medicalRecords }),
    [dispatch]
  );
  const handleKeyCidSelect = (keyCidSelect) => dispatch({ type: 'keyCidSelect', keyCidSelect });
  const handleKeyCiapSelect = (keyCiapSelect) => dispatch({ type: 'keyCiapSelect', keyCiapSelect });
  const handleSelectedCiaps = (selectedCiaps) => dispatch({ type: 'selectedCiaps', selectedCiaps });
  const handleAttendanceType = (attendanceType) =>
    dispatch({ type: 'attendanceType', attendanceType });
  const handleSoapEvolutionModal = (soapEvolutionModal) =>
    dispatch({ type: 'soapEvolutionModal', soapEvolutionModal });

  return (
    <MoreActionContext.Provider
      value={{
        store,
        dispatch,
        handleServiceStep,
        handleNote,
        handleSubjectiveNote,
        handleObjectiveNote,
        handleAssessmentNote,
        handlePlanNote,
        handleSector,
        handleStatusReason,
        handleReason,
        handleTemplateName,
        handleMedicalRecords,
        handleSelectedCids,
        handleSelectedTags,
        handleTriageStep,
        handleComplaint,
        handleCidSearch,
        handleKeyCidSelect,
        handleKeyCiapSelect,
        handleSelectedCiaps,
        handleSoapFields,
        handleSoapEvolution,
        handleSoapEvolutionModal,
        handleAttendanceType,
      }}
    >
      {children}
    </MoreActionContext.Provider>
  );
};

const useMoreActions = () => {
  const context = useContext(MoreActionContext);
  if (context === undefined) {
    throw new Error('useMoreActions must be used within a MoreActionsProvider');
  }

  return context;
};

export { MoreActionsProvider, useMoreActions };

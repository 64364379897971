import styled, { css } from 'styled-components';

export const Container = styled.div.attrs((props) => ({
  width: props.width,
}))`
  display: flex;
  flex-wrap: wrap;

  ${({ width }) => css`
    width: ${width}${typeof width === 'string' ? '' : 'px'};
  `}
`;

export const Tag = styled.div.attrs((props) => ({
  width: props.width,
}))`
  padding: 1px 3px;
  font-family: 'Roboto';
  color: #444444;
  font-size: 11px;
  background: #e3e3e3;
  border-radius: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 3px;

  ${({ width }) => css`
    max-width: ${width}${typeof width === 'string' ? '' : 'px'};
  `}
`;

export const Link = styled(Tag)`
  color: #0071eb;
  text-decoration: underline;
  cursor: pointer;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  margin: 3px 0px;
`;

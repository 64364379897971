import { useContext, useMemo, useState, useCallback, useRef } from 'react';
import { gql } from '@apollo/client';
import { mdiTune } from '@mdi/js';

import Button from 'Components/NewButton';
import ModuleContext from 'Context/moduleContext';
import LocalCache from 'Services/LocalCache';

import Styled from './styled';
import FiltersList from './FiltersList';
import { useGetAllFiltersLazyQuery } from './__generated__/Filters.generated';

export const FiltersQuery = gql`
  query GetAllFilters($entityId: Int!, $module: String!) {
    getAllFilters(entityId: $entityId, module: $module) {
      tags
      ubs
      grupos
      healthCare
      alerts
      status
      flow
    }
  }
`;

export default function Filters() {
  const [showFilters, setShowFilters] = useState(false);
  const { filters, moduleAccess } = useContext(ModuleContext);

  const [getAllFilters, { loading, data }] = useGetAllFiltersLazyQuery({
    fetchPolicy: 'network-only',
  });

  const hasData = useMemo(() => {
    const allFilters = data?.getAllFilters;
    if (!loading && allFilters) {
      return Object.values(allFilters).some((filter) => filter?.length);
    }

    return false;
  }, [data, loading]);

  const closeFilters = useCallback(() => {
    setShowFilters(false);
  }, [setShowFilters]);

  const toggleFilters = () => {
    const entityId: number | null = LocalCache.getUser()?.entityId?.[0];

    if (entityId != null && moduleAccess != null && !showFilters) {
      getAllFilters({
        variables: {
          entityId,
          module: moduleAccess as string,
        },
      });
    }

    setShowFilters((showFilters) => (showFilters = !showFilters));
  };

  // TODO - fix types
  const counter =
    (filters as any).tag.length + (filters as any).ubs.length + (filters as any).group.length;

  return (
    <>
      <Button disabled={loading} onClick={toggleFilters} prefixIcon={mdiTune}>
        Filtros
        {counter > 0 ? <Styled.FilterCounter> {counter} </Styled.FilterCounter> : null}
      </Button>
      <FiltersList
        data={data}
        loading={loading}
        show={showFilters}
        toggleFilters={toggleFilters}
        closeFilters={closeFilters}
      />
    </>
  );
}

import { useContext } from 'react';
import LinkWrapper from '../Wrappers/LinkWrapper';

import {
  RemoteAtendimentoSectors,
  RemoteAtendimentoSectorsHistory,
} from '../../Utils/Config/TeleAtendimentoConfig';
import DataContext from 'Context/dataContext';
import { useMoreActions } from 'Hooks/useMoreActions';

export const Menu = ({ children }) => {
  const { dispatch: actionDispatch } = useMoreActions();
  const { data } = useContext(DataContext);
  return (
    <>
      <div className="row card-container mb-5">
        {RemoteAtendimentoSectors.filter((sector) => sector.type === 1).map((sector) => (
          <div
            className="card-wrapper col-sm-6 col-md-3"
            key={sector.id}
            onClick={() => {
              actionDispatch({ type: 'HIDE_MODAL' });
            }}
          >
            <LinkWrapper className="card-nissan card-body" to={sector.route}>
              <div className="media">
                <div className="mr-3 align-self-center">
                  <i className={`mdi ${sector.icon} icon-3x  mdi`} />
                </div>
                <div className="media-body text-right">
                  <h3 className="dashboard--card-title font-weight-semibold mb-0">
                    {data
                      ? data?.documentsRACount?.[sector.slug] != null
                        ? data?.documentsRACount?.[sector.slug]
                        : '0'
                      : '...'}
                  </h3>
                  <span className="dashboard--card-description text-uppercase font-size-sm text-muted">
                    {sector.module}
                  </span>
                </div>
              </div>
            </LinkWrapper>
          </div>
        ))}
      </div>
      {children}
    </>
  );
};

export const MenuHistory = ({ children }) => {
  const { dispatch: actionDispatch } = useMoreActions();
  const { data } = useContext(DataContext);

  return (
    <>
      <div className="row card-container mb-5">
        {RemoteAtendimentoSectorsHistory.filter((sector) => sector.type === 1).map((sector) => (
          <div
            className="card-wrapper col-sm-6 col-md-3"
            key={sector.id}
            onClick={() => {
              actionDispatch({ type: 'HIDE_MODAL' });
            }}
          >
            <LinkWrapper className="card-nissan card-body" to={sector.route}>
              <div className="media">
                <div className="mr-3 align-self-center">
                  <i className={`mdi ${sector.icon} icon-3x  mdi`} />
                </div>
                <div className="media-body text-right">
                  <h3 className="dashboard--card-title font-weight-semibold mb-0">
                    {data
                      ? data?.documentsRACount?.[sector.slug] != null
                        ? data?.documentsRACount?.[sector.slug]
                        : '0'
                      : '...'}
                  </h3>
                  <span className="dashboard--card-description text-uppercase font-size-sm text-muted">
                    {sector.module}
                  </span>
                </div>
              </div>
            </LinkWrapper>
          </div>
        ))}
      </div>
      {children}
    </>
  );
};

import modal_schedule from './modal_schedule';
import modal_schedule_reminder from './modal_schedule_reminder';
import modal_enter_link from './modal_enter_link';
import modal_resend_link from './modal_resend_link';
import modal_cancel_schedule from './modal_cancel_schedule';
import modules from './modules';
import modal_confirmation from './modal_confirmation';

export default {
  modal_schedule,
  modal_schedule_reminder,
  modal_enter_link,
  modal_resend_link,
  modal_cancel_schedule,
  modules,
  modal_confirmation,
};

import { BadgeWithColor } from 'Components/Badge';
import { ColorDictionary } from 'Utils/Dictionary/ColorDictionary';

type Props = {
  alert: Record<string, any>;
};

const AlertColumn = ({ alert }: Props) => (
  <BadgeWithColor color={alert?.resultado}>
    {ColorDictionary?.Badge[alert?.resultado].name}
  </BadgeWithColor>
);

export default AlertColumn;

import { useContext, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import ModuleContext from 'Context/moduleContext';
import Footer from 'Components/Footer';
import useCache from 'Hooks/useCache';
import useMemed from 'Hooks/useMemed';
import { MORE_ACTIONS } from 'Utils/types';
import router from 'Utils/Helpers/router';
import routes from 'routes';
import { MoreActionsProvider } from 'Hooks/useMoreActions';
import { ChatProvider } from 'Components/Chat/hooks/useChat';
import MemedContext from 'Context/memedContext';
import { PrivateRoute } from 'Components/routes';

import Header from './components/Header';
import * as Styled from './styled';
import { useFlag } from 'Context/unleashContext';

const Default = () => {
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const [memedToken, setMemedToken] = useCache('memed_token', null);
  const { memedActive, memedPatient, setMemedPatient } = useMemed(memedToken);

  const history = useHistory();

  const isPrescriptionEnabled = useFlag('lc-eligible-prescription');

  const activeActions = useMemo(() => {
    const tmpActions = [
      MORE_ACTIONS.MOVE_PACIENT,
      MORE_ACTIONS.REGISTER_EVOLUTION,
      MORE_ACTIONS.ADD_TAG,
    ];

    if (stepActive === 'telemedicine') {
      tmpActions.push(MORE_ACTIONS.SEND_PRESCRIPTION);
    }

    if (stepActive === 'eligible' && isPrescriptionEnabled) {
      tmpActions.push(MORE_ACTIONS.SEND_PRESCRIPTION);
    }

    tmpActions.push(MORE_ACTIONS.SEND_ACTIVE_CONTACT_TEMPLATE);

    return tmpActions;
  }, [stepActive, isPrescriptionEnabled]);

  const handleClickTimeline = (medicalRecord) =>
    history.push(router.to(routes.TIMELINE.path, { medicalRecord }));

  return (
    <ChatProvider
      moduleAccess={moduleAccess}
      onClickTimeline={handleClickTimeline}
      activeActions={activeActions}
    >
      <MemedContext.Provider
        value={{ memedToken, setMemedToken, memedActive, memedPatient, setMemedPatient }}
      >
        <Styled.Wrapper>
          <Header />
          <Styled.Container className="container-fluid">
            <div className="container-internal">
              <div className="row">
                <div className="col-md-12">
                  <Switch>
                    {Object.values(routes)
                      .filter((route) => route.private)
                      .map((route, index) => (
                        <PrivateRoute {...route} key={index} />
                      ))}
                    <Route render={() => <Redirect to={router.to(routes.DEFAULT)} />} />
                  </Switch>
                </div>
              </div>
            </div>
          </Styled.Container>
          <Footer />
        </Styled.Wrapper>
      </MemedContext.Provider>
    </ChatProvider>
  );
};

const RootDefault = () => {
  const [clientRoutes] = useState(
    <MoreActionsProvider>
      <Default />
    </MoreActionsProvider>
  );
  return clientRoutes;
};

export default RootDefault;

import { shape, string } from 'prop-types';

export const MORE_ACTIONS = {
  RESCHEDULE_PATIENT: 'RESCHEDULE_PATIENT',
  CANCEL_SCHEDULE: 'CANCEL_SCHEDULE',
  MOVE_PACIENT: 'MOVE_PACIENT',
  UPDATE_PACIENT: 'UPDATE_PACIENT',
  REGISTER_EVOLUTION: 'REGISTER_EVOLUTION',
  ADD_TAG: 'ADD_TAG',
  SEND_PRESCRIPTION: 'SEND_PRESCRIPTION',
  SEND_ACTIVE_CONTACT_TEMPLATE: 'SEND_ACTIVE_CONTACT_TEMPLATE',
};

export const SYSTEM = {
  WINDOWS: 'WINDOWS',
  MAC: 'MAC',
  LINUX: 'LINUX',
  UNIX: 'UNIX',
  OTHER: 'OTHER',
};

export const sectorType = shape({
  slug: string,
});

export const DATE_FORMAT = {
  date: 'dd/MM/yyyy',
  time: 'HH:mm',
  datetime: 'dd/MM/yyyy - HH:mm',
  datetime_seconds: 'dd/MM/yyyy - HH:mm:ss',
  datetime_short: 'dd/MM/yy - HH:mm',
  datetime_abbreviated: 'dd LLL',
  timestamp: 'T',
};

import { useCallback, useContext, useRef } from 'react';
import Icon from '@mdi/react';
import { mdiCalendarRemove } from '@mdi/js';
import ModuleContext from 'Context/moduleContext';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { gql } from '@apollo/client';
import { useCancelReminderScheduleMutation } from './__generated__/index.generated';

import * as Sentry from '@sentry/react';

import { toast } from 'react-toastify';
import { Toast } from 'Components';
import useCache from 'Hooks/useCache';

import { useDataTable } from 'Components/Datatable/container';

export const cancelReminderScheduleMutation = gql`
  mutation CancelReminderSchedule($cpf: String!, $module: String!, $entityId: Int!, $userId: Int!) {
    cancelReminderSchedule(cpf: $cpf, module: $module, entityId: $entityId, userId: $userId) {
      success
      message
    }
  }
`;

export default function CancelReminderButton({ document }) {
  const [user] = useCache('user');
  const { fetch } = useDataTable();

  const { moduleAccess } = useContext(ModuleContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [cancelReminderSchedule, { loading }] = useCancelReminderScheduleMutation();

  if (document.evolutionRecord.setor !== 'CHAT_ADJOURNED' || document.status !== 'Adiado') {
    return null;
  }

  const cancelReminderScheduleOnClick = useCallback(async () => {
    try {
      const { data } = await cancelReminderSchedule({
        variables: {
          cpf: document?.patient?.cpf,
          module: moduleAccess,
          entityId: parseInt(user.entityId),
          userId: user.userId,
        },
      });

      if (data && !data.cancelReminderSchedule?.success) {
        throw new Error(data.cancelReminderSchedule?.message ?? 'Erro inesperado');
      }

      toast(
        <Toast
          type="success"
          title="Lembrete de atendimento cancelado"
          subtitle={
            <>
              O paciente foi movido para a etapa <b>Triagem</b>.
            </>
          }
        />
      );

      fetch();
      onClose();
    } catch (e) {
      Sentry.captureException(e);
      toast(
        <Toast
          type="error"
          title="Não foi possível cancelar o lembrete de atendimento."
          subtitle={e.message}
        />
      );
    }
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        title="Cancelar adiamento"
        size="sm"
        paddingY="0"
        paddingX="2"
        marginX="1"
        colorScheme="gray"
        onClick={onOpen}
      >
        <Icon path={mdiCalendarRemove} size={0.8} />
      </Button>
      <AlertDialog
        size="lg"
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar adiamento
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja cancelar o adiamento do paciente&nbsp;
              <b>{document.patient.nome}</b>?<br />
              Ele será movido para a etapa <b>Triagem</b>.
            </AlertDialogBody>

            <AlertDialogFooter background="transparent">
              <Button colorScheme="gray" variant="flat" ref={cancelRef} onClick={onClose}>
                Manter o adiamento
              </Button>
              <Button
                colorScheme="red"
                marginLeft="3"
                isLoading={loading}
                onClick={cancelReminderScheduleOnClick}
              >
                Cancelar o adiamento
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

import { gql } from '@apollo/client';

const PrescriptionModel = {
  ATIVE_PRESCRIPTION: gql`
    mutation prescriptionUserUpdate(
      $email: String!
      $enabled: Boolean!
      $crm: String!
      $uf: String!
    ) {
      prescriptionUserUpdate(email: $email, enabled: $enabled, crm: $crm, uf: $uf)
    }
  `,
};

export default PrescriptionModel;

import { fromUnixTime, isValid, isToday, parseISO } from 'date-fns';
import { toDate, format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';

import { DATE_FORMAT } from 'Utils/types';

const defaultOptBr = {
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  locale: ptBR,
};

export function parseBack(datetime) {
  return datetime?.toString().replace('.000Z', '');
}

export const formatChatDate = (timestamp) => {
  const tmpDate = toDate(fromUnixTime(timestamp));

  return format(tmpDate, 'HH:mm', defaultOptBr);
};

export const formatChatDay = (timestamp) => {
  const tmpDate = toDate(fromUnixTime(timestamp));

  return `${format(tmpDate, 'd', defaultOptBr)} de ${format(tmpDate, 'MMM', defaultOptBr)}`;
};

export const formatModalDay = (timestamp) => {
  const tmpDate = toDate(fromUnixTime(timestamp));

  return `${format(tmpDate, 'd/MM/y', defaultOptBr)} - ${format(tmpDate, 'HH:mm', defaultOptBr)}`;
};

export const formatTo = (datetime, mask) => {
  try {
    const tmpDate = toDate(parseISO(parseBack(datetime)));

    if (isValid(tmpDate)) {
      return format(tmpDate, mask, defaultOptBr);
    }

    return '';
  } catch (error) {
    return '';
  }
};

export const formatToTodayShort = (datetime, mask) => {
  try {
    const tmpDate = toDate(new Date(parseBack(datetime)), defaultOptBr);

    if (isValid(tmpDate)) {
      if (isToday(tmpDate)) {
        return format(tmpDate, DATE_FORMAT.time, defaultOptBr);
      }

      return format(tmpDate, mask, defaultOptBr);
    }

    return '';
  } catch (error) {
    return '';
  }
};

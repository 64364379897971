import environment from 'Utils/Dictionary/environment';
import { api } from 'Chat/utils/axios';

const textSend =
  'Podemos ajudar com mais alguma informação? O seu atendimento foi encerrado, mas não se preocupe! O assistente virtual da Nissan continuará ajudando caso queira retornar. Digite *AVALIAR ATENDIMENTO* para classificar como foi seu atendimento. Estamos sempre à disposição. Até logo!';

export const returnHanderOver = (senderId) => {
  (async () => {
    const handover = await api(environment.NISSAN_CHATBOT_API).get(
      `/handover?filter[where][senderId]=${senderId}`
    );

    if (handover.status === 200 && handover.data.length > 0) {
      // eslint-disable-next-line
      for (const [, value] of Object.entries(handover.data)) {
        api(environment.NISSAN_CHATBOT_API)
          .delete(`/handover/${value.id}`)
          .then(() => {
            submit(senderId, textSend);
          });
      }
    }
  })();
};

const submit = (senderId, value) => {
  (async () => {
    if (value && value?.trim().length > 0) {
      await api(environment.NISSAN_RASA).post(
        `/webhooks/whatsapp/webhook?token=${environment.RASA_AUTH}`,
        {
          messages: [
            {
              from: senderId,
              text: {
                body: value,
              },
              timestamp: Date.now(),
              type: 'liveagent',
            },
          ],
        }
      );
    }
  })();
};

const baseStyleIcon = {
  marginEnd: '0.5rem',
  display: 'inline',
  verticalAlign: 'text-bottom',
};

const baseStyle = {
  container: {
    padding: 0,
    paddingStart: 5,
  },
  item: {},
  icon: baseStyleIcon,
};

export const listTheme = {
  baseStyle,
};

import { AuthPageUnleashFallback } from 'Pages/Auth/AuthPage';
import Settings from 'Pages/Settings';
import Users from 'Pages/Settings/Users';
import TelemedicineSettings from 'Pages/Settings/Telemedicine';
import PowerBIDashboard from 'Pages/PowerBI';
import Timeline from 'Pages/shared/Timeline';
import MainPage from 'Pages/Main';
import Patients from 'Pages/Settings/Patients';

const routes = {
  LOGIN: {
    path: '/auth',
    // TODO: replace with AuthPage when lc-enabled-refactored-auth-page flag is enabled in production
    component: AuthPageUnleashFallback,
    private: false,
  },
  SETTINGS: {
    path: '/:module/settings',
    exact: true,
    component: Settings,
    private: true,
  },
  USERS: {
    path: '/:module/settings/users',
    exact: true,
    // FIXME: this component causes a nasty circular dependency, which breaks the tests. We should
    // refactor it to avoid this. But for now, we can wrap it in a dummy component to avoid the
    // circular dependency.
    component: () => <Users />,
    private: true,
  },
  PATIENTS: {
    path: '/:module/settings/patients/:type',
    exact: true,
    component: Patients,
    private: true,
  },
  TELEMEDICINE: {
    path: '/:module/settings/telemedicine',
    exact: true,
    component: TelemedicineSettings,
    private: true,
  },
  POWER_BI: {
    path: '/:module/dashboard/:slug',
    exact: true,
    component: PowerBIDashboard,
    private: true,
  },
  TIMELINE: {
    path: '/:module/timeline/:medicalRecord',
    exact: true,
    component: Timeline,
    private: true,
  },
  SERVICES: {
    path: ['/:module/:services/:sector', '/:module/:sector'],
    exact: false,
    component: MainPage,
    private: true,
  },
  DEFAULT: '/:module/all',
};

export const covid19Routes = {
  DASHBOARD: '/dashboard',
  HISTORY: '/history',
  ACTIVE: '/covid/:sector',
  POWER_BI: '/powerbi/:slug',
  TIMELINE: '/covid/timeline/:medicalRecord',
  DEFAULT: '/covid/all',
  SERVICES: '/covid/:services/:sector',
  SETTINGS: {
    SETTINGS: '/settings',
    PATIENTS: '/settings/patients/:type',
    USERS: '/settings/users',
  },
};

export const telemedicineRoutes = {
  HISTORY: '/history',
  ACTIVE: '/telemedicine/:sector',
  DEFAULT: '/telemedicine/schedule',
  SETTINGS: {
    SETTINGS: '/settings',
    PATIENTS: '/settings/patients/:type',
    USERS: '/settings/users',
    TELEMEDICINE: '/settings/telemedicine',
  },
};

export const generalistRoutes = {
  DASHBOARD: '/dashboard',
  POWER_BI: '/powerbi/:slug',
  HISTORY: '/history',
  ACTIVE: '/generalist/:sector',
  TIMELINE: '/generalist/timeline/:medicalRecord',
  DEFAULT: '/generalist/all',
  SERVICES: '/generalist/:services/:sector',
  SETTINGS: {
    SETTINGS: '/settings',
    USERS: '/settings/users',
    PATIENTS: '/settings/patients/:type',
    GENERALIST: '/settings/generalist',
  },
};

export const teleatendimento = {
  WHATSAPP: '/bi/whatsapp',
  MESSENGER: '/bi/messenger',
  IMPORT: '/other/import',
  HISTORY: '/other/history',
  CONTACT_ACTIVE: '/other/contact',
  OUTSIDE: '/other/outside',
  ACTIVE: '/teleatendimento/:sector',
  DEFAULT: '/teleatendimento/all',
  SERVICES: '/teleatendimento/:services/:sector',
  SETTINGS: {
    SETTINGS: '/settings',
    USERS: '/settings/users',
    TELEATENDIMENTO: '/settings/teleatendimento',
  },
};

export const nissanRoutes = {
  HISTORY: '/history',
  IMPORT: '/nissan/import',
  ACTIVE: '/nissan/:sector',
  DEFAULT: '/nissan/all',
  SERVICES: '/generalist/:services/:sector',
  SETTINGS: {
    SETTINGS: '/settings',
    USERS: '/settings/users',
    NISSAN: '/settings/nissan',
  },
};

export default routes;

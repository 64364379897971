import { cssVar } from '@chakra-ui/theme-tools';

const $size = cssVar('close-button-size');

const baseStyle = (props) => {
  const { colorScheme: c } = props;

  return {
    w: [$size.reference],
    h: [$size.reference],
    lineHeight: '1',
    border: '1px',
    borderRadius: 'md',
    fontWeight: 'medium',
    transitionProperty: 'common',
    transitionDuration: 'normal',

    color: `${c}.500`,
    bg: 'transparent',
    borderColor: 'transparent',
    _hover: {
      bg: `${c}.50`,
      borderColor: `${c}.50`,

      _disabled: {
        color: 'gray.200',
        bg: 'transparent',
        borderColor: 'transparent',
      },
    },

    _active: {
      bg: `${c}.100`,
      borderColor: `${c}.100`,
    },

    _focusVisible: {
      boxShadow: 'outline',
    },

    _disabled: {
      cursor: 'not-allowed',
      boxShadow: 'none',
      color: 'gray.200',
      bg: 'transparent',
      borderColor: 'transparent',
    },
  };
};

const sizes = {
  lg: {
    [$size.variable]: '40px',
    fontSize: '16px',
  },
  md: {
    [$size.variable]: '32px',
    fontSize: '12px',
  },
  sm: {
    [$size.variable]: '24px',
    fontSize: '10px',
  },
};

export const closeButtonTheme = {
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'gray',
  },
};

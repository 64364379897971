import { getColor } from '@chakra-ui/theme-tools';

const baseStyle = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = props;

  return {
    fontSize: 'md',
    marginEnd: 3,
    mb: 1.5,
    fontWeight: 'medium',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    opacity: 1,
    _disabled: {
      opacity: 0.4,
    },
    _invalid: {
      color: getColor(theme, ec ?? 'red.400'),
    },
    _focus: {
      color: getColor(theme, fc ?? 'blue.500'),
    },
  };
};

export const formLabelTheme = {
  baseStyle,
  defaultProps: { colorScheme: 'blue' },
};

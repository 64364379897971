const baseStyle = {
  container: {
    paddingX: 4,
    paddingY: 3,
    borderRadius: 'md',
  },
  title: {
    fontWeight: 'medium',
    lineHeight: 6,
  },
  description: {
    marginTop: 1.5,
    lineHeight: 4,
  },
  icon: {
    color: 'current',
    flexShrink: 0,
    marginEnd: 1.5,
    marginBottom: 'px',
    width: 3,
    height: 5,
  },
  spinner: {
    color: 'current',
    flexShrink: 0,
    marginEnd: 1.5,
    width: 3,
    height: 5,
  },
};

const variantSubtle = (props) => {
  const { colorScheme: c } = props;

  return {
    container: {
      bg: `${c}.50`,
      color: `${c}.500`,
    },
  };
};

const variantSolid = (props) => {
  const { colorScheme: c } = props;

  return {
    container: {
      paddingX: 6,
      paddingY: 8,
      w: 'min(460px, 100%)',
      bg: 'white',
      color: `${c}.500`,
    },

    description: {
      color: 'gray.500',
    },
  };
};

const variants = {
  subtle: variantSubtle,
  solid: variantSolid,
};

export const alertTheme = {
  baseStyle,
  variants,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'blue',
  },
};

const baseStyleContainer = {
  borderTopWidth: '1px',
  borderColor: 'inherit',
  _last: {
    borderBottomWidth: '1px',
  },
};

const baseStyleButton = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
  fontSize: '1rem',
  _focusVisible: {
    boxShadow: 'outline',
  },
  _hover: {
    bg: 'blackAlpha.50',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  px: 4,
  py: 2,
};

const baseStylePanel = {
  pt: 2,
  px: 4,
  pb: 5,
};

const baseStyleIcon = {
  fontSize: '1.25em',
};

const baseStyle = {
  root: {},
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon,
};

export const accordionTheme = { baseStyle, defaultProps: { colorScheme: 'blue' } };

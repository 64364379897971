import { calc, cssVar, mode } from '@chakra-ui/theme-tools';
import typography from '../foundations/typography';
import { inputTheme } from './input';

const $stepperWidth = cssVar('number-input-stepper-width');
const $inputPadding = cssVar('number-input-input-padding');
const inputPaddingValue = calc($stepperWidth).add('0.5rem').toString();

const baseStyleRoot = {
  [$stepperWidth.variable]: '24px',
  [$inputPadding.variable]: inputPaddingValue,
};

const baseStyleField = inputTheme.baseStyle;

const baseStyleStepperGroup = {
  width: [$stepperWidth.reference],
};

const baseStyleStepper = (props) => {
  return {
    borderStart: '1px solid',
    borderStartColor: mode('inherit', 'whiteAlpha.300')(props),
    color: mode('inherit', 'whiteAlpha.800')(props),
    _active: {
      bg: mode('gray.200', 'whiteAlpha.300')(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
};

const baseStyle = (props) => ({
  root: baseStyleRoot,
  field: baseStyleField,
  stepperGroup: baseStyleStepperGroup,
  stepper: baseStyleStepper(props),
});

type FontSize = keyof typeof typography.fontSizes;

function getSize(size: FontSize) {
  const sizeStyle = inputTheme.sizes?.[size];

  const radius: Partial<Record<FontSize, string>> = {
    lg: 'md',
    md: 'md',
    sm: 'sm',
    xs: 'sm',
  };

  const _fontSize = (sizeStyle.field?.fontSize ?? 'md') as FontSize;
  const fontSize = typography.fontSizes[_fontSize];

  return {
    field: {
      ...sizeStyle.field,
      paddingInlineEnd: $inputPadding.reference,
      verticalAlign: 'top',
    },
    stepper: {
      fontSize: calc(fontSize).multiply(0.75).toString(),
      _first: {
        borderTopEndRadius: radius[size],
      },
      _last: {
        borderBottomEndRadius: radius[size],
        mt: '-1px',
        borderTopWidth: 1,
      },
    },
  };
}

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
};

export const numberInputTheme = {
  baseStyle,
  sizes,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps,
};

import * as Styled from './styled';

type StepsNotificationCircleProps = {
  countNotification?: number;
};

const StepsNotificationCircle = ({ countNotification }: StepsNotificationCircleProps) => {
  const variantBadge = {
    active: {
      width: 12,
      height: 12,
      top: -6,
      right: -6,
      opacity: 1,
    },
    normal: {
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      opacity: 0,
    },
  };

  return (
    <Styled.BadgeCounter
      variants={variantBadge}
      animate={Boolean(countNotification) ? 'active' : 'normal'}
    />
  );
};

export default StepsNotificationCircle;

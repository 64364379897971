import styled from 'styled-components';

const ModalChat = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 100000;
`;

const Content = styled.div`
  width: ${(props) => (props.width ? props.width : 424)}px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  transform: translate(0, 0);
  transition: transform 0.3s;
  max-height: 100%;
  padding: 20px 26px 22px 26px;
  z-index: 2000;
  background: #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0px rgba(1, 126, 49, 0.05);
  margin: 0;
  padding: 0;
`;

const Title = styled.h3`
  font-size: 0.85rem;
  color: #7a7a7a;
`;

const Close = styled.span`
  position: absolute;
  right: 20px;
  top: 22px;
  cursor: pointer;
  z-index: 1;
`;

const Styled = {
  ModalChat,
  Content,
  Title,
  Close,
};

export default Styled;

import * as Types from '../../../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelReminderScheduleMutationVariables = Types.Exact<{
  cpf: Types.Scalars['String'];
  module: Types.Scalars['String'];
  entityId: Types.Scalars['Int'];
  userId: Types.Scalars['Int'];
}>;


export type CancelReminderScheduleMutation = { __typename?: 'Mutation', cancelReminderSchedule?: { __typename?: 'DefaultReturn', success?: boolean | null, message?: string | null } | null };


export const CancelReminderScheduleDocument = gql`
    mutation CancelReminderSchedule($cpf: String!, $module: String!, $entityId: Int!, $userId: Int!) {
  cancelReminderSchedule(
    cpf: $cpf
    module: $module
    entityId: $entityId
    userId: $userId
  ) {
    success
    message
  }
}
    `;
export type CancelReminderScheduleMutationFn = Apollo.MutationFunction<CancelReminderScheduleMutation, CancelReminderScheduleMutationVariables>;

/**
 * __useCancelReminderScheduleMutation__
 *
 * To run a mutation, you first call `useCancelReminderScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReminderScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReminderScheduleMutation, { data, loading, error }] = useCancelReminderScheduleMutation({
 *   variables: {
 *      cpf: // value for 'cpf'
 *      module: // value for 'module'
 *      entityId: // value for 'entityId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelReminderScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CancelReminderScheduleMutation, CancelReminderScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelReminderScheduleMutation, CancelReminderScheduleMutationVariables>(CancelReminderScheduleDocument, options);
      }
export type CancelReminderScheduleMutationHookResult = ReturnType<typeof useCancelReminderScheduleMutation>;
export type CancelReminderScheduleMutationResult = Apollo.MutationResult<CancelReminderScheduleMutation>;
export type CancelReminderScheduleMutationOptions = Apollo.BaseMutationOptions<CancelReminderScheduleMutation, CancelReminderScheduleMutationVariables>;
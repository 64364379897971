import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const SubStepTabContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TabWrapper = styled.div`
  margin-right: 13px;
  position: relative;
`;

export const TabText = styled.span`
  text-align: left;
  font: normal normal bold 16px/19px Roboto;
  letter-spacing: 0.32px;
  opacity: 1;
`;

export const TabLink = styled(NavLink)`
  display: block;

  border-radius: 5px 5px 0px 0px;
  padding: 12px 16px;

  cursor: pointer;

  box-shadow: inset 0px -15px 15px -15px rgba(0, 0, 0, 0.05);

  background-color: #ffffff;
  color: #9b9b9b;

  &,
  &:link,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:hover {
    color: #ff881f;
  }

  &.active {
    box-shadow: none;
    color: #444444;

    & > ${TabText} {
      padding-bottom: 8px;
      border-bottom: #ff881f solid 3px;
    }
  }

  & > ${TabText} {
    padding-bottom: 10px;
    border-bottom: transparent solid 3px;
  }
`;

export const Badge = styled.span`
  display: block;
  position: absolute;
  right: -9px;
  top: -9px;
  background: #ff881f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 9px;
  color: #fff;
  font-size: 12px;
`;

import { toDate, format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';

const defaultOptBr = {
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  locale: ptBR,
};

export default {
  formatTo: (datetime, mask) => {
    try {
      const tmpDate = toDate(datetime);
      return format(tmpDate, mask, defaultOptBr);
    } catch (error) {
      console.log(error);
      return '';
    }
  },
};

import { useCallback } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { MailIcon } from 'theme/icons';
import { Form, FormSupportingText, Span } from 'theme/ui';
import { rules } from 'Utils/Helpers/Validator';
import { useHistory } from 'react-router-dom';
import { useUpdateUserPasswordMutation } from './__generated__/ForgotPassword.generated';
import { gql } from '@apollo/client';

type ForgotPasswordFormValues = {
  email: string;
};

export const updateUserPasswordMutation = gql`
  mutation updateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      success
      error
    }
  }
`;

export const ForgotPassword = () => {
  const toast = useToast();
  const history = useHistory();

  const {
    handleSubmit,
    register,
    errors,
    getValues,
    formState: { isSubmitting },
  } = useForm<ForgotPasswordFormValues>({ mode: 'onBlur' });

  const toastError = useCallback(() => {
    toast({
      status: 'error',
      title: 'Erro',
      description: 'Não foi possível enviar o e-mail no momento.',
      position: 'top',
      isClosable: true,
    });
  }, [toast]);

  const [updateUser, { loading }] = useUpdateUserPasswordMutation({
    onCompleted: ({ updateUserPassword }) => {
      if (updateUserPassword?.success && !updateUserPassword?.error) {
        const email = getValues('email');
        history.push(`email-sended?email=${encodeURIComponent(email)}`);
        return;
      }

      toastError();
    },
    onError: toastError,
  });

  const onSubmit = useCallback(({ email }: ForgotPasswordFormValues) => {
    updateUser({ variables: { input: { email } } });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="2" marginBottom="6">
        <MailIcon boxSize="10" color="orange.500" />
        <Heading size="lg" fontWeight="light" textAlign="center">
          Recuperação de <Span color="orange.500">senha</Span>
        </Heading>
        <Text fontSize="md" textAlign="center" color="gray.300">
          Enviaremos um email com instruções para redefinir a sua senha
        </Text>
      </VStack>
      <FormControl id="email" isInvalid={!!errors.email} marginBottom="8">
        <FormLabel>E-mail</FormLabel>
        <Input
          type="email"
          name="email"
          ref={register({
            required: { value: true, message: 'O E-mail é obrigatório' },
            pattern: { value: rules.email, message: 'Deve ser um e-mail válido' },
          })}
        />
        <FormSupportingText errorText={errors.email?.message} />
      </FormControl>
      <Button width="full" type="submit" isLoading={isSubmitting || loading}>
        Enviar
      </Button>
    </Form>
  );
};

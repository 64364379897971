import { useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import RSelect from 'Components/RSelect';
import ModuleContext from 'Context/moduleContext';
import LocalCache from 'Services/LocalCache';
import { useDispatch } from 'react-redux';
import { ModulesActions } from 'store/ducks/modules';

const ModuleSelect = () => {
  const { moduleAccess } = useContext(ModuleContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleModuleChange = ({ value: moduleKey }) => {
    const parts = location.pathname.split('/');
    parts[1] = moduleKey;

    dispatch(ModulesActions.modulesResetFilters());

    history.push(parts.join('/'));
  };

  const modules = useMemo(
    () =>
      LocalCache.getModules()
        ?.filter((module) => module?.active)
        ?.map(({ name, slug }) => ({
          value: slug,
          label: name,
        })),
    []
  );

  const value = useMemo(
    () => modules?.find((m) => m.value === moduleAccess),
    [modules, moduleAccess]
  );

  return (
    <RSelect
      label="Linha de cuidado"
      width="142px"
      fontSize={20}
      options={modules}
      onChange={handleModuleChange}
      value={value}
      isSearchable={false}
      fontWeight={700}
    />
  );
};

export default ModuleSelect;

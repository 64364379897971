export const personalDataLabels = {
  age: 'Idade',
  bairro: 'Bairro',
  cidade: 'Cidade',
  cpf: 'CPF',
  healthCare: 'Convênio',
  healthCard: 'Número da carteirinha',
  email: 'Email',
  estado: 'Estado',
  height: 'Altura',
  rgh: 'RGH',
  telefone: 'Telefone',
  cellphone: 'Telefone',
  ubs: 'UBS de Origem',
  weight: 'Peso',
  gender: 'Gênero',
  city: 'Cidade',
  dataNascimento: 'Data de nascimento',
  bond: 'Vínculo',
  cep: 'CEP',
  imc: 'IMC',
};

import { useContext, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import Modal from 'Components/Modal';
import Select from 'Components/NewSelect';
import { getEntityId } from 'Utils/Config/SectorConfig';
import TagsModel, { UPDATE_PATIENT_TAGS } from 'Models/TagsModel';
import Button from 'Components/Button';
import LocalCache from 'Services/LocalCache';
import ModuleContext from 'Context/moduleContext';
import { useMoreActions } from 'Hooks/useMoreActions';

import { Styled } from './styled';

export default function TagsModal({ showTagsModal, setShowTagsModal, alertRecord, callBack }) {
  const { moduleAccess } = useContext(ModuleContext);

  const [options, setOptions] = useState([]);

  const {
    handleSelectedTags,
    store: { selectedTags },
  } = useMoreActions();

  const [fetchTags, { data: tagsList, refetch }] = useLazyQuery(
    TagsModel.GET_ALL_TAGS(getEntityId()),
    {
      fetchPolicy: 'network-only',
    }
  );

  const [updatePatientTags, { loading: submitLoading }] = useMutation(UPDATE_PATIENT_TAGS);

  const handleChange = (newValue) => {
    handleSelectedTags(newValue);
  };

  const submit = async () => {
    const arrToSubmit = [];

    selectedTags.forEach((tag) => {
      arrToSubmit.push(tag.value);
    });

    const data = {
      entityId: getEntityId()[0],
      userId: LocalCache.getUser().userId,
      medicalRecord: alertRecord.medicalRecord,
      tags: arrToSubmit,
      module: moduleAccess,
      usuario: LocalCache.getUser()?.name,
      email: LocalCache.getUser().email,
      attendantCpf: LocalCache.getUser().cpf,
    };

    await updatePatientTags({
      variables: {
        data: JSON.stringify(data),
      },
    });

    callBack && callBack();
    setShowTagsModal(false);
  };

  useEffect(() => {
    if (showTagsModal) {
      if (refetch) {
        refetch();
      } else {
        fetchTags();
      }
    }
  }, [fetchTags, showTagsModal, refetch]);

  useEffect(() => {
    const aux = [];
    if (alertRecord?.tags) {
      alertRecord.tags.forEach((tag) => {
        aux.push({
          value: tag,
          label: tag,
        });
      });
    }
    if (showTagsModal === true) {
      handleSelectedTags(aux);
    }
    // eslint-disable-next-line
  }, [alertRecord, showTagsModal]);

  useEffect(() => {
    if (tagsList) {
      const aux = [];
      tagsList?.getAllTags?.forEach((item) => {
        aux.push({
          value: item,
          label: item,
        });
      });
      setOptions(aux);
    }
  }, [tagsList]);

  return (
    <Modal isOpen={showTagsModal} onClose={() => setShowTagsModal(false)}>
      <Styled.Container>
        <Styled.Title>Adicionar ou criar uma tag</Styled.Title>
        <Styled.Subtitle>Paciente: {alertRecord?.patient?.nome}</Styled.Subtitle>

        <Styled.Label>Tags</Styled.Label>
        <Select
          isMulti
          isClearable
          placeholder="Selecione ou crie uma nova tag"
          createLabel="Adicionar tag"
          noOptionsMessage="Nenhuma tag encontrada"
          creatable
          isSearchable
          options={options}
          onChange={handleChange}
          value={selectedTags}
          maxLength={30}
        />
        <Styled.ButtonsContainer>
          <Button
            modifiers={['btn', 'fullWidth', 'bgPrimary', 'textWhite']}
            onClick={submit}
            style={{ marginTop: '15px' }}
            disabled={submitLoading}
          >
            CONFIRMAR
          </Button>
          <Button
            modifiers={['btn', 'fullWidth', 'bgTransparent', 'textPrimary', 'marginTop20']}
            onClick={() => setShowTagsModal(false)}
            style={{ marginTop: '15px' }}
          >
            CANCELAR
          </Button>
        </Styled.ButtonsContainer>
      </Styled.Container>
    </Modal>
  );
}

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs((props) => ({
  height: props.height,
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 16px;
  scroll-behavior: smooth;

  ${({ height }) => css`
    height: ${height}px;
  `}

  ::-webkit-scrollbar {
    width: 0.4em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background: #ccc;
  }
`;

export const Loading = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoMessages = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #a9a9a9;
`;

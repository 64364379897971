import { useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useParams } from 'react-router-dom';

import ModuleContext from 'Context/moduleContext';
import DashboardModel, { SUBSCRIBE_DATATABLE_DOCUMENTS } from 'Models/DashboardModel';
import { getEntityId } from 'Utils/Config/SectorConfig';

import { isTownHall } from 'Utils/Config/SectorConfig';

import { useChat } from 'Components/Chat/hooks/useChat';
import { ChatButton, MoreActions, VideoButton } from 'Components/Datatable/components';
import MovePatientButton from 'Components/Datatable/components/MovePatientButton';
import { alertGroupsKeys } from 'Utils/Dictionary/AlertDictionary';
import { personalDataLabels } from 'Utils/Dictionary/personalInputs';
import { formatTo } from 'Utils/Helpers/dateFnsHelper';
import { DATE_FORMAT, MORE_ACTIONS } from 'Utils/types';

import { MonitoringDictionary } from 'Utils/Dictionary/MonitoringDictionary';

import { getMonitoringDays } from 'Utils/Helpers/MonitoringHelper';

import {
  TimelineAlert,
  TimelineEvolution,
  TimelineMonitoring,
  TimelineMonitoringOnHold,
  TimelineMonitoringStatus,
  TimelineNote,
  TimelinePatient,
  TimelineSoapEvolution,
  TimelineTags,
  TimelineWhatsapp,
} from './components';

import ModalCancelSchedule from 'Components/ModalCancelSchedule';
import TimelineHeadComponents from './components/TimelineHeadComponents';
import * as Styled from './styled';

import DownBlueArrow from 'Assets/icons/down-blue-arrow-dark.svg?url';
import DownBlueArrowDark from 'Assets/icons/down-blue-arrow-white.svg?url';
import LeftBlueArrow from 'Assets/icons/left-blue-arrow.svg?url';
import RightBlackArrow from 'Assets/icons/right-black-arrow.svg?url';
import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';
import { Document } from 'data/types';

const isLegacy = false;

const timelineFilter = (timelineItem, moduleAccess) =>
  (timelineItem.alert && isLegacy) ||
  timelineItem.symptoms ||
  timelineItem.vitalSigns ||
  timelineItem.monitoring ||
  timelineItem.evolutionNote ||
  timelineItem.evolutionSoap ||
  timelineItem.paciente ||
  timelineItem.tags ||
  (timelineItem.evolutionRecord && timelineItem.evolutionRecord.setor) ||
  (['generalist', 'covid'].includes(moduleAccess) && timelineItem.type === 'whatsapp');

type userDataProps = {
  document?: Document;
};

export function TimelinePagination() {
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const alertTypes = ['custom', 'grey', 'blue', 'green', 'yellow', 'red'];

  const monitoringTypes = ['started', 'on_hold', 'resumed'];
  const monitoringReduce = ['started', 'resumed'];
  const monitoringOnHold = ['on_hold'];

  const [shownTimelineData, setShownTimelineData] = useState({});
  const [userData, handleUserData] = useState<userDataProps>({});
  const history = useHistory();
  const { medicalRecord } = useParams<{ medicalRecord: string }>();
  const { updateOriginalDataChat } = useChat();

  const userDocument = userData?.document;

  const monitoring = userDocument?.monitoring;
  const complaint = monitoring?.complaint;
  const monitoringDays = getMonitoringDays({ monitoring, complaint, moduleAccess });

  const displayMovePatientButton = useFlag(`lc-move-patient-button-${environment.UNLEASH_APP_ENV}`);
  const filterFlag = useFlag(`dc-new-filter-props-${environment.UNLEASH_APP_ENV}`);

  const toggleTimelineData = (id) => {
    setShownTimelineData((prevTimelineData) => ({
      ...prevTimelineData,
      [id]: !prevTimelineData[id],
    }));
  };

  const {
    loading: loadingUserData,
    refetch,
    subscribeToMore,
    data: fetchedUserData,
  } = useQuery(DashboardModel.GET_DOCUMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      medicalRecord,
      module: moduleAccess,
      entityId: getEntityId(),
    },
    onCompleted: (data) => {
      handleUserData(data);
    },
  });

  useEffect(() => {
    handleUserData(fetchedUserData);
  }, [fetchedUserData]);

  const {
    data: historyData,
    loading: loadingHistoryData,
    refetch: refetchHistory,
  } = useQuery(DashboardModel.GET_DOCUMENT_HISTORY(medicalRecord, getEntityId(), moduleAccess), {
    fetchPolicy: 'no-cache',
  });

  const subscribe = useCallback(() => {
    return typeof subscribeToMore === 'function'
      ? subscribeToMore({
          document: SUBSCRIBE_DATATABLE_DOCUMENTS(filterFlag),
          variables: { entityId: getEntityId()[0], module: moduleAccess },
          updateQuery: (prev, { subscriptionData }) => {
            if (
              !subscriptionData.data ||
              subscriptionData?.data.datatableDocuments?.medicalRecord !==
                userDocument?.medicalRecord
            )
              return prev;

            updateOriginalDataChat(subscriptionData.data.datatableDocuments);
            handleUserData({ document: subscriptionData.data.datatableDocuments });
          },
        })
      : undefined;
  }, [subscribeToMore, updateOriginalDataChat, userData, moduleAccess]);

  useEffect(() => {
    const currentSubscribe = subscribe();

    return () => {
      if (currentSubscribe) {
        currentSubscribe();
      }
    };
  }, [subscribe]);

  const getLastAlertResolved = (timelineItem) => {
    const lastAlert = alertTypes.reduce(
      (previousAlert, alert) =>
        timelineItem.symptoms[alert] && timelineItem.symptoms[alert].length > 0
          ? alert
          : previousAlert,
      ''
    );

    return <Styled.Timeline.Alert>{lastAlert}</Styled.Timeline.Alert>;
  };

  const timeline = historyData
    ? historyData?.history
        .concat(historyData?.userHealthStatusWhatsapp)
        .map((item) => ({
          ...item,
          datetime: formatTo(item.date, DATE_FORMAT.timestamp),
          type: item.healthStatus ? 'whatsapp' : 'history',
        }))
        .sort((a, b) => b.datetime - a.datetime)
    : [];

  const timelineItemsCount = timeline.filter((timelineItem) =>
    timelineFilter(timelineItem, moduleAccess)
  ).length;

  let answerCount = timeline.filter((timelineItem) => timelineItem.symptoms).length + 1;
  let answerWhatsCount =
    timeline.filter((timelineItem) => timelineItem.type === 'whatsapp').length + 1;

  const getOptIn = (optin) => {
    return MonitoringDictionary?.[optin]?.value || '';
  };

  const getStatus = (status) => {
    const term = status?.toLowerCase();
    if (!term) {
      return '';
    }

    if (term === ('blocked' || 'paused')) {
      return <span>BLOQUEADO</span>;
    }

    return MonitoringDictionary?.[status]?.value;
  };

  return (
    <Styled.Container>
      <Styled.Breadcrumb className="text-facelift-primary">
        {loadingUserData ? (
          <Skeleton height={40} width={500} style={{ marginLeft: 8 }} />
        ) : (
          <>
            <div onClick={() => history.goBack()} className="pointer">
              <img className="left-arrow" src={LeftBlueArrow} alt="left" />
              <span>Lista de pacientes</span>
            </div>
            <div className="breadcrumb-separator">
              <img src={RightBlackArrow} alt="right" />
            </div>
            <div className="breadcrumb-item">Timeline</div>
            <div className="breadcrumb-separator">
              <img src={RightBlackArrow} alt="right" />
            </div>
            <div className="patient-name">{userDocument?.patient?.nome}</div>
          </>
        )}
      </Styled.Breadcrumb>

      <div className="grid">
        <Styled.LeftContainer>
          <Styled.PatientInfoContainer>
            <div className="subcontainer">
              <div className="patient-name">
                {loadingUserData ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  userDocument?.patient?.nome
                )}
              </div>
              {userDocument?.patient
                ? Object.keys(userDocument?.patient).map((patientInfoKey) => {
                    if (
                      userDocument?.patient?.[patientInfoKey] &&
                      patientInfoKey !== 'nome' &&
                      patientInfoKey !== '__typename'
                    ) {
                      return (
                        <div key={patientInfoKey}>
                          {loadingUserData ? (
                            <Skeleton width={200} height={18} />
                          ) : (
                            <>
                              <label>{personalDataLabels[patientInfoKey]}:</label>{' '}
                              {userDocument?.patient[patientInfoKey]
                                ? patientInfoKey !== 'dataNascimento'
                                  ? userDocument?.patient[patientInfoKey]
                                  : formatTo(
                                      userDocument?.patient[patientInfoKey],
                                      DATE_FORMAT.date
                                    )
                                : ' Não informado '}
                            </>
                          )}
                        </div>
                      );
                    }

                    return null;
                  })
                : false}
              <div>
                {loadingUserData ? (
                  <Skeleton width={250} height={18} />
                ) : (
                  <>
                    <label>Início dos sintomas:</label>{' '}
                    {userDocument?.dateSymptomsStart
                      ? userDocument?.dateSymptomsStart
                      : ' Não informado '}
                  </>
                )}
              </div>
              {userDocument?.origin && (
                <>
                  <div>
                    {loadingUserData ? (
                      <Skeleton width={250} height={18} />
                    ) : (
                      <>
                        <label>Entrada:</label>{' '}
                        {userDocument?.origin?.entrada
                          ? userDocument?.origin?.entrada
                          : ' Não informado '}
                      </>
                    )}
                  </div>
                  <div>
                    {loadingUserData ? (
                      <Skeleton width={250} height={18} />
                    ) : (
                      <>
                        <label>Usuário responsável:</label>{' '}
                        {userDocument?.origin?.usuario
                          ? userDocument?.origin?.usuario
                          : ' Não aplicável '}
                      </>
                    )}
                  </div>
                  <div>
                    {loadingUserData ? (
                      <Skeleton width={250} height={18} />
                    ) : (
                      <>
                        <label>Email do usuário:</label>{' '}
                        {userDocument?.origin?.email
                          ? userDocument?.origin?.email
                          : ' Não aplicável '}
                      </>
                    )}
                  </div>
                </>
              )}
              <div>
                {loadingUserData ? (
                  <Skeleton width={275} height={18} />
                ) : (
                  <>
                    <label>Última interação:</label>{' '}
                    {userDocument?.alert?.dataAlerta
                      ? formatTo(userDocument?.alert?.dataAlerta, DATE_FORMAT.datetime)
                      : ' Não informado '}{' '}
                  </>
                )}
              </div>
              <div>
                {loadingUserData ? (
                  <Skeleton width={130} height={18} />
                ) : (
                  <>
                    <label>Acompanhamento aceito:</label>
                    {''}
                    {getOptIn(userDocument?.optin) ? (
                      <Styled.Timeline.Badge>{getOptIn(userDocument?.optin)}</Styled.Timeline.Badge>
                    ) : (
                      <></>
                    )}{' '}
                  </>
                )}
              </div>
              <div>
                {loadingUserData ? (
                  <Skeleton width={130} height={18} />
                ) : (
                  <>
                    <label>Status:</label>
                    {''}
                    {getStatus(userDocument?.monitoring?.statusDescription) ? (
                      <Styled.Timeline.Badge>
                        {getStatus(userDocument?.monitoring?.statusDescription)}
                      </Styled.Timeline.Badge>
                    ) : (
                      <></>
                    )}{' '}
                  </>
                )}
              </div>
              <div>
                {loadingUserData ? (
                  <Skeleton width={275} height={18} />
                ) : (
                  <>
                    <label>Fluxo:</label> {userDocument?.alert?.dataAlerta ? '' : ' Não informado '}{' '}
                  </>
                )}
              </div>
              <div>
                {loadingUserData ? (
                  <Skeleton width={220} height={18} count={5} />
                ) : (
                  <>
                    <label>
                      {userDocument?.monitoring?.type === 'period' ? 'Dias' : 'Etapa'} de
                      acompanhamento:
                    </label>
                    {userDocument?.monitoring?.nEnvios
                      ? ` ${monitoringDays.currentDay}/${monitoringDays.maximumDay}`
                      : null}
                  </>
                )}
              </div>
              <div>
                {loadingUserData ? (
                  <Skeleton width={220} height={18} count={5} />
                ) : (
                  <>
                    <label>Grupos:</label>{' '}
                    <div>
                      {userDocument?.symptoms?.grey?.length
                        ? alertGroupsKeys(userDocument?.symptoms?.grey)
                        : ' - '}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Styled.PatientInfoContainer>
          <Styled.Actions>
            {loadingUserData ? (
              <>
                <Skeleton width={40} height={30} />
                <Styled.ActionSpacing />
                <Skeleton width={40} height={30} />
                <Styled.ActionSpacing />
                <Skeleton width={40} height={30} />
              </>
            ) : (
              <>
                <MoreActions
                  alertRecord={userData?.document}
                  evolutionNote={userDocument?.evolutionNote}
                  medicalRecord={userDocument?.medicalRecord}
                  onFinishModal={() => {
                    refetchHistory();
                    refetch();
                  }}
                  updateOriginalDataChat={(original) =>
                    handleUserData({ ...userData, document: original })
                  }
                  activeActions={[
                    ...(stepActive === 'telemedicine'
                      ? [MORE_ACTIONS.RESCHEDULE_PATIENT, MORE_ACTIONS.CANCEL_SCHEDULE]
                      : []),
                    ...(userDocument?.evolutionRecord?.serviceStep !== 'eligible'
                      ? [MORE_ACTIONS.MOVE_PACIENT]
                      : []),
                    MORE_ACTIONS.REGISTER_EVOLUTION,
                    MORE_ACTIONS.ADD_TAG,
                    ...(userDocument?.evolutionRecord?.serviceStep === 'telemedicine'
                      ? [MORE_ACTIONS.SEND_PRESCRIPTION]
                      : []),
                    MORE_ACTIONS.SEND_ACTIVE_CONTACT_TEMPLATE,
                    MORE_ACTIONS.UPDATE_PACIENT,
                  ]}
                />
                {stepActive !== 'eligible' ? <Styled.ActionSpacing /> : null}
                {displayMovePatientButton ? (
                  <MovePatientButton
                    loadMenu={() => {
                      refetchHistory();
                      refetch();
                    }}
                    medicalRecord={userData?.document?.medicalRecord}
                    alertRecord={userData?.document}
                    stepActive={stepActive ?? ''}
                  />
                ) : null}
                <Styled.ActionSpacing />
                {userDocument?.evolutionRecord?.serviceStep === 'telemedicine' && (
                  <>
                    <VideoButton document={userData?.document} afterSubmit={() => refetch()} />
                    <Styled.ActionSpacing />
                  </>
                )}
                <ChatButton record={userData?.document} />
              </>
            )}
          </Styled.Actions>
        </Styled.LeftContainer>
        <Styled.TimelineContainer>
          <Styled.Timeline.Container>
            {loadingHistoryData ? (
              <Skeleton width="50rem" height={40} style={{ marginBottom: 30 }} count={5} />
            ) : (
              <Styled.Timeline>
                {timeline
                  .filter((timelineItem) => timelineFilter(timelineItem, moduleAccess))
                  .map((timelineItem, idx) => {
                    if (timelineItem.symptoms && timelineItem.type === 'history') {
                      answerCount--;
                    } else if (timelineItem.type === 'whatsapp') {
                      answerWhatsCount--;
                    }
                    return (
                      <div className="timeline-row" key={idx}>
                        <Styled.Timeline.LeftDate datetime={timelineItem.date} />
                        <TimelineHeadComponents
                          idx={idx}
                          moduleAccess={moduleAccess}
                          medicalRecord={medicalRecord}
                          timelineItem={timelineItem}
                          answerWhatsCount={answerWhatsCount}
                          answerCount={answerCount}
                          shownTimelineData={shownTimelineData}
                          DownBlueArrowDark={DownBlueArrowDark}
                          DownBlueArrow={DownBlueArrow}
                          toggleTimelineData={toggleTimelineData}
                          isLegacy={isLegacy}
                          getLastAlertResolved={getLastAlertResolved}
                          monitoring={monitoringTypes}
                          isTownHall={isTownHall}
                          monitoringDays={monitoringDays}
                          MonitoringDictionary={MonitoringDictionary}
                          TimelineAlert={TimelineAlert}
                          timelineItemsCount={timelineItemsCount}
                          TimelineNote={TimelineNote}
                          TimelineSoapEvolution={TimelineSoapEvolution}
                          TimelineMonitoring={TimelineMonitoring}
                          monitoringReduce={monitoringReduce}
                          TimelineMonitoringStatus={TimelineMonitoringStatus}
                          monitoringOnHold={monitoringOnHold}
                          TimelineMonitoringOnHold={TimelineMonitoringOnHold}
                          TimelineEvolution={TimelineEvolution}
                          TimelinePatient={TimelinePatient}
                          TimelineTags={TimelineTags}
                          TimelineWhatsapp={TimelineWhatsapp}
                        />
                      </div>
                    );
                  })}
              </Styled.Timeline>
            )}
          </Styled.Timeline.Container>
        </Styled.TimelineContainer>
      </div>
      <ModalCancelSchedule />
    </Styled.Container>
  );
}

export default TimelinePagination;

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs((props) => ({
  hide: props.hide || false,
}))`
  height: 43px;
  width: 96px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 8px 20px;
  background: #0071eb;
  box-shadow: 0px 3px 15px #0909091a;
  border-radius: 5px;
  z-index: -1;
  position: absolute;
  cursor: pointer;

  ${({ hide }) => css`
    top: ${hide ? 1 : -36}px;
    left: ${hide ? 1 : 0};
  `}

  &:hover {
    filter: brightness(0.98);
  }
`;

export const Text = styled.span`
  color: #fff;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
`;

import * as Types from '../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetReminderScheduleMutationVariables = Types.Exact<{
  type?: Types.InputMaybe<Types.Scalars['String']>;
  slotInitialTime: Types.Scalars['String'];
  slotEndTime: Types.Scalars['String'];
  cpf: Types.Scalars['String'];
  entityId: Types.Scalars['Int'];
  module: Types.Scalars['String'];
  userId: Types.Scalars['Int'];
  tags: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SetReminderScheduleMutation = { __typename?: 'Mutation', setReminderSchedule?: { __typename?: 'DefaultReturn', success?: boolean | null, message?: string | null } | null };


export const SetReminderScheduleDocument = gql`
    mutation setReminderSchedule($type: String, $slotInitialTime: String!, $slotEndTime: String!, $cpf: String!, $entityId: Int!, $module: String!, $userId: Int!, $tags: [String]!) {
  setReminderSchedule(
    type: $type
    slotInitialTime: $slotInitialTime
    slotEndTime: $slotEndTime
    cpf: $cpf
    entityId: $entityId
    module: $module
    userId: $userId
    tags: $tags
  ) {
    success
    message
  }
}
    `;
export type SetReminderScheduleMutationFn = Apollo.MutationFunction<SetReminderScheduleMutation, SetReminderScheduleMutationVariables>;

/**
 * __useSetReminderScheduleMutation__
 *
 * To run a mutation, you first call `useSetReminderScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReminderScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReminderScheduleMutation, { data, loading, error }] = useSetReminderScheduleMutation({
 *   variables: {
 *      type: // value for 'type'
 *      slotInitialTime: // value for 'slotInitialTime'
 *      slotEndTime: // value for 'slotEndTime'
 *      cpf: // value for 'cpf'
 *      entityId: // value for 'entityId'
 *      module: // value for 'module'
 *      userId: // value for 'userId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useSetReminderScheduleMutation(baseOptions?: Apollo.MutationHookOptions<SetReminderScheduleMutation, SetReminderScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReminderScheduleMutation, SetReminderScheduleMutationVariables>(SetReminderScheduleDocument, options);
      }
export type SetReminderScheduleMutationHookResult = ReturnType<typeof useSetReminderScheduleMutation>;
export type SetReminderScheduleMutationResult = Apollo.MutationResult<SetReminderScheduleMutation>;
export type SetReminderScheduleMutationOptions = Apollo.BaseMutationOptions<SetReminderScheduleMutation, SetReminderScheduleMutationVariables>;
import DefaultContainer from 'Components/Container';
import Footer from 'Components/Footer';

import Header from './Header';

const Container = ({ children }) => (
  <DefaultContainer footer={Footer} header={Header}>
    {children}
  </DefaultContainer>
);

export default Container;

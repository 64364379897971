import { useEffect, PropsWithChildren } from 'react';

import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Alert,
  HStack,
  AlertIcon,
  AlertTitle,
  UnorderedList,
  Button,
  ListItem,
  Collapse,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { Form, FormSupportingText } from 'theme/ui';

export type PasswordFormValues = {
  password: string;
  confirmPassword: string;
};

type PasswordFormProps = {
  isDisabled: boolean;
  onSubmit: (values: PasswordFormValues) => void;
};

export const PasswordForm = ({ isDisabled, onSubmit }: PasswordFormProps) => {
  const {
    handleSubmit,
    register,
    errors,
    watch,
    formState: { isSubmitting, touched, dirtyFields },
    triggerValidation,
  } = useForm<PasswordFormValues>({ mode: 'onChange', validateCriteriaMode: 'all' });

  useEffect(() => {
    if (watch('confirmPassword')) {
      triggerValidation('confirmPassword');
    }
  }, [watch('password')]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <VStack spacing="1" marginBottom="2">
        <FormControl id="password" isInvalid={!!errors.password} isDisabled={isDisabled}>
          <FormLabel>Nova senha</FormLabel>
          <Input
            type="password"
            name="password"
            autoComplete="new-password"
            ref={register({
              required: true,
              validate: {
                minLength: (value: string) => value.length >= 8,
                uppercaseLowercase: (value: string) => /[a-z]/.test(value) && /[A-Z]/.test(value),
                specialCharacter: (value: string) => /[$#@%*-+_!^&.,?]/.test(value),
                digits: (value: string) => /\d/.test(value),
              },
            })}
          />
          <FormSupportingText />
        </FormControl>
        <FormControl
          id="confirmPassword"
          isInvalid={!!errors.confirmPassword}
          isDisabled={isDisabled}
        >
          <FormLabel>Repita a senha</FormLabel>
          <Input
            type="password"
            name="confirmPassword"
            autoComplete="new-password"
            ref={register({
              required: 'A confirmação de senha é obrigatória',
              validate: {
                passwordMatch: (value: string) =>
                  value === watch('password') ? undefined : 'Confirmação de senha incorreta',
              },
            })}
          />
          <FormSupportingText errorText={errors.confirmPassword?.message} />
        </FormControl>
      </VStack>
      <Collapse in={!isDisabled}>
        <Alert status="info" colorScheme="gray">
          <VStack spacing="2" align="start">
            <HStack spacing="0">
              <AlertIcon />
              <AlertTitle>A sua senha deve conter:</AlertTitle>
            </HStack>
            <UnorderedList>
              <ListItemCheck
                isDirty={dirtyFields.has('password')}
                hasError={!!errors.password?.types?.minLength}
                wasTouched={!!touched.password}
              >
                No mínimo 8 caracteres
              </ListItemCheck>
              <ListItemCheck
                isDirty={dirtyFields.has('password')}
                hasError={!!errors.password?.types?.uppercaseLowercase}
                wasTouched={!!touched.password}
              >
                Letras maiúsculas e minúsculas
              </ListItemCheck>
              <ListItemCheck
                isDirty={dirtyFields.has('password')}
                hasError={!!errors.password?.types?.specialCharacter}
                wasTouched={!!touched.password}
              >
                Caracteres especiais (ex: $#@%*)
              </ListItemCheck>
              <ListItemCheck
                isDirty={dirtyFields.has('password')}
                hasError={!!errors.password?.types?.digits}
                wasTouched={!!touched.password}
              >
                Números
              </ListItemCheck>
            </UnorderedList>
          </VStack>
        </Alert>
      </Collapse>
      <Button
        width="full"
        type="submit"
        marginTop="6"
        isLoading={isSubmitting}
        isDisabled={isDisabled}
      >
        Concluir
      </Button>
    </Form>
  );
};

type ListItemCheckProps = {
  isDirty: boolean;
  hasError: boolean;
  wasTouched: boolean;
};

const ListItemCheck = ({
  isDirty,
  hasError,
  wasTouched,
  children,
}: PropsWithChildren<ListItemCheckProps>) => {
  if (hasError && wasTouched) {
    return (
      <ListItem color="red.400">
        {children}
        <WarningIcon width="3" marginStart="1.5" />
      </ListItem>
    );
  }

  if (!hasError && isDirty) {
    return (
      <ListItem color="green.400">
        {children}
        <CheckCircleIcon width="3" marginStart="1.5" />
      </ListItem>
    );
  }

  return <ListItem>{children}</ListItem>;
};

import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { mdiAccountCheck, mdiAccountOff } from '@mdi/js';

import RSelect from 'Components/RSelect';
import Navlink from 'Components/Navlink';
import { mdiTableChart } from 'Components/Icons';

type Props = {
  routeAll: string;
  routeHistory: string;
};

const StatusSelect = ({ routeAll, routeHistory }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const status = useMemo(
    () => [
      {
        value: routeAll,
        label: 'Clientes Ativos',
        icon: mdiAccountCheck,
        iconColor: '#00C884',
        iconSize: 1,
      },
      {
        value: routeHistory,
        label: 'Clientes Inativos',
        icon: mdiAccountOff,
        iconColor: '#EC2C47',
        iconSize: 1,
      },
    ],
    [routeAll, routeHistory]
  );

  const value = useMemo(() => {
    return status.find((s) => s.value === pathname);
  }, [status, pathname]);

  const handleChangeStatus = (st) => {
    history.push(st.value);
  };

  if (!value) {
    return <Navlink icon={mdiTableChart} label="Tabela de Clientes" url={routeAll} />;
  }

  return (
    <RSelect
      width="197px"
      fontSize={16}
      options={status}
      onChange={handleChangeStatus}
      value={value}
      isSearchable={false}
      fontWeight={500}
    />
  );
};

export default StatusSelect;

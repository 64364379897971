import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Datatable from 'Components/Datatable';
import Button from 'Components/NewButton';
import Toast from 'Components/Toast';
import CreatePractitionerModal from 'Components/User/CreatePractitionerModal';
import EditPractitionerModal from 'Components/User/EditPractitionerModal';

import { useFlag } from 'Context/unleashContext';
import useCache from 'Hooks/useCache';
import environment from 'Utils/Dictionary/environment';

import UserActionsButton from './UserActionsButton';
import ChangeStatusConfirmModal from 'Components/User/ChangeStatusConfirmModal';

import { ROLE } from 'Components/User/shared';
import { format } from 'date-fns';

import ptBR from 'date-fns/locale/pt-BR';
import { useUserListQuery } from './__generated__/index.generated';

const options = {
  locale: ptBR,
};

const roles = {
  [ROLE.DOCTOR]: 'Equipe médica',
  [ROLE.NURSE]: 'Equipe de enfermagem',
};

export const userListQuery = gql`
  fragment UserListExtendedFields on UserDetail {
    roleId
    createdAt
    createdBy {
      firstName
      lastName
    }
  }

  query UserList($tenantId: Int!, $useExtendedFields: Boolean = false) {
    getUsers(entityId: $tenantId) {
      id
      entityId
      firstName
      lastName
      email
      personalId
      isActive

      ...UserListExtendedFields @include(if: $useExtendedFields)
    }
  }
`;

const Users = () => {
  const [loggedUser] = useCache('user');

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(6);
  const [tableSortBy, setTableSortBy] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [changeStatusConfirmModalIsOpen, setChangeStatusConfirmModalIsOpen] = useState(false);

  const toggleStatusConfirmModal = useCallback((user = null) => {
    setSelectedUser(user);
    setChangeStatusConfirmModalIsOpen((prev) => !prev);
  }, []);

  const toggleCreateModal = useCallback(() => {
    setEditModalOpen(false);
    setCreateModalOpen((prev) => !prev);
  }, []);

  const toggleEditModal = useCallback((user = null) => {
    setCreateModalOpen(false);
    setSelectedUser(user);
    setEditModalOpen((prev) => !prev);
  }, []);

  const isUserActionsEnabled = useFlag(`lc-user-actions-${environment.UNLEASH_APP_ENV}`);
  const isUserExpandedTableEnabled = useFlag(
    `lc-user-expanded-table-${environment.UNLEASH_APP_ENV}`
  );
  const isRegisterModalEnabled = useFlag(`lc-register-modal-${environment.UNLEASH_APP_ENV}`);

  const { loading, data, refetch, error } = useUserListQuery({
    fetchPolicy: 'network-only',
    variables: {
      tenantId: parseInt(loggedUser.entityId),
      useExtendedFields: isUserExpandedTableEnabled,
    },
  });

  const columns = useMemo(() => {
    const tableColumns = [
      {
        Header: 'Nome',
        accessor: 'fullName',
        width: 'auto',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        width: 'auto',
      },
    ];

    if (isUserExpandedTableEnabled) {
      tableColumns.push(
        {
          Header: 'Especialidade ',
          accessor: 'roleLabel',
          width: 'auto',
          Cell: ({ row }) => <i>{row.original?.roleLabel}</i>,
        },
        {
          Header: 'Entrada',
          accessor: 'createdAtDate',
          width: 'auto',
          Cell: ({ row }) => {
            if (!row.original?.createdAtDate) {
              return null;
            }

            return (
              <span title={row.original.createdAtTitle}>{row.original.createdAtFormatted}</span>
            );
          },
        },
        {
          Header: 'Adicionado por',
          accessor: 'createdByFullName',
          width: 'auto',
        },
        {
          Header: 'Status',
          accessor: 'statusLabel',
          width: 'auto',
          Cell: ({ row }) => <span style={{ color: '#0071EB' }}>{row.original?.statusLabel}</span>,
        }
      );
    }

    if (isUserActionsEnabled) {
      tableColumns.push({
        Header: 'Ações',
        width: 'auto',
        id: 'userActions',
        disableSortBy: true,
        Cell: ({ row }) => (
          <UserActionsButton
            user={row.original}
            onEdit={() => toggleEditModal(row.original)}
            onActivate={() => toggleStatusConfirmModal(row.original)}
            onDeactivate={() => toggleStatusConfirmModal(row.original)}
          />
        ),
      });
    }

    // Adicionado placeholder pois a última célula recebe 'display: none'
    tableColumns.push({
      Header: '',
      id: 'placeholder',
    });

    return tableColumns;
  }, [isUserActionsEnabled, isUserExpandedTableEnabled, toggleStatusConfirmModal, toggleEditModal]);

  const handleFetchData = useCallback(({ page, sortBy }) => {
    setCurrentPage(page);
    setTableSortBy(sortBy[0]);
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setTablePageSize(pageSize);
  }, []);

  const formatAdditionalData = useCallback((practitioner) => {
    let createdAtDate = '';
    let createdAtFormatted = '';
    let createdAtTitle = '';

    if (practitioner.createdAt) {
      const createdAt = new Date(practitioner.createdAt);

      createdAtDate = format(createdAt, 'yyyy-MM-dd', options);
      createdAtFormatted = format(createdAt, 'dd/MM/yy', options);
      createdAtTitle = format(createdAt, 'PPP', options);
    }

    const createdByFullName = [practitioner.createdBy?.firstName, practitioner.createdBy?.lastName]
      .filter(Boolean)
      .join(' ');

    return {
      roleLabel: roles[practitioner.roleId?.toString()] ?? '',
      statusLabel: practitioner.isActive ? 'Ativo' : 'Desativado',
      createdByFullName,
      createdAtDate,
      createdAtFormatted,
      createdAtTitle,
    };
  }, []);

  useEffect(() => {
    if (!data?.getUsers) {
      return;
    }

    let formattedData = data.getUsers.map((practitioner) => {
      const additionalData = isUserExpandedTableEnabled ? formatAdditionalData(practitioner) : {};

      const fullName = [practitioner?.firstName, practitioner?.lastName].filter(Boolean).join(' ');

      return {
        ...practitioner,
        fullName,
        ...additionalData,
      };
    });

    if (tableSortBy) {
      formattedData = formattedData.sort((prev, curr) => {
        const sortDirection = tableSortBy.desc ? -1 : 1;

        const prevValue = prev[tableSortBy.id]?.trim();
        const currValue = curr[tableSortBy.id]?.trim();

        return prevValue.toUpperCase() > currValue.toUpperCase() ? sortDirection : -sortDirection;
      });
    }

    setFilteredData(
      formattedData.slice(currentPage * tablePageSize, (currentPage + 1) * tablePageSize)
    );
  }, [
    data,
    currentPage,
    tablePageSize,
    tableSortBy,
    formatAdditionalData,
    isUserExpandedTableEnabled,
  ]);

  useEffect(() => {
    if (error) {
      toast(
        <Toast
          type="error"
          title="Não foi possível carregar a lista de profissionais"
          subtitle={error.message}
        />,
        { autoClose: false }
      );
    }
  }, [error]);

  if (!isRegisterModalEnabled) {
    return (
      <div className="row">
        <div className="col-md-5 col-lg-4 ">
          <div className="card no-hover form-style-1">
            <fieldset className="card-body form-group">
              <div className="d-flex flex-column">
                <legend>Cadastrar profissional</legend>
              </div>
              <CreatePractitionerModal isInline onSuccess={() => refetch()} />
            </fieldset>
          </div>
        </div>
        <div className="col-md-7 col-lg-8">
          <Datatable
            columns={columns}
            data={filteredData}
            loading={loading}
            totalData={data?.getUsers.length ?? 0}
            totalPages={Math.ceil(data?.getUsers.length / tablePageSize)}
            fetchData={handleFetchData}
            onPageSizeChange={handlePageSizeChange}
            cacheKey="users-inline"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Datatable
        columns={columns}
        data={filteredData}
        loading={loading}
        totalData={data?.getUsers.length ?? 0}
        totalPages={Math.ceil(data?.getUsers.length / tablePageSize)}
        fetchData={handleFetchData}
        onPageSizeChange={handlePageSizeChange}
        header={
          <div style={{ display: 'flex', padding: '0 22px 16px' }}>
            <h6 style={{ flex: 1, fontWeight: 700, margin: 0 }}>Equipe de Profissionais</h6>
            <div>
              <Button onClick={toggleCreateModal}>Adicionar profissionais</Button>
            </div>
          </div>
        }
        cacheKey="users"
      />
      <CreatePractitionerModal
        isOpen={isCreateModalOpen}
        onClose={toggleCreateModal}
        onSuccess={() => refetch()}
      />
      <EditPractitionerModal
        isOpen={isEditModalOpen}
        onClose={() => toggleEditModal()}
        onSuccess={() => refetch()}
        user={selectedUser}
      />
      <ChangeStatusConfirmModal
        isOpen={changeStatusConfirmModalIsOpen}
        user={selectedUser}
        onSuccess={() => refetch()}
        onClose={() => toggleStatusConfirmModal()}
      />
    </>
  );
};

export default Users;

import { useMemo } from 'react';

import { mdiCheckDecagram, mdiAlertOctagon, mdiInformation, mdiVideo } from '@mdi/js';

import * as Styled from './styled';
import { ColorDictionary } from 'Utils/Dictionary/ColorDictionary';

type OwnProps = {
  title: string;
  subtitle?: string | React.ReactNode;
  type?: 'information' | 'success' | 'error' | 'video';
};

type Props = OwnProps & typeof Toast.defaultProps;

const Toast = ({ title, subtitle, type }: Props) => {
  const color = useMemo(() => {
    switch (type) {
      case 'success':
        return ColorDictionary.Colors.toast.success;
      case 'error':
        return ColorDictionary.Colors.toast.error;
      case 'video':
        return ColorDictionary.Colors.toast.video;
      default:
        return ColorDictionary.Colors.toast.default;
    }
  }, [type]);

  const icon = useMemo(() => {
    switch (type) {
      case 'success':
        return mdiCheckDecagram;
      case 'error':
        return mdiAlertOctagon;
      case 'video':
        return mdiVideo;
      default:
        return mdiInformation;
    }
  }, [type]);

  return (
    <Styled.Wrapper>
      <Styled.Icon path={icon} color={color} size={0.8} />
      <Styled.Message>
        <Styled.Title color={color}>{title}</Styled.Title>
        {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
      </Styled.Message>
    </Styled.Wrapper>
  );
};

Toast.defaultProps = {
  subtitle: null,
  type: 'information',
};

export default Toast;

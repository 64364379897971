import { css } from 'styled-components';

export default css`
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.8em;
    height: 0.8em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #cecece;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
  }
`;

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Navlink from 'Components/Navlink';
import { mdiTableChart } from 'Components/Icons';

type Props = {
  routeAll: string;
};

const StatusSelect = ({ routeAll }: Props) => {
  const location = useLocation();

  const value = useMemo(() => {
    return !['settings', 'dashboard', 'timeline'].includes(location.pathname.split('/')[2]);
  }, [location]);

  return (
    <Navlink
      icon={mdiTableChart}
      label="Tabela de pacientes"
      url={routeAll}
      color={value ? '#0071eb' : '#A9A9A9'}
    />
  );
};

export default StatusSelect;

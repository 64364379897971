import styled from 'styled-components';
import FlexWrapper from '../Wrappers/FlexWrapper';

export const Header = styled(FlexWrapper)`
  min-height: 87px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-bottom: 36px;
  padding: 5px 0;
`;

export const Label = styled.div`
  text-align: left;
  font: Bold 14px/19px Roboto;
  letter-spacing: 0.28px;
  color: #000000;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const Wrapper = styled.div`
  padding: 0 17px;
  border-left: 1px solid #eaebeb;
  .custom-select {
    padding: 0 40px 0 10px;
  }
`;

export const FlexCenter = styled(FlexWrapper)`
  align-items: center;
  height: 100%;
`;

export const FilterSquare = styled.div`
  width: 20px;
  height: 20px;
  background: ${(props) => props.background};
  border: 1px solid ${(props) => props.border};
`;

export const UpdatedData = styled.div`
  color: #636363;
  padding: 5px 0;
`;

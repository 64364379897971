import { useRef, useMemo, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLazyQuery, useMutation } from '@apollo/client';

import { springSlide } from 'Utils/animations';
import ChatContext from 'Components/Chat/context';
import { AppBar, Container, Footer, TimelineButton } from 'Components/Chat/components';
import { sizes } from 'Components/Chat/types';
import { useChat } from 'Components/Chat/hooks/useChat';
import { GET_HANDOVER } from 'repository/queries';
import { useGetBotConversationsLazyQuery } from 'repository/queries/__generated__/handover.generated';
import {
  ADD_HANDOVER,
  UPDATE_HANDOVER,
  REMOVE_HANDOVER,
  SUBMIT_HANDOVER,
  OPEN_WINDOW,
} from 'repository/mutations';
import { getEntityId } from 'Utils/Config/SectorConfig';
import DashboardModel from 'Models/DashboardModel';
import LocalCache from 'Services/LocalCache';

import * as Styled from './styled';
import { Filter } from './components/Filter';
import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';
import { toast } from 'react-toastify';
import Toast from '../Toast';

export type ChatProps = {
  expanded: boolean;
  minimized: boolean;
  senderId: string;
  newMessage: string;
  page: number;
  limit: number;
  original?: {
    medicalRecord: string;
    chat: {
      channel: string;
      dateActiveContact: string;
      dateFirstMessage: string;
      dateLastMessage: string;
      dateWhatsappStatus: string;
      messagesUnreaded: number;
      needActiveContact: boolean;
      openWindow: boolean;
      responsibleEmail: string;
      senderId: string;
      templateCount: number;
      templateSended: boolean;
      whatsappStatus: string;
    };
  };
  title: string;
  loading?: boolean;
  submittingNewMessage?: boolean;
  isLastPage?: boolean;
  totalPages?: boolean;
  messages?: any[];
  handoverActive?: boolean;
};

const Chat = (props: ChatProps) => {
  const isChatFilesEnabled = useFlag(`lc-chat-files-${environment.UNLEASH_APP_ENV}`);
  const [filterValue, setFilterValue] = useState<null | Array<string>>(null);

  const {
    socket,
    moduleAccess,
    handleTotalPages,
    handleNextPage,
    handleResetPagination,
    closeChat,
    handleHandover,
    setMessages,
    toggleMinimize,
    toggleExpandChat,
    handleNewMessage,
    onClickTimeline,
    handoverStatus,
    setFilters: reducerSetFilters,
    fetchDocument,
  } = useChat();
  const { expanded, minimized, senderId, newMessage, page, limit } = props;
  const containerRef = useRef();

  const [getHandover, { data: dataIsHandover }] = useLazyQuery(GET_HANDOVER, {
    fetchPolicy: 'no-cache',
    variables: {
      entityId: getEntityId()[0],
      senderId,
      medicalRecord: props?.original?.medicalRecord,
      module: moduleAccess,
    },
  });

  const [fetchMessages, { loading: loadingDataMessages }] = useGetBotConversationsLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data?.getBotConversations?.conversations) {
        return;
      }

      setMessages(senderId, data.getBotConversations.conversations);
      handleTotalPages(senderId, data.getBotConversations.totalPages);
      containerRef?.current?.listRef?.current?.scrollToBottom();
    },
    onError: () => {
      toast(<Toast type="error" title="Não foi possível carregar mensagens" />);
    },
  });

  const [observationRecord] = useMutation(DashboardModel.OBSERVATION);

  const [addHandover] = useMutation(ADD_HANDOVER, {
    variables: {
      module: moduleAccess,
      medicalRecord: props?.original?.medicalRecord,
      senderId,
      entityId: getEntityId()[0],
      email: LocalCache.getUser()?.email,
      usuario: LocalCache.getUser()?.name,
      attendantCpf: LocalCache.getUser()?.cpf,
    },
    onCompleted: () => {
      handleHandover(senderId, true);
      observationRecord({
        variables: {
          entityId: getEntityId()[0],
          medicalRecord: props?.original?.medicalRecord,
          user: LocalCache.getUser()?.name,
          email: LocalCache.getUser()?.email,
          note: 'Novo responsável pelo atendimento',
          module: moduleAccess,
          attendantCpf: LocalCache.getUser()?.cpf,
        },
      });
    },
    onError: () => {
      handleHandover(senderId, false);
    },
  });

  const [updateHandover, { loading: updatingHandover }] = useMutation(UPDATE_HANDOVER, {
    variables: {
      module: moduleAccess,
      medicalRecord: props?.original?.medicalRecord,
      entityId: getEntityId()[0],
      email: LocalCache.getUser()?.email,
    },
    onCompleted: () => {
      observationRecord({
        variables: {
          entityId: getEntityId()[0],
          medicalRecord: props?.original?.medicalRecord,
          user: LocalCache.getUser()?.name,
          email: LocalCache.getUser()?.email,
          note: 'Novo responsável pelo atendimento',
          module: moduleAccess,
          attendantCpf: LocalCache.getUser()?.cpf,
        },
      });
    },
  });

  const [removeHandover] = useMutation(REMOVE_HANDOVER, {
    variables: {
      module: moduleAccess,
      medicalRecord: props?.original?.medicalRecord,
      senderId,
      entityId: getEntityId()[0],
    },
    onCompleted: () => {
      if (props.original) {
        fetchDocument(props.original);
      }

      handleHandover(senderId, false);
    },
    onError: () => {
      handleHandover(senderId, true);
    },
  });

  const [submitHandover, { loading: submittingHandover }] = useMutation(SUBMIT_HANDOVER, {
    variables: {
      senderId,
    },
  });

  const [openWindow] = useMutation(OPEN_WINDOW, {
    variables: {
      module: moduleAccess,
      medicalRecord: props?.original?.medicalRecord,
      senderId,
      entityId: getEntityId()[0],
    },
  });

  const setFilters = (value) => {
    setFilterValue(value);
    handleResetPagination(senderId);
    reducerSetFilters(senderId, value);
  };

  const submittingNewMessage = useMemo(
    () => submittingHandover || updatingHandover,
    [submittingHandover, updatingHandover]
  );

  const isLoading = useMemo(() => loadingDataMessages, [loadingDataMessages]);

  const userEmail = LocalCache.getUser()?.email;
  const responsibleEmail = props?.original?.chat?.responsibleEmail;

  const myCurrentChat = useMemo(
    () => (responsibleEmail ? responsibleEmail === userEmail : false),
    [responsibleEmail, userEmail]
  );

  const awaitingPatientResponse = useMemo(
    () => props?.original?.chat?.templateSended || false,
    [props?.original?.chat]
  );

  useEffect(() => {
    const watchSocket = () => {
      if (!socket.connected) {
        socket.open();
      }
      socket.emit('add', senderId);
      socket.on(`${senderId}`, (row) => {
        setMessages(senderId, [
          {
            event: row?.event,
            timestamp: `${row?.timestamp}`,
            text:
              row?.text === null && row?.data?.elements?.[0]?.interactive
                ? row?.data?.elements?.[0]?.interactive?.body.text
                : row?.text,
            handover: handoverStatus(row),
            file: row?.parse_data?.metadata?.file,
          },
        ]);
      });
    };

    watchSocket();

    return () => {
      if (socket.connected) {
        socket.emit('remove', senderId);
      }
    };
  }, [senderId, socket, setMessages, handoverStatus]);

  useEffect(() => {
    fetchMessages({
      variables: {
        senderId,
        page,
        limit,
        filter: filterValue,
      },
    });
  }, [senderId, filterValue, page, limit]);

  useEffect(() => {
    if (dataIsHandover) {
      handleHandover(senderId, dataIsHandover?.getHandover);
    }
  }, [dataIsHandover, senderId, handleHandover]);

  useEffect(() => {
    getHandover();
  }, [myCurrentChat, getHandover]);

  useEffect(() => {
    if (myCurrentChat) {
      openWindow({
        variables: {
          operation: true,
        },
      });
    }

    return () => {
      if (myCurrentChat) {
        openWindow({
          variables: {
            operation: false,
          },
        });
      }
    };
  }, [openWindow, myCurrentChat, minimized]);

  const handlerFocus = () => {
    containerRef?.current?.editorFocus();
  };

  const handleMinimize = () => {
    toggleMinimize(senderId);
  };

  const handleExpandChat = () => {
    toggleExpandChat(senderId);
  };

  const handleClose = () => {
    closeChat(senderId);
  };

  const internalHandleHandover = (isActive) => {
    if (isActive) {
      addHandover();
      openWindow({
        variables: {
          operation: true,
        },
      });
    } else {
      removeHandover();
    }
  };

  const internalHandleNewMessage = (nMessage) => {
    handleNewMessage(senderId, nMessage);
  };

  const internalHandleNextPage = () => {
    handleNextPage(senderId);
  };

  const sendUpdateHandover = () => {
    updateHandover({
      variables: {},
    }).then(() => {
      containerRef?.current?.editorClear();
      containerRef?.current?.editorResetExpanded();
      containerRef?.current?.listRef?.current?.scrollToBottom();

      fetchDocument(props.original);
    });
  };

  const sendMessage = () => {
    submitHandover({
      variables: {
        text: newMessage,
      },
    }).then(() => {
      containerRef?.current?.editorClear();
      containerRef?.current?.editorResetExpanded();
      containerRef?.current?.listRef?.current?.scrollToBottom();
    });
  };

  const optionsFilter = useMemo(
    () => [
      {
        key: 'option-all-files',
        value: ['pdf', 'png', 'jpeg'],
        label: 'Todos os Arquivos',
        count: 0,
      },
      {
        key: 'option-pdf',
        value: ['pdf'],
        label: 'PDF',
        count: 0,
      },
      {
        key: 'option-images',
        value: ['png', 'jpeg'],
        label: 'Imagens',
        count: 0,
      },
    ],
    []
  );

  const provider = useMemo(() => {
    return {
      ...props,
      myCurrentChat,
      awaitingPatientResponse,
      needActiveContact: props?.original?.chat?.needActiveContact,
      submittingNewMessage,
      loading: isLoading,
      color: '#DCF0FF',
      colorBot: '#FFE9D5',
      colorUser: '#E2E2E2',
      colorStatus: '#DCF0FF',
      closeChat: handleClose,
      handleHandover: internalHandleHandover,
      sendMessage,
      sendUpdateHandover,
      setMessages,
      onClickTimeline,
      toggleMinimize: handleMinimize,
      toggleExpandChat: handleExpandChat,
      handleNewMessage: internalHandleNewMessage,
      handleNextPage: internalHandleNextPage,
      filterValue: filterValue,
      setFilterValue: setFilters,
      optionsFilter: optionsFilter,
    };
  }, [
    myCurrentChat,
    awaitingPatientResponse,
    props?.original?.chat?.needActiveContact,
    submittingNewMessage,
    isLoading,
    handleClose,
    internalHandleHandover,
    sendMessage,
    sendUpdateHandover,
    setMessages,
    onClickTimeline,
    handleMinimize,
    handleExpandChat,
    internalHandleNewMessage,
    internalHandleNextPage,
    filterValue,
    setFilters,
    optionsFilter,
  ]);

  return (
    <ChatContext.Provider value={provider}>
      <AnimatePresence>
        <Styled.Container
          layout
          expanded={expanded}
          minimized={minimized}
          variants={sizes()}
          transition={springSlide}
          initial="minimized"
          animate={minimized ? 'minimized' : expanded ? 'expanded' : 'normal'}
          exit="minimized"
        >
          <TimelineButton />
          <AppBar />
          {isChatFilesEnabled && !minimized && (
            <Filter
              filterValue={filterValue}
              setFilterValue={setFilters}
              optionsFilter={optionsFilter}
            />
          )}
          <Container ref={containerRef} onClick={handlerFocus} />
          <Footer onClick={handlerFocus} />
        </Styled.Container>
      </AnimatePresence>
    </ChatContext.Provider>
  );
};

export default Chat;

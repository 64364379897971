import { formatToTodayShort } from 'Utils/Helpers/dateFnsHelper';
import { DATE_FORMAT } from 'Utils/types';

import * as Styled from './styled';

type Props = {
  date?: string | any; // TODO: PropTypes.instanceOf(Date)
};

const LastInteractionColumn = ({ date }: Props) => (
  <Styled.Container>{formatToTodayShort(date, DATE_FORMAT.datetime_abbreviated)}</Styled.Container>
);

export default LastInteractionColumn;

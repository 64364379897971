import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs((props) => ({
  minimized: props.minimized,
}))`
  background: #ffffff;
  box-shadow: 0px 3px 15px #0909091a;
  border-radius: 5px 5px 0px 0px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 304px;
  min-width: 304px;
  box-sizing: border-box;

  ${({ minimized }) => css`
    height: ${minimized ? 46 : window?.screen?.availHeight > 815 ? 767 : 567}px;
  `}
`;

export const List = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  height: 100%;

  ::-webkit-scrollbar {
    width: 0.4em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background: #ccc;
  }
`;

export const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: #ececec;
  align-self: flex-end;
`;

import { Fragment, createContext, useReducer } from 'react';

import useConversation from './conversations';
import { useReduce, INIT_STATE } from '../Reducer';

const Context = createContext();

function ChatProvider({ children }) {
  const [state, dispatch] = useReducer(useReduce, INIT_STATE);
  const { URI_CONVERSATIONS, URI_SOCKET, URI_REQ_HANOVER, URI_SEND_HANDOVER } = useConversation(
    state,
    dispatch
  );

  return (
    <Fragment>
      <Context.Provider
        value={{
          URI_CONVERSATIONS,
          URI_SOCKET,
          URI_REQ_HANOVER,
          URI_SEND_HANDOVER,
          state,
          dispatch,
        }}
      >
        {children}
      </Context.Provider>
    </Fragment>
  );
}

export { Context, ChatProvider };

import * as Styled from './styled';

type OwnProps = {
  color?: string;
  activeColor?: string;
  active?: boolean;
  label: string;
  icon: {
    image?: boolean;
    path?: string;
    color?: string;
  };
  onClick: (...args: any[]) => any;
};

type Props = OwnProps & typeof ServiceStepOption.defaultProps;

const ServiceStepOption = ({ color, activeColor, active, label, icon, onClick }: Props) => (
  <Styled.Container active={active} color={color} activeColor={activeColor} onClick={onClick}>
    {icon.image ? (
      <Styled.Image src={icon.path} active={active} />
    ) : (
      <Styled.Icon path={icon.path} color={icon.color || '#0071eb'} size={1} />
    )}
    {label}
  </Styled.Container>
);

ServiceStepOption.defaultProps = {
  color: '#e3e3e3',
  activeColor: '#0071eb',
  active: false,
};

export default ServiceStepOption;

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export function showModalAction(data) {
  return {
    type: SHOW_MODAL,
    data,
  };
}

export function hideModalAction() {
  return {
    type: HIDE_MODAL,
  };
}

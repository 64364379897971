import LogoMunai from 'Assets/munai_simbolo.svg?url';

const LoginContainer = ({ children }) => (
  <div className="login-background">
    <div className="container-fluid">
      <div className="row justify-content-md-center">
        <div className="width-360 my-auto mobile-m-auto bg-white p-3 pt-4 pb-4 rounded">
          {children}
        </div>
      </div>
    </div>
  </div>
);

const Logo = () => (
  <a className="logo" href="/">
    <img src={LogoMunai} style={{ height: 48 }} alt="Munai" />
  </a>
);

LoginContainer.Logo = Logo;

const Welcome = ({ children }) => <p className="text-center text-primary">{children}</p>;

LoginContainer.Welcome = Welcome;

export default LoginContainer;

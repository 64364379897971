import { useContext, useMemo } from 'react';
import Icon from '@mdi/react';
import { mdiAccountCircle } from '@mdi/js';

import { SectorDictionary } from 'Utils/Dictionary/SectorDictionary';
import { SectorDictionary as ChatSectorDictionary } from 'Utils/Dictionary/Chat/SectorDictionary';
import { SectorDictionary as TelemedicineSectorDictionary } from 'Utils/Dictionary/Telemedicine/SectorDictionary';

import { Timeline } from '../styled';
import ModuleContext from 'Context/moduleContext';

const TimelineEvolution = ({ timelineItem, shouldShow }) => {
  const { moduleAccess } = useContext(ModuleContext);

  const InternalSectorDictionary = useMemo(
    () => ({
      ...SectorDictionary,
      ...ChatSectorDictionary(moduleAccess),
      ...TelemedicineSectorDictionary(moduleAccess),
    }),
    [moduleAccess]
  );

  return InternalSectorDictionary[timelineItem.evolutionRecord.setor] ? (
    <>
      {shouldShow ? (
        <Timeline.Body>
          <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }}>
            Movido para {InternalSectorDictionary[timelineItem.evolutionRecord.setor].module}
          </Timeline.Body.Item>
        </Timeline.Body>
      ) : null}

      <Timeline.Small>
        <Icon color="#1D1D1D" path={mdiAccountCircle} size={0.8} style={{ marginRight: 6 }} />
        Responsável: {timelineItem.evolutionRecord.usuario}
      </Timeline.Small>
    </>
  ) : (
    <></>
  );
};

export default TimelineEvolution;

import styled from 'styled-components';

export const STATUS_LIST = {
  MOVED: 'movido',
  PAUSED: 'bloqueado',
  LOW_INTERACTION: 'baixa interação',
};

const Status = styled.div`
  border: 1px solid #0071eb;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font: Bold 11px/15px Roboto;
  letter-spacing: 0.11px;
  color: #0071eb;
  padding: 1px 4px;
  display: inline-block;
  margin-right: 5px;
`;

export const LOW_INTERACTION_STATUS = [
  'unreachable', //"Usuários que nunca interagiram com o bot",
  'flow_incomplete', // "Usuários que não concluíram o fluxo do bot)",
  'virtual_screening_incomplete', // "Usuários que não realizaram a triagem após conversar com o bot)"
  'low_interaction_period',
  'low_interaction_steps',
  'low_interaction_sent',
];

export const ACTIVE_CONTACT_STATUS_SENT = [
  'pending',
  'sent',
  'read',
  'delivered',
  'read',
  'inbound',
  'active',
  'transmitted',
];

export const ACTIVE_CONTACT_STATUS_FAILED = ['rejected', 'failed'];

export const MONITORING_PAUSED_RULE = (collection) =>
  collection.monitoring && collection.monitoring.status === 'blocked';

export const actualStatusText = (collection) => {
  const isReminder = collection?.evolutionRecord?.motivo === 'REMINDER_SCHEDULE' ? true : false;
  const isReminderFinish =
    collection?.evolutionRecord?.motivo === 'REMINDER_SCHEDULE_FINISHED' ? true : false;
  const isHouveMelhora =
    collection?.alert?.houveMelhora !== null &&
    collection?.alert?.houveMelhora !== '' &&
    (collection?.alert?.houveMelhora === 'true' || collection?.alert?.houveMelhora === true);
  const isHouvePiora =
    collection?.alert?.houveMelhora !== null &&
    collection?.alert?.houveMelhora !== '' &&
    collection?.alert?.houveMelhora !== undefined &&
    !isHouveMelhora;

  const isServiceStepMonitoring =
    collection?.evolutionRecord?.serviceStep.toString() === 'monitoring';

  const haveOptinFalseInStepMonitoring = collection?.optin === false;

  if (collection?.monitoring) {
    if (
      LOW_INTERACTION_STATUS.includes(collection?.monitoring?.status) ||
      LOW_INTERACTION_STATUS.includes(collection?.monitoring?.lowInteraction)
    ) {
      return STATUS_LIST.LOW_INTERACTION;
    }

    if (MONITORING_PAUSED_RULE(collection)) {
      return STATUS_LIST.PAUSED;
    }
  }

  if (isReminder || isReminderFinish) {
    return 'Adiado';
  }

  if (isHouveMelhora) {
    return 'Melhora';
  }
  if (isHouvePiora) {
    return 'Piora';
  }

  if (isServiceStepMonitoring) {
    if (haveOptinFalseInStepMonitoring) {
      return 'Convidado';
    }
    return 'Em monitoramento';
  }

  return '';
};

export const activeContactStatusText = (collection) => {
  if (ACTIVE_CONTACT_STATUS_SENT.includes(collection?.chat?.activeContactStatus)) {
    return 'enviado';
  }
  if (ACTIVE_CONTACT_STATUS_FAILED.includes(collection?.chat?.activeContactStatus)) {
    return 'falha no envio';
  }

  return '';
};

export default function StatusHelper({ collection }) {
  const actualStatus = () => {
    const text = actualStatusText(collection);
    return text !== '' ? <Status>{text} </Status> : <></>;
  };

  return actualStatus();
}

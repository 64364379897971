import LocalCache from 'Services/LocalCache';

export default {
  sort: (aModule, bModule) => {
    if (aModule?.order < bModule?.order) {
      return -1;
    }

    if (aModule?.order > bModule?.order) {
      return 1;
    }

    return 0;
  },
  getDefault: (modules = []) => {
    const slug = modules?.find((module) => module?.active)?.slug;

    if (!slug) {
      throw new Error('Nenhum módulo ativo para a entidade.');
    }

    return slug;
  },
  enabled: (module) => {
    const modules = LocalCache.getModules();

    return Boolean(modules?.find((m) => m.slug === module && m.active));
  },
  enabledService: (module, service) => {
    const modules = LocalCache.getModules();
    const serviceSteps = modules?.find((m) => m.slug === module)?.serviceStep;

    return Boolean(serviceSteps[service]);
  },
};

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Toast } from 'Components';
import { useDataTable } from 'Components/Datatable/container';
import Button from 'Components/MainButton';
import Modal from 'Components/Modal';
import { Title } from 'Components/Modal/styled';
import ModuleContext from 'Context/moduleContext';
import useCache from 'Hooks/useCache';
import DashboardModel from 'Models/DashboardModel';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalEnterLinkActions } from 'store/ducks/modal_enter_link';
import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import { SectorDictionary } from 'Utils/Dictionary/Telemedicine/SectorDictionary';
import { MainContent, Message, MoveButton } from './styled';

export default function ModalEnterLink() {
  const { isOpen, document } = useSelector(({ modal_enter_link: { isOpen, document } }) => ({
    isOpen,
    document,
  }));
  const [joinVideoCall, { loading: loadingJoin }] = useMutation(DashboardModel.JOIN_VIDEO_CALL);
  const [evolutionRecord, { loading: loadingEvolution }] = useMutation(DashboardModel.EVOLUTION);
  const [user] = useCache('user');
  const { moduleAccess } = useContext(ModuleContext);
  const serviceSteps = SERVICE_STEPS(moduleAccess);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(ModalEnterLinkActions.modalEnterLinkReset());
  };
  const { fetch } = useDataTable();
  /**
   * Atualiza o status da teleconsulta e abre o link
   */
  const handleJoinCall = async () => {
    try {
      const response = await joinVideoCall({
        variables: {
          entityId: document.entityId,
          medicalRecord: document.medicalRecord,
          telemedicineToken: document.serviceSteps.telemedicine.token,
          module: moduleAccess,
          usuario: user?.name,
          email: user?.email,
          attendantCpf: user?.cpf,
        },
      });

      window.open(response.data.joinVideoCall, '_blank');

      await evolutionRecord({
        variables: {
          entityId: parseInt(user.entityId),
          medicalRecord: document.medicalRecord,
          motive: 'Status change',
          hospitalMedicalRecord: '',
          sector: SectorDictionary(moduleAccess).IN_PROGRESS.slug,
          user: user.name,
          email: user.email,
          serviceStep: serviceSteps?.telemedicine.value,
          module: moduleAccess,
          attendantCpf: user?.cpf,
        },
      });

      toast(
        <Toast
          type="success"
          title="Paciente movido com sucesso"
          subtitle="Pronto agora o paciente está na etapa de atendimento."
        />
      );

      fetch();

      handleClose();
    } catch (e) {
      Sentry.captureException(e);
      toast(<Toast type="error" title="Não foi possível mover o paciente" subtitle={e.message} />);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose} width="732px">
      <Title>Mover Paciente</Title>
      <MainContent>
        <Message>
          Ao acessar a videochamada o paciente será movido para aba <b>Em Atendimento</b>
        </Message>
        <MoveButton
          loading={loadingJoin || loadingEvolution}
          onClick={handleJoinCall}
          className="contained rounded uppercase"
        >
          Acessar e mover paciente
        </MoveButton>
        <Button onClick={handleClose} className="text underlined uppercase">
          Cancelar
        </Button>
      </MainContent>
    </Modal>
  );
}

import styled from 'styled-components';

const ButtonsContainer = styled.div`
  max-width: 400px;
  padding: 20px 0;
  margin: auto;
`;

const Container = styled.div`
  width: 600px;
  .select__menu {
    margin-top: 0 !important;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
`;

const Subtitle = styled.div`
  padding: 7px 0;
  color: black;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #0071eb;
  margin-top: 20px;
`;

export const Styled = {
  Container,
  Title,
  Subtitle,
  Label,
  ButtonsContainer,
};

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const sizes = {
  sm: { size: 18, radius: 3, transform: 'translate(-10, -20)' },
  md: { size: 24, radius: 3, transform: 'translate(-10, -10)' },
  lg: { size: 32, radius: 3, transform: 'translate(-5, -10)' },
};

export const Container = styled.div.attrs((props) => ({
  align: props.align,
}))`
  display: flex;
  cursor: pointer;

  ${({ align }) => css`
    align-items: ${align};
  `}
`;

export const WrapperCheckbox = styled(motion.div).attrs((props) => ({
  size: props.size,
  color: props.color,
  checkedColor: props.checkedColor,
  checked: props.checked,
}))`
  cursor: pointer;

  ${({ size, color, checkedColor, checked }) => css`
    border-radius: ${sizes[size].radius}px;
    width: ${sizes[size].size}px;
    height: ${sizes[size].size}px;
    border: 1px solid ${checked ? checkedColor : color};
  `}
`;

export const Svg = styled.svg``;

export const Path = styled(motion.path)``;

export const Label = styled.span.attrs((props) => ({
  size: props.size,
}))`
  color: #444444;
  margin-left: 10px;

  ${({ size }) => css`
    font-size: ${sizes[size].size - 4}px;
    line-height: ${sizes[size].size - 4}px;
  `}
`;

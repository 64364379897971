import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Roboto';
  letter-spacing: 0.11px;
  text-transform: capitalize;
  color: #0071eb;
  font-size: 12px;
  font-weight: 500;
`;

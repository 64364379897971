export default {
  registerPatient: 'REGISTER_PATIENT',
  importPatients: 'IMPORT_PATIENTS',
  triageStep: 'Triagem',
  monitoredByLauraStep: 'Monitorados pela Munai',
  idMonitoredByLauraStep: 4,
  chatStep: 'Chat',
  videoStep: 'Vídeo',
  idVideoStep: 3,
  elegibleStep: 'Elegíveis',
};

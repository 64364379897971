import Spinner from 'Components/Spinner';
import { Button, LoaderContainer } from './styled';

export default function MainButton(props) {
  return (
    <Button {...props} disabled={props.disabled || props.loading}>
      {props.children}
      {props.loading ? (
        <LoaderContainer>
          <Spinner color="#fff" size="xs" />
        </LoaderContainer>
      ) : null}
    </Button>
  );
}

MainButton.defaultProps = {
  className: 'contained',
};

import { inputTheme } from './input';

const baseStyle = {
  ...inputTheme.baseStyle?.field,
  textAlign: 'center',
};

const sizes = {
  lg: {
    fontSize: 'lg',
    w: 12,
    h: 12,
    borderRadius: 'md',
  },
  md: {
    fontSize: 'md',
    w: 10,
    h: 10,
    borderRadius: 'md',
  },
  sm: {
    fontSize: 'sm',
    w: 8,
    h: 8,
    borderRadius: 'sm',
  },
  xs: {
    fontSize: 'xs',
    w: 6,
    h: 6,
    borderRadius: 'sm',
  },
};

const variants = {
  outline: (props) => inputTheme.variants?.outline(props)?.field,
  flushed: (props) => inputTheme.variants?.flushed(props)?.field,
  filled: (props) => inputTheme.variants?.filled(props)?.field,
  unstyled: inputTheme.variants?.unstyled.field ?? {},
};

export const pinInputTheme = {
  baseStyle,
  sizes,
  variants,
  defaultProps: inputTheme.defaultProps,
};

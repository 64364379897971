import ReactCalendar from 'react-calendar';
import { Container } from './styled';

/**
 * função responsável pelo calendar e suas customizações
 */
export default function Calendar(props) {
  return (
    <Container>
      <ReactCalendar defaultValue={new Date()} {...props} />
    </Container>
  );
}

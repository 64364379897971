import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, PropsWithoutRef } from 'react';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

import { validationClass } from '../../Utils/Helpers/Validator';

type InputProps = PropsWithoutRef<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> & {
  label?: string;
  error?: string;
  errormessage?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <div className="form-group">
    <input
      className={validationClass('form-control', props.error)}
      placeholder=" "
      ref={ref}
      {...props}
    />
    <label htmlFor={props.id}>{props.label}</label>
    {props.error && <label className="error">{props.errormessage}</label>}
  </div>
));

Input.displayName = 'Input';

type InputWithMaskProps = InputProps & {
  mask: string;
};

export const InputWithMask = forwardRef<HTMLInputElement, InputWithMaskProps>((props, ref) => (
  <InputMask
    alwaysShowMask={false}
    defaultValue={props.defaultValue ?? ''}
    mask={props.mask}
    onChange={props.onChange}
    placeholder=" "
    disabled={props.disabled}
  >
    {() => <Input ref={ref} {...props} />}
  </InputMask>
));

InputWithMask.displayName = 'InputWithMask';

export const InputMaskControl = (props) => (
  <Controller
    as={
      <InputMask mask={props?.mask}>
        {() => (
          <Input
            type={props?.type}
            error={props?.error}
            errormessage={props?.errormessage}
            disabled={props?.disabled}
            label={props?.label}
            name={props?.name}
            id={props?.id}
          />
        )}
      </InputMask>
    }
    disabled={props?.disabled}
    defaultValue={props?.defaultValue ?? ''}
    control={props?.control}
    name={props?.name}
    rules={props?.rules}
  />
);

export default Input;

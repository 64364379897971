import { useHistory } from 'react-router-dom';

import * as Styled from './styled';
import { Spinner, StepsNotificationCircle } from 'Components';

type OwnProps = {
  active: boolean;
  activeColor?: string;
  count: number;
  unreadMessages?: number;
  icon?: {
    path: string;
    activePath: string;
    color?: string;
    image?: boolean;
  };
  label?: string;
  onClick: (...string) => void;
  to?: string;
  value?: string;
  showAlert?: boolean;
  loading?: boolean;
};

const ServiceButton = ({
  active,
  count,
  unreadMessages,
  icon,
  label,
  onClick,
  value,
  activeColor = '#0071eb',
  to,
  showAlert,
  loading,
}: OwnProps) => {
  const history = useHistory();

  const handleClick = () => {
    onClick(value);
    to && history.push(to);
  };

  return (
    <Styled.Container
      showAlert={showAlert}
      active={active}
      activeColor={activeColor}
      onClick={handleClick}
      hasMessages={Boolean(unreadMessages)}
    >
      {icon &&
        (icon.image ? (
          <Styled.Image src={active ? icon.activePath : icon.path} />
        ) : (
          <Styled.Icon
            path={active ? icon.activePath : icon.path}
            color={active ? 'white' : icon.color || '#0071eb'}
            size={1}
          />
        ))}
      <Styled.LabelContiner>
        <Styled.Title>{label}</Styled.Title>
        <Styled.Counter active={active}>
          {loading ? <Spinner color={active ? '#fff' : '#777'} size="xs" /> : count}
        </Styled.Counter>
        <StepsNotificationCircle countNotification={unreadMessages} />
      </Styled.LabelContiner>
      {showAlert && <Styled.Badge>!</Styled.Badge>}
    </Styled.Container>
  );
};

export default ServiceButton;

// Constantes do banco de dados
const ROLES = {
  USER: 1,
  STAFF: 2,
  NISSAN: 3,
  MASTER: 4,
  DOCTOR: 5,
  NURSE: 6,
};

const ALLOW_TO_DOCTORS = [ROLES.DOCTOR, ROLES.MASTER];
const MEDICAL_STAFF = [ROLES.MASTER, ROLES.USER, ROLES.DOCTOR, ROLES.NURSE];

export { ROLES, ALLOW_TO_DOCTORS, MEDICAL_STAFF };

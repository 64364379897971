import { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Redirect, Route, Switch } from 'react-router-dom';

import { teleatendimento } from 'routes';
import client from 'Services/api';
import WrapperTeleAtendimento from 'Pages/shared/WrapperTeleAtendimento';
import { MoreActionsProvider } from 'Hooks/useMoreActions';
import cacheService from 'Services/cache';

import Active from './Active';
import History from './History';
import Import from './Import';
import Outside from './Outside';
import ContactActive from './Contact';
import Settings from '../Settings';
import BIWhatsApp from './Dashboard/WhatsApp';
import BIMessenger from './Dashboard/Messenger';
import Users from '../Settings/Users';
import { PrivateRoute } from 'Components/routes';

const TeleAtendimento = () => {
  //Mudar o Nome no titulo
  document.title = 'Nissan - TeleAtendimento';

  // eslint-disable-next-line quotes
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.rel = 'icon';
  link.href = '/nissan.ico';
  link.sizes = '32x32';
  document.getElementsByTagName('head')[0].appendChild(link);
  cacheService.set('senderId', null);

  return (
    <WrapperTeleAtendimento>
      <Switch>
        <PrivateRoute component={BIWhatsApp} exact path={teleatendimento.WHATSAPP} />
        <PrivateRoute component={BIMessenger} exact path={teleatendimento.MESSENGER} />
        <PrivateRoute component={History} exact path={teleatendimento.HISTORY} />
        <PrivateRoute component={Import} exact path={teleatendimento.IMPORT} />
        <PrivateRoute component={ContactActive} exact path={teleatendimento.CONTACT_ACTIVE} />
        <PrivateRoute component={Outside} exact path={teleatendimento.OUTSIDE} />
        <PrivateRoute component={Settings} exact path={teleatendimento.SETTINGS.SETTINGS} />
        <PrivateRoute component={Users} exact path={teleatendimento.SETTINGS.USERS} />
        <PrivateRoute component={Active} exact path={teleatendimento.SERVICES} />
        <PrivateRoute component={Active} path={teleatendimento.ACTIVE} />
        <Route render={() => <Redirect to="/teleatendimento/all" />} />
      </Switch>
    </WrapperTeleAtendimento>
  );
};

const RootTeleAtendimento = () => {
  const [clientRoutes] = useState(
    <ApolloProvider client={client}>
      <MoreActionsProvider>
        <TeleAtendimento />
      </MoreActionsProvider>
    </ApolloProvider>
  );
  return clientRoutes;
};

export default RootTeleAtendimento;

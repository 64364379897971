import { mdiMenuUp, mdiMenuDown } from '@mdi/js';

import { useChat } from 'Components/Chat/hooks/useChat';

import * as Styled from './styled';

type Props = {
  totalChats: number;
};

const Header = ({ totalChats }: Props) => {
  const { toggleMinimizePreviousChat, minimizedPreviousChat } = useChat();

  return (
    <Styled.Container onClick={toggleMinimizePreviousChat}>
      <Styled.ContainerText>
        <Styled.Title>Mensagens</Styled.Title>
        <div>
          <Styled.Badge>{totalChats}</Styled.Badge>
        </div>
      </Styled.ContainerText>
      <Styled.Icon
        path={minimizedPreviousChat ? mdiMenuUp : mdiMenuDown}
        color="#707070"
        size={1.5}
      />
    </Styled.Container>
  );
};

export default Header;

import styled from 'styled-components';
import menu from 'Assets/icons/menu.svg';
import IconBase from '@mdi/react';

export const Container = styled.div`
  background: white;
  margin-bottom: 5px;
`;

export const Pagination = styled.div`
  background: transparent;
  margin: 0;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PaginationButton = styled.button`
  color: #6d6d6d;
  border: 0;
  background: none;
  padding: 0;
  &:disabled {
    opacity: 0.3;
  }
`;

export const Select = styled.select`
  width: 51px;
  border: none;
  border-bottom: 1px solid #707070;
  border-radius: 0;
  padding: 0 0 0 5px;
  height: 22px;
  margin: 0;
  float: right;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
  line-height: 15px;
  background: transparent;
  background-image: url(${menu});
  background-repeat: no-repeat;
  background-position: 100%;
  align-content: center;
  background-size: 11px;
  color: #000;
  appearance: none;
`;

export const PageSizeLabel = styled.label`
  color: #000;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  margin: 0;
`;

export const PageSizeContainer = styled.div`
  display: flex;
  margin-right: 40px;
  align-items: center;
`;

export const SearchContainer = styled.div`
  position: relative;
`;

export const ClearSearchIcon = styled(IconBase)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  cursor: pointer;
`;

export const InfoIcon = styled(IconBase)`
  margin-right: 5px;
  cursor: pointer;
`;

export const TableWrap = styled.div`
  display: block;
  max-width: 100%;
  overflow: visible;
  border-collapse: collapse;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 3px 15px #0000000d;
  padding: 20px 0px 0px;
`;

export const Thead = styled.thead`
  text-transform: uppercase;

  tr:first-child {
    th {
      background: white;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-bottom: 1px solid #ececec !important;
    }
  }
`;

export const Tbody = styled.tbody`
  tr {
    height: 48px;
    vertical-align: middle;

    td {
      vertical-align: middle;

      &:nth-last-child(2) {
        border-left: 1px solid #eaeaea;
      }
    }
  }

  tr:nth-of-type(odd) {
    background: white;
  }

  tr:nth-of-type(even) {
    background: #f8f8f8;
  }
`;

export const Tr = styled.tr``;

export const Td = styled.td``;

export const Th = styled.th.attrs((props) => ({
  active: props.active,
  sort: props.sort,
}))`
  color: ${(props) => (props.active ? '#444444' : '#a9a9a9')};
  fill: ${(props) => (props.active ? '#444444' : '#a9a9a9')};
  vertical-align: middle;
  cursor: ${(props) => (props.sort ? 'pointer' : 'default')};
`;

export const Table = styled.table`
  font-size: 13px;
  margin-bottom: 0px;

  tr[role='row'] {
    th:last-child {
      display: none;
    }
  }

  tr[role='row'] {
    td:last-child {
      display: none;
    }
  }

  th:first-child,
  td:first-child {
    padding-left: 22px;
  }

  th:nth-last-child(2),
  td:nth-last-child(2) {
    padding-right: 22px;
  }

  th,
  td {
    border-top: none;
  }
`;

export const TableHeadSearch = styled.th`
  border-bottom: 1px solid #dee2e6 !important;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
`;

const FilterCounter = styled.div`
  background-color: #ffffff;
  color: black;
  border-radius: 50%;
  color: #0071e9;
  height: 22px;
  width: 22px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 13px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FiltersListContainer = styled.div`
  position: absolute;
  z-index: 3;
  background-color: white;
  border-radius: 5px;
  margin-top: 10px;
  color: #444444;
  padding: 0px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  top: 60px;
  max-height: 500px;
  right: 10px;
  left: 10px;
  border: 1px solid #ececec;

  &.showFilters {
    z-index: 3;
    opacity: 1;
    transition: 0;
  }

  &.hideFilters {
    z-index: -1;
    opacity: 0;
    transition: 0;
  }
`;

const FiltersListFormsContainer = styled.div`
  padding: 20px 30px;
  max-height: 100%;
  overflow: auto;
`;

const FiltersListForms = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.4em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #ebedf2;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
  }
`;

const FiltersListColumn = styled.div`
  line-height: 20px;
`;

const FiltersListForm = styled.div`
  padding: 20px;
`;

const FiltersListTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
`;

const FiltersListButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #ccc;
`;

const FiltersListLabel = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
  padding: 0;
  font-size: 16px;
  cursor: pointer;

  input {
    margin: 0 10px 0 0;
    zoom: 1.4;
    cursor: pointer;
  }
`;

const FiltersRadioLabel = styled.label`
  display: inline-flex;
  align-items: center;
  user-select: none;
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 0px;

  input {
    margin: 0 10px 0 0;
    zoom: 1.4;
    cursor: pointer;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FilterSelectedItem = styled.div`
  padding: 10px 30px 10px 10px;
  flex: 1 0 auto;
  margin: 4px;
  position: relative;
  border-radius: 5px;
  transition: background-color 0.3s;
  background-color: rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const FilterSelectedListContainer = styled.div`
  display: flex;
  margin: -4px;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 32px;

  &::after {
    content: '';
    flex-grow: 999999;
  }
`;

const FilterSelectedListTitle = styled.div`
  padding: 10px;  
  font-weight: bold;
  margin: 4px;
`;

const FilterRemoveIcon = styled.div`
  position: absolute;
  right: 10px;
  font-size: 23px;
  top: 3px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

export const Spacing = styled.div`
  width: 15px;
`;

const FiltersTitle = styled.p`
  font-size: 20px;
  color: #444;
  margin-bottom: 0px;
  padding: 20px 30px;
  border-bottom: 1px solid #ccc;
`;

const Styled = {
  FilterContainer,
  TableWrap,
  FilterCounter,
  TableHeadSearch,
  Pagination,
  PaginationButton,
  Select,
  PageSizeContainer,
  PageSizeLabel,
  FiltersListContainer,
  FiltersListColumn,
  FiltersListForm,
  FiltersListForms,
  FiltersListLabel,
  FiltersListTitle,
  FiltersListButtonsContainer,
  FilterSelectedItem,
  FilterSelectedListContainer,
  FilterSelectedListTitle,
  FilterRemoveIcon,
  ClearSearchIcon,
  InfoIcon,
  SearchContainer,
  Spacing,
  FiltersRadioLabel,
  FiltersListFormsContainer,
  FiltersTitle,
};

export const Icon = styled(IconBase)`
  margin-left: 10px;
`;

export const IconPaginaton = styled(IconBase)`
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid #0071eb;

  path {
    color: #0071eb;
  }
`;

export const DataInfo = styled.span`
  margin: 0px 20px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export default Styled;

import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  margin: auto;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 100px 120px 82px 120px;
`;

const Container = styled.div`
  .select__menu {
    margin-top: 0 !important;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
`;

const MessageTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 20px;
`;

const IconTitle = styled.span`
  font-size: 16px;
  margin-bottom: 10px;
`;

const MessageContent = styled.span`
  font-size: 14px;
  color: #707070;
`;

const IconContainer = styled.div`
  margin-bottom: 6px;
`;

export const Styled = {
  Container,
  MessagesContainer,
  Title,
  MessageTitle,
  MessageContent,
  ButtonsContainer,
  IconContainer,
  IconTitle,
};

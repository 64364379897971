import { accordionTheme } from './accordion';
import { alertTheme } from './alert';
import { avatarTheme } from './avatar';
import { badgeTheme } from './badge';
import { breadcrumbTheme } from './breadcrumb';
import { buttonTheme } from './button';
import { checkboxTheme } from './checkbox';
import { closeButtonTheme } from './close-button';
import { codeTheme } from './code';
import { containerTheme } from './container';
import { dividerTheme } from './divider';
import { drawerTheme } from './drawer';
import { editableTheme } from './editable';
import { formTheme } from './form-control';
import { formErrorTheme } from './form-error';
import { formLabelTheme } from './form-label';
import { headingTheme } from './heading';
import { inputTheme } from './input';
import { kbdTheme } from './kbd';
import { linkTheme } from './link';
import { listTheme } from './list';
import { menuTheme } from './menu';
import { modalTheme } from './modal';
import { numberInputTheme } from './number-input';
import { pinInputTheme } from './pin-input';
import { popoverTheme } from './popover';
import { progressTheme } from './progress';
import { radioTheme } from './radio';
import { selectTheme } from './select';
import { skeletonTheme } from './skeleton';
import { skipLinkTheme } from './skip-link';
import { sliderTheme } from './slider';
import { spinnerTheme } from './spinner';
import { statTheme } from './stat';
import { switchTheme } from './switch';
import { tableTheme } from './table';
import { tabsTheme } from './tabs';
import { tagTheme } from './tag';
import { textareaTheme } from './textarea';
import { tooltipTheme } from './tooltip';

export { accordionTheme as Accordion } from './accordion';
export { alertTheme as Alert } from './alert';
export { avatarTheme as Avatar } from './avatar';
export { badgeTheme as Badge } from './badge';
export { breadcrumbTheme as Breadcrumb } from './breadcrumb';
export { buttonTheme as Button } from './button';
export { checkboxTheme as Checkbox } from './checkbox';
export { closeButtonTheme as CloseButton } from './close-button';
export { codeTheme as Code } from './code';
export { containerTheme as Container } from './container';
export { dividerTheme as Divider } from './divider';
export { drawerTheme as Drawer } from './drawer';
export { editableTheme as Editable } from './editable';
export { formTheme as Form } from './form-control';
export { formErrorTheme as FormError } from './form-error';
export { formLabelTheme as FormLabel } from './form-label';
export { headingTheme as Heading } from './heading';
export { inputTheme as Input } from './input';
export { kbdTheme as Kbd } from './kbd';
export { linkTheme as Link } from './link';
export { listTheme as List } from './list';
export { menuTheme as Menu } from './menu';
export { modalTheme as Modal } from './modal';
export { numberInputTheme as NumberInput } from './number-input';
export { pinInputTheme as PinInput } from './pin-input';
export { popoverTheme as Popover } from './popover';
export { progressTheme as Progress } from './progress';
export { radioTheme as Radio } from './radio';
export { selectTheme as Select } from './select';
export { skeletonTheme as Skeleton } from './skeleton';
export { skipLinkTheme as SkipLink } from './skip-link';
export { sliderTheme as Slider } from './slider';
export { spinnerTheme as Spinner } from './spinner';
export { statTheme as Stat } from './stat';
export { switchTheme as Switch } from './switch';
export { tableTheme as Table } from './table';
export { tabsTheme as Tabs } from './tabs';
export { tagTheme as Tag } from './tag';
export { textareaTheme as Textarea } from './textarea';
export { tooltipTheme as Tooltip } from './tooltip';

export const components = {
  Accordion: accordionTheme,
  Alert: alertTheme,
  Avatar: avatarTheme,
  Badge: badgeTheme,
  Breadcrumb: breadcrumbTheme,
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  CloseButton: closeButtonTheme,
  Code: codeTheme,
  Container: containerTheme,
  Divider: dividerTheme,
  Drawer: drawerTheme,
  Editable: editableTheme,
  Form: formTheme,
  FormError: formErrorTheme,
  FormLabel: formLabelTheme,
  Heading: headingTheme,
  Input: inputTheme,
  Kbd: kbdTheme,
  Link: linkTheme,
  List: listTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  NumberInput: numberInputTheme,
  PinInput: pinInputTheme,
  Popover: popoverTheme,
  Progress: progressTheme,
  Radio: radioTheme,
  Select: selectTheme,
  Skeleton: skeletonTheme,
  SkipLink: skipLinkTheme,
  Slider: sliderTheme,
  Spinner: spinnerTheme,
  Stat: statTheme,
  Switch: switchTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Tag: tagTheme,
  Textarea: textareaTheme,
  Tooltip: tooltipTheme,
};

import { telemedicineRoutes } from 'routes';
import { ROLES } from './RolesConfig';

export const RoutesNotPermitedByRoleId = {
  [telemedicineRoutes.SETTINGS.USERS]: [ROLES.USER, ROLES.NURSE],
};

export const verifyRouteAccessed = (roleId, route) => {
  const routeFiltered = route.replace('/:module', '');
  return !(
    RoutesNotPermitedByRoleId[routeFiltered] &&
    RoutesNotPermitedByRoleId[routeFiltered].includes(roleId)
  );
};

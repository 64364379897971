import MainButton from 'Components/MainButton';
import { Content } from 'Components/Modal/styled';
import styled from 'styled-components';

export const Message = styled.p`
  font: normal normal normal 14px/17px Roboto;
  margin-bottom: 30px;
  color: #444;
`;

export const MainContent = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: none;
`;

export const MoveButton = styled(MainButton)`
  width: 372px;
  height: 40px;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const CancelButton = styled(MainButton)`
  width: 372px;
  height: 40px;
  color: #0071eb !important;
`;

export const Description = styled.div`
  background: #f4f5f7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
`;

import * as Styled from './styled';

type Props = {
  footer?: (...args: any[]) => any;
  header?: (...args: any[]) => any;
};
export default function Container({ children, header: Header, footer: Footer }: Props) {
  return (
    <Styled.Wrapper>
      {Header && <Header />}
      <Styled.Container className="container-fluid">
        <div className="container-internal">
          <div className="row">
            <div className="col-md-12">{children}</div>
          </div>
        </div>
      </Styled.Container>
      {Footer && <Footer />}
    </Styled.Wrapper>
  );
}

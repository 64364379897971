import { useContext } from 'react';
import Icon from '@mdi/react';
import { mdiVideo } from '@mdi/js';
import { Button } from './styled';
import ModuleContext from 'Context/moduleContext';
import { useDispatch } from 'react-redux';
import { ModalScheduleActions } from 'store/ducks/modal_schedule';

export default function ScheduleTelemedicineButton({ document }) {
  const dispatch = useDispatch();
  const { stepActive } = useContext(ModuleContext);

  if (!['screening', 'chat'].includes(stepActive)) {
    return null;
  }
  return (
    <>
      <Button
        onClick={() =>
          dispatch(ModalScheduleActions.modalScheduleSetIsOpen(true, true, document, false))
        }
      >
        <Icon path={mdiVideo} size={0.8} style={{ marginRight: 4 }} />
        Criar
      </Button>
    </>
  );
}

import * as Types from '../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllFiltersQueryVariables = Types.Exact<{
  entityId: Types.Scalars['Int'];
  module: Types.Scalars['String'];
}>;


export type GetAllFiltersQuery = { __typename?: 'Query', getAllFilters?: { __typename?: 'Filters', tags?: Array<string | null> | null, ubs?: Array<string | null> | null, grupos?: Array<string | null> | null, healthCare?: Array<string | null> | null, alerts?: Array<string | null> | null, status?: Array<string | null> | null, flow?: Array<string | null> | null } | null };


export const GetAllFiltersDocument = gql`
    query GetAllFilters($entityId: Int!, $module: String!) {
  getAllFilters(entityId: $entityId, module: $module) {
    tags
    ubs
    grupos
    healthCare
    alerts
    status
    flow
  }
}
    `;

/**
 * __useGetAllFiltersQuery__
 *
 * To run a query within a React component, call `useGetAllFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFiltersQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      module: // value for 'module'
 *   },
 * });
 */
export function useGetAllFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetAllFiltersQuery, GetAllFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFiltersQuery, GetAllFiltersQueryVariables>(GetAllFiltersDocument, options);
      }
export function useGetAllFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFiltersQuery, GetAllFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFiltersQuery, GetAllFiltersQueryVariables>(GetAllFiltersDocument, options);
        }
export type GetAllFiltersQueryHookResult = ReturnType<typeof useGetAllFiltersQuery>;
export type GetAllFiltersLazyQueryHookResult = ReturnType<typeof useGetAllFiltersLazyQuery>;
export type GetAllFiltersQueryResult = Apollo.QueryResult<GetAllFiltersQuery, GetAllFiltersQueryVariables>;
export default {
  download: (csv, name) => {
    const blob = new Blob([csv]);
    const aLink = document.createElement('a');

    aLink.href = URL.createObjectURL(blob, { type: 'text/csv;charset=utf-8;' });
    aLink.download = name;
    aLink.type = 'text/csv';

    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);
  },
};

import { useState, useRef } from 'react';

import * as S from './styled';

import Button from 'Components/NewButton';
import Dialog from 'Components/Dialog';

import { fileOptions } from '../FileContainer';
import Spinner from 'Components/Spinner';
import { formatModalDay } from 'Utils/Helpers/dateFnsHelper';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  file: fileOptions;
  time: string;
};

export const ImageModal = ({ isOpen, onClose, file, time }: Props) => {
  const [isLoading, setLoading] = useState(true);
  const [hasZoomedIn, setZoomedIn] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const modalWidth = 732;
  const imgScaleZoom = hasZoomedIn ? 1.5 : 1;

  const modalHeight = (modalRef.current && modalRef.current.clientHeight + 178) || 0;
  const imgHeight = (imageRef.current && imageRef.current.naturalHeight) || 0;
  const imgHeightScaled = imgHeight * imgScaleZoom;
  const alignImageCenter = imgHeightScaled > modalHeight;

  const imgWidth = (imageRef.current && imageRef.current.naturalWidth) || 0;
  const imgWidthScaled = imgWidth * imgScaleZoom;

  const moveImageToRight =
    imgWidthScaled > modalWidth ? imgWidth / 2 - modalWidth / (imgScaleZoom * 2) : 0;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      backdrop
      width={`${modalWidth}px`}
      noPadding={hasZoomedIn}
      noBorder={hasZoomedIn}
      noCloseButton={hasZoomedIn}
    >
      {!hasZoomedIn && (
        <Dialog.Header>
          <Dialog.Title>{file.name}</Dialog.Title>
          <Dialog.Subtitle>{formatModalDay(time)}</Dialog.Subtitle>
        </Dialog.Header>
      )}

      <Dialog.Body
        ref={modalRef}
        noPadding={hasZoomedIn}
        noBorder={hasZoomedIn}
        style={{
          display: 'flex',
          alignItems: hasZoomedIn && alignImageCenter ? 'flex-start' : 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          height: 'calc(100vh - 125px)',
        }}
      >
        <S.ModalImagem
          src={file.url}
          alt={file.name}
          ref={imageRef}
          imgScaleZoom={imgScaleZoom}
          translateX={moveImageToRight}
          hasZoomedIn={hasZoomedIn}
          alignImageCenter={alignImageCenter}
          onLoad={() => setLoading(false)}
          onClick={() => setZoomedIn((prev) => !prev)}
        />
        {isLoading && <Spinner />}
      </Dialog.Body>

      {!hasZoomedIn && (
        <Dialog.Footer>
          <Button maxWidth={120} fullWidth onClick={onClose} type="button">
            Fechar
          </Button>
        </Dialog.Footer>
      )}
    </Dialog>
  );
};

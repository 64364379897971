import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import IconBase from '@mdi/react';

export const Container = styled(motion.div).attrs((props) => ({
  event: props.event,
  color: props.color,
  colorBot: props.colorBot,
  colorUser: props.colorUser,
  colorStatus: props.colorStatus,
  expanded: props.expanded,
  type: props.type,
}))`
  position: relative;
  display: flex;
  align-items: flex-end;

  ${({ event, color, colorUser, colorStatus, colorBot, expanded, type }) => {
    const isBot = event === 'bot';
    const isUser = event === 'user';
    const isStatus = event === 'status';
    const isFile = type;

    const getCustomCss = () => {
      if (isFile) {
        return css`
          background: #707070;
          color: #ffffff;
        `;
      }
      if (isUser) {
        return css`
          background: ${colorUser};
          color: #444444;
        `;
      }

      if (isBot) {
        return css`
          background: ${colorBot};
          color: #444444;
        `;
      }

      if (isStatus) {
        return css`
          background: ${colorStatus};
          width: ${expanded ? 512 : 320}px;
          padding: 8px 24px 7px 16px;
          max-width: ${expanded ? 512 : 320}px;
          justify-content: flex-end;
          border-radius: 0;
          font-size: 14px;
          font-weight: 500;
          align-self: stretch;
          margin: 16px 0px 16px -16px;
        `;
      }

      return css`
        background: ${color};
      `;
    };

    return css`
      max-width: 90%;
      border-radius: ${isUser ? '0px 5px 5px 5px' : '5px 0px 5px 5px'};
      align-self: ${isUser ? 'flex-start' : 'flex-end'};
      margin: 8px 0px;
      padding: 8px 24px 15px 16px;

      ${getCustomCss()}
    `;
  }}
`;

export const Text = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  position: relative;
  white-space: pre-line;
  word-break: break-word;
`;

export const Status = styled.div`
  position: absolute;
  right: 5px;
  bottom: 3px;
  display: flex;
  align-items: center;
`;

export const DateStatus = styled.span`
  color: #a9a9a9;
  font-size: 12px;
`;

export const IconStatus = styled(IconBase)`
  margin-left: 2px;
`;

import { useContext } from 'react';

import * as Styled from './styled';

import { SERVICE_STEPS } from '../../Utils/Config/SectorConfig';

import LocalCache from 'Services/LocalCache';
import ModuleContext from 'Context/moduleContext';
import amplitude from 'Utils/Helpers/amplitude';
import dateFns from 'Utils/Helpers/dateFns';
import { useSelector } from 'react-redux';
import { StepsNotificationCircle } from 'Components';
import { MenuContext } from 'Context/menuContext';

const SubStepTabs = () => {
  const { stepsData } = useContext(MenuContext);
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const imminentTeleconsultations = useSelector(
    ({ modules }) => modules.imminentTeleconsultations[moduleAccess]
  );

  const handleAmplitudeSubSteps = (moduleAccess, stepActive, sector) => {
    const data = {
      name: LocalCache?.getUser()?.name.toString(),
      email: LocalCache?.getUser()?.email.toString(),
      userId: LocalCache?.getUser()?.userId.toString(),
      entityId: LocalCache?.getUser()?.entityId.toString(),
      moduleAccess: moduleAccess,
      serviceStep: stepActive,
      subSteps: {
        module: sector.module,
        name: sector.name,
        route: sector.route,
        slug: sector.slug,
      },
      date: dateFns.formatTo(new Date(), 'dd/MM/yyyy HH:mm:ss'),
    };

    const userId = `${data.userId}.${data.entityId}`;
    amplitude.setUserId(userId);
    amplitude.trackEvent('SUB_STEP_TABS_LAURA_CARE', data);
  };

  if (SERVICE_STEPS(moduleAccess)[stepActive]?.subSteps) {
    return (
      <Styled.SubStepTabContainer>
        {SERVICE_STEPS(moduleAccess)
          [stepActive].sectors.filter((sector) => sector.type === 1)
          .map((sector) => {
            const serviceStepName = sector.slug;
            const unreadChats = stepsData?.[serviceStepName]?.unreadChats?.length;
            let serviceStepCount = 0;

            // If the feature flag 'displayUnreadMessages' is true servicestepcount is a object
            // and we need to get patientCount from it
            // We need to refactor this when removing the feature flag 'displayUnreadMessages'
            if (typeof stepsData?.[serviceStepName] === 'number') {
              serviceStepCount = stepsData?.[serviceStepName];
            } else if (stepsData?.[serviceStepName]?.patientCount) {
              serviceStepCount = stepsData?.[serviceStepName]?.patientCount;
            }

            return (
              <Styled.TabWrapper key={sector.id}>
                <Styled.TabLink
                  to={`${sector.route}`}
                  onClick={() => handleAmplitudeSubSteps(moduleAccess, stepActive, sector)}
                >
                  <Styled.TabText>
                    {sector.name}
                    &nbsp;
                    {serviceStepCount}
                  </Styled.TabText>
                  <StepsNotificationCircle countNotification={unreadChats} />
                </Styled.TabLink>
                {stepActive === 'telemedicine' &&
                  sector.slug === 'SCHEDULE' &&
                  imminentTeleconsultations && <Styled.Badge>!</Styled.Badge>}
              </Styled.TabWrapper>
            );
          })}
      </Styled.SubStepTabContainer>
    );
  }

  return null;
};

export default SubStepTabs;

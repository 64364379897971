import { RadioButtonInput, RadioButtonLabel } from './styled';

export default function RadioButton({ value, onChange, label, checked }) {
  const handleChange = (e) => {
    onChange(e, value);
  };
  return (
    <RadioButtonLabel>
      <RadioButtonInput type="radio" onChange={handleChange} checked={checked} />
      {label}
    </RadioButtonLabel>
  );
}

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Tooltip = styled(motion.div).attrs((props) => ({
  maxWidth: props.maxWidth,
}))`
  padding: 4px 8px;
  line-height: 1.15;
  background-color: #444444;
  pointer-events: none;
  border-radius: 3px;
  color: white;
  font-size: 13px;
  font-family: 'Roboto';
  z-index: 99999;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
    `}
`;

export const Text = styled.span``;

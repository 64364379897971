import { useState, useEffect, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import { mdiTextBoxPlusOutline } from '@mdi/js';

import * as Styled from './styled';

type OwnProps = {
  onChange: (...args: any[]) => any;
  multiple?: boolean;
  height?: number;
  maxSize?: number;
  dropLabel?: string;
  dragLabel?: string;
  actionLabel?: string;
  accept?: string | string[];
};

type Props = OwnProps & typeof Dropzone.defaultProps;

const Dropzone = ({
  onChange,
  accept,
  actionLabel,
  dragLabel,
  dropLabel,
  height,
  maxSize,
}: // multiple,
Props) => {
  const [files, setFiles] = useState([]);

  const removeFile = (fileName) =>
    setFiles((prevFiles) => prevFiles.filter((f) => f.name !== fileName));

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    // multiple,
    maxSize,
    accept,
    onDropAccepted: (acceptedFiles) => {
      // const currentFiles = [...files];
      // const tmpFiles = acceptedFiles.filter(
      //   (it) => !multiple || !Boolean(files && files.filter((f) => f.name === it.name).length)
      // );

      // setFiles(multiple && files ? currentFiles.concat(tmpFiles) : tmpFiles);
      setFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    onChange(files);
  }, [files, onChange]);

  return (
    <Styled.Dropzone {...getRootProps({ className: 'dropzone' })} height={height}>
      <input {...getInputProps()} />
      <Styled.Icon path={mdiTextBoxPlusOutline} color="#5b5b5b" size={1} />
      {Array.isArray(files) && files?.length ? (
        files.map((file, idx) => (
          <Fragment key={idx}>
            <Styled.File>{file.name}</Styled.File>
            <Styled.Link onClick={() => removeFile(file.name)}>Remover</Styled.Link>
          </Fragment>
        ))
      ) : isDragActive ? (
        <p>{dropLabel}</p>
      ) : (
        <>
          <p>{dragLabel} </p>
          <Styled.Link onClick={open}>{actionLabel}</Styled.Link>
        </>
      )}
    </Styled.Dropzone>
  );
};

Dropzone.defaultProps = {
  multiple: false,
  height: 109,
  maxSize: null,
  dragLabel: 'Arraste um arquivo aqui ou',
  dropLabel: 'Solte o arquivo aqui...',
  actionLabel: 'procure no seu computador',
  accept: null,
};

export default Dropzone;

import { useState } from 'react';
import LocalCache from 'Services/LocalCache';
import AtivatePrescriptionForm from './components/AtivatePrescriptionForm';
import AtivateTeleconsultationForm from './components/AtivateTeleconsultationForm';
import { ALLOW_TO_DOCTORS } from '../../../Utils/Config/RolesConfig';

export default function TelemedicineSettings() {
  const { enabled: isEnabled } = LocalCache.getModuleData('telemedicine');
  const [enabled, setEnabled] = useState(isEnabled || false);

  return (
    <div className="row">
      <div className="col-md-5">
        <AtivateTeleconsultationForm enabled={enabled} setEnabled={setEnabled} />
        {!ALLOW_TO_DOCTORS.includes(LocalCache.getUser().roleId) ? (
          <></>
        ) : (
          <AtivatePrescriptionForm enabled={enabled} />
        )}
      </div>
      {!ALLOW_TO_DOCTORS.includes(LocalCache.getUser().roleId) ? (
        <></>
      ) : (
        <div className="col-md-7">
          <div className="card">
            <div className="card-body">
              <h6>Requisitos</h6>
              <ul
                style={{
                  paddingLeft: 15,
                }}
              >
                <li>
                  <strong>Cédula de Identidade Médica</strong> (Para obter o CRM Digital (Cédula de
                  Identidade de Médico), o médico deve realizar a solicitação junto ao seu Conselho
                  Regional de Medicina. Procure o Conselho Regional no qual está inscrito para saber
                  sobre os procedimentos necessários para solicitação do CRM Digital.)
                </li>
                <li>
                  <strong>Certificado Digital</strong> (Pode ser obtido{' '}
                  <a
                    href="https://portal.cfm.org.br/crmdigital/#obtenha"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    aqui
                  </a>
                  ), pode ser em nuvem
                </li>
                <li>
                  Instalação do Certificado Digital no computador (mesmo certificados em nuvem)
                </li>
                <li>
                  Instalação da Extensão{' '}
                  <a
                    href="https://chrome.google.com/webstore/detail/web-pki/dcngeagmmhegagicpcmpinaoklddcgon"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Web PKI
                  </a>{' '}
                  no chrome
                </li>
              </ul>
              <hr />
              <h6>Prescrição via Memed</h6>
              <p>
                A prescrição é feita em parceria com a Memed, e abaixo listamos alguns tópicos para
                emitir a prescrição assinada corretamente
              </p>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card card-gray card-body">
                    <div className="media">
                      <div className="mr-3">
                        <i className="mdi mdi-file-account text-success-400 icon-2x mt-1" />
                      </div>
                      <div className="media-body">
                        <h6 className="media-title font-weight-semibold" style={{ marginTop: 14 }}>
                          <a
                            className="text-default"
                            href="https://suporte-medico.memed.com.br/hc/pt-br/articles/8562387518235-Como-eu-assino-digitalmente-pela-Memed"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Como Assinar digitalmente via Memed
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card card-gray card-body">
                    <div className="media">
                      <div className="mr-3">
                        <i className="mdi mdi-file-account text-success-400 icon-2x mt-1" />
                      </div>
                      <div className="media-body">
                        <h6 className="media-title font-weight-semibold" style={{ marginTop: 14 }}>
                          <a
                            className="text-default"
                            href="https://suporte-medico.memed.com.br/hc/pt-br/articles/8563200407195-Como-integrar-meu-certificado-v%C3%A1lido-para-qualquer-Certificadora-ICP-Brasil"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Como integrar meu certificado? (válido para qualquer Certificadora
                            ICP-Brasil)
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import * as Types from '../../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePatientMutationVariables = Types.Exact<{
  entityId: Types.Scalars['Int'];
  medicalRecord: Types.Scalars['String'];
  module: Types.Scalars['String'];
  personalData?: Types.InputMaybe<Types.PersonalDataInput>;
}>;


export type UpdatePatientMutation = { __typename?: 'Mutation', updatePatient?: string | null };


export const UpdatePatientDocument = gql`
    mutation updatePatient($entityId: Int!, $medicalRecord: String!, $module: String!, $personalData: PersonalDataInput) {
  updatePatient(
    entityId: $entityId
    medicalRecord: $medicalRecord
    module: $module
    personalData: $personalData
  )
}
    `;
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      medicalRecord: // value for 'medicalRecord'
 *      module: // value for 'module'
 *      personalData: // value for 'personalData'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
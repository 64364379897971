export const ColorDictionary = {
  Badge: {
    blue: {
      colorBadge: '#0071eb',
      color: '#0071eb',
      borderColor: '#0071eb',
      backgroundColor: '#CDDFF7',
      name: 'azul',
    },
    red: {
      colorBadge: '#EC2C47',
      color: '#EC2C47',
      borderColor: '#EC2C47',
      backgroundColor: '#FFCCD3',
      name: 'vermelho',
    },
    yellow: {
      colorBadge: '#F6C500',
      color: '#F6C500',
      borderColor: '#F6C500',
      backgroundColor: '#FFF7D5',
      name: 'amarelo',
    },
    green: {
      colorBadge: '#0F803F',
      color: '#0F803F',
      borderColor: '#0F803F',
      backgroundColor: '#CFE6D8',
      name: 'verde',
    },
    whatsapp: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'whatsapp',
    },
    grey: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'cinza',
    },
    gray: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'cinza',
    },
    custom: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'cinza',
    },
    evolution: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'evolução',
    },
    townhall: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'prefeitura',
    },
    hospital: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'hospital',
    },
    laura: {
      colorBadge: '#888888',
      color: '#ff881f',
      borderColor: '#ff881f',
      backgroundColor: '#ffd8d8',
      name: 'laura',
    },
    moved: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#888888',
      backgroundColor: '#dddddd',
      name: 'movido',
    },
    purple: {
      colorBadge: '#888888',
      color: '#C879F5',
      borderColor: '#C879F5',
      backgroundColor: '#F4E4FD',
      name: 'roxo',
    },
    none: {
      colorBadge: '#888888',
      color: '#888888',
      borderColor: '#8888fff88',
      backgroundColor: '#dddddd',
      name: '',
    },
    reminder: {
      colorBadge: '#fff',
      color: '#fff',
      borderColor: '#3F074A',
      backgroundColor: '#3F074A',
      name: 'Adiar',
    },
  },
  Colors: {
    title: '#034235',
    input: {
      error: '#DC3838',
    },
    toast: {
      default: '#0071eb',
      error: '#E83A3A',
      success: '#4BB84F',
      video: '#FF881F',
    },
  },
};

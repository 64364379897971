import { useMemo, Fragment } from 'react';
import { AnimatePresence } from 'framer-motion';

import { springSlide } from 'Utils/animations';
import { useChat } from 'Components/Chat/hooks/useChat';

import { Header, Item } from './components';

import * as Styled from './styled';

type Props = {
  chats: any[];
  handleChatToActive: (...args: any[]) => any;
  moduleAccess: string;
};

const Chat = ({ chats, handleChatToActive, moduleAccess }: Props) => {
  const { minimizedPreviousChat } = useChat();

  const getLastMessage = (list) => {
    return list[list?.length - 1];
  };

  const moduleChats = useMemo(
    () =>
      chats
        ?.filter((chat) => chat.moduleAccess === moduleAccess)
        .sort((chatA, chatB) => {
          if (chatA?.messages?.length > 1 && chatB?.messages?.length > 1) {
            const lastMessageChatA = getLastMessage(chatA?.messages);
            const lastMessageChatB = getLastMessage(chatB?.messages);

            return lastMessageChatB?.timestamp - lastMessageChatA?.timestamp;
          }

          if (chatA?.messages?.lenght) {
            return 1;
          }

          return -1;
        }),
    [chats, moduleAccess]
  );

  const variants = {
    normal: {
      height: window?.innerHeight > 815 ? 767 : 567,
    },
    minimized: {
      height: 46,
    },
  };

  if (!moduleChats?.length) {
    return null;
  }

  return (
    <AnimatePresence>
      <Styled.Container
        layout
        variants={variants}
        transition={springSlide}
        initial={minimizedPreviousChat ? 'minimized' : 'normal'}
        animate={minimizedPreviousChat ? 'minimized' : 'normal'}
      >
        <Header totalChats={moduleChats?.length} />
        <Styled.List>
          {moduleChats?.map((chat, index) => (
            <Fragment key={chat?.senderId}>
              <Item chat={chat} onClick={() => handleChatToActive(chat)} />
              {index !== moduleChats.length - 1 && <Styled.Divider />}
            </Fragment>
          ))}
        </Styled.List>
      </Styled.Container>
    </AnimatePresence>
  );
};

export default Chat;

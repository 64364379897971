import { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import Icon from '@mdi/react';
import {
  mdiDotsVertical,
  mdiChatProcessing,
  mdiAccountSupervisor,
  mdiChatPlus,
  mdiCompareVertical,
  mdiChat,
} from '@mdi/js';
import { useMutation } from '@apollo/client';

import DashboardModel from 'Models/DashboardModel';
import Datatable from 'Components/Datatable';
import Button from 'Components/Button';
import { exportDataToCsv } from 'Utils/Helpers/CsvExporter';
import RemoteAtendimentoActions from 'Components/Patient/Actions/RemoteAtendimentoActions';
import Modal from 'Components/Modal';
import ModalChat from 'Components/ModalChat';
import { useMoreActions } from 'Hooks/useMoreActions';
import DataContext from 'Context/dataContext';
import { DateAndTime, formatTo, compareDate } from 'Utils/Helpers/DateFormatterHelper';
import { numberMask } from 'Chat/utils/numberMask';
import { AlertWithBadgeAndPoint } from 'Components/Alert';
import useCache from 'Hooks/useCache';
import { DictionaryIntents } from './DictionaryIntents';
import ModuleContext from 'Context/moduleContext';
import cacheService from 'Services/cache';
import { SectorDictionary } from 'Utils/Dictionary/RemoteAtendimento/TeleAtendimento';
import ChatApp from 'Chat';

import CSV from '../Csv';

const exportCsvParams = [
  { column: 'Telefone', row: 'senderId' },
  { column: 'Nome', row: 'clients.name' },
  { column: 'CPF', row: 'clients.cpf' },
  { column: 'CEP', row: 'clients.zip' },
  { column: 'Veículo', row: 'clients.vehicle' },
  { column: 'E-Mail', row: 'clients.healthCare' },
  { column: 'Última interação', row: 'clients.createdAt' },
];

function ButtonImport({ ImportModal }) {
  const {
    store: { showModal, menuAction },
    dispatch: actionDispatch,
  } = useMoreActions();

  return (
    <>
      <Button
        className="patient-table__button"
        modifiers={['btnSmall', 'bgWhite', 'textBlue', 'btnFlex', 'marginBottom20', 'fontSize12']}
        onClick={() => {
          actionDispatch({ type: 'SHOW_MODAL', medicalRecord: 'asdasd' });
          actionDispatch({ type: 'CHANGE_ACTION', menuAction: 'IMPORT' });
        }}
      >
        <span>
          {' '}
          IMPORTAR CSV <i className="mdi mdi-file-excel-outline" />{' '}
        </span>
      </Button>
      {menuAction === 'IMPORT' &&
        ImportModal({ setShow: () => actionDispatch({ type: 'HIDE_MODAL' }), showModal })}
    </>
  );
}

function ButtonChatInit({ Status, remoteAtendimentoEvolutionRecord, user, DataDocuments }) {
  const [show, setShow] = useState(false);

  const setShowMemo = useMemo(() => setShow, [setShow]);
  let color = '#0071eb';

  switch (Status && Status.resultado) {
    case 'red':
      color = '#0071eb';
      break;
    case 'green':
      color = '#58b44e';
      break;
    case 'blue':
      color = '#0071eb';
      break;
    default:
      color = '#0071eb';
      break;
  }

  return (
    <>
      <Icon
        className="cursor-pointer"
        color={color}
        disabled={show}
        path={show ? mdiCompareVertical : mdiChatPlus}
        onClick={async () => {
          setShowMemo(true);
          await remoteAtendimentoEvolutionRecord({
            variables: {
              entityId: parseInt(user.entityId),
              sector: 'HUMAN',
              atendimentoId: DataDocuments.senderId,
              motivo: 'Status human',
              usuario: user.name,
              email: user.email,
              module: 'customer_service',
            },
          })
            .then(() => {
              setShowMemo(false);
            })
            .catch(() => {
              console.log('Error: ', {
                variables: {
                  entityId: parseInt(user.entityId),
                  sector: 'HUMAN',
                  atendimentoId: DataDocuments.senderId,
                  motivo: 'Status human',
                  usuario: user.name,
                  email: user.email,
                  module: 'customer_service',
                },
              });
            });
        }}
        size={1}
        title="Atendimento ao Cliente - HandOver"
      />
    </>
  );
}

function ButtonChat({ ChatModal, Status, DataDocuments }) {
  const [documentsRows, setdocumentsRows] = useState({});
  const {
    store: { showModal, menuAction, medicalRecord: actionMedicalRecord },
    dispatch: actionDispatch,
  } = useMoreActions();

  let color = '#0071eb';

  useEffect(() => {
    setdocumentsRows(DataDocuments);
  }, [DataDocuments]);

  switch (Status && Status.resultado) {
    case 'red':
      color = '#0071eb';
      break;
    case 'green':
      color = '#58b44e';
      break;
    case 'blue':
      color = '#0071eb';
      break;
    case 'yellow':
      color = '#ffc107';
      break;
    default:
      color = '#0071eb';
      break;
  }

  return (
    <>
      <Icon
        className="cursor-pointer"
        color={showModal ? '#0071eb' : color}
        onClick={() => {
          actionDispatch({ type: 'SHOW_MODAL', medicalRecord: DataDocuments.senderId });
          actionDispatch({ type: 'CHANGE_ACTION', menuAction: 'CHAT_MODAL' });
        }}
        path={
          Status && Status.resultado === 'red'
            ? DataDocuments.evolutionRecord.setor === 'ALL' ||
              DataDocuments.evolutionRecord.setor === 'CLOSED' ||
              DataDocuments.evolutionRecord.setor === 'HISTORY'
              ? mdiChat
              : mdiChatProcessing
            : mdiAccountSupervisor
        }
        size={1}
        title="Atendimento ao Cliente - HandOver"
      />
      {showModal &&
        menuAction === 'CHAT_MODAL' &&
        DataDocuments.senderId === actionMedicalRecord &&
        ChatModal({
          showModal,
          setShowMemo: () => actionDispatch({ type: 'HIDE_MODAL' }),
          documentsRows,
        })}
    </>
  );
}

function Action({ alertRecord, evolutionNote, medicalRecord, activeContact, renderModal }) {
  const {
    store: { showModal, menuAction, medicalRecord: actionMedicalRecord },
    dispatch: actionDispatch,
  } = useMoreActions();

  return (
    <>
      <Icon
        className="cursor-pointer"
        color={showModal ? '#0071eb' : '#7A7A7A'}
        onClick={() => {
          actionDispatch({ type: 'SHOW_MODAL', medicalRecord: alertRecord.senderId });
          actionDispatch({ type: 'CHANGE_ACTION', menuAction: 'ACTIONS' });
        }}
        path={mdiDotsVertical}
        size={1}
        title="Registrar Evolução do Atendimento"
      />
      {showModal &&
        menuAction === 'ACTIONS' &&
        alertRecord.senderId === actionMedicalRecord &&
        renderModal({
          alertRecord,
          evolutionNote,
          activeContact,
          medicalRecord,
          setShow: () => actionDispatch({ type: 'HIDE_MODAL' }),
          showModal,
        })}
    </>
  );
}

export default function Data({ sector }) {
  const [user] = useCache('user');
  const { stepActive, moduleAccess } = useContext(ModuleContext);
  const { data, loading, fetchData } = useContext(DataContext);

  const documentsData = useMemo(() => {
    if (data?.documentsRA?.records?.length > 0) {
      return data?.documentsRA.records;
    }
  }, [data]);

  const [remoteAtendimentoEvolutionRecord] = useMutation(
    DashboardModel.REMOTE_ATENDIMENTO_EVOLUTION_RECORD
  );

  const renderModal = useCallback(
    ({ setShow, medicalRecord, evolutionNote, activeContact, alertRecord, showModal }) => {
      const handleClose = () => setShow();

      return (
        <Modal isOpen={showModal} onClose={handleClose}>
          <RemoteAtendimentoActions
            actualSector={sector}
            alertRecord={alertRecord}
            callBack={(item) => {
              if (item === 'ActiveContactSend') {
                setShow();
              }
            }}
            evolutionNote={evolutionNote}
            medicalRecord={medicalRecord}
            activeContact={activeContact}
          />
          <Button
            modifiers={['btn', 'fullWidth', 'bgTransparent', 'textPrimary', 'marginTop20']}
            onClick={handleClose}
          >
            CANCELAR
          </Button>
        </Modal>
      );
    },
    [sector]
  );

  const ChatModal = useCallback(
    ({ showModal, setShowMemo, documentsRows }) => {
      if (showModal === true && cacheService.get('senderId') === null) {
        cacheService.set('senderId', documentsRows);
      } else if (showModal === true && cacheService.get('senderId') !== null) {
        documentsRows = cacheService.get('senderId');
      }

      return (
        <ModalChat
          initialOpen
          onClose={() => {
            cacheService.set('senderId', null);
            setShowMemo();
          }}
        >
          {() => {
            return (
              <>
                <ChatApp
                  callBack={async (setor) => {
                    await remoteAtendimentoEvolutionRecord({
                      variables: {
                        entityId: parseInt(user.entityId),
                        sector: setor,
                        atendimentoId: documentsRows?.senderId,
                        motivo: 'Status human',
                        usuario: user.name,
                        email: user.email,
                        module: 'customer_service',
                        name: 'Enviado',
                      },
                    }).catch(() => {
                      console.log('Error: ', {
                        variables: {
                          entityId: parseInt(user.entityId),
                          sector: setor,
                          atendimentoId: documentsRows?.senderId,
                          motivo: 'Status human',
                          usuario: user.name,
                          email: user.email,
                          module: 'customer_service',
                        },
                      });
                    });
                  }}
                  data={{
                    sector: documentsRows?.evolutionRecord.setor,
                    senderId: documentsRows?.senderId,
                    name: documentsRows?.clients.name,
                    alert: documentsRows?.alert.resultado,
                    company: 'nissan',
                  }}
                />
              </>
            );
          }}
        </ModalChat>
      );
    },
    [remoteAtendimentoEvolutionRecord, user]
  );

  const ImportModal = useCallback(({ setShow, showModal }) => {
    return (
      <Modal isOpen={showModal} onClose={setShow}>
        <CSV />
      </Modal>
    );
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   Header: '',
      //   accessor: 'Checkbox',
      //   width: 200,
      //   Cell: ({ row }) => <input type="checkbox" id={row.original.senderId} name="sendersIds" />,
      // },
      {
        Header: 'Telefone',
        accessor: 'senderId',
        width: 200,
        Cell: ({ row }) => (
          <strong style={{ color: '#000' }}>{numberMask(row.original.senderId)}</strong>
        ),
      },
      {
        Header: 'Nome',
        accessor: 'clients.name',
        width: 200,
        collapse: true,
        Cell: ({ row }) => <strong style={{ color: '#000' }}> {row.original.clients.name}</strong>,
      },
      {
        Header: 'Intenção',
        accessor: 'intent',
        width: 100,
        collapse: true,
        Cell: ({ row }) => {
          const getIntent = row.original.clients.intent;
          const dictionary = DictionaryIntents[0][getIntent] || DictionaryIntents[0]['no'];
          return <>{dictionary}</>;
        },
      },
      {
        Header: 'CEP',
        accessor: 'clients.zip',
        width: 100,
      },
      {
        Header: 'Veículo',
        accessor: 'clients.vehicle',
        width: 100,
      },
      {
        Header: 'CPF',
        accessor: 'clients.cpf',
        width: 15,
        collapse: true,
      },
      {
        Header: 'E-Mail',
        accessor: 'clients.email',
        width: 230,
      },
      {
        Header: 'Última interação',
        accessor: 'updateAt',
        width: 180,
        Cell: ({ row }) => (
          <AlertWithBadgeAndPoint
            alert={row.original.alert}
            data={formatTo(row.original.medicalRecord.updateAt, DateAndTime.datetime)}
            updatedAt={compareDate(row.original.medicalRecord.updateAt)}
            sector={row.original.evolutionRecord.setor}
          />
        ),
      },
      {
        Header: 'Chat',
        id: '',
        width: 65,
        hide:
          sector.slug === SectorDictionary.TELEATENDIMENTO_IMPORT.slug ||
          sector.slug === SectorDictionary.TELEATENDIMENTO_CONTACT_ACTIVE.slug ||
          sector.slug === SectorDictionary.TELEATENDIMENTO_OUTSIDE.slug,
        Cell: ({ row }) =>
          row.original.evolutionRecord.setor === 'AWAITING' ? (
            <ButtonChatInit
              Status={row.original.alert}
              remoteAtendimentoEvolutionRecord={remoteAtendimentoEvolutionRecord}
              user={user}
              DataDocuments={row.original}
            />
          ) : (
            <ButtonChat
              ChatModal={ChatModal}
              Status={row.original.alert}
              DataDocuments={row.original}
            />
          ),
      },
      {
        Header: '',
        id: 'action',
        width: 24,
        Cell: ({ row }) => (
          <Action
            alertRecord={row.original}
            evolutionNote={row.original.evolutionNote}
            medicalRecord={row.original.medicalRecord}
            activeContact={compareDate(row.original.medicalRecord.updateAt)}
            renderModal={renderModal}
          />
        ),
      },
      {
        Header: '',
        accessor: 'search',
        width: 0,
        Cell: () => null,
      },
    ],
    [sector.slug, remoteAtendimentoEvolutionRecord, user, ChatModal, renderModal]
  );

  return (
    <>
      <div className="text-right">
        {sector.slug === 'IMPORT' ? (
          <ButtonImport ImportModal={ImportModal} />
        ) : (
          <Button
            className="patient-table__button"
            disabled={!documentsData}
            modifiers={[
              'btnSmall',
              'bgWhite',
              'textBlue',
              'btnFlex',
              'marginBottom20',
              'fontSize12',
            ]}
            onClick={() =>
              exportDataToCsv(
                (allStepData) => prepareData(allStepData, sector),
                exportCsvParams,
                `${sector.name}.csv`,
                stepActive,
                moduleAccess,
                sector.name
              )
            }
          >
            <span>
              {' '}
              EXPORTAR P/ EXCEL <i className="mdi mdi-file-excel-outline" />{' '}
            </span>
          </Button>
        )}
      </div>
      <Datatable
        columns={columns}
        data={documentsData?.length ? prepareData(documentsData, sector) : []}
        loading={loading}
        fetchData={fetchData}
        totalData={data?.documentsRA?.totalDocuments}
        totalPages={data?.documentsRA?.totalPages}
        cacheKey="tele-atendimento"
      />
    </>
  );
}

function prepareData(data, sector) {
  return data
    .filter((d) => sector.rule(d))
    .map((d) => ({
      ...d,
      intent: DictionaryIntents[0][d.clients.intent] || DictionaryIntents[0]['no'],
      updateAt: formatTo(d.medicalRecord.updateAt, DateAndTime.datetime),
      clients: {
        ...d.clients,
        name: d.clients.name,
      },
      search: `
        ${compareDate(d.medicalRecord.updateAt) === 0 ? 'janela' : '24h+'}
        ${d.evolutionRecord.email}
        ${numberMask(d.senderId)}

        // Intent,Veiculo, data
        ${DictionaryIntents[0][d.clients.intent] || DictionaryIntents[0]['no']},${
        d.clients.vehicle
      },${formatTo(d.medicalRecord.updateAt, DateAndTime.datetime)}
        // email,intent, veiculo, data
        ${d.evolutionRecord.email},${
        DictionaryIntents[0][d.clients.intent] || DictionaryIntents[0]['no']
      },${d.clients.vehicle},${formatTo(d.medicalRecord.updateAt, DateAndTime.datetime)}
        // data,email, intent, veiculo
        ${formatTo(d.medicalRecord.updateAt, DateAndTime.datetime)},${d.evolutionRecord.email},${
        DictionaryIntents[0][d.clients.intent] || DictionaryIntents[0]['no']
      },${d.clients.vehicle}
        `,
    }));
}

import { formatTo } from 'Utils/Helpers/dateFnsHelper';
import { DATE_FORMAT } from 'Utils/types';

import { useLocation, useHistory } from 'react-router-dom';
import routes from 'routes';
import router from 'Utils/Helpers/router';

import { Timeline } from '../../styled';

const TimelineMonitoringOnHold = ({
  timelineItem,
  interruptedModule,
  interruptedModuleLink,
  shouldShow,
}) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      {shouldShow ? (
        <Timeline.Body>
          <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }}>
            <Timeline>
              <Timeline.Head>
                <Timeline.Item>
                  <strong>Acompanhamento Iniciado</strong>
                </Timeline.Item>
                <Timeline.Item>
                  <strong>
                    {timelineItem ? formatTo(timelineItem, DATE_FORMAT.datetime_seconds) : ''}
                  </strong>
                </Timeline.Item>
              </Timeline.Head>
              <Timeline.Item>Paciente respondeu com SIM etapa de aceite do programa</Timeline.Item>
              <Timeline.Button
                onClick={() => {
                  history.push(
                    router.to(routes.TIMELINE.path, {
                      module: interruptedModuleLink,
                      medicalRecord: location?.pathname?.split('/')[3],
                    })
                  );
                }}
              >
                <strong>Ver Timeline {interruptedModule}</strong>
              </Timeline.Button>
            </Timeline>
          </Timeline.Body.Item>
        </Timeline.Body>
      ) : null}
    </>
  );
};

export default TimelineMonitoringOnHold;

import { createActions, createReducer, resettableReducer } from 'reduxsauce';
import moment from 'moment';

// define as ações
export const { Types: ModalScheduleReminderTypes, Creators: ModalScheduleReminderActions } =
  createActions({
    modalScheduleSetIsOpen: ['reminder', 'isOpen', 'document', 'isUpdate'],
    modalScheduleSetDate: ['date'],
    modalScheduleSetInterval: ['interval'],
    modalScheduleSetStep: ['step'],
    modalScheduleReset: null,
  });

// define o estado inicial
const INITIAL_STATE = {
  reminder: false,
  isOpen: false,
  date: null,
  interval: null,
  step: 0,
  document: null,
  isUpdate: false,
};

/**
 * Atualiza se a modal está aberta ou não
 * @param {{}} state
 * @param {{}} payload
 */
const modalScheduleSetIsOpen = (
  state: Record<string, any>,
  { reminder, isOpen, document, isUpdate }: Record<string, any>
) => ({
  ...state,
  reminder,
  isOpen,
  document,
  isUpdate,
});

/**
 * Atualiza a data selecionada
 * @param {{}} state
 * @param {{}} payload
 */
const modalScheduleSetDate = (state: Record<string, any>, { date }: Record<string, any>) => ({
  ...state,
  date: moment(date).utc(-3).toDate(),
  interval: INITIAL_STATE.interval,
});

/**
 * Atualiza o horário selecionado para a data
 * @param {{}} state
 * @param {{}} payload
 */
const modalScheduleSetInterval = (
  state: Record<string, any>,
  { interval }: Record<string, any>
) => ({
  ...state,
  interval: {
    startTime: interval.startTime,
    endTime: interval.endTime,
    scheduledConsultations: interval.scheduledConsultations,
  },
});

/**
 * Atualiza o passo da modal
 * @param {{}} state
 * @param {{}} payload
 */
const modalScheduleSetStep = (state: Record<string, any>, { step }: Record<string, any>) => ({
  ...state,
  step: step < 0 || step > 1 ? state.step : step,
});

// define o reducer
const reducer = createReducer(INITIAL_STATE, {
  [ModalScheduleReminderTypes.MODAL_SCHEDULE_SET_IS_OPEN]: modalScheduleSetIsOpen,
  [ModalScheduleReminderTypes.MODAL_SCHEDULE_SET_DATE]: modalScheduleSetDate,
  [ModalScheduleReminderTypes.MODAL_SCHEDULE_SET_INTERVAL]: modalScheduleSetInterval,
  [ModalScheduleReminderTypes.MODAL_SCHEDULE_SET_STEP]: modalScheduleSetStep,
});

// torna o reducer atualizado
export default resettableReducer(ModalScheduleReminderTypes.MODAL_SCHEDULE_RESET)(reducer);

import { gql } from '@apollo/client';

import { ConversationsFields } from '../fragments';

export const GET_BOT_CONVERSATIONS = gql`
  query GetBotConversations($senderId: String!, $page: Int!, $limit: Int!, $filter: [String]) {
    getBotConversations(senderId: $senderId, page: $page, limit: $limit, filter: $filter) {
      ...ConversationsFields
    }
  }
  ${ConversationsFields}
`;

export const GET_HANDOVER = gql`
  query GetHandover($senderId: String!, $entityId: Int!, $module: String, $medicalRecord: String) {
    getHandover(
      entityId: $entityId
      senderId: $senderId
      module: $module
      medicalRecord: $medicalRecord
    )
  }
`;

export const GET_ACTIVE_CONTACT_TEMPLATES = gql`
  query GetActiveContactTemplates(
    $entityId: Int!
    $module: String!
    $sector: String!
    $variables: ParamsContactTemplates!
    $monitoring_type: [String]!
  ) {
    getActiveContactTemplates(
      entityId: $entityId
      module: $module
      sector: $sector
      variables: $variables
      monitoring_type: $monitoring_type
    ) {
      name
      message
      variables
      category
      description
    }
  }
`;

export const StatusDictionary = {
  CRITIC: {
    slug: 'CRITIC',
    name: 'Em estado Crítico',
    relative: null,
    id: '1',
  },
  OBSERVATION: {
    slug: 'OBSERVATION',
    name: 'Em Observação no PA',
    relative: null,
    id: '2',
  },
  NOT_CRITIC: {
    slug: 'NOT_CRITIC',
    name: 'Sem Criticidade',
    relative: null,
    id: '3',
  },
  NO_SHOW: {
    slug: 'NO_SHOW',
    name: 'Não compareceu',
    relative: null,
    id: '4',
  },
  AWAITING_EXAMS: {
    slug: 'AWAITING_EXAMS',
    name: 'Aguardando exame',
    relative: 3,
    id: '5',
  },
  ORIENTED_SYMPTOMS: {
    slug: 'ORIENTED_SYMPTOMS',
    name: 'Orientação de sintomas',
    relative: 3,
    id: '6',
  },
  ORIENTED_ISOLATION: {
    slug: 'ORIENTED_ISOLATION',
    name: 'Orientação de isolamento',
    relative: 3,
    id: '7',
  },
  CRITICAL_SYMPTOM: {
    slug: 'CRITICAL_SYMPTOM',
    name: 'Sintomas Críticos',
    relative: null,
    id: '8',
  },
  BLOCKED: {
    slug: 'BLOCKED',
    name: 'Pausado',
    relative: null,
    id: '9',
  },
};
export const StatusId = {
  1: StatusDictionary.CRITIC,
  2: StatusDictionary.OBSERVATION,
  3: StatusDictionary.NOT_CRITIC,
  4: StatusDictionary.NO_SHOW,
  5: StatusDictionary.AWAITING_EXAMS,
  6: StatusDictionary.ORIENTED_SYMPTOMS,
  7: StatusDictionary.ORIENTED_ISOLATION,
  8: StatusDictionary.CRITICAL_SYMPTOM,
  9: StatusDictionary.BLOCKED,
};

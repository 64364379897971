import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ServiceSteps = styled.div`
  display: flex;
`;

export const Flex = styled.div.attrs((props) => ({
  justify: props.justify || 'center',
}))`
  display: flex;
  width: 100%;

  ${({ justify }) => css`
    justify-content: ${justify};
  `}
`;

import '@szhsin/react-menu/dist/index.css';

import * as Styled from './styled';

type OwnProps = {
  items: {
    label: string;
    icon: string;
    onClick?: (...args: any[]) => any;
    href?: string;
  }[];
  activeColor?: string;
};

type Props = OwnProps & typeof Burgermenu.defaultProps;

const Burgermenu = ({ items, activeColor }: Props) => {
  return (
    <Styled.Menu
      menuButton={({ open }) => (
        <Styled.Container active={open} activeColor={activeColor}>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </Styled.Container>
      )}
      align="end"
    >
      {items
        .filter((e) => e.visible)
        .map(({ onClick, label, href, external = false, icon }) => {
          const props = { ...(onClick ? { onClick } : {}), ...(external && href ? { href } : {}) };

          return (
            <Styled.MenuItem {...props} key={label}>
              <Styled.Icon size={0.9} path={icon} />
              {label}
            </Styled.MenuItem>
          );
        })}
    </Styled.Menu>
  );
};

Burgermenu.defaultProps = {
  activeColor: '#0071eb',
};

export default Burgermenu;

import { useState } from 'react';
import useEventListener from './useEventListener';

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  const updateSize = () => {
    return setSize([window.innerWidth, window.innerHeight]);
  };

  useEventListener('resize', updateSize, window);

  return size;
};

export default useWindowSize;

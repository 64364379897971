import { useCallback, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import Dialog from 'Components/Dialog';
import Button from 'Components/NewButton';
import Toast from 'Components/Toast';

import PractitionerForm from './PractitionerForm';

export const editPractitionerUserMutation = gql`
  mutation editPractitionerUser($input: EditUserInput!) {
    editUser(input: $input) {
      success
      error
    }
  }
`;

const defaultSuccessMessage = 'Dados do profissional editados com sucesso';
const defaultErrorMessage = 'Não foi possível editar os dados do profissional';

const EditPractitionerContent = ({ isOpen, onClose, onSuccess, user }) => {
  const [loading, setLoading] = useState(false);
  const formContext = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      personalId: user.personalId,
    },
  });
  const { handleSubmit, reset, formState } = formContext;

  const [editPractitioner] = useMutation(editPractitionerUserMutation, {
    onCompleted: (data) => {
      const { success, error } = data.editUser;

      if (error || !success) {
        toast(<Toast type="error" title={defaultErrorMessage} subtitle={error} />, {
          autoClose: false,
        });

        setLoading(false);
        return;
      }

      toast(<Toast type="success" title={defaultSuccessMessage} />, {
        autoClose: false,
      });

      onSuccess();
      onClose();
      reset();
    },
    onError: (err) => {
      toast(<Toast type="error" title={defaultErrorMessage} subtitle={err.message} />, {
        autoClose: false,
      });

      setLoading(false);
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const input = {
        userId: user.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        personalId: data.personalId?.replace(/\D/g, ''),
      };

      editPractitioner({ variables: { input } });
    },
    [editPractitioner, user.id]
  );

  return (
    <FormContext {...formContext}>
      <form className="form-style-1" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Dialog isOpen={isOpen} onClose={onClose} backdrop width="732px">
          <Dialog.Header>
            <Dialog.Title>Editar dados</Dialog.Title>
            <Dialog.Subtitle>Atualize os dados de um profissional</Dialog.Subtitle>
          </Dialog.Header>

          <Dialog.Body>
            <PractitionerForm editOnly />
          </Dialog.Body>

          <Dialog.Footer>
            <Button maxWidth={120} fullWidth variant="link" onClick={onClose} type="button">
              Cancelar
            </Button>

            <Button
              maxWidth={120}
              fullWidth
              type="submit"
              disabled={loading || formState.isSubmitting || !formState.isValid}
              loading={loading || formState.isSubmitting}
            >
              Confirmar
            </Button>
          </Dialog.Footer>
        </Dialog>
      </form>
    </FormContext>
  );
};

const EditPractitionerModal = ({ user, ...props }) => {
  if (!user) return null;

  return <EditPractitionerContent user={user} {...props} />;
};

export default EditPractitionerModal;

const baseStyle = {
  fontFamily: 'heading',
  fontWeight: 'bold',
  color: 'gray.500',
};

const sizes = {
  lg: {
    fontSize: '2xl',
    lineHeight: 1.33,
  },
  md: {
    fontSize: 'xl',
    lineHeight: 1.2,
  },
  sm: {
    fontSize: 'md',
    lineHeight: 1.2,
  },
  xs: {
    fontSize: 'sm',
    lineHeight: 1.2,
  },
};

export const headingTheme = {
  baseStyle,
  sizes,
  defaultProps: {
    size: 'xl',
  },
};

// import React from 'react';
import styled from 'styled-components';
import { applyStyleModifiers, styleModifierPropTypes } from 'styled-components-modifiers';
import { modifierConfig } from '../../Utils/Config/modifierConfig';

export const Button = styled.button`
  ${applyStyleModifiers(modifierConfig)};
  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
`;

Button.propTypes = {
  modifiers: styleModifierPropTypes(modifierConfig),
};

Button.defaultProps = {
  type: 'button',
};

export default Button;

import { useContext, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { Input, InputMaskControl } from 'Components';
import Modal from 'Components/Modal';
import Button from 'Components/NewButton';
import Toast from 'Components/Toast';
import ModuleContext from 'Context/moduleContext';
import useCache from 'Hooks/useCache';
import { formatTo } from 'Utils/Helpers/dateFnsHelper';
import cellphone from 'Utils/Helpers/validate/cellphone';
import birthdate from 'Utils/Helpers/validate/birthdate';
import { rules, rulesMessages } from 'Utils/Helpers/Validator';
import { DATE_FORMAT } from 'Utils/types';

import { Styled } from '../Actions/styled';

import {
  UpdatePatientMutationVariables,
  useUpdatePatientMutation,
} from './__generated__/index.generated';

export const updatePatientMutation = gql`
  mutation updatePatient(
    $entityId: Int!
    $medicalRecord: String!
    $module: String!
    $personalData: PersonalDataInput
  ) {
    updatePatient(
      entityId: $entityId
      medicalRecord: $medicalRecord
      module: $module
      personalData: $personalData
    )
  }
`;

type Patient = {
  __typename?: string;
  name?: string | null;
  cpf?: string | null;
  age?: string | null;
  rgh?: string | null;
  email?: string | null;
  gender?: string | null;
  dataNascimento?: string | null;
  telefone?: string | null;
  neighborhood?: string | null;
  bond?: string | null;
  city?: string | null;
  healthCare?: string | null;
  healthCard?: string | null;
  weight?: string | null;
  height?: string | null;
  imc?: string | null;
  ubs?: string | null;
  nome?: string | null;
  bairro?: string | null;
  estado?: string | null;
  cep?: string | null;
  cidade?: string | null;
};

type UpdatePatientProps = {
  medicalRecord: string;
  callback?: () => void;
  alertRecord: { patient: Patient };
  onCloseModal: () => void;
};

const UpdatePatient = ({
  medicalRecord,
  callback,
  alertRecord,
  onCloseModal,
}: UpdatePatientProps) => {
  const [patient, setPatient] = useState<Patient>({});
  const [validation, setValidation] = useState({ input: '', validating: false });

  const { moduleAccess } = useContext(ModuleContext);

  const { register, handleSubmit, errors, reset, control, formState } = useForm({
    mode: 'onChange',
  });

  const [user] = useCache('user');

  const [updatePatient, { loading }] = useUpdatePatientMutation({
    onCompleted: () => {
      toast(
        <Toast
          type="success"
          title="Edição nos dados do paciente realizada com sucesso"
          subtitle="Os dados do paciente foram alterados, e já podem ser visualizado na Timeline."
        />
      );

      reset({
        dataNascimento: '',
        telefone: '',
        email: '',
        nome: '',
      });
      setPatient({});
      callback?.();
    },
    onError: (error) => {
      toast(
        <Toast
          type="error"
          title="Não foi possível editar os dados do paciente"
          subtitle={error.message}
        />
      );
    },
  });

  const onCancel = () => {
    if (validation.validating) {
      setValidation((state) => ({ ...state, validating: false }));
      reset({ ...patient });
      return;
    }

    onCloseModal();
  };

  const onSubmit = async (data) => {
    if (!validation.validating) {
      setValidation((state) => ({ ...state, validating: true }));
      setPatient({ ...data });
      return;
    }

    const updatedPersonalData = {
      ...alertRecord.patient,
      ...patient,
      dataNascimento: birthdate.parseDate(patient.dataNascimento),
    };
    delete updatedPersonalData.__typename;

    const variables: UpdatePatientMutationVariables = {
      entityId: Number(user.entityId),
      medicalRecord,
      module: moduleAccess,
      personalData: updatedPersonalData,
    };

    await updatePatient({ variables });
  };

  useEffect(() => {
    if (alertRecord) {
      reset({
        ...alertRecord.patient,
        dataNascimento: alertRecord.patient.dataNascimento
          ? formatTo(alertRecord.patient.dataNascimento, DATE_FORMAT.date)
          : '',
      });
    }
  }, [alertRecord]);

  return (
    <>
      <Modal.Title>
        {validation.validating ? 'Deseja realmente editar os dados?' : 'Edição dos dados'}
      </Modal.Title>
      <Modal.Subtitle>
        {validation.validating
          ? 'Confira bem todas as informações antes de confirmar \n qualquer alteração'
          : 'Verifique bem se está editando o paciente correto'}
      </Modal.Subtitle>
      <Styled.Separator height={44} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {validation.validating ? (
          <div className="row">
            <div className="col-12">
              <label style={{ fontSize: 14, color: '#F24C63' }}>
                Para confirmar a alteração digite a palavra <b>{'"ALTERAR"'}</b>
              </label>
              <Input
                name="validationInput"
                placeholder="ALTERAR"
                type="text"
                value={validation.input}
                style={{ borderColor: '#F24C63', borderRadius: 5 }}
                onChange={(event) =>
                  setValidation((state) => {
                    state.input = event.target.value;
                    return { ...state };
                  })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-7">
                <Input
                  name="nome"
                  id="name"
                  label="Nome do paciente"
                  type="text"
                  ref={register({ required: 'Favor informar o Nome.' })}
                  error={errors?.nome}
                  errormessage={errors?.nome?.message}
                />
              </div>
              <div className="col-5">
                <InputMaskControl
                  type="text"
                  id="birthDate"
                  label="Data de nascimento"
                  name="dataNascimento"
                  control={control}
                  mask="99/99/9999"
                  error={errors?.dataNascimento}
                  errormessage={errors?.dataNascimento?.message}
                  rules={{
                    required: rulesMessages.date.required,
                    validate: (v) => birthdate.isValid(v),
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <InputMaskControl
                  type="text"
                  error={errors?.telefone}
                  errormessage={errors?.telefone?.message}
                  label="Telefone"
                  name="telefone"
                  id="phone"
                  control={control}
                  mask="(99) 99999-9999"
                  rules={{
                    required: rulesMessages.phone.required,
                    validate: (v) => Boolean(cellphone.isValid(v)) || rulesMessages.phone.custom,
                  }}
                />
              </div>
              <div className="col-7">
                <Input
                  name="email"
                  label="E-mail"
                  type="text"
                  ref={register({
                    pattern: {
                      value: rules.email,
                      message: rulesMessages.email.pattern,
                    },
                  })}
                  error={errors?.email}
                  errormessage={errors?.email?.message}
                />
              </div>
            </div>
          </>
        )}
        <Styled.Flex direction="row-reverse">
          <Button
            disabled={
              validation.validating
                ? validation.input !== 'ALTERAR'
                : formState.isSubmitting || !formState.isValid
            }
            maxWidth={128}
            loading={loading}
            fullWidth
            background={validation.validating ? '#444444' : undefined}
            type="submit"
            style={{ marginTop: '15px' }}
          >
            Confirmar
          </Button>
          <Styled.Separator width={24} />
          <Button
            maxWidth={128}
            disabled={loading}
            fullWidth
            variant={validation.validating ? 'contained' : 'link'}
            onClick={onCancel}
            style={{ marginTop: '15px' }}
            type="button"
          >
            {validation.validating ? 'Verificar' : 'Cancelar'}
          </Button>
        </Styled.Flex>
      </form>
    </>
  );
};

export default UpdatePatient;

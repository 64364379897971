import styled, { css, keyframes } from 'styled-components';
import IconBase from '@mdi/react';
import RcSelect, { components } from 'react-select';
import RcAsyncSelect from 'react-select/async';
import RcCreatableSelect from 'react-select/creatable';
import RcAsyncCreatableSelect from 'react-select/async-creatable';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-.3rem);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 1;
    transform: translateY(-.3rem);
  }
`;

const customStyles = css`
  cursor: default;

  .react-select__control--menu-is-open,
  .react-select__control--is-focused {
    border: 1px solid #707070 !important;
    &:hover {
      border: 1px solid #707070;
    }
  }

  .react-select__control {
    font-size: 14px;
    cursor: ${(props) => (props.openMenuOnClick ? 'pointer' : 'default')};
    border: 1px solid #707070;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    width: 100%;
    min-height: auto;

    &:hover {
      border: 1px solid #707070;
    }

    .react-select__placeholder {
      color: #444444;
    }

    .react-select__single-value {
      color: #444444;
    }

    .react-select__value-container {
      ${({ isSearchable }) => css`
        padding: ${isSearchable ? 6 : 10}px 10px;
      `}
    }

    .react-select__indicators {
      padding-right: 10px;

      /* .react-select__clear-indicator {
        color: #0071eb;
      } */

      .react-select__dropdown-indicator {
        padding-right: 10px;
      }

      .react-select__indicator-separator {
        display: none;
      }
    }
  }

  .react-select__multi-value {
    background-color: #f4f5f8;
    border-radius: 5px;
    color: #c4c5d6;
  }

  .react-select__multi-value__remove {
    color: #ef4f6b;
    cursor: pointer;

    &:hover {
      background-color: #ef4f6b !important;
      color: #fff;
    }
  }

  .react-select__menu {
    z-index: 9999;
    font-size: 14px;
    color: #444444;
    background: #fff;
    cursor: pointer;
    line-height: 17px;
    box-shadow: 0px 3px 6px #00000029;
    max-height: initial !important;
    border-radius: 5px;
    animation: ${(props) => (props.active ? fadeOut : fadeIn)} 0.15s ease-in-out;

    .react-select__menu-list {
      border-radius: 5px;
      padding: 0px;

      .react-select__option {
        height: 41px;
        display: flex;
        align-items: center;
      }

      .react-select__option--is-focused {
        background: transparent;
        cursor: pointer;
      }

      .react-select__option--is-selected {
        background: #f4f5f7;
        font-weight: 600;
        color: #444444;
        border-radius: 5px;
      }

      .react-select__option--is-disabled {
        cursor: default;

        &:hover {
          background: transparent;
        }
      }

      ::-webkit-scrollbar {
        width: 0.4em;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #ebedf2;
      }

      ::-webkit-scrollbar-thumb:hover {
        border-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
  }

  .react-select__control--is-disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

const sharedProps = (props) => ({
  isSearchable: props.isSearchable,
  openMenuOnClick: props.openMenuOnClick,
});

export const SelectWrapper = styled(RcSelect).attrs(sharedProps)`
  ${customStyles}
`;

export const AsyncSelectWrapper = styled(RcAsyncSelect).attrs(sharedProps)`
  ${customStyles}
`;

export const CreatableSelectWrapper = styled(RcCreatableSelect).attrs(sharedProps)`
  ${customStyles}
`;

export const AsyncCreatableSelectWrapper = styled(RcAsyncCreatableSelect).attrs(sharedProps)`
  ${customStyles}
`;

export const Container = styled.div.attrs((props) => ({
  width: props.width || '100%',
}))`
  display: flex;
  flex-direction: column;

  ${({ width }) => css`
    width: ${typeof width === 'number' ? `${width}px` : width};
  `}
`;

export const Label = styled.span`
  font-size: 14px;
  height: 17px;
  color: #444444;
  margin-bottom: 5px;
`;

export const Control = styled(components.Control).attrs(sharedProps)`
  cursor: ${(props) => (props.openMenuOnClick ? 'pointer' : 'default')};
`;

export const Option = styled(components.Option)`
  display: flex;
  align-items: center;
`;

export const Icon = styled(IconBase)`
  margin-right: 8px;
`;

export const SingleValue = styled.div`
  display: flex;
  align-items: center;
  color: #444444;
`;

export const ChevronDown = styled.div`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #0071eb;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
  height: 12px;
  width: 12px;
  cursor: pointer;
`;

import styled, { css } from 'styled-components';

const TextEllipsis = styled.div.attrs((props) => ({
  width: props.width,
}))`
  ${({ width }) => css`
    width: ${width}px;
    overflow: hidden;
    white-space: nowrap;
  `}

  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export default TextEllipsis;

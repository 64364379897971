import * as Styled from './styled';

type OwnProps = {
  active: boolean;
  activeColor?: string;
  icon: {
    path?: string;
    activePath?: string;
    color?: string;
  };
  label: string;
  onClick: (...args: any[]) => any;
};

type Props = OwnProps & typeof Option.defaultProps;

const Option = ({ active, icon, label, onClick, value, activeColor }: Props) => {
  const handleClick = () => {
    onClick(value);
  };

  return (
    <Styled.Container active={active} activeColor={activeColor} onClick={handleClick}>
      {icon.image ? (
        <Styled.Image src={active ? icon.activePath : icon.path} />
      ) : (
        <Styled.Icon
          path={active ? icon.activePath : icon.path}
          active={active.toString()}
          color={active ? 'white' : icon.color || '#0071eb'}
          size={1}
        />
      )}
      <Styled.LabelContiner>
        <Styled.Title>{label}</Styled.Title>
      </Styled.LabelContiner>
    </Styled.Container>
  );
};

Option.defaultProps = {
  activeColor: '#0071eb',
};

export default Option;

import {
  forwardRef,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  HelpTextProps,
  FormErrorMessageProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type FormSupportingTextProps = {
  helpText?: string | ReactNode;
  errorText?: string | ReactNode;
};

export const FormSupportingText = forwardRef<
  (HelpTextProps | FormErrorMessageProps) & FormSupportingTextProps,
  'div'
>(({ errorText, helpText, ...props }, ref) => {
  if (errorText) {
    return (
      <FormErrorMessage ref={ref} {...props}>
        <FormErrorIcon />
        {errorText}
      </FormErrorMessage>
    );
  }

  if (helpText) {
    return (
      <FormHelperText ref={ref} {...props}>
        {helpText}
      </FormHelperText>
    );
  }

  return (
    <FormHelperText ref={ref} {...props}>
      &nbsp;
    </FormHelperText>
  );
});

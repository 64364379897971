import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Input, Select } from 'Components';
import LocalCache from 'Services/LocalCache';
import { rules, rulesMessages } from 'Utils/Helpers/Validator';
import PrescriptionModel from 'Models/PrescriptionModel';
import { toast } from 'react-toastify';
import Toast from 'Components/Toast';
import MemedContext from 'Context/memedContext';
import UFDictionary from 'Utils/Dictionary/UFDicionary';

export default function AtivatePrescriptionForm({ enabled }) {
  const { register, handleSubmit, errors, formState } = useForm({ mode: 'onChange' });
  const { setMemedToken } = useContext(MemedContext);
  const { crm, uf, token } = LocalCache.getModuleData('telemedicine');
  const [loading, setLoading] = useState(false);
  const [registeredToken, setRegisteredToken] = useState(token || null);
  const [ativePrescriptionUserUpdate] = useMutation(PrescriptionModel.ATIVE_PRESCRIPTION);

  const onSubmit = (data) => {
    const { crm, uf } = data;
    setLoading(true);
    ativePrescriptionUserUpdate({
      variables: {
        email: LocalCache.getUser().email,
        enabled: true,
        crm,
        uf,
      },
    })
      .then((data) => {
        toast(<Toast type="success" title={'Prescrição ativa com sucesso.'} />, {
          autoClose: false,
        });

        const moduleData = JSON.parse(data.data.prescriptionUserUpdate);

        LocalCache.setUser({
          ...LocalCache.getUser(),
          moduleData,
        });

        setRegisteredToken(enabled ? moduleData?.telemedicine?.token : null);
        setMemedToken(enabled ? moduleData?.telemedicine?.token : null);
      })
      .catch((error) => {
        toast(
          <Toast
            type="error"
            title={'Não foi possível ativar prescrição.'}
            subtitle={error.message}
          />,
          { autoClose: false }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form className="form-style-1 card mt-3" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="card-body form-group">
          <div className="row">
            <div className="col-md-12">
              <legend className="mb-1">Ativar Prescrição</legend>
              <p>Prescrição será ativada somente após a ativação da teleconsulta</p>
            </div>
            <div className="col-md-6">
              <Input
                defaultValue={crm}
                error={errors?.crm}
                errormessage={errors?.crm?.message}
                label="CRM"
                name="crm"
                disabled={!enabled}
                ref={register({
                  required: rulesMessages.crm.required,
                  pattern: { value: rules?.crm, message: rulesMessages.crm.pattern },
                })}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Select
                defaultValue={uf}
                error={errors?.uf}
                errormessage={rulesMessages.uf}
                initial={uf}
                label="CRM UF"
                name="uf"
                disabled={!enabled}
                options={UFDictionary.map((uf) => ({
                  id: uf,
                  name: uf,
                }))}
                register={register({
                  required: true,
                  pattern: { value: rules?.uf, message: rulesMessages.uf.pattern },
                })}
              />
            </div>
          </div>
          <button
            className="btn btn-primary btn-block"
            disabled={loading || !formState.isValid || !enabled}
          >
            {loading ? 'CARREGANDO...' : registeredToken ? 'ATUALIZAR PRESCRIÇÃO' : 'ATIVAR'}
          </button>
        </fieldset>
      </form>
    </>
  );
}

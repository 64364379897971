import { useContext, useMemo } from 'react';

import LinkWrapper from 'Components/Wrappers/LinkWrapper';
import ModuleContext from 'Context/moduleContext';
import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import routes from 'routes';
import router from 'Utils/Helpers/router';
import moduleHelper from 'Utils/Helpers/moduleHelper';

export default function Settings() {
  const { moduleAccess } = useContext(ModuleContext);

  const hasEnabledTelemedicine = useMemo(
    () => moduleHelper.enabledService(moduleAccess, SERVICE_STEPS(moduleAccess).telemedicine.value),
    [moduleAccess]
  );

  return (
    <div className="row justify-content-center">
      {hasEnabledTelemedicine && (
        <div className="col-lg-4">
          <LinkWrapper className="card" to={router.to(routes.TELEMEDICINE.path)}>
            <div className="card-body text-center">
              <i className="mdi mdi-bottle-tonic-plus-outline icon-2x icon-v2 text-blue border-blue border-3 rounded-round p-3 mb-3 mt-1" />
              <h5 className="card-title">Ativar Telemedicina</h5>
              <p className="mb-3">Editar dados dos profissional, e ativar a prescrição</p>
            </div>
          </LinkWrapper>
        </div>
      )}
      <div className="col-lg-4">
        <LinkWrapper className="card" to={router.to(routes.USERS.path)}>
          <div className="card-body text-center">
            <i className="mdi mdi-account icon-2x icon-v2 text-blue border-blue border-3 rounded-round p-3 mb-3 mt-1" />
            <h5 className="card-title">Cadastro de Profissionais</h5>
            <p className="mb-3">Listar e cadastrar profissionais ativos no BackOffice</p>
          </div>
        </LinkWrapper>
      </div>
    </div>
  );
}

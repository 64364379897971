export const numberMask = (number) => {
  let substr = 4;

  if (number.length === 13) substr = 5;

  return `+${number.substr(0, 2)} (${number.substr(2, 2)}) ${
    number.substr(4, substr).length === 4
      ? '9' + number.substr(4, substr)
      : number.substr(4, substr)
  }-${number.substr(-4)}`;
};

import { useContext, useMemo } from 'react';
import { mdiMessage } from '@mdi/js';

import { useChat } from 'Components/Chat/hooks/useChat';
import { getEntityEmail, SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import ModuleContext from 'Context/moduleContext';
import LocalCache from 'Services/LocalCache';
import moduleHelper from 'Utils/Helpers/moduleHelper';
import { IconBtn } from './styled';
import { Document } from 'data/types';

import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';

type Props = {
  record?: Document;
};

const ChatButton = ({ record }: Props) => {
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const { openChat } = useChat();
  const emailCurrentUser = getEntityEmail();

  const displayRequestedTeamSupport = useFlag(
    `lc-display-unread-messages-${environment.UNLEASH_APP_ENV}`
  );

  const isDataEncrypted = useMemo(
    () =>
      LocalCache.isDataEncrypted() && record?.patient?.cpf && record?.patient?.cpf?.length >= 15,
    [record]
  );

  const isChatActivated = useMemo(
    () =>
      moduleHelper.enabledService(moduleAccess, SERVICE_STEPS(moduleAccess).chat.value) &&
      stepActive !== 'eligible',
    [moduleAccess, stepActive]
  );

  const myCurrentChat: boolean = useMemo(
    () =>
      record?.chat?.responsibleEmail ? record?.chat?.responsibleEmail === emailCurrentUser : true,
    [record, emailCurrentUser]
  );

  const messageHelp = useMemo(() => {
    if (!record?.chat?.senderId) {
      return 'Para iniciar uma conversa com este paciente, você precisa enviar um contato ativo pelo ícone de ações.';
    }

    return '';
  }, [record]);

  const displayMessagesUnreaded: boolean = useMemo(() => {
    const isRequestedTeamSupport =
      displayRequestedTeamSupport && 
      record?.handover?.status &&
      record?.handover?.reason === 'REQUESTED_TEAM_SUPPORT';

    return !isDataEncrypted && (isRequestedTeamSupport || myCurrentChat) && !Boolean(messageHelp);
  }, [displayRequestedTeamSupport, myCurrentChat, record]);

  const handleOpen = () => {
    if (isDataEncrypted) {
      return;
    }

    openChat({
      senderId: record?.chat?.senderId,
      title: record?.patient?.nome,
      original: record,
    });
  };

  if (!isChatActivated) {
    return null;
  }

  return (
    <IconBtn
      background="#ececec"
      icon={mdiMessage}
      onClick={handleOpen}
      disabled={isDataEncrypted || Boolean(messageHelp)}
      messageHelp={isDataEncrypted ? '' : messageHelp}
      counter={displayMessagesUnreaded ? record?.chat?.messagesUnreaded : 0}
    />
  );
};

export default ChatButton;

import { useContext, useState, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import DashboardModel, { SUBSCRIBE_REMOTE_ATENDIMENTO_SECTOR } from 'Models/DashboardModel';
import ModuleContext from 'Context/moduleContext';
import DataContext from 'Context/dataContext';
import { getEntityId, getEntityRoleId, getEntityEmail } from 'Utils/Config/SectorConfig';
import useCache from 'Hooks/useCache';

export const WrapperTeleAtendimento = ({ children }) => {
  // moduleDispatch
  const { searchKeyword, stepActive } = useContext(ModuleContext);
  const [userPageSize] = useCache('user_page_size', 6);
  const [page, handlePage] = useState(1);
  const [limit, handleLimit] = useState(userPageSize);

  const [fetchAllDocuments, { subscribeToMore, data, loading, refetch }] = useLazyQuery(
    DashboardModel.GET_REMOTE_ATENDIMENTO,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        entityId: getEntityId(),
        module: 'customer_service',
        sector: [stepActive],
        emailMenu: getEntityRoleId() === 3 ? getEntityEmail() : null,
        emailUser:
          getEntityRoleId() === 3
            ? stepActive === 'ALL' ||
              stepActive === 'AWAITING' ||
              stepActive === 'HISTORY' ||
              stepActive === 'OUTSIDE'
              ? null
              : getEntityEmail()
            : null,
        page,
        limit,
        search: searchKeyword,
      },
    }
  );

  const subscribe = useCallback(() => {
    return typeof subscribeToMore === 'function'
      ? subscribeToMore({
          document: SUBSCRIBE_REMOTE_ATENDIMENTO_SECTOR,
          variables: { entityId: getEntityId()[0] },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;
            let inserted = false;
            const newData = prev?.documentsRA?.records.map((document) => {
              if (document.senderId === subscriptionData.data.remoteAtendimentoSector.senderId) {
                inserted = true;
                return subscriptionData.data.remoteAtendimentoSector;
              } else {
                return document;
              }
            });

            if (
              !inserted &&
              subscriptionData.data.remoteAtendimentoSector.evolutionRecord.setor === stepActive
            ) {
              newData.push(subscriptionData.data.remoteAtendimentoSector);
            }
            return {
              ...prev,
              documentsRA: {
                ...prev?.documentsRA,
                records: newData,
              },
            };
          },
        })
      : undefined;
  }, [subscribeToMore, stepActive]);

  const internalFetchData = useCallback(async () => {
    if (refetch) {
      await refetch();
    } else {
      await fetchAllDocuments();
    }
  }, [fetchAllDocuments, refetch]);

  useEffect(() => {
    internalFetchData();
  }, [internalFetchData]);

  useEffect(() => {
    const currentSubscribe = subscribe();

    return () => {
      if (currentSubscribe) {
        currentSubscribe();
      }
    };
  }, [subscribe]);

  const fetchData = async ({ page: newPage, limit: newLimit }) => {
    await handlePage(newPage + 1);
    await handleLimit(newLimit);
  };

  return (
    <DataContext.Provider value={{ data, loading, fetchData }}>{children}</DataContext.Provider>
  );
};

export default WrapperTeleAtendimento;

import { useContext, useState, useEffect, useMemo } from 'react';
import { mdiLink, mdiVideo } from '@mdi/js';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import IconButtonPopover from 'Components/IconButtonPopover';
import NewButton from 'Components/NewButton';
import DashboardModel from 'Models/DashboardModel';
import useCache from 'Hooks/useCache';
import LocalCache from 'Services/LocalCache';
import ModuleContext from 'Context/moduleContext';
import moduleHelper from 'Utils/Helpers/moduleHelper';

import * as Styled from './styled';
import { useDispatch } from 'react-redux';
import { ModalEnterLinkActions } from 'store/ducks/modal_enter_link';
import { ModalResendLinkActions } from 'store/ducks/modal_resend_link';

const STATUS_COLOR = {
  EXPIRED: { color: '#CF0E00', help: 'Link expirado' },
  WAITING: { color: '#57FF57', help: 'Paciente já está na sala' },
  SENDED: { color: '#0071eb', help: 'Link enviado' },
};

type OwnProps = {
  document: Record<string, any>;
  afterSubmit?: (...args: any[]) => any;
};

type Props = OwnProps & typeof VideoButton.defaultProps;

const VideoButton = ({ document, afterSubmit }: Props) => {
  const [user] = useCache('user');
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const dispatch = useDispatch();

  const [click, setClick] = useState(false);
  const [joinVideoCallUrl, setJoinVideoCallUrl] = useState({});
  const [note] = useState('default');

  const slotInitialTime = moment(document?.serviceSteps?.telemedicine?.scheduling?.slotInitialTime);
  const disabled =
    !slotInitialTime.startOf('day').isSame(moment().startOf('day')) || !slotInitialTime.isValid();

  const [joinVideoCall] = useMutation(DashboardModel.JOIN_VIDEO_CALL);
  const [sendTeleconsultationLink, { loading: sendLinkLoading }] = useMutation(
    DashboardModel.TELECONSULTATION_LINK,
    {
      onCompleted: () => {
        afterSubmit && afterSubmit();
      },
    }
  );

  useEffect(() => {
    if (joinVideoCallUrl?.[0]?.data.joinVideoCall) {
      window.open(`${joinVideoCallUrl?.[0]?.data.joinVideoCall}`, '_blank');
    }
  }, [joinVideoCallUrl]);

  const isVideoActivated = useMemo(
    () =>
      document?.evolutionRecord?.serviceStep === 'telemedicine' &&
      moduleHelper.enabledService(moduleAccess, document?.evolutionRecord?.serviceStep) &&
      stepActive !== 'eligible',
    [document, stepActive, moduleAccess]
  );

  const isDataEncrypted = useMemo(
    () => LocalCache.isDataEncrypted() && document?.patient?.cpf?.length >= 15,
    [document]
  );

  const submit = () => {
    if (isDataEncrypted) {
      return;
    }

    sendTeleconsultationLink({
      variables: {
        entityId: document.entityId,
        cellphone: document.patient.telefone,
        atendimentoId: document.medicalRecord,
        module: moduleAccess,
        note,
        usuario: user?.name,
        email: user?.email,
        attendantCpf: user?.cpf,
      },
    });
  };

  const transferInProgress = async () => {
    if (isDataEncrypted) {
      return;
    }

    if (document?.evolutionRecord?.setor !== 'IN_PROGRESS') {
      return dispatch(ModalEnterLinkActions.modalEnterLinkSetIsOpen(true, document));
    }

    await Promise.all([
      joinVideoCall({
        variables: {
          entityId: document.entityId,
          medicalRecord: document.medicalRecord,
          telemedicineToken: document.serviceSteps.telemedicine.token,
          module: moduleAccess,
          usuario: user?.name,
          email: user?.email,
          attendantCpf: user?.cpf,
        },
      }).catch((error) => alert(error.message)),
      setClick(true),
    ]).then((joinVideoCallPromise) => {
      setJoinVideoCallUrl(joinVideoCallPromise);
      setClick(false);
    });
  };

  const getTelemedicineLinkStatus = () => {
    const periodFromNow = moment().diff(
      moment(document.serviceSteps.telemedicine?.monitoring?.dataUltimoEnvio?.replace('.000Z', '')),
      'hours'
    );

    const meetRoomDateStarted = moment().diff(
      moment(document.serviceSteps.telemedicine?.meetRoomDateStarted?.replace('.000Z', '')),
      'hours'
    );

    const tokenDateSent = moment().diff(
      moment(document.serviceSteps.telemedicine?.tokenDateSent?.replace('.000Z', '')),
      'hours'
    );

    if (periodFromNow >= 2) {
      return STATUS_COLOR.EXPIRED;
    }

    if (meetRoomDateStarted >= 2) {
      return STATUS_COLOR.EXPIRED;
    }

    if (tokenDateSent >= 2) {
      return STATUS_COLOR.EXPIRED;
    }

    if (
      document.serviceSteps?.telemedicine?.waiting ||
      document?.serviceSteps?.telemedicine?.meetRoomDoctor !== null
    ) {
      return STATUS_COLOR.WAITING;
    } else {
      return STATUS_COLOR.SENDED;
    }
  };

  const patientIsWaiting = () => {
    const meetRoomDateStarted = moment().diff(
      moment(document.serviceSteps.telemedicine?.meetRoomDateStarted?.replace('.000Z', '')),
      'hours'
    );

    const tokenDateSent = moment().diff(
      moment(document.serviceSteps.telemedicine?.tokenDateSent?.replace('.000Z', '')),
      'hours'
    );

    return (document?.serviceSteps?.telemedicine?.waiting && user?.moduleData?.telemedicine) ||
      document?.serviceSteps?.telemedicine?.meetRoomDoctor !== null
      ? meetRoomDateStarted >= 2 || tokenDateSent >= 2
        ? true
        : false
      : true;
  };

  const checkLinkSended = () => {
    return document.serviceSteps?.telemedicine?.token ? true : false;
  };

  if (!isVideoActivated) {
    return null;
  }

  return (
    <Styled.Container>
      <IconButtonPopover
        icon={mdiVideo}
        activeColor="#fff"
        color="#fff"
        disabledColor="#dbdbdb"
        showStatus={checkLinkSended()}
        statusHelp={getTelemedicineLinkStatus().help}
        statusColor={getTelemedicineLinkStatus().color}
        disabled={isDataEncrypted || disabled}
      >
        <NewButton
          color="#FFFFFF"
          onClick={() => transferInProgress()}
          size="sm"
          background={click === false ? '#0071eb' : '#ccc'}
          disabled={patientIsWaiting()}
          fullWidth
          marginBotton={11}
        >
          Entrar na videochamada
        </NewButton>
        <Styled.Spacing />
        {checkLinkSended() && getTelemedicineLinkStatus() === STATUS_COLOR.EXPIRED ? (
          <Styled.Error>*Link expirado para o usuário.</Styled.Error>
        ) : null}
        <NewButton
          color="#FFFFFF"
          onClick={() =>
            getTelemedicineLinkStatus() === STATUS_COLOR.SENDED && checkLinkSended()
              ? dispatch(ModalResendLinkActions.modalResendLinkSetIsOpen(true, document, submit))
              : submit()
          }
          size="sm"
          background="#444444"
          fullWidth
          disabled={sendLinkLoading}
          suffixIcon={checkLinkSended() ? mdiLink : mdiLink}
        >
          {sendLinkLoading ? 'Enviando link' : checkLinkSended() ? 'Reenviar link' : 'Enviar link'}
        </NewButton>
      </IconButtonPopover>
    </Styled.Container>
  );
};

VideoButton.defaultProps = {
  afterSubmit: null,
};

export default VideoButton;

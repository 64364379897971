import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';

import environment from 'Utils/Dictionary/environment';

import * as Styled from './styled';

import useCache from 'Hooks/useCache';

const Footer = () => {
  const [user] = useCache('user');
  const currentYear = useMemo(() => getYear(new Date()), []);

  const [entityName, setEntityName] = useState('');

  useEffect(() => {
    if (user) {
      setEntityName(` | ${user?.entity?.name}`);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Styled.Footer>
      <Styled.Text>
        © {currentYear} Munai{entityName}{' '}
      </Styled.Text>
      <Styled.Text>
        <Styled.Version>v{environment.VERSION}</Styled.Version>
      </Styled.Text>
    </Styled.Footer>
  );
};

Footer.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
};

Footer.defaultProps = {
  link: 'https://triagemvirtual.laura-br.com',
  label: 'Triagem Virtual',
};

export default Footer;

import { Redirect, Route } from 'react-router-dom';

import useCache from 'Hooks/useCache';
import routes from 'routes';
import { verifyRouteAccessed } from 'Utils/Config/RoutesPermissions';

type Props = {
  component: any;
  location?: {
    pathname?: string;
  };
};

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const [user] = useCache('user');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user || !user.token || !verifyRouteAccessed(user.roleId, rest?.computedMatch?.path)) {
          return (
            <Redirect
              to={{
                pathname: routes.LOGIN.path,
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;

import styled from 'styled-components';
import { ColorDictionary } from '../../Utils/Dictionary/ColorDictionary';

export const Badge = styled.span`
  padding: 0 6px;
  line-height: 20px;
  border-radius: 3px;
  border: 1px solid transparent;
`;

const LeftDot = styled.div`
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.color};
  position: absolute;
  left: -44px;
  border-radius: 100%;
  top: 10px;
`;

const WhiteMiddleDot = styled.div`
  height: 6px;
  width: 6px;
  background-color: white;
  position: absolute;
  left: -39px;
  border-radius: 100%;
  top: 15px;
  z-index: 2;
`;

export const BadgeWithColor = ({ children, color, type }) => {
  if (!type) {
    return <Badge style={ColorDictionary.Badge[color]}>{children}</Badge>;
  } else if (type === 'timeline') {
    return (
      <div>
        <WhiteMiddleDot />
        <LeftDot color={ColorDictionary.Badge[color].colorBadge} />
        <Badge style={ColorDictionary.Badge[color]}>{children}</Badge>
      </div>
    );
  }
};

export const BadgeWithColorTranslated = ({ color, type }) => {
  return ColorDictionary.Badge[color] ? (
    <BadgeWithColor type={type} color={color}>
      {ColorDictionary.Badge[color].name}
    </BadgeWithColor>
  ) : (
    <></>
  );
};

export const BadgeWithColorNone = ({ children, color, type }) => {
  if (!type) {
    return <Badge style={ColorDictionary.Badge[color]}>{children}</Badge>;
  } else if (type === 'timeline') {
    return (
      <div>
        <WhiteMiddleDot />
        <LeftDot color={ColorDictionary.Badge[color].color} />
      </div>
    );
  }
};

export const BadgeWithColorTranslatedNone = ({ color, type }) => {
  return ColorDictionary.Badge[color] ? <BadgeWithColorNone type={type} color={color} /> : <></>;
};

export const BadgeWithColorBadge = ({ children, color, type }) => {
  if (!type) {
    return <Badge style={ColorDictionary.Badge[color]}>{children}</Badge>;
  } else if (type === 'timeline') {
    return (
      <div>
        <Badge style={ColorDictionary.Badge[color]}>{children}</Badge>
      </div>
    );
  }
};

export const BadgeWithColorTranslatedBadge = ({ color, type }) => {
  return ColorDictionary.Badge[color] ? (
    <BadgeWithColorBadge type={type} color={color}>
      {ColorDictionary.Badge[color].name}
    </BadgeWithColorBadge>
  ) : (
    <></>
  );
};

export default Badge;

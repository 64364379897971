import { Center } from '@chakra-ui/react';
import { useFlag } from 'Context/unleashContext';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Card } from 'theme/ui';
import environment from 'Utils/Dictionary/environment';
import { ForgotPassword } from './ForgotPassword';
import { EmailSended } from './EmailSended';
import { Login } from './Login';
import OldLogin from './OldLogin';
import { ResetPassword } from './ResetPassword';
import { CreatePassword } from './CreatePassword';

export const AuthPage = () => {
  const { path } = useRouteMatch();

  // TODO: remove when lc-user-invite flag is enabled in production
  const isUserInviteEnabled = useFlag(`lc-user-invite-${environment.UNLEASH_APP_ENV}`);

  return (
    <Center height="full">
      <Card padding="7" paddingTop="8" margin="2" width="min(380px, 100%)" shadow="lg">
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/login`} />
          </Route>
          <Route path={`${path}/login`}>
            <Login />
          </Route>
          <Route path={`${path}/forgot-password`}>
            <ForgotPassword />
          </Route>
          <Route path={`${path}/email-sended`}>
            <EmailSended />
          </Route>
          <Route path={`${path}/reset-password`}>
            <ResetPassword />
          </Route>
          {isUserInviteEnabled && (
            <Route path={`${path}/create-password`}>
              <CreatePassword />
            </Route>
          )}
        </Switch>
      </Card>
    </Center>
  );
};

// TODO: remove when lc-enabled-refactored-auth-page flag is enabled in production
export const AuthPageUnleashFallback = () => {
  const isRefactoredAuthPageEnabled = useFlag(
    `lc-enabled-refactored-auth-page-${environment.UNLEASH_APP_ENV}`
  );

  if (isRefactoredAuthPageEnabled) {
    return <AuthPage />;
  }

  return <OldLogin />;
};

import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export default styled(ToastContainer)`
  width: 459px;
  margin-top: 25px;
  z-index: 9999999;

  .Toastify__toast-container {
  }

  .Toastify__toast {
    background: #fff;
    box-shadow: 0px 3px 30px #00000033;
    border-radius: 6px;
    opacity: 1;
  }

  .Toastify__toast--error {
  }

  .Toastify__toast--warning {
  }

  .Toastify__toast--success {
  }

  .Toastify__toast-body {
    padding: 23px;
  }

  .Toastify__progress-bar {
  }

  .Toastify__close-button {
  }
`;

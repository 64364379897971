import { useContext } from 'react';
import { mdiClose, mdiArrowExpand } from '@mdi/js';

import { MoreActions } from 'Components/Datatable/components';
import ChatContext from 'Components/Chat/context';
import { useChat } from 'Components/Chat/hooks/useChat';

import * as Styled from './styled';

const AppBar = () => {
  const { activeActions, componentActions, loadMenu } = useChat();
  const { original, title, toggleExpandChat, closeChat, toggleMinimize, minimized, expanded } =
    useContext(ChatContext);

  const { updateOriginalDataChat } = useChat();

  return (
    <Styled.Container>
      <Styled.Title onClick={toggleMinimize}>{title}</Styled.Title>
      <Styled.Actions>
        {!minimized && (
          <>
            <Styled.IconExpand
              onClick={toggleExpandChat}
              path={mdiArrowExpand}
              color="#707070"
              expanded={expanded.toString()}
              size={1.15}
            />
            {activeActions?.length > 0 ? (
              <MoreActions
                alertRecord={original}
                medicalRecord={original?.medicalRecord}
                activeActions={activeActions}
                componentActions={componentActions}
                onFinishModal={loadMenu}
                updateOriginalDataChat={updateOriginalDataChat}
              />
            ) : null}
          </>
        )}
        <Styled.Icon onClick={closeChat} path={mdiClose} color="#707070" size={1.15} />
      </Styled.Actions>
    </Styled.Container>
  );
};

export default AppBar;

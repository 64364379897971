import * as Styled from './styled';

type OwnProps = {
  color?: string;
  activeColor?: string;
  active?: boolean;
  label: string;
  onClick: (...args: any[]) => any;
};

type Props = OwnProps & typeof SectorOption.defaultProps;

const SectorOption = ({ color, activeColor, active, label, onClick }: Props) => (
  <Styled.Container active={active} color={color} activeColor={activeColor} onClick={onClick}>
    {label}
  </Styled.Container>
);

SectorOption.defaultProps = {
  color: '#e3e3e3',
  activeColor: '#0071eb',
  active: false,
};

export default SectorOption;

import styled, { css } from 'styled-components';
import IconBase from '@mdi/react';
import { ControlledMenu, MenuItem as MenuItemBase } from '@szhsin/react-menu';

export const Container = styled.div.attrs((props) => ({
  active: props.active,
  activeColor: props.activeColor,
  disabled: props.disabled,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  margin: 0px 5px;
  border-radius: 100%;

  & span {
    display: block;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    margin-bottom: 2px;
    transition: background-color 0.2s ease;
  }

  ${({ active, activeColor, disabled }) => css`
    cursor: ${disabled ? 'default' : 'pointer'};

    &:hover {
      background: ${disabled ? 'transparent' : '#ececec'};
    }

    & span {
      background: ${active ? activeColor : disabled ? '#E3E3E3' : '#707070'};
    }
  `}
`;

export const Menu = styled(ControlledMenu)`
  margin-right: 0px;
  padding: 0px;
  width: 260px;
  background: white;
  color: #444444;
  border-radius: 5px;
  z-index: 999;
  box-shadow: 0px 3px 20px #00000029;
`;

export const MenuItem = styled(MenuItemBase).attrs((props) => ({
  disabled: props.disabled,
}))`
  background: white;
  padding: 14px;
  font-size: 14px;
  border-radius: 5px;

  ${({ disabled }) => css`
    color: ${disabled ? '#A9A9A9' : '#444444'};

    &:hover {
      background: ${disabled ? 'white' : '#f4f5f7'};
    }

    &:active {
      color: ${disabled ? '#A9A9A9' : '#444444'};
      ${disabled ? 'white' : '#f4f5f7'};
    }
  `}
`;

export const Icon = styled(IconBase)`
  margin-right: 8px;
`;

export const Content = styled.div``;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 10px;
  font-size: 18px;
  color: #333;
`;

import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { mdiViewDashboard } from '@mdi/js';

import RSelect from 'Components/RSelect';
import Navlink from 'Components/Navlink';

type Props = {
  options: {
    value: string;
    label: string;
  }[];
};

const DashboardSelect = ({ options }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const value = useMemo(() => {
    return options.find((s) => s.value === pathname);
  }, [options, pathname]);

  const handleChangeStatus = (st) => {
    history.push(st.value);
  };

  if (!value) {
    return (
      <Navlink
        icon={mdiViewDashboard}
        label="Dashboard"
        url={options.length ? options[0].value : ''}
      />
    );
  }

  return (
    <RSelect
      width="175px"
      fontSize={16}
      options={options}
      onChange={handleChangeStatus}
      value={value}
      isSearchable={false}
      fontWeight={500}
      icon={mdiViewDashboard}
      iconColor="#FF881F"
    />
  );
};

export default DashboardSelect;

import { gql } from '@apollo/client';

import { EntitiesModulesFields } from './module';

export const EntityInitialFields = gql`
  fragment EntityInitialFields on Entity {
    name
    slug
    protocolId
    totalMonitoringDays
    remoteMonitoringTypes
    nextServiceStep
    doubtsLink
    phoneNumber
    openingHours
  }
`;

export const EntityInitialWithModuleFields = gql`
  fragment EntityInitialWithModuleFields on Entity {
    ...EntityInitialFields
    entitiesModules {
      ...EntitiesModulesFields
    }
  }
  ${EntityInitialFields}
  ${EntitiesModulesFields}
`;

export const EntityResumedFields = gql`
  fragment EntityResumedFields on Entity {
    id
    name
    description
    ...EntityInitialFields
  }
  ${EntityInitialFields}
`;

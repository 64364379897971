import { useState, useContext, useMemo, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import client from 'Services/api';

import { useAsyncDebounce } from 'react-table';

import useCache from 'Hooks/useCache';
import { useMoreActions } from 'Hooks/useMoreActions';
import ModuleContext from 'Context/moduleContext';
import { mdiAlertCircle } from '@mdi/js';
import EvolutionModel from 'Models/EvolutionModel';
import DashboardModel from 'Models/DashboardModel';
import Button from 'Components/NewButton';
import { Styled } from '../Actions/styled';
import { toast } from 'react-toastify';
import Toast from 'Components/Toast';
import AsyncSelect from 'react-select/async';
import LocalCache from 'Services/LocalCache';

export const TextareaLimit = ({ limit, height, onChange, initialValue, ...props }) => {
  const [input, setInput] = useState(initialValue);

  const handleOnChange = useAsyncDebounce((value) => {
    onChange(value);
  }, 100);

  return (
    <>
      <Styled.Textarea
        style={{ fontSize: '14px', marginBottom: '35px' }}
        onChange={(e) => {
          setInput(e.target.value);
          handleOnChange(e.target.value);
        }}
        height={height}
        value={input}
        {...props}
      />
    </>
  );
};

const attendanceTypeOptions = [
  { value: 1, label: 'Chat' },
  { value: 2, label: 'Teleconsulta' },
  { value: 3, label: 'Administrativo' },
  { value: 4, label: 'Outro' },
];

const attendanceTypeStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #AAAAAA',
    boxShadow: 'none',
    '&:focus-within': {
      borderColor: '#0071eb',
      boxShadow: '1px #0071eb',
    },
    ':active, :hover, :focus': { border: '1px solid #0071eb !important' },
  }),
  multiValue: (styles) => ({
    ...styles,
    color: '#707070',
    background: '#E3E3E3 0% 0% no-repeat padding-box',
    borderRadius: '5px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#707070',
    ':hover': {},
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: '#707070',
    ':hover': {},
  }),
};

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #AAAAAA',
    boxShadow: 'none',
    '&:focus-within': {
      borderColor: '#0071eb',
      boxShadow: '1px #0071eb',
    },
    ':active, :hover, :focus': { border: '1px solid #0071eb !important' },
  }),
  multiValue: (styles) => ({
    ...styles,
    color: '#707070',
    background: '#E3E3E3 0% 0% no-repeat padding-box',
    borderRadius: '5px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#707070',
    ':hover': {},
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: '#707070',
    ':hover': {},
  }),
};

export default function RegisterEvolution({ onCloseModal, medicalRecord, callBack }) {
  const { moduleAccess } = useContext(ModuleContext);
  const {
    handleNote,
    handleSubjectiveNote,
    handleObjectiveNote,
    handleAssessmentNote,
    handlePlanNote,
    handleSelectedCids,
    handleSelectedCiaps,
    handleSoapFields,
    handleSoapEvolution,
    handleAttendanceType,
    dispatch,
    store: {
      note,
      subjectiveNote,
      objectiveNote,
      assessmentNote,
      planNote,
      selectedCids,
      keyCidSelect,
      selectedCiaps,
      keyCiapSelect,
      soapFields,
      soapEvolution,
      attendanceType,
    },
  } = useMoreActions();

  const isValid = useMemo(() => Boolean(note), [note]);
  const [setEvolutionSoap, { loading: loadingSoap }] = useMutation(
    EvolutionModel.SET_EVOLUTION_SOAP
  );
  const [observationRecord, { loading }] = useMutation(DashboardModel.OBSERVATION);
  const [user] = useCache('user');

  const [validSoapFields, setValidSoapFields] = useState(false);

  const areEmptyFieldsEvolutionSoap =
    subjectiveNote === '' &&
    objectiveNote === '' &&
    assessmentNote === '' &&
    selectedCids.length === 0 &&
    selectedCiaps.length === 0 &&
    planNote === '';

  const AttendanceTypeDropDown = () => {
    return (
      <Styled.AttendanceTypeContainer>
        <Styled.Label
          style={{ fontWeight: '500', fontSize: '14px', marginTop: '0', marginBottom: '8px' }}
        >
          Tipo de atendimento
        </Styled.Label>
        <Styled.AttendanceTypeSelector
          isClearable
          placeholder="Selecione um tipo de atendimento"
          isSearchable
          styles={attendanceTypeStyles}
          options={attendanceTypeOptions}
          onChange={handleAttendanceType}
          value={attendanceType}
        />
      </Styled.AttendanceTypeContainer>
    );
  };

  useEffect(() => {
    setValidSoapFields(!areEmptyFieldsEvolutionSoap && selectedCids.length > 0);
  }, [selectedCids, areEmptyFieldsEvolutionSoap]);

  const handleOnChangeCid = (selectedCid) => {
    handleSelectedCids(selectedCid);
  };

  const handleOnChangeCiap = (selectedCiap) => {
    handleSelectedCiaps(selectedCiap);
  };

  useEffect(() => {
    handleSoapFields({
      subjective: subjectiveNote,
      objective: objectiveNote,
      assessment: assessmentNote,
      plan: planNote,
    });
    // eslint-disable-next-line
  }, [subjectiveNote, objectiveNote, assessmentNote, planNote]);

  const onSubmit = () => {
    setEvolutionSoap({
      variables: {
        entityId: +user.entityId,
        module: moduleAccess,
        cpf: medicalRecord,
        soapFields,
        cid: selectedCids.map(({ label }) => label),
        ciap: selectedCiaps.map(({ label }) => label),
        attendanceType: attendanceType?.label,
        userFirstName: LocalCache.getUser()?.name,
        userEmail: LocalCache.getUser()?.email,
        attendantCpf: user.cpf,
      },
    })
      .then(() => {
        toast(
          <Toast
            type="success"
            title="Evolução registrada com sucesso"
            subtitle="A evolução foi registrada na timeline do paciente, onde pode ser visualizada."
          />
        );
        handleSoapFields({});
        callBack && callBack();
      })
      .catch((error) => dispatch({ type: 'error', error: error.message }));
  };

  const submit = () => {
    observationRecord({
      variables: {
        entityId: parseInt(user.entityId),
        medicalRecord,
        note,
        user: user.name,
        email: user.email,
        module: moduleAccess,
        attendanceType: attendanceType?.label,
        attendantCpf: user?.cpf,
      },
    })
      .then(() => {
        toast(
          <Toast
            type="success"
            title="Evolução registrada com sucesso"
            subtitle="A evolução foi registrada na timeline do paciente, onde pode ser visualizada."
          />
        );
        handleNote('');
        callBack && callBack();
      })
      .catch((error) => dispatch({ type: 'error', error: error.message }));
  };

  const LoadOptionsCid = async (inputValue) => {
    if (inputValue.length < 3) return [];

    const { data } = await client.query({
      query: EvolutionModel.GET_CID_CLASSIFIERS,
      variables: { cid: inputValue },
    });

    return data ? data.getCidClassifiers.map((d) => ({ value: d.name, label: d.name })) : [];
  };

  const LoadOptionsCiap = async (inputValue) => {
    if (inputValue.length < 3) return [];

    const { data } = await client.query({
      query: EvolutionModel.GET_CIAP_CLASSIFIERS,
      variables: { ciap: inputValue },
    });

    return data ? data.getCiapClassifiers.map((d) => ({ value: d.name, label: d.name })) : [];
  };

  return (
    <Styled.ModalContainer>
      <Styled.TitleWrapper>
        <Styled.NewTitleModal style={{ fontSize: '20px', color: '#444444' }}>
          Registrar Evolução
        </Styled.NewTitleModal>
        <Styled.ModalSubtitle style={{ fontSize: '14px' }}>
          Selecione um dos modelos para registrar as informações do paciente
        </Styled.ModalSubtitle>
        <Styled.HorizontalLine />
      </Styled.TitleWrapper>

      <Styled.OverFlowContainer default={!soapEvolution}>
        <Styled.Flex justify="flex-start">
          <Styled.EvolutionButton
            maxWidth={372}
            onClick={() => handleSoapEvolution(true)}
            active={soapEvolution}
            style={{ marginRight: '15px', padding: '10px' }}
          >
            <Styled.EvolutionButtonText active={soapEvolution}>SOAP</Styled.EvolutionButtonText>
          </Styled.EvolutionButton>
          <Styled.EvolutionButton
            maxWidth={372}
            onClick={() => handleSoapEvolution(false)}
            active={!soapEvolution}
          >
            <Styled.EvolutionButtonText active={!soapEvolution}>Padrão</Styled.EvolutionButtonText>
          </Styled.EvolutionButton>
        </Styled.Flex>

        {soapEvolution ? (
          <Styled.ContainerEvolutionSoapForm>
            <AttendanceTypeDropDown></AttendanceTypeDropDown>
            <Styled.Label
              style={{ fontWeight: '500', fontSize: '14px', marginTop: '0', marginBottom: '8px' }}
            >
              Subjetivo
            </Styled.Label>
            <TextareaLimit
              limit={200}
              name="subjective"
              placeholder="Digite aqui"
              initialValue={subjectiveNote || ''}
              onChange={handleSubjectiveNote}
              disabled={loading}
            />
            <Styled.Label
              style={{ fontWeight: '500', fontSize: '14px', marginTop: '0', marginBottom: '8px' }}
            >
              Objetivo
            </Styled.Label>
            <TextareaLimit
              limit={200}
              name="objective"
              placeholder="Digite aqui"
              initialValue={objectiveNote || ''}
              onChange={handleObjectiveNote}
              disabled={loading}
            />
            <Styled.Label
              style={{ fontWeight: '500', fontSize: '14px', marginTop: '0', marginBottom: '8px' }}
            >
              Avaliação
            </Styled.Label>
            <TextareaLimit
              limit={200}
              name="assessment"
              placeholder="Digite aqui"
              initialValue={assessmentNote || ''}
              onChange={handleAssessmentNote}
              disabled={loading}
            />
            <Styled.ClassifierInput>
              <Styled.Label
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  marginTop: '0',
                  marginBottom: '8px',
                }}
              >
                CID
              </Styled.Label>
              <AsyncSelect
                key={keyCidSelect}
                styles={colourStyles}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                isMulti
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Digite aqui"
                value={selectedCids}
                noOptionsMessage={() => 'Nenhuma CID encontrada'}
                loadingMessage={() => 'Carregando...'}
                loadOptions={LoadOptionsCid}
                onChange={handleOnChangeCid}
              />
              <Styled.AlertWrapper>
                <Styled.Alert path={mdiAlertCircle} />
                <Styled.AlertText>
                  Campo destinado apenas para profissionais médicos
                </Styled.AlertText>
              </Styled.AlertWrapper>
            </Styled.ClassifierInput>
            <Styled.ClassifierInput>
              <Styled.Label
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  marginTop: '0',
                  marginBottom: '8px',
                }}
              >
                CIAP
              </Styled.Label>
              <AsyncSelect
                key={keyCiapSelect}
                styles={colourStyles}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                isMulti
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Digite aqui"
                value={selectedCiaps}
                noOptionsMessage={() => 'Nenhuma CIAP encontrada'}
                loadingMessage={() => 'Carregando...'}
                loadOptions={LoadOptionsCiap}
                onChange={handleOnChangeCiap}
              />
              <Styled.AlertWrapper>
                <Styled.Alert path={mdiAlertCircle} />
                <Styled.AlertText>Campo destinado profissionais da saúde</Styled.AlertText>
              </Styled.AlertWrapper>
            </Styled.ClassifierInput>
            <Styled.Label
              style={{ fontWeight: '500', fontSize: '14px', marginTop: '0', marginBottom: '8px' }}
            >
              Plano
            </Styled.Label>
            <TextareaLimit
              limit={200}
              name="plan"
              placeholder="Digite aqui"
              initialValue={planNote || ''}
              onChange={handlePlanNote}
              disabled={loading}
            />
          </Styled.ContainerEvolutionSoapForm>
        ) : (
          <Styled.ContainerEvolutionDefault>
            <AttendanceTypeDropDown></AttendanceTypeDropDown>
            <Styled.Label
              style={{ fontWeight: '500', fontSize: '14px', marginTop: '0', marginBottom: '8px' }}
            >
              Nova evolução
            </Styled.Label>
            <TextareaLimit
              height={'calc(66vh - 310px);'}
              limit={200}
              name="evolution"
              placeholder="Digite aqui"
              initialValue={note || ''}
              onChange={handleNote}
              disabled={loading}
            />
          </Styled.ContainerEvolutionDefault>
        )}
      </Styled.OverFlowContainer>
      <Styled.FooterWrapper soap={soapEvolution}>
        <Styled.HorizontalRule />
        <Styled.EvolutionCancelButtonContainer>
          <Button maxWidth={120} fullWidth variant="link" onClick={() => onCloseModal()}>
            Cancelar
          </Button>
          <Styled.ConfirmButton
            outlined
            loading={loading || loadingSoap}
            maxWidth={120}
            variant="outline"
            fullWidth
            disabled={soapEvolution ? !validSoapFields : !isValid}
            onClick={() => {
              soapEvolution ? onSubmit() : submit();
            }}
          >
            Confirmar
          </Styled.ConfirmButton>
        </Styled.EvolutionCancelButtonContainer>
      </Styled.FooterWrapper>
    </Styled.ModalContainer>
  );
}

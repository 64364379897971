import { SYSTEM } from './types';

export const omit = (obj, key) => {
  const { [key]: _ommited, ...rest } = obj;
  return rest;
};

export const formatSenderId = (phone) => `55${phone.replace(/[^\w]/gi, '')}`;

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const getFirstName = (name) => name?.split(' ')[0] || '';

export const getOperatingSystem = (window) => {
  if (window?.navigator.appVersion.indexOf('Win') !== -1) {
    return SYSTEM.WINDOWS;
  }

  if (window?.navigator.appVersion.indexOf('Mac') !== -1) {
    return SYSTEM.MAC;
  }

  if (window?.navigator.appVersion.indexOf('X11') !== -1) {
    return SYSTEM.UNIX;
  }

  if (window?.navigator.appVersion.indexOf('Linux') !== -1) {
    return SYSTEM.LINUX;
  }

  return SYSTEM.OTHER;
};

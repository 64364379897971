import { useState } from 'react';
import Button from 'Components/Button';

import { exportDataToCsv } from 'Utils/Helpers/CsvExporter';

const ButtonCsv = ({
  documentsData,
  prepareData,
  exportCsvParams,
  sectorName,
  stepActive,
  moduleAccess,
  sector,
}) => {
  const [statusLoading, setStatusLoading] = useState(false);
  const [status, setStatus] = useState('EXPORTAR P/ EXCEL');
  const [value, setValue] = useState(null);
  const [pagesTotal, setPagesTotal] = useState(null);

  return (
    <Button
      className="patient-table__button"
      disabled={!documentsData || statusLoading}
      modifiers={['btnSmall', 'bgWhite', 'textBlue', 'btnFlex', 'marginBottom20', 'fontSize12']}
      onClick={() => {
        exportDataToCsv(
          (allStepData) => prepareData(allStepData),
          exportCsvParams,
          `${sectorName}.csv`,
          stepActive,
          moduleAccess,
          sector,
          setValue,
          setPagesTotal,
          setStatus,
          setStatusLoading
        );
      }}
    >
      <span>{value !== null ? `EXPORTANDO ${value} DE ${pagesTotal}` : status}</span>
    </Button>
  );
};

export default ButtonCsv;

import styled from 'styled-components';

export const Footer = styled.div`
  border-top: 1px solid #eaeaea;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 12px;
  color: #444444;
  font-family: 'Roboto';
`;

export const Version = styled.span`
  color: #a9a9a9;
`;

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { AnimatePresence } from 'framer-motion';

import { springSlide } from 'Utils/animations';

import * as Styled from './styled';
import { PropsWithChildren } from 'react';

type OwnProps = {
  onClose: (...args: any[]) => any;
  isOpen?: boolean;
} & PropsWithChildren;

type Props = OwnProps & typeof Modal.defaultProps;

function Modal({ children, onClose, isOpen, width, noPadding, height }: Props) {
  return (
    <AnimatePresence>
      {isOpen && (
        <Styled.Modal>
          <Styled.Content
            height={height}
            noPadding={noPadding}
            width={width}
            transition={springSlide}
            initial={{
              opacity: 0,
              scale: 0.75,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                ease: 'easeOut',
                duration: 0.15,
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.75,
              transition: {
                ease: 'easeIn',
                duration: 0.15,
              },
            }}
          >
            <Styled.Close onClick={onClose}>
              <Icon color="#212121" path={mdiClose} size={0.7} />
            </Styled.Close>
            {children}
          </Styled.Content>
        </Styled.Modal>
      )}
    </AnimatePresence>
  );
}

Modal.Title = Styled.Title;
Modal.Subtitle = Styled.Subtitle;

Modal.defaultProps = {
  width: 600,
  isOpen: false,
};

export default Modal;

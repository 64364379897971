import { Content } from 'Components/Modal/styled';
import IconBase from '@mdi/react';
import styled from 'styled-components';

export const Message = styled.p`
  font: normal normal normal 14px Roboto;
  color: #707070;
  justify-content: center;
  text-align: center;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  &:last-child {
    margin-top: -2px;
  }
  &:last-child {
    margin-bottom: -45px;
  }
`;

export const Title = styled.p`
  font: normal normal normal 16px Roboto;
  color: #707070;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

export const MainContent = styled(Content)`
  display: flex;
  width: 680px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: none;
  min-height: 50px;
  min-width: 50px;
`;

export const Icon = styled(IconBase)`
  width: 33px;
  color: #0071eb;
`;

import routesDictionary from 'Utils/Dictionary/routesDictionary';

export const SectorDictionary = (module) => {
  return {
    TRANSFERRED: {
      slug: 'TRANSFERRED',
      background: '#FF881F',
      color: '#FF881F',
      name: 'Em espera',
      icon: 'mdi-account-clock',
      module: 'etapa de Vídeo em espera',
      route: `/${module}${routesDictionary.TRANSFERRED}`,
      type: 1,
      id: '8',
      automated: false,
      rule: (collection) =>
        (collection.vitalSigns || collection.symptoms) &&
        collection.alert &&
        collection?.evolutionRecord?.serviceStep === 'telemedicine' &&
        collection?.evolutionRecord?.setor === 'TRANSFERRED' &&
        collection.medicalRecord &&
        collection.patient &&
        collection.patient.cpf,
    },
    SCHEDULE: {
      slug: 'SCHEDULE',
      background: '#FF881F',
      color: '#FF881F',
      name: 'Agendamentos',
      icon: 'mdi-account-clock',
      module: 'etapa de Vídeo agendado',
      route: `/${module}${routesDictionary.SCHEDULE}`,
      type: 1,
      id: '13',
      rule: (collection) =>
        (collection.vitalSigns || collection.symptoms) &&
        collection.alert &&
        collection?.evolutionRecord?.serviceStep === 'telemedicine' &&
        collection?.evolutionRecord?.setor === 'SCHEDULE' &&
        collection.medicalRecord &&
        collection.patient &&
        collection.patient.cpf,
    },
    IN_PROGRESS: {
      slug: 'IN_PROGRESS',
      background: '#FF77FF',
      color: '#FF33FF',
      name: 'Em atendimento',
      icon: 'mdi-doctor',
      module: 'etapa de Vídeo em atendimento',
      route: `/${module}${routesDictionary.IN_PROGRESS}`,
      type: 1,
      id: '9',
      automated: false,
      rule: (collection) =>
        (collection.vitalSigns || collection.symptoms) &&
        collection.alert &&
        collection?.evolutionRecord?.serviceStep === 'telemedicine' &&
        collection.medicalRecord &&
        collection.patient &&
        collection.patient.cpf &&
        collection?.evolutionRecord?.setor === 'IN_PROGRESS',
    },
  };
};

export const SectorRoutes = (module) => {
  return {
    transferred: SectorDictionary(module).TRANSFERRED,
    schedule: SectorDictionary(module).SCHEDULE,
    in_progress: SectorDictionary(module).IN_PROGRESS,
  };
};

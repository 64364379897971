import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { mdiAccountPlus, mdiExitToApp } from '@mdi/js';
import { getEntityRoleId, ROLE_ID } from '../../Utils/Config/SectorConfig';

import LocalCache from 'Services/LocalCache';
import {
  HeaderTeleAtendimento as DefaultHeader,
  ModuleSelect,
  StatusSelectTeleAtendimento as StatusSelect,
  DashboardSelect,
} from 'Components';
import routes, { teleatendimento } from 'routes';

import ModuleContext from 'Context/moduleContext';
import { Search } from 'Components/Datatable/Search';
import { removeMemedScriptConfig } from '../../Utils/Helpers/removeMemedScriptConfig';

import { clearCachedFilters } from 'store/ducks/modules';

export default function Header() {
  const { searchKeyword } = useContext(ModuleContext);
  const history = useHistory();

  const handleLogout = async () => {
    clearCachedFilters();
    LocalCache.clearCache();
    await removeMemedScriptConfig();
    history.push(routes.LOGIN.path);
  };

  const options = () => {
    const menuAll = [
      {
        label: 'Gerenciar equipe',
        icon: mdiAccountPlus,
        onClick: () => history.push(teleatendimento.SETTINGS.SETTINGS),
      },
      {
        label: 'Fazer logout',
        icon: mdiExitToApp,
        onClick: handleLogout,
      },
    ];

    const menu = [
      {
        label: 'Fazer logout',
        icon: mdiExitToApp,
        onClick: handleLogout,
      },
    ];

    return getEntityRoleId() === ROLE_ID ? menu : menuAll;
  };

  return (
    <DefaultHeader className="header-grid" options={options()}>
      <DefaultHeader.Col width="100%">
        <DefaultHeader.Col justify="flex-start">
          <ModuleSelect />
          <DefaultHeader.Divider />
          <StatusSelect routeAll={teleatendimento.DEFAULT} routeHistory={teleatendimento.HISTORY} />

          {getEntityRoleId() === ROLE_ID ? (
            <></>
          ) : (
            <>
              <DefaultHeader.Divider />
              <DashboardSelect
                options={[
                  { value: teleatendimento.WHATSAPP, label: 'BI - WhatsApp' },
                  { value: teleatendimento.MESSENGER, label: 'BI - Messenger' },
                ]}
              />
            </>
          )}
        </DefaultHeader.Col>
        <Search keyword={searchKeyword} />
      </DefaultHeader.Col>
    </DefaultHeader>
  );
}

import { mdiDotsVertical } from '@mdi/js';

import EditUserIcon from 'Assets/icons/user/edit-user.svg?url';
import DeactivateUserIcon from 'Assets/icons/user/deactivate-user.svg?url';
import ReactivateUserIcon from 'Assets/icons/user/reactivate-user.svg?url';

import { Menu, MenuButton, MenuItem, MenuIcon } from 'Components/Menu/Menu';

const UserActionsButton = ({ user, onEdit, onActivate, onDeactivate }) => {
  const { isActive } = user;

  return (
    <Menu
      direction="left"
      menuButton={
        <MenuButton
          styles={{ padding: 8, borderRadius: 100, marginTop: -8, marginBottom: -8 }}
          title="Menu de ações"
        >
          <MenuIcon path={mdiDotsVertical} size={0.75} />
        </MenuButton>
      }
    >
      <MenuItem onClick={() => onEdit(user)}>
        <img src={EditUserIcon} alt="Ícone de editar dados" />
        Editar dados
      </MenuItem>
      {isActive ? (
        <MenuItem onClick={() => onDeactivate(user)}>
          <img src={DeactivateUserIcon} alt="Ícone de desativar profissional" />
          Desativar profissional
        </MenuItem>
      ) : (
        <MenuItem onClick={() => onActivate(user)}>
          <img src={ReactivateUserIcon} alt="Ícone de reativar profissional" />
          Reativar profissional
        </MenuItem>
      )}
    </Menu>
  );
};

export default UserActionsButton;

import { Styles } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: {
    body: {
      fontFamily: 'body',
      color: 'chakra-body-text',
      bg: 'chakra-body-bg',
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
      lineHeight: 'base',
    },
    '*::placeholder': {
      color: 'chakra-placeholder-color',
    },
    '*, *::before, &::after': {
      borderColor: 'chakra-border-color',
      wordWrap: 'break-word',
    },
    '.chakra-portal ul': {
      padding: 0,
    },
    '.chakra-toast__inner': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
    },
    '.chakra-toast .chakra-alert': {
      shadow: 'xl',
    },
    '#chakra-toast-manager-top': {
      marginTop: '13 !important',
    },
  },
};

import { useContext, useMemo, useEffect } from 'react';

import ModuleContext from 'Context/moduleContext';
import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import { StatusDictionary } from 'Utils/Dictionary/StatusDictionary';
import { Styled as StyledBase } from 'Components/Patient/Actions/styled';
import Input from 'Components/Input';
import { useMoreActions } from 'Hooks/useMoreActions';

import LocalCache from 'Services/LocalCache';
import { SectorOption, Separator, ServiceStepOption } from './components';

import * as Styled from './styled';
import moduleHelper from 'Utils/Helpers/moduleHelper';

const statusReason = [
  {
    label: 'Alta em Piora',
    value: StatusDictionary.CRITIC,
  },
  {
    label: 'Alta Melhorado',
    value: StatusDictionary.NOT_CRITIC,
  },
  {
    label: 'Não Respondeu',
    value: StatusDictionary.NO_SHOW,
  },
];

const reasons = [
  {
    label: 'Aguardando exame',
    value: StatusDictionary.AWAITING_EXAMS,
  },
  {
    label: 'Orientação de sintomas',
    value: StatusDictionary.ORIENTED_SYMPTOMS,
  },

  {
    label: 'Orientação de isolamento',
    value: StatusDictionary.ORIENTED_ISOLATION,
  },
];

type Props = {
  inputRef: Record<string, any>;
  alertRecord: Record<string, any>;
};

const ListServiceStep = ({ inputRef, alertRecord, isCancelling }: Props) => {
  const { moduleAccess, stepActive } = useContext(ModuleContext);
  const {
    handleServiceStep,
    handleSector,
    handleStatusReason,
    handleReason,
    store: { serviceStep, sector, status, reason },
  } = useMoreActions();

  const services = useMemo(
    () =>
      Object.entries(SERVICE_STEPS(moduleAccess))
        .filter(
          ([, service]) =>
            !service?.hideOnChangeStatus &&
            (moduleHelper.enabledService(moduleAccess, service.value) || service.fixed) &&
            !service?.automated
        )
        .map(([, service]) => ({
          ...service,
        })),
    [moduleAccess]
  );

  const isHistory = useMemo(
    () => serviceStep?.value === SERVICE_STEPS(moduleAccess).history.value,
    [serviceStep, moduleAccess]
  );

  const isQueueEnabled = useMemo(() => {
    const module = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);
    return module?.[0]?.serviceStep?.isTelemedicineQueueEnabled &&
      serviceStep?.value === 'telemedicine'
      ? true
      : false;
  }, [moduleAccess, serviceStep]);

  const activeStep = useMemo(
    () =>
      services.find(
        (service) =>
          service.value ===
          (alertRecord?.evolutionRecord?.serviceStep
            ? alertRecord?.evolutionRecord?.serviceStep
            : stepActive)
      ),
    [alertRecord, services, stepActive]
  );

  useEffect(() => {
    if (!serviceStep) {
      handleServiceStep(activeStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.Container>
      <Styled.ServiceSteps>
        {services.map((sStep) => {
          return (
            <ServiceStepOption
              key={sStep.value}
              {...sStep}
              activeColor="#0071eb"
              active={serviceStep?.value === sStep.value}
              onClick={() => {
                if (serviceStep?.value !== sStep.value) {
                  handleServiceStep(sStep);
                  handleSector('');
                  handleStatusReason('');
                  handleReason('');
                }
              }}
            />
          );
        })}
      </Styled.ServiceSteps>
      {serviceStep &&
      !isQueueEnabled &&
      serviceStep?.subSteps &&
      serviceStep?.sectors?.length > 0 ? (
        <>
          <Separator />
          <Styled.Flex>
            {serviceStep?.subSteps &&
              serviceStep?.sectors
                ?.filter((sect) => !sect?.automated)
                .map((sect) => (
                  <SectorOption
                    key={sect.id}
                    label={sect.name}
                    active={sector === sect}
                    onClick={() => {
                      handleSector(sect);
                    }}
                  />
                ))}
          </Styled.Flex>
        </>
      ) : null}
      {isHistory ? (
        <>
          <StyledBase.Label align="center">1. Motivo</StyledBase.Label>
          <Styled.Flex>
            {statusReason.map(({ value, label }) => (
              <SectorOption
                key={value.slug}
                label={label}
                active={status === value}
                onClick={() => {
                  if (status !== value) {
                    handleStatusReason(value);
                    handleReason('');
                  }
                }}
              />
            ))}
          </Styled.Flex>
        </>
      ) : null}
      {isHistory &&
        !isCancelling &&
        [StatusDictionary.OBSERVATION, StatusDictionary.CRITIC].includes(status) && (
          <>
            <Separator />
            <Styled.Flex>
              <div className="col-md-6">
                <Input
                  label="Número do Atendimento (opcional)"
                  name="hospitalMedicalRecord"
                  type="number"
                  ref={inputRef}
                />
              </div>
            </Styled.Flex>
          </>
        )}

      {isHistory && status === StatusDictionary.NOT_CRITIC ? (
        <>
          <StyledBase.Label align="center">2. Resolução</StyledBase.Label>
          <Styled.Flex>
            {reasons.map(({ value, label }) => (
              <SectorOption
                key={value.slug}
                label={label}
                active={reason === value}
                onClick={() => {
                  handleReason(value);
                }}
              />
            ))}
          </Styled.Flex>
        </>
      ) : null}
    </Styled.Container>
  );
};

export default ListServiceStep;

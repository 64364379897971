import { BoxProps, chakra } from '@chakra-ui/react';

export const Card = chakra<'div', BoxProps>('div', {
  baseStyle: {
    padding: 6,
    backgroundColor: 'white',
    rounded: 'lg',
    shadow: 'lg',
  },
});

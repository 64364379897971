import MainButton from 'Components/MainButton';
import { Content } from 'Components/Modal/styled';
import styled from 'styled-components';

export const Message = styled.p`
  font: normal normal normal 14px/17px Roboto;
  margin-bottom: 30px;
  color: #0071eb;
`;

export const MainContent = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: none;
`;

export const MoveButton = styled(MainButton)`
  width: 372px;
  height: 40px;
  margin-bottom: 20px;
`;

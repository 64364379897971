import React from 'react';
import { useState, useReducer, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ModuleContext from 'Context/moduleContext';
import TeleAtendimento from 'Pages/TeleAtendimento';
import { MODULES } from 'Utils/Config/SectorConfig';
import cacheService from 'Services/cache';
import useCache from 'Hooks/useCache';
import LocalCache from 'Services/LocalCache';
import useMovidesk from 'Hooks/useMovidesk';
import routes from 'routes';
import DefaulLayout from 'layouts/Default';
import moduleHelper from 'Utils/Helpers/moduleHelper';

import Loader from 'Components/Loader';
import ModalSchedule from 'Components/ModalSchedule';
import ModalResendLink from 'Components/ModalResendLink';
import ModalEnterLink from 'Components/ModalEnterLink';
import { removeMemedScriptConfig } from '../../Utils/Helpers/removeMemedScriptConfig';
import ModalScheduleReminder from 'Components/ModalScheduleReminder';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_MODULE':
      cacheService.set('module_access', action.moduleAccess);
      return {
        ...state,
        moduleAccess: action.moduleAccess,
        Container: action.Container,
        stepActive: action.stepActive,
      };
    case 'CHANGE_STEP':
      cacheService.set('step_active', action.stepActive);
      return {
        ...state,
        stepActive: action.stepActive,
      };
    case 'CHANGE_SECTOR':
      cacheService.set('step_subscriptionChange', action.subscriptionChange);
      return {
        ...state,
        subscriptionChange: action.subscriptionChange,
      };
    default:
  }
};

const ContainerApp = () => {
  const [loading, handleLoading] = useState(false);

  const [user] = useCache('user');
  const [moduleAccessCache] = useCache('module_access', 'covid');
  const [stepActiveCache] = useCache('step_active', 'screening');
  const [modules] = useCache('modules');

  const history = useHistory();
  const location = useLocation();

  if (user && !modules) {
    LocalCache.deleteUser();
    LocalCache.deleteMemedToken();
    removeMemedScriptConfig();
    history.push(routes.LOGIN.path);
  }

  useMovidesk();

  const [{ moduleAccess, Container, stepActive }, moduleDispatch] = useReducer(reducer, {
    moduleAccess: moduleAccessCache,
    stepActive: stepActiveCache,
    Container: MODULES[moduleAccessCache]?.container,
  });

  const [filters, setFilters] = useState({
    tag: [],
    ubs: [],
    group: [],
  });

  const [searchKeyword, setSearchKeyword] = useState(null);

  useEffect(() => {
    const module = location.pathname.split('/')[1];
    if (module && moduleAccess !== module && moduleHelper.enabled(module)) {
      handleLoading(true);
      moduleDispatch({
        type: 'CHANGE_MODULE',
        moduleAccess: module,
        Container: MODULES[module]?.container,
        stepActive: stepActive,
      });
    }

    setTimeout(() => {
      handleLoading(false);
    }, 2100);
  }, [location, moduleAccess, stepActive]);

  return loading ? (
    <Loader modules={modules} />
  ) : (
    <ModuleContext.Provider
      value={{
        Container,
        moduleAccess,
        moduleDispatch,
        modules,
        filters,
        setFilters,
        searchKeyword,
        setSearchKeyword,
        stepActive,
      }}
    >
      {moduleAccess === 'remoteatendimento' ? <TeleAtendimento /> : <DefaulLayout />}
      <ModalSchedule />
      <ModalScheduleReminder />
      <ModalResendLink />
      <ModalEnterLink />
      {/* <ModalCancelSchedule /> */}
    </ModuleContext.Provider>
  );
};

export default ContainerApp;

import { Heading, Mark, Skeleton, Text, useToast, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { MunaiLogo } from 'theme/icons';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'Hooks/useQueryParams';
import {
  useCreateUserPasswordMutation,
  useInviteTokenLazyQuery,
} from './__generated__/CreatePassword.generated';
import { PasswordForm, PasswordFormValues } from './PasswordForm';

export const inviteTokenQuery = gql`
  query InviteToken($token: String!) {
    inviteToken(token: $token) {
      email
      isValid
    }
  }
`;

export const createUserPasswordMutation = gql`
  mutation CreateUserPassword($input: CreateUserPasswordInput!) {
    createUserPassword(input: $input) {
      success
      error
    }
  }
`;

export const CreatePassword = () => {
  const [loadingToken, setLoadingToken] = useState(true);

  const toast = useToast();
  const history = useHistory();
  const [token] = useQueryParams('token');

  const onNetworkError = useCallback(({ networkError }) => {
    if (networkError) {
      toast({
        status: 'error',
        title: 'Erro de conexão',
        description: 'Não foi possível conectar-se aos nossos servidores no momento.',
        position: 'top',
        isClosable: true,
      });
    }
  }, []);

  const showExpiredTokenToast = useCallback(() => {
    toast({
      status: 'error',
      title: 'Link expirou',
      description: 'O link utilizado expirou. Peça ao administrador que gere um novo convite.',
      position: 'top',
      isClosable: true,
    });
  }, []);

  const [loadInviteToken, { data }] = useInviteTokenLazyQuery({
    onError: onNetworkError,
    onCompleted: ({ inviteToken }) => {
      if (!inviteToken?.isValid) {
        showExpiredTokenToast();
        history.replace('login');
      }

      setLoadingToken(false);
    },
  });

  const [createUserPassword] = useCreateUserPasswordMutation({
    onError: onNetworkError,
    onCompleted: ({ createUserPassword }) => {
      if (createUserPassword?.success) {
        toast({
          status: 'success',
          title: 'Sucesso',
          description: 'Sua senha foi configurada.',
          position: 'top',
          isClosable: true,
        });
      } else {
        showExpiredTokenToast();
      }
      history.replace('login');
    },
  });

  useEffect(() => {
    if (token) {
      loadInviteToken({ variables: { token } });
    } else {
      history.replace('login');
    }
  }, [token]);

  const onSubmit = useCallback(({ password }: PasswordFormValues) => {
    if (token) {
      createUserPassword({
        variables: {
          input: {
            token,
            password,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <VStack alignItems="flex-start" spacing="4" marginBottom="6">
        <MunaiLogo boxSize="10" />
        <Heading size="lg" fontWeight="light">
          Bem vindo <br />
          ao{' '}
          <Mark color="#A04FC6" padding={0}>
            Digital Care
          </Mark>
        </Heading>
      </VStack>
      <VStack spacing="1" align="start" marginBottom="5">
        <Text
          fontSize="md"
          fontWeight="medium"
          opacity={loadingToken ? 0.4 : 1}
          transitionProperty="opacity"
          transitionDuration="normal"
        >
          E-mail
        </Text>
        <Skeleton isLoaded={!loadingToken} minWidth="140">
          <Text>{data?.inviteToken?.email ?? <>&nbsp;</>}</Text>
        </Skeleton>
      </VStack>
      <PasswordForm onSubmit={onSubmit} isDisabled={loadingToken} />
    </>
  );
};

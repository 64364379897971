const baseStylePreview = {
  borderRadius: 'md',
  py: '3px',
  transitionProperty: 'common',
  transitionDuration: 'normal',
};

const baseStyleInput = {
  borderRadius: 'md',
  py: '3px',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  width: 'full',
  _focusVisible: { boxShadow: 'outline' },
  _placeholder: { opacity: 0.6 },
};

const baseStyleTextarea = {
  borderRadius: 'md',
  py: '3px',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  width: 'full',
  _focusVisible: { boxShadow: 'outline' },
  _placeholder: { opacity: 0.6 },
};

const baseStyle = {
  preview: baseStylePreview,
  input: baseStyleInput,
  textarea: baseStyleTextarea,
};

export const editableTheme = {
  baseStyle,
};

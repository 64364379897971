import styled from 'styled-components';

import info from 'Assets/icons/info.svg';
import infoHover from 'Assets/icons/info-hover.svg';

export const Container = styled.div`
  width: 21px;
  height: 21px;
  background-image: url(${info});
  background-position: center;
  background-repeat: none;
  background-size: cover;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-image: url(${infoHover});
  }
`;

export const ContainerHelp = styled.div`
  font-size: 14px;
  margin-bottom: 0px;
  padding: 5px 5px 0px;
`;

export const List = styled.ul`
  list-style: none;
  margin-top: 5px;
  padding: 5px 5px 0px;
`;

export const ListItem = styled.li`
  padding: 3px 0px;
`;

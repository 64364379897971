import { shape, string, oneOf, arrayOf, bool, number } from 'prop-types';
import { mdiCheck, mdiUpdate, mdiAlertCircleOutline } from '@mdi/js';

export const sizes = () => ({
  minimized: {
    maxHeight: 48,
    height: 48,
    width: 200,
    inner: 320,
  },
  normal: {
    maxHeight: 492,
    width: 320,
    height: 492,
    inner: 280,
  },
  expanded: {
    maxHeight: window?.innerHeight > 840 ? 698 : window?.innerHeight - 100,
    width: 512,
    height: window?.innerHeight > 840 ? 698 : window?.innerHeight - 100,
    inner: window?.innerHeight > 840 ? 485 : window?.innerHeight - 312,
  },
});

export const reducerChat = {
  OPEN_CHAT: 'OPEN_CHAT',
  CLOSE_CHAT: 'CLOSE_CHAT',
  CLEAR_CHATS: 'CLEAR_CHATS',
  SET_NEW_MESSAGE: 'SET_NEW_MESSAGE',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_FILTERS: 'SET_FILTERS',
  TOGGLE_EXPAND_CHAT: 'TOGGLE_EXPAND_CHAT',
  TOGGLE_MINIMIZE_CHAT: 'TOGGLE_MINIMIZE_CHAT',
  SET_HANDOVER: 'SET_HANDOVER',
  TOGGLE_MINIMIZE_PREVIOUS_CHAT: 'TOGGLE_MINIMIZE_PREVIOUS_CHAT',
  SET_CHAT_TO_ACTIVE: 'SET_CHAT_TO_ACTIVE',
  REORDER_CHATS: 'REORDER_CHATS',
  REMOVE_PREVIOUS_CHAT: 'REMOVE_PREVIOUS_CHAT',
  SET_TOTAL_PAGES_CHAT: 'SET_TOTAL_PAGES_CHAT',
  HANDLE_NEXT_PAGE: 'HANDLE_NEXT_PAGE',
  RESET_PAGINATION: 'RESET_PAGINATION',
  MERGE_ORIGINAL_DATA: 'MERGE_ORIGINAL_DATA',
  SET_LOAD_MENU: 'SET_LOAD_MENU',
};

export const chatData = {
  senderId: '',
  title: '',
  loading: false,
  submittingNewMessage: false,
  page: 1,
  isLastPage: false,
  totalPages: 0,
  limit: 10,
  messages: [],
  newMessage: '',
  expanded: false,
  minimized: false,
  handoverActive: false,
  original: {},
};

export const CHAT_STATUS = {
  sended: mdiCheck,
  wait: mdiUpdate,
  error: mdiAlertCircleOutline,
};

export const CHAT_STATUS_COLOR = {
  sended: '#0071eb',
  wait: '#A9A9A9',
  error: '#A9A9A9',
};

export const statusMessageEnum = oneOf(['wait', 'sended', 'error']);
export const eventMessageEnum = oneOf(['bot', 'user', 'handover']);

export const patientDataType = shape({
  patient: shape({
    nome: string,
  }),
  evolutionNote: shape({}),
  medicalRecord: shape({}),
});

export const chatMessage = {
  /**
   * Uuid
   */
  _id: string,
  /**
   * Event message
   */
  event: eventMessageEnum,
  /**
   * Message content
   */
  text: string.isRequired,
  /**
   * Timestamp message
   */
  timestamp: string.isRequired,
  /**
   * Handover message
   */
  handover: bool,
  /**
   * Status message
   */
  status: statusMessageEnum,
};

export const chat = {
  /**
   * Sender identify
   */
  senderId: string.isRequired,
  /**
   * Title chat
   */
  title: string.isRequired,
  /**
   * Array of messages
   */
  messages: arrayOf(shape(chatMessage)),
  /**
   * New message value to send
   */
  newMessage: string,
  /**
   * Is expanded chat container
   */
  expanded: bool,
  /**
   * Is minimized chat container
   */
  minimized: bool,
  /**
   * Enable / disable chat automatic bot
   */
  handoverActive: bool,
  /**
   * Page list on chat
   */
  page: number,
};

import { useRef } from 'react';
import chroma from 'chroma-js';

import * as Styled from './styled';

const customStyles = {
  multiValue: (styles, { data }) => {
    if (data.color) {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: `${color.alpha(0.1).css()} !important`,
      };
    }

    return { ...styles };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
};

type OwnProps = {
  activeColor?: string;
  color?: string;
  creatable?: boolean;
  fontSize?: number;
  fontWeight?: number;
  icon?: string;
  iconColor?: string;
  label?: string;
  menuPosition?: 'fixed' | 'absolute';
  width?: string;
};

type Props = OwnProps & typeof RSelect.defaultProps;

const RSelect = ({ creatable, label, icon, iconColor, ...props }: Props) => {
  let menuIsOpen = false;
  const refSelect = useRef();

  const CustomChevronDow = () => <Styled.ChevronDown active={menuIsOpen} />;

  const CustomSingleValue = ({ data }) => {
    if (refSelect.current) {
      const { state } = refSelect.current;
      menuIsOpen = state?.menuIsOpen;
    }

    return (
      <Styled.SingleValue className="react-select__single-value" active={menuIsOpen}>
        {icon ? (
          <Styled.Icon path={icon} size={1} color={menuIsOpen ? props.activeColor : iconColor} />
        ) : (
          data.icon && (
            <Styled.Icon
              path={data.icon}
              size={data.iconSize || 1}
              color={menuIsOpen ? props.activeColor : data.iconColor}
            />
          )
        )}
        {data.label}
      </Styled.SingleValue>
    );
  };

  const CustomSelectOption = (p) => (
    <Styled.Option {...p}>
      {p.data.icon && (
        <Styled.Icon path={p.data.icon} size={p.data.iconSize} color={p.data.iconColor} />
      )}
      {p.data.label}
    </Styled.Option>
  );

  const CustomContainer = (p) => (
    <Styled.Container width={props.width}>
      <Styled.Label>{label}</Styled.Label>
      <Styled.Control {...p} />
    </Styled.Container>
  );

  const customProps = {
    loadingMessage: () => 'carregando...',
    classNamePrefix: 'react-select',
    noOptionsMessage: () => 'Nenhuma opção encontrada',
    components: {
      Control: CustomContainer,
      SingleValue: CustomSingleValue,
      Option: CustomSelectOption,
      DropdownIndicator: CustomChevronDow,
    },
    styles: customStyles,
    ref: refSelect,
  };

  if (props.loadOptions) {
    if (creatable) {
      return <Styled.AsyncCreatableSelectWrapper {...props} {...customProps} />;
    }

    return <Styled.AsyncSelectWrapper {...props} {...customProps} />;
  }

  if (creatable) {
    return <Styled.CreatableSelectWrapper {...props} {...customProps} />;
  }

  return <Styled.SelectWrapper {...props} {...customProps} />;
};

RSelect.defaultProps = {
  activeColor: '#0071eb',
  color: '#444444',
  menuPosition: 'fixed',
  creatable: false,
  label: null,
  width: '100%',
  fontSize: 14,
  fontWeight: 500,
  icon: null,
  iconColor: '#444444',
};

export default RSelect;

import LocalCache from 'Services/LocalCache';

export default {
  to: (path, variables = {}) => {
    const tmpVariables = {
      ...variables,
      module: variables?.module ? variables?.module : LocalCache.getModule(),
    };

    return path.replace(/([:])+([a-zA-Z]+)/g, (_, __, variable) => {
      if (!tmpVariables[variable]) {
        throw new Error(`Variable ${variable} is not defined`);
      }

      return tmpVariables[variable];
    });
  },
};

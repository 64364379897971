import { memo } from 'react';

import { Styled } from './styles';
import logo from '../../../Assets/logo_nissan.jpeg';

import getInitials from '../../utils/getInitials';
import moment from 'moment';

const ConversationMessage = (props) => {
  const { message, data } = props;

  const messager = (message) => {
    if (message && message.event === 'user') {
      return (
        <Styled.User>
          <Styled.UserAvatar>
            {' '}
            {data && data.name === '' ? 'NC' : getInitials(data.name)}
          </Styled.UserAvatar>
          <Styled.UserText>
            <Styled.TextUser>{message.text}</Styled.TextUser>
            <span> {moment(message.timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')}</span>
          </Styled.UserText>
        </Styled.User>
      );
    } else if (message && message.event === 'bot') {
      return (
        <Styled.Bot>
          <Styled.BotText>
            <Styled.TextBot>{message.text}</Styled.TextBot>
            <span> {moment(message.timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')}</span>
          </Styled.BotText>
          <Styled.Avatar src={logo} alt="company" />
        </Styled.Bot>
      );
    }
  };

  return <>{messager(message)}</>;
};

export default memo(ConversationMessage);

export default {
  UNAUTHENTICATED: {
    LOGIN: '/',
    RECOVER_PASSWORD: '/recover-password',
    STATUS: '/status',
  },
  DASHBOARD: '/dashboard',
  HISTORY: '/covid/history',
  ACTIVE: '/active/:sector',
  ALL: '/covid/all',
  INTENTIONS: '/covid/intentions',
  CHECKOUT: '/covid/waiting',
  MONITORING: '/covid/monitoring',
  TOWN_MONITORING: '/covid/town_monitoring',
  FORWARDED: '/covid/forwarded',
  HELP: '/help',
  SETTINGS: {
    SETTINGS: '/settings',
    USERS: '/settings/users',
    TELEMEDICINE: '/settings/telemedicine',
  },
  CHAT_WAITING: '/chat/waiting',
  CHAT_PROGRESS: '/chat/progress',
  CHAT_ADJOURNED: '/chat/adjourned',
  TRANSFERRED: '/telemedicine/transferred',
  SCHEDULE: '/telemedicine/schedule',
  IN_PROGRESS: '/telemedicine/in_progress',
  TELEMEDICINE_HISTORY: '/telemedicine/history',
  TELEMEDICINE_QUEUE: '/telemedicine/queue',
  GENERALIST_TRANSFERRED: '/generalist/all',
  GENERALIST_IN_PROCESS: '/generalist/in_process',
  GENERALIST_HISTORY: '/generalist/history',
  GENERALIST_MONITORING: '/generalist/monitoring',
  GENERALIST_QUEUE: '/generalist/queue',
  NISSAN: '/nissan/all',
  NISSAN_IN_AWAITING: '/nissan/awaiting',
  NISSAN_HUMAN: '/nissan/human',
  NISSAN_CLOSED: '/nissan/closed',
  NISSAN_HISTORY: '/history',
  NISSAN_IMPORT: '/nissan/import',

  TELEATENDIMENTO: '/teleatendimento/all',
  TELEATENDIMENTO_IN_AWAITING: '/teleatendimento/awaiting',
  TELEATENDIMENTO_HUMAN: '/teleatendimento/human',
  TELEATENDIMENTO_CLOSED: '/teleatendimento/closed',
  TELEATENDIMENTO_HISTORY: '/other/history',
  TELEATENDIMENTO_IMPORT: '/other/import',
  TELEATENDIMENTO_CONTACT_ACTIVE: '/other/contact',
  TELEATENDIMENTO_OUTSIDE: '/other/outside',
};

import BurguerMenu from 'Components/BurgerMenu';

import { MunaiTipo } from 'theme/icons';

import * as Styled from './styled';

type OwnProps = {
  background?: string;
  children: React.ReactElement[] | React.ReactElement;
  options?: {
    label: string;
    icon: string;
    onClick?: (...args: any[]) => any;
    href?: string;
  }[];
};

const Header = ({ children, options = [], ...props }: OwnProps) => {
  return (
    <Styled.Container {...props}>
      <MunaiTipo boxSize="16" />
      <Styled.Content>
        <Styled.Children>{children}</Styled.Children>
        {options.length && (
          <Styled.RightSection>
            <BurguerMenu items={options} />
          </Styled.RightSection>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

Header.Divider = Styled.Divider;
Header.Col = Styled.Col;

export default Header;

import { Timeline } from '../../styled';

const TimelineMonitoringStatus = ({ timelineItem, shouldShow }) => (
  <>
    {shouldShow ? (
      <Timeline.Body>
        <Timeline.Body.Item style={{ fontSize: '0.812rem', color: '#A9A9A9' }}>
          {timelineItem}
        </Timeline.Body.Item>
      </Timeline.Body>
    ) : null}
  </>
);

export default TimelineMonitoringStatus;

import routesDictionary from 'Utils/Dictionary/routesDictionary';

export const SectorDictionary = {
  DASHBOARD: {
    slug: 'DASHBOARD',
    background: '#FF9955',
    color: '#FF6633',
    name: 'Pacientes para avaliação',
    module: 'etapa de Triagem',
    icon: 'mdi-account-edit',
    route: routesDictionary.ALL,
    type: 1,
    id: '1',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('covid') &&
      collection.patient &&
      collection.patient.cpf &&
      (collection?.evolutionRecord?.serviceStep === 'screening' ||
        collection?.evolutionRecord === null),
  },
  INTENTIONS: {
    slug: 'INTENTIONS',
    background: '#FFB399',
    color: '#FFB355',
    name: 'Pacientes confirmados',
    module: 'PACIENTES CONFIRMADOS',
    icon: 'mdi-account-check',
    route: routesDictionary.INTENTIONS,
    type: 1,
    id: '2',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('covid') &&
      collection.patient &&
      collection.patient.cpf,
  },
  CHECKOUT: {
    slug: 'CHECKOUT',
    background: '#FBECFF',
    color: '#9550A5',
    name: 'Pacientes aguardando no PA',
    icon: 'mdi-account-alert',
    module: 'PACIENTES AGUARDANDO NO PA',
    route: routesDictionary.CHECKOUT_,
    type: 1,
    id: '3',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('covid') &&
      collection.patient &&
      collection.patient.cpf &&
      (collection.evolutionRecord && collection.evolutionRecord.setor !== null
        ? collection.evolutionRecord.setor === SectorDictionary.CHECKOUT.slug
        : collection.hospitalConfirmation &&
          !collection.monitoring &&
          !collection.evolutionRecord &&
          true),
  },
  MONITORING: {
    slug: 'MONITORING',
    background: '#FBECFF',
    color: '#9550A5',
    name: 'Pacientes monitorados',
    icon: 'mdi-account-clock',
    module: 'etapa de Monitorados pela Munai',
    route: routesDictionary.MONITORING,
    type: 1,
    id: '4',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('covid') &&
      collection.patient &&
      collection.patient.cpf &&
      (collection.evolutionRecord && collection.evolutionRecord.setor !== null
        ? collection.evolutionRecord.setor === SectorDictionary.MONITORING.slug
        : collection.monitoring && true),
  },
  HISTORY: {
    slug: 'HISTORY',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Finalizado',
    module: 'etapa de Histórico',
    icon: 'mdi-account-arrow-right',
    route: routesDictionary.HISTORY,
    type: 2,
    id: '5',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.serviceStep === 'history',
  },
  TOWN_MONITORING: {
    slug: 'TOWN_MONITORING',
    background: '#99FF99',
    color: '#99FF11',
    name: 'Monitorado pela Prefeitura',
    icon: 'mdi-account-clock',
    module: 'PACIENTES MONITORADOS PELA PREFEITURA',
    id: '6',
    type: 1,
    route: routesDictionary.TOWN_MONITORING,
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('covid') &&
      collection.patient &&
      collection.patient.cpf &&
      (collection.evolutionRecord && collection.evolutionRecord.setor !== null
        ? collection.evolutionRecord.setor === SectorDictionary.TOWN_MONITORING.slug
        : collection.monitoring && true),
  },
  FORWARDED: {
    slug: 'FORWARDED',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Direcionado UPA/Hospital',
    icon: 'mdi-account-alert',
    module: 'PACIENTES DIRECIONADOS PARA UPA/HOSPITAL',
    route: routesDictionary.FORWARDED,
    type: 1,
    id: '7',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.patient &&
      collection.patient.cpf,
  },
  // TRANSFERRED: {
  //   slug: 'TRANSFERRED',
  //   background: '#FF881F',
  //   color: '#FF881F',
  //   name: 'Pacientes transferidos',
  //   icon: 'mdi-account-clock',
  //   module: 'etapa de Vídeo -> Em espera',
  //   route: routesDictionary.TRANSFERRED,
  //   type: 1,
  //   id: '8',
  //   rule: (collection) =>
  //     (collection.vitalSigns || collection.symptoms) &&
  //     collection.alert &&
  //     collection.process &&
  //     collection.process.includes('telemedicine') &&
  //     collection?.telemedicine?.evolutionRecord.setor === 'TRANSFERRED' &&
  //     collection.medicalRecord &&
  //     collection.patient &&
  //     collection.patient.cpf,
  // },
  SCHEDULE: {
    slug: 'SCHEDULE',
    background: '#FF881F',
    color: '#FF881F',
    name: 'Pacientes agendados',
    icon: 'mdi-account-clock',
    module: 'etapa de Vídeo -> Agendado',
    route: routesDictionary.SCHEDULE,
    type: 1,
    id: '13',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.process &&
      collection.process.includes('telemedicine') &&
      collection?.telemedicine?.evolutionRecord.setor === 'SCHEDULE' &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf,
  },
  IN_PROGRESS: {
    slug: 'IN_PROGRESS',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes em atendimento',
    icon: 'mdi-doctor',
    module: 'etapa de Vídeo -> Em atendimento',
    route: routesDictionary.IN_PROGRESS,
    type: 1,
    id: '9',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('telemedicine') &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.telemedicine?.evolutionRecord.setor === 'IN_PROGRESS',
  },
  TELEMEDICINE_HISTORY: {
    slug: 'HISTORY',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes Atendidos',
    icon: 'mdi-account-check',
    module: 'PACIENTES ATENDIDOS',
    route: routesDictionary.TELEMEDICINE_HISTORY,
    type: 1,
    id: '10',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('telemedicine') &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.telemedicine?.evolutionRecord.setor === 'HISTORY',
  },
  TELEMEDICINE_QUEUE: {
    slug: 'AWAITING',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes na fila de espera',
    icon: 'mdi-account-check',
    module: 'PACIENTES NA FILA DE ESPERA',
    route: routesDictionary.TELEMEDICINE_QUEUE,
    type: 1,
    id: '11',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.process &&
      collection.process.includes('telemedicine') &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.telemedicine?.evolutionRecord.setor === 'AWAITING',
  },
  GENERALIST_QUEUE: {
    slug: 'AWAITING',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes aguardando no PA',
    icon: 'mdi-account-check',
    module: 'PACIENTES AGUARDANDO NO PA',
    route: routesDictionary.GENERALIST_QUEUE,
    type: 1,
    id: '11',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.process &&
      collection.process.includes('generalist') &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.setor === 'AWAITING',
  },
  GENERALIST_IN_PROGRESS: {
    slug: 'GENERALIST_IN_PROGRESS',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes confirmados',
    icon: 'mdi-doctor',
    module: 'PACIENTES CONFIRMADOS',
    route: routesDictionary.GENERALIST_IN_PROCESS,
    type: 1,
    id: '9',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.process &&
      collection.process.includes('generalist') &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.setor === 'GENERALIST_IN_PROGRESS',
  },
  ELIGIBLE: {
    slug: 'ELIGIBLE',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Elegíveis',
    module: 'ELEGÍVEIS',
    icon: 'mdi-account-arrow-right',
    route: routesDictionary.HISTORY,
    type: 1,
    id: '12',
    rule: (collection) =>
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.serviceStep === 'eligible',
  },
};

export const SectorROUTES = {
  all: SectorDictionary.DASHBOARD,
  intentions: SectorDictionary.INTENTIONS,
  waiting: SectorDictionary.CHECKOUT,
  monitoring: SectorDictionary.MONITORING,
  town_monitoring: SectorDictionary.TOWN_MONITORING,
  forwarded: SectorDictionary.FORWARDED,
  schedule: SectorDictionary.SCHEDULE,
  in_progress: SectorDictionary.IN_PROGRESS,
  queue: SectorDictionary.TELEMEDICINE_QUEUE,
  history: SectorDictionary.TELEMEDICINE_HISTORY,
  telemedicine: SectorDictionary.SCHEDULE,
};

export const SectorModuleROUTES = {
  monitoring: SectorDictionary.MONITORING,
  telemedicine: SectorDictionary.SCHEDULE,
};

import { isProduction, hostnameVars } from 'Utils/Config/hostnameVars';

export default {
  isProduction: isProduction(),
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  VERSION: require('../../../package.json').version,
  GRAPHQL_ENDPOINT: hostnameVars('GRAPHQL_ENDPOINT') as string,
  GRAPHQL_WEBSOCKET_ENDPOINT: hostnameVars('GRAPHQL_WEBSOCKET_ENDPOINT') as string,
  CHATBOT_STREAM: hostnameVars('CHATBOT_STREAM'),
  HOTJAR_ID: hostnameVars('HOTJAR_ID'),
  HOTJAR_SV: hostnameVars('HOTJAR_SV'),
  BUGSNAG: hostnameVars('BUGSNAG'),
  MEMED_URL: hostnameVars('MEMED_URL'),
  MEMED_API_URL: hostnameVars('MEMED_API_URL'),
  NISSAN_RASA: hostnameVars('NISSAN_RASA'),
  NISSAN_CHATBOT_STREAM: hostnameVars('NISSAN_CHATBOT_STREAM'),
  NISSAN_CHATBOT_API: hostnameVars('NISSAN_CHATBOT_API'),
  RASA_AUTH: hostnameVars('NISSAN_RASA_AUTH'),
  LANDING_TRIAGE: hostnameVars('LANDING_TRIAGE'),
  AMPLITUDE: hostnameVars('AMPLITUDE'),
  AMPLITUDE_API_KEY: hostnameVars('AMPLITUDE'),
  SENTRY_DSN: hostnameVars('SENTRY_DSN'),
  SENTRY_ENVIRONMENT: hostnameVars('SENTRY_ENVIRONMENT'),
  SENTRY_TRACES_SAMPLE_RATE: hostnameVars('SENTRY_TRACES_SAMPLE_RATE'),
  UNLEASH_APP_NAME: hostnameVars('UNLEASH_APP_NAME'),
  UNLEASH_APP_ENV: hostnameVars('UNLEASH_APP_ENV'),
  UNLEASH_PROXY_URL: hostnameVars('UNLEASH_PROXY_URL'),
  UNLEASH_CLIENT_KEY: hostnameVars('UNLEASH_CLIENT_KEY'),
  UNLEASH_METRICS_INTERVAL: hostnameVars('UNLEASH_METRICS_INTERVAL'),
  UNLEASH_REFRESH_INTERVAL: hostnameVars('UNLEASH_REFRESH_INTERVAL'),
};

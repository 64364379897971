import { cssVar } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const baseStyle = (props) => {
  const defaultStartColor = 'gray.100';
  const defaultEndColor = 'gray.300';

  const { startColor = defaultStartColor, endColor = defaultEndColor, theme } = props;

  const start = getColor(theme, startColor);
  const end = getColor(theme, endColor);

  return {
    [$startColor.variable]: start,
    [$endColor.variable]: end,
    opacity: 0.7,
    borderRadius: 'sm',
    borderColor: start,
    background: end,
  };
};

export const skeletonTheme = {
  baseStyle,
};

import { useContext } from 'react';
import { HelperText, ScheduleTimesContainer } from './styled';
import { gql } from '@apollo/client';
import ScheduleTimesItem from './ScheduleTimesItem';
import { AnimatePresence } from 'framer-motion';
import useCache from 'Hooks/useCache';
import ModuleContext from 'Context/moduleContext';
import { ModalScheduleReminderActions } from 'store/ducks/modal_schedule_reminder';
import { useDispatch } from 'react-redux';
import Spinner from 'Components/Spinner';
import { useGetTeleconsultationScheduleSlotsQuery } from './__generated__/ScheduleTimes.generated';

export const getTeleconsultationScheduleSlotsQuery = gql`
  query getTeleconsultationScheduleSlots($entityId: Int!, $date: String!, $module: String!) {
    getTeleconsultationScheduleSlots(entityId: $entityId, date: $date, module: $module) {
      startTime
      endTime
      scheduledConsultations
    }
  }
`;

/**
 * Componente responsável pela listagem dos horários disponíveis para o dia
 * @param {{}} props
 */
export default function ScheduleTimes({ date, value, height }: Record<string, any>) {
  const [user] = useCache('user');
  const { moduleAccess } = useContext(ModuleContext);
  const dispatch = useDispatch();
  const { data, loading, error } = useGetTeleconsultationScheduleSlotsQuery({
    variables: {
      entityId: parseInt(user.entityId),
      date: date || '',
      module: moduleAccess,
    },
  });

  const onChange = (item) => {
    dispatch(ModalScheduleReminderActions.modalScheduleSetInterval(item));
  };

  if (!date) {
    return null;
  }

  const renderScheduleTimes = (item, index) => {
    const selected =
      value?.startTime.toString() === item.startTime.toString() &&
      value?.endTime.toString() === item.endTime.toString();
    return <ScheduleTimesItem key={index} selected={selected} item={item} onClick={onChange} />;
  };

  if (loading) {
    return (
      <HelperText>
        <Spinner color="#444" size="md" />
      </HelperText>
    );
  }

  if (error) {
    return <HelperText>{error}</HelperText>;
  }

  const correctTimezoneOffsetPredicate = (
    item: {
      __typename?: 'TeleconsultationSlots' | undefined;
      startTime?: any;
      endTime?: any;
      scheduledConsultations?: number | null | undefined;
    } | null
  ) => {
    const { startTime, endTime, scheduledConsultations } = item!;

    const startTimeDate = new Date(startTime);
    const endTimeDate = new Date(endTime);

    const startTimeWithOffset = startTimeDate.setMinutes(
      startTimeDate.getMinutes() + startTimeDate.getTimezoneOffset()
    );
    const endTimeWithOffset = endTimeDate.setMinutes(
      endTimeDate.getMinutes() + endTimeDate.getTimezoneOffset()
    );

    return {
      startTime: new Date(startTimeWithOffset),
      endTime: new Date(endTimeWithOffset),
      scheduledConsultations,
    };
  };

  return (
    <AnimatePresence>
      <ScheduleTimesContainer
        height={height}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            ease: 'easeOut',
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
      >
        {data?.getTeleconsultationScheduleSlots
          ?.map(correctTimezoneOffsetPredicate)
          .map(renderScheduleTimes)}
      </ScheduleTimesContainer>
    </AnimatePresence>
  );
}

import {
  forwardRef,
  useContext,
  useState,
  useRef,
  useMemo,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useAsyncDebounce } from 'react-table';

import TextArea from 'Components/TextArea';
import ChatContext from 'Components/Chat/context';
import MessageList from 'Components/Chat/components/MessageList';
import { getOperatingSystem } from 'Utils';

import * as Styled from './styled';
import { SYSTEM } from 'Utils/types';

const Container = forwardRef((props, ref) => {
  const {
    newMessage,
    handleNewMessage,
    handoverActive,
    expanded,
    senderId,
    minimized,
    sendMessage,
    loading,
    myCurrentChat,
    needActiveContact,
    awaitingPatientResponse,
  } = useContext(ChatContext);
  const [resetExpanded, handleResetExpanded] = useState(0);
  const OS = getOperatingSystem(window);
  const handleOnChange = useAsyncDebounce((value) => {
    handleNewMessage(value);
  }, 150);

  const listRef = useRef();
  const editorRef = useRef();

  const isValid = useMemo(
    () => Boolean(newMessage?.trim()) && handoverActive,
    [newMessage, handoverActive]
  );

  // TODO: adicionar awaitingPatientResponse novamente quando estiver funcionando
  const isDisabled = useMemo(() => {
    return (
      loading || !handoverActive || !myCurrentChat || needActiveContact || awaitingPatientResponse
    );
  }, [loading, handoverActive, myCurrentChat, needActiveContact, awaitingPatientResponse]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Carregando conversa...';
    }

    if (!handoverActive) {
      return 'Bot em atendimento';
    }

    if (!myCurrentChat) {
      return 'Paciente já está em atendimento';
    }

    if (needActiveContact) {
      return 'Envie um novo contato ativo';
    }

    if (awaitingPatientResponse) {
      return 'Aguarde o paciente responder para enviar outra mensagem';
    }

    return 'Escreva uma mensagem';
  }, [loading, handoverActive, myCurrentChat, needActiveContact, awaitingPatientResponse]);

  useEffect(
    () => handleResetExpanded((prev) => prev + 1),
    [expanded, minimized, handleResetExpanded]
  );

  const handleInternalNewMessage = (newMessage) => {
    handleOnChange(newMessage);
  };

  useImperativeHandle(ref, () => ({
    editorFocus() {
      editorRef?.current?.focus();
    },
    editorClear() {
      editorRef?.current?.clear();
    },
    editorResetExpanded() {
      handleResetExpanded((prev) => prev + 1);
    },
    listRef,
  }));

  const onKeyDown = (e) => {
    if (!isValid) return;

    if (OS === SYSTEM.MAC) {
      if (e.keyCode === 13 && e.metaKey) {
        sendMessage();
      }
    } else {
      if (e.keyCode === 13 && e.ctrlKey) {
        sendMessage();
      }
    }
  };

  return (
    <Styled.Container ref={ref} {...props}>
      <MessageList ref={listRef} id={`${senderId}-message-list`} />
      <TextArea
        ref={editorRef}
        initialValue={newMessage}
        onChange={handleInternalNewMessage}
        top={listRef?.current?.height + 8}
        expandable
        direction="top"
        width={expanded ? '94%' : '90%'}
        padding={16}
        placeholder={placeholder}
        disabled={isDisabled}
        resetExpanded={resetExpanded}
        onKeyDown={onKeyDown}
      />
    </Styled.Container>
  );
});

Container.displayName = 'Container';

export default Container;

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { sizes } from './types';

export const Container = styled(motion.div).attrs((props) => ({
  expanded: props.expanded,
  minimized: props.minimized,
}))`
  background: #ffffff;
  box-shadow: 0px 3px 15px #0909091a;
  border-radius: 5px 5px 0px 0px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ expanded, minimized }) => css`
    height: ${minimized
      ? sizes().minimized.height
      : expanded
      ? sizes().expanded.height
      : sizes().normal.height}px;
    width: ${minimized
      ? sizes().minimized.width
      : expanded
      ? sizes().expanded.width
      : sizes().normal.width}px;
  `}
`;

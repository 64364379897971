import { useContext } from 'react';
import { ModalConfirmationActions } from 'store/ducks/modal_confirmation';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiInformation, mdiLink, mdiMessageTextOutline } from '@mdi/js';

import Modal from 'Components/Modal';
import { Styled } from './styled';
import Button from 'Components/Button';
import { useTelemedicineQueueEnabled } from 'Hooks/useTelemedicineQueueEnabled';
import { useTelemedicineScheduleEnabled } from 'Hooks/useTelemedicineScheduleEnabled';
import { useMutation } from '@apollo/client';
import { ADD_ELIGIBLE_PATIENT } from 'repository/mutations';
import LocalCache from 'Services/LocalCache';
import { toast } from 'react-toastify';
import Toast from 'Components/Toast';
import ModuleContext from 'Context/moduleContext';
import constants from '../../constants';

export default function ModalConfirmation() {
  const dispatch = useDispatch();
  const { moduleAccess } = useContext(ModuleContext);

  const { isOpen, serviceStep, data, modeRegister } = useSelector(
    ({ modal_confirmation: { isOpen, serviceStep, data, modeRegister } }) => ({
      isOpen,
      serviceStep,
      data,
      modeRegister,
    })
  );

  const isTelemedicineQueueEnabled = useTelemedicineQueueEnabled();
  const isTelemedicineScheduleEnabled = useTelemedicineScheduleEnabled();

  const handleClose = () => {
    dispatch(ModalConfirmationActions.modalConfirmationSetIsOpen(false));
  };

  const isTriageChatOrEligibleStep = () => {
    return (
      serviceStep?.label === constants.triageStep ||
      serviceStep?.label === constants.chatStep ||
      serviceStep?.label === constants.elegibleStep
    );
  };

  const isVideoStepAndHasQueue = () => {
    return serviceStep?.label === constants.videoStep && isTelemedicineQueueEnabled;
  };

  const isVideoStepAndHasServiceDefault = () => {
    return (
      serviceStep?.label === constants.videoStep &&
      !isTelemedicineQueueEnabled &&
      !isTelemedicineScheduleEnabled
    );
  };

  const isMonitoredByLauraStep = () => {
    return serviceStep?.label === constants.monitoredByLauraStep;
  };

  const handleSelectServiceStep = (selectedServiceStep) => {
    if (selectedServiceStep?.label === constants.videoStep) {
      if (isTelemedicineScheduleEnabled) {
        return {
          label: selectedServiceStep?.label,
          subStep: 'SCHEDULE',
        };
      } else if (isTelemedicineQueueEnabled) {
        return {
          label: selectedServiceStep?.label,
          subStep: 'TRANSFERRED',
        };
      } else {
        return {
          label: selectedServiceStep?.label,
          subStep: '',
        };
      }
    } else {
      return {
        label: selectedServiceStep?.label,
        subStep: '',
      };
    }
  };

  const [addEligible, { loading }] = useMutation(ADD_ELIGIBLE_PATIENT, {
    variables: {
      entityId: parseInt(LocalCache.getUser().entityId),
      user: LocalCache.getUser()?.name,
      module: moduleAccess,
      complaint: data?.selectedComplaint?.value,
      flow: data?.selectedComplaint?.label,
      triage: handleSelectServiceStep(data?.selectedServiceStep),
    },
    onCompleted: () => {
      toast(
        <Toast
          type="success"
          title={`${
            isTriageChatOrEligibleStep() || isVideoStepAndHasServiceDefault()
              ? 'Inserção na etapa realizada com sucesso'
              : isVideoStepAndHasQueue()
              ? 'Inserção na etapa Vídeo concluída'
              : isMonitoredByLauraStep()
              ? 'Inserção na etapa Monitorados pela Munai concluída'
              : ''
          }`}
          subtitle={`${
            isTriageChatOrEligibleStep() || isVideoStepAndHasServiceDefault()
              ? 'Pacientes inseridos nessa etapa permanecem lá até que seus atendimentos sejam iniciados manualmente.'
              : isVideoStepAndHasQueue()
              ? 'Pacientes inseridos na etapa Vídeo já recebem o link da teleconsulta, onde podem acessar e aguardar na sala de espera até serem atendidos.'
              : isMonitoredByLauraStep()
              ? 'Pacientes inseridos na etapa Monitorados pela Munai já recebem o convite para participar do acompanhamento.'
              : ''
          }`}
        />
      );

      dispatch(ModalConfirmationActions.modalConfirmationSetOnCompleted(true));
      dispatch(ModalConfirmationActions.modalConfirmationSetLoading(false));
    },
    onError: (error) => {
      toast(
        <Toast type="error" title={'Erro ao adicionar o paciente.'} subtitle={error.message} />
      );

      dispatch(ModalConfirmationActions.modalConfirmationSetOnCompleted(false));
      dispatch(ModalConfirmationActions.modalConfirmationSetLoading(false));
    },
  });

  const submitFormRegisterPatient = () => {
    dispatch(ModalConfirmationActions.modalConfirmationSetLoading(true));
    dispatch(ModalConfirmationActions.modalConfirmationSetIsOpen(false));
    const { name, cpf, email, cellphone, birthdate } = data;
    addEligible({
      variables: {
        name,
        cpf,
        email,
        cellphone,
        birthdate,
        tags: data?.selectedTags.map((tag) => tag.value),
      },
    });
  };

  function handleSubmit() {
    if (modeRegister === constants.registerPatient) {
      submitFormRegisterPatient();
    } else {
      dispatch(ModalConfirmationActions.modalConfirmationSetIsOpen(false));
      dispatch(ModalConfirmationActions.modalConfirmationSetOnCompleted(true));
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} width="700px">
      <Styled.Container>
        <Styled.Title>Inserir paciente(s) na etapa</Styled.Title>

        <Styled.MessagesContainer>
          {isMonitoredByLauraStep() && (
            <>
              <Styled.IconContainer>
                <Icon
                  color="#0071eb"
                  path={mdiMessageTextOutline}
                  size={1.4}
                  style={{ marginBottom: '6px' }}
                />
              </Styled.IconContainer>
              <Styled.MessageTitle>Inserir e enviar mensagem</Styled.MessageTitle>

              <Styled.MessageContent>
                Ao inserir os pacientes na etapa <b>Monitorados pela Munai</b>, você automaticamente
                estará enviando uma mensagem de convite para iniciar o acompanhamento.
              </Styled.MessageContent>
            </>
          )}

          {isVideoStepAndHasQueue() && (
            <>
              <Styled.IconContainer>
                <Icon color="#0071eb" path={mdiLink} size={1.4} style={{ marginBottom: '6px' }} />
              </Styled.IconContainer>
              <Styled.MessageTitle>Inserir e enviar link</Styled.MessageTitle>

              <Styled.MessageContent>
                Ao inserir pacientes na etapa de <b>Vídeo</b>, você automaticamente estará enviando
                o link da teleconsulta. Assim que o paciente acessar o link, ele poderá aguardar na
                sala de espera até ser atendido, onde visualizará o número de pessoas esperando
                atendimento.
              </Styled.MessageContent>
            </>
          )}

          {(isTriageChatOrEligibleStep() || isVideoStepAndHasServiceDefault()) && (
            <>
              <Styled.IconContainer>
                <Icon color="#0071eb" path={mdiInformation} size={1.4} />
              </Styled.IconContainer>
              <Styled.MessageTitle>Envio manual</Styled.MessageTitle>

              <Styled.MessageContent>
                Ao inserir pacientes na etapa, ainda será necessário enviar um template de contato
                ativo manualmente a cada usuário para iniciar os atendimentos.
              </Styled.MessageContent>
            </>
          )}
        </Styled.MessagesContainer>

        <Styled.ButtonsContainer>
          <Button
            modifiers={['btn', 'fullWidth', 'bgPrimary', 'textWhite']}
            onClick={handleSubmit}
            disabled={false}
          >
            CONFIRMAR
          </Button>
          <Button
            modifiers={['btn', 'fullWidth', 'bgTransparent', 'textPrimary', 'marginTop20']}
            loading={loading}
            onClick={() => {
              handleClose();
            }}
            style={{ marginTop: '15px', textDecoration: 'underline' }}
          >
            CANCELAR
          </Button>
        </Styled.ButtonsContainer>
      </Styled.Container>
    </Modal>
  );
}

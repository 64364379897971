import { gql } from '@apollo/client';

export const GET_ENTITY_ENDPOINT_NAMES = gql`
  query ($entityId: Int!) {
    getEntityEndpointsNames(entityId: $entityId) {
      modules {
        name
        slug
      }
      sections {
        name
        slug
        belongs
      }
      triages {
        name
        slug
        belongs
        careline
      }
    }
  }
`;

import styled from 'styled-components';

export const App = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const Main = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: calc(97.3vh - 100px);
  background: #fff;
  color: #333;
`;

import { SHOW_MODAL, HIDE_MODAL } from './actions';
import { initialState } from './RemoteAtendimentoActions';

export default function actionReducer(state, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, showModal: true, data: action.data };

    case HIDE_MODAL:
      return initialState;

    case 'loading':
      return {
        ...state,
        loading: true,
      };
    case 'success':
      return {
        ...initialState,
        showMessage: {
          type: 'success',
          content: action.message,
        },
      };
    case 'error':
      return {
        ...state,
        loading: false,
        error: '',
        showMessage: {
          type: 'error',
          content: action.error,
        },
      };
    case 'serviceStep':
      return {
        ...state,
        serviceStep: action.serviceStep,
      };
    case 'sector':
      return {
        ...state,
        sector: action.sector,
      };
    case 'statusReason':
      return {
        ...state,
        status: action.statusReason,
      };
    case 'reason':
      return {
        ...state,
        reason: action.reason,
      };
    case 'action':
      return {
        ...state,
        action: action.action,
      };
    default:
      return state;
  }
}

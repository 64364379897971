import { MORE_ACTIONS } from 'Utils/types';

import ChangeStatus from '../ChangeStatus';
import RegisterEvolution from '../RegisterEvolution';
import SendActiveContactTemplates from '../SendActiveContactTemplates';
import UpdatePatient from '../UpdatePatient';

export default function Actions({
  medicalRecord,
  callBack,
  menuAction,
  alertRecord,
  onCloseModal,
}) {
  return (
    <>
      {menuAction === MORE_ACTIONS.MOVE_PACIENT && (
        <ChangeStatus callBack={callBack} medicalRecord={medicalRecord} alertRecord={alertRecord} />
      )}
      {menuAction === MORE_ACTIONS.REGISTER_EVOLUTION && (
        <RegisterEvolution
          callBack={callBack}
          medicalRecord={medicalRecord}
          onCloseModal={onCloseModal}
        />
      )}
      {menuAction === MORE_ACTIONS.SEND_ACTIVE_CONTACT_TEMPLATE && (
        <SendActiveContactTemplates callBack={callBack} alertRecord={alertRecord} />
      )}
      {menuAction === MORE_ACTIONS.UPDATE_PACIENT && (
        <UpdatePatient
          callback={callBack}
          medicalRecord={medicalRecord}
          alertRecord={alertRecord}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
}

import { createActions, createReducer, resettableReducer } from 'reduxsauce';

// define as ações
export const { Types: ModalEnterLinkTypes, Creators: ModalEnterLinkActions } = createActions({
  modalEnterLinkSetIsOpen: ['isOpen', 'document'],
  modalEnterLinkReset: null,
});

// define o estado inicial
const INITIAL_STATE = {
  isOpen: false,
  document: null,
};

/**
 * Atualiza se a modal está aberta ou não
 * @param {{}} state
 * @param {{}} payload
 */
const modalEnterLinkSetIsOpen = (
  state: Record<string, any>,
  { isOpen, document }: Record<string, any>
) => ({
  ...state,
  isOpen,
  document,
});

// define o reducer
const reducer = createReducer(INITIAL_STATE, {
  [ModalEnterLinkTypes.MODAL_ENTER_LINK_SET_IS_OPEN]: modalEnterLinkSetIsOpen,
});

// torna o reducer atualizado
export default resettableReducer(ModalEnterLinkTypes.MODAL_ENTER_LINK_RESET)(reducer);

import { useMemo, useContext } from 'react';
import { mdiSend, mdiLock } from '@mdi/js';

import ChatContext from 'Components/Chat/context';
import Toggle from 'Components/Toggle';
import Button from 'Components/NewButton';
import { getOperatingSystem } from 'Utils';
import { SYSTEM } from 'Utils/types';

import * as Styled from './styled';

type footerProps = {
  onClick: () => void;
};

const Footer = ({ onClick }: footerProps) => {
  const {
    sendMessage,
    sendUpdateHandover,
    handleHandover,
    handoverActive,
    newMessage,
    loading,
    submittingNewMessage,
    myCurrentChat,
    needActiveContact,
  } = useContext(ChatContext);
  const OS = getOperatingSystem(window);

  const isDisabled = useMemo(
    () => loading || (needActiveContact && myCurrentChat && handoverActive),
    [loading, needActiveContact, myCurrentChat, handoverActive]
  );

  const isCheckDisabled = (myCurrentChat, newMessage, handoverActive, isDisabled) => {
    if (!myCurrentChat) return myCurrentChat;
    return !Boolean(newMessage?.trim() && myCurrentChat) || !handoverActive || isDisabled;
  };

  const shortcut = useMemo(() => {
    if (OS === SYSTEM.MAC) {
      return 'cmd + enter';
    }

    return 'ctrl + enter';
  }, [OS]);

  return (
    <>
      <Styled.Container onClick={onClick}>
        <Toggle
          onChange={handleHandover}
          initialOn={handoverActive}
          disabled={loading || !myCurrentChat}
          label={handoverActive ? 'ativar bot' : 'ativar manual'}
        />
        <Button
          onClick={myCurrentChat ? sendMessage : sendUpdateHandover}
          fullWidth
          maxWidth={98}
          background={myCurrentChat ? '#707070' : '#FF881F'}
          prefixIcon={handoverActive && myCurrentChat ? mdiSend : null}
          suffixIcon={!handoverActive || !myCurrentChat ? mdiLock : null}
          loading={submittingNewMessage}
          disabled={isCheckDisabled(myCurrentChat, newMessage, handoverActive, isDisabled)}
        >
          {myCurrentChat ? 'Enviar' : 'Atender'}
        </Button>
        <Styled.Shortcut>{shortcut}</Styled.Shortcut>
      </Styled.Container>
    </>
  );
};

export default Footer;

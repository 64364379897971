const baseStyleLink = {
  transitionProperty: 'common',
  transitionDuration: 'fast',
  transitionTimingFunction: 'ease-out',
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'inherit',
  _hover: {
    textDecoration: 'underline',
  },
  _focusVisible: {
    boxShadow: 'outline',
  },
};

const baseStyle = {
  link: baseStyleLink,
};

export const breadcrumbTheme = {
  baseStyle,
  defaultProps: { colorScheme: 'blue' },
};

import { useContext, useMemo } from 'react';
import { useParams } from 'react-router';

import ModuleContext from 'Context/moduleContext';
import useCache from 'Hooks/useCache';
import { Header } from 'Components/Dashboard/styled';

export default function PowerBIDashboard() {
  const { moduleAccess } = useContext(ModuleContext);
  const { slug } = useParams();
  const [powerBi] = useCache('powerBi', null);

  const iframeURL = useMemo(() => {
    if (powerBi !== null && powerBi !== undefined) {
      const currentBI = powerBi.find((bi) => bi.slug === slug && bi.module === moduleAccess);

      if (currentBI) {
        return currentBI.iframe;
      }
    }
    return null;
  }, [powerBi, slug, moduleAccess]);

  return (
    <>
      <div className="container-fluid" style={{ paddingBottom: 30, width: '95%' }}>
        <Header className="row">
          <iframe
            title="PowerBI"
            width="100%"
            height="700"
            src={iframeURL}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </Header>
      </div>
    </>
  );
}

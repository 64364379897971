import { useSubscription } from '@apollo/client';
import { Toast } from 'Components';
import ModuleContext from 'Context/moduleContext';
import gql from 'graphql-tag';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ModulesActions } from 'store/ducks/modules';
import { getEntityId } from 'Utils/Config/SectorConfig';

const SUBSCRIBE_IMMINENT_TELECONSULTATION = gql`
  subscription ImminentTeleconsultations($entityId: Int!) {
    imminentTeleconsultations(entityId: $entityId) {
      imminentTeleconsultations
      module
    }
  }
`;

/**
 * Componente que verifica as consultas próximas de serem iniciadas
 */
export default function ImminentTeleconsultations() {
  const dispatch = useDispatch();
  const { moduleAccess } = useContext(ModuleContext);
  const prevImminentTeleconsultations = useRef();
  const imminentTeleconsultations = useSelector(
    ({ modules: { imminentTeleconsultations } }) => imminentTeleconsultations[moduleAccess]
  );

  const { data: dataImminentTeleconsultations } = useSubscription(
    SUBSCRIBE_IMMINENT_TELECONSULTATION,
    {
      shouldResubscribe: true,
      fetchPolicy: 'network-first',
      variables: {
        entityId: getEntityId()[0],
      },
    }
  );

  useEffect(() => {
    if (imminentTeleconsultations && !prevImminentTeleconsultations.current) {
      toast(
        <Toast
          type="video"
          title="Teleconsulta agendada próxima de ser iniciada"
          subtitle={
            <>
              Uma nova consulta irá iniciar daqui a pouco. Para conferi-la, navegue até a aba de{' '}
              <b>{'Vídeo > Agendamentos.'}</b>
            </>
          }
        />
      );
    }

    prevImminentTeleconsultations.current = imminentTeleconsultations;
  }, [imminentTeleconsultations]);

  useEffect(() => {
    const _module = dataImminentTeleconsultations?.imminentTeleconsultations?.module;
    const _active =
      dataImminentTeleconsultations?.imminentTeleconsultations?.imminentTeleconsultations;

    dispatch(ModulesActions.modulesSetImminentTeleconsultations({ [_module]: _active }));
    // eslint-disable-next-line
  }, [dataImminentTeleconsultations]);

  return null;
}

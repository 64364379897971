// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-nissan {
  background-color: #c30030 !important;
}
.card-nissan {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-nissan {
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.card-nissan {
  border: none;
  border-radius: 12px;
  /* box-shadow: rgba(5, 8, 92, 0.1) 0 5px 15px; */
}

.card-nissan.card-body {
  box-sizing: content-box;
}
.card-nissan:hover,
.card-nissan.active {
  box-shadow: rgba(5, 8, 92, 0.1) 0 5px 15px;
  color: #6c757d;
}

.card-nissan {
  color: #6c757d;
  cursor: pointer;
}
.card-nissan .mdi {
  color: #a9adb1;
}

.card-nissan.active .mdi {
  color: #0071eb !important;
}
.card-nissan.active {
  color: #0071eb !important;
  border: 1px solid #0071eb;
}
.card-nissan.active .text-muted {
  color: #0071eb !important;
}
.card-nissan.active .mdi {
  color: #0071eb !important;
}

.card-nissan.card-gray {
  box-shadow: 0px 1px 6px rgba(200, 200, 200, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/nissan.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;EACtB,2BAA2B;EAC3B,sCAAsC;EACtC,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,yCAAyC;AAC3C;AACA;EACE,YAAY;EACZ,mBAAmB;EACnB,gDAAgD;AAClD;;AAEA;EACE,uBAAuB;AACzB;AACA;;EAEE,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gDAAgD;AAClD","sourcesContent":[".bg-nissan {\n  background-color: #c30030 !important;\n}\n.card-nissan {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  min-width: 0;\n  word-wrap: break-word;\n  background-color: #fff;\n  background-clip: border-box;\n  border: 1px solid rgba(0, 0, 0, 0.125);\n  border-radius: 0.25rem;\n}\n\n.card-nissan {\n  margin-bottom: 10px;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);\n}\n.card-nissan {\n  border: none;\n  border-radius: 12px;\n  /* box-shadow: rgba(5, 8, 92, 0.1) 0 5px 15px; */\n}\n\n.card-nissan.card-body {\n  box-sizing: content-box;\n}\n.card-nissan:hover,\n.card-nissan.active {\n  box-shadow: rgba(5, 8, 92, 0.1) 0 5px 15px;\n  color: #6c757d;\n}\n\n.card-nissan {\n  color: #6c757d;\n  cursor: pointer;\n}\n.card-nissan .mdi {\n  color: #a9adb1;\n}\n\n.card-nissan.active .mdi {\n  color: #0071eb !important;\n}\n.card-nissan.active {\n  color: #0071eb !important;\n  border: 1px solid #0071eb;\n}\n.card-nissan.active .text-muted {\n  color: #0071eb !important;\n}\n.card-nissan.active .mdi {\n  color: #0071eb !important;\n}\n\n.card-nissan.card-gray {\n  box-shadow: 0px 1px 6px rgba(200, 200, 200, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { gql } from '@apollo/client';

const ComplaintModel = {
  GET_COMPLAINT: () => gql`
    query getComplaintByModule($entityId: Int!, $module: String!) {
      getComplaintByModule(entityId: $entityId, module: $module) {
        moduleSlug
        moduleName
        complaintSlug
        complaintName
      }
    }
  `,
};

export default ComplaintModel;

import {
  mdiMessageTextOutline,
  mdiVideoOutline,
  mdiHomePlusOutline,
  mdiMessageText,
  mdiVideo,
  mdiHomePlus,
  mdiClipboardText,
  mdiClipboardTextOutline,
  mdiAccountMultipleCheckOutline,
  mdiAccountMultipleCheck,
} from '@mdi/js';

import LocalCache from 'Services/LocalCache';
import { SectorDictionary } from 'Utils/Dictionary/SectorDictionary';
import routesDictionary from 'Utils/Dictionary/routesDictionary';
import TeleAtendimentoContainer from 'Components/TeleAtendimento/Container';

import mdiMunai from 'Assets/munai_simbolo.svg?url';
import mdiMunaiActive from 'Assets/munai_simbolo_white.svg?url';

import { TelemedicineSectors } from './TelemedicineConfig';
import { GeneralistSectors } from './GeneralistConfig';
import { ChatSectors } from './ChatConfig';
import { RemoteAtendimentoSectors } from './RemoteAtendimentoConfig';
import { RemoteAtendimentoSectors as TeleAtendimentoSectors } from './TeleAtendimentoConfig';

const SectorDefault = [
  SectorDictionary.DASHBOARD,
  SectorDictionary.INTENTIONS,
  SectorDictionary.CHECKOUT,
  SectorDictionary.MONITORING,
  SectorDictionary.HISTORY,
];

const SectorTown = [
  SectorDictionary.DASHBOARD,
  SectorDictionary.TOWN_MONITORING,
  SectorDictionary.FORWARDED,
  {
    ...SectorDictionary.MONITORING,
    module: 'PACIENTES MONITORADOS PELA MUNAI',
    name: 'Monitorado pela Munai',
  },
  SectorDictionary.HISTORY,
];

const SectorTownWithCheckout = [
  SectorDictionary.DASHBOARD,
  SectorDictionary.TOWN_MONITORING,
  {
    ...SectorDictionary.CHECKOUT,
    module: SectorDictionary.FORWARDED.module,
    name: SectorDictionary.FORWARDED.name,
  },
  {
    ...SectorDictionary.MONITORING,
    module: 'PACIENTES MONITORADOS PELA MUNAI',
    name: 'Monitorado pela Munai',
  },
  SectorDictionary.HISTORY,
];

export const ENTITY_ID = 63;

export const ROLE_ID = 3;

const TOWN_HALL_SJP = 27;

const ACCCC_ENTITY_ID = 4;

const TOWN_HALL_ENTITIES_CHECKOUT = [TOWN_HALL_SJP];

export const MODULES = {
  covid: {
    value: 'covid',
    label: 'COVID-19',
    initialRoute: routesDictionary.ALL,
  },
  generalist: {
    value: 'generalist',
    label: 'Generalista',
    initialRoute: routesDictionary.GENERALIST_TRANSFERRED,
  },
  remoteatendimento: {
    value: 'remoteatendimento',
    label: 'Atendimento',
    initialRoute: routesDictionary.TELEATENDIMENTO,
    container: ({ children }) => TeleAtendimentoContainer({ children }),
  },
};

export const MODULE_DEFAULT_SECTORS = {
  covid: SectorConfig(),
  generalist: GeneralistSectors,
  remoteatendimento: TeleAtendimentoSectors,
};

export const SERVICE_STEPS = (module) => {
  const moduleAccess = LocalCache?.getModules()?.filter((item) => item?.slug === module);
  const isTelemedicineScheduleEnabled =
    moduleAccess?.[0]?.serviceStep?.isTelemedicineScheduleEnabled;

  return {
    screening: {
      value: 'screening',
      label: 'Triagem',
      to: `/${module}/all`,
      fixed: true,
      icon: {
        path: mdiClipboardTextOutline,
        activePath: mdiClipboardText,
      },
      sectors: MODULE_DEFAULT_SECTORS[module],
      count: 'DASHBOARD',
      subSteps: false,
      initialSector: 'DASHBOARD',
    },
    chat: {
      value: 'chat',
      label: 'Chat',
      to: `/${module}${routesDictionary.CHAT_WAITING}`,
      sectors: ChatSectors(module),
      fixed: false,
      icon: {
        path: mdiMessageTextOutline,
        activePath: mdiMessageText,
      },
      count: 'CHAT',
      subSteps: true,
      initialSector: 'CHAT_WAITING',
    },
    telemedicine: {
      value: 'telemedicine',
      label: 'Vídeo',
      to: isTelemedicineScheduleEnabled
        ? `/${module}${routesDictionary.SCHEDULE}`
        : `/${module}${routesDictionary.TRANSFERRED}`,
      sectors: TelemedicineSectors(module),
      fixed: false,
      icon: {
        path: mdiVideoOutline,
        activePath: mdiVideo,
      },
      count: isTelemedicineScheduleEnabled ? 'TELEMEDICINESCHEDULE' : 'TELEMEDICINEQUEUE',
      subSteps: true,
      initialSector: 'TRANSFERRED',
      automated: isTelemedicineScheduleEnabled,
    },
    monitoring: {
      value: 'monitoring',
      label: 'Acompanhamento',
      sectors: MODULE_DEFAULT_SECTORS[module],
      to: `/${module}/monitoring`,
      fixed: true,
      icon: {
        image: true,
        path: mdiMunai,
        activePath: mdiMunaiActive,
      },
      count: 'MONITORING',
      subSteps: false,
      initialSector: 'MONITORING',
    },
    history: {
      value: 'history',
      label: 'Histórico',
      to: `/${module}/history`,
      sectors: MODULE_DEFAULT_SECTORS[module],
      fixed: true,
      icon: {
        path: mdiHomePlusOutline,
        activePath: mdiHomePlus,
      },
      count: 'HISTORY',
      subSteps: false,
      initialSector: 'HISTORY',
    },
    ELIGIBLE: {
      value: 'eligible',
      label: 'Elegíveis',
      to: `/${module}/eligible`,
      sectors: MODULE_DEFAULT_SECTORS[module],
      fixed: true,
      icon: {
        path: mdiAccountMultipleCheckOutline,
        activePath: mdiAccountMultipleCheck,
      },
      count: 'ELIGIBLE',
      subSteps: false,
      initialSector: 'ELIGIBLE',
      hideOnChangeStatus: true,
    },
  };
};

export const ModuleSectors = (module) => {
  const modules = {
    remoteatendimento: RemoteAtendimentoSectors,
    telemedicine: TelemedicineSectors,
    generalist: GeneralistSectors,
    covid: SectorConfig(),
  };
  return modules[module];
};

export default function SectorConfig() {
  const user = LocalCache.getUser();
  if (user) {
    if (user?.entityId?.some((r) => TOWN_HALL_ENTITIES_CHECKOUT.includes(r))) {
      return SectorTownWithCheckout;
    }
  }

  if (user?.entity?.protocolId === 2) {
    return SectorTown;
  }

  return SectorDefault;
}

export function isTownHall() {
  return LocalCache.getEntity()?.protocolId === 2;
}
export const activeModule = () => LocalCache.getModule();

export const isLegacyEntity = () =>
  LocalCache.getUser().entityId ? LocalCache.getUser().entityId.includes(ACCCC_ENTITY_ID) : false;

export const getEntityId = () =>
  LocalCache.getUser().entityId ? LocalCache.getUser().entityId : false;

export const getEntityEmail = () => (LocalCache.getUser().email ? LocalCache.getUser().email : '');

export const getEntityRoleId = () =>
  LocalCache.getUser().roleId ? LocalCache.getUser().roleId : '';

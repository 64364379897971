import styled from 'styled-components';

export const Legend = styled.legend`
  font-family: 'Roboto', sans-serif;
  cursor: default;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px !important;
`;

export const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #0071eb;
  font-weight: 400;
`;

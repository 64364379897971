import {
  useEffect,
  useState,
  createContext,
  useReducer,
  useLayoutEffect,
  useCallback,
  useContext,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { unionBy } from 'lodash';
import axios from 'axios';

import { sizes, chatData } from 'Components/Chat/types';
import { reducerChat } from 'Components/Chat/types';
import Actions from 'Components/Patient/Actions';
import useCache from 'Hooks/useCache';
import Chat from 'Components/Chat';
import PreviousChat from 'Components/Chat/components/PreviousChat';
import useWindowSize from 'Hooks/useWindowSize';
import LocalCache from 'Services/LocalCache';
import environment from 'Utils/Dictionary/environment';
import useSocket from 'Hooks/useSocket';
import { getEntityId } from 'Utils/Config/SectorConfig';
import DashboardModel from 'Models/DashboardModel';
import { Document } from 'data/types';

const newChat = {
  ...chatData,
};

const ContainerChats = styled.div`
  position: fixed;
  height: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: 99999;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
`;

// TODO - add types and improve current typing
const ChatGlobalContext = createContext({
  minimizedPreviousChat: true,
  activeActions: [],
  componentActions: null,
  socket: null as unknown as SocketIOClient.Socket,
  moduleAccess: '',
  loadMenu: null,
  setLoadMenu: (() => void 0) as unknown as (fn: any) => any,
  handoverStatus: (() => void 0) as unknown as (item: any) => boolean,
  handleTotalPages: (() => void 0) as unknown as (senderId: string, totalPages: number) => void,
  handleNextPage: (() => void 0) as unknown as (senderId: string) => void,
  handleResetPagination: (() => void 0) as unknown as (senderId: string) => void,
  closeChat: () => void 0,
  openChat: () => void 0,
  handleNewMessage: () => void 0,
  setMessages: (() => void 0) as unknown as (senderId: string, messages: any[]) => void,
  setFilters: (() => void 0) as unknown as (senderId: string, filters: string[]) => void,
  toggleExpandChat: () => void 0,
  handleHandover: () => void 0,
  toggleMinimize: () => void 0,
  clearChats: () => void 0,
  onClickTimeline: () => void 0,
  removePreviousChat: () => void 0,
  updateOriginalDataChat: (() => void 0) as unknown as (item: string) => void,
  toggleMinimizePreviousChat: () => void 0,
  fetchDocument: async (document: Document): Promise<void> => void 0,
});

const reduceWidhtChats = (acc, chat) =>
  acc +
  (chat?.minimized
    ? sizes().minimized.width
    : chat?.expanded
    ? sizes().expanded.width
    : sizes().normal.width) +
  16;

const getCurrentChat = (chats, senderId, moduleAccess) => {
  const currentChat = chats.findIndex(
    (chat) => chat.senderId === senderId && chat.moduleAccess === moduleAccess
  );

  return { tmpChats: [...chats], currentChat };
};

const getCurrentChatByMedicalRecord = (chats, medicalRecord, moduleAccess) => {
  const currentChat = chats.findIndex(
    (chat) => chat?.original?.medicalRecord === medicalRecord && chat.moduleAccess === moduleAccess
  );

  return { tmpChats: [...chats], currentChat };
};

const delimitationOfWindows = (chats, previousChats, senderId, moduleAccess) => {
  let currentSenderId = senderId;
  let newChats = [...chats.filter((chat) => chat?.moduleAccess === moduleAccess)];
  let newPreviousChats = [];
  const currentResolution = window?.innerWidth - (previousChats.length ? 312 : 0);
  const currentChatsWidth = newChats?.reduce(reduceWidhtChats, 0);

  if (currentChatsWidth >= currentResolution) {
    newChats = newChats?.map((chat, index) => {
      if (currentSenderId && chat.senderId === currentSenderId) {
        return chat;
      }

      if (!currentSenderId && index === newChats.length - 1) {
        currentSenderId = chat.senderId;
        return { ...chat, minimized: false };
      }

      return { ...chat, minimized: true };
    });

    const newChatsWidth = newChats?.reduce(reduceWidhtChats, 0);

    if (newChatsWidth >= currentResolution) {
      const { currentChat } = getCurrentChat(newChats, currentSenderId, moduleAccess);
      const currentChatSize = newChats[currentChat]?.minimized
        ? sizes().minimized.width
        : newChats[currentChat]?.expanded
        ? sizes().expanded.width
        : sizes().normal.width;
      const skipMinimizedChatsCurrent = Math.ceil(
        Math.round(
          (currentChatSize + (previousChats.length ? 0 : 312)) / (sizes().minimized.width + 16)
        )
      );

      if (currentChat === 0) {
        newPreviousChats = newChats?.slice(1, skipMinimizedChatsCurrent);
        newChats?.splice(1, skipMinimizedChatsCurrent);
      } else {
        newPreviousChats = newChats?.slice(0, skipMinimizedChatsCurrent);
        newChats?.splice(0, skipMinimizedChatsCurrent);
      }
    }
  }

  return { newChats, newPreviousChats, currentSenderId };
};

const mergePreviousChats = (state, previousChats, senderId, moduleAccess) => [
  ...state.previousChats?.filter((chat) => chat.moduleAccess !== moduleAccess),
  ...state.previousChats?.filter(
    (chat) =>
      chat.moduleAccess === moduleAccess &&
      chat.senderId !== senderId &&
      !previousChats.find((pc) => pc.senderId === chat.senderId)
  ),
  ...previousChats,
];

const chatReducer = (state, action) => {
  switch (action.type) {
    case reducerChat.SET_CHAT_TO_ACTIVE: {
      const tmpChats = [...state.chats, { ...action.payload.chat, minimized: false }];

      const { newChats, newPreviousChats } = delimitationOfWindows(
        tmpChats,
        state.previousChats,
        action.payload.chat.senderId,
        action.payload.moduleAccess
      );

      return {
        ...state,
        chats: [
          ...tmpChats?.filter((chat) => chat.moduleAccess !== action.payload.moduleAccess),
          ...newChats,
        ],
        previousChats: mergePreviousChats(
          state,
          newPreviousChats,
          action.payload.chat.senderId,
          action.payload.moduleAccess
        ),
      };
    }
    case reducerChat.OPEN_CHAT: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat >= 0) {
        if (tmpChats[currentChat]?.minimized) {
          tmpChats[currentChat] = {
            ...tmpChats[currentChat],
            minimized: false,
          };

          const { newChats, newPreviousChats } = delimitationOfWindows(
            tmpChats,
            state.previousChats,
            action.payload.senderId,
            action.payload.moduleAccess
          );

          return {
            ...state,
            chats: [
              ...state.chats?.filter((chat) => chat.moduleAccess !== action.payload.moduleAccess),
              ...newChats,
            ],
            previousChats: mergePreviousChats(
              state,
              newPreviousChats,
              action.payload.senderId,
              action.payload.moduleAccess
            ),
          };
        }

        return { ...state };
      }

      const tChats = [
        ...state.chats,
        {
          ...newChat,
          senderId: action.payload.senderId,
          title: action.payload.title,
          original: action.payload.original,
          moduleAccess: action.payload.moduleAccess,
        },
      ];

      const { newChats, newPreviousChats } = delimitationOfWindows(
        tChats,
        state.previousChats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      return {
        ...state,
        chats: [
          ...tChats?.filter((chat) => chat.moduleAccess !== action.payload.moduleAccess),
          ...newChats,
        ],
        previousChats: mergePreviousChats(
          state,
          newPreviousChats,
          action.payload.senderId,
          action.payload.moduleAccess
        ),
      };
    }
    case reducerChat.CLOSE_CHAT: {
      return {
        ...state,
        chats: [
          ...state.chats?.filter((chat) => chat.moduleAccess !== action.payload.moduleAccess),
          ...state.chats?.filter(
            (chat) =>
              chat.moduleAccess === action.payload.moduleAccess &&
              chat.senderId !== action.payload.senderId
          ),
        ],
      };
    }
    case reducerChat.REMOVE_PREVIOUS_CHAT: {
      return {
        ...state,
        previousChats: [
          ...state.previousChats?.filter(
            (chat) => chat.moduleAccess !== action.payload.moduleAccess
          ),
          ...state.previousChats?.filter(
            (chat) =>
              chat.moduleAccess === action.payload.moduleAccess &&
              chat.senderId !== action.payload.senderId
          ),
        ],
      };
    }
    case reducerChat.CLEAR_CHATS:
      return {
        ...state,
        chats: [],
        previousChats: [],
      };
    case reducerChat.SET_NEW_MESSAGE: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        newMessage: action.payload.newMessage,
      };

      return { ...state, chats: tmpChats };
    }
    case reducerChat.SET_MESSAGES: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      const tmpMessages = unionBy(
        [...tmpChats[currentChat].messages, ...action.payload.messages],
        'timestamp'
      );

      let messages = tmpMessages.sort((pre, next) => pre.timestamp - next.timestamp);

      // FIXME - this should not be handled here, but the current socket implementation is not working properly
      // and emits several times the same message
      const currentFilters = tmpChats[currentChat].filters;
      if (currentFilters?.length) {
        messages = tmpMessages.filter((message) => currentFilters.includes(message.file?.type));
      }

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        messages,
      };

      return { ...state, chats: tmpChats };
    }
    case reducerChat.TOGGLE_EXPAND_CHAT: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        expanded: !tmpChats[currentChat]?.expanded,
      };

      const { newChats, newPreviousChats } = delimitationOfWindows(
        tmpChats,
        state.previousChats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      return {
        ...state,
        chats: [
          ...state.chats?.filter((chat) => chat.moduleAccess !== action.payload.moduleAccess),
          ...newChats,
        ],
        previousChats: mergePreviousChats(
          state,
          newPreviousChats,
          action.payload.senderId,
          action.payload.moduleAccess
        ),
      };
    }
    case reducerChat.TOGGLE_MINIMIZE_CHAT: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        minimized: !tmpChats[currentChat]?.minimized,
      };

      const { newChats, newPreviousChats } = delimitationOfWindows(
        tmpChats,
        state.previousChats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      return {
        ...state,
        chats: [
          ...state.chats?.filter((chat) => chat.moduleAccess !== action.payload.moduleAccess),
          ...newChats,
        ],
        previousChats: mergePreviousChats(
          state,
          newPreviousChats,
          action.payload.senderId,
          action.payload.moduleAccess
        ),
      };
    }
    case reducerChat.SET_HANDOVER: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        handoverActive: action.payload.handoverActive,
      };

      return { ...state, chats: tmpChats };
    }
    case reducerChat.SET_TOTAL_PAGES_CHAT: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      const tmpPage = tmpChats[currentChat]?.page || 1;
      const isLastPage = tmpPage >= action.payload.totalPages;

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        totalPages: action.payload.totalPages,
        isLastPage,
      };

      return { ...state, chats: tmpChats };
    }
    case reducerChat.HANDLE_NEXT_PAGE: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      const tmpPage =
        tmpChats[currentChat]?.page <= tmpChats[currentChat]?.totalPages
          ? tmpChats[currentChat]?.page + 1
          : tmpChats[currentChat]?.page;
      const isLastPage = tmpPage >= tmpChats[currentChat]?.totalPages;

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        page: tmpPage,
        isLastPage,
      };

      return { ...state, chats: tmpChats };
    }
    case reducerChat.RESET_PAGINATION: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      const tmpPage = 1;

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        messages: [],
        page: tmpPage,
        isLastPage: true,
      };

      return { ...state, chats: tmpChats };
    }
    case reducerChat.SET_FILTERS: {
      const { tmpChats, currentChat } = getCurrentChat(
        state.chats,
        action.payload.senderId,
        action.payload.moduleAccess
      );

      if (currentChat < 0) {
        return { ...state };
      }

      tmpChats[currentChat] = {
        ...tmpChats[currentChat],
        filters: action.payload.filters,
      };

      return { ...state, chats: tmpChats };
    }
    case reducerChat.REORDER_CHATS: {
      const { newChats, newPreviousChats, currentSenderId } = delimitationOfWindows(
        state.chats,
        state.previousChats,
        undefined,
        action.payload.moduleAccess
      );

      return {
        ...state,
        chats: [
          ...state.chats?.filter((chat) => chat.moduleAccess !== action.payload.moduleAccess),
          ...newChats,
        ],
        previousChats: mergePreviousChats(
          state,
          newPreviousChats,
          currentSenderId,
          action.payload.moduleAccess
        ),
      };
    }
    case reducerChat.MERGE_ORIGINAL_DATA: {
      let tmpState = { ...state };
      const { tmpChats, currentChat } = getCurrentChatByMedicalRecord(
        state?.chats,
        action?.payload?.original?.medicalRecord,
        action?.payload?.moduleAccess
      );
      const { tmpChats: tmpPreviousChats, currentChat: currentPreviousChat } =
        getCurrentChatByMedicalRecord(
          state?.previousChats,
          action?.payload?.original?.medicalRecord,
          action?.payload?.moduleAccess
        );

      if (currentChat >= 0) {
        tmpChats[currentChat] = {
          ...tmpChats[currentChat],
          original: action.payload.original,
          title: action.payload.original?.patient?.nome,
        };

        tmpState = { ...state, chats: tmpChats };
      }

      if (currentPreviousChat >= 0) {
        tmpPreviousChats[currentPreviousChat] = {
          ...tmpPreviousChats[currentPreviousChat],
          original: action.payload.original,
          title: action.payload.original?.patient?.nome,
        };

        tmpState = { ...state, previousChats: tmpPreviousChats };
      }

      return tmpState;
    }
    case reducerChat.TOGGLE_MINIMIZE_PREVIOUS_CHAT:
      return { ...state, minimizedPreviousChat: !state.minimizedPreviousChat };
    case reducerChat.SET_LOAD_MENU:
      return { ...state, loadMenu: action.payload.loadMenu };
    default:
      return { ...state };
  }
};

type ChatProviderProps = {
  uriSocket?: string;
  onClickTimeline?: (...args: any[]) => any;
  activeActions?: [];
  componentActions?: React.ReactElement | React.ReactNode | ((...args: any[]) => any);
  moduleAccess: string;
  children: ReactNode;
};

const ChatProvider = ({
  children,
  moduleAccess,
  onClickTimeline,
  uriSocket = environment.CHATBOT_STREAM,
  activeActions = [],
  componentActions = Actions,
}: ChatProviderProps) => {
  // Load cached chats
  const [cachedChats, handleCachedChats] = useCache('chats', []);
  const [cachedPreviousChats, handleCachedPreviousChats] = useCache('previousChats', []);
  const [state, dispatch] = useReducer(chatReducer, {
    chats: cachedChats,
    previousChats: cachedPreviousChats,
    minimizedPreviousChat: true,
    loadMenu: null,
  });
  const [socket] = useSocket(uriSocket as string, {
    autoConnect: false,
    query: {
      senderId: '',
      authorization: LocalCache.getUser()?.token,
    },
  });
  const [width] = useWindowSize();
  const [started, setStarted] = useState(false);

  // Save all localstorage
  useEffect(() => {
    handleCachedChats(state?.chats);
  }, [state?.chats, handleCachedChats]);

  useEffect(() => {
    handleCachedPreviousChats(state?.previousChats);
  }, [state?.previousChats, handleCachedPreviousChats]);

  useLayoutEffect(() => {
    dispatch({ type: reducerChat.REORDER_CHATS, payload: { moduleAccess } });
  }, [moduleAccess, width, dispatch]);

  const openChat = (payload) => {
    dispatch({ type: reducerChat.OPEN_CHAT, payload: { ...payload, moduleAccess } });
    fetchDocument(payload?.original);
  };
  const clearChats = () => dispatch({ type: reducerChat.CLEAR_CHATS });
  const closeChat = (senderId) =>
    dispatch({ type: reducerChat.CLOSE_CHAT, payload: { senderId, moduleAccess } });
  const removePreviousChat = (senderId) =>
    dispatch({ type: reducerChat.REMOVE_PREVIOUS_CHAT, payload: { senderId, moduleAccess } });
  const toggleExpandChat = (senderId) =>
    dispatch({ type: reducerChat.TOGGLE_EXPAND_CHAT, payload: { senderId, moduleAccess } });
  const toggleMinimize = (senderId) =>
    dispatch({ type: reducerChat.TOGGLE_MINIMIZE_CHAT, payload: { senderId, moduleAccess } });
  const toggleMinimizePreviousChat = () =>
    dispatch({ type: reducerChat.TOGGLE_MINIMIZE_PREVIOUS_CHAT });

  const handleNewMessage = (senderId, newMessage) =>
    dispatch({
      type: reducerChat.SET_NEW_MESSAGE,
      payload: { senderId, newMessage, moduleAccess },
    });

  const setLoadMenu = useCallback(
    (loadMenu) =>
      dispatch({
        type: reducerChat.SET_LOAD_MENU,
        payload: { loadMenu },
      }),
    [dispatch]
  );

  const setMessages = useCallback(
    (senderId, messages) => {
      return dispatch({
        type: reducerChat.SET_MESSAGES,
        payload: { senderId, messages, moduleAccess },
      });
    },
    [dispatch, moduleAccess]
  );

  const handleHandover = useCallback(
    (senderId, handoverActive) =>
      dispatch({
        type: reducerChat.SET_HANDOVER,
        payload: { senderId, handoverActive, moduleAccess },
      }),
    [dispatch, moduleAccess]
  );

  const handleTotalPages = useCallback(
    (senderId, totalPages) =>
      dispatch({
        type: reducerChat.SET_TOTAL_PAGES_CHAT,
        payload: { senderId, totalPages, moduleAccess },
      }),
    [dispatch, moduleAccess]
  );

  const handleNextPage = (senderId) =>
    dispatch({
      type: reducerChat.HANDLE_NEXT_PAGE,
      payload: { senderId, moduleAccess },
    });

  const handleResetPagination = (senderId: string) =>
    dispatch({
      type: reducerChat.RESET_PAGINATION,
      payload: { senderId, moduleAccess },
    });

  const handleSetFilters = (senderId: string, filters: string[]) =>
    dispatch({
      type: reducerChat.SET_FILTERS,
      payload: { senderId, filters, moduleAccess },
    });

  const handleChatToActive = (chat) => {
    dispatch({ type: reducerChat.SET_CHAT_TO_ACTIVE, payload: { chat, moduleAccess } });
  };

  const updateOriginalDataChat = useCallback(
    (original) => {
      dispatch({ type: reducerChat.MERGE_ORIGINAL_DATA, payload: { original, moduleAccess } });
    },
    [dispatch, moduleAccess]
  );

  const fetchDocument = async (document) => {
    try {
      const { data } = await axios.post(
        environment.GRAPHQL_ENDPOINT,
        {
          query: DashboardModel.GET_DOCUMENT,
          variables: {
            entityId: getEntityId(),
            medicalRecord: document?.medicalRecord,
            module: moduleAccess,
          },
        },
        { headers: { authorization: `Bearer ${LocalCache.getUser().token}` } }
      );
      updateOriginalDataChat(data?.data?.document);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!started) {
      for (const chat of state.chats) {
        fetchDocument(chat?.original);
      }
      setStarted(true);
    }
    // eslint-disable-next-line
  }, [started]);

  const handoverStatus = useCallback((item) => {
    switch (item?.event) {
      case 'bot':
        return item?.data?.intent?.name === 'handover' ? true : false;
      case 'user':
        return item?.parse_data?.intent?.name === 'handover' ? true : false;
      case 'contact':
        return item?.data?.intent?.name === 'contact_active' ? true : false;
      default:
        return false;
    }
  }, []);

  return (
    <ChatGlobalContext.Provider
      value={{
        activeActions,
        componentActions,
        minimizedPreviousChat: state?.minimizedPreviousChat,
        loadMenu: state?.loadMenu,
        socket,
        moduleAccess,
        openChat,
        clearChats,
        closeChat,
        handleTotalPages,
        handleNextPage,
        handleResetPagination,
        toggleExpandChat,
        toggleMinimize,
        handleHandover,
        handleNewMessage,
        setMessages,
        setFilters: handleSetFilters,
        onClickTimeline,
        removePreviousChat,
        toggleMinimizePreviousChat,
        handoverStatus,
        updateOriginalDataChat,
        setLoadMenu,
        fetchDocument,
      }}
    >
      {children}
      <ContainerChats>
        <PreviousChat
          chats={state?.previousChats}
          moduleAccess={moduleAccess}
          handleChatToActive={handleChatToActive}
        />
        {state?.chats
          ?.filter((chat) => chat.moduleAccess === moduleAccess)
          ?.map((chat) => {
            return <Chat key={chat?.senderId} {...chat} />;
          })}
      </ContainerChats>
    </ChatGlobalContext.Provider>
  );
};

const useChat = () => {
  const context = useContext(ChatGlobalContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
};

export { ChatProvider, useChat };

import { gql, useSubscription } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import LocalCache from 'Services/LocalCache';
import environment from 'Utils/Dictionary/environment';
import { useFlag } from './unleashContext';

type StepsChatCount = {
  patientCount: number;
  unreadChats: string[];
};

type StepsData = {
  DASHBOARD?: StepsChatCount | number;
  TELEMEDICINE?: StepsChatCount | number;
  TELEMEDICINESCHEDULE?: StepsChatCount | number;
  TELEMEDICINEQUEUE?: StepsChatCount | number;
  CHAT?: StepsChatCount | number;
  HISTORY?: StepsChatCount | number;
  MONITORING?: StepsChatCount | number;
  IN_PROGRESS?: StepsChatCount | number;
  SCHEDULE?: StepsChatCount | number;
  TRANSFERRED?: StepsChatCount | number;
  CHAT_WAITING?: StepsChatCount | number;
  CHAT_PROGRESS?: StepsChatCount | number;
  CHAT_ADJOURNED?: StepsChatCount | number;
  ELIGIBLE?: StepsChatCount | number;
};

type TMenuContext = {
  stepsData?: StepsData | null;
};

type UpdateStepNotificationPayload = {
  type: 'ADD_NOTIFICATION' | 'REMOVE_NOTIFICATION';
  patientId: string;
  responsibleEmail?: string;
  handoverReason?: 'REQUESTED_TEAM_SUPPORT' | string;
  steps: string[];
};

type AddNotificationPayload = {
  patientId: string;
  responsibleEmail?: string;
  handoverReason?: 'REQUESTED_TEAM_SUPPORT' | string;
  steps: string[];
};

type MenuProviderProps = {
  children: React.ReactNode;
  stepsData?: any;
};

export const MenuContext = createContext<TMenuContext>({} as TMenuContext);

const notificationSubscription = gql`
  subscription updateStepsNotification($entityId: Int!, $module: String!) {
    updateStepsNotification(module: $module, entityId: $entityId) {
      type
      patientId
      responsibleEmail
      handoverReason
      steps
    }
  }
`;

export const MenuProvider = (props: MenuProviderProps) => {
  const [stepsData, setStepsData] = useState(props.stepsData);
  const user = LocalCache.getUser();
  const module = LocalCache.getModule();
  const { email: userEmail, entityId } = user;

  useEffect(() => {
    setStepsData(props.stepsData);
  }, [props.stepsData]);

  const displayRequestedTeamSupport = useFlag(
    `lc-display-unread-messages-${environment.UNLEASH_APP_ENV}`
  );

  if (displayRequestedTeamSupport) {
    // TODO - tratar 500
    useSubscription(notificationSubscription, {
      variables: {
        entityId: entityId[0],
        module: module,
      },
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      onSubscriptionData(options) {
        const { data } = options.subscriptionData;
        const { updateStepsNotification } = data;

        updateNotification(updateStepsNotification);
      },
    });
  }

  const updateNotification = (params: UpdateStepNotificationPayload) => {
    const { type, patientId, responsibleEmail, handoverReason, steps } = params;

    if (type === 'ADD_NOTIFICATION') {
      addNotification({
        patientId,
        responsibleEmail,
        handoverReason,
        steps,
      });
    } else {
      removeNotification(patientId, steps);
    }
  };

  const addNotification = (params: AddNotificationPayload) => {
    const { patientId, responsibleEmail, handoverReason, steps } = params;

    // check if current user can see patient notifications

    if (
      responsibleEmail &&
      responsibleEmail !== userEmail &&
      handoverReason !== 'REQUESTED_TEAM_SUPPORT'
    ) {
      return;
    }

    // check if patient is already in the list if not pushes

    const temp = { ...stepsData };

    steps.forEach((step) => {
      if (temp[step] && !temp[step].unreadChats.includes(patientId)) {
        temp[step].unreadChats.push(patientId);
      }
    });

    setStepsData(temp);
  };

  const removeNotification = (patientId: string, steps: string[]) => {
    const temp = { ...stepsData };

    steps.forEach((step) => {
      if (temp[step]?.unreadChats?.includes(patientId)) {
        temp[step].unreadChats = temp[step].unreadChats.filter((id: string) => id !== patientId);
      }
    });

    setStepsData(temp);
  };

  return (
    <MenuContext.Provider
      value={{
        stepsData,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};

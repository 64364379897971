import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Input from 'Components/Input';
import { UncontrolledSelect } from 'Components/Select';
import { useFlag } from 'Context/unleashContext';
import environment from 'Utils/Dictionary/environment';
import UFDictionary from 'Utils/Dictionary/UFDicionary';
import { customRules, rules, rulesMessages } from 'Utils/Helpers/Validator';

import { ROLE } from './shared';

// TODO - improve the inputs performance https://react-hook-form.com/pt/advanced-usage/#FormProviderPerformance

const federativeUnits = UFDictionary.map((uf) => ({
  id: uf,
  name: uf,
}));

const DoctorCredentialsInput = () => {
  const { register, errors, unregister, watch } = useFormContext();

  useEffect(() => {
    return () => {
      unregister('federativeUnit');
      unregister('practitionerRegistration');
      unregister('registrationCategory');
    };
  }, [unregister]);

  return (
    <div className="d-flex">
      <div style={{ flex: 0 }}>
        <span className="form-control border bg-light">CRM</span>
      </div>
      <div className="px-1" />
      <div style={{ flex: 1 }}>
        <UncontrolledSelect
          error={errors?.federativeUnit}
          errormessage={rulesMessages.uf}
          id="UF"
          label="UF"
          name="federativeUnit"
          options={federativeUnits}
          ref={register({
            required: rulesMessages.uf.required,
            pattern: { value: rules.uf, message: rulesMessages.uf.pattern },
          })}
          isSelected={!!watch('federativeUnit')}
        />
      </div>
      <div className="px-1" />
      <div style={{ flex: 2 }}>
        <Input
          name="practitionerRegistration"
          id="Código"
          label="Código"
          type="number"
          ref={register({
            required: 'Favor informar o CRM.',
            validate: customRules.empty,
            pattern: { value: rules?.crm, message: rulesMessages.crm.pattern },
          })}
          error={errors.practitionerRegistration}
          errormessage={errors?.practitionerRegistration?.message}
        />
      </div>
    </div>
  );
};

const NurseCredentialsInput = () => {
  const { register, errors, unregister, getValues, watch } = useFormContext();

  useEffect(() => {
    return () => {
      unregister('federativeUnit');
      unregister('practitionerRegistration');
      unregister('registrationCategory');
    };
  }, [unregister]);

  return (
    <div className="d-flex">
      <div style={{ flex: 0 }}>
        <span className="form-control border bg-light">COREN</span>
      </div>
      <div className="px-1" />
      <div style={{ flex: 1 }}>
        <UncontrolledSelect
          error={errors.federativeUnit}
          errormessage={errors?.federativeUnit?.message}
          id="UF"
          label="UF"
          name="federativeUnit"
          options={federativeUnits}
          isSelected={!!watch('federativeUnit')}
          ref={register({
            validate: (value) => {
              if (
                !value &&
                (getValues('practitionerRegistration') || getValues('registrationCategory'))
              )
                return rulesMessages.uf.required;
              return true;
            },
            pattern: { value: rules.uf, message: rulesMessages.uf.pattern },
          })}
        />
      </div>
      <div className="px-1" />
      <div style={{ flex: 2 }}>
        <Input
          name="practitionerRegistration"
          id="Código"
          label="Código"
          type="number"
          error={errors.practitionerRegistration}
          errormessage={errors?.practitionerRegistration?.message}
          ref={register({
            validate: (value) => {
              if (!value && (getValues('federativeUnit') || getValues('registrationCategory')))
                return rulesMessages.coren.required;

              return true;
            },
            pattern: { value: rules.number, message: rulesMessages.coren.pattern },
          })}
        />
      </div>
      <div className="px-1" />
      <div style={{ flex: 2 }}>
        <Input
          name="registrationCategory"
          id="Categoria"
          label="Categoria"
          type="text"
          error={errors.registrationCategory}
          errormessage={errors?.registrationCategory?.message}
          ref={register({
            validate: (value) => {
              if (
                !value &&
                (getValues('federativeUnit') || getValues('practitionerRegistration'))
              ) {
                return rulesMessages.corenCategory.required;
              }

              return true;
            },
            pattern: {
              value: rules.corenCategory,
              message: rulesMessages.corenCategory.pattern,
            },
          })}
        />
      </div>
    </div>
  );
};

const PractitionerCredentialsInput = () => {
  const { watch } = useFormContext();
  const isPractitionerCredentialsEnabled = useFlag(
    `lc-practitioner-flag-${environment.UNLEASH_APP_ENV}`
  );
  const roleId = watch('roleId');

  if (!isPractitionerCredentialsEnabled) return null;

  if (roleId === ROLE.DOCTOR) {
    return <DoctorCredentialsInput />;
  }

  if (roleId === ROLE.NURSE) {
    return <NurseCredentialsInput />;
  }

  return null;
};

export default PractitionerCredentialsInput;

import { useMotionValue, useTransform } from 'framer-motion';

import { spring } from 'Utils/animations';

import * as Styled from './styled';

type OwnProps = {
  checked?: boolean;
  size?: 'sm' | 'md' | 'lg';
  color?: string;
  checkedColor?: string;
  onChange?: (...args: any[]) => any;
  align?: 'center' | 'flex-start' | 'flex-end';
  label?: React.ReactElement[] | React.ReactElement | string;
};

type Props = OwnProps & typeof Checkbox.defaultProps;

const Checkbox = ({ label, checked, size, color, checkedColor, onChange, ...rest }: Props) => {
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.12], [0, 1]);

  const boxVariants = {
    checked: {
      background: checkedColor,
      borderColor: checkedColor,
    },
    unchecked: { background: '#FFFFFF', borderColor: color },
  };

  const checkVariants = {
    checked: { pathLength: 0.9 },
    unchecked: { pathLength: 0 },
  };

  return (
    <Styled.Container onClick={onChange} {...rest}>
      <Styled.WrapperCheckbox
        variants={boxVariants}
        initial={checked ? 'checked' : 'unchecked'}
        animate={checked ? 'checked' : 'unchecked'}
        transition={spring}
        checked={checked}
        color={color}
        size={size}
        checkedColor={checkedColor}
      >
        <Styled.Svg
          xmlns="http://www.w3.org/2000/svg"
          width={Styled.sizes[size].size}
          height={Styled.sizes[size].size}
          viewBox=" 0 0 150 150"
        >
          <Styled.Path
            d="M38 74.707l24.647 24.646L116.5 45.5"
            transform={Styled.sizes[size].transform}
            fill="transparent"
            strokeWidth="15"
            stroke="white"
            strokeLinecap="round"
            variants={checkVariants}
            style={{ pathLength: pathLength, opacity: opacity }}
          />
        </Styled.Svg>
      </Styled.WrapperCheckbox>
      {label ? <Styled.Label size={size}>{label}</Styled.Label> : null}
    </Styled.Container>
  );
};

Checkbox.defaultProps = {
  checked: false,
  size: 'sm',
  align: 'center',
  color: '#E3E3E3',
  checkedColor: '#0071eb',
  onChange: null,
};

export default Checkbox;

import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router';

import useCache from 'Hooks/useCache';
import cacheService from 'Services/cache';

import amplitude from '../../../Utils/Helpers/amplitude';
import dateFns from '../../../Utils/Helpers/dateFns';

import {
  LOGIN,
  GET_ENTITY_AND_MODULES,
  CHECK_MEMED_TOKEN,
  GET_POWER_BI_INFOS,
} from 'repository/queries';
import moduleHelper from 'Utils/Helpers/moduleHelper';
import { useUnleash } from 'Context/unleashContext';

const getMemedToken = (moduleData) => {
  const hasMemedToken = moduleData?.telemedicine && moduleData?.telemedicine?.token;

  if (hasMemedToken) {
    return moduleData?.telemedicine?.token;
  }

  return null;
};

const useLogicLogin = () => {
  const [error, handleError] = useState(null);
  const [loading, handleLoading] = useState(false);
  const [tmpUser, setTmpUser] = useCache('tmpUser', null);
  const [, setUser] = useCache('user', null);
  const [, setModules] = useCache('modules', null);
  const [, setModuleAccess] = useCache('module_access', 'covid');
  const [, setMemedToken] = useCache('memed_token', null);
  const [, setPowerBI] = useCache('powerBi', null);
  const history = useHistory();

  const { updateUnleashUser } = useUnleash();
  useEffect(() => {
    updateUnleashUser(tmpUser?.entityId);
  }, [tmpUser?.entityId, updateUnleashUser]);

  useEffect(() => {
    return () => {
      handleLoading(false);
    };
  }, [handleLoading]);

  const onError = (newError) => {
    handleLoading(false);
    setTmpUser(null);

    if (Array.isArray(newError)) {
      return handleError(newError.map((e) => (e?.message ? e.message : e)).join(', '));
    }

    handleError(newError.message);
  };

  const onCompletedEntity = ({ entity: { entitiesModules, ...entity } }) => {
    const { moduleData } = tmpUser;
    const tmpModules = entitiesModules
      ?.map((module) => ({
        ...module,
        serviceStep: JSON.parse(module?.serviceStep),
      }))
      .sort(moduleHelper.sort);
    const moduleAccess = moduleHelper.getDefault(tmpModules);
    const memedToken = getMemedToken(moduleData);

    setMemedToken(memedToken);
    setModules(tmpModules);
    setModuleAccess(moduleAccess);

    const userId = `${tmpUser.userId}.${tmpUser.entityId}`;
    amplitude.setUserId(userId);
    amplitude.trackEvent('LOGIN_LAURA_CARE', {
      tmpUser,
      entity,
      date: dateFns.formatTo(new Date(), 'dd/MM/yyyy HH:mm:ss'),
    });

    setUser({
      ...tmpUser,
      entity,
    });
    cacheService.remove('tmpUser');

    history.push(`/${moduleAccess}/all`);
  };

  const [fetchEntity] = useLazyQuery(GET_ENTITY_AND_MODULES, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: onCompletedEntity,
  });

  const onCompletedPowerBiInfos = ({ getPowerBiInfos }) => {
    setPowerBI(getPowerBiInfos);

    fetchEntity({
      variables: {
        entityId: tmpUser?.entityId[0],
      },
    });
  };

  const [fetchPowerBiInfos] = useLazyQuery(GET_POWER_BI_INFOS, {
    fetchPolicy: 'no-cache',
    onError: () => {
      fetchEntity({
        variables: {
          entityId: tmpUser?.entityId[0],
        },
      });
    },
    onCompleted: onCompletedPowerBiInfos,
  });

  const onCompletedMemedToken = ({ checkMemedToken }) => {
    setTmpUser({
      ...tmpUser,
      moduleData: {
        ...tmpUser.moduleData,
        telemedicine: {
          ...tmpUser.moduleData.telemedicine,
          token: checkMemedToken,
        },
      },
    });

    fetchPowerBiInfos({
      variables: {
        entityId: tmpUser?.entityId[0],
      },
    });
  };

  const [checkMemedToken] = useLazyQuery(CHECK_MEMED_TOKEN, {
    fetchPolicy: 'no-cache',
    onError: () => {
      console.error('Fail on check memed token');
      fetchPowerBiInfos({
        variables: {
          entityId: tmpUser?.entityId[0],
        },
      });
    },
    onCompleted: onCompletedMemedToken,
  });

  const onCompletedLogin = ({
    login: {
      user: { email, entityId, firstName: name, moduleData, roleId, id: userId, cpf },
      token,
    },
  }) => {
    setTmpUser({
      entityId,
      name,
      moduleData,
      roleId,
      userId,
      token,
      email,
      cpf,
    });

    if (moduleData && moduleData?.telemedicine) {
      checkMemedToken({
        variables: {
          userId: userId.toString(),
          memedId: moduleData?.telemedicine?.cpf,
        },
      });
    } else {
      fetchPowerBiInfos({
        variables: {
          entityId: entityId[0],
        },
      });
    }
  };

  const [login] = useLazyQuery(LOGIN, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: onCompletedLogin,
  });

  const onSubmit = async (data) => {
    try {
      handleError(null);
      handleLoading(true);

      login({
        variables: data,
      });
    } catch (error) {
      handleError(error.message);
    }
  };

  return { onSubmit, error, loading };
};

export default useLogicLogin;

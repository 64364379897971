import * as Types from '../../../data/types';

import { gql } from '@apollo/client';
export type ContactTemplateFieldsFragment = { __typename?: 'ActiveContactTemplates', name?: string | null, message?: string | null, variables?: string | null };

export type EventsFieldsFragment = { __typename?: 'BotEvents', event?: string | null, timestamp?: string | null, text?: string | null, handover?: boolean | null, file?: { __typename?: 'FileBot', url?: string | null, type?: string | null, size?: string | null, name?: string | null } | null };

export type ConversationsFieldsFragment = { __typename?: 'ConversationDocuments', totalPages: number, totalDocuments: number, conversations?: Array<{ __typename?: 'BotEvents', event?: string | null, timestamp?: string | null, text?: string | null, handover?: boolean | null, file?: { __typename?: 'FileBot', url?: string | null, type?: string | null, size?: string | null, name?: string | null } | null } | null> | null };

export const ContactTemplateFieldsFragmentDoc = gql`
    fragment ContactTemplateFields on ActiveContactTemplates {
  name
  message
  variables
}
    `;
export const EventsFieldsFragmentDoc = gql`
    fragment EventsFields on BotEvents {
  event
  timestamp
  text
  handover
  file {
    url
    type
    size
    name
  }
}
    `;
export const ConversationsFieldsFragmentDoc = gql`
    fragment ConversationsFields on ConversationDocuments {
  totalPages
  totalDocuments
  conversations {
    ...EventsFields
  }
}
    ${EventsFieldsFragmentDoc}`;
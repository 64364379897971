import Icon from '@mdi/react';
import { mdiCalendarArrowRight } from '@mdi/js';
import { useDispatch } from 'react-redux';
import { ModalScheduleReminderActions } from 'store/ducks/modal_schedule_reminder';
import { Button } from '@chakra-ui/react';

export default function ScheduleReminderButton({ document }) {
  const dispatch = useDispatch();

  if (['CHAT_ADJOURNED', 'ELIGIBLE'].includes(document.evolutionRecord.setor)) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      title="Adiar paciente"
      size="sm"
      paddingY="0"
      paddingX="2"
      marginX="1"
      colorScheme="gray"
      onClick={() =>
        dispatch(ModalScheduleReminderActions.modalScheduleSetIsOpen(true, true, document, false))
      }
    >
      <Icon path={mdiCalendarArrowRight} size={0.8} />
    </Button>
  );
}

import { useContext, useMemo, useEffect, useState } from 'react';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import {
  mdiAccountArrowRight,
  mdiMessageTextOutline,
  mdiTagPlusOutline,
  mdiClipboardEditOutline,
  mdiFileDocumentOutline,
  mdiCalendar,
  mdiCalendarRemove,
} from '@mdi/js';

import MoreOptions from 'Components/MoreOptions';
import TagsModal from 'Components/TagsModal';
import Modal from 'Components/Modal';
import Button from 'Components/NewButton';
import Actions from 'Components/Patient/Actions';
import { MORE_ACTIONS } from 'Utils/types';
import MemedContext from 'Context/memedContext';
import { useMoreActions } from 'Hooks/useMoreActions';
import { SERVICE_STEPS } from 'Utils/Config/SectorConfig';
import ModuleContext from 'Context/moduleContext';

import LocalCache from 'Services/LocalCache';
import amplitude from 'Utils/Helpers/amplitude';
import dateFns from 'Utils/Helpers/dateFns';
import moduleHelper from 'Utils/Helpers/moduleHelper';
import { parseBack } from 'Utils/Helpers/dateFnsHelper';

import * as Styled from './styled';
import { useDispatch } from 'react-redux';
import { ModalCancelScheduleActions } from 'store/ducks/modal_cancel_schedule';
import { ModalScheduleActions } from 'store/ducks/modal_schedule';

type OwnProps = {
  activeActions?: string[];
  componentActions?: React.ReactElement | React.ReactNode | ((...args: any[]) => any);
  memedActive?: boolean;
  onFinishModal?: (...args: any[]) => any;
};

type Props = OwnProps & typeof MoreActions.defaultProps;

const MoreActions = ({
  onFinishModal,
  alertRecord,
  medicalRecord,
  stepActive,
  location,
  activeActions,
  componentActions: ComponentActions,
  updateOriginalDataChat,
}: Props) => {
  const [showTagsModal, handleShowTagsModal] = useState(false);
  const { memedActive, setMemedPatient } = useContext(MemedContext);
  const { moduleAccess } = useContext(ModuleContext);
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState(0);

  const {
    store: { showModal, menuAction, medicalRecord: actionMedicalRecord, soapEvolutionModal },
    dispatch: actionDispatch,
  } = useMoreActions();

  const isChatActivated = useMemo(
    () => moduleHelper.enabledService(moduleAccess, SERVICE_STEPS(moduleAccess).chat.value),
    [moduleAccess]
  );

  const isOpen = useMemo(
    () => showModal && actionMedicalRecord === medicalRecord,
    [medicalRecord, actionMedicalRecord, showModal]
  );

  const dateLastMessage = useMemo(() => {
    let _dateLastMessage = null;
    if (alertRecord?.monitoring?.dataUltimoEnvio && alertRecord?.chat?.dateActiveContact) {
      _dateLastMessage = Math.max.apply(null, [
        new Date(parseBack(alertRecord?.chat?.dateActiveContact)),
        new Date(parseBack(alertRecord?.monitoring?.dataUltimoEnvio)),
      ]);
      return new Date(_dateLastMessage);
    } else if (alertRecord?.monitoring?.dataUltimoEnvio && !alertRecord?.chat?.dateActiveContact) {
      _dateLastMessage = parseBack(alertRecord?.monitoring?.dataUltimoEnvio);
      return new Date(_dateLastMessage);
    } else if (alertRecord?.chat?.dateActiveContact) {
      _dateLastMessage = parseBack(alertRecord?.chat?.dateActiveContact);
      return new Date(_dateLastMessage);
    } else {
      const date = new Date();
      return date.setDate(date.getDate() - 1);
    }
  }, [alertRecord]);

  const isSchedule = useMemo(() => {
    const [module] = LocalCache?.getModules()?.filter((item) => item?.slug === moduleAccess);
    return module?.serviceStep?.isTelemedicineScheduleEnabled === true ? true : false;
  }, [moduleAccess]);

  const checkRemainingTime = () => {
    const diffLastActiveContact = dateLastMessage
      ? differenceInMinutes(new Date(), dateLastMessage)
      : 0;
    const _remainingTime = 24 * 60 - Math.abs(diffLastActiveContact);
    setRemainingTime(_remainingTime);
    if (_remainingTime <= 0 && updateOriginalDataChat && !alertRecord?.chat?.needActiveContact) {
      const _original = { ...alertRecord, chat: { ...alertRecord?.chat } };
      if (_original?.chat) {
        _original.chat.needActiveContact = true;
      }
      updateOriginalDataChat(_original);
    } else if (
      _remainingTime > 0 &&
      updateOriginalDataChat &&
      alertRecord?.chat?.needActiveContact
    ) {
      const _original = { ...alertRecord, chat: { ...alertRecord?.chat } };
      if (_original?.chat) {
        _original.chat.needActiveContact = false;
      }
      updateOriginalDataChat(_original);
    }
  };

  useEffect(() => {
    checkRemainingTime();
    const interval = setInterval(checkRemainingTime, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [alertRecord]);

  const handleAmplitudeActionItens = (subSteps, label, moduleAccess, stepActive) => {
    let serviceAtive;
    if (stepActive === 'chat' || stepActive === 'telemedicine') {
      serviceAtive = {
        subSteps: {
          route: subSteps,
        },
      };
    }
    const data = {
      name: LocalCache?.getUser()?.name.toString(),
      email: LocalCache?.getUser()?.email.toString(),
      userId: LocalCache?.getUser()?.userId.toString(),
      entityId: LocalCache?.getUser()?.entityId.toString(),
      moduleAccess: moduleAccess,
      serviceStep: stepActive,
      action: label,
      ...serviceAtive,
      date: dateFns.formatTo(new Date(), 'dd/MM/yyyy HH:mm:ss'),
    };

    const userId = `${data.userId}.${data.entityId}`;
    amplitude.setUserId(userId);
    amplitude.trackEvent('ACTIONS_LIST_LAURA_CARE', data);
  };

  const handleCloseModal = () => {
    actionDispatch({ type: 'HIDE_MODAL' });
  };

  const templateSendLimit = 2;

  const moreOptions = () => {
    const tmpOptions = [];
    const activeActiveContact =
      alertRecord?.chat?.templateCount === templateSendLimit &&
      (alertRecord?.chat?.dateActiveContact || alertRecord?.monitoring)
        ? Boolean(remainingTime <= 0)
        : true;

    activeActions.forEach((active) => {
      switch (active) {
        case MORE_ACTIONS.RESCHEDULE_PATIENT:
          if (isSchedule) {
            tmpOptions.push({
              label: 'Remarcar consulta',
              icon: mdiCalendar,
              onClick: () => {
                handleAmplitudeActionItens(location, 'Remarcar consulta', moduleAccess, stepActive);
                dispatch(ModalScheduleActions.modalScheduleSetIsOpen(true, alertRecord, true));
              },
            });
          }

          break;
        case MORE_ACTIONS.CANCEL_SCHEDULE:
          if (isSchedule) {
            tmpOptions.push({
              label: 'Cancelar consulta',
              icon: mdiCalendarRemove,
              onClick: () => {
                handleAmplitudeActionItens(location, 'Cancelar consulta', moduleAccess, stepActive);
                dispatch(
                  ModalCancelScheduleActions.modalCancelScheduleSetIsOpen(true, alertRecord)
                );
              },
            });
          }
          break;
        case MORE_ACTIONS.MOVE_PACIENT:
          tmpOptions.push({
            label: 'Mover paciente',
            icon: mdiAccountArrowRight,
            onClick: () => {
              handleAmplitudeActionItens(location, 'Mover paciente', moduleAccess, stepActive);
              actionDispatch({ type: 'CHANGE_ACTION', menuAction: MORE_ACTIONS.MOVE_PACIENT });
              actionDispatch({ type: 'SHOW_MODAL', medicalRecord });
            },
          });
          break;
        case MORE_ACTIONS.REGISTER_EVOLUTION: {
          tmpOptions.push({
            label: 'Registrar evolução',
            icon: mdiMessageTextOutline,
            onClick: () => {
              handleAmplitudeActionItens(location, 'Registrar evolução', moduleAccess, stepActive);
              actionDispatch({
                type: 'CHANGE_ACTION',
                menuAction: MORE_ACTIONS.REGISTER_EVOLUTION,
              });
              actionDispatch({ type: 'SHOW_MODAL', medicalRecord });
            },
          });
          break;
        }
        case MORE_ACTIONS.SEND_ACTIVE_CONTACT_TEMPLATE:
          if (isChatActivated) {
            tmpOptions.push({
              label: 'Enviar template / Contato ativo',
              icon: mdiFileDocumentOutline,
              disabled: !activeActiveContact,
              helpText: !activeActiveContact
                ? `Você poderá enviar um novo contato ativo dentro de ${
                    Math.round(remainingTime / 60) > 2
                      ? `${Math.floor(remainingTime / 60)} horas`
                      : `${
                          remainingTime < 120 && remainingTime > 60
                            ? '1 hora'
                            : remainingTime > 1
                            ? `${remainingTime} minutos`
                            : '1 minuto'
                        }`
                  }.`
                : '',
              onClick: () => {
                handleAmplitudeActionItens(
                  location,
                  'Enviar template / Contato ativo',
                  moduleAccess,
                  stepActive
                );
                actionDispatch({
                  type: 'CHANGE_ACTION',
                  menuAction: MORE_ACTIONS.SEND_ACTIVE_CONTACT_TEMPLATE,
                });
                actionDispatch({ type: 'SHOW_MODAL', medicalRecord });
              },
            });
          }
          break;
        case MORE_ACTIONS.ADD_TAG: {
          tmpOptions.push({
            label: 'Adicionar/Criar tag',
            icon: mdiTagPlusOutline,
            onClick: () => {
              handleAmplitudeActionItens(location, 'Adicionar/Criar tag', moduleAccess, stepActive);
              handleShowTagsModal(true);
            },
          });
          break;
        }
        case MORE_ACTIONS.SEND_PRESCRIPTION: {
          tmpOptions.push({
            label: 'Enviar prescrição',
            icon: mdiClipboardEditOutline,
            disabled: !memedActive,
            helpText: 'Você deve ativar a prescrição.',
            onClick: () => {
              handleAmplitudeActionItens(
                location,
                'Você deve ativar a prescrição.',
                moduleAccess,
                stepActive
              );

              if (memedActive) {
                setMemedPatient({
                  name: alertRecord.patient.nome,
                  phone: alertRecord.patient.telefone.match(/\d/g).join(''),
                  medicalRecord,
                });
              }
            },
          });
          break;
        }
        case MORE_ACTIONS.UPDATE_PACIENT: {
          tmpOptions.push({
            label: 'Editar dados do paciente',
            icon: mdiClipboardEditOutline,
            onClick: () => {
              actionDispatch({ type: 'CHANGE_ACTION', menuAction: MORE_ACTIONS.UPDATE_PACIENT });
              actionDispatch({ type: 'SHOW_MODAL', medicalRecord });
            },
          });
          break;
        }
        default:
          break;
      }
    });

    return tmpOptions;
  };

  return (
    <>
      <MoreOptions
        items={moreOptions()}
        disabled={LocalCache.isDataEncrypted() && alertRecord?.patient?.cpf.length >= 15}
        info={{ action: 'action', location, moduleAccess, stepActive }}
      />
      <Modal isOpen={isOpen} onClose={handleCloseModal} height={soapEvolutionModal}>
        <ComponentActions
          callBack={() => {
            handleCloseModal();
            onFinishModal && onFinishModal();
          }}
          medicalRecord={medicalRecord}
          alertRecord={alertRecord}
          menuAction={menuAction}
          onCloseModal={handleCloseModal}
        />
        {![MORE_ACTIONS.SEND_ACTIVE_CONTACT_TEMPLATE, MORE_ACTIONS.UPDATE_PACIENT].includes(
          menuAction
        ) &&
          (menuAction === 'REGISTER_EVOLUTION' ? null : (
            <Styled.CancelButtonContainer>
              <Button
                maxWidth={372}
                fullWidth
                variant="link"
                onClick={() => handleCloseModal()}
                style={{ marginTop: '15px' }}
              >
                CANCELAR
              </Button>
            </Styled.CancelButtonContainer>
          ))}
      </Modal>
      <TagsModal
        alertRecord={alertRecord}
        setShowTagsModal={handleShowTagsModal}
        showTagsModal={showTagsModal}
        callBack={onFinishModal}
      />
    </>
  );
};

MoreActions.defaultProps = {
  componentActions: Actions,
  activeActions: [],
  onFinishModal: null,
  memedActive: false,
};

export default MoreActions;

import { motion } from 'framer-motion';
import styled from 'styled-components';

export const BadgeCounter = styled(motion.div)`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  width: 18px;
  height: 18px;
  background: #ff881f;
  color: white;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
`;

import { createContext } from 'react';

const ModuleContext = createContext({
  modules: null,
  moduleAccess: null as unknown as string,
  stepActive: null,
  Container: null,
  client: null,
  subscriptionChange: false,
  moduleDispatch: () => void 0,
  filters: {
    tags: {
      active: null,
      options: [],
    },
    ubs: {
      active: null,
      options: [],
    },
    grupos: {
      active: null,
      options: [],
    },
    evolution: {
      active: null,
      options: [],
    },
    healthCare: {
      active: null,
      options: [],
    },
    status: {
      active: null,
      options: [],
    },
    flow: {
      active: null,
      options: [],
    },
    alerts: {
      active: null,
      options: [],
    },
  },
  setFilters: () => void 0,
  searchKeyword: null,
  setSearchKeyword: (keyword: string) => void 0,
});

export default ModuleContext;

import { Heading, useToast, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { PasswordIcon } from 'theme/icons';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'Hooks/useQueryParams';
import {
  useResetUserPasswordMutation,
  useResetPasswordTokenLazyQuery,
} from './__generated__/ResetPassword.generated';
import { PasswordForm, PasswordFormValues } from './PasswordForm';

export const resetPasswordTokenQuery = gql`
  query ResetPasswordToken($token: String!) {
    resetPasswordToken(token: $token) {
      isValid
    }
  }
`;

export const resetUserPasswordMutation = gql`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      success
      error
    }
  }
`;

export const ResetPassword = () => {
  const [loadingToken, setLoadingToken] = useState(true);

  const toast = useToast();
  const history = useHistory();
  const [token] = useQueryParams('token');

  const onNetworkError = useCallback(({ networkError }) => {
    if (networkError) {
      toast({
        status: 'error',
        title: 'Erro de conexão',
        description: 'Não foi possível conectar-se aos nossos servidores no momento.',
        position: 'top',
        isClosable: true,
      });
    }
  }, []);

  const expiredTokenToast = useCallback(() => {
    toast({
      status: 'error',
      title: 'Link expirou',
      description: 'O link utilizado expirou. Tente enviá-lo novamente.',
      position: 'top',
      isClosable: true,
    });
  }, []);

  const [loadResetPasswordToken] = useResetPasswordTokenLazyQuery({
    onError: onNetworkError,
    onCompleted: ({ resetPasswordToken }) => {
      if (!resetPasswordToken?.isValid) {
        expiredTokenToast();
        history.replace('login');
      }

      setLoadingToken(false);
    },
  });

  const [resetUserPassword] = useResetUserPasswordMutation({
    onError: onNetworkError,
    onCompleted: ({ resetUserPassword }) => {
      if (resetUserPassword?.success) {
        toast({
          status: 'success',
          title: 'Sucesso',
          description: 'Sua nova senha foi configurada.',
          position: 'top',
          isClosable: true,
        });
      } else {
        expiredTokenToast();
      }
      history.replace('login');
    },
  });

  useEffect(() => {
    if (token) {
      loadResetPasswordToken({ variables: { token } });
    } else {
      history.replace('login');
    }
  }, [token]);

  const onSubmit = useCallback(({ password: newPassword }: PasswordFormValues) => {
    if (token) {
      resetUserPassword({
        variables: {
          input: {
            token,
            newPassword,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <VStack spacing="2" marginBottom="6">
        <PasswordIcon boxSize="10" color="orange.500" />
        <Heading size="lg" fontWeight="light" textAlign="center">
          Crie uma nova senha
        </Heading>
      </VStack>
      <PasswordForm onSubmit={onSubmit} isDisabled={loadingToken} />
    </>
  );
};

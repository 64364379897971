import 'moment-timezone';
import moment from 'moment';

export const DateAndTime = {
  date: 'DD/MM/YYYY',
  time: 'HH:mm',
  datetime: 'DD/MM/YYYY - HH:mm',
  datetimeseconds: 'DD/MM/YYYY - HH:mm:ss',
  datetime_short: 'DD/MM/YY - HH:mm',
  timestamp: 'x',
};

export const DateAndTimeEn = {
  date: 'YYYY-MM-DD',
  time: 'HH:mm',
  datetime: 'YYYY-MM-DD - HH:mm',
  datetimeseconds: 'YYYY-MM-DD - HH:mm:ss',
  datetime_short: 'YY-MM-DD - HH:mm',
  timestamp: 'x',
};

export function compareDate(updatedAt) {
  const now = formatTo(
    moment(new Date().toISOString()).tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.000[Z]'),
    DateAndTime.timestamp
  );

  const update = formatTo(updatedAt, DateAndTime.timestamp);

  const days = Math.ceil((now - update) / (1000 * 60 * 60 * 24));

  return days === 1 ? 0 : -1;
}

function parseBack(datetime) {
  return datetime.replace('.000Z', '');
}

export function formatTo(datetime, to) {
  if (datetime) return moment(parseBack(datetime)).format(to);

  return '';
}

import { useEffect, useState } from 'react';

import { spring } from 'Utils/animations';

import * as Styled from './styled';

type OwnProps = {
  label?: string;
  color?: string;
  activeColor?: string;
  handleColor?: string;
  size?: 'sm' | 'md' | 'lg';
  onChange?: (...args: any[]) => any;
  disabled?: boolean;
};

type Props = OwnProps & typeof Toggle.defaultProps;

const Toggle = ({
  label,
  color,
  activeColor,
  handleColor,
  size,
  onChange,
  initialOn,
  disabled,
}: Props) => {
  const [isOn, handleIsOn] = useState(false);

  const variants = {
    disabled: {
      background: '#707070',
    },
    active: {
      background: activeColor,
    },
    normal: {
      background: color,
    },
  };

  const toggleSwitch = () => {
    if (disabled) {
      return;
    }

    handleIsOn(!isOn);
    onChange && onChange(!isOn);
  };

  useEffect(() => {
    handleIsOn(initialOn);
  }, [initialOn, handleIsOn]);

  return (
    <Styled.Container>
      <Styled.Switch
        variants={variants}
        size={size}
        active={isOn}
        color={color}
        disabled={disabled}
        initial={disabled ? 'disabled' : isOn ? 'active' : 'normal'}
        animate={disabled ? 'disabled' : isOn ? 'active' : 'normal'}
        onClick={toggleSwitch}
      >
        <Styled.Handle layout transition={spring} size={size} handleColor={handleColor} />
      </Styled.Switch>
      {label && <Styled.Label size={size}>{label}</Styled.Label>}
    </Styled.Container>
  );
};

Toggle.defaultProps = {
  color: '#0071eb',
  activeColor: '#FF881F',
  handleColor: '#fff',
  size: 'md',
  disabled: false,
};

export default Toggle;

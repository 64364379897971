import { useMutation, useQuery } from '@apollo/client';
import { mdiSend } from '@mdi/js';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import * as uuid from 'uuid';

import Checkbox from 'Components/Checkbox';
import Modal from 'Components/Modal';
import Button from 'Components/NewButton';
import Select from 'Components/NewSelect';
import Row from 'Components/Row';
import Toast from 'Components/Toast';
import DataContext from 'Context/dataContext';
import ModuleContext from 'Context/moduleContext';
import useCache from 'Hooks/useCache';
import { useMoreActions } from 'Hooks/useMoreActions';
import { getFirstName } from 'Utils';
import { SERVICE_STEPS, getEntityEmail, getEntityId } from 'Utils/Config/SectorConfig';
import environment from 'Utils/Dictionary/environment';
import { SEND_ACTIVE_CONTACT_MESSAGE } from 'repository/mutations';
import { GET_ACTIVE_CONTACT_TEMPLATES, GET_ENTITY_ENDPOINT_NAMES } from 'repository/queries';

import { useChat } from 'Components/Chat/hooks/useChat';
import moduleHelper from 'Utils/Helpers/moduleHelper';
import * as Styled from './styled';

type Props = {
  alertRecord: Record<string, any>;
  callBack: (...args: any[]) => any;
};

const SendActiveContactTemplates = ({ alertRecord, callBack }: Props) => {
  const {
    handleMedicalRecords,
    handleTemplateName,
    handleComplaint,
    handleTriageStep,
    handleServiceStep,
    store: { templateName, medicalRecords, complaint, triageStep, serviceStep },
  } = useMoreActions();
  const { moduleAccess } = useContext(ModuleContext);
  const { sector } = useContext(DataContext);
  const { fetchDocument } = useChat();

  const [user] = useCache('user');

  const monitoring_type = useMemo(() => {
    const remoteMonitoringType = JSON.parse(user?.entity?.remoteMonitoringTypes);
    if (remoteMonitoringType != null) {
      return Object.keys(remoteMonitoringType)?.map((key) => key);
    }

    return [];
  }, [user]);

  const { data, loading } = useQuery(GET_ACTIVE_CONTACT_TEMPLATES, {
    variables: {
      entityId: parseInt(user?.entityId),
      module: moduleAccess,
      sector: alertRecord?.evolutionRecord?.setor || sector,
      variables: {
        name: getFirstName(alertRecord?.patient?.nome),
        entity_name: user?.entity?.name,
        screening_url: `${environment.LANDING_TRIAGE}/${user?.entity?.slug}`,
        screening_entity: `${user?.entity?.doubtsLink}`,
        complaint: `${alertRecord?.flow}`,
        entity_phone: `${user?.entity?.phoneNumber}`,
        entity_opening_hours: `${user?.entity?.openingHours}`,
      },
      monitoring_type,
    },
  });

  const { data: dataModules, loading: loadingModules } = useQuery(GET_ENTITY_ENDPOINT_NAMES, {
    variables: {
      entityId: parseInt(user?.entityId),
    },
  });

  const [sendActiveContactMessage, { loading: sendingActiveContactMessage }] = useMutation(
    SEND_ACTIVE_CONTACT_MESSAGE
  );

  const servicesOpts = useMemo(
    () =>
      Object.entries(SERVICE_STEPS(moduleAccess))
        .filter(
          ([, service]) =>
            !service?.hideOnChangeStatus &&
            (moduleHelper.enabledService(moduleAccess, service.value) || service.fixed)
        )
        .map(([, service]) => ({
          ...service,
          icon: null,
        })),
    [moduleAccess]
  );

  const complaintOptions = useMemo(() => {
    if (dataModules) {
      return dataModules?.getEntityEndpointsNames?.sections
        ?.filter((section) => section?.belongs === moduleAccess)
        .map((sector) => ({
          value: sector?.slug,
          label: sector?.name,
        }));
    }

    return [];
  }, [dataModules, moduleAccess]);

  const triageStepOptions = useMemo(() => {
    if (dataModules) {
      return dataModules?.getEntityEndpointsNames?.triages
        ?.filter(
          (triage) => triage?.belongs === complaint?.value && triage?.careline === moduleAccess
        )
        .map((triage) => ({
          value: triage?.slug,
          label: triage?.name,
        }));
    }

    return [];
  }, [dataModules, complaint, moduleAccess]);

  const selectedTemplate = useMemo(
    () => data?.getActiveContactTemplates?.find((template) => template?.name === templateName),
    [templateName, data?.getActiveContactTemplates]
  );

  const hasScreeningUrl = useMemo(() => {
    if (selectedTemplate) {
      return selectedTemplate?.variables?.includes('{SCREENING_URL}');
    }

    return false;
  }, [selectedTemplate]);

  const hasNextStep = useMemo(
    () => user?.entity?.nextServiceStep && alertRecord?.evolutionRecord?.serviceStep === 'eligible',
    [alertRecord, user]
  );

  useEffect(
    () =>
      handleMedicalRecords([
        { value: alertRecord?.patient?.telefone, label: alertRecord?.patient?.nome },
      ]),
    [alertRecord, handleMedicalRecords]
  );

  const submit = async () => {
    try {
      const promises = medicalRecords.map((mRecord) => {
        return new Promise((resolve, reject) => {
          sendActiveContactMessage({
            variables: {
              template: selectedTemplate?.name,
              variables: selectedTemplate?.variables
                ?.replaceAll(/{USER_NAME}/g, getFirstName(mRecord?.label))
                ?.replaceAll(/{ENTITY_NAME}/g, user?.entity?.name)
                ?.replaceAll(
                  /{SCREENING_URL}/g,
                  `${environment.LANDING_TRIAGE}/${user?.entity?.slug}/${moduleAccess}/${complaint?.value}/${triageStep?.value}`
                )
                ?.replaceAll(/{SCREENING_ENTITY}/g, `${user?.entity?.doubtsLink}`)
                ?.replaceAll(/{COMPLAINT_NAME}/g, alertRecord?.flow)
                ?.replaceAll(/{ENTITY_PHONE}/g, `${user?.entity?.phoneNumber}`)
                ?.replaceAll(/{ENTITY_OPENING_HOURS}/g, `${user?.entity?.openingHours}`),
              cellphone: mRecord?.value,
              entityId: getEntityId()[0],
              medicalRecord: alertRecord.medicalRecord,
              text: selectedTemplate?.message,
              module: moduleAccess,
              name: mRecord?.label,
              user: getEntityEmail(),
              application: 'Backoffice',
              uuid: uuid.v4(),
              complaint: complaint?.value,
              triagestep: triageStep?.value,
              ...(hasNextStep
                ? { nextServiceStep: serviceStep?.value, nextSector: serviceStep?.initialSector }
                : {}),
            },
          })
            .then(() => {
              resolve();
            })
            .catch((err) => reject(err));
        });
      });

      await Promise.all(promises)
        .then(() => {
          toast(
            <Toast
              type="success"
              title="Template enviado com sucesso"
              subtitle="Em breve o paciente receberá a mensagem."
            />
          );
          callBack && callBack();
          fetchDocument(alertRecord);
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      Sentry.captureException(error);
      toast(
        <Toast
          type="error"
          title="Não foi possível enviar o template de contato ativo"
          subtitle={error.message}
        />
      );
    }
  };

  return (
    <Styled.ModalContainer>
      <Modal.Title>Enviar Template / Contato Ativo</Modal.Title>
      <Modal.Subtitle>
        Para enviar um Template/Contato Ativo, selecione o contato, o motivo e escolha a mensagem
        que deseja enviar.
      </Modal.Subtitle>
      <Styled.Container>
        <Select
          label="Enviar contato ativo para:"
          placeholder="Selecione um paciente"
          options={[
            {
              value: alertRecord?.patient?.telefone,
              label: alertRecord?.patient?.nome,
            },
          ]}
          value={medicalRecords}
          onChange={(newValue) => handleMedicalRecords(newValue)}
          hideDropdownIndicator
          openMenuOnClick={false}
          readonly
          menuPosition="fixed"
          width="60%"
        />

        {loading ? (
          <Styled.Loading>
            {[0, 1].map((it) => (
              <Skeleton key={it} style={{ margin: '0px 10px' }} width="45%" count={5} />
            ))}
          </Styled.Loading>
        ) : (
          <Styled.Options>
            {data?.getActiveContactTemplates?.map((template) => {
              return (
                <Checkbox
                  key={template?.name}
                  color="#A9A9A9"
                  align="flex-start"
                  checked={templateName === template?.name}
                  onChange={() => handleTemplateName(template?.name)}
                  label={
                    <Styled.Option selected={templateName === template?.name}>
                      <Styled.Name>{template?.description}</Styled.Name>
                      {template?.message}
                    </Styled.Option>
                  }
                />
              );
            })}
          </Styled.Options>
        )}
        {hasScreeningUrl && !loadingModules ? (
          <>
            <Styled.Triage>
              <Select
                label="Queixa principal:"
                placeholder="Selecione uma opção"
                options={complaintOptions}
                value={complaint}
                isSearchable
                onChange={(newComplaint) => handleComplaint(newComplaint)}
                menuPosition="fixed"
                hideSelectedOptions={false}
              />
              <div />
              <Select
                label="Triagem:"
                placeholder="Selecione uma opção"
                options={triageStepOptions}
                value={triageStep}
                isSearchable
                onChange={(newTriageStep) => handleTriageStep(newTriageStep)}
                menuPosition="fixed"
                hideSelectedOptions={false}
              />
            </Styled.Triage>
            {hasNextStep ? (
              <Styled.Triage>
                <Select
                  label="Etapa:"
                  placeholder="Selecione uma opção"
                  options={servicesOpts}
                  value={serviceStep}
                  isSearchable
                  onChange={(newServiceStep) => handleServiceStep(newServiceStep)}
                  menuPosition="fixed"
                  hideSelectedOptions={false}
                />
              </Styled.Triage>
            ) : null}
          </>
        ) : null}
      </Styled.Container>
      <Row justify="flex-end">
        <Button
          onClick={submit}
          fullWidth
          maxWidth={93}
          suffixIcon={mdiSend}
          disabled={
            Boolean(templateName?.trim() === '') ||
            medicalRecords?.lenght <= 0 ||
            (hasScreeningUrl && (!complaint || !triageStep || (hasNextStep && !serviceStep)))
          }
          loading={sendingActiveContactMessage}
        >
          Enviar
        </Button>
      </Row>
    </Styled.ModalContainer>
  );
};

export default SendActiveContactTemplates;

import styled from 'styled-components';
import IconBase from '@mdi/react';
import { motion } from 'framer-motion';

export const Container = styled.div`
  padding: 16px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f4f5f7;
  }
`;

export const ContentContainer = styled.div`
  margin-left: 16px;
  display: flex;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
`;

export const Title = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  color: #444444;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
`;

export const SubTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  color: #aaaaaa;
  font-size: 12px;
  margin-top: -3px;
`;

export const LastInteraction = styled.div`
  font-family: 'Roboto';
  color: #a9a9a9;
  font-size: 12px;
`;

export const CloseIconContainer = styled(motion.div)``;

export const Icon = styled(IconBase)`
  position: absolute;
  bottom: 8px;
  right: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 100%;

  &:hover {
    background: #ececec;
  }
`;

import { gql } from '@apollo/client';

const TagsModel = {
  GET_ALL_TAGS: (entityId) => gql`
    query getAllTags {
      getAllTags(entityId: ${entityId})
    }
  `,
};

export const UPDATE_PATIENT_TAGS = gql`
  mutation updatePatientTags($data: String) {
    updatePatientTags(data: $data)
  }
`;

export default TagsModel;

import birthdateValidator from 'Utils/Helpers/validate/birthdate';
import cpfValidator from 'Utils/Helpers/validate/cpf';
import cellphone from 'Utils/Helpers/validate/cellphone';

export const validationClass = (classNames, error) => {
  if (error) return `${classNames} error`;

  return classNames;
};

export const rules = {
  number: /^(\d+)$/,
  uf: /^[a-zA-Z]{2}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/i,
  document: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  zipcode: /^[0-9]{5}-[\d]{3}$/,
  date: /^[\d]{2}\/[\d]{2}\/[\d]{4}$/,
  latLong: /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,10}[,]-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,10}$/,
  plate: /^(([a-zA-Z]{3}-[0-9]{4})|[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2})$/,
  crm: /([0-9]{4,10})$/,
  corenCategory: /^([a-zA-Z]+)$/,
};

export const rulesMessages = {
  number: {
    required: 'Favor informar o número.',
    pattern: 'número inválido.',
  },
  uf: {
    required: 'Obrigatório.',
    pattern: 'Obrigatório.',
  },
  plate: {
    required: 'Favor informar a placa.',
    pattern: 'placa inválida (AAA-0000 ou AAA0A00).',
  },
  firstName: {
    required: 'Favor informar o nome.',
  },
  lastName: {
    required: 'Favor informar o sobrenome.',
  },
  birthdate: {
    required: 'Favor informar a data de nascimento.',
    pattern: 'Favor informar uma data válida.',
  },
  email: {
    required: 'Favor informar o email.',
    pattern: 'Favor informar um e-mail válido.',
  },
  password: {
    required: 'Favor informar a senha.',
    pattern: 'Favor usar letras e números, e informar no mínimo 6 caracteres.',
  },
  document: {
    required: 'Favor informar o documento.',
    pattern: 'Favor informar um documento válido.',
  },
  phone: {
    required: 'Favor informar o telefone.',
    custom: 'Favor informa um telefone válido.',
  },
  zipcode: {
    required: 'Favor informar o CEP.',
    pattern: 'Favor informar um CEP válido.',
  },
  date: {
    required: 'Favor informar a Data.',
    pattern: 'Informar no formato (DD/MM/AAAA).',
  },
  crm: {
    required: 'Favor informar o CRM.',
    pattern: 'Favor informar um CRM válido.',
  },
  coren: {
    required: 'Favor informar o COREN.',
    pattern: 'Favor informar um COREN válido.',
  },
  corenCategory: {
    required: 'Favor informar o COREN.',
    pattern: 'Favor informar um COREN válido.',
  },
  latLong: {
    pattern: 'Latitude/Longitude juntos separadas por virgula.',
  },
};

export const customRules = {
  empty: (val) => val.trim().length > 0 || 'Campo requerido',
  cpf: (v) => cpfValidator.isValid(v) || rulesMessages.document.pattern,
  birthdate: (v) => birthdateValidator.isValid(v),
  cellphone: (v) => Boolean(cellphone.isValid(v)) || rulesMessages.phone.custom,
  email: (v) => v.length === 0 || v.match(rules.email) || rulesMessages.email.pattern,
};

import { useRef } from 'react';
import chroma from 'chroma-js';
import { components } from 'react-select';

import * as Styled from './styled';

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 99999,
  }),
  multiValue: (styles, { data }) => {
    if (data.color) {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: `${color.alpha(0.1).css()} !important`,
      };
    }

    return { ...styles };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    ...(data.color ? { color: data.color } : {}),
  }),
};

type OwnProps = {
  creatable?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  cacheOptions?: boolean;
  defaultOptions?: boolean;
  isSearchable?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  hideDropdownIndicator?: boolean;
  openMenuOnClick?: boolean;
  showCheckbox?: boolean;
  label?: string;
  createLabel?: string;
  noOptionsMessage?: string;
  loadingMessage?: string;
  maxLength?: number;
  placeholder?: string;
  menuPosition?: 'fixed' | 'absolute';
  width?: string | number;
  onChange: (...args: any[]) => any;
  loadOptions?: (...args: any[]) => any;
};

type Props = OwnProps & typeof NewSelect.defaultProps;

const NewSelect = ({
  creatable,
  label,
  createLabel,
  noOptionsMessage,
  loadingMessage,
  maxLength,
  showCheckbox,
  hideDropdownIndicator,
  ...props
}: Props) => {
  let menuIsOpen = false;
  const refSelect = useRef();

  const CustomChevronDow = () => (hideDropdownIndicator ? null : <Styled.ChevronDown />);

  const CustomInput = (p) => <components.Input {...p} maxLength={maxLength} />;

  const CustomSelectOption = (p) => (
    <Styled.Option {...p}>
      {showCheckbox ? (
        <Styled.Checkbox type="checkbox" checked={p.isSelected} onChange={() => null} />
      ) : (
        p.data.icon && (
          <Styled.Icon path={p.data.icon} size={p.data.iconSize} color={p.data.iconColor} />
        )
      )}
      {p.data.label}
    </Styled.Option>
  );

  const CustomContainer = (p) => {
    if (refSelect.current) {
      const { state } = refSelect.current;
      menuIsOpen = state?.menuIsOpen;
    }

    return (
      <Styled.Container width={props.width} active={menuIsOpen}>
        {label && <Styled.Label>{label}</Styled.Label>}
        <Styled.Control {...p} openMenuOnClick={props.openMenuOnClick} />
      </Styled.Container>
    );
  };

  const customProps = {
    loadingMessage: () => loadingMessage,
    classNamePrefix: 'react-select',
    noOptionsMessage: () => noOptionsMessage,
    formatCreateLabel: (inputValue) => `${createLabel} "${inputValue}"`,
    components: {
      Control: CustomContainer,
      Option: CustomSelectOption,
      DropdownIndicator: CustomChevronDow,
      Input: CustomInput,
    },
    styles: customStyles,
    ref: refSelect,
    ...(showCheckbox ? { closeMenuOnSelect: false, hideSelectedOptions: false } : {}),
    menuPortalTarget: document.querySelector('body'),
  };

  if (props.loadOptions) {
    if (creatable) {
      return <Styled.AsyncCreatableSelectWrapper {...props} {...customProps} />;
    }

    return <Styled.AsyncSelectWrapper {...props} {...customProps} />;
  }

  if (creatable) {
    return <Styled.CreatableSelectWrapper {...props} {...customProps} />;
  }

  return <Styled.SelectWrapper {...props} {...customProps} />;
};

NewSelect.defaultProps = {
  menuPosition: 'absolute',
  creatable: false,
  label: null,
  width: '100%',
  isSearchable: false,
  placeholder: 'Selecione uma opção',
  loadOptions: null,
  createLabel: 'Adicionar',
  noOptionsMessage: 'Nenhuma opção encontrada',
  loadingMessage: 'carregando...',
  maxLength: null,
  isMulti: false,
  isClearable: false,
  cacheOptions: false,
  defaultOptions: false,
  closeMenuOnSelect: true,
  hideSelectedOptions: true,
  hideDropdownIndicator: false,
  openMenuOnClick: true,
  showCheckbox: false,
};

export default NewSelect;

import type { SystemStyleObject, SystemStyleFunction } from '@chakra-ui/styled-system';

const baseStyle: SystemStyleObject = {
  lineHeight: '1',
  border: '1px',
  borderRadius: 'md',
  fontWeight: 'medium',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _focusVisible: {
    boxShadow: 'outline',
  },
  _disabled: {
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
};

const variantFilled: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  if (c === 'light-gray') {
    return {
      bg: 'gray.100',
      borderColor: 'gray.100',
      color: 'gray.400',

      _hover: {
        bg: 'gray.200',
        borderColor: 'gray.200',

        _disabled: {
          bg: 'gray.50',
          borderColor: 'gray.50',
          color: 'white',
        },
      },
      _active: { bg: 'gray.100', borderColor: 'gray.100' },
      _disabled: {
        bg: 'gray.50',
        borderColor: 'gray.50',
        color: 'white',
      },
    };
  }

  const { color = 'white' } = accessibleColorMap[c] ?? {};

  return {
    bg: `${c}.500`,
    borderColor: `${c}.500`,
    color: color,
    _hover: {
      bg: `${c}.400`,
      _disabled: {
        bg: 'gray.100',
        borderColor: 'gray.100',
        color: 'white',
      },
    },
    _active: { bg: `${c}.500` },
    _disabled: {
      bg: 'gray.100',
      borderColor: 'gray.100',
      color: 'white',
    },
  };
};

const variantOutlined: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    color: `${c}.500`,
    bg: 'transparent',
    borderColor: `${c}.500`,

    _hover: {
      color: `${c}.300`,
      borderColor: `${c}.300`,

      _disabled: {
        color: 'gray.100',
        borderColor: 'gray.100',
      },
    },

    _active: {
      color: `${c}.500`,
      borderColor: `${c}.500`,
    },

    _disabled: {
      color: 'gray.100',
      borderColor: 'gray.100',
    },
  };
};

const variantFlat: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    color: `${c}.500`,
    bg: 'transparent',
    borderColor: 'transparent',

    _hover: {
      bg: `${c}.50`,
      borderColor: `${c}.50`,

      _disabled: {
        color: 'gray.200',
        bg: 'transparent',
        borderColor: 'transparent',
      },
    },

    _active: {
      bg: `${c}.100`,
      borderColor: `${c}.100`,
    },

    _disabled: {
      color: 'gray.200',
      bg: 'transparent',
      borderColor: 'transparent',
    },
  };
};

const variantLink: SystemStyleFunction = (props) => {
  return {
    ...variantFlat(props),
    textDecoration: 'underline',
    fontWeight: 'normal',
    padding: 0,
    height: 'auto',
  };
};

type AccessibleColor = {
  color?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    color: 'black',
  },
  orange: {
    color: 'black',
  },
};

const variantUnstyled: SystemStyleObject = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
};

const variants = {
  filled: variantFilled,
  outlined: variantOutlined,
  flat: variantFlat,
  link: variantLink,
  unstyled: variantUnstyled,
};

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    h: 12,
    minW: 12,
    fontSize: 'lg',
    px: 8,
    py: 4,
  },
  md: {
    h: 10,
    minW: 10,
    fontSize: 'md',
    px: 4,
    py: 2,
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: 'sm',
    px: 3,
    py: 1,
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: 'xs',
    px: 2,
    py: 1,
  },
};

export const buttonTheme = {
  baseStyle,
  variants,
  sizes,
  defaultProps: { colorScheme: 'blue', variant: 'filled', size: 'md' },
};

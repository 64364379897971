const baseStyle = {
  opacity: 0.6,
  borderColor: 'inherit',
};

const variantSolid = {
  borderStyle: 'solid',
};

const variantDashed = {
  borderStyle: 'dashed',
};

const variants = {
  solid: variantSolid,
  dashed: variantDashed,
};

export const dividerTheme = {
  baseStyle,
  variants,
  defaultProps: {
    variant: 'solid',
    colorScheme: 'gray',
  },
};

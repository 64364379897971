import 'regenerator-runtime/runtime';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { UnleashClient } from 'unleash-proxy-client';

import environment from 'Utils/Dictionary/environment';
import { App } from 'Components';

import 'bootstrap/dist/css/bootstrap.css';
import '@mdi/font/css/materialdesignicons.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'Assets/css/index.css';
import 'Assets/css/nissan.css';
import 'Assets/css/table.css';
import { Provider } from 'react-redux';
import store from './store';
import { DataTableProvider } from 'Components/Datatable/container';
import { UnleashProvider } from 'Context/unleashContext';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

if (environment.isProduction) {
  hotjar.initialize(
    parseInt(environment.HOTJAR_ID as string),
    parseInt(environment.HOTJAR_SV as string)
  );
}

if (environment.SENTRY_DSN) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: parseInt(environment.SENTRY_TRACES_SAMPLE_RATE || '0.2'),
    release: environment.VERSION,
    environment: environment.SENTRY_ENVIRONMENT,
  });
}

const unleashClient = new UnleashClient({
  appName: environment.UNLEASH_APP_NAME as string,
  clientKey: environment.UNLEASH_CLIENT_KEY as string,
  disableMetrics: !environment.isProduction,
  metricsInterval: parseInt(environment.UNLEASH_METRICS_INTERVAL as string),
  refreshInterval: parseInt(environment.UNLEASH_REFRESH_INTERVAL as string),
  url: environment.UNLEASH_PROXY_URL as string,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ChakraProvider theme={theme} resetCSS={false}>
    <Provider store={store}>
      <UnleashProvider client={unleashClient}>
        <DataTableProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </DataTableProvider>
      </UnleashProvider>
    </Provider>
  </ChakraProvider>
);

import { Cache as CacheBase } from '@wora/cache-persist';
import { IDBStorage } from '@wora/cache-persist/lib/idbstorage';

const browser = navigator.userAgent.toLowerCase();
const isSafari = browser.indexOf('safari') > -1 && browser.indexOf('chrome') < 0;

const indexedDbAvailable =
  (window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB) &&
  !isSafari;

class Cache extends CacheBase {
  constructor() {
    let idb = {};

    if (indexedDbAvailable) {
      const idbStorages = IDBStorage.create({
        name: 'padigital',
        storeNames: ['cache'],
      });

      idb = {
        ...idb,
        storage: idbStorages[0],
        serialize: false,
      };
    }

    super(idb);
  }

  set(key, value) {
    super.set(key, value);
    super.flush();
  }

  remove(key) {
    super.remove(key);
    super.flush();
  }

  delete(key) {
    super.delete(key);
    super.flush();
  }

  async purge() {
    super.purge();
    await super.flush();
  }
}

export default new Cache();

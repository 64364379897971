import BurguerMenu from 'Components/BurgerMenu';

import LogoDefault from 'Assets/nissan.svg?url';

import * as Styled from './styled';

type OwnProps = {
  background?: string;
  children: React.ReactElement[] | React.ReactElement;
  logo?: string;
  options?: {
    label: string;
    icon: string;
    onClick?: (...args: any[]) => any;
    href?: string;
  }[];
  title?: string;
};

type Props = OwnProps & typeof HeaderTeleAtendimento.defaultProps;

const HeaderTeleAtendimento = ({ children, logo, title, options, ...props }: Props) => {
  return (
    <Styled.Container {...props}>
      <Styled.Logo alt={title} src={logo} />
      <Styled.Content>
        <Styled.Children>{children}</Styled.Children>
        {options.length && (
          <Styled.RightSection>
            <BurguerMenu items={options} />
          </Styled.RightSection>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

HeaderTeleAtendimento.defaultProps = {
  background: 'white',
  logo: LogoDefault,
  title: 'Nissan - TeleAtendimento',
  options: [],
};

HeaderTeleAtendimento.Divider = Styled.Divider;
HeaderTeleAtendimento.Col = Styled.Col;

export default HeaderTeleAtendimento;

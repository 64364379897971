import { createContext } from 'react';

const dataText = createContext({
  data: null,
  loading: false,
  doctorInRoom: false,
  totalDoctorsOnline: 0,
  toggleDoctorStatus: () => void 0,
  fetchData: () => void 0,
});

export default dataText;

import * as Types from '../../../data/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InviteTokenQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type InviteTokenQuery = { __typename?: 'Query', inviteToken?: { __typename?: 'InviteToken', email?: string | null, isValid: boolean } | null };

export type CreateUserPasswordMutationVariables = Types.Exact<{
  input: Types.CreateUserPasswordInput;
}>;


export type CreateUserPasswordMutation = { __typename?: 'Mutation', createUserPassword?: { __typename?: 'CreateUserPasswordPayload', success: boolean, error?: string | null } | null };


export const InviteTokenDocument = gql`
    query InviteToken($token: String!) {
  inviteToken(token: $token) {
    email
    isValid
  }
}
    `;

/**
 * __useInviteTokenQuery__
 *
 * To run a query within a React component, call `useInviteTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInviteTokenQuery(baseOptions: Apollo.QueryHookOptions<InviteTokenQuery, InviteTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteTokenQuery, InviteTokenQueryVariables>(InviteTokenDocument, options);
      }
export function useInviteTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteTokenQuery, InviteTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteTokenQuery, InviteTokenQueryVariables>(InviteTokenDocument, options);
        }
export type InviteTokenQueryHookResult = ReturnType<typeof useInviteTokenQuery>;
export type InviteTokenLazyQueryHookResult = ReturnType<typeof useInviteTokenLazyQuery>;
export type InviteTokenQueryResult = Apollo.QueryResult<InviteTokenQuery, InviteTokenQueryVariables>;
export const CreateUserPasswordDocument = gql`
    mutation CreateUserPassword($input: CreateUserPasswordInput!) {
  createUserPassword(input: $input) {
    success
    error
  }
}
    `;
export type CreateUserPasswordMutationFn = Apollo.MutationFunction<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>;

/**
 * __useCreateUserPasswordMutation__
 *
 * To run a mutation, you first call `useCreateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPasswordMutation, { data, loading, error }] = useCreateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>(CreateUserPasswordDocument, options);
      }
export type CreateUserPasswordMutationHookResult = ReturnType<typeof useCreateUserPasswordMutation>;
export type CreateUserPasswordMutationResult = Apollo.MutationResult<CreateUserPasswordMutation>;
export type CreateUserPasswordMutationOptions = Apollo.BaseMutationOptions<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>;
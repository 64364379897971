import styled from 'styled-components';
import IconBase from '@mdi/react';

export const Container = styled.div`
  width: 100%;
  padding: 16px 24px 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 48px;
  background: #fff;
  box-shadow: 0px 3px 15px #0909090a;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #ececec;
`;

export const Actions = styled.div`
  display: flex;
  margin-left: 25px;
  align-items: center;
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  color: #444444;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  width: 100%;
`;

export const Icon = styled(IconBase)`
  cursor: pointer;
  padding: 5px;
  border-radius: 100%;

  &:hover {
    background: #ececec;
  }
`;

export const IconExpand = styled(IconBase).attrs((props) => ({
  expanded: props.expanded,
}))`
  transform: rotate(${(props) => (props.expanded === 'true' ? 0 : 90)}deg);
  cursor: pointer;
  padding: 5px;
  border-radius: 100%;

  &:hover {
    background: #ececec;
  }
`;

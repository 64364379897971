import styled from 'styled-components';

export const RadioGroupContainer = styled.div``;

export const RadioButtonLabel = styled.label`
  display: inline-flex;
  align-items: center;
  user-select: none;
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 0px;
`;

export const RadioButtonInput = styled.input`
  margin: 0 4px 0 0;
  zoom: 1.4;
  cursor: pointer;
`;

import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Mark,
  Spacer,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { MunaiLogo } from 'theme/icons';
import { Form, FormSupportingText } from 'theme/ui';
import { rules } from 'Utils/Helpers/Validator';
import useLogicLogin from './hooks/useLogicLogin';

// const loginWithEmailMutation = gql`
//   mutation loginWithEmail($input: LoginWithEmailInput!) {
//     loginWithEmail(input: $input) {
//       token
//     }
//   }
// `;

type LoginFormValues = {
  email: string;
  password: string;
};

export const Login = () => {
  const toast = useToast();

  const { onSubmit, error, loading } = useLogicLogin();

  // TODO: Refatorar login
  //
  // const history = useHistory();
  // const [, setToken] = useCache<string>('user-token');
  //
  // const [loginWithEmail] = useMutation(loginWithEmailMutation, {
  //   onCompleted: ({ token }) => {
  //     setToken(token);
  //     history.replace('/');
  //   },
  //   onError: () => {
  //     toast({
  //       status: 'error',
  //       title: 'Credenciais inválidas',
  //       description: 'E-mail ou senha incorretos.',
  //       position: 'top',
  //       isClosable: true,
  //     });
  //   },
  // });
  //
  // const onSubmit = useCallback(({ email, password }: LoginFormValues) => {
  //   loginWithEmail({ variables: { input: { email, password } } });
  // }, []);

  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitting },
  } = useForm<LoginFormValues>({ mode: 'onBlur' });

  useEffect(() => {
    if (error) {
      toast({
        status: 'error',
        title: 'Credenciais inválidas',
        description: 'E-mail ou senha incorretos.',
        position: 'top',
        isClosable: true,
      });
    }
  }, [error]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <VStack alignItems="flex-start" spacing="4" marginBottom="6">
        <MunaiLogo boxSize="10" />
        <Heading size="lg" fontWeight="light">
          Bem-vindo <br />
          ao{' '}
          <Mark color="#A04FC6" padding={0}>
            Digital Care
          </Mark>
        </Heading>
      </VStack>
      <VStack spacing="1" marginBottom="6">
        <FormControl id="email" isInvalid={!!errors.email}>
          <FormLabel>E-mail</FormLabel>
          <Input
            type="email"
            name="email"
            ref={register({
              required: 'O E-mail é obrigatório',
              pattern: { value: rules.email, message: 'Deve ser um e-mail válido' },
            })}
          />
          <FormSupportingText errorText={errors.email?.message} />
        </FormControl>
        <FormControl id="password" isInvalid={!!errors.password}>
          <FormLabel>Senha</FormLabel>
          <Input
            type="password"
            name="password"
            ref={register({ required: 'A senha é obrigatória' })}
          />
          <HStack marginTop="2" align="start">
            <FormSupportingText marginTop="0" errorText={errors.password?.message} />
            <Spacer minWidth="4" />
            <Button as={Link} to={'forgot-password'} variant="link">
              Esqueceu sua senha?
            </Button>
          </HStack>
        </FormControl>
      </VStack>
      <Button width="full" type="submit" isLoading={isSubmitting || loading}>
        Entrar
      </Button>
    </Form>
  );
};

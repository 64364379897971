import * as S from './styles';

type Option = { value: string; key: string; count: number; label: string };

type Props = {
  filterValue: string;
  setFilterValue: (value: string | null) => void;
  optionsFilter: Option[];
};

export const Filter = ({ filterValue, setFilterValue, optionsFilter }: Props) => {
  const handleClickOption = (option) => {
    if (option.value === filterValue) {
      setFilterValue(null);
    } else {
      setFilterValue(option.value);
    }
  };

  return (
    <S.Wrapper>
      {optionsFilter.map((option) => (
        <S.FilterOption
          key={option.key}
          onClick={() => handleClickOption(option)}
          isSelected={option.value === filterValue}
        >
          {option.label}
        </S.FilterOption>
      ))}
    </S.Wrapper>
  );
};

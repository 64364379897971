import { useEffect, useState } from 'react';
import { ApolloProvider, useLazyQuery } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import client from 'Services/api';
import cacheService from 'Services/cache';
import Loader from 'Components/Loader';
import { CHECK_MEMED_TOKEN, VALIDATE_TOKEN, GET_ENTITY_AND_MODULES } from 'repository/queries';
import LocalCache from 'Services/LocalCache';
import { Switch } from 'react-router';
import { PublicRoute } from 'Components/routes';
import routes from 'routes';
import { AppContainer } from 'containers';
import useCache from 'Hooks/useCache';
import ToastContainer from 'Components/ToastContainer';

import moduleHelper from 'Utils/Helpers/moduleHelper';
import { useUnleash } from 'Context/unleashContext';
import { removeMemedScriptConfig } from '../../Utils/Helpers/removeMemedScriptConfig';
import { clearCachedFilters } from 'store/ducks/modules';

const App = () => {
  const [loading, handleLoading] = useState(true);
  const [cacheLoaded, handleCacheLoaded] = useState(false);
  const [, setMemedToken] = useCache('memed_token', null);

  const [, handleError] = useState<string | null>(null);

  const [tmpUser, setTmpUser] = useCache('tmpUser', null);
  const [, setModules] = useCache('modules', null);
  const [, setModuleAccess] = useCache('module_access', 'covid');

  // Ao recarregar a pagina busca as config da entidade

  const onError = (newError) => {
    handleLoading(false);
    setTmpUser(null);

    if (Array.isArray(newError)) {
      return handleError(newError.map((e) => (e?.message ? e.message : e)).join(', '));
    }

    handleError(newError.message);
  };

  const onCompletedEntity = ({ entity: { entitiesModules, ...entity } }) => {
    const tmpModules = entitiesModules
      ?.map((module) => ({
        ...module,
        serviceStep: JSON.parse(module?.serviceStep),
      }))
      .sort(moduleHelper.sort);
    const moduleAccess = moduleHelper.getDefault(tmpModules);

    setModules(tmpModules);
    setModuleAccess(moduleAccess);
  };

  const [fetchEntity] = useLazyQuery(GET_ENTITY_AND_MODULES, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: onCompletedEntity,
  });

  const [checkMemedToken] = useLazyQuery(CHECK_MEMED_TOKEN, {
    fetchPolicy: 'no-cache',
    onError: () => {
      console.error('Fail on check memed token');
      setTimeout(() => {
        handleLoading(false);
      }, 500);
    },
    onCompleted: ({ checkMemedToken }) => {
      setMemedToken(checkMemedToken);

      setTimeout(() => {
        handleLoading(false);
      }, 1000);
    },
  });

  const [validateToken] = useLazyQuery(VALIDATE_TOKEN, {
    onCompleted: () => {
      const telemedicineData = LocalCache.getModuleData('telemedicine');
      if (Object.keys(telemedicineData).length > 0) {
        checkMemedToken({
          variables: {
            userId: LocalCache?.getUser()?.userId.toString(),
            memedId: telemedicineData?.cpf,
          },
        });
      } else {
        setTimeout(() => {
          handleLoading(false);
        }, 1000);
      }
    },
    onError: () => {
      clearCachedFilters();
      LocalCache.clearCache();
      removeMemedScriptConfig();
      handleLoading(false);
    },
  });

  useEffect(() => {
    const restoreCache = async () => {
      await cacheService.restore();
      handleCacheLoaded(cacheService.isRehydrated());
    };

    restoreCache();
  }, [handleCacheLoaded]);

  useEffect(() => {
    if (cacheLoaded) {
      // Check if new structure entity
      if (!LocalCache.getEntity()) {
        LocalCache.deleteUser();
        LocalCache.deleteMemedToken();
        removeMemedScriptConfig();
        handleLoading(false);
      } else {
        if (LocalCache.getUser()) {
          validateToken();
          fetchEntity({
            variables: {
              entityId: LocalCache.getUser()?.entityId?.[0],
            },
          });
        } else {
          handleLoading(false);
        }
      }
    }
  }, [cacheLoaded, validateToken, handleLoading, fetchEntity, tmpUser]);

  const { updateUnleashUser } = useUnleash();

  useEffect(() => {
    const cachedUser = LocalCache.getUser();

    if (cacheLoaded) {
      updateUnleashUser(cachedUser.entityId?.[0]);
    }
  }, [updateUnleashUser, cacheLoaded]);

  return loading ? (
    <Loader />
  ) : (
    <BrowserRouter>
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        limit={1}
        hideProgressBar
        transition={Slide}
        autoClose={5000}
        closeOnClick
        rtl={false}
      />
      <Switch>
        <PublicRoute {...routes.LOGIN} />
        <AppContainer />
      </Switch>
    </BrowserRouter>
  );
};

const AppRoot = () => {
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

export default Sentry.withProfiler(AppRoot);

import { useState, useEffect, forwardRef } from 'react';
import { validationClass } from '../../Utils/Helpers/Validator';

export default function Select({ register, options, label, error, initial = '', ...props }) {
  const [isSelected, setIsSelected] = useState(false);
  const [selectValue, setSelectValue] = useState(initial);

  useEffect(() => {
    setIsSelected(selectValue !== '');
  }, [selectValue]);

  return (
    <div className="form-group">
      <select
        className={validationClass(
          `form-control select-minimal ${isSelected ? 'selected' : ''}`,
          error
        )}
        onChange={(e) => setSelectValue(e.target.value)}
        ref={register}
        id={props.id}
        value={selectValue}
        {...props}
      >
        {options.map((value) => (
          <option key={value.id} value={value.id} selected={value.id === initial}>
            {value.name}
          </option>
        ))}
      </select>
      <label htmlFor={props.id}>{label}</label>
      {error && (
        <label className="error">{props.errormessage[error.type] || props.errormessage}</label>
      )}
    </div>
  );
}

export const UncontrolledSelect = forwardRef(
  ({ id, name, label, initial, error, errormessage, options, isSelected, ...props }, ref) => {
    return (
      <div className="form-group">
        <select
          className={validationClass(
            `form-control select-minimal${isSelected ? ' selected' : ''}`,
            error
          )}
          id={id}
          name={name}
          placeholder=" "
          ref={ref}
          {...props}
        >
          {options?.map((value) => (
            <option key={value.id} value={value.id}>
              {value.name}
            </option>
          ))}
        </select>
        <label htmlFor={id}>{label}</label>
        {error && <label className="error">{errormessage[error.type] || errormessage}</label>}
      </div>
    );
  }
);

UncontrolledSelect.displayName = 'UncontrolledSelect';

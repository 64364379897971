import { gql } from '@apollo/client';

export const GET_POWER_BI_INFOS = gql`
  query ($entityId: Int!) {
    getPowerBiInfos(entityId: $entityId) {
      iframe
      module
      slug
      title
    }
  }
`;

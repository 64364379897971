const filterTranslation = {
  status: {
    LOW_INTERACTION: 'Baixa interação',
    IMPROVEMENT: 'Melhora',
    WORSENING: 'Piora',
    BLOCKED: 'Bloqueio',
  },
};

export default function useFilterTranslation() {
  const getFilterTranslation = (filterKey, filterValue) => {
    return filterTranslation[filterKey]
      ? filterTranslation[filterKey][filterValue] || filterValue
      : filterValue;
  };

  return { getFilterTranslation };
}

import Styled from '../Datatable/styled';
import { useDispatch, useSelector } from 'react-redux';
import { filterLabel } from './FiltersList';
import { ModulesActions } from 'store/ducks/modules';
import useFilterTranslation from 'Hooks/useFilterTranslation';

export const SelectedFiltersList = () => {
  const filters = useSelector(({ modules }) => modules.filters);
  const dispatch = useDispatch();
  const { getFilterTranslation } = useFilterTranslation();

  const hasFilters = Boolean(
    Object.keys(filters).filter(
      (filterKey) =>
        Boolean(filters[filterKey]?.selected?.length) || filters[filterKey]?.active !== null
    ).length
  );

  if (!hasFilters) {
    return null;
  }

  const removeFilter = (type, name) => {
    dispatch(ModulesActions.modulesRemoveFilter(type, name));
  };

  const resetFilterActive = (type) => {
    dispatch(ModulesActions.modulesResetFilterActive(type));
  };

  return (
    <Styled.FilterSelectedListContainer>
      <Styled.FilterSelectedListTitle>Filtros selecionados:</Styled.FilterSelectedListTitle>
      {Object.keys(filters).map((filterKey, i) => (
        <>
          {filters[filterKey]?.selected?.map((filterItem, j) => (
            <Styled.FilterSelectedItem key={`${i}-${j}`}>
              <div>
                <b>{filterLabel[filterKey]}</b>:{' '}
                {filters[filterKey]?.translate
                  ? getFilterTranslation(filterKey, filterItem)
                  : filterItem}
              </div>
              <Styled.FilterRemoveIcon onClick={() => removeFilter(filterKey, filterItem)}>
                &times;
              </Styled.FilterRemoveIcon>
            </Styled.FilterSelectedItem>
          ))}
          {filters[filterKey]?.active !== null && (
            <Styled.FilterSelectedItem key={i}>
              <div>
                <b>
                  {filters[filterKey]?.active === false ? 'Não contém' : 'Contém'}&nbsp;
                  {filterLabel[filterKey].toLowerCase()}
                </b>
              </div>
              <Styled.FilterRemoveIcon onClick={() => resetFilterActive(filterKey)}>
                &times;
              </Styled.FilterRemoveIcon>
            </Styled.FilterSelectedItem>
          )}
        </>
      ))}
    </Styled.FilterSelectedListContainer>
  );
};

import * as Styled from './styled';

type OwnProps = {
  height?: number;
  spacing?: number;
  children?: React.ReactElement[] | React.ReactElement;
};

type Props = OwnProps & typeof Divider.defaultProps;

const Divider = ({ height, spacing, children }: Props) => (
  <Styled.Wrapper spacing={spacing}>
    <Styled.Line height={height} />
    {children && <Styled.Text>{children}</Styled.Text>}
    <Styled.Line height={height} />
  </Styled.Wrapper>
);

Divider.defaultProps = {
  height: 1,
  spacing: 5,
  children: null,
};

export default Divider;

import { useState } from 'react';
import * as Sentry from '@sentry/react';

import cacheService from 'Services/cache';

const useCache = <TValue = any>(
  key: string,
  initialValue?: TValue
): [TValue, (value: TValue) => void] => {
  const [storedValue, setStoredValue] = useState<TValue>(() => {
    try {
      const item = cacheService.get(key);
      return item ? item : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: TValue) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      cacheService.set(key, valueToStore);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export default useCache;

export const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
};

export const springSlide = {
  type: 'spring',
  stiffness: 900,
  damping: 100,
  velocity: 70,
};

import { useContext } from 'react';

import ChatContext from 'Components/Chat/context';
import Tooltip from 'Components/Tooltip';
import { springSlide } from 'Utils/animations';

import * as Styled from './styled';

const TimelineButton = () => {
  const { onClickTimeline, minimized, original } = useContext(ChatContext);

  return (
    <Tooltip text="Ver timeline">
      <Styled.Container
        onClick={() => onClickTimeline(original?.medicalRecord)}
        // layout
        transition={springSlide}
        hide={!Boolean(onClickTimeline) || minimized}
      >
        <Styled.Text>Timeline</Styled.Text>
      </Styled.Container>
    </Tooltip>
  );
};

export default TimelineButton;

import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';

import { useChat } from 'Components/Chat/hooks/useChat';
import DataContext from 'Context/dataContext';
import ModuleContext from 'Context/moduleContext';
import { useFlag } from 'Context/unleashContext';
import useCache from 'Hooks/useCache';
import useDocumentFilters from 'Hooks/useDocumentsFilter';
import DashboardModel, { SUBSCRIBE_DATATABLE_DOCUMENTS } from 'Models/DashboardModel';
import LocalCache from 'Services/LocalCache';
import Subscriptions from 'Subscriptions';
import { getEntityId } from 'Utils/Config/SectorConfig';
import environment from 'Utils/Dictionary/environment';
import { actualStatusText } from '../../Utils/Helpers/StatusHelper';
import { Document } from '../../data/types';

export const Wrapper = ({ children, sector }) => {
  const { stepActive, moduleAccess, searchKeyword } = useContext(ModuleContext);
  const [userPageSize] = useCache('user_page_size', 6);
  const [orderBy, handleOrderBy] = useCache('sorted_column', null);
  const [sort, handleSort] = useCache('sorted_column_order', 0);
  const [page, handlePage] = useState(1);
  const [limit, handleLimit] = useState(userPageSize);
  const [doctorInRoom, handleDoctorInRoom] = useState(false);
  const [totalDoctorsOnline, handleTotalDoctorsOnline] = useState(0);
  const [hideColumn, handleHideColumn] = useState(false);
  const isDocumentWithHandover = useFlag(
    `lc-display-unread-messages-${environment.UNLEASH_APP_ENV}`
  );

  const filterFlag = useFlag(`dc-new-filter-props-${environment.UNLEASH_APP_ENV}`);

  const { updateOriginalDataChat } = useChat();
  const { cpf } = LocalCache.getModuleData('telemedicine');

  const [changeDoctorStatus] = useMutation(DashboardModel.CHANGE_DOCTOR_STATUS);

  const onCompletedRoomData = ({ getRoomData: { availableDoctors } }) => {
    handleDoctorInRoom(Boolean(availableDoctors?.find((doctorId) => doctorId === cpf)));
    handleTotalDoctorsOnline(availableDoctors?.length);
  };

  const [fetchRoomData] = useLazyQuery(DashboardModel.GET_ROOM_DATA(getEntityId()), {
    fetchPolicy: 'no-cache',
    onCompleted: onCompletedRoomData,
  });

  const filters = useDocumentFilters();

  const [fetchAllDocuments, { subscribeToMore, data, loading, refetch }] = useLazyQuery(
    DashboardModel.DOCUMENTS_BY_PAGE(isDocumentWithHandover),
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        entityId: getEntityId(),
        serviceStep: stepActive,
        module: moduleAccess,
        page,
        limit,
        orderBy: hideColumn === false ? orderBy : null,
        sort: hideColumn === false ? sort : 1,
        sector,
        search: searchKeyword,
        filter: filters,
      },
    }
  );

  const userEmail = LocalCache.getUser()?.email;

  const statusHashMap = {
    melhora: 'IMPROVEMENT',
    piora: 'WORSENING',
    bloqueio: 'BLOCKED',
    'baixa interação': 'LOW_INTERACTION',
    'em monitoramento': 'IN_MONITORING',
    convidado: 'WAITING_TO_ACCEPT',
    movido: 'MOVED',
    bloqueado: 'PAUSED',
  };

  const alertHashMap = {
    cinza: 'grey',
    vermelho: 'red',
    amarelo: 'yellow',
    verde: 'green',
    azul: 'blue',
  };

  const isDocumentValidForFilter = useCallback(
    (document: Document): boolean => {
      if (!filterFlag) {
        return true;
      }
      if (filters) {
        if (filters?.tags?.active) {
          if (!document.tags || document.tags.length === 0) {
            return false;
          }

          const tags = filters?.tags?.options;

          const hasSelectedTags = document.tags.some((tag) => tags.includes(tag as string));

          if (tags.length && !hasSelectedTags) return false;
        }

        if (filters?.tags?.active === false) {
          if (document.tags && document.tags.length > 0) {
            return false;
          }
        }

        if (filters?.evolution?.active) {
          if (
            (!document.evolutionNote || Object.keys(document.evolutionNote).length === 0) &&
            (!document.evolutionSoap || Object.keys(document.evolutionSoap).length === 0)
          ) {
            return false;
          }
        }

        if (filters?.evolution?.active === false) {
          if (
            (document.evolutionNote && Object.keys(document.evolutionNote).length > 0) ||
            (document.evolutionSoap && Object.keys(document.evolutionSoap).length > 0)
          ) {
            return false;
          }
        }

        if (filters?.healthCare?.active) {
          if (!document.patient?.healthCare) {
            return false;
          }

          const healthCares = filters?.healthCare?.options;

          const hasSelectedHealthCare = healthCares.includes(document.patient.healthCare);

          if (healthCares.length && !hasSelectedHealthCare) return false;
        }

        if (filters?.healthCare?.active === false) {
          if (document.patient?.healthCare) {
            return false;
          }
        }

        if (filters?.status?.active) {
          const actualStatus = actualStatusText(document).toLowerCase();

          if (!actualStatus || !statusHashMap[actualStatus]) {
            return false;
          }

          if (
            filters.status.options &&
            filters.status.options.length &&
            !filters.status.options.includes(statusHashMap[actualStatus])
          ) {
            return false;
          }
        }

        if (filters?.status?.active === false) {
          const actualStatus = actualStatusText(document);

          if (actualStatus) {
            return false;
          }
        }

        if (filters?.flow?.active) {
          if (!document.moduleParams?.flow) {
            return false;
          }

          const flows = filters?.flow?.options;

          const hasSelectedFlow = flows.includes(document.moduleParams?.flow);

          if (flows.length && !hasSelectedFlow) return false;
        }

        if (filters?.flow?.active === false) {
          if (document.moduleParams?.flow) {
            return false;
          }
        }

        if (filters?.hasUnreadChat?.active) {
          if (
            document.chat?.messagesUnreaded === 0 ||
            document.handover?.reason !== 'REQUESTED_TEAM_SUPPORT' ||
            document.chat?.responsibleEmail !== userEmail
          ) {
            return false;
          }
        }

        if (filters?.hasUnreadChat?.active === false) {
          if (document?.chat?.messagesUnreaded && document.chat.messagesUnreaded > 0) {
            return false;
          }
        }
      }

      if (searchKeyword) {
        // if none pass return false
        const search = (searchKeyword as string).toLowerCase();

        const isNameOnSearch = document.patient?.nome?.toLowerCase().includes(search);
        const isPhoneOnSearch =
          document.patient?.telefone?.includes(search) ||
          document.chat?.senderId?.toString().includes(search);

        const isCpfOnSearch =
          document.patient?.cpf?.includes(search) ||
          document.medicalRecord?.toString().includes(search);

        const isUbsOnSearch = document.patient?.ubs?.toLowerCase().includes(search);

        const isTagsOnSearch = document.tags?.some(
          (tag) => tag && tag.toLowerCase().includes(search)
        );

        const isFlowOnSearch = document.moduleParams?.flow?.toLowerCase().includes(search);

        const isStatusOnSearch = actualStatusText(document)?.toLowerCase().includes(search);

        const isComplaintOnSearch = document.complaint?.toLowerCase().includes(search);

        const isTriageOnSearch = document.triage?.toLowerCase().includes(search);

        const isAlertResultOnSearch = document.alert?.resultado
          ?.toLowerCase()
          .includes(alertHashMap[search]);

        if (
          !isNameOnSearch &&
          !isPhoneOnSearch &&
          !isCpfOnSearch &&
          !isUbsOnSearch &&
          !isTagsOnSearch &&
          !isStatusOnSearch &&
          !isComplaintOnSearch &&
          !isTriageOnSearch &&
          !isAlertResultOnSearch &&
          !isFlowOnSearch
        ) {
          return false;
        }
      }

      return true;
    },
    [filters, searchKeyword, userEmail, filterFlag]
  );

  const subscribe = useCallback(() => {
    return typeof subscribeToMore === 'function'
      ? subscribeToMore({
          document: SUBSCRIBE_DATATABLE_DOCUMENTS(filterFlag),
          variables: { entityId: getEntityId()[0], module: moduleAccess },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;
            updateOriginalDataChat(subscriptionData.data.datatableDocuments);
            let inserted = false;
            const newData = prev?.documentsByPage?.records.map((document) => {
              if (
                document.medicalRecord === subscriptionData.data.datatableDocuments.medicalRecord
              ) {
                inserted = true;
                return subscriptionData.data.datatableDocuments;
              } else {
                return document;
              }
            });
            if (
              !inserted &&
              subscriptionData.data.datatableDocuments?.evolutionRecord?.serviceStep ===
                stepActive &&
              isDocumentValidForFilter(subscriptionData.data.datatableDocuments)
            ) {
              newData.push(subscriptionData.data.datatableDocuments);
            }

            return { ...prev, documentsByPage: { ...prev?.documentsByPage, records: newData } };
          },
        })
      : undefined;
  }, [subscribeToMore, stepActive, updateOriginalDataChat, moduleAccess, filters, searchKeyword]);

  const internalFetchData = useCallback(() => {
    fetchAllDocuments();
  }, [fetchAllDocuments]);

  useEffect(() => {
    if (!data) {
      fetchAllDocuments();
    }
  }, [data]);

  useEffect(() => {
    internalFetchData();
  }, [internalFetchData]);

  useEffect(() => {
    const currentSubscribe = subscribe();

    return () => {
      if (currentSubscribe) {
        currentSubscribe();
      }
    };
  }, [subscribe]);

  useEffect(() => {
    if (stepActive === 'telemedicine') {
      fetchRoomData();
    }
  }, [stepActive, fetchRoomData]);

  const toogleDoctorStatus = async () => {
    await changeDoctorStatus({
      variables: {
        entityId: getEntityId()[0],
        doctorId: cpf,
        status: !doctorInRoom,
      },
    });

    setTimeout(() => {
      fetchRoomData();
    }, 1000);
  };

  const isHideColumn = (newSortBy) => {
    if (stepActive !== 'telemedicine' && newSortBy[0]?.id === 'scheduling') {
      return true;
    } else
      return (
        stepActive === 'eligible' &&
        (newSortBy[0]?.id === 'dateLastInteraction' || newSortBy[0]?.id === 'alertResult')
      );
  };

  const fetchData = ({ page: newPage, limit: newLimit, sortBy: newSortBy }) => {
    handlePage(newPage + 1);
    handleLimit(newLimit);

    handleHideColumn(isHideColumn(newSortBy));

    if (newSortBy?.length) {
      handleOrderBy(
        ['alert.dataAlerta', 'monitoring'].includes(newSortBy[0].id)
          ? 'dataAlerta'
          : newSortBy[0].id
      );
      handleSort(newSortBy[0].desc ? -1 : 1);
    } else {
      handleOrderBy(null);
      handleSort(1);
    }
  };

  return (
    <DataContext.Provider
      value={{
        data,
        loading,
        fetchData,
        toogleDoctorStatus,
        doctorInRoom,
        totalDoctorsOnline,
        refetch,
        sector,
      }}
    >
      {children}
      <Subscriptions />
    </DataContext.Provider>
  );
};

export default Wrapper;

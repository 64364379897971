import { useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiClose, mdiCheckDecagram, mdiAlertOctagon } from '@mdi/js';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { modifierConfig } from '../../Utils/Config/modifierConfig';

const NotifyWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 33px;
  top: 11px;
  text-align: center;
  z-index: 1111;
`;

const NotifyContainer = styled.div`
  position: relative;
  padding: 6px 53px 6px 31px;
  background: #fff;
  z-index: 110;
  text-align: center;
  font-size: 0.812rem;
  display: inline-block;
  border-radius: 5px;
  ${applyStyleModifiers(modifierConfig)};
`;

const Close = styled.span`
  position: absolute;
  right: 11px;
  top: 9px;
  cursor: pointer;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  ${applyStyleModifiers(modifierConfig)};
`;

const NotifyWrapperClose = (props) => (
  <Close {...props}>
    <Icon path={mdiClose} color="#E3E3E3" size={0.7} />
  </Close>
);

const NotifyWrapperSuccess = ({ children, ...props }) => (
  <Text {...props} modifiers={['textSuccess']}>
    <Icon path={mdiCheckDecagram} color="#29B050" size={0.9} style={{ marginRight: '5px' }} />
    {children}
  </Text>
);

const NotifyWrapperError = ({ children, ...props }) => (
  <Text {...props} modifiers={['textDanger']}>
    <Icon path={mdiAlertOctagon} color="#EC2C47" size={0.9} style={{ marginRight: '5px' }} />
    {children}
  </Text>
);

export default function Notify({ showMessage, setShowMessage }) {
  useEffect(() => {
    let timeout: NodeJS.Timer;

    // Show loader a bits longer to avoid loading flash
    if (showMessage.show) {
      timeout = setTimeout(() => {
        setShowMessage({
          ...showMessage,
          show: false,
        });
      }, 6000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showMessage, setShowMessage]);

  return showMessage.show ? (
    <NotifyWrapper>
      <NotifyContainer
        className="fade-in"
        modifiers={[showMessage.type === 'success' ? 'borderSuccess' : 'borderDanger']}
      >
        const{' '}
        <NotifyWrapperClose
          onClick={() => {
            setShowMessage({
              ...showMessage,
              show: false,
            });
          }}
        />
        {showMessage.type === 'success' ? (
          <NotifyWrapperSuccess>{showMessage.content}</NotifyWrapperSuccess>
        ) : (
          []
        )}
        {showMessage.type === 'error' ? (
          <NotifyWrapperError>{showMessage.content}</NotifyWrapperError>
        ) : (
          []
        )}
      </NotifyContainer>
    </NotifyWrapper>
  ) : (
    []
  );
}

export function NotifyV2({ showMessage }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Show loader a bits longer to avoid loading flash
    if (showMessage) {
      setShow(true);
      const timeout = setTimeout(() => {
        setShow(false);
      }, 6000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showMessage]);

  if (!show) return <Fragment />;

  return (
    <NotifyWrapper>
      <NotifyContainer
        className="fade-in"
        modifiers={[showMessage.type === 'success' ? 'borderSuccess' : 'borderDanger']}
      >
        const <NotifyWrapperClose onClick={() => setShow(false)} />
        {showMessage.type === 'success' ? (
          <NotifyWrapperSuccess>{showMessage.content}</NotifyWrapperSuccess>
        ) : (
          []
        )}
        {showMessage.type === 'error' ? (
          <NotifyWrapperError>{showMessage.content}</NotifyWrapperError>
        ) : (
          []
        )}
      </NotifyContainer>
    </NotifyWrapper>
  );
}

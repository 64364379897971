import * as Styled from './styled';

type OwnProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  speedMultiplier?: number;
  total?: number;
};

type Props = OwnProps & typeof Spinner.defaultProps;

const Spinner = ({ size, color, total, speedMultiplier }: Props) => (
  <Styled.Container>
    {Array.from(Array(total).keys()).map((index) => (
      <Styled.Spinner
        key={index}
        index={index}
        size={Styled.sizes[size]?.size}
        margin={Styled.sizes[size]?.margin}
        speedMultiplier={speedMultiplier}
        color={color}
      />
    ))}
  </Styled.Container>
);

Spinner.defaultProps = {
  size: 'md',
  color: '#0071eb',
  speedMultiplier: 1,
  total: 3,
};

export default Spinner;

import { gql } from '@apollo/client';

const document = (filterFlag?: boolean) => `
process
tags
tagsIntegration
complaint
optin
triage
dateSymptomsStart
entityId,
medicalRecord,
dataEntrada,
dateLastInteraction
evolutionRecord {
  atendimentoIdHospital
  motivo
  observacao
  usuario
  email
  setor
  serviceStep
  url
  dataColeta
}
alert{
  dataAlerta
  resultado
  houveMelhora
}
symptoms {
  grey{
    questionId
    question
    type
    values
    tag
  }
  blue{
    questionId
    question
    type
    values
    tag
  }
  green{
    questionId
    question
    type
    values
    tag
  }
  yellow{
    questionId
    question
    type
    values
    tag
  }
  red{
    questionId
    question
    type
    values
    tag
  }
  custom{
    questionId
    question
    type
    values
    tag
  }
}
monitoring {
  dataPrimeiroEnvio
  dataUltimoEnvio
  canal
  status
  nEnvios
  type
  lowInteraction
}
patient {
  nome
  bairro
  cpf
  rgh
  telefone
  estado
  age
  weight
  height
  ubs
  healthCare
  imc
  dataNascimento
  email
}
evolutionNote {
  observacao
  usuario
  email
}
hospitalConfirmation{
  dataColeta
  confirmado
}
hospitalCheckout {
  dataColeta
  confirmado
  data
  hora
}
moduleParams {
  flow
}
chat {
  senderId
  channel
  whatsappStatus
  needActiveContact
  templateCount
  messagesUnreaded
  responsibleEmail
  openWindow
  dateFirstMessage
  dateLastMessage
  dateWhatsappStatus
  templateSended
  dateActiveContact
  activeContactStatus
}
handover {
  status
  reason
}
serviceSteps {
  telemedicine {
    meetRoomDoctor
    meetRoomDateStarted
    tokenDateSent
    token
    waiting
    evolutionNote{
      crm
      dataPrescricao
      emailMedico
      medico
      observacao
      usuarioMedico
    }
    scheduling {
      dateRequest
      slotInitialTime
      slotEndTime
      user
      email
      type
      nWhatsappTemplatesSent
    }
    monitoring {
      canal
      dataPrimeiroEnvio
      dataUltimoEnvio
      dataWhatsappStatus
      nEnvios
      status
      whatsappStatus
    }
  }
}
${
  filterFlag
    ? `
  evolutionSoap {
    uuid
    soap {
      subjective
      objective
      assessment
      plan
    }
    cid
    ciap
    dateInserted
    user
    email
    attendanceType
    attendantCpf
    numConsRegistroProfissional
    ufConsProfissional
    numeroCPFProfissional
    nomeProfissionalSolicitante
    sgOrgaoProfissional
  }`
    : ''
}
`;

const getRemoteAtendimentoDocuments = `
  senderId
  clients {
    cpf
    name
    email
    phone
    zip
    plate
    vehicle
    channel
    intent
    createdAt
  }
  channels {
    whatsapp {
      conversation {
        senderId
        latestEventTime
      }
    }
  }
  evolutionRecord {
    atendimentoId
    dataColeta
    email
    url
    usuario
    setor
    color
  }
  medicalRecord{
    atendimentoId
    updateAt
  }
  alert {
    atendimentoId
    dataAlerta
    resultado
    score
    houveMelhora
  }
`;

export const SUBSCRIBE_REMOTE_ATENDIMENTO_SECTOR = gql`
  subscription($entityId: Int!) {
    remoteAtendimentoSector(entityId: $entityId) {
      ${getRemoteAtendimentoDocuments}
    }
  }
`;

export const SUBSCRIBE_DATATABLE_DOCUMENTS = (filterFlag?: boolean) => gql`
subscription($entityId: Int!, $module: String!) {
  datatableDocuments(entityId: $entityId, module: $module) {
    ${document(filterFlag)}
  }
}
`;

const subscriptionRemoteAtendimentoDocuments = (entityId) => `
subscriptionRA(entityId: ${entityId} )
`;

const getDocumentsAll = (entityId, serviceStep, moduleAccess, filterFlag?: boolean) => `
documents(entityId: ${entityId} ${
  serviceStep ? `, serviceStep: "${serviceStep}"` : ''
}, module:"${moduleAccess}") {
  ${document(filterFlag)}
  }
`;

const getAll = (entityId, module = 'covid') => `
    collections(entityId: ${entityId}, module: "${module}")
    {
      monitoring {
        dataPrimeiroEnvio
        dataUltimoEnvio
        canal
        status
        nEnvios
      }
      entityId,
      medicalRecord,
      dataEntrada,
      patient {
        nome
        bairro
        cpf
        rgh
        telefone
        estado
        weight
        height
        ubs
      }
      vitalSigns {
        contatoCorona {
          name
          value
        }
        febre38 {
          name
          value
        }
        febre3Dias {
          name
          value
        }
        fisicoCorona {
          name
          value
        }
        grupos {
          name
          value
        }
        moraCorona {
          name
          value
        }
        proximoCorona {
          name
          value
        }
        sintomas {
          name
          value
        }
        questao0 {
          name
          value
        }
        questao1 {
          name
          value
        }
        questao21 {
          name
          value
        }
        questao22 {
          name
          value
        }
        questao3 {
          name
          value
        }
        dataInicioSintomas {
          name
          value
        }
      }
      alert {
        houveMelhora
        dataAlerta
        resultado
        anomalias {
          questao0
          questao1
          questao2
          questao3
          sintomas
          epidemiologia
          comorbidade
          gravidade
        }
      }
      evolutionRecord {
        atendimentoIdHospital
        motivo
        observacao
        usuario
        email
        setor
      }
      evolutionNote {
        observacao
        usuario
        email
      }
      hospitalConfirmation{
        dataColeta
        confirmado
      }
      hospitalCheckout {
        dataColeta
        confirmado
        data
        hora
      }
      tags
    }
`;

const DashboardModel = {
  TELEATENDIMENTO_CONTACT_ACTIVE: gql`
    mutation remoteAtendimentoEvolutionRecord(
      $entityId: Int!
      $sector: String!
      $atendimentoId: String!
      $motivo: String
      $usuario: String
      $email: String
      $name: String
      $module: String!
    ) {
      remoteAtendimentoEvolutionRecord(
        entityId: $entityId
        sector: $sector
        atendimentoId: $atendimentoId
        motivo: $motivo
        usuario: $usuario
        email: $email
        module: $module
      )
      teleAtendimentoContactActive(
        module: $module
        entityId: $entityId
        atendimentoId: $atendimentoId
        name: $name
      )
    }
  `,
  GET_DOCUMENTS_RA_COUNT: (entityId, moduleAccess, emailUser) => gql`
    query documentsRACount {
      documentsRACount(
        entityId: ${entityId},
        module: "${moduleAccess}",
        ${emailUser ? `, emailUser: "${emailUser}"` : ''}){
          ALL
          AWAITING
          HUMAN
          CLOSED
          HISTORY
          IMPORT
      }
    }
  `,
  GET_DOCUMENTS_ALL: (entityId, serviceStep, moduleAccess, filterFlag?: boolean) => gql`
    query documents {
      ${getDocumentsAll(entityId, serviceStep, moduleAccess, filterFlag)}
    }
    `,
  DOCUMENTS_BY_PAGE: (isDocumentWithHandover, filterFlag?: boolean) => gql`
    query documentsByPage (
      $module: String!
      $entityId: [Int!]!
      $serviceStep: String
      $page: Int
      $limit: Int
      $search: String
      $orderBy: String
      $sort: Int
      $sector: String
      $filter: DocumentsFilterInput
    ) ${isDocumentWithHandover ? '@live' : ''} {
      documentsByPage (
        module: $module
        entityId: $entityId
        serviceStep: $serviceStep
        page: $page
        limit: $limit
        search: $search
        orderBy: $orderBy
        sort: $sort
        sector: $sector
        filter: $filter
      ) {
        totalPages
        totalDocuments
        records {
          ${document(filterFlag)}
        }
      }
    }
  `,
  GET_DOCUMENT: gql`
    query ($module: String!, $medicalRecord: String!, $entityId: [Int!]!) {
      document(module: $module, medicalRecord: $medicalRecord, entityId: $entityId) {
        entityId
        medicalRecord
        dateLastInteraction
        patient {
          nome
          bairro
          cidade
          cpf
          healthCare
          healthCard
          rgh
          telefone
          estado
          age
          weight
          height
          ubs
          email
          bairro
          gender
          dataNascimento
          bond
          cep
        }
        origin {
          entrada
          usuario
          email
        }
        tags
        alert {
          dataAlerta
        }
        symptoms {
          grey {
            values
            epidemiologyVariables {
              variableKey
            }
          }
        }
        dateSymptomsStart
        optin
        monitoring {
          type
          nEnvios
          status
          dataPrimeiroEnvio
          dataUltimoEnvio
          statusDescription
          complaint
        }
        chat {
          senderId
          channel
          whatsappStatus
          needActiveContact
          templateCount
          messagesUnreaded
          responsibleEmail
          openWindow
          dateFirstMessage
          dateLastMessage
          dateWhatsappStatus
          dateActiveContact
          templateSended
        }
        serviceSteps {
          telemedicine {
            meetRoomDoctor
            meetRoomDateStarted
            tokenDateSent
            token
            waiting
            evolutionNote {
              crm
              dataPrescricao
              emailMedico
              medico
              observacao
              usuarioMedico
            }
            scheduling {
              dateRequest
              slotInitialTime
              slotEndTime
              user
              email
              type
              nWhatsappTemplatesSent
            }
            monitoring {
              canal
              dataPrimeiroEnvio
              dataUltimoEnvio
              dataWhatsappStatus
              nEnvios
              status
              whatsappStatus
              complaint
            }
          }
        }
        evolutionRecord {
          atendimentoIdHospital
          motivo
          observacao
          usuario
          email
          setor
          serviceStep
          url
          dataColeta
        }
      }
    }
  `,
  GET_REMOTE_ATENDIMENTO: gql`
    query documentsRA (
      $entityId: [Int!]!
      $module: String!
      $sector: [String]
      $emailMenu: String
      $emailUser: String
      $page: Int
      $limit: Int
      $search: String
    ) {
      documentsRA (
        entityId: $entityId
        module: $module
        sector: $sector
        emailMenu: $emailMenu
        emailUser: $emailUser
        page: $page
        limit: $limit
        search: $search
      ){
        totalPages
        totalDocuments
        records {
          ${getRemoteAtendimentoDocuments}
        }
      }
      documentsRACount(
        entityId: $entityId,
        module: $module,
        emailMenu: $emailMenu){
          ALL
          AWAITING
          HUMAN
          CLOSED
          HISTORY
          OUTSIDE
          CONTACT_ACTIVE
          IMPORT
      }
    }
  `,
  MUTATION_R_A_IMPORT: gql`
    mutation addDocumentsRA(
      $module: String!
      $entityId: Int!
      $email: String!
      $atendimentoId: [addDocumentsRAInput]
    ) {
      addDocumentsRA(
        module: $module
        entityId: $entityId
        email: $email
        atendimentoId: $atendimentoId
      )
    }
  `,
  GET_ROOM_DATA: (entityId) => gql`
query getRoomData {
  getRoomData(entityId: ${entityId}){
    participants
    roomLimit
    patientsTokens
    availableDoctors
    endService
    startService
    active
  }
}
`,
  SUB_REMOTE_ATENDIMENTO: (entityId) => gql`
query subscriptionRA {
  ${subscriptionRemoteAtendimentoDocuments(entityId)}
}
`,
  GET_DOCUMENT_HISTORY: (medicalRecord, entityId, moduleAccess) => gql`
query history {
  userHealthStatusWhatsapp(medicalRecord: "${medicalRecord}", entityId: ${entityId} module: "${moduleAccess}") {
    id
    entityId
    healthStatus
    symptom
    date
  },
  history(medicalRecord: "${medicalRecord}", entityId: ${entityId}, module: "${moduleAccess}") {
    date,
    evolutionSoap {
      attendanceType
      soap {
        subjective
        objective
        assessment
        plan
      }
      cid
      ciap
      user
    }
    evolutionRecord {
      atendimentoIdHospital
      motivo
      observacao
      usuario
      email
      setor
    }
    alert{
      dataAlerta
      resultado
      houveMelhora
    }
    vitalSigns{
      type
      nRespostas
      complaint
    }
    symptoms{
      grey{
        questionId
        question
        type
        values
        tag
      }
      blue{
        questionId
        question
        type
        values
        tag
      }
      green{
        questionId
        question
        type
        values
        tag
      }
      yellow{
        questionId
        question
        type
        values
        tag
      }
      red{
        questionId
        question
        type
        values
        tag
      }
      custom{
        questionId
        question
        type
        values
        tag
      }
    }
    monitoring{
      nEnvios
      status
      template
      statusDescription
      type
      dataPrimeiroEnvio
      dateInterruptedStatus
      interruptedModule
      complaint
    }
    evolutionNote {
      attendanceType
      observacao
      usuario
      email
    }
    hospitalConfirmation{
      dataColeta
      confirmado
    }
    hospitalCheckout {
      dataColeta
      confirmado
      data
      hora
    }
    tags {
      added
      removed
    }
    paciente {
      nome
      dataNascimento
      telefone
      email
    }
  }
}
`,
  GET_TIMELINE: (medicalRecord, entityId, moduleAccess) => gql`
query timeline {
  userHealthStatusWhatsapp(medicalRecord: "${medicalRecord}", entityId: ${entityId}, module: ${moduleAccess}) {
    id
    entityId
    healthStatus
    symptom
    date
  },
  timeline(medicalRecord: "${medicalRecord}", entityId: ${entityId}, module: ${moduleAccess}) {
    date
    vitalSigns {
      contatoCorona {
        name
        value
      }
      febre38 {
        name
        value
      }
      febre3Dias {
        name
        value
      }
      fisicoCorona {
        name
        value
      }
      grupos {
        name
        value
      }
      moraCorona {
        name
        value
      }
      proximoCorona {
        name
        value
      }
      sintomas {
        name
        value
      }
      questao1 {
        name
        value
      }
      questao21 {
        name
        value
      }
      questao22 {
        name
        value
      }
      questao3 {
        name
        value
      }
    }
    alert {
      dataAlerta
      resultado
      anomalias {
        sintomas
        questao0
        questao1
        questao2
        questao3
        epidemiologia
        comorbidade
        gravidade
      }
    }
    evolutionRecord {
      atendimentoIdHospital
      observacao
      motivo
      usuario
      email
      setor
    }
    evolutionNote {
      observacao
      usuario
      email
    }
    hospitalConfirmation{
      dataColeta
      confirmado
    }
    hospitalCheckout {
      dataColeta
      confirmado
      data
      hora
    }
  }
}
`,
  GET_ALL: (entityId, module) => gql`
query getAll {
  ${getAll(entityId, module)}
}
`,
  GET_HOSPITAL_DASHBOARD_LEGACY: (entityId, module) => gql`
query getAll {
  count(entityId: ${entityId}) {
    allGroupedAlerts {
      _id
      count
    }
  },
  ${getAll(entityId, module)}
}
`,
  GET_HOSPITAL_DASHBOARD: (entityId, module, filterFlag?: boolean) => gql`
query getAll {
  count(entityId: ${entityId} module: "${module}") {
    allGroupedAlerts {
      _id
      count
    }
  },
  ${getDocumentsAll(entityId, '', module, filterFlag)}
}
`,
  DELETE: gql`
    mutation removeAlert($medicalRecord: Int!, $note: String) {
      removeAlert(medicalRecord: $medicalRecord, note: $note)
    }
  `,
  EVOLUTION: gql`
    mutation evolutionRecord(
      $entityId: Int!
      $medicalRecord: String!
      $hospitalMedicalRecord: String!
      $serviceStep: String
      $motive: String
      $note: String
      $user: String
      $email: String
      $sector: String
      $module: String!
      $cellphone: String
      $attendantCpf: String
    ) {
      evolutionRecord(
        entityId: $entityId
        medicalRecord: $medicalRecord
        hospitalMedicalRecord: $hospitalMedicalRecord
        serviceStep: $serviceStep
        motive: $motive
        note: $note
        user: $user
        email: $email
        sector: $sector
        module: $module
        cellphone: $cellphone
        attendantCpf: $attendantCpf
      )
    }
  `,
  OBSERVATION: gql`
    mutation evolutionNote(
      $entityId: Int!
      $module: String!
      $medicalRecord: String!
      $note: String
      $user: String
      $email: String
      $attendanceType: String
      $attendantCpf: String
    ) {
      evolutionNote(
        entityId: $entityId
        medicalRecord: $medicalRecord
        note: $note
        user: $user
        email: $email
        module: $module
        attendanceType: $attendanceType
        attendantCpf: $attendantCpf
      )
    }
  `,
  OBSERVATION_PERSCRIPTION: gql`
    mutation evolutionNotePrescription(
      $entityId: Int!
      $module: String!
      $medicalRecord: String!
      $note: String
      $user: String
      $email: String
      $prescriptionPDF: String
      $status: Boolean
      $attendantCpf: String
    ) {
      evolutionNotePrescription(
        entityId: $entityId
        medicalRecord: $medicalRecord
        note: $note
        user: $user
        email: $email
        module: $module
        prescriptionPDF: $prescriptionPDF
        status: $status
        attendantCpf: $attendantCpf
      )
    }
  `,
  SEND_PRESCRIPTION_LINK: gql`
    mutation sendPrescriptionLink(
      $module: String!
      $entityId: Int!
      $atendimentoId: String!
      $cellphone: String!
      $prescriptionLink: String!
      $usuario: String
      $email: String
      $attendantCpf: String
    ) {
      sendPrescriptionLink(
        module: $module
        entityId: $entityId
        atendimentoId: $atendimentoId
        cellphone: $cellphone
        prescriptionLink: $prescriptionLink
        usuario: $usuario
        email: $email
        attendantCpf: $attendantCpf
      )
    }
  `,
  TRANSFER: gql`
    mutation transfer(
      $entityId: Int!
      $medicalRecord: String!
      $hospitalId: Int!
      $hospitalName: String!
      $data: String
      $hora: String
    ) {
      transfer(
        entityId: $entityId
        medicalRecord: $medicalRecord
        hospitalId: $hospitalId
        hospitalName: $hospitalName
        data: $data
        hora: $hora
      )
    }
  `,
  CONFIRMATION: gql`
    mutation setHospitalConfirmation($entityId: Int!, $medicalRecord: String!) {
      setHospitalConfirmation(entityId: $entityId, medicalRecord: $medicalRecord)
    }
  `,
  INDICATORS: (entityId) => gql`
query Count {
  count(entityId: ${entityId})
  {
    allInputs
    allCheckouts
    allEvolutions
  }
}
`,
  OTHER_HOSPITALS_ALERTS: (entityId) => gql`
query count {
  count(entityId: ${entityId}) {
    allGroupedAlerts {
      _id
      count
    }
  }
}
`,
  TRANSFER_SERVICE_STEP: gql`
    mutation transferServiceStep(
      $entityId: Int!
      $medicalRecord: String!
      $module: String!
      $user: String!
      $email: String!
      $serviceStep: String!
      $initialSector: String!
    ) {
      transferServiceStep(
        entityId: $entityId
        medicalRecord: $medicalRecord
        module: $module
        user: $user
        email: $email
        serviceStep: $serviceStep
        initialSector: $initialSector
      )
    }
  `,
  JOIN_VIDEO_CALL: gql`
    mutation joinVideoCall(
      $entityId: Int!
      $medicalRecord: String!
      $telemedicineToken: String!
      $module: String!
      $usuario: String
      $email: String
      $attendantCpf: String
    ) {
      joinVideoCall(
        entityId: $entityId
        medicalRecord: $medicalRecord
        telemedicineToken: $telemedicineToken
        module: $module
        usuario: $usuario
        email: $email
        attendantCpf: $attendantCpf
      )
    }
  `,
  REMOTE_ATENDIMENTO_EVOLUTION_RECORD: gql`
    mutation remoteAtendimentoEvolutionRecord(
      $entityId: Int!
      $sector: String!
      $atendimentoId: String!
      $motivo: String
      $usuario: String
      $email: String
      $name: String
      $module: String!
      $attendantCpf: String
    ) {
      remoteAtendimentoEvolutionRecord(
        entityId: $entityId
        sector: $sector
        atendimentoId: $atendimentoId
        motivo: $motivo
        usuario: $usuario
        email: $email
        name: $name
        module: $module
        attendantCpf: $attendantCpf
      )
    }
  `,
  TELECONSULTATION_LINK: gql`
    mutation sendTeleconsultationLink(
      $entityId: Int!
      $cellphone: String!
      $atendimentoId: String!
      $module: String!
      $note: String
      $usuario: String
      $email: String
      $attendantCpf: String
    ) {
      sendTeleconsultationLink(
        entityId: $entityId
        cellphone: $cellphone
        atendimentoId: $atendimentoId
        module: $module
        note: $note
        usuario: $usuario
        email: $email
        attendantCpf: $attendantCpf
      )
    }
  `,
  TELEMEDICINE_EVOLUTION_NOTE: gql`
    mutation telemedicineEvolutionNote(
      $entityId: Int!
      $medicalRecord: String!
      $doctorName: String
      $doctorEmail: String!
      $note: String!
    ) {
      telemedicineEvolutionNote(
        entityId: $entityId
        medicalRecord: $medicalRecord
        regionalMedicalCouncil: "12-3213"
        doctorName: $doctorName
        doctorUser: $doctorName
        doctorEmail: $doctorEmail
        note: $note
      )
    }
  `,
  CHANGE_DOCTOR_STATUS: gql`
    mutation changeDoctorStatus($entityId: Int!, $doctorId: String!, $status: Boolean!) {
      changeDoctorStatus(entityId: $entityId, doctorId: $doctorId, status: $status)
    }
  `,
};

export default DashboardModel;

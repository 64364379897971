import styled, { css } from 'styled-components';

export const Container = styled.div.attrs((props) => ({
  color: props.color,
  activeColor: props.activeColor,
  active: props.active,
}))`
  text-align: center;
  margin: 0px 4px;
  width: 138px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 16px;
  color: #444444;
  font-size: 14px;
  font-family: 'Roboto';

  ${({ color, activeColor, active }) => css`
    border: 1px solid ${active ? activeColor : color};
    opacity: ${active ? 1 : 0.9};
  `}

  &:hover {
    opacity: 1;
  }
`;

import * as amplitude from '@amplitude/analytics-browser';

import environment from 'Utils/Dictionary/environment';

if (environment.AMPLITUDE_API_KEY) {
  amplitude.init(environment.AMPLITUDE_API_KEY);
}

export default {
  setUserId(userId) {
    if (environment.AMPLITUDE_API_KEY) {
      amplitude.setUserId(userId);
    }
  },
  trackEvent(eventName, eventProperties) {
    if (environment.AMPLITUDE_API_KEY) {
      amplitude.track(eventName, eventProperties);
    }
  },
};

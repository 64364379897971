import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import IconBase from '@mdi/react';

export const Container = styled(motion.div).attrs((props) => ({
  width: props.width,
  expandable: props.expandable,
  expanded: props.expanded,
  direction: props.direction,
  padding: props.padding,
  disabled: props.disabled,
}))`
  border-radius: 5px;
  min-height: 55px;

  ${({ width, expandable, expanded, direction, padding, disabled }) => css`
    padding: ${expandable ? '8px 36px 8px 16px' : '8px 16px'};
    width: ${width}${typeof width === 'string' ? '' : 'px'};
  `};
`;

export const TextArea = styled(motion.textarea).attrs((props) => ({
  disabled: props.disabled,
}))`
  border: none;
  padding: 0px;
  background: transparent;
  font-family: 'Roboto';
  font-size: 14px;
  width: 100%;
  height: 100%;
  resize: none;
  overflow: auto;
  outline: none;

  ${({ disabled }) => css`
    color: ${disabled ? '#fff' : '#aaaaaa'};
  `}

  &:disabled {
    color: #fff;
  }

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #ebedf2;
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
  }

  &::placeholder {
    font-family: 'Roboto';
    font-size: 14px;
    color: #aaaaaa;
  }

  &:focus,
  &:active {
    border: none;
  }
`;

export const ExpandableIcon = styled(IconBase).attrs((props) => ({
  onClick: props.onClick,
}))`
  position: absolute;
  top: 0;
  right: 8px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

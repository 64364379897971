import { gql } from '@apollo/client';

import { TelemedicineDataFields } from './telemedicine';

export const NewModulesFields = gql`
  fragment NewModulesFields on ModuleName {
    name
    slug
  }
`;

export const SectionsFields = gql`
  fragment SectionsFields on SectionName {
    name
    slug
    belongs
  }
`;

export const TriagesFields = gql`
  fragment TriagesFields on TriageName {
    name
    slug
    belongs
  }
`;

export const ModulesDataFields = gql`
  fragment ModulesDataFields on ModuleData {
    telemedicine {
      ...TelemedicineDataFields
    }
  }
  ${TelemedicineDataFields}
`;

export const EntitiesModulesFields = gql`
  fragment EntitiesModulesFields on entitiesModules {
    name
    slug
    active
    order
    serviceStep
  }
`;

export const ModulesFields = gql`
  fragment ModulesFields on Modules {
    default
    telemedicine
    generalist
    remoteatendimento
    chat
  }
`;

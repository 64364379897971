import styled, { css } from 'styled-components';

export const Container = styled.header<{ background?: string }>`
  display: flex;
  gap: 50px;
  align-items: center;
  flex: 1;
  height: 86px;
  box-shadow: 0px 3px 6px #0000000a;
  padding: 16px 24px 16px 32px;

  ${({ background }) => css`
    background: ${background ?? 'white'};
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;

export const Children = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

export const ExitIcon = styled.img`
  margin-left: 15px;
  cursor: pointer;
`;

export const SettingsIcon = styled.img``;

export const Divider = styled.div`
  height: 62px;
  width: 2px;
  background: #ececec;
  margin: 0px 25px;
`;

export const Col = styled.div.attrs((props) => ({
  direction: props.direction || 'row',
  align: props.align || 'center',
  justify: props.justify || 'space-between',
  width: props.width || 'auto',
}))`
  display: flex;

  ${({ direction, align, justify, width }) => css`
    flex-direction: ${direction};
    align-items: ${align};
    justify-content: ${justify};
    width: ${width};
  `}
`;

import routesDictionary from 'Utils/Dictionary/routesDictionary';
import { getEntityEmail, getEntityRoleId } from '../../Config/SectorConfig';

export const SectorDictionaryHistory = {
  TELEATENDIMENTO_HISTORY: {
    slug: 'HISTORY',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Casos resolvidos',
    module: 'Casos resolvidos',
    icon: 'mdi-check',
    route: routesDictionary.TELEATENDIMENTO_HISTORY,
    type: 1,
    id: '20',
    rule: (collection) => collection?.senderId && collection?.evolutionRecord.setor === 'HISTORY',
  },
  TELEATENDIMENTO_OUTSIDE: {
    slug: 'OUTSIDE',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Bot-Fora das 24h',
    module: 'Bot-Fora das 24h',
    icon: 'mdi-account-details',
    route: routesDictionary.TELEATENDIMENTO_OUTSIDE,
    type: 1,
    id: '21',
    rule: (collection) =>
      (collection?.senderId && collection?.evolutionRecord.setor === 'ALL') ||
      collection?.evolutionRecord.setor === 'AWAITING',
  },
  TELEATENDIMENTO_CONTACT_ACTIVE: {
    slug: 'CONTACT_ACTIVE',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Contato Ativo',
    module: 'Contato Ativo',
    icon: 'mdi-contacts',
    route: routesDictionary.TELEATENDIMENTO_CONTACT_ACTIVE,
    type: 1,
    id: '22',
    rule: (collection) =>
      collection?.senderId &&
      collection?.evolutionRecord.setor === 'CONTACT_ACTIVE' &&
      (getEntityRoleId() === 3
        ? collection?.evolutionRecord.email === getEntityEmail()
        : collection?.evolutionRecord.email),
  },
  TELEATENDIMENTO_IMPORT: {
    slug: 'IMPORT',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Casos importados',
    module: 'Casos importados',
    icon: 'mdi-file-excel',
    route: routesDictionary.TELEATENDIMENTO_IMPORT,
    type: 1,
    id: '23',
    rule: (collection) =>
      collection?.senderId &&
      collection?.evolutionRecord.setor === 'IMPORT' &&
      (getEntityRoleId() === 3
        ? collection?.evolutionRecord.email === getEntityEmail()
        : collection?.evolutionRecord.email),
  },
};

export const SectorDictionary = {
  TELEATENDIMENTO: {
    slug: 'ALL',
    background: '#FF77FF',
    color: '#ff881f',
    name: 'Em aberto',
    icon: 'mdi-account-alert',
    module: 'Em aberto',
    route: routesDictionary.TELEATENDIMENTO,
    type: 1,
    id: '12',
    rule: (collection) =>
      collection?.senderId &&
      collection?.medicalRecord.updateAt &&
      collection?.evolutionRecord.setor === 'ALL',
  },
  TELEATENDIMENTO_IN_AWAITING: {
    slug: 'AWAITING',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Aguardando atendimento',
    icon: 'mdi-account-clock',
    module: 'Aguardando atendimento',
    route: routesDictionary.TELEATENDIMENTO_IN_AWAITING,
    type: 1,
    id: '13',
    rule: (collection) =>
      collection?.senderId &&
      collection?.medicalRecord.updateAt &&
      collection?.evolutionRecord.setor === 'AWAITING',
  },
  TELEATENDIMENTO_HUMAN: {
    slug: 'HUMAN',
    background: '#FF881F',
    color: '#FF881F',
    name: 'Em atendimento humano',
    icon: 'mdi-message-processing',
    module: 'Em atendimento humano',
    route: routesDictionary.TELEATENDIMENTO_HUMAN,
    type: 1,
    id: '14',
    rule: (collection) =>
      collection?.senderId &&
      collection?.medicalRecord.updateAt &&
      collection?.evolutionRecord.setor === 'HUMAN' &&
      (getEntityRoleId() === 3
        ? collection?.evolutionRecord.email === getEntityEmail()
        : collection?.evolutionRecord.email),
  },
  TELEATENDIMENTO_CLOSED: {
    slug: 'CLOSED',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Casos fechados',
    icon: 'mdi-account-check',
    module: 'Casos fechados',
    route: routesDictionary.TELEATENDIMENTO_CLOSED,
    type: 1,
    id: '15',
    rule: (collection) =>
      collection?.senderId &&
      collection?.medicalRecord.updateAt &&
      collection?.evolutionRecord.setor === 'CLOSED' &&
      (getEntityRoleId() === 3
        ? collection?.evolutionRecord.email === getEntityEmail()
        : collection?.evolutionRecord.email),
  },

  TELEATENDIMENTO_HISTORY: {
    slug: 'HISTORY',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Casos resolvidos',
    module: 'Casos resolvidos',
    icon: 'mdi-check',
    route: routesDictionary.TELEATENDIMENTO_HISTORY,
    type: 2,
    id: '16',
    rule: (collection) =>
      collection?.senderId &&
      collection?.medicalRecord.updateAt &&
      collection?.evolutionRecord.setor === 'HISTORY' &&
      (getEntityRoleId() === 3
        ? collection?.evolutionRecord.email === getEntityEmail()
        : collection?.evolutionRecord.email),
  },

  TELEATENDIMENTO_IMPORT: {
    slug: 'IMPORT',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Casos importados',
    module: 'Casos importados',
    icon: 'mdi-file-excel',
    route: routesDictionary.TELEATENDIMENTO_IMPORT,
    type: 2,
    id: '17',
    rule: (collection) =>
      collection?.senderId &&
      collection?.medicalRecord.updateAt &&
      collection?.evolutionRecord.setor === 'IMPORT' &&
      (getEntityRoleId() === 3
        ? collection?.evolutionRecord.email === getEntityEmail()
        : collection?.evolutionRecord.email),
  },
  TELEATENDIMENTO_CONTACT_ACTIVE: {
    slug: 'CONTACT_ACTIVE',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Contato Ativo',
    module: 'Contato Ativo',
    icon: 'mdi-contacts',
    route: routesDictionary.TELEATENDIMENTO_CONTACT_ACTIVE,
    type: 2,
    id: '18',
    rule: (collection) =>
      collection?.senderId &&
      collection?.evolutionRecord.setor === 'CONTACT_ACTIVE' &&
      (getEntityRoleId() === 3
        ? collection?.evolutionRecord.email === getEntityEmail()
        : collection?.evolutionRecord.email),
  },
  TELEATENDIMENTO_OUTSIDE: {
    slug: 'OUTSIDE',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Bot-Fora das 24h',
    module: 'Bot-Fora das 24h',
    icon: 'mdi-account-details',
    route: routesDictionary.TELEATENDIMENTO_OUTSIDE,
    type: 2,
    id: '19',
    rule: (collection) =>
      (collection?.senderId && collection?.evolutionRecord.setor === 'ALL') ||
      collection?.evolutionRecord.setor === 'AWAITING',
  },
};

export const SectorRoutes = {
  all: SectorDictionary.TELEATENDIMENTO,
  human: SectorDictionary.TELEATENDIMENTO_HUMAN,
  awaiting: SectorDictionary.TELEATENDIMENTO_IN_AWAITING,
  closed: SectorDictionary.TELEATENDIMENTO_CLOSED,

  history: SectorDictionaryHistory.TELEATENDIMENTO_HISTORY,
  outside: SectorDictionaryHistory.TELEATENDIMENTO_OUTSIDE,
  contact: SectorDictionaryHistory.TELEATENDIMENTO_CONTACT_ACTIVE,
  import: SectorDictionaryHistory.TELEATENDIMENTO_IMPORT,
};

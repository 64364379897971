import { createActions, createReducer, resettableReducer } from 'reduxsauce';

// define as ações
export const { Types: ModulesTypes, Creators: ModulesActions } = createActions({
  modulesSetImminentTeleconsultations: ['imminentTeleconsultations'],
  modulesSetFilters: ['filters'],
  modulesRemoveFilter: ['filterKey', 'filterValue'],
  modulesResetFilterActive: ['filterKey'],
  modulesResetFilters: null,
  modulesReset: null,
});

const FILTER_DEFAULT = {
  active: null,
  options: [],
  selected: [],
  static: false,
  translate: false,
};

// define o estado inicial
const INITIAL_STATE = {
  imminentTeleconsultations: {},
  filters: {
    tags: FILTER_DEFAULT,
    ubs: FILTER_DEFAULT,
    group: FILTER_DEFAULT,
    evolution: {
      ...FILTER_DEFAULT,
      static: true,
    },
    healthCare: {
      ...FILTER_DEFAULT,
      static: false,
    },
    status: {
      ...FILTER_DEFAULT,
      translate: true,
    },
    flow: FILTER_DEFAULT,
    alert: FILTER_DEFAULT,
    hasUnreadChat: {
      ...FILTER_DEFAULT,
      static: true,
    },
  },
};

/**
 * Atualiza se a modal está aberta ou não
 * @param {{}} state
 * @param {{}} payload
 */
const modulesSetImminentTeleconsultations = (
  state: Record<string, any>,
  { imminentTeleconsultations }: Record<string, any>
) => ({
  ...state,
  imminentTeleconsultations: {
    ...state.imminentTeleconsultations,
    ...imminentTeleconsultations,
  },
});

const modulesSetFilters = (state, { filters }) => {
  const _filters = {};
  for (const filterKey in filters) {
    _filters[filterKey] = {
      ...state.filters[filterKey],
      active: filters[filterKey]?.active,
      selected: filters[filterKey]?.selected,
    };
  }

  setCachedFilters(_filters);

  return {
    ...state,
    filters: _filters,
  };
};

const modulesResetFilters = (state) => {
  clearCachedFilters();

  return {
    ...state,
    filters: INITIAL_STATE.filters,
  };
};

const modulesRemoveFilter = (state, { filterKey, filterValue }) => {
  const _filters = {
    ...state.filters,
    [filterKey]: {
      ...state.filters[filterKey],
      selected: state.filters[filterKey]?.selected.filter((value) => value !== filterValue),
    },
  };

  setCachedFilters(_filters);

  return {
    ...state,
    filters: _filters,
  };
};

const modulesResetFilterActive = (state, { filterKey }) => {
  const _filters = {
    ...state.filters,
    [filterKey]: {
      ...INITIAL_STATE.filters[filterKey],
    },
  };

  setCachedFilters(_filters);

  return {
    ...state,
    filters: _filters,
  };
};

const getInitialCachedState = () => {
  const filters = { ...INITIAL_STATE.filters };
  const cachedFilters = getCachedFilters();

  for (const filterKey in filters) {
    filters[filterKey] = {
      ...filters[filterKey],
      active: cachedFilters[filterKey]?.active ?? INITIAL_STATE.filters[filterKey]?.active,
      selected: cachedFilters[filterKey]?.selected?.length
        ? cachedFilters[filterKey].selected
        : INITIAL_STATE.filters[filterKey]?.selected,
    };
  }

  return {
    ...INITIAL_STATE,
    filters,
  };
};

// define o reducer
const reducer = createReducer(getInitialCachedState(), {
  [ModulesTypes.MODULES_SET_IMMINENT_TELECONSULTATIONS]: modulesSetImminentTeleconsultations,
  [ModulesTypes.MODULES_SET_FILTERS]: modulesSetFilters,
  [ModulesTypes.MODULES_REMOVE_FILTER]: modulesRemoveFilter,
  [ModulesTypes.MODULES_RESET_FILTER_ACTIVE]: modulesResetFilterActive,
  [ModulesTypes.MODULES_RESET_FILTERS]: modulesResetFilters,
});

// torna o reducer atualizado
export default resettableReducer(ModulesTypes.MODULES_RESET)(reducer);

function setCachedFilters(filters = {}) {
  localStorage.setItem('filter-cache', JSON.stringify(filters));
}

function getCachedFilters() {
  const cachedItem = localStorage.getItem('filter-cache');

  if (cachedItem) {
    return JSON.parse(cachedItem);
  }

  return {};
}

export function clearCachedFilters() {
  localStorage.removeItem('filter-cache');
}

const baseStyle = {
  text: {
    color: 'red.400',
    mt: 1.5,
    fontSize: 'sm',
    lineHeight: 'normal',
  },
  icon: {
    marginEnd: 2,
    color: 'red.400',
    marginBottom: 'px',
  },
};

export const formErrorTheme = {
  baseStyle,
};

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  // Interação
  blue: {
    50: '#EBF4FF',
    100: '#DDE9F7',
    200: '#B1D2F6',
    300: '#62A6F0',
    400: '#238BFC',
    500: '#0071EB',
    600: '#0555CC',
    700: '#043E8F',
    800: '#032D68',
    900: '#001A3D',
  },

  // Marca
  orange: {
    50: '#FFF4EB',
    100: '#F7EBE2',
    200: '#FCDCC0',
    300: '#FBB476',
    400: '#FD9E4A',
    500: '#FF881F',
    600: '#E66F00',
    700: '#D14900',
    800: '#913200',
    900: '#381400',
  },

  // Neutra
  gray: {
    50: '#EDEFF3',
    100: '#DBDCE1',
    200: '#B7B9C2',
    300: '#898D9A',
    400: '#5C6275',
    500: '#4A4F5E',
    600: '#272A35',
    700: '#181A20',
  },

  // Sucesso
  green: {
    50: '#EDFDF3',
    100: '#D4F7E1',
    200: '#9DECBA',
    300: '#4CDC80',
    400: '#12BA4F',
    500: '#10893C',
    600: '#0A5224',
  },

  // Atenção
  yellow: {
    50: '#FEF8EC',
    100: '#FEEEB8',
    200: '#FEE385',
    300: '#FDD95D',
    400: '#FDCD2B',
    500: '#DF9C0C',
    600: '#B6701B',
  },

  // Erro
  red: {
    50: '#FCEEEE',
    100: '#FCE4E4',
    200: '#F8C4C4',
    300: '#F29191',
    400: '#EC5B5B',
    500: '#DC3838',
    600: '#AD1F1F',
  },
};

export default colors;

import { useContext, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import useCache from '../../Hooks/useCache';
import DashboardModel from '../../Models/DashboardModel';
import Datatable from '../../Components/Datatable';

import { Container, ContainerButton, ButtonCSV, Text } from './styles';
import CSVReader from 'react-csv-reader';
import Button from '../../Components/Button';
import { numberMask } from '../../Chat/utils/numberMask';
import { getEntityEmail } from '../../Utils/Config/SectorConfig';
import ModuleContext from 'Context/moduleContext';

function CSV() {
  const [user] = useCache('user');
  const { moduleDispatch } = useContext(ModuleContext);

  const [state, setState] = useState(true);
  const [send, setSend] = useState(true);

  const [message, setMessage] = useState('');

  const [data, setData] = useState([]);
  const [row, setRow] = useState([]);

  const [remoteAtendimentoImport] = useMutation(DashboardModel.MUTATION_R_A_IMPORT);

  const HandleModal = async () => {
    // eslint-disable-next-line
    const filtered = data?.filter((item) => {
      if (item.telefone) {
        return item;
      }
    });
    // eslint-disable-next-line
    const senderId = filtered?.map((item) => {
      if (item.telefone) {
        return {
          senderId: item.telefone !== null ? `${item.telefone}` : '',
          name: item.nome !== null ? `${item.nome}` : '',
          cpf: item.cpf !== null ? `${item.cpf}` : '',
          email: item.email !== null ? `${item.email}` : '',
          phone: item.celular !== null ? `${item.celular}` : '',
          zip: item.cep !== null ? `${item.cep}` : '',
          vehicle: item.veiculo !== null ? `${item.veiculo}` : '',
          plate: item.placa !== null ? `${item.placa}` : '',
          intent: item.intencao !== null ? `${item.intencao}` : '',
        };
      }
    });

    if (senderId?.length > 0) {
      remoteAtendimentoImport({
        variables: {
          module: 'customer_service',
          entityId: user.entityId[0],
          email: getEntityEmail() || 'contato@munai.com.br',
          atendimentoId: senderId,
        },
      })
        .then(function () {
          setMessage(
            'Dados enviados com sucesso, em alguns minutos será carregado os dados do arquivo CSV importado.'
          );
          setState(true);
          setSend(false);
          setData([]);
          setRow([]);

          setTimeout(function () {
            moduleDispatch({
              type: 'CHANGE_STEP',
              stepActive: 'IMPORT',
            });
          }, 3000);
        })
        .catch(function () {
          setMessage('Seu arquivo CSV tem algum dado invalido !.');
          setState(true);
          setSend(false);
          setData([]);
          setRow([]);
        });
    } else {
      setMessage('CSV invalido, não possui os campos requeridos para o envio!.');
      setState(true);
      setSend(false);
      setData([]);
      setRow([]);
    }
  };

  const handleForce = (data) => {
    setState(false);
    setData(data);

    setSend(true);

    if (data) {
      // eslint-disable-next-line
      const filtered = data?.filter((item) => {
        if (item.telefone) {
          return item;
        }
      });

      if (filtered?.length > 0) {
        // eslint-disable-next-line
        const senderId = filtered?.map((item) => {
          if (item.telefone) {
            return {
              senderId: item.telefone !== null ? `${item.telefone}` : '',
              name: item.nome !== null ? `${item.nome}` : '',
              cpf: item.cpf !== null ? `${item.cpf}` : '',
              email: item.email !== null ? `${item.email}` : '',
              phone: item.celular !== null ? `${item.celular}` : '',
              zip: item.cep !== null ? `${item.cep}` : '',
              vehicle: item.veiculo !== null ? `${item.veiculo}` : '',
              plate: item.placa !== null ? `${item.placa}` : '',
              intent: item.intencao !== null ? `${item.intencao}` : '',
            };
          }
        });
        setRow(senderId);
      } else {
        setMessage('CSV invalido, não possui os campos requeridos para o envio!.');
        setState(true);
        setSend(false);
        setData([]);
        setRow([]);
      }
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Telefone',
        accessor: 'senderId',
        width: 200,
        Cell: ({ row }) => (
          <strong style={{ margin: '0px', color: '#000' }}>
            {numberMask(row.original.senderId)}
          </strong>
        ),
      },
      {
        Header: 'Nome',
        accessor: 'name',
        width: 200,
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
        width: 200,
      },
      {
        Header: 'E-Mail',
        accessor: 'email',
        width: 200,
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        width: 200,
      },
      {
        Header: 'CEP',
        accessor: 'zip',
        width: 200,
      },
      {
        Header: 'Veiculo',
        accessor: 'vehicle',
        width: 200,
      },
      {
        Header: 'Placa',
        accessor: 'plate',
        width: 200,
      },
      {
        Header: 'Intenção',
        accessor: 'intent',
        width: 200,
      },
      {
        Header: '',
        accessor: 'search',
        width: 0,
        Cell: () => null,
      },
    ],
    []
  );

  return (
    <>
      <ContainerButton>
        <span>
          <strong>Total de Itens: {row?.length || 0}</strong>
        </span>
        <ButtonCSV>
          <Text htmlFor="input-file"> SELECIONE O ARQUIVO </Text>
          <CSVReader
            inputId="input-file"
            inputStyle={{ display: 'none' }}
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          />
        </ButtonCSV>

        <Button
          style={{
            margin: '0px 0px 19px 0px',
          }}
          className="patient-table__button"
          disabled={state}
          modifiers={['btnSmall', '', 'textBlue', 'btnFlex', '', 'fontSize12']}
          onClick={HandleModal}
        >
          <span>IMPORTAR</span>
        </Button>
      </ContainerButton>

      <Container>
        {send ? (
          <Datatable columns={columns} data={row ? row : []} loading={!row} cacheKey="csv" />
        ) : (
          <span>
            <strong>{message}</strong>
          </span>
        )}
      </Container>
    </>
  );
}

export default CSV;

import styled, { css } from 'styled-components';
import IconBase from '@mdi/react';

const sharedProps = (props) => ({
  activeColor: props.activeColor,
  active: Boolean(props.active),
  iconColor: props.iconColor || '#0071eb',
});

export const Container = styled.div.attrs(sharedProps)`
  height: 57px;
  padding: 10px 17px;
  display: flex;
  box-shadow: 0px 3px 15px #0909090a;
  border-radius: 5px;
  min-width: 150px;
  margin: 0px 8px;
  margin-bottom: 40px;
  align-items: center;
  text-decoration: none !important;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: none;
  }

  ${({ active, activeColor }) => css`
    color: ${active ? 'white' : '#444444'};
    background: ${active ? activeColor : '#FFFFFF'};
    font-size: ${active ? 16 : 15}px;
    font-weight: ${active ? 700 : 500};
  `}
`;

export const LabelContiner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  letter-spacing: 0.45px;
`;

export const Icon = styled(IconBase)`
  margin-right: 17px;
`;

export const Image = styled.img.attrs(sharedProps)`
  margin-right: 17px;
  height: 24px;
  width: 24px;
`;

import styled, { css } from 'styled-components';
import IconBase from '@mdi/react';

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

export const Icon = styled(IconBase)`
  margin-right: 12px;
  width: 24px;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Subtitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #070707;
  padding-top: 8px;
`;

export const Title = styled.span.attrs((props) => ({
  color: props.color,
}))`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;

  ${({ color }) => css`
    color: ${color};
  `}
`;

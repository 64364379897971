import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { split } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import environment from 'Utils/Dictionary/environment';
import LocalCache from 'Services/LocalCache';

import amplitude from '../Utils/Helpers/amplitude';
import dateFns from '../Utils/Helpers/dateFns';
import { isLiveQueryOperationDefinitionNode } from '@n1ru4l/graphql-live-query';
import { getOperationAST, OperationDefinitionNode } from 'graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

const getAuthorizationHeaders = () => {
  const authToken = LocalCache.getUser()?.token;
  const tmpAuthToken = LocalCache.getTmpUser()?.token;
  const authorizationToken = authToken || tmpAuthToken;

  const headers: RequestInit['headers'] = {};

  if (authorizationToken) {
    headers['Authorization'] = `Bearer ${authorizationToken}`;
  }

  return headers;
};

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: { ...headers, ...getAuthorizationHeaders() },
  }));

  return forward(operation);
});

const amplitudeLink = new ApolloLink((operation, forward) => {
  const user = LocalCache.getUser();

  if (!user) {
    return forward(operation);
  }

  const data = {
    name: user.name,
    email: user.email,
    userId: user.userId,
    entityId: user.entityId,
    date: dateFns.formatTo(new Date(), 'dd/MM/yyyy HH:mm:ss'),
    height: window?.screen?.height,
    width: window?.screen?.width,
  };

  const userId = `${data.userId}.${data.entityId[0]}`;
  amplitude.setUserId(userId);
  amplitude.trackEvent('ACTIVITIES_LAURA_CARE', data);

  return forward(operation);
});

const createWrappedLink = (originalLink: ApolloLink) =>
  from([new RetryLink(), authLink, amplitudeLink, originalLink]);

const isSubscription = (definition: Maybe<OperationDefinitionNode>) => {
  const isSubscription =
    definition?.kind === 'OperationDefinition' && definition.operation === 'subscription';

  return isSubscription;
};

const isLiveQuery = (
  definition: Maybe<OperationDefinitionNode>,
  variables: Record<string, any>
) => {
  const isLiveQuery = !!definition && isLiveQueryOperationDefinitionNode(definition, variables);
  return isLiveQuery;
};

const httpLink = createWrappedLink(new HttpLink({ uri: environment.GRAPHQL_ENDPOINT }));
const wsLink = new GraphQLWsLink(
  createClient({
    url: environment.GRAPHQL_WEBSOCKET_ENDPOINT,
    connectionParams: () => ({
      ...getAuthorizationHeaders(),
    }),
  })
);

const composedLink = split(
  ({ query, operationName, variables }) => {
    const definition = getOperationAST(query, operationName);
    return isSubscription(definition) || isLiveQuery(definition, variables);
  },
  wsLink,
  httpLink
);

export default new ApolloClient({
  link: composedLink,
  cache: new InMemoryCache(),
});

import routesDictionary from 'Utils/Dictionary/routesDictionary';
import { SectorRoutes as TelemedicineSectorRoutes } from 'Utils/Dictionary/Telemedicine/SectorDictionary';
import { SectorRoutes as ChatSectorRoutes } from 'Utils/Dictionary/Chat/SectorDictionary';

export const SectorDictionary = {
  GENERALIST_TRANSFERRED: {
    slug: 'DASHBOARD',
    background: '#FF881F',
    color: '#FF881F',
    name: 'Pacientes para avaliação',
    icon: 'mdi-account-clock',
    module: 'PACIENTES PARA AVALIAÇÃO',
    route: routesDictionary.GENERALIST_TRANSFERRED,
    type: 1,
    id: '8',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      (collection?.evolutionRecord?.serviceStep === 'screening' ||
        collection?.evolutionRecord === null),
  },
  GENERALIST_IN_PROGRESS: {
    slug: 'GENERALIST_IN_PROGRESS',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes confirmados',
    icon: 'mdi-doctor',
    module: 'PACIENTES CONFIRMADOS',
    route: routesDictionary.GENERALIST_IN_PROCESS,
    type: 1,
    id: '9',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.setor === 'GENERALIST_IN_PROGRESS',
  },
  GENERALIST_MONITORING: {
    slug: 'MONITORING',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes monitorados',
    icon: 'mdi-account-check',
    module: 'PACIENTES MONITORADOS',
    route: routesDictionary.GENERALIST_MONITORING,
    type: 1,
    id: '10',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.setor === 'MONITORING',
  },
  GENERALIST_QUEUE: {
    slug: 'AWAITING',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Pacientes aguardando no PA',
    icon: 'mdi-account-check',
    module: 'PACIENTES AGUARDANDO NO PA',
    route: routesDictionary.GENERALIST_QUEUE,
    type: 1,
    id: '11',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.setor === 'AWAITING',
  },
  FORWARDED: {
    slug: 'FORWARDED',
    background: '#FF77FF',
    color: '#FF33FF',
    name: 'Encaminhados para UPA/Hospital',
    icon: 'mdi-account-alert',
    module: 'ENCAMINHADOS PARA UPA/HOSPITAL',
    route: routesDictionary.FORWARDED,
    type: 1,
    id: '4',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.alert &&
      collection?.evolutionRecord?.serviceStep === 'forwarded' &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf,
  },
  HISTORY: {
    slug: 'HISTORY',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Finalizado',
    module: 'HISTÓRICO',
    icon: 'mdi-account-arrow-right',
    route: routesDictionary.HISTORY,
    type: 2,
    id: '5',
    rule: (collection) =>
      (collection.vitalSigns || collection.symptoms) &&
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.serviceStep === 'history',
  },
  ELIGIBLE: {
    slug: 'ELIGIBLE',
    background: '#E3E3E3',
    color: '#E3E3AA',
    name: 'Elegíveis',
    module: 'ELEGÍVEIS',
    icon: 'mdi-account-arrow-right',
    route: routesDictionary.HISTORY,
    type: 1,
    id: '6',
    rule: (collection) =>
      collection.medicalRecord &&
      collection.patient &&
      collection.patient.cpf &&
      collection?.evolutionRecord?.serviceStep === 'eligible',
  },
};

export const SectorRoutes = (module) => {
  return {
    all: SectorDictionary.GENERALIST_TRANSFERRED,
    transferred: SectorDictionary.GENERALIST_TRANSFERRED,
    in_process: SectorDictionary.GENERALIST_IN_PROGRESS,
    forwarded: SectorDictionary.FORWARDED,
    queue: SectorDictionary.GENERALIST_QUEUE,
    monitoring: SectorDictionary.GENERALIST_MONITORING,
    history: SectorDictionary.HISTORY,
    telemedicine: TelemedicineSectorRoutes(module),
    chat: ChatSectorRoutes(module),
    eligible: SectorDictionary.ELIGIBLE,
  };
};

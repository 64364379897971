import { createActions, createReducer, resettableReducer } from 'reduxsauce';

export const { Types: ModalResendLinkTypes, Creators: ModalResendLinkActions } = createActions({
  modalResendLinkSetIsOpen: ['isOpen', 'document', 'submit'],
  modalResendLinkReset: null,
});

const INITIAL_STATE = {
  isOpen: false,
  document: null,
  submit: null,
};

const modalResendLinkSetIsOpen = (state, { isOpen, document, submit }) => ({
  ...state,
  isOpen,
  document,
  submit,
});

const reducer = createReducer(INITIAL_STATE, {
  [ModalResendLinkTypes.MODAL_RESEND_LINK_SET_IS_OPEN]: modalResendLinkSetIsOpen,
});

export default resettableReducer(ModalResendLinkTypes.MODAL_RESEND_LINK_RESET)(reducer);

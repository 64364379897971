import { createActions, createReducer, resettableReducer } from 'reduxsauce';

export const { Types: ModalConfirmationTypes, Creators: ModalConfirmationActions } = createActions({
  modalConfirmationSetIsOpen: ['isOpen'],
  modalConfirmationSetData: ['serviceStep', 'data', 'modeRegister'],
  modalConfirmationSetOnCompleted: ['onCompleted'],
  modalConfirmationSetLoading: ['loading'],
  modalConfirmationReset: null,
});

const INITIAL_STATE = {
  isOpen: false,
  serviceStep: null,
  data: null,
  modeRegister: null,
  onCompleted: false,
  loading: false,
};

const modalConfirmationSetIsOpen = (state, { isOpen }) => ({
  ...state,
  isOpen,
});

const modalConfirmationSetData = (state, { serviceStep, data, modeRegister }) => ({
  ...state,
  serviceStep,
  data,
  modeRegister,
});

const modalConfirmationSetOnCompleted = (state, { onCompleted }) => ({
  ...state,
  onCompleted,
});

const modalConfirmationSetLoading = (state, { loading }) => ({
  ...state,
  loading,
});

const reducer = createReducer(INITIAL_STATE, {
  [ModalConfirmationTypes.MODAL_CONFIRMATION_SET_IS_OPEN]: modalConfirmationSetIsOpen,
  [ModalConfirmationTypes.MODAL_CONFIRMATION_SET_DATA]: modalConfirmationSetData,
  [ModalConfirmationTypes.MODAL_CONFIRMATION_SET_ON_COMPLETED]: modalConfirmationSetOnCompleted,
  [ModalConfirmationTypes.MODAL_CONFIRMATION_SET_LOADING]: modalConfirmationSetLoading,
});

export default resettableReducer(ModalConfirmationTypes.MODAL_CONFIRMATION_RESET)(reducer);

import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Input, InputMaskControl } from 'Components';
import { toast } from 'react-toastify';
import Toast from 'Components/Toast';
import LocalCache from 'Services/LocalCache';
import { rulesMessages, customRules } from 'Utils/Helpers/Validator';
import TelemedicineModel from 'Models/TelemedicineModel';

export default function AtivateTeleconsultationForm({ enabled, setEnabled }) {
  const { register, handleSubmit, errors, control, formState } = useForm({ mode: 'onChange' });
  const { firstName, lastName, cpf, birthdate } = LocalCache.getModuleData('telemedicine');
  const [loading, setLoading] = useState(false);
  const [telemedicineUserUpdate] = useMutation(TelemedicineModel.USER_UPDATE);

  const onSubmit = (data) => {
    const { firstName, lastName, cpf, birthdate } = data;
    setLoading(true);
    telemedicineUserUpdate({
      variables: {
        email: LocalCache.getUser().email,
        firstName,
        lastName,
        enabled: true,
        cpf,
        birthdate,
      },
    })
      .then((data) => {
        toast(<Toast type="success" title={'Teleconsulta ativada com sucesso!'} />, {
          autoClose: false,
        });
        const moduleData = JSON.parse(data.data.telemedicineUserUpdate);

        LocalCache.setUser({
          ...LocalCache.getUser(),
          moduleData,
        });

        setEnabled(true);
      })
      .catch((error) => {
        toast(
          <Toast
            type="error"
            title={'Não foi possível ativar a teleconsulta.'}
            subtitle={error.message}
          />,
          { autoClose: false }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form className="form-style-1 card mb-3" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="card-body form-group">
          <div className="row">
            <div className="col-md-12">
              <legend>Ativar Teleconsulta</legend>
            </div>

            <div className="col-md-6">
              <Input
                defaultValue={firstName}
                error={errors?.firstName}
                errormessage={errors?.firstName?.message}
                label="Nome"
                name="firstName"
                ref={register({
                  required: rulesMessages.firstName.required,
                  validate: customRules.empty,
                })}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <Input
                defaultValue={lastName}
                error={errors?.lastName}
                errormessage={errors?.lastName?.message}
                label="Sobrenome"
                name="lastName"
                ref={register({
                  required: rulesMessages.lastName.required,
                  validate: customRules.empty,
                })}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputMaskControl
                type="text"
                defaultValue={cpf}
                error={errors?.cpf}
                errormessage={errors?.cpf?.message}
                label="CPF"
                name="cpf"
                control={control}
                mask="999.999.999-99"
                rules={{
                  required: rulesMessages.document.required,
                  pattern: {
                    message: rulesMessages.document.pattern,
                  },
                  validate: customRules.cpf,
                }}
              />
            </div>
            <div className="col-md-6">
              <InputMaskControl
                type="text"
                defaultValue={birthdate}
                error={errors?.birthdate}
                errormessage={errors?.birthdate?.message}
                label="Data de nascimento"
                name="birthdate"
                control={control}
                mask="99/99/9999"
                rules={{
                  required: rulesMessages.birthdate.required,
                  pattern: {
                    message: rulesMessages.birthdate.pattern,
                  },
                  validate: customRules.birthdate,
                }}
              />
            </div>
          </div>
          <button className="btn btn-primary btn-block" disabled={loading || !formState.isValid}>
            {loading ? 'CARREGANDO...' : enabled ? 'ATUALIZAR DADOS' : 'ATIVAR'}
          </button>
        </fieldset>
      </form>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  .react-calendar__navigation {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    background-color: transparent;
    border: none;
    color: #0071eb;
    font-weight: 600;
    font-size: 20px;
    outline: 0;
  }
  .react-calendar__navigation__arrow:disabled {
    opacity: 0.5;
    color: #444;
  }
  .react-calendar__navigation__arrow:hover {
    filter: brightness(90%);
  }
  .react-calendar__navigation__label {
    &:first-letter {
      text-transform: uppercase;
    }
    font: normal normal normal 16px/19px Roboto;
  }
  .react-calendar__month-view__weekdays__weekday {
    & abbr {
      width: 100%;
      text-align: center;
      display: inline-block;
      color: #a9a9a9;
      text-decoration: none;
      font: normal normal normal 12px/18px Roboto;
      height: 22px;
    }
    text-transform: uppercase;
  }
  .react-calendar__year-view__months__month {
    &:disabled {
      & abbr {
        background-color: transparent;
        color: #878787;
      }
    }
    & abbr {
      height: 32px;
      border-radius: 16px;
      overflow: hidden;
      background-color: #ececec;
      display: block;
      color: #0071eb;
      padding-top: 6px;
      transition: filter 0.5s;
    }
    &:hover abbr {
      filter: brightness(90%);
    }
    &.react-calendar__tile--hasActive abbr {
      background-color: #0071eb;
      color: #fff;
    }
    background-color: transparent;
    border: none;
    outline: 0;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .react-calendar__decade-view__years__year,
  .react-calendar__century-view__decades__decade {
    &:disabled {
      background-color: transparent;
      color: #878787;
    }
    &.react-calendar__tile--hasActive {
      background-color: #0071eb;
      color: #fff;
    }
    transition: filter 0.5s;
    &:hover {
      filter: brightness(90%);
    }
    background-color: transparent;
    border: none;
    outline: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    height: 32px;
    border-radius: 16px;
    overflow: hidden;
    background-color: #ececec;
    display: block;
    color: #0071eb;
    padding-top: 6px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .react-calendar__month-view__days__day {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    &:disabled {
      & abbr {
        background-color: transparent;
        color: #878787;
      }
    }
    font: normal normal normal 12px/18px Roboto;
    background-color: transparent;
    border: none !important;
    outline: 0;
    & abbr {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ececec;
      color: #0071eb;
      padding-top: 7px;
      transition: filter 200ms, background-color 200ms, color 200ms;
    }
    &:hover abbr {
      filter: brightness(90%);
    }
    &:focus abbr,
    &:active abbr {
      background-color: #0071eb;
      color: #fff;
    }
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }
`;

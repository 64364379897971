import * as Styled from '../../styled';
import useCache from 'Hooks/useCache';

function TimelineHeadComponents({
  idx,
  medicalRecord,
  timelineItem,
  answerWhatsCount,
  answerCount,
  shownTimelineData,
  DownBlueArrow,
  DownBlueArrowDark,
  toggleTimelineData,
  isLegacy,
  getLastAlertResolved,
  monitoring,
  isTownHall,
  monitoringDays,
  MonitoringDictionary,
  TimelineAlert,
  timelineItemsCount,
  TimelineNote,
  TimelineSoapEvolution,
  TimelineMonitoring,
  monitoringReduce,
  TimelineMonitoringStatus,
  monitoringOnHold,
  TimelineMonitoringOnHold,
  TimelineEvolution,
  TimelinePatient,
  TimelineTags,
  TimelineWhatsapp,
  moduleAccess,
}) {
  const [modules] = useCache('modules');

  return (
    <Styled.Timeline.Wrapper
      className="timeline__wrapper"
      data-idx={idx}
      data-medicalrecord={medicalRecord}
    >
      <div
        className="header-container"
        onClick={() => toggleTimelineData(idx)}
        style={{
          backgroundColor:
            monitoringOnHold.includes(timelineItem.monitoring?.statusDescription) &&
            !timelineItem.monitoring?.template
              ? MonitoringDictionary?.[timelineItem.monitoring?.statusDescription]?.backgroundColor
              : MonitoringDictionary?.[timelineItem.monitoring?.statusDescription]
                  ?.backgroundColorDefault,
          color:
            monitoringOnHold.includes(timelineItem.monitoring?.statusDescription) &&
            !timelineItem.monitoring?.template
              ? MonitoringDictionary?.[timelineItem.monitoring?.statusDescription]?.colorDefault
              : MonitoringDictionary?.[timelineItem.monitoring?.statusDescription]?.color,
          margin:
            monitoring.includes(timelineItem.monitoring?.statusDescription) &&
            !timelineItem.monitoring?.template
              ? MonitoringDictionary?.[timelineItem.monitoring?.statusDescription]?.margin
              : MonitoringDictionary?.[timelineItem.monitoring?.statusDescription]?.marginDefault,
        }}
      >
        {/* badge start */}
        {timelineItem.alert && isLegacy && (
          <Styled.Timeline.Alert>{timelineItem.alert.resultado}</Styled.Timeline.Alert>
        )}
        {timelineItem.symptoms && getLastAlertResolved(timelineItem)}
        {/* badge end */}
        {/* -----------------------evolutionNote----------------------- */}
        <Styled.Timeline.Alert>{''}</Styled.Timeline.Alert>
        {timelineItem.evolutionNote && (
          <Styled.Timeline.HeaderDateOnly
            title={
              timelineItem.evolutionNote.observacao !== 'Novo responsável pelo atendimento'
                ? 'Evolução'
                : 'Atendimento'
            }
          />
        )}
        {/* -----------------------evolutionSoap----------------------- */}
        {timelineItem.evolutionSoap && <Styled.Timeline.Alert>{''}</Styled.Timeline.Alert>}
        {timelineItem.evolutionSoap && <Styled.Timeline.AlertNone>none</Styled.Timeline.AlertNone>}
        {timelineItem.evolutionSoap && <Styled.Timeline.HeaderDateOnly title="Evolução" />}
        {/* -----------------------evolutionRecord----------------------- */}
        {timelineItem.evolutionRecord && (
          <Styled.Timeline.Alert>{isTownHall() ? 'townhall' : 'hospital'}</Styled.Timeline.Alert>
        )}
        {timelineItem.evolutionRecord && <Styled.Timeline.HeaderDateOnly title="Movido" />}
        {/* -----------------------Symptoms----------------------- */}
        {timelineItem.type === 'whatsapp' && (
          <Styled.Timeline.Alert>whatsapp</Styled.Timeline.Alert>
        )}
        {timelineItem.vitalSigns && timelineItem.alert && !timelineItem.symptoms && (
          <Styled.Timeline.Alert>{timelineItem?.alert?.resultado}</Styled.Timeline.Alert>
        )}
        {(([null].includes(timelineItem?.vitalSigns?.nRespostas) &&
          !['steps'].includes(timelineItem?.vitalSigns?.type)) ||
          timelineItem.alert ||
          timelineItem.type === 'whatsapp') && (
          <Styled.Timeline.Header
            idx={
              timelineItem.type === 'whatsapp'
                ? answerWhatsCount
                : timelineItem.symptoms
                ? answerCount
                : idx + 1
            }
          />
        )}
        {/* -----------------------vitalSigns----------------------- */}
        {![null].includes(timelineItem?.vitalSigns?.nRespostas) &&
          ['steps'].includes(timelineItem?.vitalSigns?.type) &&
          timelineItem.symptoms && (
            <Styled.Timeline.HeaderDateOnly
              title={
                timelineItem.vitalSigns?.nRespostas === '0' ? (
                  <>Resposta do Acompanhamento</>
                ) : (
                  <>
                    Resposta do Acompanhamento{' '}
                    {`${timelineItem.vitalSigns?.nRespostas}/${monitoringDays.maximumDay}`}
                  </>
                )
              }
            />
          )}

        {/*  */}

        {/* -----------------------monitoring----------------------- */}
        {['period'].includes(timelineItem.monitoring?.type) &&
          !['component'].includes(timelineItem.monitoring?.status) && (
            <Styled.Timeline.Alert>munai</Styled.Timeline.Alert>
          )}
        {['steps'].includes(timelineItem.monitoring?.type) &&
          !['component'].includes(timelineItem.monitoring?.status) && (
            <Styled.Timeline.Alert>munai</Styled.Timeline.Alert>
          )}
        {['period', 'steps'].includes(timelineItem.monitoring?.type) &&
          !['component'].includes(timelineItem.monitoring?.status) && (
            <>
              <Styled.Timeline.HeaderDateOnly
                title={
                  timelineItem.monitoring?.type === 'steps'
                    ? `Mensagem de Acompanhamento ${timelineItem.monitoring?.nEnvios}/${monitoringDays.maximumDay}`
                    : 'Mensagem de Acompanhamento'
                }
              />
            </>
          )}
        {['steps'].includes(timelineItem.monitoring?.type) &&
          ['component'].includes(timelineItem.monitoring?.status) && (
            <Styled.Timeline.AlertNone>none</Styled.Timeline.AlertNone>
          )}
        {['steps'].includes(timelineItem.monitoring?.type) &&
          ['component'].includes(timelineItem.monitoring?.status) && (
            <>
              <Styled.Timeline.HeaderDateOnly
                moduleAccess={moduleAccess}
                interruptedModule={
                  timelineItem?.monitoring?.statusDescription === 'on_hold'
                    ? modules?.filter(
                        (module) => module?.slug === timelineItem?.monitoring?.interruptedModule
                      )?.[0]?.name
                    : null
                }
                title={
                  ['generalist', 'covid'].includes(moduleAccess)
                    ? MonitoringDictionary?.[timelineItem?.monitoring?.statusDescription]?.[
                        moduleAccess
                      ]
                    : MonitoringDictionary?.[timelineItem?.monitoring?.statusDescription]?.title
                }
              />
            </>
          )}
        {/* -----------------------paciente----------------------- */}
        {timelineItem.paciente && <Styled.Timeline.Alert>{''}</Styled.Timeline.Alert>}
        {timelineItem.paciente && <Styled.Timeline.AlertNone>none</Styled.Timeline.AlertNone>}
        {timelineItem?.paciente && <Styled.Timeline.HeaderDateOnly title="Dados Editados" />}
        {/* -----------------------tags----------------------- */}
        {timelineItem.tags && <Styled.Timeline.AlertNone>none</Styled.Timeline.AlertNone>}
        {timelineItem.tags && <Styled.Timeline.HeaderDateOnly title="Tags alteradas" />}
        {/* -----------------------Arrow----------------------- */}
        <img
          className={`blue-arrow ${shownTimelineData[idx] ? 'opened-arrow' : ''}`}
          src={
            monitoringOnHold.includes(timelineItem.monitoring?.statusDescription)
              ? DownBlueArrowDark
              : DownBlueArrow
          }
          alt="opened"
        />
      </div>
      {/* -----------------------Body----------------------- */}
      {/* -----------------------symptoms----------------------- */}
      {timelineItem.type === 'whatsapp' && (
        <TimelineWhatsapp timelineItem={timelineItem} shouldShow={shownTimelineData[idx]} />
      )}
      {timelineItem.symptoms && (
        <TimelineAlert
          idx={answerCount}
          timelineItemsCount={timelineItemsCount}
          timelineItem={timelineItem}
          shouldShow={shownTimelineData[idx]}
        />
      )}

      {/* -----------------------evolutionNote----------------------- */}
      {timelineItem.evolutionNote && (
        <TimelineNote timelineItem={timelineItem} shouldShow={shownTimelineData[idx]} />
      )}

      {/* -----------------------evolutionSoap----------------------- */}
      {timelineItem.evolutionSoap && (
        <TimelineSoapEvolution timelineItem={timelineItem} shouldShow={shownTimelineData[idx]} />
      )}

      {/* -----------------------monitoring----------------------- */}
      {timelineItem.monitoring?.template && (
        <TimelineMonitoring timelineItem={timelineItem} shouldShow={shownTimelineData[idx]} />
      )}
      {monitoringReduce.includes(timelineItem.monitoring?.statusDescription) &&
        !timelineItem.monitoring?.template && (
          <TimelineMonitoringStatus
            timelineItem={MonitoringDictionary?.[timelineItem.monitoring?.statusDescription]?.text}
            shouldShow={shownTimelineData[idx]}
          />
        )}
      {monitoringOnHold.includes(timelineItem.monitoring?.statusDescription) &&
        !timelineItem.monitoring?.template && (
          <TimelineMonitoringOnHold
            timelineItem={timelineItem.monitoring?.dateInterruptedStatus}
            interruptedModuleLink={timelineItem.monitoring?.interruptedModule}
            interruptedModule={
              modules?.filter(
                (module) => module?.slug === timelineItem.monitoring?.interruptedModule
              )?.[0]?.name
            }
            shouldShow={shownTimelineData[idx]}
          />
        )}

      {/* -----------------------evolutionRecord----------------------- */}
      {timelineItem.evolutionRecord && (
        <TimelineEvolution timelineItem={timelineItem} shouldShow={shownTimelineData[idx]} />
      )}

      {/* -----------------------paciente----------------------- */}
      {timelineItem?.paciente && (
        <TimelinePatient timelineItem={timelineItem} shouldShow={shownTimelineData[idx]} />
      )}

      {/* -----------------------tags----------------------- */}
      {timelineItem.tags && (
        <TimelineTags timelineItem={timelineItem.tags} shouldShow={shownTimelineData[idx]} />
      )}
    </Styled.Timeline.Wrapper>
  );
}

export default TimelineHeadComponents;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-height: 500px;
  overflow: auto;

  margin: 0px;
  padding: 0px;

  > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 3px;
    color: #333;
  }
`;

export const ContainerProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin: 0px;
  padding: 0px;

  > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 3px;
    color: #333;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;

  margin: 0px 0px 0px -25px;
  padding: 0px;

  > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin: 5px 10px 0px 0px;
    color: #333;
  }
`;
export const Progress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 14px;

  margin-top: 10px;
  color: #fff;
  border: 1px #333 solid;
  background: #ccc;
  border-radius: 3px;

  > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 13px;

    background: #333;
  }
`;

export const Percent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 15px;

  margin-top: 8px;
  margin-bottom: 15px;
  color: #333;
`;

export const ButtonCSV = styled.div`
  font-size: 0.687rem;
  font-weight: bold;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  padding: 0 0.812rem;
  background-color: #eee;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
  opacity: 0.8;
`;

export const Text = styled.label`
  display: inline-flex;
  cursor: pointer;
  font-size: 0.75rem;
  align-items: center;
  padding: 0px;
  margin: 0px;
  color: #0071eb;
  &:focus {
    color: #333;
  }
`;
